import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { UserArtistMembershipDocument } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  query UserArtistMembership($artistHandle: String!) {
    userArtistMembership(artistHandle: $artistHandle) {
      artistId
      serialNumber
      unseenUpdates
      createdAt
      receipts
      cardFirstTimeSeen
      artist {
        id
        name
        membershipCardImage {
          id
          membershipCardImageOptimizedUrl: imageOptimizedUrl
        }
        mainVaultId
        linkValue
        mainVault {
          id
          isUserArtistAdmin
          activeSubscription {
            id
          }
        }
      }
      user {
        id
        displayName
        computedDisplayName
        username
        avatar {
          id
          cdnUrl
          userProfileImageUrl: imageOptimizedUrl
        }
      }
    }
  }
`);

export function useMembership({
  artistHandle,
  hasActiveSubscription = true,
}: {
  artistHandle: string | null | undefined;
  hasActiveSubscription?: boolean;
}) {
  const { loggedInUser, loginStatus } = useAuthContext();

  const { data, isLoading } = useQuery(UserArtistMembershipDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      artistHandle,
    },
    enabled: !!artistHandle && loginStatus === LoginStatus.LOGGED_IN && hasActiveSubscription,
    filterQueryKey: { loggedInUserId: loggedInUser?.id },
  });

  return {
    membership: data?.data.userArtistMembership,
    isLoading,
  };
}
