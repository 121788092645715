import type { IsousState } from '../graphql/generated';

export const BOTTOMSHEET_TYPES = {
  ACTION: 'ACTION',
  ADD_TRACK_ATTACHMENT: 'ADD_TRACK_ATTACHMENT',
  CONFIRMATION: 'CONFIRMATION',
  EXIT_FLOW: 'EXIT_FLOW',
  SHARE: 'SHARE',
  SHARE_SNIPPET: 'SHARE_SNIPPET',
  SHARE_SNIPPET_INTERSTITIAL: 'SHARE_SNIPPET_INTERSTITIAL',
  SUBSCRIBE_WELCOME_MESSAGE: 'SUBSCRIBE_WELCOME_MESSAGE',
  PROFILE_PICTURE: 'PROFILE_PICTURE',
  USER_PROFILE: 'USER_PROFILE',
  MESSAGE_REACTION: 'MESSAGE_REACTION',
  ARTIST_TRACK_NOTE: 'ARTIST_TRACK_NOTE',
  ARTIST_MEDIA_ATTACHMENT: 'ARTIST_MEDIA_ATTACHMENT',
  ABOUT_ARTIST: 'ABOUT_ARTIST',
  ACCEPT_COLLABORATION: 'ACCEPT_COLLABORATION',
  GET_APP: 'GET_APP',
  CONTENT_COMMENTS: 'CONTENT_COMMENTS',
  MEDIA_VIEW: 'MEDIA_VIEW',
  SUBSCRIBERS_FILTERS: 'SUBSCRIBERS_FILTERS',
  USER_REFERRAL_FEATURE: 'USER_REFERRAL_FEATURE',
  CUSTOM: 'CUSTOM',
  JOIN_VAULT: 'JOIN_VAULT',
  MEMBERSHIP_CONFIRMATION: 'MEMBERSHIP_CONFIRMATION',
  CUSTOMIZE_VAULT: 'CUSTOMIZE_VAULT',
  DSP: 'DSP',
  EVENT_CREATE_SUCCESS: 'EVENT_CREATE_SUCCESS',
  RSVP_CREATE_SUCCESS: 'RSVP_CREATE_SUCCESS',
  RECEIPT: 'RECEIPT',
  CONNECT_STREAMING_PLATFORM: 'CONNECT_STREAMING_PLATFORM',
  SEARCH_GOOGLE_LOCATION: 'SEARCH_GOOGLE_LOCATION',
  FILTER_MEMBERS: 'FILTER_MEMBERS',
  NEW_MESSAGE: 'NEW_MESSAGE',
  BAN_USER: 'BAN_USER',
  TOGGLE_FORM: 'TOGGLE_FORM',
  SHARE_CONTENT: 'SHARE_CONTENT',
  SHARE_DROP: 'SHARE_DROP',
  ADD_LOCATION: 'ADD_LOCATION',
  ACCOUNT_SWITCHER: 'ACCOUNT_SWITCHER',
} as const;

export const US_STATE_CODES = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'Washington D.C.', code: 'DC' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
] as const satisfies Array<{ name: string; code: IsousState }>;
