import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { proxy, useSnapshot } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';
import { processAudio } from '../utils/waveformUtils';

type Recording = {
  identifier: string;
  progress: number;
  objectUrl: string;
  uploaded: boolean;
  mediaId: string | null;
  cdnUrl: string | null;
  type: MediaType;
  peaks?: number[];
  duration?: number;
} | null;

export const AudioAttachments = proxy<{
  [key: string]: {
    recording: Recording;
    isRecording: boolean;
    isUploading: boolean;
    isProcessingAudioFile: boolean;
  };
}>({});

export const defaultAudioAttachment = {
  recording: null,
  isRecording: false,
  isUploading: false,
  isProcessingAudioFile: false,
};

export function useAudioAttachment({ identifier }: { identifier: string }) {
  const audioAttachment = useSnapshot(AudioAttachments)[identifier] ?? defaultAudioAttachment;

  useEffect(() => {
    if (AudioAttachments[identifier] == null) {
      AudioAttachments[identifier] = defaultAudioAttachment;
    }
  }, [identifier]);

  return audioAttachment;
}

export function clearRecording({ identifier }: { identifier: string }) {
  if (AudioAttachments[identifier]?.recording) {
    URL.revokeObjectURL(AudioAttachments[identifier].recording.objectUrl);
  }

  AudioAttachments[identifier] = defaultAudioAttachment;
}

export function setRecording({ identifier, file }: { identifier: string; file: File }) {
  const currentAttachment = AudioAttachments[identifier] ?? defaultAudioAttachment;

  const newRecording = {
    identifier: fileIdentifier(file),
    progress: 0,
    objectUrl: URL.createObjectURL(file),
    uploaded: false,
    mediaId: null,
    cdnUrl: null,
    type: MediaType.Recording,
  };

  AudioAttachments[identifier] = {
    ...currentAttachment,
    isProcessingAudioFile: true,
    recording: {
      identifier: fileIdentifier(file),
      progress: 0,
      objectUrl: URL.createObjectURL(file),
      uploaded: false,
      mediaId: null,
      cdnUrl: null,
      type: MediaType.Recording,
    },
  };

  processAudio(file)
    .then(({ normalizedPeaks, duration }) => {
      AudioAttachments[identifier] = {
        ...currentAttachment,
        isProcessingAudioFile: false,
        recording: {
          ...newRecording,
          peaks: normalizedPeaks,
          duration,
        },
      };
    })
    .catch(error => {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'process audio recording',
        },
      });
      clearRecording({ identifier });
    });
}

export function updateProgress({ identifier, progress }: { identifier: string; progress: number }) {
  const currentAttachment = AudioAttachments[identifier] ?? defaultAudioAttachment;

  if (currentAttachment.recording != null) {
    AudioAttachments[identifier] = {
      ...currentAttachment,
      isUploading: progress > 0 && progress < 100,
      recording: { ...currentAttachment.recording, progress },
    };
  }
}

export function updateRecordingWithUploadResult({
  identifier,
  mediaId,
  cdnUrl,
}: {
  identifier: string;
  mediaId: string | null;
  cdnUrl: string | null;
}) {
  const currentAttachment = AudioAttachments[identifier] ?? defaultAudioAttachment;

  if (currentAttachment.recording != null) {
    AudioAttachments[identifier] = {
      ...currentAttachment,
      recording: {
        ...currentAttachment.recording,
        mediaId,
        cdnUrl,
        uploaded: true,
      },
      isUploading: false,
    };
  }
}
