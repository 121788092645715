import { gql } from '@soundxyz/gql-string';
import {
  ArtistProfileImageFragmentDoc,
  type FragmentType,
  getFragment,
  UserProfileImageFragmentDoc,
} from '../graphql/generated';

gql(/* GraphQL */ `
  fragment UserProfileImage on User {
    avatar {
      id
      cdnUrl
      userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
    }
  }
  fragment ArtistProfileImage on Artist {
    profileImage {
      id
      cdnUrl
      artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
    }
  }
`);

export function getUserProfileImageUrl({
  artist,
  user,
}: {
  artist: FragmentType<ArtistProfileImageFragmentDoc> | undefined | null;
  user: FragmentType<UserProfileImageFragmentDoc>;
}) {
  const artistFrag = getFragment(ArtistProfileImageFragmentDoc, artist);
  const userFrag = getFragment(UserProfileImageFragmentDoc, user);

  if (artistFrag?.profileImage) {
    return artistFrag.profileImage.artistSmallProfileImageUrl ?? artistFrag.profileImage.cdnUrl;
  }

  return userFrag.avatar.userSmallProfileImageUrl ?? userFrag.avatar.cdnUrl;
}
