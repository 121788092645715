import './lib/rum';

import React from 'react';
import { TamaguiProvider } from '@tamagui/core';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { config } from '../tamagui.config';
import { GraphQLReactQueryProvider } from './graphql/client';
import { MainRouter } from './routers/MainRouter';
import './tamagui.css';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export function App() {
  return (
    <div className="h-full w-[100vw]">
      <GraphQLReactQueryProvider>
        <TamaguiProvider config={config} defaultTheme="dark" disableInjectCSS>
          <MainRouter />
        </TamaguiProvider>
      </GraphQLReactQueryProvider>
    </div>
  );
}
