import type { FC, LegacyRef } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { useSnapshot } from 'valtio/react';
import { faLock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { loadTrack, useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../audio/AudioPosition';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistMainVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
  RemoveVaultContentsDocument,
  TrackRowInfoFragmentDoc,
  VaultContentPaginationDocument,
} from '../../graphql/generated';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getDurationAsTime } from '../../utils/dateUtils';
import { LockedPlayButton, PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { Waveform } from '../waveform/Waveform';
import { PlayIndicatorBars } from './PlayIndicatorBars';
import { SongArtwork } from './SongArtwork';

gql(/* GraphQL */ `
  fragment TrackRowInfo on VaultTrack {
    __typename
    id
    title

    thumbnailMedia {
      id
      thumbnailUrl: imageOptimizedUrl(input: { width: 580, height: 580 })
    }
    blurredThumbnailMediaUrl
    normalizedPeaks
    duration

    parentVaultContentId

    vaultId
    vault {
      id
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      isUserArtistAdmin
    }

    featureAccess {
      feature {
        __typename
      }
    }
    isFullVersionAvailable
    freeTierSnippet {
      id
      duration
    }
    ...WaveformTrackInfo
  }
`);

RefetchOnComplete({
  trigger: [RemoveVaultContentsDocument],
  refetch: [VaultContentPaginationDocument, ArtistMainVaultViewFragmentDoc, ArtistByHandleDocument],
});

type Props = {
  waveFormClass?: string;
  className?: string;
  track: FragmentType<TrackRowInfoFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  isLocked: boolean;
  isAuthor?: boolean;
};

export const TrackRow: FC<Props> = ({
  track,
  waveFormClass,
  className,
  containerRef,
  isLocked,
  isAuthor,
}) => {
  const { playing, activeTrackId, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const trackInfo = getFragment(TrackRowInfoFragmentDoc, track);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const {
    id,
    title,
    duration,
    vaultId,
    isFullVersionAvailable,
    freeTierSnippet,
    parentVaultContentId,
    thumbnailMedia,
    blurredThumbnailMediaUrl,
  } = trackInfo;

  const isSnippet = !isFullVersionAvailable;
  const durationToUse = isSnippet ? freeTierSnippet?.duration ?? duration : duration;

  const isPlayDisabled = false;

  const isPlaying = playing && activeTrackId === id;

  const onPlayClick = () => {
    if (activeTrackId !== id) {
      loadTrack({
        trackId: id,
        vaultId,
        component: 'message_attachment',
        folderId: parentVaultContentId,
      });
    } else {
      activeTrack != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: activeTrack.id,
            vaultId: activeTrack.vault.id,
            artistId: activeTrack.vault.artist?.id,
            percentComplete,
            isPreview: !activeTrack.isFullVersionAvailable,
            component: 'message_attachment',
          },
        });

      togglePlayPause();
    }
  };

  return (
    <View
      className={twMerge(
        'my-1 box-border flex  w-[280px] flex-shrink-0 flex-col overflow-hidden rounded-md md2:w-[356px]',
        isAuthor
          ? 'bg-vault_accent_text/30'
          : vaultTheme.mode === 'light'
            ? 'bg-vault_text/30'
            : 'bg-vault_accent_text/30',
        className,
      )}
      containerRef={containerRef}
    >
      <TrackArtwork
        mediaUrl={thumbnailMedia?.thumbnailUrl}
        blurredMediaUrl={blurredThumbnailMediaUrl}
        isLocked={isLocked}
        trackTitle={title}
      />
      <View className="box-border flex w-full flex-row items-start gap-2 overflow-hidden px-4 pt-4">
        <View className="box-border flex max-w-full flex-1 flex-shrink flex-grow flex-col overflow-hidden">
          <View className="mr-3 flex w-full flex-row items-center justify-start gap-1 overflow-hidden">
            {isPlaying && (
              <PlayIndicatorBars className="mr-[2px] flex-shrink-0" isAuthor={!!isAuthor} />
            )}
            <Text
              className={twMerge(
                'mr-1 flex-1 truncate font-base text-[14px]/[18px] font-semibold',
                isAuthor ? 'text-vault_accent_text' : 'text-vault_text',
              )}
            >
              {title}
            </Text>

            {isSnippet && (
              <Text
                className={twMerge(
                  'flex h-4 w-fit flex-shrink-0 items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                  isAuthor
                    ? 'border-vault_accent_text text-vault_accent_text'
                    : 'border-vault_text text-vault_text',
                )}
              >
                Snippet
              </Text>
            )}
          </View>
          <View
            className={twMerge(
              'font-base',
              isAuthor
                ? 'text-[11px]/[14px] text-vault_accent_text'
                : 'text-[11px]/[14px] text-vault_text',
            )}
          >
            {getDurationAsTime(durationToUse)}
          </View>
        </View>
        <View className="flex-shrink-0">
          {isLocked ? (
            <LockedPlayButton />
          ) : (
            <PlayButtonView
              isPlaying={isPlaying}
              isDisabled={isPlayDisabled}
              className={isAuthor ? 'text-vault_accent_text' : 'text-vault_text'}
              onClick={e => {
                e.stopPropagation();
                onPlayClick();
              }}
            />
          )}
        </View>
      </View>
      <View className="box-border flex w-full flex-row items-center gap-4 px-4 pb-4">
        <div className="flex flex-1">
          <Waveform
            track={trackInfo}
            className={twMerge('flex-1', waveFormClass)}
            height={24}
            isDisabled={isLocked || isPlayDisabled}
            isAuthor={!!isAuthor}
          />
        </div>
      </View>
    </View>
  );
};

export function TrackRowSkeleton() {
  return (
    <View className="box-border flex w-full flex-1 flex-col items-center">
      <LoadingSkeleton className="mb-[20px] h-[112px] w-full bg-vault_text/10" />
    </View>
  );
}

function TrackArtwork({
  mediaUrl,
  blurredMediaUrl,
  isLocked,
  trackTitle,
}: {
  mediaUrl: string | null | undefined;
  blurredMediaUrl: string | null | undefined;
  isLocked: boolean;
  trackTitle: string;
}) {
  const imageUrl = isLocked ? blurredMediaUrl : mediaUrl;

  return (
    <View className="relative flex aspect-1  w-full  flex-shrink-0 flex-col items-center justify-center bg-transparent">
      <SongArtwork
        thumbnailUrl={imageUrl}
        fullSize
        iconSize={32}
        transparentBackground
        borderRadiusSize="none"
        title={trackTitle}
      />

      {isLocked && (
        <div className="absolute flex items-center justify-center">
          <FontAwesomeIcon icon={faLock} fontSize={24} className="text-vault_text/80" />
        </div>
      )}
    </View>
  );
}
