import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faGif } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCirclePlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import type { VaultType } from '../../graphql/generated';
import { useLockedMessages } from '../../hooks/useLockedMessages';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { SkeletonMessageBubble } from '../message/MessageBubble';
import { MockMessageBubble } from '../message/MockMessageBubble';
import { LockedContent } from '../messages/LockedContent';

export const LockedChatView = ({
  vaultId,
  vaultType,
  linkValue,
  vaultArtistName,
  vaultArtistImage,
  vaultArtistMembershipCardImage,
  monthlySubPrice = DEFAULT_PRICE,
  chatAvailableForFreeUsers,
  showJoinFree,
}: {
  vaultId: string | undefined;
  vaultType: VaultType | undefined;
  linkValue: string | undefined;
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined | null;
  vaultArtistMembershipCardImage: string | undefined | null;
  monthlySubPrice: number;
  chatAvailableForFreeUsers: boolean;
  showJoinFree: boolean;
  dominantColor: string | null | undefined;
}) => {
  const messages = useLockedMessages({ vaultArtistName, vaultArtistImage });
  const messagesRef = useRef<HTMLDivElement>(null);

  return (
    <View className="relative flex h-full w-full flex-1 overflow-clip overscroll-none">
      <View containerRef={messagesRef} className="flex w-full flex-1 flex-col">
        {messages.map((message, i) =>
          i === 0 ? (
            <MockMessageBubble
              key={i}
              className={twMerge(i !== 0 && 'mt-4')}
              author={message.author}
              content={message.content}
              profileImageUrl={message.profileImageUrl}
              reactionSummary={message.reactionSummary}
              isVaultArtist={message.isVaultArtist}
              hasVaultContent={message.hasVaultContent}
            />
          ) : (
            <SkeletonMessageBubble
              key={i}
              isAuthor={false}
              className="mt-4 w-full gap-1 pl-4"
              contentClassName={twMerge(i === 1 && 'w-[400px]')}
            />
          ),
        )}
      </View>
      <View
        className={twMerge(
          'z-above0 pointer-events-none fixed bottom-[-10px] left-[0] right-0 h-[95vh] bg-gradient-to-t from-vault_background via-vault_background  via-65% to-transparent md:h-[100vh]',
          showJoinFree ? 'fixed' : 'absolute',
        )}
      />

      <LockedContent
        showJoinFree={showJoinFree}
        vaultId={vaultId}
        linkValue={linkValue}
        artistName={vaultArtistName}
        memberCardImage={vaultArtistMembershipCardImage}
        monthlySubPrice={monthlySubPrice}
        artistImage={vaultArtistImage}
        vaultType={vaultType}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      />
      <div className="bottom-8 hidden h-8 w-full flex-row gap-4 md2:absolute md2:flex">
        <div className="box-border h-full w-8 rounded-full border border-solid border-vault_text/20" />
        <div className="box-border flex h-full flex-1 flex-row items-center justify-between rounded-full border border-solid border-vault_text/20 px-2">
          <Text className="flex-1 font-base text-base-s font-medium text-vault_text/20">
            Join the inner circle to chat
          </Text>
          <View className="flex flex-row gap-2">
            <FontAwesomeIcon icon={faGif} className="text-vault_text/20" />
            <FontAwesomeIcon icon={faCirclePlus} className="text-vault_text/20" />
          </View>
        </div>
      </div>
    </View>
  );
};
