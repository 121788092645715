import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faGripLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  FolderItemFragmentDoc,
  type FragmentType,
  getFragment,
  ImageItemFragmentDoc,
  TrackItemFragmentDoc,
  VaultItemFragmentDoc,
  VideoItemFragmentDoc,
} from '../../../graphql/generated';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString, pluralizeText } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { Preview } from '../../elements/Preview';

export const VaultItemRow = ({
  item,
  isActive,
}: {
  item: FragmentType<VaultItemFragmentDoc>;
  isActive?: boolean;
}) => {
  const vaultTheme = useSnapshot(VaultThemeStore);

  const itemInfo = getFragment(VaultItemFragmentDoc, item);

  const getContent = () => {
    switch (itemInfo.__typename) {
      case 'VaultTrack': {
        const {
          title: trackTitle,
          createdAt,
          duration,
          thumbnailMedia,
        } = getFragment(TrackItemFragmentDoc, itemInfo);

        return (
          <View className="flex items-center gap-3">
            <Preview
              title={trackTitle}
              media={thumbnailMedia?.imageOptimizedUrl}
              type="TRACK"
              locked={false}
              color={vaultTheme.textColor}
              size="sm"
              isSnippet={false}
              themeMode="dark"
            />

            <View className="flex flex-col gap-1">
              <ItemText title={trackTitle} />
              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {getDurationAsTime(duration)}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultFolder': {
        const {
          title: folderName,
          createdAt: folderCreatedAt,
          childrenVaultCount,
        } = getFragment(FolderItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <Preview
              title={folderName}
              media={null}
              type="FOLDER"
              locked={false}
              color={vaultTheme.textColor}
              size="sm"
              isSnippet={false}
              themeMode="dark"
            />

            <View className="flex flex-col gap-1">
              <ItemText title={folderName} />

              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: folderCreatedAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {millify(childrenVaultCount, { precision: 0 })}&nbsp;
                {pluralizeText({
                  count: childrenVaultCount,
                  text: 'file',
                })}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultImage': {
        const { title, createdAt, uploadedMedia } = getFragment(ImageItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <Preview
              title={title}
              media={uploadedMedia?.smallImageUrl ?? uploadedMedia?.cdnUrl}
              type="IMAGE"
              locked={false}
              color={vaultTheme.textColor}
              size="sm"
              isSnippet={false}
              themeMode="dark"
            />

            <View className="flex flex-col gap-1">
              <ItemText title={title} />

              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultVideo': {
        const {
          title: videoName,
          createdAt: videoCreatedAt,
          duration,
          uploadedMedia,
        } = getFragment(VideoItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <Preview
              title={videoName}
              media={uploadedMedia?.url}
              thumbnailUrl={uploadedMedia?.videoScreenshotUrl}
              type="VIDEO"
              locked={false}
              color={vaultTheme.textColor}
              size="sm"
              isSnippet={false}
              themeMode="dark"
            />

            <View className="flex flex-col gap-1">
              <ItemText title={videoName} />

              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: videoCreatedAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {getDurationAsTime(duration)}
              </Text>
            </View>
          </View>
        );
      }
      default:
        return null;
    }
  };
  return (
    <View
      className={twMerge(
        'flex w-full flex-row items-center justify-between gap-5 rounded-md border border-solid p-1 px-4 py-3',
        'border-vault_text/5 bg-vault_background md2:bg-transparent',
        isActive ? 'opacity-10' : 'opacity-100',
      )}
    >
      {getContent()}

      <FontAwesomeIcon
        icon={faGripLines}
        fontSize="16"
        className={isActive ? 'text-vault_text' : 'text-vault_text/50'}
      />
    </View>
  );
};

const ItemText = ({ title }: { title: string }) => {
  return (
    <Text className="line-clamp-2 max-w-[calc(100vw-160px)] flex-shrink text-ellipsis font-base !text-base-m font-medium text-vault_text sm:max-w-[unset]">
      {title}
    </Text>
  );
};
