import type { FC, ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { ArtistVaultRowFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { View } from '../common/View';
import { UserRow } from '../structures/UserRow';

gql(/* GraphQL */ `
  fragment artistVaultRow on Artist {
    id
    name
    profileImage {
      id
      artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      cdnUrl
      dominantColor
    }
    linkValue
    mainVault {
      id
      isUserArtistAdmin
    }
    userId
  }
`);

type Props<Event extends EventType> = {
  artist: FragmentType<ArtistVaultRowFragmentDoc> | null;
  isSelected?: boolean;
  onClick?: () => void;
  subText?: string;
  subTextComponent?: ReactNode;
  href?: string;
  className?: string;
  actionComponent?: ReactNode;
  containerRef?: (node?: Element | null | undefined) => void;
  isMyVault?: boolean;
  event?: EventObject<Event>;
};

const VaultRow = <Event extends EventType>({
  artist,
  isSelected = false,
  onClick,
  subText,
  href,
  className,
  actionComponent,
  subTextComponent,
  containerRef,
  isMyVault = false,
  event,
}: Props<Event>) => {
  const { profileImage, name = 'Unnamed' } = getFragment(ArtistVaultRowFragmentDoc, artist) || {};

  const profileImageUrl = profileImage?.artistSmallProfileImageUrl ?? profileImage?.cdnUrl;

  return (
    <Container
      className={twMerge(
        'flex flex-row justify-between rounded-xl py-[12px] pl-[8px] text-[unset] no-underline',
        isSelected && 'bg-base800',
        className,
      )}
      href={href}
      onClick={
        event != null
          ? () => {
              trackEvent(event);
              onClick?.();
            }
          : onClick
      }
      containerRef={containerRef}
    >
      <UserRow
        profileImageUrl={profileImageUrl}
        managedVault={isMyVault}
        name={name}
        rightComponent={actionComponent}
        useVaultTheme={false}
        verifiedBadge={false}
        subtitle={subText}
        subtitleComponent={subTextComponent}
      />
    </Container>
  );
};

const Container: FC<{
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  containerRef?: (node?: Element | null | undefined) => void;
}> = ({ href, children, onClick, className, containerRef }) => {
  if (href == null) {
    return (
      <View onClick={onClick} className={className} containerRef={containerRef}>
        {children}
      </View>
    );
  }
  return (
    <Link to={href} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export { VaultRow };
