import { useEffect, useRef } from 'react';
import { captureException } from '@sentry/react';
import { Mp3MediaRecorder } from 'mp3-mediarecorder';
import {
  AudioAttachments,
  clearRecording,
  setRecording,
  updateRecordingWithUploadResult,
  useAudioAttachment,
} from '../contexts/AudioRecordContext';
import { useToast } from '../contexts/ToastContext';
import { MediaType } from '../graphql/generated';
import { uploadMultipartFile } from '../utils/s3Utils';
import { getWorker, terminateWorker } from '../utils/workerUtils';

export function useAudioRecorder({
  artistId,
  messageChannelId,
}: {
  artistId: string;
  messageChannelId: string;
}) {
  useAudioAttachment({ identifier: messageChannelId });

  const AudioAttachment = AudioAttachments[messageChannelId];

  const recorderRef = useRef<Mp3MediaRecorder | null>(null);
  const worker = useRef<Worker | null>(null);

  const { openToast } = useToast();

  const handleRecordingError = (error: Error) => {
    let errorMessage = 'An unexpected error occurred while trying to record audio.';
    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
      errorMessage = 'Audio recording permission was denied.';
    } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
      errorMessage = 'No microphone devices found.';
    } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
      errorMessage = 'Your microphone is currently in use by another application.';
    }
    openToast({ text: errorMessage, variant: 'error' });
  };

  const startRecording = async (onStart?: () => void) => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && worker.current) {
        onStart?.();
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

        const recorder = new Mp3MediaRecorder(stream, { worker: worker.current });
        recorderRef.current = recorder;
        recorder.start();

        if (AudioAttachment != null) {
          AudioAttachment.isRecording = true;
        }
      } else {
        throw new Error('Audio recording is not supported by this browser.');
      }
    } catch (error) {
      if (AudioAttachment != null) {
        AudioAttachment.isRecording = false;
      }
      handleRecordingError(error as Error);
      captureException(error);
    }
  };

  const stopRecording = async () => {
    if (!recorderRef.current) return;

    try {
      recorderRef.current.stop();
      recorderRef.current.ondataavailable = e => {
        const fileType = 'audio/mp3';
        const fileName = 'recording.mp3';
        const audioBlob = new Blob([e.data], { type: fileType });
        const audioFile = new File([audioBlob], fileName, { type: fileType });
        setRecording({ identifier: messageChannelId, file: audioFile });
        uploadRecording(audioFile);
      };
    } catch (error) {
      captureException(error);
      openToast({
        text: `Error stopping the recording: ${error}`,
        variant: 'error',
      });
    } finally {
      recorderRef.current?.stream.getTracks().forEach(track => track.stop());
      if (AudioAttachment != null) {
        AudioAttachment.isRecording = false;
      }
    }
  };

  const uploadRecording = async (file: File) => {
    try {
      if (AudioAttachment != null) {
        AudioAttachment.isUploading = true;
      }
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: MediaType.Recording,
        artistId,
      });
      if (AudioAttachment != null) {
        AudioAttachment.isUploading = false;
      }
      updateRecordingWithUploadResult({ identifier: messageChannelId, mediaId, cdnUrl });
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'AudioRecorder',
        },
      });
      openToast({
        text: `There was an error uploading your audio note. ${error}`,
        variant: 'error',
      });
      clearRecording({ identifier: messageChannelId });
    }
  };

  useEffect(() => {
    worker.current = getWorker();
    return () => terminateWorker();
  }, []);

  return {
    startRecording,
    stopRecording,
  };
}
