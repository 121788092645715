import { type UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { useNavigate } from 'react-router';
import { faHardDrive, faMessages } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  type FragmentType,
  getFragment,
  type MembershipReceiptFragment,
  RsvpDropViewFragmentDoc,
  RsvpPrivateDropFragmentDoc,
  RsvpPublicDropFragmentDoc,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { CarouselItem } from '../campaign/earnedReceipt/CarouselItem';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ReceiptUI } from '../membership/receipt/ReceiptUI';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { StaticMembershipBadge } from '../vault/MembershipBadge';

type Data = {
  title: string;
  description: string;
  component: 'receipt' | 'membershipCard' | 'vault' | 'chat';
};

function claimCarouselData({ hasActiveSubscription }: { hasActiveSubscription: boolean }) {
  const data: Data[] = [
    {
      title: "You're all set!",
      description: 'Receipts go towards earning status',
      component: 'receipt',
    },
  ];

  if (!hasActiveSubscription) {
    data.push({
      title: 'Your membership card',
      description: "As you collect more receipts, you'll unlock more rewards and access.",
      component: 'membershipCard',
    });

    data.push({
      title: 'Access my Vault',
      description:
        "I'll share music, images, videos, and more as my way of thanking you for your support.",
      component: 'vault',
    });

    data.push({
      title: 'Chat with me',
      description:
        'Hang out in the group chat with me and let me know what you think about my music.',
      component: 'chat',
    });
  }

  return data;
}

// TODO: Move this page away from rsvp context and use it for all receipts
export function ClaimPageView({
  dropFrag,
  receipt,
}: {
  dropFrag: FragmentType<RsvpDropViewFragmentDoc> | null | undefined;
  receipt: MembershipReceiptFragment | null | undefined;
}) {
  const navigate = useNavigate();
  const { artistHandle } = useArtistHandle();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isDesktop } = useWindow();

  const drop = getFragment(RsvpDropViewFragmentDoc, dropFrag);
  const publicDrop =
    drop?.__typename === 'PublicRsvpEvent' ? getFragment(RsvpPublicDropFragmentDoc, drop) : null;
  const privateDrop =
    drop?.__typename === 'RsvpEventPrivateInfo'
      ? getFragment(RsvpPrivateDropFragmentDoc, drop)
      : null;

  const dropData = publicDrop || privateDrop;

  const hasActiveSubscription = !!drop?.artist.mainVault.activeSubscription?.id;

  const carouselData = useMemo(
    () => claimCarouselData({ hasActiveSubscription }),
    [hasActiveSubscription],
  );

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwner = !!ownedArtist;

  const showCarouselIndicators = carouselData.length > 1;

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + carouselData.length) % carouselData.length);
  };

  const scrollToSlide = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const child = container.firstChild as HTMLElement | null;
      const childWidth = child?.getBoundingClientRect().width || 0;
      container.scrollLeft = childWidth * index;
    }
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const newIndex = Math.round(scrollLeft / childWidth);

          if (newIndex !== currentSlide) {
            setCurrentSlide(newIndex);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide],
  );

  const onClaimReceipt = useCallback(() => {
    const linkValue =
      receipt?.membership.artist.linkValue || drop?.artist.linkValue || artistHandle;

    const queryParams = constructQueryParams({
      artistHandle: linkValue,
      openBottomSheet: 'freeTierModal',
      redirect: null,
    });

    navigate(
      isOwner
        ? artistNavigationPath(linkValue, '/drops')
        : artistNavigationPath(linkValue, '/drops', queryParams),
    );
  }, [
    artistHandle,
    drop?.artist.linkValue,
    isOwner,
    navigate,
    receipt?.membership.artist.linkValue,
  ]);

  useEffect(() => {
    scrollToSlide(currentSlide);
  }, [currentSlide]);

  const renderCarouselContent = useMemo(() => {
    return (component: string | undefined) => {
      if (!component || !artistHandle || !dropData || !receipt) return null;

      switch (component) {
        case 'receipt':
          return (
            <ReceiptUI
              className="aspect-[280/378] h-full max-h-[378px] min-h-[322px] w-[unset] max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]"
              title={dropData.title}
              receiptNumber={receipt.serialNumber}
              username={receipt.user.computedDisplayName || '@username'}
              artistName={receipt.membership.artist.name}
              artistHandle={artistHandle}
              createdAt={receipt.createdAt}
              type="rsvp"
            />
          );
        case 'membershipCard':
          return (
            <View className="aspect-[280/378] h-full max-h-[378px] w-[unset] max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]">
              <StaticMembershipBadge
                isLoading={false}
                artistName={receipt.membership.artist.name || artistHandle}
                serialNumber={receipt.membership.serialNumber}
                imageUrl={
                  receipt.membership.artist.membershipCardImage?.membershipCardImageUrl ||
                  drop?.artist.membershipCardImage?.membershipCardImageOptimizedUrl
                }
                displayName={receipt.user.computedDisplayName || '@username'}
                createdAt={receipt.membership.createdAt}
                receiptCount={receipt.membership.receipts}
                artistHandle={artistHandle}
                className="!h-full !w-full"
              />
            </View>
          );
        case 'vault':
          return <CarouselItem icon={faHardDrive} />;
        case 'chat':
          return <CarouselItem icon={faMessages} />;
        default:
          return null;
      }
    };
  }, [
    artistHandle,
    drop?.artist.membershipCardImage?.membershipCardImageOptimizedUrl,
    dropData,
    receipt,
  ]);

  const coverImageUrl = dropData?.coverImage?.rsvpCoverImageUrl;

  const background = useMemo(() => {
    return (
      <>
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Blurred Drop Cover"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={crypto.randomUUID()}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        )}

        <View className="absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black md2:block" />
        <View className="absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black md2:hidden" />
      </>
    );
  }, [coverImageUrl]);

  if (!receipt?.id) return null;

  return (
    <View className="no-scrollbar box-border flex h-full w-full flex-col items-center justify-center overflow-x-clip">
      {background}
      <View className="flex flex-1 flex-col items-center justify-center pb-4 pt-8">
        <View className="z-above4 flex flex-col">
          <View className="flex items-center justify-center">
            {isDesktop ? (
              <View className="flex flex-row items-center gap-8">
                {showCarouselIndicators && (
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                    onClick={prevSlide}
                  />
                )}
                {renderCarouselContent(carouselData[currentSlide]?.component)}
                {showCarouselIndicators && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                    onClick={nextSlide}
                  />
                )}
              </View>
            ) : (
              <View
                containerRef={containerRef}
                className="no-scrollbar flex min-h-[322px] w-full flex-shrink-[1] snap-x snap-mandatory flex-row items-center overflow-x-auto"
                onScroll={onScroll}
              >
                {carouselData.map((item, index) => (
                  <motion.div key={index} className="h-full w-full flex-none snap-center">
                    <View className="flex h-full flex-col items-center justify-center overflow-y-hidden">
                      {renderCarouselContent(item.component)}
                    </View>
                  </motion.div>
                ))}
              </View>
            )}
          </View>

          {/* Dots */}
          {showCarouselIndicators && (
            <View className="mt-6 flex justify-center">
              {carouselData.map((_, index) => (
                <View
                  key={index}
                  className={`mx-1 h-2 w-2 rounded-full ${
                    index === currentSlide ? 'bg-yellow100' : 'bg-base500'
                  }`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </View>
          )}

          <View className="mt-4 flex flex-[1.4] flex-shrink-[10] flex-col items-center justify-start">
            {/* Text content */}
            <View className="mt-2 box-border w-full max-w-md px-10 md2:px-0">
              <View className="relative">
                <View className="box-border flex flex-col items-center justify-center overflow-hidden rounded-lg px-4 text-center">
                  {carouselData[currentSlide]?.title && (
                    <Text className="mb-2 text-base-xl font-semibold text-white">
                      {carouselData[currentSlide]?.title}
                    </Text>
                  )}

                  {carouselData[currentSlide]?.description && (
                    <Text className="box-border max-h-20 w-full text-base-l text-base400">
                      {carouselData[currentSlide]?.description}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <Button
              type="primary"
              label="Claim receipt"
              className="mx-8 mt-5 w-[250px]"
              onClick={onClaimReceipt}
              event={{
                type: EVENTS.CLAIM_RECEIPT,
                properties: { dropId: drop?.id, campaignId: undefined },
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
}
