import { useEffect } from 'react';
import msFn from 'ms';
import { Navigate, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import {
  MusicCampaignSkeleton,
  MusicCampaignView,
} from '../../../components/campaign/MusicCampaignView';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useQuery } from '../../../graphql/client';
import { ReleaseCampaignByIdOrSlugDocument } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';

const fiveMinutes = msFn('5 minutes');

gql(/* GraphQL */ `
  query ReleaseCampaignByIdOrSlug($artistHandle: String, $id: UUID, $slug: String) {
    releaseCampaignByIdOrSlug(artistHandle: $artistHandle, id: $id, slug: $slug) {
      id
      __typename
      ... on ReleaseCampaignPublicInfo {
        currentState
      }
      ...MusicCampaignView
      ...EarnedReceiptView
    }
  }
`);

export const CampaignPage = () => {
  const { loginStatus } = useAuthContext();

  const { campaignSlugOrId } = useParams<{
    campaignSlugOrId: string;
  }>();

  const { artistHandle } = useArtistHandle();

  const { setShowMenu } = useMenuContainer();

  useVaultTheme();

  const { data, isLoading, isError, refetch } = useQuery(ReleaseCampaignByIdOrSlugDocument, {
    cacheTime: 0,
    staleTime: 0,
    variables: !!artistHandle &&
      !!campaignSlugOrId && {
        artistHandle: artistHandle.toLowerCase(),
        ...(isUUID4(campaignSlugOrId) ? { id: campaignSlugOrId } : { slug: campaignSlugOrId }),
      },
    filterQueryKey: {
      campaignSlugOrId,
      artistHandle,
    },
    // Refetch to always get latest campaign.currentState
    refetchInterval: fiveMinutes,
    // We only want the public release campaign here
    select(data) {
      return data.data.releaseCampaignByIdOrSlug;
    },
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (campaignSlugOrId == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError) {
    return (
      <View className="flex h-full w-full items-center justify-center bg-vault_background">
        <ErrorView
          onRetryClick={refetch}
          loggingType="campaign_page"
          withVaultTheme
          showBackButton
        />
      </View>
    );
  }

  if (isLoading || loginStatus === LoginStatus.LOADING) {
    return <MusicCampaignSkeleton />;
  }

  if (data == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <MusicCampaignView campaignFrag={data} isPreview={false} />;
};
