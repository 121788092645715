import {
  type ContentUploadErrors,
  type ContentUploadFields,
  ContentUploadFieldSchema,
} from './schema';
import { initialUploadContentState, uploadContentState } from './store';

export const setField = <FieldKey extends keyof ContentUploadFields>(
  name: FieldKey,
  value: ContentUploadFields[FieldKey],
) => {
  uploadContentState.fields[name] = value;
};

export const setError = <FieldKey extends keyof ContentUploadErrors>(
  name: FieldKey,
  value: string | null,
) => {
  uploadContentState.errors[name] = value;
};

export const clearErrors = () => {
  uploadContentState.errors = {
    title: null,
  };
};

export const clearFields = () => {
  uploadContentState.fields = initialUploadContentState().fields;
};

export const validateField = async <FieldKey extends keyof ContentUploadErrors>(name: FieldKey) => {
  //clear field error
  uploadContentState.errors[name] = null;

  const result = await ContentUploadFieldSchema.shape[name].safeParseAsync(
    uploadContentState.fields[name],
  );

  if (!result.success) {
    uploadContentState.errors[name] = result.error.flatten().formErrors?.[0];
  }
};

export const populateFields = (newFields: Partial<ContentUploadFields>) => {
  for (const [key, value] of Object.entries(newFields)) {
    if (key in uploadContentState.fields && value !== undefined) {
      (uploadContentState.fields as Record<string, unknown>)[key] = value;
    }
  }
};
