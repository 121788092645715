import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ROUTES } from '../../constants/routeConstants';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Logo } from '../svg/Logo';

export function MenuLogo({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View className="mb-2 max-w-8 pl-3 md2:my-3 [&_svg]:w-12">
      <Link
        className="flex cursor-pointer appearance-none items-center gap-5 border-none bg-transparent no-underline outline-none"
        to={ROUTES.LANDING_PAGE}
      >
        <Logo className="h-12 w-12 flex-shrink-0" variant={withVaultTheme ? 'themed' : 'white'} />
        <Text
          className={twMerge(
            'hidden font-title text-[18px] font-medium md2:block',
            withVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          Vault
        </Text>
      </Link>
    </View>
  );
}
