import { useEffect, useMemo, useState } from 'react';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import { appendAttachmentPreviewCopyToMessage } from '@soundxyz/vault-utils/dist/utils/linkUtils';
import { useVaultContentByIdOrSlug } from '../useVaultContent';

export const useTextBlastPreviewMessage = ({
  alreadySent,
  artistHandle,
  message,
  attachedTrack,
  messageAttachmentCount,
  shouldIgnoreMedia = false,
}: {
  alreadySent: boolean;
  artistHandle: string;
  message: string | null | undefined;
  attachedTrack: {
    id: string | null | undefined;
    linkValue: string | null | undefined;
  } | null;
  messageAttachmentCount: number | null;
  shouldIgnoreMedia: boolean;
}) => {
  const [attachedTrackLinkValue, setAttachedTrackLinkValue] = useState<string | null>(
    attachedTrack?.linkValue ?? null,
  );

  const { data: queriedAttachedTrack } = useVaultContentByIdOrSlug({
    vaultContentId: attachedTrack?.linkValue ? null : attachedTrack?.id,
    artistHandle,
  });

  useEffect(() => {
    if (alreadySent) return;

    if (attachedTrack?.linkValue) {
      setAttachedTrackLinkValue(attachedTrack.linkValue);
      return;
    }

    if (
      !attachedTrack?.id ||
      !queriedAttachedTrack ||
      queriedAttachedTrack.__typename !== 'VaultTrack'
    ) {
      setAttachedTrackLinkValue(null);
      return;
    }

    setAttachedTrackLinkValue(queriedAttachedTrack.linkValue);
  }, [alreadySent, artistHandle, attachedTrack, queriedAttachedTrack]);

  const previewMessage = useMemo(() => {
    const trackVaultContentLinkValue =
      attachedTrack?.id || attachedTrack?.linkValue ? attachedTrackLinkValue : null;

    const additionalInput = (() => {
      if (!!trackVaultContentLinkValue) {
        return {
          trackVaultContentLinkValue,
          mediaCount: null,
        };
      }

      if (messageAttachmentCount != null && messageAttachmentCount > 0 && !shouldIgnoreMedia) {
        return {
          trackVaultContentLinkValue: null,
          mediaCount: messageAttachmentCount,
        };
      }
      return {
        trackVaultContentLinkValue: null,
        mediaCount: null,
      };
    })();

    return appendAttachmentPreviewCopyToMessage({
      message,
      shortenedWebappUrl: new URL(
        ENVIRONMENT === 'production' ? 'https://vlt.fm' : 'https://dev.vlt.fm',
      ),
      artistHandle,
      ...additionalInput,
    });
  }, [
    attachedTrack?.id,
    attachedTrack?.linkValue,
    attachedTrackLinkValue,
    message,
    artistHandle,
    messageAttachmentCount,
    shouldIgnoreMedia,
  ]);

  return alreadySent ? message : previewMessage;
};
