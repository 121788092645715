import { type ChangeEventHandler, type FormEventHandler, type ReactNode, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoginWithSms } from '@privy-io/react-auth';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faComment } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument, FeatureTypename, TierTypename } from '../../graphql/generated';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { FormErrorIndicator } from '../forms/FormErrorIndicator';
import { Item, Select } from '../forms/Select';

export function DeprecatedAllAccessPass({
  artistImageUrl,
  showArtistImage,
  containerClassName,
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  showDivider = false,
  dividerClassName,
  action,
  actionContainerClassname,
  artistHandle,
  showChatAccessLink,
}: {
  artistImageUrl?: string | null;
  showArtistImage?: boolean;
  containerClassName?: string;
  title: string;
  titleClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
  showDivider?: boolean;
  dividerClassName?: string;
  actionContainerClassname?: string;
  action: ReactNode;
  artistHandle: string;
  showChatAccessLink: boolean;
}) {
  return (
    <View
      className={twMerge(
        'flex w-full min-w-0 flex-col items-center justify-center',
        containerClassName,
      )}
    >
      <View className="flex w-full flex-col items-center justify-center rounded-xl bg-base800 py-6">
        {artistImageUrl && showArtistImage && (
          <ArtistProfileImage
            className="mb-4 h-[86px] w-[86px] rounded-full object-cover object-center"
            profileImageUrl={artistImageUrl}
          />
        )}

        <Text
          className={twMerge(
            'mb-4 text-center font-title text-[20px] font-medium leading-[22px] [leading-trim:both] [text-edge:cap]',
            titleClassName,
          )}
        >
          {title}
        </Text>

        {subtitle && (
          <Text className={twMerge('mb-6 text-center text-title-s text-white', subtitleClassName)}>
            {subtitle}
          </Text>
        )}

        {showDivider && (
          <View className={twMerge('mb-4 flex w-full', dividerClassName)}>
            <View className="mx-6 flex h-[1px] flex-1 bg-base700" />
          </View>
        )}

        <View className="flex-start flex flex-col gap-4 px-6 py-3">
          <View className="flex items-center gap-5">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon icon={faWaveformLines} fontSize={16} className="text-white" />
            </View>
            <span className="line-clamp-1 font-base !text-base-m font-normal text-white md2:!text-base-l">
              Full access to unreleased music
            </span>
          </View>

          <View className="flex items-center gap-5">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon icon={faTicket} fontSize={16} className="text-white" />
            </View>
            <span className="line-clamp-1 font-base !text-base-m font-normal text-white md2:!text-base-l">
              First access & discounts to shows
            </span>
          </View>

          <View className="flex items-center gap-5">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon icon={faComment} fontSize={16} className="text-white" />
            </View>
            <span className="line-clamp-1 font-base !text-base-m font-normal text-white md2:!text-base-l">
              Private group{' '}
              {showChatAccessLink ? (
                <Link to={`/${artistHandle}/chat`} className="text-[unset] underline">
                  chat access
                </Link>
              ) : (
                <span>chat access</span>
              )}
            </span>
          </View>
        </View>
      </View>
      <View className={twMerge('w-full', actionContainerClassname)}>
        <View
          className="h-[3px] w-[calc(100%-32px)] bg-base800"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='50%25' x2='100%25' y2='50%25' stroke='%230E1213FF' stroke-width='5' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
          }}
        />
        <View className="flex w-full flex-col items-center justify-center rounded-xl bg-base800 py-5">
          {action}
        </View>
      </View>
    </View>
  );
}

export function SimplifiedAllAccessPass({ artistHandle }: { artistHandle: string }) {
  const { data } = useQuery(ArtistByHandleDocument, {
    variables: {
      link: artistHandle.toLowerCase(),
    },
    staleTime: 0,
  });
  const chatAvailableForFreeUsers = data?.data.artistLink?.artist.mainVault.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  return (
    <View className="box-border flex w-full flex-col items-center justify-center gap-4 rounded-xl p-6">
      <View className="flex w-full flex-col items-center gap-4 text-center">
        <View className="flex items-center gap-5 text-vault_text">
          <View className="flex h-5 w-5 justify-center">
            <FontAwesomeIcon icon={faWaveformLines} fontSize={16} />
          </View>
          <span className="line-clamp-1 text-center font-base !text-base-l font-normal">
            Listen to unreleased music
          </span>
        </View>

        <View className="flex items-center gap-5 text-vault_text">
          <View className="flex h-5 w-5 justify-center">
            <FontAwesomeIcon
              icon={chatAvailableForFreeUsers ? faComment : faTicket}
              fontSize={16}
            />
          </View>
          <span className="font-base !text-base-l font-normal">
            {chatAvailableForFreeUsers
              ? 'Private group chat access'
              : 'First access & discounts to shows'}
          </span>
        </View>

        <View className="flex items-center gap-5 text-vault_text">
          <View className="flex h-5 w-5 justify-center">
            <FontAwesomeIcon icon={faMobile} fontSize={16} />
          </View>
          <span className="line-clamp-1 font-base !text-base-l font-normal">
            Text updates for new drops
          </span>
        </View>
      </View>
    </View>
  );
}

export function DeprecatedAllAccessPassWithForm({
  chatAvailableForFreeUsers,
  isSubscribingFreeTier,
  linkValue,
  monthlySubPrice,
  onJoinFreeClick,
}: {
  onJoinFreeClick: () => void;
  chatAvailableForFreeUsers: boolean;
  isSubscribingFreeTier: boolean;
  monthlySubPrice: number;
  linkValue: string;
}) {
  const { loginStatus } = useAuthContext();

  const { isOpen } = useSignIn();

  return (
    <View className="mt-6 flex w-full min-w-0 flex-col items-center justify-center gap-[1px]">
      <View className="box-border flex w-full flex-col items-center justify-center gap-4 rounded-xl bg-vault_text/10 p-6">
        <View className="flex w-full flex-col items-start gap-4">
          <View className="flex w-full items-center gap-5 text-vault_text md:w-auto">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon icon={faWaveformLines} fontSize={16} />
            </View>
            <span className="line-clamp-1 font-base !text-base-l font-normal">
              Listen to unreleased music
            </span>
          </View>

          <View className="flex w-full items-center gap-5 text-vault_text md:w-auto">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon
                icon={chatAvailableForFreeUsers ? faComment : faTicket}
                fontSize={16}
              />
            </View>
            <span className="line-clamp-1 font-base !text-base-l font-normal">
              {chatAvailableForFreeUsers
                ? 'Private group chat access'
                : 'First access & discounts to shows'}
            </span>
          </View>

          <View className="flex w-full items-center gap-5 text-vault_text md:w-auto">
            <View className="flex h-5 w-5 justify-center">
              <FontAwesomeIcon icon={faMobile} fontSize={16} />
            </View>
            <span className="line-clamp-1 font-base !text-base-l font-normal">
              Text updates for new drops
            </span>
          </View>
        </View>
      </View>

      <View
        className={twMerge(
          'flex w-full flex-col items-center justify-center pb-4',
          loginStatus === LoginStatus.LOGGED_OUT ? 'pt-8' : 'pt-4',
        )}
      >
        {loginStatus === LoginStatus.LOGGED_OUT ? (
          <DeprecatedSignInForm linkValue={linkValue} />
        ) : (
          <Button
            label="Join for free"
            type="primary-themed"
            onClick={onJoinFreeClick}
            loading={isSubscribingFreeTier}
            disabled={isSubscribingFreeTier}
            disabledClassName="bg-opacity-75"
            className="w-full"
            event={{
              type: EVENTS.OPEN_BOTTOMSHEET,
              properties: {
                bottomsheetType: BOTTOMSHEET_TYPES.SUBSCRIBE_WELCOME_MESSAGE,
                monthlySubPrice: `${monthlySubPrice}`,
                artistHandle: linkValue,
                isJoiningFreeTier: true,
                code: null,
                loginStatus,
                component: 'vault_page',
              },
            }}
          />
        )}
      </View>

      {loginStatus === LoginStatus.LOGGED_OUT && (
        <Text className="w-full text-center font-base !text-base-s font-normal text-vault_text/40">
          By signing up, you agree to the{' '}
          <a
            href={TOS_URL}
            target="_blank"
            className="text-vault_text/50 no-underline hover:cursor-pointer"
            onClick={e => isOpen && e.preventDefault()}
          >
            Terms
          </a>{' '}
          &{' '}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            className="text-vault_text/50 no-underline hover:cursor-pointer"
            onClick={e => isOpen && e.preventDefault()}
          >
            Privacy Policy
          </a>
        </Text>
      )}
    </View>
  );
}

function DeprecatedSignInForm({ linkValue }: { linkValue: string }) {
  const [searchParams] = useSearchParams();

  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
  } = useSignIn();

  const {
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const isSubmitLoading = status === 'sending-code' || status === 'submitting-code';
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 && !!validPhoneNumber && codeRenabled !== 1);

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' } });

    onSignInClick({
      source: 'AllAccessPass - SignInForm',
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  if (status === 'awaiting-code-input' && lastActivePhoneNumber) {
    const queryParams = constructQueryParams({
      artistHandle: linkValue,
      openBottomSheet: 'freeTierModal',
      invite,
      c: smsCampaignResponseShortcode,
      backToVault: 1,
    });
    return <Navigate to={`${ROUTES.VERIFY}?${queryParams}`} />;
  }

  const selectedCountry = COUNTRY_CODES.find(({ code }) => code === countryCode);

  return (
    <View className="w-full">
      <form onSubmit={onNextClick} className="w-full">
        <View className="mb-4 mt-[-1rem] box-content flex flex-col rounded-full border border-solid border-vault_text/20 pt-4">
          <View className="box-border flex items-center gap-7 overflow-hidden">
            <Select
              value={selectedCountry?.code ?? ''}
              onValueChange={val => setCountryCode(val)}
              itemProps={{
                className:
                  'bg-transparent text-vault_text hover:bg-transparent focus:bg-transparent',
              }}
              className={twMerge(
                'bg-transparent p-0 font-base !text-base-l font-normal text-vault_text hover:bg-transparent',
                selectedCountry && selectedCountry.dial_code.length >= 4
                  ? 'w-[3.4em]'
                  : selectedCountry && selectedCountry.dial_code.length === 3
                    ? 'w-[3.2em]'
                    : 'w-[3em]',
              )}
              contentClassName="w-[5em] border border-solid border-vault_text/5 bg-vault_background/90 backdrop-blur-2xl"
              onOpenChange={setIsOpen}
              disabled={status === 'sending-code'}
              shouldHideIcon
            >
              {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                <Item
                  key={code}
                  value={code}
                  className="box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base !text-base-l font-normal text-vault_text hover:bg-transparent focus:bg-transparent"
                  dropDownClassName="box-border justify-center overflow-x-clip rounded-sm bg-transparent pr-1 font-base !text-base-l font-normal hover:bg-transparent focus:bg-transparent"
                >
                  <Text className="mx-2 text-[24px]">{flag}</Text>
                  <Text className="pr-2 font-base text-[16px]/[20px] font-semibold md2:pr-0">
                    {dial_code}
                  </Text>
                </Item>
              ))}
            </Select>
            <input
              type="tel"
              value={phone}
              placeholder="Phone number"
              onChange={onChange}
              disabled={status === 'sending-code'}
              autoFocus
              className={twMerge(
                'border-none bg-transparent font-base !text-base-l font-normal focus:border-none focus:outline-none',
                'ml-2 flex-1 text-vault_text placeholder:text-vault_text/50 focus:w-[unset] md:ml-0',
              )}
            />
            {errorText != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-vault_text/5',
              errorText != null && 'bg-destructive300',
            )}
          />
          {errorText != null && (
            <Text className="my-3 text-center font-base !text-base-m font-normal text-destructive300">
              {errorText}
            </Text>
          )}
        </View>

        <Button
          label="Join for free"
          type="primary-themed"
          buttonType="submit"
          loading={isSubmitLoading}
          disabled={isSubmitDisabled}
          disabledClassName="opacity-30"
          className="w-full"
          event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
        />
      </form>
    </View>
  );
}
