import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import type { DspBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { getDSPIcon, getDSPName } from '../campaign/helpers';
import { CampaignLinksType } from '../campaign/schema';
import { useCampaignForm } from '../campaign/useCampaignForm';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function DspBottomSheet({ type }: DspBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { fields, setField } = useCampaignForm();

  const dspData = (() => {
    const allDsps = Object.values(ThirdPartyPlatform).filter(
      key => key !== ThirdPartyPlatform.Other,
    );
    switch (type) {
      case CampaignLinksType.PresavePrereleaseLinks:
        return allDsps.filter(
          key => key === ThirdPartyPlatform.Spotify || key === ThirdPartyPlatform.AppleMusic,
        );
      case CampaignLinksType.PresaveReleaseLinks:
        return allDsps.filter(
          key => key !== ThirdPartyPlatform.Spotify && key !== ThirdPartyPlatform.AppleMusic,
        );
      case CampaignLinksType.StreamReleaseLinks:
        return allDsps;
      default:
        return allDsps;
    }
  })();

  const dspDataWithIcons = dspData
    .filter(key => !fields.dsps.some(existingDsp => existingDsp.key === key))
    .map(key => ({
      key,
      name: getDSPName(key),
      icon: getDSPIcon(key),
    }));

  const handleDspClick = useCallback(
    (dspKey: ThirdPartyPlatform) => {
      if (fields.dsps.some(existingDsp => existingDsp.key === dspKey)) {
        return; // Prevent adding duplicate DSPs
      }

      const newDsp = {
        key: dspKey,
        name: getDSPName(dspKey),
        uri: '',
        buttonText: 'Play',
      };

      setField('dsps', [...fields.dsps, newDsp]);
      closeBottomsheet();
    },
    [closeBottomsheet, fields.dsps, setField],
  );

  const handleCustomDspClick = useCallback(() => {
    const newDsp = {
      key: ThirdPartyPlatform.Other,
      name: 'Other',
      uri: '',
      buttonText: 'Visit',
    };

    setField('dsps', [...fields.dsps, newDsp]);
    closeBottomsheet();
  }, [closeBottomsheet, fields.dsps, setField]);

  return (
    <View className="flex w-full flex-col items-center justify-center">
      <View className="z-10 sticky -top-2 box-border flex w-full flex-row items-center justify-between bg-vault_background pb-7 pt-4 md:pt-0">
        <View className="flex-1">
          <Button
            leadingIcon={faXmark}
            iconOnly
            onClick={() => closeBottomsheet()}
            label="Close"
            className="text-[24px] text-vault_text"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </View>

        <View className="flex-1 text-center">
          <Text className="font-title text-title-m font-medium text-vault_text">Add link</Text>
        </View>

        <View className="flex flex-1 justify-end" />
      </View>

      {dspDataWithIcons.map(dsp => (
        <DSPItem
          key={dsp.key}
          name={dsp.name}
          icon={dsp.icon}
          onClick={() => handleDspClick(dsp.key)}
        />
      ))}
      {type !== CampaignLinksType.PresavePrereleaseLinks && (
        <Button
          className="mt-6 flex w-full flex-row justify-start gap-2 rounded-xl bg-vault_text/10 px-5 py-6 font-title !text-title-s font-medium text-vault_text"
          type="secondary"
          label="Add custom"
          leadingIcon={faPlus}
          onClick={handleCustomDspClick}
        />
      )}
    </View>
  );
}

const DSPItem = ({
  name,
  icon,
  onClick,
}: {
  name: string;
  icon: IconDefinition;
  onClick: () => void;
}) => {
  return (
    <View
      className="mb-2 flex w-full cursor-pointer rounded-xl bg-vault_text/10 py-6 transition-all hover:bg-vault_text/20"
      onClick={onClick}
    >
      <View className="flex flex-row gap-3 px-5">
        <FontAwesomeIcon className="h-5 w-5 text-vault_text" icon={icon} />
        <Text className="font-title text-title-s font-medium text-vault_text">{name}</Text>
      </View>
    </View>
  );
};
