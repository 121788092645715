import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YStack } from 'tamagui';
import { useSnapshot } from 'valtio';
import { faCircle, faCircleDot } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { COLOR } from '../../constants/colorConstants';
import { VaultThemeStore } from '../../hooks/useVaultTheme';

export function Radio({
  selected,
  disabled = false,
  selectedColor = COLOR.base_accent,
  unselectedColor = COLOR.white,
  size = 20,
  useVaultTheme,
  onChange,
}: {
  selected: boolean;
  disabled?: boolean;
  size?: number;
  onChange?: (selected: boolean) => void;
} & (
  | {
      selectedColor?: string;
      unselectedColor?: string;
      useVaultTheme?: never;
    }
  | {
      selectedColor?: never;
      unselectedColor?: never;
      useVaultTheme?: boolean;
    }
)) {
  const opacity = disabled ? 0.2 : 1;
  const { textColor, accentColor } = useSnapshot(VaultThemeStore);
  const componentRef = useRef(null);

  const selectedCol = !!useVaultTheme ? accentColor : selectedColor;
  const unselectedCol = !!useVaultTheme ? textColor : unselectedColor;

  return (
    <YStack
      ref={componentRef}
      flexShrink={0}
      opacity={opacity}
      width={size}
      height={size}
      // Accessibility attributes for web
      aria-checked={selected}
      role="radio"
      tabIndex={disabled ? -1 : 0}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onPress={() => {
        if (!disabled) {
          onChange?.(!selected);
        }
      }}
      pressStyle={{
        opacity: disabled ? 0.2 : 0.8,
      }}
    >
      <FontAwesomeIcon
        icon={selected ? faCircleDot : faCircle}
        fontSize={size}
        color={selected ? selectedCol : unselectedCol}
      />
    </YStack>
  );
}
