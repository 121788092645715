import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useWindow } from '../../hooks/useWindow';
import type { EventType } from '../../types/eventTypes';
import { BackButton } from '../buttons/BackButton';
import { Button, type ButtonProps } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { AudioPlayer } from '../main/AudioPlayer';

function List({ children, showOnMobile }: { children: ReactNode; showOnMobile: boolean }) {
  return (
    <View
      className={twMerge(
        'box-border h-full w-full flex-col md2:rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10 md2:bg-vault_text/3 lg:w-[438px]',
        showOnMobile ? 'flex' : 'hidden lg:flex',
      )}
    >
      {children}
    </View>
  );
}

function ListTitle<Event extends EventType>({
  actionButton,
  title,
  titleOverride,
  withBackButton,
  withDivider,
}: {
  actionButton: ButtonProps<Event> | null;
  title: string;
  titleOverride: ReactNode | null;
  withBackButton: boolean;
  withDivider: boolean;
}) {
  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row items-center justify-between gap-6 border-0 px-4 pb-4 pt-5 lg:px-6 lg:pb-6 lg:pt-8',
        withDivider && 'border-b border-solid border-vault_text/10',
      )}
    >
      {titleOverride || (
        <>
          {withBackButton && <BackButton withVaultTheme className="text-[24px]/[24px]" />}
          <Text className="flex-1 text-center font-title text-[18px]/[22px] font-medium text-vault_text md2:text-[22px]/[26px] lg:text-left">
            {title}
          </Text>
          {actionButton != null ? (
            <Button
              className={twMerge('text-[24px]/[24px] text-vault_text', actionButton.className)}
              {...actionButton}
            />
          ) : withBackButton ? (
            <View className="h-[24px] w-[15px]" />
          ) : null}
        </>
      )}
    </View>
  );
}

function ListContent({ children }: { children: ReactNode }) {
  return <View className="box-border flex w-full flex-1 px-4">{children}</View>;
}

function Detail({ children, showOnMobile }: { children: ReactNode; showOnMobile: boolean }) {
  return (
    <View
      className={twMerge(
        'box-border h-full flex-1 overflow-hidden rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10 md2:bg-vault_text/3',
        showOnMobile ? 'flex' : 'hidden lg:flex',
      )}
    >
      {children}
    </View>
  );
}

// Main layout component
function ListDetailLayout({ children }: { children: ReactNode | ReactNode[] }) {
  const { isDesktop } = useWindow();

  return (
    <View className="flex h-full flex-col bg-vault_background">
      <View className="box-border flex h-full flex-col overflow-x-hidden overflow-y-clip overscroll-none bg-vault_background md2:px-2">
        <View className="flex w-full flex-1 flex-col items-center justify-start overflow-x-hidden overflow-y-clip overscroll-none md2:ml-[300px] md2:w-[calc(100%-300px)]">
          <View className="z-above1 mt-1 box-border flex w-full max-w-screen-dt flex-1 flex-row items-center justify-center gap-2 overflow-x-hidden overflow-y-clip overscroll-none lg:mt-2">
            {children}
          </View>
        </View>
      </View>
      {isDesktop && <AudioPlayer withBottomNavigator withVaultTheme />}
    </View>
  );
}

// List components (aka left side)
ListDetailLayout.List = List;
ListDetailLayout.ListTitle = ListTitle;
ListDetailLayout.ListContent = ListContent;

// Detail components (aka right side)
ListDetailLayout.Detail = Detail;

export { ListDetailLayout };
