import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import type { ConfirmationBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ConfirmationBottomsheet: FC<
  ConfirmationBottomsheetProps & { withVaultTheme: boolean | null | undefined }
> = ({
  subText,
  onConfirm,
  event,
  customContent,
  title = 'Are you sure?',
  confirmButtonText = 'Yes',
  cancelButtonText,
  confirmType = 'success',
  isExtraBottomsheet = false,
  fullWidth = false,
  withVaultTheme = false,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeExtraBottomsheet } = useExtraBottomsheetContainer();

  return (
    <View
      className={twMerge(
        'flex flex-col items-center justify-between px-4 md2:h-full md2:py-4',
        fullWidth && 'w-full ',
      )}
    >
      <Text
        className={twMerge(
          'mb-[16px] text-center font-title font-medium text-white max-md2:text-title-m md2:text-title-xl',
          withVaultTheme && 'text-vault_text',
        )}
      >
        {title}
      </Text>
      {customContent
        ? customContent
        : subText != null && (
            <Text
              className={twMerge(
                'mb-[16px] text-center font-base text-[18px]/[24px] font-normal text-white',
                withVaultTheme && 'text-vault_text',
              )}
            >
              {subText}
            </Text>
          )}
      <View className="flex w-[calc(100%-20px)] flex-row max-md2:mb-[16px]">
        <Button
          type="secondary"
          label={
            cancelButtonText ? cancelButtonText : confirmType === 'success' ? 'Cancel' : 'Keep'
          }
          onClick={() => (isExtraBottomsheet ? closeExtraBottomsheet() : closeBottomsheet())}
          className={twMerge(
            'mr-[8px] w-[unset] flex-1 justify-center rounded-full',
            withVaultTheme && 'bg-vault_text/10',
          )}
          event={{ type: EVENTS.CONFIRM_CANCEL, properties: { event } }}
          labelClassName={twMerge(
            'flex-row items-center justify-center text-center',
            withVaultTheme && 'text-vault_text',
          )}
        />
        <Button
          type="primary"
          label={confirmButtonText}
          onClick={e => {
            onConfirm(e);
            isExtraBottomsheet ? closeExtraBottomsheet() : closeBottomsheet();
          }}
          className={twMerge(
            'ml-[8px] flex-1',
            confirmType === 'delete' && 'bg-destructive300 text-base50',
            withVaultTheme && 'bg-vault_accent',
          )}
          event={{ type: EVENTS.CONFIRM_SUCCES, properties: { event } }}
          labelClassName={twMerge(
            'flex-row items-center justify-center text-center',
            withVaultTheme && 'text-vault_accent_text',
          )}
        />
      </View>
    </View>
  );
};

export { ConfirmationBottomsheet };
