import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck, faCrown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { TierTypename } from '../../graphql/generated';
import type { UserProfileBottomsheetProps } from '../../types/bottomsheetTypes';
import { getLocationText } from '../../utils/location';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ProfileImage } from './ProfileImage';

export function CommonUserInfo({
  activeSubscriptionTier,
  avatarUrl,
  displayName,
  geoLocation,
  isBannedFromChat,
  isVaultArtist,
  joinDateText,
  onBanClick,
  receipts,
  showLocation,
  username,
  withVaultTheme,
}: {
  activeSubscriptionTier: TierTypename | null | undefined;
  avatarUrl: string | null | undefined;
  displayName: string | null | undefined;
  geoLocation: UserProfileBottomsheetProps['userLocation'] | undefined;
  isBannedFromChat: boolean;
  isVaultArtist: boolean;
  joinDateText: string | null | undefined;
  onBanClick: (() => void) | null;
  receipts: number | null | undefined;
  showLocation: boolean;
  username: string | null | undefined;
  withVaultTheme: boolean;
}) {
  return (
    <View className="box-border flex w-full flex-col items-center px-4">
      <ProfileImage
        className="mb-[16px] mt-[20px] h-[100px] w-[100px] rounded-full"
        profileImageUrl={avatarUrl}
        onClick={undefined}
      />
      <View className="flex flex-col items-center justify-center gap-4">
        <View className="flex w-full flex-col items-center justify-center gap-1">
          {isBannedFromChat && (
            <View
              className={twMerge(
                'rounded-full px-2 text-[11px] font-semibold leading-[20px] text-destructive300',
                withVaultTheme ? 'bg-vault_text/10' : 'bg-base800',
                !!onBanClick && 'cursor-pointer',
              )}
              onClick={() => onBanClick?.()}
            >
              Banned
            </View>
          )}
          {!!displayName && displayName.length > 0 && (
            <Text
              className={twMerge(
                'flex items-center font-title text-[28px]/[34px] font-medium',
                withVaultTheme
                  ? isVaultArtist
                    ? 'text-vault_accent'
                    : 'text-vault_text'
                  : isVaultArtist
                    ? 'text-yellow100'
                    : 'text-white',
              )}
            >
              {displayName}
              {isVaultArtist && (
                <span>
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    className={twMerge(
                      'mb-[2px] ml-2 select-none text-[18px]',
                      withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
                    )}
                  />
                </span>
              )}
            </Text>
          )}
          {!!username && username.length > 0 && (
            <Text
              className={twMerge(
                'flex items-center font-base text-[14px]/[18px] font-medium',
                withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
              )}
            >
              @{username}
            </Text>
          )}
        </View>

        <View className="flex flex-col justify-center gap-1 text-center">
          {!isVaultArtist && activeSubscriptionTier === TierTypename.PaidTier && (
            <View className="mb-1 flex w-full items-center justify-center gap-1">
              <FontAwesomeIcon
                icon={faCrown}
                className={twMerge(
                  'ml-2 select-none text-[14px]',
                  withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
                )}
              />

              <Text
                className={twMerge(
                  'font-base text-[14px]/[18px] font-medium',
                  withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
                )}
              >
                All access pass
              </Text>
            </View>
          )}

          <View className="flex flex-col items-center gap-2 text-center">
            {(!!joinDateText || isVaultArtist) && (
              <View className="flex w-full items-center justify-center gap-1 font-medium">
                {!!joinDateText && (
                  <View
                    className={twMerge(
                      'text-center font-base text-[14px]/[18px]',
                      withVaultTheme ? 'text-vault_text/60' : 'text-white',
                    )}
                  >
                    {joinDateText}
                  </View>
                )}

                {isVaultArtist && (
                  <View
                    className={twMerge(
                      'flex items-center gap-1 text-center font-base text-[14px]/[18px]',
                      withVaultTheme ? 'text-vault_text/60' : 'text-white',
                    )}
                  >
                    {!!joinDateText && '·'}
                    <Text>{millify(receipts ?? 0)}</Text>
                    <FontAwesomeIcon icon={faReceipt} />
                  </View>
                )}
              </View>
            )}

            {showLocation && !!geoLocation && (
              <View
                className={twMerge(
                  'flex items-center text-center font-base text-[14px]/[18px]',
                  withVaultTheme ? 'text-vault_text/60' : 'text-white',
                )}
              >
                <Text>{getLocationText(geoLocation)}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
