import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import {
  faBellSlash,
  faCancel,
  faCog,
  faEye,
  faMessage,
  faMessageSlash,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  BanSubscriberDocument,
  GetSubscribersBanStatusDocument,
  GetUserChannelsDocument,
  GetUserToBanInformationDocument,
  UnbanSubscriberDocument,
} from '../../graphql/generated';
import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import type { BanUserBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';

gql(/* GraphQL */ `
  query GetUserToBanInformation($input: QueryVaultSubscriberByUserIdInput!) {
    vaultSubscriberByUserId(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on QueryVaultSubscriberByUserIdSuccess {
        data {
          id
          user {
            id
            avatar {
              id
              cdnUrl
              imageOptimizedUrl(input: { height: 200, width: 200 })
              dominantColor
            }
            computedDisplayName
          }
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [BanSubscriberDocument, UnbanSubscriberDocument],
  refetch: [
    GetUserToBanInformationDocument,
    GetUserChannelsDocument,
    GetSubscribersBanStatusDocument,
  ],
});

export function BanUserBottomsheet({ artistId, userId, withVaultTheme }: BanUserBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const ownedArtist = useOwnedArtist({ artistId });

  const {
    data: userToBan,
    isLoading: isLoadingUserToBan,
    isError: isErrorUserToBan,
    refetch: refetchUserToBan,
  } = useQuery(GetUserToBanInformationDocument, {
    variables: !!ownedArtist && { input: { userId, vaultId: ownedArtist?.mainVault.id } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserId?.__typename === 'QueryVaultSubscriberByUserIdSuccess'
        ? data.data.vaultSubscriberByUserId.data.user
        : null,
  });

  const {
    data: isBanned,
    isLoading: isLoadingBanStatus,
    isError: isErrorBanStatus,
    refetch: refetchBanStatus,
  } = useSubscriberBanStatus({ userId, artistId });

  const { mutateAsync: banSubscriberMutation, isLoading: isBanLoading } = useMutation(
    BanSubscriberDocument,
    {},
  );

  const { mutateAsync: unbanSubscriberMutation, isLoading: isUnBanLoading } = useMutation(
    UnbanSubscriberDocument,
    {},
  );

  const isLoading = isLoadingBanStatus || isLoadingUserToBan;
  const isError = isErrorBanStatus || isErrorUserToBan;
  const refetch = refetchUserToBan || refetchBanStatus;

  const userName = userToBan?.computedDisplayName || 'username';

  const onBanClick = useCallback(async () => {
    if (isBanned) return;

    const { data } = await banSubscriberMutation({
      input: {
        userId,
        artistId,
      },
    });

    if (data?.banSubscriberFromVault) {
      openToast({
        text: `${userName} has been banned`,
        variant: 'success',
      });
    } else {
      openToast({
        text: 'Failed to ban user. Please try again.',
        variant: 'error',
      });
    }

    closeBottomsheet();
  }, [artistId, banSubscriberMutation, closeBottomsheet, isBanned, openToast, userId, userName]);

  const onUnbanClick = useCallback(async () => {
    if (!isBanned) return;

    const { data } = await unbanSubscriberMutation({
      input: {
        userId,
        artistId,
      },
    });

    if (data?.unbanSubscriberFromVault) {
      openToast({
        text: `${userName} has been unbanned`,
        variant: 'success',
      });
    } else {
      openToast({
        text: 'Failed to unban user. Please try again.',
        variant: 'error',
      });
    }

    closeBottomsheet();
  }, [artistId, closeBottomsheet, isBanned, openToast, unbanSubscriberMutation, userId, userName]);

  if (isLoading) {
    return (
      <View className="flex h-full w-full flex-col items-center gap-8">
        <View className="flex flex-col items-center justify-center gap-4 pt-5 md2:pt-0">
          <LoadingSkeleton
            className="h-[100px] w-[100px] shrink-0 rounded-full"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton className="h-[22px] w-[150px]" withVaultTheme={withVaultTheme} />
        </View>
        <View className="flex w-full flex-col items-center gap-5">
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme={withVaultTheme} />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme={withVaultTheme} />
          </View>
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme={withVaultTheme} />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme={withVaultTheme} />
          </View>
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme={withVaultTheme} />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme={withVaultTheme} />
          </View>
        </View>
        <View className="flex w-full flex-col gap-4 pb-4">
          <LoadingSkeleton
            className="h-[52px] w-full rounded-full"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton
            className="h-[52px] w-full rounded-full"
            withVaultTheme={withVaultTheme}
          />
        </View>
      </View>
    );
  }

  if (isError || userToBan == null) {
    // Return error state
    return (
      <View className="flex h-full w-full flex-col items-center gap-8">
        <ErrorView onRetryClick={refetch} withVaultTheme={withVaultTheme} />
      </View>
    );
  }

  return (
    <View className="flex h-full w-full flex-col items-center gap-8">
      <View className="flex flex-col items-center justify-center gap-4 pt-5 md2:pt-0">
        <ProfileImage
          profileImageUrl={userToBan.avatar.imageOptimizedUrl || userToBan.avatar.cdnUrl}
          className="h-[100px] w-[100px] shrink-0 rounded-full"
          onClick={undefined}
        />

        <Text
          className={twMerge(
            'line-clamp-1 font-title text-[18px]/[22px] font-medium',
            withVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          {isBanned ? 'Unban' : 'Ban'} {userName}?
        </Text>
      </View>
      <View className="flex flex-col gap-5">
        {isBanned ? (
          <>
            <InfoRow
              icon={faMessage}
              text="They will be able to send new messages again "
              withVaultTheme={withVaultTheme}
            />
            <InfoRow
              icon={faBellSlash}
              text="They won't be notified that you unbanned them"
              withVaultTheme={withVaultTheme}
            />
          </>
        ) : (
          <>
            <InfoRow
              icon={faMessageSlash}
              text="They won't be able to send messages"
              withVaultTheme={withVaultTheme}
            />
            <InfoRow
              icon={faEye}
              text="They can still view content and messages from you and other users"
              withVaultTheme={withVaultTheme}
            />
            <InfoRow
              icon={faCog}
              text="You can unban them in settings"
              withVaultTheme={withVaultTheme}
            />
          </>
        )}
      </View>

      <View className="flex w-full flex-col gap-4 pb-4">
        {isBanned ? (
          <Button
            leadingIcon={faCancel}
            label="Unban member"
            type={withVaultTheme ? 'primary-themed' : 'primary'}
            className="w-full rounded-full px-[28px] py-4 font-title text-[16px]/[20px] font-medium"
            onClick={onUnbanClick}
            loading={isUnBanLoading}
          />
        ) : (
          <>
            <Button
              label="Ban member"
              leadingIcon={faCancel}
              className={twMerge(
                'w-full rounded-full px-[28px] py-4 font-title text-[16px]/[20px] font-medium text-destructive300',
                withVaultTheme ? 'bg-vault_text/10' : 'bg-white/10',
              )}
              onClick={onBanClick}
              loading={isBanLoading}
            />

            <Button
              label="Not now"
              className={twMerge(
                'w-full rounded-full bg-transparent px-[28px] py-4 font-title text-[16px]/[20px] font-medium',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
              onClick={closeBottomsheet}
              disabled={isBanLoading}
            />
          </>
        )}
      </View>
    </View>
  );
}

function InfoRow({
  icon,
  text,
  withVaultTheme,
}: {
  icon: IconDefinition;
  text: string;
  withVaultTheme: boolean;
}) {
  return (
    <View className="flex items-center gap-4">
      <FontAwesomeIcon
        icon={icon}
        className={twMerge('text-[20px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
      />
      <Text
        className={twMerge(
          'text-left font-base text-[16px]/[20px]',
          withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
        )}
      >
        {text}
      </Text>
    </View>
  );
}
