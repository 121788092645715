import { proxy, ref } from 'valtio';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { type ContentUploadState } from './schema';

export const initialUploadContentState = () =>
  ({
    fields: {
      folderId: null,
      mediaId: null,
      thumbnailId: null,
      thumbnailUrl: null,
      isThumbnailUploading: false,
      thumbnailRef: ref({
        current: null,
      }),
      normalizedPeaks: null,
      title: '',
      caption: null,
      featureAccess: VaultContentAccessFeatureInput.FreeVaultContent,
      contentType: null,
      shouldSendSMS: true,
      downloadEnabled: false,
      isUploading: false,
      isUploadingPreview: false,
      totalSize: 0,
      progressSize: 0,
      isPromptOpen: false,
      fileRef: ref({
        current: null,
      }),
      retryUpload: null,
      isEditing: false,
      duration: null,
      isSnippetOnly: false,
    },
    errors: {
      title: null,
    },
  }) satisfies ContentUploadState;

export const uploadContentState: ContentUploadState = proxy(initialUploadContentState());

export type UploadContentStateKeys = keyof ContentUploadState['fields'];
