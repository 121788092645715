import { useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faImage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faVideo } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ArtistProfileImage } from '../../components/artist/ArtistProfileImage';
import { AuthCTABox } from '../../components/auth/AuthCTABox';
import { BackButton } from '../../components/buttons/BackButton';
import { SubscribeButton } from '../../components/buttons/SubscribeButton';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { SongArtwork } from '../../components/track/SongArtwork';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { COLOR } from '../../constants/colorConstants';
import {
  SEMI_TRANSPARENT_NEW_FILE_IMAGE_BLACK,
  SEMI_TRANSPARENT_NEW_FILE_IMAGE_WHITE,
} from '../../constants/imageConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TierTypename, VaultType } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useVaultContentByIdOrSlug } from '../../hooks/useVaultContent';
import { useVaultTheme, VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { formatTime } from '../../utils/textUtils';

export function ContentLandingPage() {
  const { vaultContentId, vaultContentSlug } = useParams<{
    vaultContentId: string;
    vaultContentSlug: string;
    artistHandle: string;
  }>();
  const { artistHandle } = useArtistHandle();

  const { loginStatus, loggedInUser } = useAuthContext();

  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  useVaultTheme();

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite'); // invite code
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');

  const {
    data: vaultContent,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useVaultContentByIdOrSlug({
    vaultContentId,
    vaultContentSlug,
    artistHandle,
  });

  const { isDesktop } = useWindow();

  const vault = vaultContent?.vault;
  const artist = vault?.artist;
  const artistName = artist?.name;
  const artistAvatarUrl = artist?.profileImage.artistSmallProfileImageUrl;
  const contentTitle = vaultContent?.title ?? '';

  const isTrack = vaultContent?.__typename === 'VaultTrack';
  const isVideo = vaultContent?.__typename === 'VaultVideo';
  const isFolder = vaultContent?.__typename === 'VaultFolder';
  const isImage = vaultContent?.__typename === 'VaultImage';

  const artwork =
    isImage || isVideo
      ? vaultContent.blurredMediaUrl
      : isTrack
        ? vaultContent?.thumbnailMedia?.cdnUrl
        : null;

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwnedArtist = !!ownedArtist;

  const isSubscriberOrOwner = !!vault?.activeSubscription || isOwnedArtist;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault?.activeSubscription,
    isOwner: isOwnedArtist,
  });

  const isVaultFreeOnly = vault?.type === VaultType.FreeOnly;
  const isCurrentUserFreeTier = activeSubscriptionFeatures?.tier === TierTypename.FreeTier;
  const showSubscribeButton = !isVaultFreeOnly && isCurrentUserFreeTier && vault != null;

  const showLock = !isTrack && !vaultContent?.isFullVersionAvailable;

  const onJoinFreeClick = useCallback(async () => {
    if (isSubscriberOrOwner || vault?.id == null) return;

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
      membershipConfirmationBottomsheetProps: {
        vaultId: vault.id,
        isLoading: false,
        artistHandle: artist?.linkValue,
        artistName: artist?.name,
        imageUrl: artist?.membershipCardImage?.membershipCardImageUrl,
        loggedInUserUsername: loggedInUser?.username,
        loginStatus,
        inviteCode: invite,
        smsCampaignResponseShortcode,
        sourceReleaseCampaignId: null,
      },
      shared: {
        hideCloseBottomsheetButton: false,
        preventSwipeToDismiss: false,
        preventOutsideAutoClose: true,
        hidePulleyBar: true,
        withVaultTheme: true,
        isUnclosable: true,
      },
    });
  }, [
    isSubscriberOrOwner,
    vault?.id,
    openBottomsheet,
    artist?.linkValue,
    artist?.name,
    artist?.membershipCardImage?.membershipCardImageUrl,
    loggedInUser?.username,
    loginStatus,
    invite,
    smsCampaignResponseShortcode,
  ]);

  useEffect(() => {
    if (
      bottomSheetType === 'freeTierModal' &&
      loginStatus === LoginStatus.LOGGED_IN &&
      !isSubscriberOrOwner &&
      !isFetching &&
      !isLoading
    ) {
      onJoinFreeClick();
    }
  }, [
    loginStatus,
    navigate,
    onJoinFreeClick,
    bottomSheetType,
    isFetching,
    isLoading,
    isSubscriberOrOwner,
  ]);

  useSetMetaHeaders({
    title: vaultContent
      ? `${artistName} - ${contentTitle}`
      : /**
         * Use defaults for loading state
         */
        '',
  });

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  if (!vaultContent) {
    return !isError ? (
      <Navigate to={ROUTES.NOT_FOUND} />
    ) : (
      <DefaultLayout
        withVaultTheme
        showBorder
        showRoundedTop
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        contentClassName="md2:bg-vault_text/3"
        shouldSkipMargin
        headerLeft={<BackButton className="text-vault_text" />}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="content_landing_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (isFolder) {
    const queryParams = constructQueryParams({
      invite,
      c: smsCampaignResponseShortcode,
    });
    return (
      <Navigate
        to={artistNavigationPath(
          artistHandle,
          vaultContentSlug != null ? `/f/${vaultContentSlug}` : `/folder/${vaultContent.id}`,
          queryParams,
        )}
        replace
      />
    );
  }

  if (isOwnedArtist || vaultContent?.isFullVersionAvailable) {
    const queryParams = constructQueryParams({
      trackId: vaultContent.id,
      invite,
      c: smsCampaignResponseShortcode,
    });
    return <Navigate to={artistNavigationPath(artistHandle, '/', queryParams)} replace />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showBorder
      shouldSkipMargin
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={vault?.id}
      withBottomNavigator={false}
      headerClassName="bg-vault_background pt-8 2xl:pt-10"
      contentClassName="md2:bg-vault_text/3 "
      headerCenter={
        <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
          <View className="flex flex-row items-center gap-2">
            <ArtistProfileImage
              profileImageUrl={artistAvatarUrl}
              className="h-9 w-9 rounded-full"
            />

            <Text className="select-none font-base text-[16px]/[20px] font-medium text-vault_text">
              {artistName}
            </Text>
          </View>
        </Link>
      }
      extend={isDesktop}
    >
      <View className="box-border flex w-full flex-col gap-6 px-4 pb-4 max-md2:min-h-[calc(100vh-120px)] md2:max-w-[66%] md2:pt-2 2xl:max-w-[70%] 2xl:pt-4">
        <View className="flex flex-col gap-6 max-md2:flex-grow">
          <View className="relative aspect-1">
            <ContentArtwork
              imageUri={artwork}
              contentType={vaultContent?.__typename}
              title={contentTitle}
            />

            {showLock && (
              <View className="absolute left-1/2 top-1/2 flex h-6 w-6 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-black/70">
                <FontAwesomeIcon icon={faLock} className="text-[11px] text-white" />
              </View>
            )}
          </View>

          <View className="relative flex flex-col gap-6 ">
            <View className="w-full min-w-0 flex-col items-center justify-center gap-1">
              <Text className="line-clamp-3 w-full text-left font-title text-[28px]/[34px] font-medium text-vault_text">
                {contentTitle}
              </Text>
              <View className="flex w-full flex-row justify-between">
                <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
                  <Text className="line-clamp-1 font-base !text-base-l font-medium text-vault_text">
                    {artistName}
                  </Text>
                </Link>

                {isVideo ? (
                  <Text className="font-base !text-base-l font-medium text-vault_text opacity-50">
                    {formatTime(vaultContent.duration)}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
        </View>

        <View className="max-md2:mt-auto">
          {showSubscribeButton ? (
            <SubscribeButton
              label={`Upgrade to ${isTrack ? 'listen' : isImage ? 'view' : 'watch'}`}
              className="w-full bg-vault_accent !text-base-l text-vault_accent_text"
              linkValue={artistHandle}
              artistAvatarUrl={artistAvatarUrl}
              price={vault.price || DEFAULT_PRICE}
              vaultId={vault.id}
              showBottomSheet={false}
              component="content_landing_page"
            />
          ) : (
            <AuthCTABox
              type={isTrack ? 'listen' : isImage ? 'view' : 'watch'}
              artistHandle={artistHandle}
              artist={artist}
              fill
            />
          )}
        </View>
      </View>
    </DefaultLayout>
  );
}

function ContentArtwork({
  imageUri,
  contentType,
  title,
}: {
  imageUri: string | null | undefined;
  contentType?: string;
  title: string;
}) {
  const { textColor } = useSnapshot(VaultThemeStore);

  const isImage = contentType === 'VaultImage';
  const isVideo = contentType === 'VaultVideo';
  const isTrack = contentType === 'VaultTrack';

  const contentIcon = isImage ? faImage : isVideo ? faVideo : faWaveformLines;

  if (isTrack) {
    return <SongArtwork thumbnailUrl={imageUri} title={title} fullSize />;
  }
  if (imageUri != null) {
    return (
      <Image
        src={imageUri}
        alt={title ?? 'Media'}
        className="aspect-1 h-auto w-full object-cover"
      />
    );
  }
  return (
    <View className="flex aspect-square h-auto w-full items-center justify-center bg-vault_text/10">
      <Image
        width={143}
        height={179}
        src={
          textColor == COLOR.white
            ? SEMI_TRANSPARENT_NEW_FILE_IMAGE_WHITE
            : SEMI_TRANSPARENT_NEW_FILE_IMAGE_BLACK
        }
        alt="File placeholder"
        className="h-[179px] w-[143px]"
      />
      {!imageUri && (
        <View className="absolute">
          <FontAwesomeIcon icon={contentIcon} className="text-[41px] text-vault_text/50" />
        </View>
      )}
    </View>
  );
}
