import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useLoginWithEmail, useLoginWithSms } from '@privy-io/react-auth';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { AuthBox } from '../../components/auth/AuthBox';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { CodeInput } from '../../components/forms/CodeInput';
import { Logo } from '../../components/svg/Logo';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useLinkAppleMusicAccount } from '../../hooks/appleMusic/useAppleMusicAuth';
import { fetchReceiptId } from '../../hooks/campaign/fetchReceiptId';
import { useLogError } from '../../hooks/logger/useLogError';
import { useLinkSpotifyAccount } from '../../hooks/spotify/useSpotifyAuth';
import { useSignIn } from '../../hooks/useSignIn';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useTimer } from '../../hooks/useTimer';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { SignInStore } from './store';

export const VerifyPage: FC = () => {
  const navigate = useNavigate();

  const { loginStatus, loggedInUser, authError } = useAuthContext();

  const { openBottomsheet } = useBottomsheetContainer();
  const logError = useLogError();

  const { onResendCodeClick, status, setEmailStatus } = useSignIn();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const artistHandle = searchParams.get('artistHandle');
  const payeeSecretId = searchParams.get('psid');
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const backToVault = searchParams.get('backToVault');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const linkSpotifyParam = searchParams.get('linkSpotify');
  const linkAppleMusicParam = searchParams.get('linkAppleMusic');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const source = searchParams.get('source');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const eventType = searchParams.get('eventType');
  const showReceiptModal = searchParams.get('showReceiptModal');
  const receiptIdParam = searchParams.get('receiptId');

  const [receiptId, setReceiptId] = useState<string | null>(receiptIdParam);

  const [errorText, setErrorText] = useState<string | null>(null);

  const { openToast } = useToast();

  const linkSpotify = useLinkSpotifyAccount();

  const linkAppleMusic = useLinkAppleMusicAccount();

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      bottomSheetType,
      isInOnboarding,
      payeeSecretId,
      redirect,
      loggedInUser,
      code,
      trackId,
      showReceiptModal,
    }: {
      artistHandle?: string | null;
      bottomSheetType?: string | null;
      isInOnboarding?: boolean;
      payeeSecretId?: string | null;
      redirect?: string | null;
      loggedInUser?: {
        highestSubscriptionLevel?: string | null;
        artist?: { mainLinkValue: string } | null;
      } | null;
      code?: string | null;
      trackId?: string | null;
      showReceiptModal?: string | null;
    }): string => {
      if (isInOnboarding) {
        const queryParams = constructQueryParams({
          psid: payeeSecretId,
          openBottomSheet: bottomSheetType,
          artistHandle,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
          source,
          sourceReleaseCampaignId,
          eventType,
          redirect,
          showReceiptModal: showReceiptModal,
          receiptId: receiptIdParam ?? receiptId,
        });
        return `${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`;
      }

      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          openBottomSheet: bottomSheetType,
          redirect: isInOnboarding ? 'onboarding' : undefined,
          code,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
          source,
          sourceReleaseCampaignId,
          eventType,
          showReceiptModal: showReceiptModal,
          receiptId: receiptIdParam ?? receiptId,
        });
        if (redirect) {
          return artistNavigationPath(artistHandle, `/${redirect}`, queryParams);
        }
        if (bottomSheetType) {
          return artistNavigationPath(artistHandle, '/', queryParams);
        }
        return artistNavigationPath(artistHandle, '/subscribe', queryParams);
      }

      if (redirect != null) {
        return redirect;
      }

      if (loggedInUser?.artist != null) {
        return artistNavigationPath(loggedInUser.artist.mainLinkValue, '/');
      }

      return ROUTES.VAULTS;
    },
  );

  useEffect(() => {
    if (authError != null) {
      logError({
        action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
        error: authError,
        errorType: ERROR_TYPE.AUTH_ERROR,
        level: 'fatal',
        message: 'We encountered an error creating your profile',
        pillar: PILLARS.AUTH,
        indexedTags: {
          userId: loggedInUser?.id,
        },
        unindexedExtra: {
          source: 'VaultLandingView - SignInForm',
        },
      });
      setErrorText('We encountered an error creating your profile');
    } else if (loginStatus === LoginStatus.LOGGED_IN && loggedInUser != null) {
      const isInOnboarding = loggedInUser.username == null && !code;

      function navigateAuth() {
        const navigateTo = navigationPath({
          artistHandle,
          bottomSheetType,
          isInOnboarding,
          payeeSecretId,
          redirect,
          loggedInUser,
          trackId,
          code,
          showReceiptModal: showReceiptModal,
        });

        navigate(navigateTo);
      }

      if (linkSpotifyParam) {
        linkSpotify().finally(navigateAuth);
      } else if (linkAppleMusicParam) {
        linkAppleMusic().finally(navigateAuth);
      } else {
        navigateAuth();
      }
    }
  }, [
    loginStatus,
    navigate,
    artistHandle,
    loggedInUser,
    authError,
    redirect,
    payeeSecretId,
    openBottomsheet,
    openToast,
    bottomSheetType,
    code,
    trackId,
    navigationPath,
    linkSpotify,
    linkSpotifyParam,
    linkAppleMusic,
    linkAppleMusicParam,
    showReceiptModal,
    receiptId,
    logError,
  ]);

  useEffect(() => {
    const getReceiptId = async () => {
      const fetchedReceiptId = await fetchReceiptId({
        showReceiptModal,
        receiptIdParam,
        artistHandle,
        loggedIn: loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.id,
        eventType,
        releaseCampaignId: sourceReleaseCampaignId,
        source,
      });

      setReceiptId(fetchedReceiptId);
    };

    void getReceiptId();
  }, [
    artistHandle,
    receiptIdParam,
    showReceiptModal,
    eventType,
    sourceReleaseCampaignId,
    source,
    loginStatus,
    loggedInUser,
  ]);

  const signInState = SignInStore.useStore().value;

  const phoneNumber = signInState?.lastActivePhoneNumber ?? null;
  const email = signInState?.lastActiveEmail ?? null;
  const authMethod = signInState?.authMethod ?? 'sms';
  const codeRenabled =
    signInState?.codeRenabled[authMethod === 'sms' ? phoneNumber ?? '_' : email ?? '_'] ?? 1;

  const { loginWithCode: loginWithSms } = useLoginWithSms();
  const { loginWithCode: loginWithEmail } = useLoginWithEmail();

  const loginWithCodeEmailWithStatusUpdate = useStableCallback(
    async ({ code }: { code: string }) => {
      setEmailStatus('submitting-code');
      await loginWithEmail({ code })
        .then(() => {
          setEmailStatus('done');
        })
        .catch(e => {
          setEmailStatus('error');
          throw e;
        });
    },
  );

  const loginWithCode = authMethod === 'sms' ? loginWithSms : loginWithCodeEmailWithStatusUpdate;

  const onCodeInputChange = useStableCallback((value: string) => {
    const newVal = value.replace(/\D/g, '').replace(/\s/g, ''); // Remove non-digit characters

    trackEvent({
      type: EVENTS.INPUT_VERIFICATION_CODE,
      properties: {
        artistHandle: null,
        phone_number: phoneNumber,
        email,
        auth_method: authMethod,
      },
    });

    if (newVal.length === 6) {
      void tryLogin(newVal);
    }

    setErrorText(null);
  });

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const tryLogin = async (code: string): Promise<void> => {
    trackEvent({
      type: EVENTS.RECEIVE_VERIFICATION_CODE,
      properties: {
        artistHandle: null,
        phone_number: phoneNumber,
        email,
        auth_method: authMethod,
      },
    });
    try {
      await loginWithCode({ code });
      // Still loading, need to wait for AuthProvider loginStatus to update
      trackEvent({
        type: EVENTS.SET_VERIFICATION_SUCCESS,
        properties: {
          artistHandle: null,
          phone_number: phoneNumber,
          email,
          auth_method: authMethod,
        },
      });
    } catch (e) {
      trackEvent({
        type: EVENTS.SET_VERIFICATION_ERROR,
        properties: {
          artistHandle: null,
          phone_number: phoneNumber,
          email,
          auth_method: authMethod,
        },
      });
      logError({
        action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
        error: e,
        errorType: ERROR_TYPE.AUTH_ERROR,
        level: 'warning',
        message: 'We encountered an error sending your verification code',
        pillar: PILLARS.AUTH,
        indexedTags: {
          phoneNumber,
        },
        unindexedExtra: {
          source: 'VaultLandingView - SignInForm',
        },
      });
      setErrorText('We encountered an error verifying your code');

      return;
    }
  };

  const onBackClick = useStableCallback(async () => {
    await SignInStore.produceExistingState(
      draft => {
        draft.lastActivePhoneNumber = null;
        draft.lastActiveEmail = null;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
        lastActiveEmail: null,
      },
    );

    if (backToVault && artistHandle) {
      navigate(artistNavigationPath(artistHandle, '/'));
    } else if (window.history.state.idx != null && window.history.state.idx > 0) {
      navigate(ROUTES.SIGN_IN);
    } else {
      navigate('/');
    }
  });

  if ((!phoneNumber && !email) || status === 'initial') {
    return <Navigate to={ROUTES.SIGN_IN} />;
  }

  return (
    <VerifyPageV2
      onChange={onCodeInputChange}
      hasError={errorText != null}
      onBackClick={onBackClick}
      errorText={errorText}
      codeSentDisabledSecondsRemaining={codeSentDisabledSecondsRemaining}
      status={status}
      loginStatus={loginStatus}
      onResendCodeClick={onResendCodeClick}
      isInCooldown={isInCooldown}
    />
  );
};

function VerifyPageV2({
  onChange,
  hasError,
  onBackClick,
  errorText,
  codeSentDisabledSecondsRemaining,
  status,
  loginStatus,
  onResendCodeClick,
  isInCooldown,
}: {
  onChange: (value: string) => void;
  hasError: boolean;
  onBackClick: () => void;
  errorText: string | null;
  codeSentDisabledSecondsRemaining: number;
  status: string;
  loginStatus: LoginStatus;
  onResendCodeClick: ({
    source,
    onSuccess,
    codeSentDisabledSecondsRemaining,
  }: {
    codeSentDisabledSecondsRemaining: number;
    source: string;
    onSuccess?: () => void;
  }) => void;
  isInCooldown: boolean;
}) {
  return (
    <div className="relative flex items-center justify-center h-screen-safe">
      <AuthBox
        header={
          <View className="flex w-full items-center justify-center self-center">
            <View className="flex h-[32px] w-[32px] flex-col items-center justify-center">
              <Logo variant="default" />
            </View>
          </View>
        }
        onBackClick={onBackClick}
        subText={<Text className="font-title text-[22px] font-medium text-white">Verify</Text>}
        userAction={
          <CodeInput
            autoFocus
            length={6}
            placeholder="_"
            onChange={onChange}
            allowedCharacters="numeric"
            hasError={hasError}
            variant="transparent"
            withVaultTheme={false}
          />
        }
        footerInfo={
          <p
            className={twMerge(
              'text-center !text-base-s text-base_text',
              hasError && 'text-destructive300',
              !hasError && 'opacity-80',
            )}
          >
            {hasError ? (
              errorText
            ) : codeSentDisabledSecondsRemaining > 0 && status !== 'submitting-code' ? (
              <>
                Didn't receive the code yet?
                <br />
                <span className="underline">Resend in {codeSentDisabledSecondsRemaining}s</span>
              </>
            ) : (
              <>
                Didn't receive the code yet?
                <br />
                <button
                  onClick={() => {
                    onResendCodeClick({
                      source: 'VaultLandingView - VerifyForm',
                      codeSentDisabledSecondsRemaining,
                    });
                  }}
                  disabled={
                    status === 'sending-code' ||
                    status === 'submitting-code' ||
                    isInCooldown ||
                    loginStatus === LoginStatus.LOADING
                  }
                  className="appearance-none border-none bg-transparent p-0 text-center font-base !text-base-s text-vault_text underline hover:cursor-pointer disabled:opacity-30"
                >
                  {status === 'sending-code'
                    ? 'Sending...'
                    : status === 'submitting-code' || loginStatus === LoginStatus.LOADING
                      ? 'Verifying...'
                      : 'Resend'}
                </button>
              </>
            )}
          </p>
        }
        fill={false}
      />
    </div>
  );
}
