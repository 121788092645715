import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { faEnvelope } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPhone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useSignIn } from '../../hooks/useSignIn';

export function AuthMethodSwitch({ withVaultTheme = false }: { withVaultTheme?: boolean }) {
  const { authMethod, setAuthMethod } = useSignIn();

  return (
    <div
      className={twMerge(
        'flex cursor-pointer select-none flex-row items-center justify-center gap-2 py-[10px]',
        withVaultTheme ? 'text-vault_text/70' : 'text-base_text/70',
      )}
      onClick={() => setAuthMethod(authMethod === 'sms' ? 'email' : 'sms')}
    >
      {authMethod === 'sms' && (
        <motion.div
          initial={{ opacity: 0, y: 28 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="flex flex-row items-center gap-2"
        >
          <FontAwesomeIcon icon={faEnvelope} className="text-[11px]" />
          <p className="text-[12px]">Use email instead</p>
        </motion.div>
      )}
      {authMethod === 'email' && (
        <motion.div
          initial={{ opacity: 0, y: 28 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="flex flex-row items-center gap-2"
        >
          <FontAwesomeIcon icon={faPhone} className="text-[11px]" />
          <p className="text-[12px]">Use phone instead</p>
        </motion.div>
      )}
    </div>
  );
}
