import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { keyBy } from 'lodash-es';
import type GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { gql } from '@soundxyz/gql-string';
import { US_STATE_CODES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { useQuery } from '../../graphql/client';
import { LocationMemberCountsDocument } from '../../graphql/generated';
import type { MassMessageStateFields } from '../../hooks/message/useMassMessageForm';
import { GeocoderResultSchema } from '../../hooks/useSelectLocation';
import { getManyFromList } from '../../utils/arrayUtils';
import { createBatchingInputStore } from '../../utils/batchInput';
import {
  findOrFetchGeocoderResult,
  getCoordinateCacheKey,
  getCountryInfo,
} from '../../utils/mapUtils';
import { pluralizeText } from '../../utils/textUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

gql(/* GraphQL */ `
  query LocationMemberCounts($artistId: String!) {
    memberCountsByCountry(artistId: $artistId) {
      __typename
      ... on QueryMemberCountsByCountrySuccess {
        data {
          isoCountryCode
          isoUsStateCode
          memberCount
        }
      }
    }
    memberCountsByUSState(artistId: $artistId) {
      __typename
      ... on QueryMemberCountsByUSStateSuccess {
        data {
          isoCountryCode
          isoUsStateCode
          memberCount
        }
      }
    }
  }
`);

export function useMemberCountsByLocation({ artistId }: { artistId: string | null | undefined }) {
  const { data: memberCountsByLocation, isLoading } = useQuery(LocationMemberCountsDocument, {
    variables: !!artistId && { artistId },
    staleTime: Infinity,
    select: data => {
      if (
        data.data.memberCountsByCountry.__typename === 'NotAuthorizedError' ||
        data.data.memberCountsByUSState.__typename === 'NotAuthorizedError'
      ) {
        return null;
      }

      const memberCountsByCountry = getManyFromList(
        data.data.memberCountsByCountry.data,
        ({ isoCountryCode, isoUsStateCode, memberCount }) => {
          if (isoCountryCode == null) return null;

          return { isoCountryCode, isoUsStateCode, memberCount };
        },
      );

      const memberCountsByUSState = getManyFromList(
        data.data.memberCountsByUSState.data,
        ({ isoCountryCode, isoUsStateCode, memberCount }) => {
          if (isoCountryCode == null || isoUsStateCode == null) return null;

          return { isoCountryCode, isoUsStateCode, memberCount };
        },
      );

      const memberCountsByLocation = keyBy(
        [...memberCountsByCountry, ...memberCountsByUSState],
        ({ isoCountryCode, isoUsStateCode }) => {
          if (isoUsStateCode != null) {
            return `${isoCountryCode}-${isoUsStateCode}`;
          }

          return isoCountryCode;
        },
      );

      return memberCountsByLocation;
    },
  });

  return { memberCountsByLocation, isLoading };
}

const { useBatchedKey } = createBatchingInputStore({
  chunkLimit: 5,
});

function parseKey(key: string) {
  const [countryKey, address] = key.split('-');

  if (countryKey == null || address == null) {
    return null;
  }

  return { countryKey, address };
}

function getKey({
  countryKey,
  address,
}: {
  countryKey: string | null | undefined;
  address: string | null | undefined;
}) {
  if (countryKey == null || address == null) {
    return null;
  }

  return `${countryKey}-${address}`;
}

function useBatchedCountryInfo({
  key,
  address,
  geocoder,
}: {
  key: string | null | undefined;
  address: string | null | undefined;
  geocoder: google.maps.Geocoder;
}) {
  const keys = useBatchedKey({
    key: getKey({ countryKey: key, address }),
  });

  const keysRef = useRef<string[] | undefined>(keys);

  useEffect(() => {
    keysRef.current = keys;
  }, [keys]);

  const [isLoading, setIsLoading] = useState(false);

  const queryKey = useMemo(() => keys?.join(','), [keys]);

  const [countryInfo, setCountryInfo] = useState<Awaited<ReturnType<typeof getCountryInfo>>>({
    countryInfo: null,
    stateCode: null,
    countryCacheKey: key ?? '',
  });

  useEffect(() => {
    if (key == null || address == null) {
      return;
    }

    setCountryInfo({
      countryInfo: null,
      stateCode: null,
      countryCacheKey: key,
    });
  }, [key, address]);

  useEffect(() => {
    const keys = keysRef.current;

    if (keys == null || keys.length === 0 || key == null || address == null) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const results = await Promise.all(
        getManyFromList(keys, key => {
          const parsedKey = parseKey(key);
          if (parsedKey == null) {
            return null;
          }

          const { countryKey, address } = parsedKey;

          return getCountryInfo({ geocoder, countryCacheKey: countryKey, type: 'REGION', address });
        }),
      );

      setIsLoading(false);
      const result = results.find(({ countryCacheKey }) => countryCacheKey === key);

      if (result != null) {
        setCountryInfo(result);
      }
    })();
  }, [address, geocoder, key, queryKey]);

  return { isLoading, ...countryInfo };
}

export type GoogleLocationRowProps = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<Parameters<typeof GooglePlacesAutocomplete>['0']['selectProps']>['components']
      >
    >['Option']
  >['defaultProps']
>;

export const GoogleLocationRow = memo(function GoogleLocationRow({
  label,
  data: passedData,
  setValue,
  geocoder,
  artistId,
}: GoogleLocationRowProps & {
  geocoder: google.maps.Geocoder;
  artistId: string | null | undefined;
}) {
  const { success, data } = GeocoderResultSchema.safeParse(passedData);

  const { isLoading, countryInfo, stateCode } = useBatchedCountryInfo({
    geocoder,
    address: label,
    key: label,
  });

  const { memberCountsByLocation, isLoading: isLoadingMemberCounts } = useMemberCountsByLocation({
    artistId,
  });

  const memberCount = useMemo(() => {
    if (countryInfo == null) {
      return null;
    }

    const key = stateCode != null ? `${countryInfo.code}-${stateCode}` : countryInfo.code;

    return memberCountsByLocation?.[key]?.memberCount;
  }, [countryInfo, memberCountsByLocation, stateCode]);

  const countryFlag = countryInfo?.flag;

  const subtext = useMemo(() => {
    if (data?.value.types.includes('country')) {
      return `Country${
        memberCount
          ? ` • ${memberCount} ${pluralizeText({
              count: memberCount ?? 0,
              text: 'member',
            })}`
          : ''
      }`;
    }

    if (data?.value.types.includes('administrative_area_level_1') && stateCode != null) {
      return `State${
        memberCount
          ? ` • ${memberCount} ${pluralizeText({
              count: memberCount ?? 0,
              text: 'member',
            })}`
          : ''
      }`;
    }

    if (data?.value.types.includes('locality') || data?.value.types.includes('sublocality')) {
      return 'City';
    }

    return 'Region';
  }, [data?.value.types, memberCount, stateCode]);

  if (
    label == null ||
    setValue == null ||
    !success ||
    data == null ||
    isLoading ||
    isLoadingMemberCounts
  ) {
    return null;
  }

  return (
    <View
      onClick={() => {
        setValue(data, 'select-option');
      }}
      className="box-border flex w-full cursor-pointer flex-row items-center justify-start gap-3 border-0 border-b border-solid border-vault_text/10 px-3 py-4"
    >
      {countryFlag != null ? (
        <Text className="text-[20px]">{countryFlag}</Text>
      ) : (
        <View className="w-[25px]" />
      )}
      <View className="flex flex-1 flex-shrink flex-col gap-1">
        <Text className="line-clamp-1 font-title text-[16px] font-medium text-vault_text">
          {data.value.description}
        </Text>
        <Text className="font-base text-[14px] font-normal text-vault_text/60">{subtext}</Text>
      </View>
    </View>
  );
});

export function InitialLocationRow({
  location,
  geocoder,
}: {
  location: MassMessageStateFields['locationsV2'][number];
  geocoder: google.maps.Geocoder;
}) {
  const [subtext, setSubtext] = useState<string>('');

  useEffect(() => {
    if (location.type === 'REGION') {
      if (location.isoUsStateCode == null) {
        setSubtext('Country');
      } else {
        setSubtext('State');
      }
      return;
    }

    (async () => {
      const geocoderResults = await findOrFetchGeocoderResult({
        geocoder,
        type: 'COORDINATE',
        coordinate: {
          lat: location.latitude,
          lng: location.longitude,
        },
        cacheKey: getCoordinateCacheKey({
          latitude: location.latitude,
          longitude: location.longitude,
        }),
      });

      if (geocoderResults == null) {
        return;
      }

      const { address_components } = geocoderResults;

      const isCity = address_components.some(
        component =>
          component.types.includes('locality') || component.types.includes('sublocality'),
      );

      if (isCity) {
        setSubtext('City');
      }

      setSubtext('Region');
    })();
  }, [geocoder, location]);

  const { countryFlag, displayName } = useMemo(() => {
    const country = COUNTRY_CODES.find(({ code }) => code === location.isoCountry);

    if (country == null) {
      return {
        countryFlag: null,
        displayName: null,
      };
    }

    if (location.type === 'REGION') {
      if (location.isoUsStateCode == null) {
        return {
          countryFlag: country.flag,
          displayName: country.name,
        };
      }

      const state = US_STATE_CODES.find(({ code }) => code === location.isoUsStateCode);

      if (state == null) {
        return {
          countryFlag: country.flag,
          displayName: country.name,
        };
      }

      return {
        countryFlag: country.flag,
        displayName: `${state.name}, ${country.name}`,
      };
    }

    return {
      countryFlag: country.flag,
      displayName: location.displayName,
    };
  }, [location]);

  if (countryFlag == null || displayName == null) {
    return null;
  }

  return (
    <View className="mt-4 box-border flex w-full cursor-pointer flex-row items-center justify-start gap-3 rounded-lg bg-vault_text/10 px-3 py-4">
      {countryFlag != null ? (
        <Text className="text-[20px]">{countryFlag}</Text>
      ) : (
        <View className="w-[25px]" />
      )}
      <View className="flex flex-1 flex-shrink flex-col gap-1">
        <Text className="line-clamp-1 font-title text-[16px] font-medium text-vault_text">
          {displayName}
        </Text>
        <Text className="font-base text-[14px] font-normal text-vault_text/60">{subtext}</Text>
      </View>
    </View>
  );
}
