import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { GlobalAuthContext } from '../contexts/AuthContext';
import { fetchQuery } from '../graphql/client';
import { ContentByIdDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  query ContentById($vaultContentId: UUID!, $asArtistId: UUID) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename

      id
      title
      caption
      parentVaultContentId
      linkValue
      downloadEnabled

      featureAccess {
        feature {
          __typename
        }
      }

      vault {
        id
        type
        messageChannelId
        artist: artistProfile {
          id
          name
          linkValue
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
          }
          membershipCardImage {
            id
            membershipCardImageUrl: imageOptimizedUrl
          }
        }
        price
        activeSubscription {
          id
          ...ActiveSubscriptionFeatures
        }
        isUserArtistAdmin
      }

      commentMessageCount
      commentPinnedMessage {
        id
        content
        ...ContentCommentRow
      }
      commentCaptionMessage {
        id
        content
        ...ContentCommentRow
      }

      ... on VaultVideo {
        duration
      }

      ... on VaultTrack {
        id

        createdAt
        downloadEnabled

        duration

        title
        thumbnailMedia {
          id
          imageOptimizedUrl(input: { width: 400, height: 400 })
          imageSmallUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        blurredThumbnailMediaUrl
        snippetVideo {
          id
          url: cdnUrl
        }
        freeTierSnippet {
          id
          startAt
          endAt
        }
        featureAccess {
          feature {
            __typename
          }
        }
        normalizedPeaks
        isFullVersionAvailable
      }
    }
    getSignedTrackContent(trackContentId: $vaultContentId) {
      __typename
      ... on QueryGetSignedTrackContentSuccess {
        data
      }
      ... on Error {
        message
      }
    }
    getSignedTrackSnippetContent(trackContentId: $vaultContentId) {
      __typename
      ... on QueryGetSignedTrackSnippetContentSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

export async function queryAudioFromTrack({
  trackId,
  editSnippet,
}: {
  trackId: string;
  editSnippet?: boolean;
}) {
  if (!isUUID4(trackId)) return null;

  const {
    data: { vaultContentById, getSignedTrackContent, getSignedTrackSnippetContent },
  } = await fetchQuery(ContentByIdDocument, {
    variables: {
      vaultContentId: trackId,
      asArtistId: GlobalAuthContext.loggedInUser?.artist?.id,
    },
    staleTime: 5000,
  });

  if (!vaultContentById || vaultContentById.__typename !== 'VaultTrack') return null;

  const isFullVersionAvailable = editSnippet ? true : vaultContentById.isFullVersionAvailable;

  const featureAccessList = vaultContentById?.featureAccess || [];
  const hasFreeAccess = featureAccessList.some(
    access => access.feature.__typename === 'FreeVaultContent',
  );

  const trackWithSignature =
    getSignedTrackContent.__typename === 'QueryGetSignedTrackContentSuccess'
      ? getSignedTrackContent.data
      : null;

  const snippetWithSignature =
    getSignedTrackSnippetContent?.__typename === 'QueryGetSignedTrackSnippetContentSuccess'
      ? getSignedTrackSnippetContent.data
      : null;

  const cdnUrl = !isFullVersionAvailable
    ? hasFreeAccess
      ? trackWithSignature
      : snippetWithSignature
    : trackWithSignature;

  if (!cdnUrl) return null;

  const trackUrl = new URL(cdnUrl);

  if (
    IS_FINAL_DEPLOYMENT &&
    (trackUrl.pathname.endsWith('playlist.m3u8') || trackUrl.pathname.endsWith('trimmed.m3u8'))
  ) {
    trackUrl.hostname = window.location.hostname;
  }

  return {
    cdnUrl: trackUrl.href,
    ...vaultContentById,
  };
}
