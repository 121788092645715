import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPalette } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import type { CustomizeVaultBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { CustomizeProfile, CustomizeVaultSkeleton } from '../menu/customize-vault/CustomizeProfile';
import { CustomizeVaultTheme } from '../menu/customize-vault/CustomizeVaultTheme';
import { type CustomizeMenuValidationSchema } from '../menu/customize-vault/schema';
import { useCustomize } from '../menu/customize-vault/useCustomize';

export function CustomizeVaultBottomsheet({
  form,
  onClose,
  vaultId,
  activeTab,
  setActiveTab,
  onSubmit: onSubmitParent,
}: CustomizeVaultBottomsheetProps & { onSubmit: () => void }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = form;

  const { accentColor: formAccentColor, backgroundColor: formBackgroundColor } = useWatch({
    control,
  });

  const {
    accents,
    isActionPending,
    isCustomizing,
    isError,
    isLoading,
    refetchArtistProfile,
    refetchVault,
    setActionPending,
    setTemporaryLogoUrl,
    setTemporaryProfileImageUrl,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    vault,
    onSubmit: onSubmitCustom,
  } = useCustomize({ vaultId });

  const onSubmit = useCallback(
    async (props: CustomizeMenuValidationSchema) => {
      onSubmitCustom({ props, onSubmitParent });
    },
    [onSubmitCustom, onSubmitParent],
  );

  if (!vaultId) {
    return null;
  }

  return (
    <View className="relative box-border flex w-full flex-col items-center gap-7 pb-24">
      <View className="sticky -top-[10px] z-above4 box-border flex w-full flex-row items-center justify-between bg-black pb-7 pt-4">
        <div className="flex-1">
          <Button
            leadingIcon={faXmark}
            iconOnly
            onClick={onClose}
            disabled={isActionPending}
            label="Close"
            className="text-[24px] text-white"
            disabledClassName="opacity-50 cursor-not-allowed"
          />
        </div>

        <div className="flex-1 text-center">
          <Text className="font-title text-title-m">Customize</Text>
        </div>

        <div className="flex flex-1 justify-end">
          <Button
            buttonType="submit"
            label=""
            labelComponent={
              <Text className="font-base text-base-l font-medium text-[#E3F41D]">Save</Text>
            }
            onClick={handleSubmit(onSubmit)}
            disabled={isActionPending}
            loading={isSubmitting}
            disabledClassName="opacity-50"
          />
        </div>
      </View>

      <View className="mb-6 flex w-full items-center justify-center gap-2">
        <Button
          leadingIcon={faUser}
          label="Profile"
          className={twMerge(
            'h-[44px] w-1/2 rounded-full px-4 py-3 text-white',
            activeTab === 'profile'
              ? 'bg-base700'
              : 'border border-solid border-base700 bg-transparent opacity-30',
          )}
          onClick={() => setActiveTab('profile')}
          disabled={isActionPending}
        />
        <Button
          leadingIcon={faPalette}
          label="Theme"
          className={twMerge(
            'h-[44px] w-1/2 rounded-full px-4 py-3 text-white',
            activeTab === 'theme'
              ? 'bg-base700'
              : 'border border-solid border-base700 bg-transparent opacity-30',
          )}
          onClick={() => setActiveTab('theme')}
          disabled={isActionPending}
        />
      </View>

      {isError ? (
        <ErrorView
          onRetryClick={() => {
            refetchVault();
            refetchArtistProfile();
          }}
          withVaultTheme={false}
          loggingType="customize_vault_menu"
        />
      ) : isLoading || vault == null ? (
        <CustomizeVaultSkeleton />
      ) : (
        <>
          <CustomizeVaultTheme
            setActionPending={setActionPending}
            isVisible={activeTab === 'theme'}
            setTemporaryLogoUrl={setTemporaryLogoUrl}
            temporaryLogoUrl={temporaryLogoUrl}
            vault={vault}
            isCustomizing={isCustomizing}
            setValue={setValue}
            isSubmitting={isSubmitting}
            accents={accents}
            formBackgroundColor={formBackgroundColor}
            formAccentColor={formAccentColor}
          />

          <CustomizeProfile
            isVisible={activeTab === 'profile'}
            setTemporaryProfileImageUrl={setTemporaryProfileImageUrl}
            temporaryProfileImageUrl={temporaryProfileImageUrl}
            setActionPending={setActionPending}
            vault={vault}
            isCustomizing={isCustomizing}
            isSubmitting={isSubmitting}
            setValue={setValue}
            errors={errors}
            register={register}
          />
        </>
      )}
    </View>
  );
}
