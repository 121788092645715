import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCreditCard } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useQuery } from '../../graphql/client';
import { ReferralCodeInfoFragmentDoc, TierTypename } from '../../graphql/generated';
import { ArtistSubscribeViewFragmentDoc, GetPaymentMethodsDocument } from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useSubscribeToVault } from '../../hooks/useSubscribeToVault';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { StripeProvider, useStripeContext } from '../../providers/StripeProvider';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { validateEmail } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Item, Select } from '../forms/Select';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { PaymentMethodRow } from '../payment/PaymentMethodRow';
import { SubscribeHeader } from '../subscribe/SubscribeHeader';

gql(/* GraphQL */ `
  fragment artistSubscribeView on Artist {
    id
    mainVault {
      id
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
    }
    ...ArtistSubscribeHeaderInfo
  }
`);

type Props = {
  artist: FragmentType<ArtistSubscribeViewFragmentDoc>;
  artistHandle: string;
  code: string | null;
  referralCodeInfo: FragmentType<ReferralCodeInfoFragmentDoc> | null | undefined;
};

const SubscribeView: FC<Props> = ({ artist, artistHandle, code, referralCodeInfo }) => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const [selectValue, setSelectValue] = useState('add_new');
  const { data: paymentMethods, isLoading } = useQuery(GetPaymentMethodsDocument, {
    staleTime: 0,
    select(data) {
      return data.data.paymentMethods;
    },
  });
  const [isOpen, setIsOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const artistFragment = getFragment(ArtistSubscribeViewFragmentDoc, artist);
  const referralCodeFragment = referralCodeInfo
    ? getFragment(ReferralCodeInfoFragmentDoc, referralCodeInfo)
    : null;

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwner = !!ownedArtist;
  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: artistFragment.mainVault.activeSubscription,
    isOwner,
  });

  if (activeSubscriptionFeatures?.tier === TierTypename.PaidTier) {
    return (
      <Navigate
        to={
          redirect === 'onboarding'
            ? `${ROUTES.ONBOARDING}/username?artistHandle=${artistHandle}`
            : artistNavigationPath(artistHandle, '/')
        }
      />
    );
  }

  useEffect(() => {
    if (referralCodeFragment && !referralCodeFragment.isActive) {
      openToast({
        text: 'This code is no longer valid',
        variant: 'success',
      });
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [artistHandle, navigate, openToast, referralCodeFragment]);

  useEffect(() => {
    if (paymentMethods?.[0] && paymentMethods?.length > 0) {
      setSelectValue(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  const showReferralClaim =
    !!code &&
    referralCodeFragment != null &&
    referralCodeFragment.freeMonths > 0 &&
    referralCodeFragment.isActive;

  return (
    <View className="box-border flex w-full flex-col items-center px-4">
      <SubscribeHeader
        artist={artistFragment}
        subscriptionType={showReferralClaim ? 'referral_claim' : 'subscribe'}
        freeMonths={referralCodeFragment?.freeMonths}
      />
      {isLoading ? (
        <View className="mt-[10px] box-border h-[60px] w-full px-1">
          <LoadingSkeleton className="h-full w-full rounded-xl bg-vault_text/10" />
        </View>
      ) : (
        <Select
          value={selectValue}
          onValueChange={value => setSelectValue(value)}
          className={twMerge(
            'mx-0 mt-3 w-full rounded-xl px-7 py-0',
            'group bg-vault_text/10 text-vault_text hover:bg-vault_text/10 focus:bg-vault_text/10',
          )}
          contentClassName="bg-vault_background px-3 backdrop-blur-2xl"
          onOpenChange={setIsOpen}
          disabled={paymentMethods?.length === 0}
        >
          <Item
            value="add_new"
            className={twMerge(
              'box-border w-full rounded-xl border-none py-0 pl-7',
              'bg-vault_text/10 hover:bg-vault_text/20',
              selectValue === 'add_new' && 'group-only-of-type:bg-transparent',
            )}
            dropDownClassName="bg-transparent hover:bg-transparent focus:bg-transparent"
          >
            <Button
              label="Add new card"
              type="secondary"
              leadingIcon={faCreditCard}
              className="bg-transparent px-0 py-[20px] font-title text-[16px]/[20px] font-medium text-vault_text"
            />
          </Item>
          {paymentMethods?.map(method => (
            <Item
              value={method.id}
              key={method.id}
              className={twMerge(
                'box-border w-full rounded-xl border-none py-0 pl-7',
                'bg-vault_text/10 hover:bg-vault_text/20',
                selectValue === method.id && 'group-only-of-type:bg-transparent',
              )}
              dropDownClassName="bg-transparent hover:bg-transparent focus:bg-transparent"
            >
              <PaymentMethodRow
                paymentMethod={method}
                className="pl-0 text-vault_text"
                withVaultTheme
              />
            </Item>
          ))}
        </Select>
      )}
      <StripeProvider
        vaultId={artistFragment.mainVault.id}
        referralCode={showReferralClaim ? code : null}
        withVaultTheme
      >
        <SubscriptionForm
          artistHandle={artistHandle}
          vaultId={artistFragment.mainVault.id}
          artistId={artistFragment.id}
          paymentMethodId={selectValue}
          isOpen={isOpen}
          showReferralClaim={showReferralClaim}
          freeMonths={referralCodeFragment?.freeMonths}
        />
      </StripeProvider>
    </View>
  );
};

const SubscriptionForm: FC<{
  artistHandle: string;
  vaultId: string;
  paymentMethodId: string;
  artistId: string;
  isOpen: boolean;
  showReferralClaim: boolean;
  freeMonths?: number;
}> = ({
  artistHandle,
  vaultId,
  paymentMethodId,
  artistId,
  isOpen,
  showReferralClaim,
  freeMonths,
}) => {
  const { loggedInUser } = useAuthContext();
  const elements = useElements();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const isMounted = useIsMounted();
  const { subscriptionId, clientSecret } = useStripeContext();
  const { subscribe } = useSubscribeToVault();
  const { openToast } = useToast();

  const [stripePaymentReady, setStripePaymentReady] = useState(false);
  const buttonTitle = showReferralClaim && freeMonths ? 'Unlock All Access Pass' : 'Subscribe';

  const onClick = async () => {
    if (!stripe || !elements || isOpen) {
      return;
    }

    if (loggedInUser?.email == null && (!isValidEmail || email.length == 0)) {
      setIsValidEmail(false);
      return;
    }

    setIsLoading(true);

    const handleSubscription = async (newCard: boolean, isFreeTrial: boolean) => {
      if (subscriptionId) {
        subscribe({
          vaultId,
          linkValue: artistHandle,
          subscriptionId,
          onSuccess: () => handleSubscriptionSuccess(vaultId, newCard, isFreeTrial),
        });
      }
    };

    const handleSubscriptionSuccess = (vaultId: string, newCard: boolean, isFreeTrial: boolean) => {
      trackEvent({
        type: EVENTS.SUBSCRIPTION_SUCCESS,
        properties: { vaultId, artistId, newCard, isFreeTrial },
      });
      openToast({
        text: "Welcome! You've unlocked this vault",
        variant: 'success',
      });
      setIsLoading(false);
    };

    if (paymentMethodId === 'add_new') {
      if (clientSecret?.startsWith('seti_')) {
        await elements.submit();
        const { error } = await stripe.confirmSetup({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.origin}/${artistHandle}/success?vaultId=${vaultId}${
              subscriptionId != null ? `&subscriptionId=${subscriptionId}` : ''
            }`,
          },
        });

        if (error) {
          setIsLoading(false);
          openToast({
            text: 'There was an error processing your payment. Please try again.',
            variant: 'error',
          });
          return;
        }

        handleSubscription(true, true);
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,

        redirect: 'if_required',

        confirmParams: {
          // Should not happen
          return_url: `${window.location.origin}/${artistHandle}/success?vaultId=${vaultId}${
            subscriptionId != null ? `&subscriptionId=${subscriptionId}` : ''
          }`,
          receipt_email: loggedInUser?.email ?? email,
        },
      });

      if (isMounted.current) {
        if (error != null) {
          setIsLoading(false);
          openToast({
            text: 'Your payment could not be confirmed. Please review your payment details',
            variant: 'error',
          });
        } else {
          handleSubscription(true, false);
        }
      }
    } else if (clientSecret != null) {
      if (clientSecret.startsWith('seti_')) {
        const { error } = await stripe.confirmSetup({
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            payment_method: paymentMethodId,
            return_url: `${window.location.origin}/${artistHandle}/success?vaultId=${vaultId}${
              subscriptionId != null ? `&subscriptionId=${subscriptionId}` : ''
            }`,
          },
        });

        if (error) {
          setIsLoading(false);
          openToast({
            text: 'There was an error processing your payment. Please try again.',
            variant: 'error',
          });
          return;
        }

        handleSubscription(false, true);
        return;
      }

      const { error } = await stripe.confirmPayment({
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          payment_method: paymentMethodId,
          // Should not happen
          return_url: `${window.location.origin}/${artistHandle}/success?vaultId=${vaultId}${
            subscriptionId != null ? `&subscriptionId=${subscriptionId}` : ''
          }`,
          receipt_email: loggedInUser?.email ?? email,
        },
      });

      if (isMounted.current) {
        if (error != null) {
          setIsLoading(false);
          openToast({
            text: 'Your payment could not be confirmed. Please review your payment details',
            variant: 'error',
          });
        } else {
          handleSubscription(false, false);
        }
      }
    }
  };

  const showSubButton = paymentMethodId === 'add_new' ? stripePaymentReady : true;

  const KILLSWITCH_SUBSCRIPTION = useBetterGate('KILLSWITCH_SUBSCRIPTION') === 'enabled';

  return (
    <View className="no-scrollbar relative my-[20px] box-border w-full overflow-y-scroll">
      <View className="box-border flex w-full flex-col items-center">
        {paymentMethodId === 'add_new' && (
          <View className="box-border w-full px-1">
            <PaymentElement
              className="mb-4 w-full"
              onReady={() => {
                setStripePaymentReady(true);
              }}
              options={{
                terms: { card: 'never', applePay: 'never', googlePay: 'never' },
                layout: {
                  type: 'tabs',
                  defaultCollapsed: false,
                },
              }}
            />
          </View>
        )}
        {paymentMethodId === 'add_new' && !stripePaymentReady && (
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            className="inline-block animate-spin rounded-full font-medium text-vault_text/50"
          />
        )}
        {loggedInUser?.email == null && (
          <View className="mb-6 w-full text-vault_text">
            <Text className="mb-1 w-full text-left font-base text-[16px] font-normal">Email</Text>
            <input
              className={twMerge(
                'box-border w-full border-x-0 border-b-1.5 border-t-0 border-solid px-3 py-[10px] text-[16px] font-light outline-none focus:outline-none',
                'border-vault_text/5 bg-vault_background text-vault_text',
              )}
              placeholder="Email"
              value={email}
              onChange={e => {
                e.preventDefault();

                setEmail(e.target.value.trim());
              }}
              onBlur={() =>
                email.length === 0 ? setIsValidEmail(false) : setIsValidEmail(validateEmail(email))
              }
              onFocus={() => setIsValidEmail(true)}
            />
            {!isValidEmail && (
              <Text className="mt-1 w-full text-left font-base text-[13px] font-extralight text-vault_text/50">
                Please provide a valid email
              </Text>
            )}
          </View>
        )}
        {!KILLSWITCH_SUBSCRIPTION && showSubButton && (
          <Button
            label={buttonTitle}
            type="primary-themed"
            className="mb-4 w-full text-[16px]/[20px]"
            loading={isLoading}
            onClick={onClick}
            event={{ type: EVENTS.SUBSCRIBE, properties: { vaultId, artistId } }}
          />
        )}
        {KILLSWITCH_SUBSCRIPTION && (
          <Button
            label="This artist cannot be subscribed to at this time. Please try again later"
            type="secondary"
            className="my-4 w-full bg-vault_text/10 text-vault_text"
            disabled
          />
        )}
        <View className="w-[100%]">
          <Text className="w-full justify-center text-center font-base !text-base-m text-vault_text/50">
            By confirming your subscription, you allow Vault.fm to charge you for future payments in
            accordance with their terms. You can always cancel your subscription.
          </Text>
        </View>
      </View>
    </View>
  );
};

const SkeletonSubscribeView: FC = () => {
  return (
    <View className="box-border flex w-full max-w-[400px] flex-col items-center px-4">
      <LoadingSkeleton className="mb-5 aspect-square w-[150px] rounded-xl bg-vault_text/10" />
      <LoadingSkeleton className="mb-5 h-[20px] w-[175px] rounded-xl bg-vault_text/10" />
      <LoadingSkeleton className="mb-5 h-[30px] w-[175px] rounded-xl bg-vault_text/10" />
    </View>
  );
};

export { SubscribeView, SkeletonSubscribeView };
