/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string; }
  /** Hex color code string */
  HexColor: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown; }
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: string; output: string; }
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: string; output: string; }
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: number; output: number; }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: number; output: number; }
  /** Semantic version string */
  SemanticVersion: { input: string; output: string; }
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: number; output: number; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: string; output: string; }
  /** UUID v4 */
  UUID: { input: string; output: string; }
  /** Represents NULL values */
  Void: { input: null; output: null; }
};

/** Scheduled Event Location Target Input */
export type AnnouncementLocationTargetInput = {
  /** ISO country code */
  isoCountry: IsoCountry | '%future added value';
  /** Optional ISO US state code. */
  isoUsStateCode?: InputMaybe<IsousState | '%future added value'>;
};

/** Scheduled Event Location Target Input - supports both region-based and geo-based targeting */
export type AnnouncementLocationTargetV2Input = {
  /** [GEO] Display name of the location */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** ISO country code */
  isoCountry: IsoCountry | '%future added value';
  /** [REGION] Optional ISO US state code */
  isoUsStateCode?: InputMaybe<IsousState | '%future added value'>;
  /** [GEO] Latitude of the center point of the location */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** [GEO] Longitude of the center point of the location */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** [GEO] Radius in miles from the center point of the location */
  radiusMiles?: InputMaybe<Scalars['Float']['input']>;
  /** Type of location target */
  type: AnnouncementLocationTargetV2Type | '%future added value';
};

/** Type of announcement location target */
export const AnnouncementLocationTargetV2Type = {
  Geo: 'GEO',
  Region: 'REGION'
} as const;

export type AnnouncementLocationTargetV2Type = typeof AnnouncementLocationTargetV2Type[keyof typeof AnnouncementLocationTargetV2Type];
/** Announcement source (where an announcement is created/sourced from) input */
export type AnnouncementSourceInput = {
  /** ID of the source object */
  sourceId: Scalars['String']['input'];
  /** Source type of the announcement */
  sourceType: AnnouncementSourceType | '%future added value';
};

/** Type of announcement source */
export const AnnouncementSourceType = {
  ArtistEvent: 'ARTIST_EVENT',
  VaultContentFolder: 'VAULT_CONTENT_FOLDER',
  VaultContentMedia: 'VAULT_CONTENT_MEDIA',
  VaultContentTrack: 'VAULT_CONTENT_TRACK'
} as const;

export type AnnouncementSourceType = typeof AnnouncementSourceType[keyof typeof AnnouncementSourceType];
/** Status of the apple music presave */
export const AppleMusicPresaveStatus = {
  Completed: 'COMPLETED',
  Pending: 'PENDING'
} as const;

export type AppleMusicPresaveStatus = typeof AppleMusicPresaveStatus[keyof typeof AppleMusicPresaveStatus];
/** Role of an artist admin */
export const ArtistAdminRole = {
  Admin: 'ADMIN',
  Artist: 'ARTIST'
} as const;

export type ArtistAdminRole = typeof ArtistAdminRole[keyof typeof ArtistAdminRole];
/** Type of artist event */
export const ArtistEventType = {
  ReleaseCampaign: 'RELEASE_CAMPAIGN',
  RsvpEvent: 'RSVP_EVENT'
} as const;

export type ArtistEventType = typeof ArtistEventType[keyof typeof ArtistEventType];
/** Type of artist membership receipt */
export const ArtistMembershipReceiptTypenames = {
  ArtistMembershipPlayStreamReceipt: 'ArtistMembershipPlayStreamReceipt',
  ArtistMembershipPresaveReceipt: 'ArtistMembershipPresaveReceipt',
  ArtistMembershipRsvpEventReceipt: 'ArtistMembershipRsvpEventReceipt',
  ArtistMembershipUnknownReceipt: 'ArtistMembershipUnknownReceipt'
} as const;

export type ArtistMembershipReceiptTypenames = typeof ArtistMembershipReceiptTypenames[keyof typeof ArtistMembershipReceiptTypenames];
/** Type of artist phone number to be assigned */
export const ArtistPhoneNumberType = {
  Local: 'LOCAL',
  TollFree: 'TOLL_FREE'
} as const;

export type ArtistPhoneNumberType = typeof ArtistPhoneNumberType[keyof typeof ArtistPhoneNumberType];
/** Artist socials input */
export type ArtistSocialsInput = {
  /** Custom website URL */
  customWebsiteUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Instagram handle */
  instagramHandle?: InputMaybe<Scalars['String']['input']>;
  /** Spotify profile URL */
  spotifyUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Tiktok handle */
  tiktokHandle?: InputMaybe<Scalars['String']['input']>;
};

/** Status of an artist */
export const ArtistStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Test: 'TEST'
} as const;

export type ArtistStatus = typeof ArtistStatus[keyof typeof ArtistStatus];
/** Type of artist user settings feature */
export const ArtistUserSettingsFeature = {
  DmFromArtist: 'DM_FROM_ARTIST',
  DmFromUser: 'DM_FROM_USER',
  GroupChatMessage: 'GROUP_CHAT_MESSAGE',
  GroupChatMessageFromArtist: 'GROUP_CHAT_MESSAGE_FROM_ARTIST',
  NewVaultContent: 'NEW_VAULT_CONTENT'
} as const;

export type ArtistUserSettingsFeature = typeof ArtistUserSettingsFeature[keyof typeof ArtistUserSettingsFeature];
/** Details of the splits to be assigned */
export type AssignSplitDetailsInput = {
  /** Payee email to pay out this split */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier of the split. Expected to be a just-created random UUID */
  id: Scalars['UUID']['input'];
  /** Name of the payee */
  name: Scalars['String']['input'];
  /** Payee ID to pay out this split. Required if emailAddress is not provided */
  payeeId?: InputMaybe<Scalars['String']['input']>;
  /** Amount of the split in basis points */
  percentBps: Scalars['Int']['input'];
  /** Role of the payee */
  role: Scalars['String']['input'];
};

export type AuthMethodInput = {
  /** Email address (Either email or phone must be provided) */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Phone number (Either email or phone must be provided) */
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Multi-part part information */
export type CompletedMultipartUploadPart = {
  /** Opaque identifier of the part data */
  etag: Scalars['String']['input'];
  /** Individual part number identifier */
  partNumber: Scalars['Int']['input'];
};

/** Input type for connecting Spotify account */
export type ConnectSpotifyInput = {
  /** Spotify authorization code */
  code: Scalars['String']['input'];
  /** Spotify redirect URI */
  redirectUri: Scalars['String']['input'];
};

/** Update info about a given device */
export type DevicePlatformInfoInput = {
  /** App build */
  appBuild: Scalars['Int']['input'];
  /** Version identifier */
  appVersion: Scalars['String']['input'];
  /** Device type identifier */
  deviceType: Scalars['String']['input'];
  /** Platform identifier */
  platform: Scalars['String']['input'];
  /** Device timezone identifier */
  timezone: Scalars['String']['input'];
};

/** Type of external account, either card or bank account */
export const ExternalAccountType = {
  BankAccount: 'BANK_ACCOUNT',
  Card: 'CARD'
} as const;

export type ExternalAccountType = typeof ExternalAccountType[keyof typeof ExternalAccountType];
/** Feature type names */
export const FeatureTypename = {
  ChatRead: 'ChatRead',
  ChatSendAudioAttachments: 'ChatSendAudioAttachments',
  ChatSendImageAttachments: 'ChatSendImageAttachments',
  ChatSendVideoAttachments: 'ChatSendVideoAttachments',
  ChatWrite: 'ChatWrite',
  DmRead: 'DMRead',
  DmWrite: 'DMWrite',
  FreeScheduledEvent: 'FreeScheduledEvent',
  FreeVaultContent: 'FreeVaultContent',
  PaidScheduledEvent: 'PaidScheduledEvent',
  PaidVaultContent: 'PaidVaultContent',
  TrackCommentsRead: 'TrackCommentsRead',
  TrackCommentsWrite: 'TrackCommentsWrite',
  UnknownFeature: 'UnknownFeature',
  UserReferrals: 'UserReferrals'
} as const;

export type FeatureTypename = typeof FeatureTypename[keyof typeof FeatureTypename];
export type GifAttachmentInput = {
  /** aspect ratio of the gif. */
  aspectRatio: Scalars['Float']['input'];
  /** Unique identifier of the gif. */
  id: Scalars['String']['input'];
  /** title of the gif. */
  title: Scalars['String']['input'];
  /** url of the gif. */
  url: Scalars['String']['input'];
};

export const GrantAuthAccessType = {
  Artist: 'ARTIST',
  BandMember: 'BAND_MEMBER',
  Manager: 'MANAGER'
} as const;

export type GrantAuthAccessType = typeof GrantAuthAccessType[keyof typeof GrantAuthAccessType];
/** ISO 3166-1 alpha-2 country code */
export const IsoCountry = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  An: 'AN',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Xk: 'XK',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW'
} as const;

export type IsoCountry = typeof IsoCountry[keyof typeof IsoCountry];
/** ISO 3166-2:US alpha-2 state code */
export const IsousState = {
  Ak: 'AK',
  Al: 'AL',
  Ar: 'AR',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Ga: 'GA',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Va: 'VA',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY'
} as const;

export type IsousState = typeof IsousState[keyof typeof IsousState];
export type ImageOptimizedInput = {
  /** Height for the image transformation. If neither width nor height is specified image is not resized */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Quality of the image transformation. Value must be between 1 and 100 */
  quality?: Scalars['Int']['input'];
  /** Width for the image transformation. If neither width nor height is specified image is not resized */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Possible status of a link */
export const LinkStatus = {
  Active: 'ACTIVE',
  Redirect: 'REDIRECT',
  Reserved: 'RESERVED',
  Unknown: 'UNKNOWN'
} as const;

export type LinkStatus = typeof LinkStatus[keyof typeof LinkStatus];
/** Type of a link */
export const LinkType = {
  Artist: 'ARTIST',
  Username: 'USERNAME'
} as const;

export type LinkType = typeof LinkType[keyof typeof LinkType];
/** Location place input used for user profile updates */
export type LocationPlaceInput = {
  /** The id of the new location place */
  googlePlaceId: Scalars['String']['input'];
  /** The label of the new location place */
  googlePlaceInputLabel: Scalars['String']['input'];
};

/** Type of media entity, either Images or Audio */
export const MediaType = {
  Audio: 'AUDIO',
  DefaultAvatar: 'DEFAULT_AVATAR',
  Image: 'IMAGE',
  Recording: 'RECORDING',
  Unknown: 'UNKNOWN',
  Video: 'VIDEO'
} as const;

export type MediaType = typeof MediaType[keyof typeof MediaType];
/** Type of media entity, either Images or Audio */
export const MediaTypeInput = {
  Audio: 'AUDIO',
  Image: 'IMAGE',
  Recording: 'RECORDING',
  Video: 'VIDEO'
} as const;

export type MediaTypeInput = typeof MediaTypeInput[keyof typeof MediaTypeInput];
/** Usage type of the media */
export const MediaUsageType = {
  MediumImage: 'MEDIUM_IMAGE',
  Primary: 'PRIMARY',
  Screenshot: 'SCREENSHOT',
  SmallImage: 'SMALL_IMAGE',
  StandardCompression: 'STANDARD_COMPRESSION'
} as const;

export type MediaUsageType = typeof MediaUsageType[keyof typeof MediaUsageType];
/** Input for message channel by artist handle */
export type MessageChannelArtistHandleInput = {
  /** Artist handle of message channel */
  handle: Scalars['String']['input'];
  /** Type of message channel */
  type?: MessageChannelType | '%future added value';
};

/** Filter type for message channel involvement paginated query */
export const MessageChannelInvolvementFilter = {
  All: 'ALL',
  PaidOnly: 'PAID_ONLY',
  UnreadOnly: 'UNREAD_ONLY',
  WithNotificationOverrides: 'WITH_NOTIFICATION_OVERRIDES'
} as const;

export type MessageChannelInvolvementFilter = typeof MessageChannelInvolvementFilter[keyof typeof MessageChannelInvolvementFilter];
/** Status of the message channel */
export const MessageChannelStatus = {
  Active: 'ACTIVE',
  Hidden: 'HIDDEN'
} as const;

export type MessageChannelStatus = typeof MessageChannelStatus[keyof typeof MessageChannelStatus];
/** Type of message channel */
export const MessageChannelType = {
  ArtistDm: 'ARTIST_DM',
  Vault: 'VAULT'
} as const;

export type MessageChannelType = typeof MessageChannelType[keyof typeof MessageChannelType];
/** Type of messsage reaction */
export const MessageReactionType = {
  Emoji: 'EMOJI',
  Fire: 'FIRE',
  Heart: 'HEART',
  Laugh: 'LAUGH',
  ThumbsUp: 'THUMBS_UP',
  Unknown: 'UNKNOWN'
} as const;

export type MessageReactionType = typeof MessageReactionType[keyof typeof MessageReactionType];
/** Type of messsage reaction to be created */
export const MessageReactionTypeInput = {
  Emoji: 'EMOJI',
  Fire: 'FIRE',
  Heart: 'HEART',
  ThumbsUp: 'THUMBS_UP'
} as const;

export type MessageReactionTypeInput = typeof MessageReactionTypeInput[keyof typeof MessageReactionTypeInput];
/** Source of the message input */
export const MessageSource = {
  ContentSection: 'CONTENT_SECTION',
  VaultChat: 'VAULT_CHAT'
} as const;

export type MessageSource = typeof MessageSource[keyof typeof MessageSource];
/** Type of the message source */
export const MessageSourceType = {
  Chat: 'CHAT',
  ContentComment: 'CONTENT_COMMENT',
  DeprecatedSms: 'DEPRECATED_SMS',
  MobileApp: 'MOBILE_APP',
  Notification: 'NOTIFICATION',
  ReceivedEmail: 'RECEIVED_EMAIL',
  ReceivedSms: 'RECEIVED_SMS',
  ScheduledEvent: 'SCHEDULED_EVENT',
  VaultContentUpload: 'VAULT_CONTENT_UPLOAD',
  WebApp: 'WEB_APP'
} as const;

export type MessageSourceType = typeof MessageSourceType[keyof typeof MessageSourceType];
export type MutationAbortMultipartUploadInput = {
  /** Upload ID to use for each upload part of the final object */
  uploadId: Scalars['String']['input'];
  /** Upload key for the final upload object */
  uploadKey: Scalars['String']['input'];
};

export type MutationAppleMusicPresaveInput = {
  /** The Apple Music auth user token if user is not authenticated */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
};

export type MutationAssignArtistAdminInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
  /** Username or uuid or phone number (starting with +) of the user to assign as admin */
  newAdmin: Scalars['String']['input'];
  /** Role to assign to the user */
  role?: ArtistAdminRole | '%future added value';
};

/** Input for creating a group of splits */
export type MutationAssignSplitsInput = {
  /** Details of the splits */
  splitDetails: Array<AssignSplitDetailsInput>;
  /** ID of the VaultContent for split */
  vaultContentId: Scalars['UUID']['input'];
};

/** Information about the payee to be attached to authenticated user */
export type MutationAttachPayeeToUserInput = {
  /** Artist ID to attach to the payee, if authenticated user manages the artist */
  attachArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** Secret ID of the Payee to be attached */
  payeeSecretId: Scalars['UUID']['input'];
};

/** Input for banning a subscriber */
export type MutationBanSubscriberFromVaultInput = {
  /** The artist profile associated with the ban, if authenticated user is an admin */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationCancelRsvpEventUserInput = {
  /** The ID of the RSVP event user */
  rsvpEventUserId: Scalars['UUID']['input'];
};

export type MutationCancelSubscriptionInput = {
  /** vault subscriptionId */
  subscriptionId: Scalars['UUID']['input'];
};

export type MutationCompleteMultipartUploadInput = {
  /** Ordered list of uploaded multi-part parts to associate with the final object */
  parts: Array<CompletedMultipartUploadPart>;
  /** Upload ID to use for each upload part of the final object */
  uploadId: Scalars['String']['input'];
  /** Upload key for the final upload object */
  uploadKey: Scalars['String']['input'];
};

export type MutationConnectAppleMusicInput = {
  /** Override existing user account with Apple Music connection if already linked */
  overrideAccount?: InputMaybe<Scalars['Boolean']['input']>;
  /** Apple Music User Token */
  userToken: Scalars['String']['input'];
};

export type MutationCreateAnnouncementInput = {
  /** List of drop IDs for which RSVPed users should be targeted */
  artistEventTarget?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** Content of the announcement */
  content: Scalars['String']['input'];
  /** Type of features to allow access to the announcement */
  featureAccessList?: Array<ScheduledEventAccessFeatureInput | '%future added value'>;
  /**
   * Location target (list of locations (iso country code and optional region)) for the announcement
   * @deprecated Use locationTargetV2 instead
   */
  locationTarget?: InputMaybe<Array<AnnouncementLocationTargetInput>>;
  /** Location target V2 - list of locations (region and/or geo types) for the announcement */
  locationTargetV2?: InputMaybe<Array<AnnouncementLocationTargetV2Input>>;
  /** List of message attachments IDs */
  messageAttachmentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Time the announcement should be sent (null if sent right away) */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Source of the announcement */
  source?: InputMaybe<AnnouncementSourceInput>;
  /** Vault content ID attachment */
  vaultContentIdAttachment?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input for creating an artist */
export type MutationCreateArtistInput = {
  /** Role of the artist admin */
  adminRole?: ArtistAdminRole | '%future added value';
  /** Identifier of uploaded media */
  avatarImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Email address of the artist */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Username of the existing user account */
  existingUsername?: InputMaybe<Scalars['String']['input']>;
  /** Unique handle for the artist, used for the artist's URL */
  handle: Scalars['String']['input'];
  /** Name of the artist */
  name?: Scalars['String']['input'];
  /** Type of phone number to assign to the artist */
  phoneNumber?: InputMaybe<ArtistPhoneNumberType | '%future added value'>;
  /** Area code for the phone number */
  phoneNumberAreaCode?: InputMaybe<Scalars['String']['input']>;
  /** Price of this artists vault. Default is $5 (500 cents) */
  price?: InputMaybe<Scalars['Int']['input']>;
  /** Whether to use the payee for the underlying user if it exists */
  shouldUseUserPayee?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of the vault */
  type: VaultType | '%future added value';
  /** Identifier of the user */
  userId?: InputMaybe<Scalars['UUID']['input']>;
  /** Phone number of the user */
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateArtistV2Input = {
  /** The handle of the artist */
  linkValue: Scalars['String']['input'];
  /** The display name of the artist */
  name: Scalars['String']['input'];
  /** The user to be added to the artist */
  userToArtistId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateMessageInput = {
  /** The id of the artist to send the message as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The content of the message to create */
  content: Scalars['String']['input'];
  /** A GIF attachment to be sent with the message */
  gifAttachment?: InputMaybe<GifAttachmentInput>;
  /** An array of the ids of the uploaded attachments to be sent with the message */
  messageAttachments?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Id of the message channel to send the message to */
  messageChannelId: Scalars['UUID']['input'];
  /** An optional unique uuid generated by client-side to use for optimistic updates */
  optimisticId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the message to reply to */
  replyToId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Source of the message input
   * @deprecated Use sourceType instead of source
   */
  source?: InputMaybe<MessageSource | '%future added value'>;
  /** Source type of the message input */
  sourceType?: InputMaybe<MessageSourceType | '%future added value'>;
  /** ID of the root message of the thread */
  threadRootId?: InputMaybe<Scalars['UUID']['input']>;
  /** The id of the vault content to be sent with the message */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateMessageReactionInput = {
  /** The id of the artist to send the reaction as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The emoji keyword to use for the reaction */
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
  /** Id of the message to create a reaction too */
  messageId: Scalars['UUID']['input'];
  /** Type of reaction to create */
  reactionType: MessageReactionTypeInput | '%future added value';
};

export type MutationCreateMultipartUploadInput = {
  /** Artist ID to use for the upload, if authenticated user is an admin */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** File name of media to be uploaded */
  fileName: Scalars['String']['input'];
  /** Media type to be uploaded */
  mediaType: MediaTypeInput | '%future added value';
  /** Number of parts to use for multi-part upload */
  totalPartsCount: Scalars['Int']['input'];
};

export type MutationCreatePrimaryUserAccountInput = {
  /** The phone or email the user will be using to log in with */
  signInMethod: UserLookupInput;
  /** The username to set for the user */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateReleaseCampaignInput = {
  /**
   * The announcement to send to users
   * @deprecated Not currently supported
   */
  announcement?: InputMaybe<ReleaseCampaignAnnouncementInput>;
  /** The Apple Music resource ID of the content */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The handle of the associated artist */
  artistHandle: Scalars['String']['input'];
  /** The type of content */
  contentType?: ReleaseCampaignContentType | '%future added value';
  /** Media ID of the cover image */
  coverImageMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the release campaign */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The external links associated with the release campaign */
  externalLinks: Array<ReleaseCampaignExternalLinkInput>;
  /** The ISRC of the content */
  isrc?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not presaves are enabled */
  presavesEnabled: Scalars['Boolean']['input'];
  /** The date and time when the release campaign content will be released */
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The message to send to users upon release */
  releaseMessage?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to find the Apple Music link */
  shouldFindAppleMusicLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to find the Spotify link */
  shouldFindSpotifyLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Apple Music presave */
  showAppleMusicPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave */
  showSpotifyPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave link first */
  showSpotifyPresaveFirst?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Spotify resouce ID of the content */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The URL of the third party release image */
  thirdPartyReleaseImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** The title of the release campaign */
  title: Scalars['String']['input'];
  /** The UPC of the content */
  upc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the associated vault content */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateRsvpEventInput = {
  /** The ID of the artist of the RSVP event where the authenticated user is an admin */
  artistId: Scalars['UUID']['input'];
  /** The ID of the cover image of the RSVP event */
  coverImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the RSVP event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The date of the RSVP event */
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Whether late RSVPs are enabled for the RSVP event */
  lateRsvpsEnabled?: Scalars['Boolean']['input'];
  /** The release announcement of the RSVP event */
  releaseAnnouncement?: InputMaybe<RsvpEventReleaseAnnouncementInput>;
  /** The thank you message of the RSVP event */
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  /** The title of the RSVP event */
  title: Scalars['String']['input'];
};

export type MutationCreateSmsCampaignInput = {
  /** Whether or not the campaign is active */
  active?: Scalars['Boolean']['input'];
  /** Handle of the artist to create the campaign for */
  artistHandle: Scalars['String']['input'];
  /** Phone number of the artist to create the campaign for */
  artistPhoneNumber: Scalars['String']['input'];
  /** Message for campaign response texts */
  message?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSubscriptionPaymentIntentInput = {
  /** Referral code to apply to the subscription */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Vault id to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCreateUserFeedbackInput = {
  /** User feedback text */
  text: Scalars['String']['input'];
  /** The id of the associated vault */
  vaultId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateVaultContentInput = {
  /** Custom caption of the content */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Type of content to add */
  contentType: VaultContentType | '%future added value';
  /** Whether to enable users to download the content; currently only for tracks */
  downloadEnabled?: Scalars['Boolean']['input'];
  /** Duration of the track in seconds */
  duration?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Media ID to add to the vault */
  mediaId: Scalars['UUID']['input'];
  /** Normalized peaks of the track */
  normalizedPeaks?: InputMaybe<Array<Scalars['NonNegativeInt']['input']>>;
  /** Optional notification message to send to subscribers */
  notificationMessage?: InputMaybe<Scalars['String']['input']>;
  /** Parent vault content ID */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Whether to send SMS notifications */
  shouldSendSms: Scalars['Boolean']['input'];
  /** Track thumbnail media ID */
  thumbnailMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Custom title of the content */
  title: Scalars['String']['input'];
  /** Optional unique ID of the content */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  /** Vault ID to add content to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCreateVaultFolderInput = {
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Parent vault content ID */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Title of the folder */
  title: Scalars['String']['input'];
  /** Vault ID to create folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCustomizeVaultInput = {
  /** Accent color as hex */
  accentColor: Scalars['HexColor']['input'];
  /** Background color as hex */
  backgroundColor: Scalars['HexColor']['input'];
  /** Display name of the artist */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile image */
  logoImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the profile image */
  profileImageId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * Remove the logo image
   * @deprecated Use unset.logoImageId instead
   */
  shouldRemoveLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Socials of the artist */
  socials?: InputMaybe<ArtistSocialsInput>;
  /** Specify unset behavior. If a field is specified as unset true and the update is nullish, it will be unset. If a field is not specified as unset true, it will be ignored if nullish */
  unset?: InputMaybe<UnsetCustomizeVaultInput>;
  /** Vault ID to theme */
  vaultId: Scalars['UUID']['input'];
};

export type MutationDeactivateReleaseCampaignInput = {
  /** The ID of the release campaign to deactivate */
  releaseCampaignId: Scalars['String']['input'];
};

export type MutationDeactivateRsvpEventInput = {
  /** Id of the RSVP event to deactivate */
  rsvpEventId: Scalars['UUID']['input'];
};

export type MutationDeleteAnnouncementInput = {
  /** ID of the scheduled announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationDeleteMessageInput = {
  /** Id of the message to delete */
  messageId: Scalars['UUID']['input'];
};

export type MutationDeleteMessageReactionInput = {
  /** The id of the artist to remove the reaction as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The emoji keyword to use for the reaction */
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
  /** Id of the message to delete reaction */
  messageId: Scalars['UUID']['input'];
  /** Type of reaction to delete */
  reactionType: MessageReactionType | '%future added value';
};

export type MutationDeleteReleaseCampaignInput = {
  /** The ID of the release campaign to delete */
  releaseCampaignId: Scalars['String']['input'];
};

export type MutationDeleteRsvpEventInput = {
  /** Id of the RSVP event to delete */
  rsvpEventId: Scalars['UUID']['input'];
};

export type MutationEditVaultContentInput = {
  /** Custom caption of the content */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Whether to enable users to download the content; currently only for tracks */
  downloadEnabled?: Scalars['Boolean']['input'];
  /** Type of feature to allow access to the content */
  featureAccess?: InputMaybe<VaultContentAccessFeatureInput | '%future added value'>;
  /** Thumbnail media ID to set for the content */
  thumbnailMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Custom title of the content */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Specify unset behavior. If a field is specified as unset true and the update is nullish, it will be unset. If a field is not specified as unset true, it will be ignored if nullish */
  unset?: InputMaybe<UnsetVaultContentInput>;
  /** Vault Content ID to edit content for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationEditVaultContentSnippetInput = {
  /** End time of the snippet in seconds */
  endTime?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Start time of the snippet in seconds */
  startTime: Scalars['NonNegativeInt']['input'];
  /** Vault Content ID to edit snippet for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationGrantArtistAuthAccessInput = {
  /** The artist handle to add access to */
  artistHandle: Scalars['String']['input'];
  /** The phone number that will be able to access the artist profile */
  userPhoneNumberGranted: Scalars['String']['input'];
};

export type MutationGrantArtistPhoneNumberInput = {
  /** The area code of the phone number */
  areaCode: Scalars['String']['input'];
  /** The handle of the artist */
  artistHandle: Scalars['String']['input'];
  /** The country code of the phone number */
  countryCode?: SupportedPhoneCountryCode | '%future added value';
};

export type MutationGrantAuthAccessInput = {
  /** The type of access to grant */
  accessType: GrantAuthAccessType | '%future added value';
  /** The handle of the artist to grant access to */
  artistHandle: Scalars['String']['input'];
  /** The email or phone number of the human to grant access to */
  authMethod: AuthMethodInput;
  /** Whether the user should be added to Vault HQ */
  joinVaultHQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** If creating a new account for a manager, the desired username for the manager */
  managerUsername?: InputMaybe<Scalars['String']['input']>;
};

export type MutationHideAnnouncementInput = {
  /** ID of the announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationLinkAppleMusicInput = {
  /** Override existing user account with Apple Music connection if already linked */
  overrideAccount?: InputMaybe<Scalars['Boolean']['input']>;
  /** Apple Music User Token */
  userToken: Scalars['String']['input'];
};

export type MutationMoveContentsToVaultFolderInput = {
  /** Content IDs to move to folder */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Folder ID to move content to (null to move to main vault level) */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Vault ID to for the content */
  vaultId: Scalars['UUID']['input'];
};

export type MutationPinVaultContentMessageInput = {
  /** Id of the message to pin */
  messageId: Scalars['UUID']['input'];
};

export type MutationPreviewMembershipCardImageUrlInput = {
  /** ID of the image media */
  backgroundImageMediaId: Scalars['UUID']['input'];
};

export type MutationReactivateReleaseCampaignInput = {
  /** The ID of the release campaign to reactivate */
  releaseCampaignId: Scalars['String']['input'];
};

export type MutationReactivateRsvpEventInput = {
  /** Id of the RSVP event to reactivate */
  rsvpEventId: Scalars['UUID']['input'];
};

/** Input for registering a mobile device token for push notifications */
export type MutationRegisterMobileDeviceTokenInput = {
  /** Firebase device token for mobile notifications */
  firebaseToken: Scalars['String']['input'];
  /** Indicates if the device is currently considered logged in */
  isLoggedIn: Scalars['Boolean']['input'];
  /** Device platform info */
  platformInfo: DevicePlatformInfoInput;
  /** Enable or disable push notification permissions */
  pushPermissionsEnabled: Scalars['Boolean']['input'];
};

export type MutationRemoveAllMessageChannelParticipantUserSettingsInput = {
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
};

export type MutationRemoveAllPinnedMessageInput = {
  /** Id of the message channel to remove unpin all */
  messageChannelId: Scalars['UUID']['input'];
};

export type MutationRemoveArtistAdminInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
  /** User identifier */
  userId: Scalars['UUID']['input'];
};

export type MutationRemoveArtistAuthAccessInput = {
  /** The artist handle to revoke access to */
  artistHandle: Scalars['String']['input'];
  /** The phone number that won't be able to access the user profile */
  userPhoneNumberRevoked: Scalars['String']['input'];
};

export type MutationRemoveMessageChannelParticipantUserSettingsInput = {
  /** The id of the actor */
  actorId: Scalars['UUID']['input'];
  /** The id of the channel */
  channelId: Scalars['UUID']['input'];
};

export type MutationRemovePaymentMethodInput = {
  id: Scalars['String']['input'];
};

export type MutationRemovePinnedMessageInput = {
  /** Id of the message to remove as pinned */
  messageId: Scalars['UUID']['input'];
};

export type MutationRemovePinnedVaultContentMessageInput = {
  /** Id of the vault content */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationRemoveVaultContentsInput = {
  /** Folder IDs to remove */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Vault ID to remove folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationReportPlaySessionInput = {
  duration: Scalars['NonNegativeFloat']['input'];
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  numTimesPaused: Scalars['NonNegativeInt']['input'];
  sessionId: Scalars['UUID']['input'];
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationReportPlayStreamReleaseCampaignInput = {
  /** Apple Music auth user token. Only required if user is not authenticated. */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** Release campaign identifier */
  releaseCampaignId: Scalars['UUID']['input'];
  /** Spotify auth code. Only required if user is not authenticated. */
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResetDeviceTokenInput = {
  /** Firebase device token for push notifications */
  firebaseToken: Scalars['String']['input'];
};

/** Input for banning a subscriber from a chat from Retool */
export type MutationRetoolBanSubscriberInput = {
  /** The artist id banning the user */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationRevokeAuthAccessInput = {
  /** The human who will have their access to the specified user account revoked */
  authId: Scalars['UUID']['input'];
  /** The userID corresponding to the user account being removed from authId (aka human) */
  targetUserId: Scalars['UUID']['input'];
};

export type MutationRsvpToEventInput = {
  /** The ID of the RSVP event */
  rsvpEventId: Scalars['String']['input'];
};

export type MutationSeenFeatureInput = {
  /** The key of the feature which has been viewed */
  featureKey: FeatureTypename | '%future added value';
};

export type MutationSendGreetingsInput = {
  message: Scalars['String']['input'];
};

export type MutationSetCustomPreferencesInput = {
  /** The custom vault view type */
  vaultViewType: VaultViewType | '%future added value';
};

export type MutationSetLastReadAtChannelInput = {
  /** Id of the artist if reading as an artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** Date to set the last read at. If date is set in the future, it will be overridden to the current date */
  date: Scalars['DateTime']['input'];
  /** Id of the message channel to set the last read at */
  messageChannelId: Scalars['UUID']['input'];
};

export type MutationSetOpenedPushNotificationTimeInput = {
  /** Time when the push notification was opened */
  clickedAt: Scalars['DateTime']['input'];
  /** Id of the pushNotification */
  pushNotificationId: Scalars['String']['input'];
};

export type MutationSetSpotifyStateRedirectInput = {
  /** Spotify state redirect url */
  redirectUrl: Scalars['URL']['input'];
  /** Spotify state */
  state: Scalars['String']['input'];
};

export type MutationSetUserVaultContentPositionAsSeenInput = {
  /** Vault content ID to get position for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationSetVaultContentOrderInput = {
  /** Content IDs in the order they should appear */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Parent folder ID to set content order */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Vault ID to set content order */
  vaultId: Scalars['UUID']['input'];
};

export type MutationSetVaultWelcomeMessageInput = {
  /** Vault ID */
  vaultId: Scalars['UUID']['input'];
  /** Welcome message to set on the vault */
  welcomeMessage: Scalars['String']['input'];
  /** Welcome message to set on the vault when sending from personal number */
  welcomePersonalNumberMessage?: InputMaybe<Scalars['String']['input']>;
};

export type MutationShowAnnouncementInput = {
  /** ID of the announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationSpotifyPresaveInput = {
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The Spotify auth user token if user is not authenticated */
  spotifyAuthUserToken?: InputMaybe<Scalars['String']['input']>;
};

/** Input for subscribing to a vault for free */
export type MutationSubscribeToVaultFreeInput = {
  /** Invite code from user */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Shortcode for the SMS campaign response associated with the subscribe action */
  smsCampaignResponseShortcode?: InputMaybe<Scalars['String']['input']>;
  /** ReleaseCampaignId of the release campaign page that the user came from */
  sourceReleaseCampaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** RsvpEventId of the rsvp event page that the user came from */
  sourceRsvpEventId?: InputMaybe<Scalars['UUID']['input']>;
  /** VaultContentId of the vault content page that the user came from */
  sourceVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Vault ID to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

/** Input for subscribing to a vault */
export type MutationSubscribeToVaultInput = {
  /** Invite code from user */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Shortcode for the SMS campaign response associated with the subscribe action */
  smsCampaignResponseShortcode?: InputMaybe<Scalars['String']['input']>;
  /** ReleaseCampaignId of the release campaign page that the user came from */
  sourceReleaseCampaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** RsvpEventId of the rsvp event page that the user came from */
  sourceRsvpEventId?: InputMaybe<Scalars['UUID']['input']>;
  /** VaultContentId of the vault content page that the user came from */
  sourceVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Stripe subscriptionId */
  subscriptionId: Scalars['String']['input'];
  /** Vault ID to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationTrackMessageLinkClickInput = {
  /** Unique shortcode of the message */
  messageShortcode: Scalars['String']['input'];
};

/** Input for banning a subscriber */
export type MutationUnbanSubscriberFromVaultInput = {
  /** The artist profile associated with the ban, if authenticated user is an admin */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationUpdateAnnouncementInput = {
  /** List of drop IDs for which RSVPed users should be targeted */
  artistEventTarget?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Content of the announcement */
  content: Scalars['String']['input'];
  /** Type of features to allow access to the announcement */
  featureAccessList: Array<ScheduledEventAccessFeatureInput | '%future added value'>;
  /**
   * Location target (list of locations (iso country code and optional region)) for the announcement
   * @deprecated Use locationTargetV2 instead
   */
  locationTarget?: InputMaybe<Array<AnnouncementLocationTargetInput>>;
  /** Location target V2 - list of locations (region and/or geo types) for the announcement */
  locationTargetV2?: InputMaybe<Array<AnnouncementLocationTargetV2Input>>;
  /** List of message attachments IDs */
  messageAttachmentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Time the announcement should be sent */
  scheduledAt: Scalars['DateTime']['input'];
  /** ID of the scheduled event announcement */
  scheduledEventId: Scalars['UUID']['input'];
  /** Vault content ID attachment */
  vaultContentIdAttachment?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationUpdateArtistProfileInput = {
  /** ID of the artist */
  artistId: Scalars['UUID']['input'];
  /** Profile description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the artist */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile image */
  profileImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Socials of the artist */
  socials?: InputMaybe<ArtistSocialsInput>;
};

export type MutationUpdateArtistReleaseCampaignInput = {
  /** The Apple Music resource ID of the content */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The handle of the associated artist */
  artistHandle: Scalars['String']['input'];
  /** The type of content */
  contentType?: InputMaybe<ReleaseCampaignContentType | '%future added value'>;
  /** The ID of the cover image */
  coverImageMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the release campaign */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The external links associated with the release campaign */
  externalLinks?: InputMaybe<Array<ReleaseCampaignExternalLinkInput>>;
  /** The ISRC of the content */
  isrc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign to update */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The date and time when the release campaign content will be released */
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The message to send to users upon release */
  releaseMessage?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to find the Apple Music link */
  shouldFindAppleMusicLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to find the Spotify link */
  shouldFindSpotifyLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Apple Music presave */
  showAppleMusicPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave */
  showSpotifyPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave link first */
  showSpotifyPresaveFirst?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Spotify resource ID of the content */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The title of the release campaign */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Specify unset behavior. If a field is specified as unset true and the update is nullish, it will be unset. If a field is not specified as unset true, it will be ignored if nullish */
  unset: UnsetArtistReleaseCampaignInput;
  /** The UPC of the content */
  upc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the associated vault content */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationUpdateArtistStatusInput = {
  /** The handle of the artist */
  artistHandle: Scalars['String']['input'];
  /** The status of the artist */
  status: ArtistStatus | '%future added value';
};

export type MutationUpdateArtistUserSettingsByFeatureInput = {
  /** The handle of the artist */
  artistHandle: Scalars['String']['input'];
  /** The feature to update */
  feature: ArtistUserSettingsFeature | '%future added value';
  /** Should receive push notifications */
  isPushEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications */
  isSmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The radio fields to update */
  radioFields?: InputMaybe<Array<RadioFieldInput>>;
};

export type MutationUpdateArtistUserSettingsInput = {
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
  /** Should receive push notifications */
  isPushNotificationEnabled: Scalars['Boolean']['input'];
  /** Should receive sms notifications */
  isSmsEnabled: Scalars['Boolean']['input'];
};

export type MutationUpdateMessageChannelParticipantUserSettingsInput = {
  /** The id of the actor */
  actorId: Scalars['UUID']['input'];
  /** The id of the channel */
  channelId: Scalars['UUID']['input'];
  /** Should receive push notifications */
  isPushNotificationEnabled: Scalars['Boolean']['input'];
  /** Should receive push notifications from other users in the channel (Only applies to the vault chat) */
  isPushNotificationEnabledForUserMessagesInGroupChat?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications */
  isSmsEnabled: Scalars['Boolean']['input'];
};

export type MutationUpdatePaymentMethodInput = {
  /** Current payment method */
  currentPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** New payment method */
  id: Scalars['String']['input'];
};

export type MutationUpdatePinnedMessageInput = {
  /** Id of the message to set as pinned */
  messageId: Scalars['UUID']['input'];
};

/** Input updating a user push notifications settings */
export type MutationUpdatePushNotificationsSettingsInput = {
  /** Should receive push notifications for unread messages */
  catchUpOnUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new unplayed tracks */
  discoverNewUnplayedTracks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for invites being redeemed */
  inviteRedeemed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new artist messages */
  newArtistMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new artists */
  newArtistOnVault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new artist tracks */
  newArtistTrack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new subscribers */
  newSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new subscription unlocks */
  newSubscriptionUnlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new vault content */
  newVaultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications */
  notificationsEnabled: Scalars['Boolean']['input'];
  /** Should receive push notifications for subscribed artist activity */
  subscribedArtistIsActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for mentions of you */
  userMentionedYou?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for reactions to your messages */
  userReactedToYourMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for replies to your messages */
  userRepliedToYourMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateRsvpEventInput = {
  /** The ID of the cover image of the RSVP event */
  coverImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the RSVP event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The date of the RSVP event */
  eventDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Whether late RSVPs are enabled for the RSVP event */
  lateRsvpsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The release announcement of the RSVP event */
  releaseAnnouncement?: InputMaybe<RsvpEventReleaseAnnouncementInput>;
  /** Id of the RSVP event to update */
  rsvpEventId: Scalars['UUID']['input'];
  /** The thank you message of the RSVP event */
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  /** The title of the RSVP event */
  title: Scalars['String']['input'];
};

export type MutationUpdateSmsCampaignInput = {
  /** Whether or not the campaign is active */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** SMS campaign ID */
  id: Scalars['String']['input'];
  /** Message for campaign response texts */
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Input updating a user sms notifications settings */
export type MutationUpdateSmsNotificationsSettingsInput = {
  /** Should receive sms notifications for new artist tracks */
  newArtistTrack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new subscribers */
  newSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new vault content */
  newVaultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications */
  notificationsEnabled: Scalars['Boolean']['input'];
  /** Should receive sms notifications for RSVP events */
  rsvpEvents?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input updating a user upsell interstitials */
export type MutationUpdateUpsellInterstitialsInput = {
  /** The user used the chat for the first time */
  firstChat?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user logged in for the first time */
  firstLogin?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user shared a snippet for the first time */
  firstSnippetShare?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user listened to a track for the first time */
  firstTrack?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateUserFeedbackInput = {
  /** The id of the user feedback */
  id: Scalars['UUID']['input'];
  /** Admin notes on the feedback */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Status of the user feedback */
  status?: InputMaybe<UserFeedbackStatus | '%future added value'>;
};

/** Input updating a user's fields */
export type MutationUpdateUserInput = {
  /** The id of the new avatar (null to remove avatar) */
  newAvatarId?: InputMaybe<Scalars['String']['input']>;
  /** The new display name */
  newDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** The new email address (null to remove email address) */
  newEmail?: InputMaybe<Scalars['String']['input']>;
  /** The new location place id (null to remove location) */
  newLocationPlaceId?: InputMaybe<Scalars['String']['input']>;
  /** The new location place input label */
  newLocationPlaceInputLabel?: InputMaybe<Scalars['String']['input']>;
  /** The new username */
  newUsername?: InputMaybe<Scalars['String']['input']>;
  /**
   * The new zip code (null to remove zip code)
   * @deprecated Use locationPlace instead
   */
  newZipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserProfileInput = {
  /** The id of the new avatar */
  avatarId?: InputMaybe<Scalars['String']['input']>;
  /** The new display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The new email address
   * @deprecated Use stripeEmail instead
   */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** The new location place */
  locationPlace?: InputMaybe<LocationPlaceInput>;
  /** The new email address to be used for stripe */
  stripeEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Specify unset behavior. If a field is specified as unset true and the update is nullish, it will be unset. If a field is not specified as unset true, it will be ignored if nullish */
  unset: UnsetUserProfileInput;
  /** The new username */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Input updating a user's fields */
export type MutationUpdateUserRemoveFieldsInput = {
  /** Should remove the avatar */
  shouldRemoveAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should remove the display name */
  shouldRemoveDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateVaultFolderInput = {
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Folder ID to update */
  folderId: Scalars['UUID']['input'];
  /** Title of the folder */
  title: Scalars['String']['input'];
  /** Vault ID to update folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationUpsertUserViewContentInput = {
  /** Vault content identifier */
  vaultContentId: Scalars['UUID']['input'];
};

/** Status of the allowlist */
export const PhoneAllowlistStatus = {
  Allowed: 'ALLOWED',
  Pending: 'PENDING'
} as const;

export type PhoneAllowlistStatus = typeof PhoneAllowlistStatus[keyof typeof PhoneAllowlistStatus];
/** Platform type */
export const PlatformType = {
  Android: 'ANDROID',
  Ios: 'IOS',
  Web: 'WEB'
} as const;

export type PlatformType = typeof PlatformType[keyof typeof PlatformType];
/** Status of the presave */
export const PresaveStatus = {
  Cancelled: 'CANCELLED',
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  Pending: 'PENDING',
  Scheduled: 'SCHEDULED'
} as const;

export type PresaveStatus = typeof PresaveStatus[keyof typeof PresaveStatus];
export type QueryArtistAnalyticsInput = {
  /** Optional date to get analytics for after */
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
  /** Optional date to get analytics for before */
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryArtistByLinkInput = {
  /** Unique link of the artist */
  link: Scalars['String']['input'];
};

export type QueryArtistUserSettingsByFeatureInput = {
  /** The handle of the artist */
  artistHandle: Scalars['String']['input'];
};

export type QueryArtistUserSettingsInput = {
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
};

export type QueryGetArtistReferralCodeByCodeInput = {
  /** The referral code */
  code: Scalars['String']['input'];
};

export type QueryGetArtistSubscriberBanStatusInput = {
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** User identifier */
  userId: Scalars['UUID']['input'];
};

export type QueryGetArtistSubscribersBanStatusInput = {
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** User identifiers */
  userIds: Array<Scalars['UUID']['input']>;
};

export type QueryGetBanStatusInput = {
  artistId: Scalars['UUID']['input'];
};

export type QueryGetTrackIdsToPopulateAudioQueueInput = {
  /** Parent vault content id to get children for */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Sort by option for the queue (Either ordered by time or position in folders) */
  sortBy?: VaultContentQueueSortBy | '%future added value';
  /** Vault id to get track ids for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryMembershipReceiptInput = {
  /** ID of the receipt */
  receiptId: Scalars['UUID']['input'];
};

export type QueryMessageChannelByHandleInput = {
  /** Artist handle of message channel */
  artistHandle: Scalars['String']['input'];
  /** Type of message channel */
  type?: MessageChannelType | '%future added value';
};

export type QueryMessageChannelInput = {
  /** The handle of the message channel */
  artistHandle?: InputMaybe<MessageChannelArtistHandleInput>;
  /** The id of the message channel */
  messageChannelId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryMessagesByIdInput = {
  /** Ids of the messages to retrieve */
  messageIds: Array<Scalars['UUID']['input']>;
};

export type QueryOriginalAllVaultContentCsvInput = {
  /** Artist handles */
  artistHandles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOriginalAllVaultContentInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
};

export type QueryOriginalVaultContentInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
  /** Content title */
  contentTitle: Scalars['String']['input'];
};

export type QueryPayeeStatusFromSecretInput = {
  /** Secret identifier of payee */
  secretId: Scalars['UUID']['input'];
};

export type QueryReceiptTypeCountInput = {
  /** ID of the artist */
  artistId: Scalars['UUID']['input'];
  /** Type of the expected receipt */
  receiptType: ArtistMembershipReceiptTypenames | '%future added value';
  /** ID of the release campaign */
  releaseCampaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the RSVP event */
  rsvpEventId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryReleaseCampaignAppleMusicPresaveStatusInput = {
  /** The Apple Music auth user token */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
};

export type QueryReleaseCampaignSpotifyPresaveStatusInput = {
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The Spotify auth user code */
  spotifyAuthUserCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRetoolMessageChannelByArtistIdInput = {
  artistId: Scalars['UUID']['input'];
};

export type QuerySplitDetailsInput = {
  /** ID of the VaultContent for split */
  vaultContentId: Scalars['String']['input'];
};

export type QuerySpotifyAuthStateRedirectUrlInput = {
  /** Spotify auth state */
  state: Scalars['String']['input'];
};

export type QueryThirdPartyReleaseInformationInput = {
  /** Apple music resource ID */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The type of resource */
  resourceType: ReleaseCampaignContentType | '%future added value';
  /** Spotify album URI */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTotalActionsCountInput = {
  /** ID of the artist */
  artistId: Scalars['UUID']['input'];
};

export type QueryTotalReceiptCountInput = {
  /** ID of the artist */
  artistId: Scalars['UUID']['input'];
};

export type QueryUserContentViewByContentIdInput = {
  /** Content ID to get user content view for */
  contentId: Scalars['UUID']['input'];
};

export type QueryUserFeatureSettingsByArtistInput = {
  /** The handle of the artist */
  artistHandle: Scalars['String']['input'];
};

export type QueryVaultContentAnalyticsInput = {
  /** Optional date to get analytics for after */
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Optional artist to get track analytics for */
  artistId?: InputMaybe<Scalars['UUID']['input']>;
  /** Optional date to get analytics for before */
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Limit the number of results returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Page to return */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryVaultContentsAreSeenInput = {
  /** List of content IDs to check if the user has seen  (max 25) */
  contentIds: Array<Scalars['UUID']['input']>;
};

export type QueryVaultSubscriberByUserIdForPublicInput = {
  /** User ID to get vault subscription for */
  userId: Scalars['UUID']['input'];
  /** Vault ID to get vault subscription for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryVaultSubscriberByUserIdInput = {
  /** User ID to get vault subscription for */
  userId: Scalars['UUID']['input'];
  /** Vault ID to get vault subscription for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryVaultSubscriptionInput = {
  subscriptionId: Scalars['UUID']['input'];
};

export type QueryVersionStatusInput = {
  /** Platform type */
  platform: PlatformType | '%future added value';
  /** Semantic version */
  version: Scalars['SemanticVersion']['input'];
};

/** Radio field input */
export type RadioFieldInput = {
  /** Whether the radio field is enabled */
  enabled: Scalars['Boolean']['input'];
  /** The key of the radio field */
  key: Scalars['String']['input'];
};

/** Release Campaign Announcement Input */
export type ReleaseCampaignAnnouncementInput = {
  /** Announcement date */
  date: Scalars['DateTime']['input'];
  /** Announcement message */
  message: Scalars['String']['input'];
};

/** Type of content associated with a release campaign */
export const ReleaseCampaignContentType = {
  Album: 'ALBUM',
  Track: 'TRACK'
} as const;

export type ReleaseCampaignContentType = typeof ReleaseCampaignContentType[keyof typeof ReleaseCampaignContentType];
/** Release Campaign External Link Input */
export type ReleaseCampaignExternalLinkInput = {
  /** Call to action of the external link */
  cta?: InputMaybe<Scalars['String']['input']>;
  /** Whether the external link is enabled */
  enabled: Scalars['Boolean']['input'];
  /** Order of the external link within the release campaign */
  order: Scalars['Int']['input'];
  /** Platform of the external link */
  platform: ThirdPartyPlatform | '%future added value';
  /** URL of the external link */
  url: Scalars['String']['input'];
};

/** The current state of the release campaign */
export const ReleaseCampaignState = {
  Deleted: 'DELETED',
  Inactive: 'INACTIVE',
  Presave: 'PRESAVE',
  Streaming: 'STREAMING'
} as const;

export type ReleaseCampaignState = typeof ReleaseCampaignState[keyof typeof ReleaseCampaignState];
/** Status of the release campaign */
export const ReleaseCampaignStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Inactive: 'INACTIVE'
} as const;

export type ReleaseCampaignStatus = typeof ReleaseCampaignStatus[keyof typeof ReleaseCampaignStatus];
/** Rsvp Event Release Announcement Input */
export type RsvpEventReleaseAnnouncementInput = {
  /** The date of the scheduled release announcement */
  date: Scalars['DateTime']['input'];
  /** The message to send to users upon release */
  message: Scalars['String']['input'];
  /** The target of the scheduled release announcement */
  target: RsvpEventReleaseAnnouncementTarget | '%future added value';
};

/** The target of the scheduled release announcement */
export const RsvpEventReleaseAnnouncementTarget = {
  AllVaultMembers: 'ALL_VAULT_MEMBERS',
  RsvpdUsers: 'RSVPD_USERS'
} as const;

export type RsvpEventReleaseAnnouncementTarget = typeof RsvpEventReleaseAnnouncementTarget[keyof typeof RsvpEventReleaseAnnouncementTarget];
/** The status of the RSVP event */
export const RsvpEventStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Inactive: 'INACTIVE'
} as const;

export type RsvpEventStatus = typeof RsvpEventStatus[keyof typeof RsvpEventStatus];
/** The status of the RSVP event user */
export const RsvpEventUserStatus = {
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED'
} as const;

export type RsvpEventUserStatus = typeof RsvpEventUserStatus[keyof typeof RsvpEventUserStatus];
/** Type of scheduled event access feature */
export const ScheduledEventAccessFeatureInput = {
  FreeEvent: 'FREE_EVENT',
  PaidEvent: 'PAID_EVENT'
} as const;

export type ScheduledEventAccessFeatureInput = typeof ScheduledEventAccessFeatureInput[keyof typeof ScheduledEventAccessFeatureInput];
/** SMS delivery status of the scheduled event */
export const ScheduledEventSmsDeliveryStatus = {
  Completed: 'COMPLETED',
  Processing: 'PROCESSING',
  Scheduled: 'SCHEDULED',
  Terminated: 'TERMINATED'
} as const;

export type ScheduledEventSmsDeliveryStatus = typeof ScheduledEventSmsDeliveryStatus[keyof typeof ScheduledEventSmsDeliveryStatus];
/** Status of the scheduled event */
export const ScheduledEventStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Hidden: 'HIDDEN',
  Inactive: 'INACTIVE'
} as const;

export type ScheduledEventStatus = typeof ScheduledEventStatus[keyof typeof ScheduledEventStatus];
/** The target of the scheduled event */
export const ScheduledEventTarget = {
  AllVaultMembers: 'ALL_VAULT_MEMBERS',
  RsvpdUsers: 'RSVPD_USERS'
} as const;

export type ScheduledEventTarget = typeof ScheduledEventTarget[keyof typeof ScheduledEventTarget];
/** Sort direction */
export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** Status of the spotify presave */
export const SpotifyPresaveStatus = {
  Completed: 'COMPLETED',
  Pending: 'PENDING'
} as const;

export type SpotifyPresaveStatus = typeof SpotifyPresaveStatus[keyof typeof SpotifyPresaveStatus];
/** Spotify product status */
export const SpotifyProductStatus = {
  Free: 'free',
  Open: 'open',
  Premium: 'premium',
  Unknown: 'unknown'
} as const;

export type SpotifyProductStatus = typeof SpotifyProductStatus[keyof typeof SpotifyProductStatus];
/** Subscription tier level name */
export const SubscriptionTierLevel = {
  Free: 'FREE',
  Paid: 'PAID'
} as const;

export type SubscriptionTierLevel = typeof SubscriptionTierLevel[keyof typeof SubscriptionTierLevel];
/** Supported phone country code, Subset of ISO 3166-1 alpha-2 */
export const SupportedPhoneCountryCode = {
  Ac: 'AC',
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Ta: 'TA',
  Tc: 'TC',
  Td: 'TD',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Xk: 'XK',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW'
} as const;

export type SupportedPhoneCountryCode = typeof SupportedPhoneCountryCode[keyof typeof SupportedPhoneCountryCode];
/** Third party platform */
export const ThirdPartyPlatform = {
  Amazon: 'AMAZON',
  AppleMusic: 'APPLE_MUSIC',
  Bandcamp: 'BANDCAMP',
  Deezer: 'DEEZER',
  Discord: 'DISCORD',
  Other: 'OTHER',
  Soundcloud: 'SOUNDCLOUD',
  Spotify: 'SPOTIFY',
  Whatsapp: 'WHATSAPP',
  Youtube: 'YOUTUBE',
  YoutubeMusic: 'YOUTUBE_MUSIC'
} as const;

export type ThirdPartyPlatform = typeof ThirdPartyPlatform[keyof typeof ThirdPartyPlatform];
/** Base tier names of a vault */
export const TierTypename = {
  FreeTier: 'FreeTier',
  PaidTier: 'PaidTier'
} as const;

export type TierTypename = typeof TierTypename[keyof typeof TierTypename];
export type UnsetArtistReleaseCampaignInput = {
  /** Whether or not to unset the Apple Music resource ID */
  appleMusicResourceId?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the cover image */
  coverImageMediaId?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the description */
  description?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the ISRC */
  isrc?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the release announcement */
  releaseAnnouncement?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the release date */
  releaseDate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the Spotify resource ID */
  spotifyResourceId?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the third party release image */
  thirdPartyReleaseImageUrl?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the UPC */
  upc?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the associated vault content */
  vaultContentId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnsetCustomizeVaultInput = {
  /** Whether or not to unset the logo image ID */
  logoImageId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnsetUserProfileInput = {
  /** Is the avatar field to be unset */
  avatarId?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the display name field to be unset. Artists cannot unset their display name. */
  displayName?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Is the email field to be unset
   * @deprecated Use stripeEmail instead
   */
  email?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the location place field to be unset */
  locationPlace?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the stripe email field to be unset */
  stripeEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnsetVaultContentInput = {
  /** Whether or not to unset the caption */
  caption?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to unset the thumbnail media ID */
  thumbnailMediaId?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Status of the user feedback */
export const UserFeedbackStatus = {
  Pending: 'PENDING',
  Resolved: 'RESOLVED'
} as const;

export type UserFeedbackStatus = typeof UserFeedbackStatus[keyof typeof UserFeedbackStatus];
/** Input type for looking up a user by various identifiers */
export type UserLookupInput = {
  /** Find/create the user based on auth email address */
  authEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Find/create the user based on phone number, takes any formatting. must include country code */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Find the user based on username -- not used for `create` mutations */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** Type of vault content access feature */
export const VaultContentAccessFeatureInput = {
  FreeVaultContent: 'FREE_VAULT_CONTENT',
  PaidVaultContent: 'PAID_VAULT_CONTENT'
} as const;

export type VaultContentAccessFeatureInput = typeof VaultContentAccessFeatureInput[keyof typeof VaultContentAccessFeatureInput];
/** Sort by options for vault content queue */
export const VaultContentQueueSortBy = {
  FolderPosition: 'FolderPosition',
  SortTime: 'SortTime'
} as const;

export type VaultContentQueueSortBy = typeof VaultContentQueueSortBy[keyof typeof VaultContentQueueSortBy];
/** Status of the vault content */
export const VaultContentStatus = {
  Available: 'AVAILABLE',
  Deleted: 'DELETED',
  Hidden: 'HIDDEN',
  Pending: 'PENDING'
} as const;

export type VaultContentStatus = typeof VaultContentStatus[keyof typeof VaultContentStatus];
/** Type of content associated with a vault */
export const VaultContentType = {
  Folder: 'FOLDER',
  Image: 'IMAGE',
  Track: 'TRACK',
  Video: 'VIDEO'
} as const;

export type VaultContentType = typeof VaultContentType[keyof typeof VaultContentType];
/** Source type of the vault subscription */
export const VaultSubscriptionSourceType = {
  Import: 'IMPORT',
  PresaveCampaignPage: 'PRESAVE_CAMPAIGN_PAGE',
  RsvpEvent: 'RSVP_EVENT',
  StandardSignup: 'STANDARD_SIGNUP',
  StreamingCampaignPage: 'STREAMING_CAMPAIGN_PAGE',
  VaultContentPage: 'VAULT_CONTENT_PAGE'
} as const;

export type VaultSubscriptionSourceType = typeof VaultSubscriptionSourceType[keyof typeof VaultSubscriptionSourceType];
/** Status of the vault subscription */
export const VaultSubscriptionStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
} as const;

export type VaultSubscriptionStatus = typeof VaultSubscriptionStatus[keyof typeof VaultSubscriptionStatus];
/** Type of vault */
export const VaultType = {
  Freemium: 'FREEMIUM',
  FreeOnly: 'FREE_ONLY'
} as const;

export type VaultType = typeof VaultType[keyof typeof VaultType];
/** Type of vault view */
export const VaultViewType = {
  File: 'FILE',
  List: 'LIST'
} as const;

export type VaultViewType = typeof VaultViewType[keyof typeof VaultViewType];
export type NotifyPlayedContentMutationVariables = Exact<{
  input: MutationUpsertUserViewContentInput;
}>;


export type NotifyPlayedContentMutation = { upsertUserViewContent: { id: string } | null };

export type ContentByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type ContentByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string, caption: string | null, parentVaultContentId: string | null, linkValue: string, downloadEnabled: boolean, commentMessageCount: number, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null }, commentPinnedMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null, commentCaptionMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null } | { __typename: 'VaultImage', id: string, title: string, caption: string | null, parentVaultContentId: string | null, linkValue: string, downloadEnabled: boolean, commentMessageCount: number, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null }, commentPinnedMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null, commentCaptionMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null } | { __typename: 'VaultTrack', id: string, createdAt: string, downloadEnabled: boolean, duration: number, title: string, blurredThumbnailMediaUrl: string | null, normalizedPeaks: Array<number>, isFullVersionAvailable: boolean, caption: string | null, parentVaultContentId: string | null, linkValue: string, commentMessageCount: number, thumbnailMedia: { id: string, imageOptimizedUrl: string | null, imageSmallUrl: string | null } | null, snippetVideo: { id: string, url: string } | null, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null }, commentPinnedMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null, commentCaptionMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null } | { __typename: 'VaultVideo', duration: number, id: string, title: string, caption: string | null, parentVaultContentId: string | null, linkValue: string, downloadEnabled: boolean, commentMessageCount: number, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null }, commentPinnedMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null, commentCaptionMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) | null } | null, getSignedTrackContent: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'QueryGetSignedTrackContentSuccess', data: string }, getSignedTrackSnippetContent: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryGetSignedTrackSnippetContentSuccess', data: string } | null };

export type ReportPlayStoppedAnalyticsMutationVariables = Exact<{
  input: MutationReportPlaySessionInput;
}>;


export type ReportPlayStoppedAnalyticsMutation = { reportPlaySession: string };

export type BubbleArtistFragment = { id: string, name: string, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null }, mainVault: { id: string, contentCount: number, type: VaultType } } & { ' $fragmentName'?: 'BubbleArtistFragment' };

export type JoinArtistFragment = { id: string, name: string, mainVaultId: string, linkValue: string, profileImage: { id: string, dominantColor: string | null, artistFullProfileImageUrl: string | null } } & { ' $fragmentName'?: 'JoinArtistFragment' };

export type JoinArtistByIdQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type JoinArtistByIdQuery = { artist: (
    { id: string }
    & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
  ) | null };

export type CountryCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryCodeQuery = { userCountryCode: IsoCountry | null };

export type AttachPayeeStatusQueryVariables = Exact<{
  input: QueryPayeeStatusFromSecretInput;
}>;


export type AttachPayeeStatusQuery = { payeeStatusFromSecret: { __typename: 'NotFoundError', message: string } | { __typename: 'PayeeAlreadyAttachedError', message: string } | { __typename: 'QueryPayeeStatusFromSecretSuccess', data: { availableUserAccount: { email: string, name: string | null } | null, availableArtistAccounts: Array<{ artistId: string, email: string, name: string, profileImageUrl: string | null }> | null, lastSplit: { percentBps: number, content: { contentTitle: string | null, artistHandle: string, artistName: string } | null } | null } } };

export type AttachPayeeMutationVariables = Exact<{
  input: MutationAttachPayeeToUserInput;
}>;


export type AttachPayeeMutation = { attachPayeeToUser: { __typename: 'MutationAttachPayeeToUserSuccess', data: { id: string, email: string } } | { __typename: 'ValidationError', message: string } };

export type GetUserToBanInformationQueryVariables = Exact<{
  input: QueryVaultSubscriberByUserIdInput;
}>;


export type GetUserToBanInformationQuery = { vaultSubscriberByUserId: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryVaultSubscriberByUserIdSuccess', data: { id: string, user: { id: string, computedDisplayName: string, avatar: { id: string, cdnUrl: string, imageOptimizedUrl: string | null, dominantColor: string | null } } | { id: string, computedDisplayName: string, avatar: { id: string, cdnUrl: string, imageOptimizedUrl: string | null, dominantColor: string | null } } } } | null };

export type ReactCommentMutationVariables = Exact<{
  input: MutationCreateMessageReactionInput;
}>;


export type ReactCommentMutation = { createMessageReaction: { __typename: 'MutationCreateMessageReactionSuccess', data: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type RemoveReactCommentMutationVariables = Exact<{
  input: MutationDeleteMessageReactionInput;
}>;


export type RemoveReactCommentMutation = { deleteMessageReaction: { __typename: 'MutationDeleteMessageReactionSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type AddCommentMutationVariables = Exact<{
  input: MutationCreateMessageInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type AddCommentMutation = { createMessage: { __typename: 'MutationCreateMessageSuccess', data: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'RateLimitError', message: string } | { __typename: 'ValidationError', message: string } };

export type ContentCommentsQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ContentCommentsQuery = { vaultContentComments: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type DeleteCommentMutationVariables = Exact<{
  input: MutationDeleteMessageInput;
}>;


export type DeleteCommentMutation = { deleteMessage: { __typename: 'MutationDeleteMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type EventCreatedFragment = { id: string, initialReleaseImageUrl: string | null, currentState: ReleaseCampaignState, title: string, linkValue: string, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'EventCreatedFragment' };

export type ReactorsByMessageIdQueryVariables = Exact<{
  messageId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  reactionType?: InputMaybe<MessageReactionType>;
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReactorsByMessageIdQuery = { reactorsByMessageId: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryReactorsByMessageIdSuccess', data: { edges: Array<{ cursor: string, node: { actor: { __typename: 'MessageActorArtist', id: string, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null } } } | { __typename: 'MessageActorUser', id: string, user: { id: string, displayName: string | null, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } | { id: string, displayName: string | null, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } }, reactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string, createdAt: string }> } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } } };

export type GetArtistMembershipReceiptQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetArtistMembershipReceiptQuery = { membershipReceipt: (
    { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
  ) | (
    { __typename: 'ArtistMembershipPresaveReceipt', id: string, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
  ) | (
    { __typename: 'ArtistMembershipRsvpEventReceipt', id: string, rsvpEvent: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment': MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment } }
  ) | null };

export type RsvpEventCreatedFragment = { id: string, title: string, linkValue: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } }, releaseAnnouncement: { id: string, content: string | null, scheduledAt: string, rsvpTarget: ScheduledEventTarget | null } | null } & { ' $fragmentName'?: 'RsvpEventCreatedFragment' };

export type BanSubscriberMutationVariables = Exact<{
  input: MutationBanSubscriberFromVaultInput;
}>;


export type BanSubscriberMutation = { banSubscriberFromVault: boolean };

export type UnbanSubscriberMutationVariables = Exact<{
  input: MutationUnbanSubscriberFromVaultInput;
}>;


export type UnbanSubscriberMutation = { unbanSubscriberFromVault: boolean };

export type GetUserInformationQueryVariables = Exact<{
  input: QueryVaultSubscriberByUserIdInput;
}>;


export type GetUserInformationQuery = { vaultSubscriberByUserId: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryVaultSubscriberByUserIdSuccess', data: { id: string, phone: string | null, email: string | null, artistDMChannelId: string | null, vaultSubscriptionSourceText: string | null, vaultSubscriptionSourceType: VaultSubscriptionSourceType | null, createdAt: string, userLocation: { country: string | null, city: string | null, region: string | null } | null, artistMembership: { receipts: number } | null, artist: { id: string, linkValue: string } } } | null };

export type GetExposedUserInfoQueryVariables = Exact<{
  input: QueryVaultSubscriberByUserIdForPublicInput;
}>;


export type GetExposedUserInfoQuery = { vaultSubscriberByUserIdForPublic: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryVaultSubscriberByUserIdForPublicSuccess', data: { createdAt: string } } | null };

export type ThirdPartyReleaseInformationQueryVariables = Exact<{
  input: QueryThirdPartyReleaseInformationInput;
}>;


export type ThirdPartyReleaseInformationQuery = { thirdPartyReleaseInformation: { __typename: 'QueryThirdPartyReleaseInformationSuccess', data: { releaseImageUrl: string | null, releaseTitle: string, releaseArtistName: string | null, urls: Array<{ platform: ThirdPartyPlatform, url: string }> } } | { __typename: 'ValidationError', message: string } };

type MusicCampaignView_ReleaseCampaignInactive_Fragment = { __typename: 'ReleaseCampaignInactive', id: string, linkValue: string, artist: { id: string, name: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null, artistProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'MusicCampaignView_ReleaseCampaignInactive_Fragment' };

type MusicCampaignView_ReleaseCampaignPrivateInfo_Fragment = (
  { __typename: 'ReleaseCampaignPrivateInfo', id: string, linkValue: string, artist: { id: string, name: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null, artistProfileImageUrl: string | null } } }
  & { ' $fragmentRefs'?: { 'PrivateMusicampaignViewFragment': PrivateMusicampaignViewFragment } }
) & { ' $fragmentName'?: 'MusicCampaignView_ReleaseCampaignPrivateInfo_Fragment' };

type MusicCampaignView_ReleaseCampaignPublicInfo_Fragment = (
  { __typename: 'ReleaseCampaignPublicInfo', id: string, linkValue: string, artist: { id: string, name: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null, artistProfileImageUrl: string | null } } }
  & { ' $fragmentRefs'?: { 'PublicMusicampaignViewFragment': PublicMusicampaignViewFragment } }
) & { ' $fragmentName'?: 'MusicCampaignView_ReleaseCampaignPublicInfo_Fragment' };

export type MusicCampaignViewFragment = MusicCampaignView_ReleaseCampaignInactive_Fragment | MusicCampaignView_ReleaseCampaignPrivateInfo_Fragment | MusicCampaignView_ReleaseCampaignPublicInfo_Fragment;

export type PublicMusicampaignViewFragment = { __typename: 'ReleaseCampaignPublicInfo', id: string, title: string, description: string | null, releaseDate: string | null, linkValue: string, status: ReleaseCampaignStatus, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, showSpotifyPresaveFirst: boolean, showAppleMusicPresave: boolean, showSpotifyPresave: boolean, externalLinks: Array<{ cta: string | null, enabled: boolean, id: string, platform: ThirdPartyPlatform, url: string }>, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, name: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null, artistProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'PublicMusicampaignViewFragment' };

export type PrivateMusicampaignViewFragment = { __typename: 'ReleaseCampaignPrivateInfo', id: string, title: string, description: string | null, releaseDate: string | null, linkValue: string, status: ReleaseCampaignStatus, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, showSpotifyPresaveFirst: boolean, showAppleMusicPresave: boolean, showSpotifyPresave: boolean, externalLinks: Array<{ cta: string | null, enabled: boolean, id: string, platform: ThirdPartyPlatform, url: string }>, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, name: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null, artistProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'PrivateMusicampaignViewFragment' };

export type EarnedReceiptViewFragment = { id: string, title: string, linkValue: string, currentState: ReleaseCampaignState, spotifyResourceId: string | null, appleMusicResourceId: string | null, contentType: ReleaseCampaignContentType, initialReleaseImageUrl: string | null, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } }, externalLinks: Array<{ id: string, url: string, platform: ThirdPartyPlatform }> } & { ' $fragmentName'?: 'EarnedReceiptViewFragment' };

export type ReleaseCampaignReportPlayStreamMutationVariables = Exact<{
  input: MutationReportPlayStreamReleaseCampaignInput;
}>;


export type ReleaseCampaignReportPlayStreamMutation = { reportPlayStreamReleaseCampaign: { __typename: 'MutationReportPlayStreamReleaseCampaignSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment': MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | null };

export type GetReceiptNumberQueryVariables = Exact<{
  input: QueryReceiptTypeCountInput;
}>;


export type GetReceiptNumberQuery = { receiptTypeCount: { __typename: 'QueryReceiptTypeCountSuccess', data: number } | { __typename: 'ValidationError', message: string } };

export type GetCampaignAppleMusicPresaveStatusQueryVariables = Exact<{
  input: QueryReleaseCampaignAppleMusicPresaveStatusInput;
}>;


export type GetCampaignAppleMusicPresaveStatusQuery = { releaseCampaignAppleMusicPresaveStatus: { id: string, status: PresaveStatus } | null };

export type AppleMusicPresaveMutationVariables = Exact<{
  input: MutationAppleMusicPresaveInput;
}>;


export type AppleMusicPresaveMutation = { appleMusicPresave: { __typename: 'MutationAppleMusicPresaveSuccess' } | { __typename: 'NotFoundError', message: string } };

export type GetCampaignSpotifyPresaveStatusQueryVariables = Exact<{
  input: QueryReleaseCampaignSpotifyPresaveStatusInput;
}>;


export type GetCampaignSpotifyPresaveStatusQuery = { releaseCampaignSpotifyPresaveStatus: { id: string, status: PresaveStatus } | null };

export type SpotifyPresaveMutationVariables = Exact<{
  input: MutationSpotifyPresaveInput;
}>;


export type SpotifyPresaveMutation = { spotifyPresave: { __typename: 'MutationSpotifyPresaveSuccess' } | { __typename: 'NotFoundError', message: string } };

export type CreateReleaseCampaignMutationVariables = Exact<{
  input: MutationCreateReleaseCampaignInput;
}>;


export type CreateReleaseCampaignMutation = { createReleaseCampaign: { __typename: 'MutationCreateReleaseCampaignSuccess', data: (
      { id: string, linkValue: string }
      & { ' $fragmentRefs'?: { 'EventCreatedFragment': EventCreatedFragment } }
    ) } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type UpdateReleaseCampaignMutationVariables = Exact<{
  input: MutationUpdateArtistReleaseCampaignInput;
}>;


export type UpdateReleaseCampaignMutation = { updateArtistReleaseCampaign: { __typename: 'MutationUpdateArtistReleaseCampaignSuccess', data: { id: string, linkValue: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeleteReleaseCampaignMutationVariables = Exact<{
  input: MutationDeleteReleaseCampaignInput;
}>;


export type DeleteReleaseCampaignMutation = { deleteReleaseCampaign: { __typename: 'MutationDeleteReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type DeactivateReleaseCampaignMutationVariables = Exact<{
  input: MutationDeactivateReleaseCampaignInput;
}>;


export type DeactivateReleaseCampaignMutation = { deactivateReleaseCampaign: { __typename: 'MutationDeactivateReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type ReactivateReleaseCampaignMutationVariables = Exact<{
  input: MutationReactivateReleaseCampaignInput;
}>;


export type ReactivateReleaseCampaignMutation = { reactivateReleaseCampaign: { __typename: 'MutationReactivateReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type ContentCommentThreadPreviewFragment = { id: string, createdAt: string, updatedAt: string, content: string, activeSubscriptionTier: TierTypename | null, threadRootId: string | null, threadMessagesCount: number, creator: { __typename: 'MessageActorArtist', id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null } } } | { __typename: 'MessageActorUser', id: string, user: { id: string, createdAt: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } | { id: string, createdAt: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } }, reactionsSummary: Array<{ type: MessageReactionType, count: number, emojiKeyword: string }>, replyTo: { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } } | null, artistReactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }>, myReactionsInfo: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }> } & { ' $fragmentName'?: 'ContentCommentThreadPreviewFragment' };

export type ContentCommentRowFragment = { id: string, createdAt: string, updatedAt: string, content: string, activeSubscriptionTier: TierTypename | null, threadRootId: string | null, threadMessagesCount: number, creator: { __typename: 'MessageActorArtist', id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null } } } | { __typename: 'MessageActorUser', id: string, user: { id: string, createdAt: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } | { id: string, createdAt: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } }, reactionsSummary: Array<{ type: MessageReactionType, count: number, emojiKeyword: string }>, replyTo: { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } } | null, threadMessagesPreview: Array<{ ' $fragmentRefs'?: { 'ContentCommentThreadPreviewFragment': ContentCommentThreadPreviewFragment } }>, artistReactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }>, myReactionsInfo: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }> } & { ' $fragmentName'?: 'ContentCommentRowFragment' };

export type MessagesByIdQueryVariables = Exact<{
  input: QueryMessagesByIdInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type MessagesByIdQuery = { messagesById: Array<(
    { id: string, updatedAt: string }
    & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
  )> };

export type ArtistLayoutFragment = { id: string, name: string, linkValue: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null, mainVault: { id: string, price: number | null, messageChannelId: string, type: VaultType, contentCount: number, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string, status: VaultSubscriptionStatus, currentPeriodEnd: string | null, stripeSubscriptionId: string | null }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } } & { ' $fragmentName'?: 'ArtistLayoutFragment' };

export type MessageChannelLayoutInfoFragment = { id: string, vault: { id: string, contentCount: number, artist: { id: string, name: string } | null } | null } & { ' $fragmentName'?: 'MessageChannelLayoutInfoFragment' };

export type VaultTrackIdsQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultTrackIdsQuery = { vaultFromId: { id: string, trackIds: Array<string> } | null };

export type GetTrackIdsToPopulateQueueQueryVariables = Exact<{
  input: QueryGetTrackIdsToPopulateAudioQueueInput;
}>;


export type GetTrackIdsToPopulateQueueQuery = { getTrackIdsToPopulateAudioQueue: Array<string> };

type FilterMembersEventItem_ArtistReleaseCampaignEvent_Fragment = { __typename: 'ArtistReleaseCampaignEvent', id: string, releaseCampaign: { __typename: 'ReleaseCampaignInactive', id: string } | { __typename: 'ReleaseCampaignPrivateInfo', title: string, initialReleaseImageUrl: string | null, releaseDate: string | null, createdAt: string, id: string, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } }, coverImage: { id: string, campaignCoverImageUrl: string | null } | null } | { __typename: 'ReleaseCampaignPublicInfo', id: string } } & { ' $fragmentName'?: 'FilterMembersEventItem_ArtistReleaseCampaignEvent_Fragment' };

type FilterMembersEventItem_ArtistRsvpEvent_Fragment = { __typename: 'ArtistRsvpEvent', id: string, rsvpEvent: { __typename: 'InactiveRsvpEvent', id: string, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } | { __typename: 'PublicRsvpEvent', id: string, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } | { __typename: 'RsvpEventPrivateInfo', title: string, eventDate: string | null, createdAt: string, id: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } } & { ' $fragmentName'?: 'FilterMembersEventItem_ArtistRsvpEvent_Fragment' };

export type FilterMembersEventItemFragment = FilterMembersEventItem_ArtistReleaseCampaignEvent_Fragment | FilterMembersEventItem_ArtistRsvpEvent_Fragment;

export type LocationMemberCountsQueryVariables = Exact<{
  artistId: Scalars['String']['input'];
}>;


export type LocationMemberCountsQuery = { memberCountsByCountry: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMemberCountsByCountrySuccess', data: Array<{ isoCountryCode: IsoCountry | null, isoUsStateCode: IsousState | null, memberCount: number }> }, memberCountsByUSState: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMemberCountsByUSStateSuccess', data: Array<{ isoCountryCode: IsoCountry | null, isoUsStateCode: IsousState | null, memberCount: number }> } };

export type MmInsightsFragment = { id: string, content: string | null, scheduledAt: string, memberCount: number, linkClickRate: number | null, smsDeliveryStatus: ScheduledEventSmsDeliveryStatus, locationTargetV2: Array<{ __typename: 'AnnouncementTargetingLocationV2Geo', displayName: string, latitude: number, longitude: number, radiusMiles: number, isoCountry: IsoCountry, type: AnnouncementLocationTargetV2Type } | { __typename: 'AnnouncementTargetingLocationV2Region', isoCountry: IsoCountry, isoUsStateCode: IsousState | null, type: AnnouncementLocationTargetV2Type }> | null, featureAccess: Array<{ feature: { __typename: 'FreeScheduledEvent' } | { __typename: 'PaidScheduledEvent' } }>, artistEventTarget: Array<{ id: string, title: string | null }> | null, rsvpEvent: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } | null, vaultContentAttachment: { id: string, linkValue: string } | { id: string, linkValue: string } | { id: string, linkValue: string } | { id: string, linkValue: string } | null, messageAttachments: Array<{ id: string, mediaType: MediaType }> | null } & { ' $fragmentName'?: 'MmInsightsFragment' };

export type MassMessageInsightsQueryVariables = Exact<{
  announcementId: Scalars['UUID']['input'];
}>;


export type MassMessageInsightsQuery = { announcementById: (
    { __typename: 'Announcement', id: string, smsDeliveryStatus: ScheduledEventSmsDeliveryStatus, scheduledAt: string }
    & { ' $fragmentRefs'?: { 'MmInsightsFragment': MmInsightsFragment } }
  ) };

export type CancelScheduledAnnouncementMutationVariables = Exact<{
  input: MutationDeleteAnnouncementInput;
}>;


export type CancelScheduledAnnouncementMutation = { deleteAnnouncement: { __typename: 'MutationDeleteAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type HideScheduledAnnouncementMutationVariables = Exact<{
  input: MutationHideAnnouncementInput;
}>;


export type HideScheduledAnnouncementMutation = { hideAnnouncement: { __typename: 'MutationHideAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type ShowScheduledAnnouncementMutationVariables = Exact<{
  input: MutationShowAnnouncementInput;
}>;


export type ShowScheduledAnnouncementMutation = { showAnnouncement: { __typename: 'MutationShowAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type GetWeeklySubscriberDataQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type GetWeeklySubscriberDataQuery = { weeklyNewSubscribers: { newSubscribersPerWeek: Array<{ newSubscriberCount: number, weekStart: string }> }, mySubscribersSummary: { activeSubscriptionsCount: number } };

export type CampaignInsightsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  receiptType: ArtistMembershipReceiptTypenames;
}>;


export type CampaignInsightsQuery = { allArtistMembershipReceipts: { edges: Array<{ cursor: string, node: { id: string, createdAt: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: (
            { id: string, createdAt: string }
            & { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } }
          ) | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } | { id: string, createdAt: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: (
            { id: string, createdAt: string }
            & { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } }
          ) | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } | { id: string, createdAt: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: (
            { id: string, createdAt: string }
            & { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } }
          ) | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type CampaignInsightHeaderFragment = { id: string, title: string, initialReleaseImageUrl: string | null, currentState: ReleaseCampaignState, linkValue: string, status: ReleaseCampaignStatus, description: string | null, releaseDate: string | null, newSubscriptionCount: number, receiptCount: number, dspActionCount: number, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'CampaignInsightHeaderFragment' };

type CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment = (
  { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string }, membership: { serialNumber: number }, user: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } } | { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } }, artist: { id: string, name: string, linkValue: string, mainVaultId: string } }
  & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
) & { ' $fragmentName'?: 'CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment' };

type CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment = (
  { __typename: 'ArtistMembershipPresaveReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string }, membership: { serialNumber: number }, user: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } } | { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } }, artist: { id: string, name: string, linkValue: string, mainVaultId: string } }
  & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
) & { ' $fragmentName'?: 'CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment' };

type CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment = (
  { __typename: 'ArtistMembershipRsvpEventReceipt', id: string, createdAt: string, serialNumber: number, rsvpEvent: { __typename: 'InactiveRsvpEvent', id: string, title: string } | { __typename: 'PublicRsvpEvent', id: string, title: string } | { __typename: 'RsvpEventPrivateInfo', id: string, title: string }, membership: { serialNumber: number }, user: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } } | { id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, smallProfileImageUrl: string | null } }, artist: { id: string, name: string, linkValue: string, mainVaultId: string } }
  & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment': MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment } }
) & { ' $fragmentName'?: 'CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment' };

export type CampaignReceiptItemFragment = CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment | CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment | CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment;

export type RsvpInsightHeaderFragment = { id: string, createdAt: string, title: string, description: string | null, eventDate: string | null, linkValue: string, newSubscriptionCount: number, receiptCount: number, status: RsvpEventStatus, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'RsvpInsightHeaderFragment' };

export type RsvpInsightsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RsvpInsightsQuery = { allArtistMembershipReceipts: { edges: Array<{ cursor: string, node: { id: string, rsvpDate: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } } | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } | { id: string, rsvpDate: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } } | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } | { id: string, rsvpDate: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, receipts: number, vaultSubscription: { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } } | null }, artist: { id: string, mainVault: { id: string, type: VaultType } } } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

type ReleaseCampaignEventItem_ReleaseCampaignInactive_Fragment = { __typename: 'ReleaseCampaignInactive' } & { ' $fragmentName'?: 'ReleaseCampaignEventItem_ReleaseCampaignInactive_Fragment' };

type ReleaseCampaignEventItem_ReleaseCampaignPrivateInfo_Fragment = (
  { __typename: 'ReleaseCampaignPrivateInfo' }
  & { ' $fragmentRefs'?: { 'PrivateReleaseCampaignEventItemFragment': PrivateReleaseCampaignEventItemFragment } }
) & { ' $fragmentName'?: 'ReleaseCampaignEventItem_ReleaseCampaignPrivateInfo_Fragment' };

type ReleaseCampaignEventItem_ReleaseCampaignPublicInfo_Fragment = (
  { __typename: 'ReleaseCampaignPublicInfo' }
  & { ' $fragmentRefs'?: { 'PublicReleaseCampaignEventItemFragment': PublicReleaseCampaignEventItemFragment } }
) & { ' $fragmentName'?: 'ReleaseCampaignEventItem_ReleaseCampaignPublicInfo_Fragment' };

export type ReleaseCampaignEventItemFragment = ReleaseCampaignEventItem_ReleaseCampaignInactive_Fragment | ReleaseCampaignEventItem_ReleaseCampaignPrivateInfo_Fragment | ReleaseCampaignEventItem_ReleaseCampaignPublicInfo_Fragment;

export type PublicReleaseCampaignEventItemFragment = { __typename: 'ReleaseCampaignPublicInfo', id: string, title: string, releaseDate: string | null, linkValue: string, status: ReleaseCampaignStatus, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'PublicReleaseCampaignEventItemFragment' };

export type PrivateReleaseCampaignEventItemFragment = { __typename: 'ReleaseCampaignPrivateInfo', id: string, title: string, releaseDate: string | null, linkValue: string, status: ReleaseCampaignStatus, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, receiptCount: number, dspActionCount: number, announcement: { id: string, content: string | null } | null, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, artist: { id: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'PrivateReleaseCampaignEventItemFragment' };

type MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment = { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string }, membership: { createdAt: string, receipts: number, serialNumber: number, artist: { id: string, name: string, linkValue: string, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } }, artist: { id: string, name: string, linkValue: string }, user: { id: string, displayName: string | null, computedDisplayName: string, username: string | null } | { id: string, displayName: string | null, computedDisplayName: string, username: string | null } } & { ' $fragmentName'?: 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment' };

type MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment = { __typename: 'ArtistMembershipPresaveReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string }, membership: { createdAt: string, receipts: number, serialNumber: number, artist: { id: string, name: string, linkValue: string, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } }, artist: { id: string, name: string, linkValue: string }, user: { id: string, displayName: string | null, computedDisplayName: string, username: string | null } | { id: string, displayName: string | null, computedDisplayName: string, username: string | null } } & { ' $fragmentName'?: 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment' };

type MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment = { __typename: 'ArtistMembershipRsvpEventReceipt', id: string, createdAt: string, serialNumber: number, rsvpEvent: { id: string, title: string } | { id: string, title: string } | { id: string, title: string }, membership: { createdAt: string, receipts: number, serialNumber: number, artist: { id: string, name: string, linkValue: string, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } }, artist: { id: string, name: string, linkValue: string }, user: { id: string, displayName: string | null, computedDisplayName: string, username: string | null } | { id: string, displayName: string | null, computedDisplayName: string, username: string | null } } & { ' $fragmentName'?: 'MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment' };

export type MembershipReceiptFragment = MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment | MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment | MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment;

export type MyVaultSubscriptionFragment = { id: string, vault: { id: string, artist: (
      { id: string }
      & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
    ) | null } } & { ' $fragmentName'?: 'MyVaultSubscriptionFragment' };

export type MenuVaultSubscriptionFragment = { createdAt: string, id: string, updatedAt: string, status: VaultSubscriptionStatus, vault: { isUserArtistAdmin: boolean, contentCount: number, id: string, artist: (
      { id: string, linkValue: string }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null } } & { ' $fragmentName'?: 'MenuVaultSubscriptionFragment' };

export type GetPriorityActiveVaultSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPriorityActiveVaultSubscriptionsQuery = { activeVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'MenuVaultSubscriptionFragment': MenuVaultSubscriptionFragment;'MyVaultSubscriptionFragment': MyVaultSubscriptionFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CustomizeVaultViewFragment = { id: string, accentColor: any, backgroundColor: any, artistId: string | null, artistProfile: { id: string, name: string, instagramHandle: string | null, spotifyUrl: string | null, tiktokHandle: string | null, customWebsiteUrl: string | null, profileImage: { id: string, mediaType: MediaType, artistProfileImageUrl: string | null } } | null, logoImage: { id: string, mediaType: MediaType, logoImageUrl: string | null } | null } & { ' $fragmentName'?: 'CustomizeVaultViewFragment' };

export type CustomizeVaultMutationVariables = Exact<{
  input: MutationCustomizeVaultInput;
}>;


export type CustomizeVaultMutation = { customizeVault: { __typename: 'MutationCustomizeVaultSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'CustomizeVaultViewFragment': CustomizeVaultViewFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type VaultCustomizationQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultCustomizationQuery = { vaultFromId: (
    { id: string }
    & { ' $fragmentRefs'?: { 'CustomizeVaultViewFragment': CustomizeVaultViewFragment } }
  ) | null };

export type ArtistSettingsViewFragment = { id: string, name: string, description: string, customWebsiteUrl: string | null, spotifyUrl: string | null, instagramHandle: string | null, tiktokHandle: string | null, profileImage: { id: string, mediaType: MediaType, artistSmallProfileImageUrl: string | null }, mainVault: { id: string, welcomeMessage: string | null }, phoneNumbers: Array<{ phoneNumber: string, phoneCountryCode: SupportedPhoneCountryCode }> } & { ' $fragmentName'?: 'ArtistSettingsViewFragment' };

export type ArtistProfileSettingsQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type ArtistProfileSettingsQuery = { artistById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'ArtistSettingsViewFragment': ArtistSettingsViewFragment } }
  ) };

export type VaultTrackByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type VaultTrackByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, linkValue: string, isFullVersionAvailable: boolean } | { __typename: 'VaultImage', blurredMediaUrl: string | null, id: string, linkValue: string, isFullVersionAvailable: boolean, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null } | (
    { __typename: 'VaultTrack', id: string, linkValue: string, isFullVersionAvailable: boolean }
    & { ' $fragmentRefs'?: { 'TrackRowInfoFragment': TrackRowInfoFragment } }
  ) | { __typename: 'VaultVideo', blurredMediaUrl: string | null, id: string, linkValue: string, isFullVersionAvailable: boolean, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null } | null };

export type VaultMessageAttachmentFragment = { id: string, vaultContent: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } | { id: string, title: string } | null } & { ' $fragmentName'?: 'VaultMessageAttachmentFragment' };

export type ReplyToMessageFragment = { id: string, createdAt: string, content: string, activeSubscriptionTier: TierTypename | null, creator: { __typename: 'MessageActorArtist', id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null } } } | { __typename: 'MessageActorUser', id: string, user: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } | { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } }, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )> } & { ' $fragmentName'?: 'ReplyToMessageFragment' };

export type MessageBubbleFragment = { id: string, content: string, createdAt: string, pinnedPriority: number | null, activeSubscriptionTier: TierTypename | null, replyToWasDeleted: boolean, creator: { __typename: 'MessageActorArtist', id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null } } } | { __typename: 'MessageActorUser', id: string, user: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } | { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null } } }, artistReactions: Array<(
    { id: string, type: MessageReactionType, emojiKeyword: string }
    & { ' $fragmentRefs'?: { 'ArtistReactionsMessageReactionRowFragment': ArtistReactionsMessageReactionRowFragment } }
  )>, myReactions: Array<(
    { id: string, type: MessageReactionType, emojiKeyword: string }
    & { ' $fragmentRefs'?: { 'MyReactionsMessageReactionRowFragment': MyReactionsMessageReactionRowFragment } }
  )>, reactionsSummary: Array<{ ' $fragmentRefs'?: { 'MessageReactionRowFragment': MessageReactionRowFragment } }>, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )>, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, replyTo: (
    { id: string }
    & { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } }
  ) | null } & { ' $fragmentName'?: 'MessageBubbleFragment' };

export type MessageSubscriptionBubbleFragment = { id: string, content: string, createdAt: string, source: MessageSource, activeSubscriptionTier: TierTypename | null, asArtist: { id: string, linkValue: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, creatorUser: { id: string, computedDisplayName: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, userSmallProfileImageUrl: string | null } } | null, vaultContent: Array<{ id: string, vaultContent: { id: string, title: string | null } | null }>, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, replyTo: { id: string, content: string, createdAt: string, activeSubscriptionTier: TierTypename | null, asArtist: { id: string, linkValue: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, creatorUser: { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, url: string, userSmallProfileImageUrl: string | null } } | null, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, vaultContent: Array<{ id: string, vaultContent: { id: string } | null }> } | null } & { ' $fragmentName'?: 'MessageSubscriptionBubbleFragment' };

export type MessageReactionRowFragment = { type: MessageReactionType, emojiKeyword: string, count: number } & { ' $fragmentName'?: 'MessageReactionRowFragment' };

export type MyReactionsMessageReactionRowFragment = { id: string, type: MessageReactionType, emojiKeyword: string } & { ' $fragmentName'?: 'MyReactionsMessageReactionRowFragment' };

export type ArtistReactionsMessageReactionRowFragment = { id: string, type: MessageReactionType, emojiKeyword: string } & { ' $fragmentName'?: 'ArtistReactionsMessageReactionRowFragment' };

export type SendMessageMutationVariables = Exact<{
  input: MutationCreateMessageInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type SendMessageMutation = { createMessage: { __typename: 'MutationCreateMessageSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'RateLimitError', message: string } | { __typename: 'ValidationError', message: string } };

export type GetBanStatusQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type GetBanStatusQuery = { getBanStatus: boolean };

export type PinnedMessageFragment = { id: string, content: string, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )> } & { ' $fragmentName'?: 'PinnedMessageFragment' };

export type EarningsRowFragment = { id: string | null, month: number, year: number, amount: number } & { ' $fragmentName'?: 'EarningsRowFragment' };

export type PaymentMethodRowFragment = { id: string, last4: string, brand: string, expMonth: number, expYear: number } & { ' $fragmentName'?: 'PaymentMethodRowFragment' };

export type RsvpPublicDropFragment = { eventDate: string | null, title: string, lateRsvpsEnabled: boolean, description: string | null, status: RsvpEventStatus, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null } & { ' $fragmentName'?: 'RsvpPublicDropFragment' };

export type RsvpPrivateDropFragment = { eventDate: string | null, title: string, lateRsvpsEnabled: boolean, description: string | null, status: RsvpEventStatus, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null } & { ' $fragmentName'?: 'RsvpPrivateDropFragment' };

type RsvpDropView_InactiveRsvpEvent_Fragment = { __typename: 'InactiveRsvpEvent', id: string, linkValue: string, artist: (
    { id: string, name: string, linkValue: string, isAuthUserAdmin: boolean, membershipCardImage: { id: string, membershipCardImageOptimizedUrl: string | null } | null, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null } }
    & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
  ) } & { ' $fragmentName'?: 'RsvpDropView_InactiveRsvpEvent_Fragment' };

type RsvpDropView_PublicRsvpEvent_Fragment = (
  { __typename: 'PublicRsvpEvent', id: string, linkValue: string, artist: (
    { id: string, name: string, linkValue: string, isAuthUserAdmin: boolean, membershipCardImage: { id: string, membershipCardImageOptimizedUrl: string | null } | null, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null } }
    & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
  ) }
  & { ' $fragmentRefs'?: { 'RsvpPublicDropFragment': RsvpPublicDropFragment } }
) & { ' $fragmentName'?: 'RsvpDropView_PublicRsvpEvent_Fragment' };

type RsvpDropView_RsvpEventPrivateInfo_Fragment = (
  { __typename: 'RsvpEventPrivateInfo', id: string, linkValue: string, artist: (
    { id: string, name: string, linkValue: string, isAuthUserAdmin: boolean, membershipCardImage: { id: string, membershipCardImageOptimizedUrl: string | null } | null, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null } }
    & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
  ) }
  & { ' $fragmentRefs'?: { 'RsvpPrivateDropFragment': RsvpPrivateDropFragment } }
) & { ' $fragmentName'?: 'RsvpDropView_RsvpEventPrivateInfo_Fragment' };

export type RsvpDropViewFragment = RsvpDropView_InactiveRsvpEvent_Fragment | RsvpDropView_PublicRsvpEvent_Fragment | RsvpDropView_RsvpEventPrivateInfo_Fragment;

export type RsvpToEventMutationVariables = Exact<{
  input: MutationRsvpToEventInput;
}>;


export type RsvpToEventMutation = { rsvpToEvent: { __typename: 'MutationRsvpToEventSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'UserNotSubscribedToVaultError', message: string } };

export type CheckMembershipForRsvpQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type CheckMembershipForRsvpQuery = { artistByLink: { id: string, isAuthUserAdmin: boolean, mainVault: { id: string, activeSubscription: { id: string } | null } } | null };

export type CreateRsvpEventMutationVariables = Exact<{
  input: MutationCreateRsvpEventInput;
}>;


export type CreateRsvpEventMutation = { createRsvpEvent: { __typename: 'MutationCreateRsvpEventSuccess', data: (
      { id: string, linkValue: string }
      & { ' $fragmentRefs'?: { 'RsvpEventCreatedFragment': RsvpEventCreatedFragment } }
    ) } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type UpdateRsvpEventMutationVariables = Exact<{
  input: MutationUpdateRsvpEventInput;
}>;


export type UpdateRsvpEventMutation = { updateRsvpEvent: { __typename: 'MutationUpdateRsvpEventSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeleteRsvpEventMutationVariables = Exact<{
  input: MutationDeleteRsvpEventInput;
}>;


export type DeleteRsvpEventMutation = { deleteRsvpEvent: { __typename: 'MutationDeleteRsvpEventSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeactivateRsvpEventMutationVariables = Exact<{
  input: MutationDeactivateRsvpEventInput;
}>;


export type DeactivateRsvpEventMutation = { deactivateRsvpEvent: { __typename: 'MutationDeactivateRsvpEventSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type ReactivateRsvpEventMutationVariables = Exact<{
  input: MutationReactivateRsvpEventInput;
}>;


export type ReactivateRsvpEventMutation = { reactivateRsvpEvent: { __typename: 'MutationReactivateRsvpEventSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type ArtistNotificationRowFragment = { id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, url: string | null } } } & { ' $fragmentName'?: 'ArtistNotificationRowFragment' };

export type ArtistMessageChannelNotificationOverridesQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type ArtistMessageChannelNotificationOverridesQuery = { artistMessageChannelInvolvements: { pageInfo: { hasNextPage: boolean, endCursor: string | null }, edges: Array<{ node: { messageChannel: (
          { id: string, details: { titleText: string } | null }
          & { ' $fragmentRefs'?: { 'OverrideNotificationRowFragment': OverrideNotificationRowFragment } }
        ) } }> } };

export type RemoveAllMessageChannelOverridesMutationVariables = Exact<{
  input: MutationRemoveAllMessageChannelParticipantUserSettingsInput;
}>;


export type RemoveAllMessageChannelOverridesMutation = { removeAllMessageChannelParticipantUserSettings: boolean };

export type RemoveMessageChannelOverrideMutationVariables = Exact<{
  input: MutationRemoveMessageChannelParticipantUserSettingsInput;
}>;


export type RemoveMessageChannelOverrideMutation = { removeMessageChannelParticipantUserSettings: boolean };

export type OverrideNotificationRowFragment = { id: string, channelType: MessageChannelType, details: { titleText: string, isPushNotificationEnabled: boolean, isSmsEnabled: boolean | null, coverImage: { id: string, cdnUrl: string, url: string | null } | null } | null } & { ' $fragmentName'?: 'OverrideNotificationRowFragment' };

export type GetCampaignForAudioPlayerQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetCampaignForAudioPlayerQuery = { releaseCampaignByIdOrSlug: { id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } } | { id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } } | { id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } } | null };

export type ArtistSubscribeHeaderInfoFragment = { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, mainVault: { price: number | null } } & { ' $fragmentName'?: 'ArtistSubscribeHeaderInfoFragment' };

export type TrackFileInfoFragment = { __typename: 'VaultTrack', id: string, title: string, linkValue: string, createdAt: string, parentVaultContentId: string | null, normalizedPeaks: Array<number>, vaultId: string, duration: number, commentMessageCount: number, isFullVersionAvailable: boolean, vault: { id: string, isUserArtistAdmin: boolean, artistId: string | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, freeTierSnippet: { id: string, duration: number } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> } & { ' $fragmentName'?: 'TrackFileInfoFragment' };

export type TrackRowInfoFragment = (
  { __typename: 'VaultTrack', id: string, title: string, blurredThumbnailMediaUrl: string | null, normalizedPeaks: Array<number>, duration: number, parentVaultContentId: string | null, vaultId: string, isFullVersionAvailable: boolean, thumbnailMedia: { id: string, thumbnailUrl: string | null } | null, vault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, freeTierSnippet: { id: string, duration: number } | null }
  & { ' $fragmentRefs'?: { 'WaveformTrackInfoFragment': WaveformTrackInfoFragment } }
) & { ' $fragmentName'?: 'TrackRowInfoFragment' };

export type CreateVaultContentMutationVariables = Exact<{
  input: MutationCreateVaultContentInput;
}>;


export type CreateVaultContentMutation = { createVaultContent: { __typename: 'AlreadyExistsError', message: string } | { __typename: 'ContentNotifierPublishError', message: string } | { __typename: 'MutationCreateVaultContentSuccess', data: (
      { __typename: 'VaultFolder', linkValue: string, id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
    ) | (
      { __typename: 'VaultImage', linkValue: string, id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
    ) | (
      { __typename: 'VaultTrack', linkValue: string, id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment;'TrackFileInfoFragment': TrackFileInfoFragment } }
    ) | (
      { __typename: 'VaultVideo', linkValue: string, id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'TranscodePublishError', message: string } | { __typename: 'ValidationError', message: string } | null };

export type EditVaultContentMutationVariables = Exact<{
  input: MutationEditVaultContentInput;
}>;


export type EditVaultContentMutation = { editVaultContent: { __typename: 'MutationEditVaultContentSuccess', data: (
      { __typename: 'VaultFolder', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
    ) | (
      { __typename: 'VaultImage', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
    ) | (
      { __typename: 'VaultTrack', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'TrackRowInfoFragment': TrackRowInfoFragment;'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment;'TrackItemFragment': TrackItemFragment } }
    ) | (
      { __typename: 'VaultVideo', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

type UserRow_PrivateUser_Fragment = { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, mediaType: MediaType, dominantColor: string | null, avatarImageUrl: string | null } } & { ' $fragmentName'?: 'UserRow_PrivateUser_Fragment' };

type UserRow_PublicUser_Fragment = { id: string, username: string | null, displayName: string | null, computedDisplayName: string, createdAt: string, avatar: { id: string, cdnUrl: string, mediaType: MediaType, dominantColor: string | null, avatarImageUrl: string | null } } & { ' $fragmentName'?: 'UserRow_PublicUser_Fragment' };

export type UserRowFragment = UserRow_PrivateUser_Fragment | UserRow_PublicUser_Fragment;

export type GetTotalReceiptNumberQueryVariables = Exact<{
  input: QueryTotalReceiptCountInput;
}>;


export type GetTotalReceiptNumberQuery = { totalReceiptCount: number };

export type GetTotalActionsNumberQueryVariables = Exact<{
  input: QueryTotalActionsCountInput;
}>;


export type GetTotalActionsNumberQuery = { totalActionsCount: number };

export type ArtistVaultRowFragment = { id: string, name: string, linkValue: string, userId: string | null, profileImage: { id: string, cdnUrl: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null }, mainVault: { id: string, isUserArtistAdmin: boolean } } & { ' $fragmentName'?: 'ArtistVaultRowFragment' };

export type FolderItemFragment = { id: string, title: string, createdAt: string, parentVaultContentId: string | null, childrenVaultCount: number, nestedChildrenVaultContentCount: number, linkValue: string, vault: { id: string, activeSubscription: { id: string, createdAt: string } | null } } & { ' $fragmentName'?: 'FolderItemFragment' };

export type ImageItemFragment = { id: string, title: string, caption: string | null, linkValue: string, createdAt: string, blurredMediaUrl: string | null, downloadEnabled: boolean, vaultId: string, parentVaultContentId: string | null, uploadedMedia: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null, smallImageUrl: string | null } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'ImageItemFragment' };

export type TrackItemFragment = { id: string, title: string, caption: string | null, linkValue: string, createdAt: string, vaultId: string, duration: number, commentMessageCount: number, parentVaultContentId: string | null, normalizedPeaks: Array<number>, isFullVersionAvailable: boolean, blurredThumbnailMediaUrl: string | null, downloadEnabled: boolean, thumbnailMedia: { id: string, imageOptimizedUrl: string | null } | null, freeTierSnippet: { id: string, duration: number } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'TrackItemFragment' };

type VaultItem_VaultFolder_Fragment = (
  { __typename: 'VaultFolder', id: string, caption: string | null, parentVaultContentId: string | null, title: string }
  & { ' $fragmentRefs'?: { 'FolderItemFragment': FolderItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultFolder_Fragment' };

type VaultItem_VaultImage_Fragment = (
  { __typename: 'VaultImage', id: string, caption: string | null, parentVaultContentId: string | null, title: string, uploadedMedia: { id: string, mediaType: MediaType, cdnUrl: string, imageUrl: string | null } | null }
  & { ' $fragmentRefs'?: { 'ImageItemFragment': ImageItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultImage_Fragment' };

type VaultItem_VaultTrack_Fragment = (
  { __typename: 'VaultTrack', id: string, blurredThumbnailMediaUrl: string | null, caption: string | null, parentVaultContentId: string | null, title: string, thumbnailMedia: { id: string, imageSmallUrl: string | null } | null, vault: { id: string, artistProfile: { id: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null } }
  & { ' $fragmentRefs'?: { 'TrackItemFragment': TrackItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultTrack_Fragment' };

type VaultItem_VaultVideo_Fragment = (
  { __typename: 'VaultVideo', id: string, caption: string | null, parentVaultContentId: string | null, title: string, uploadedMedia: { id: string, mediaType: MediaType, videoScreenshotUrl: string | null, videoUrl: string } | null }
  & { ' $fragmentRefs'?: { 'VideoItemFragment': VideoItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultVideo_Fragment' };

export type VaultItemFragment = VaultItem_VaultFolder_Fragment | VaultItem_VaultImage_Fragment | VaultItem_VaultTrack_Fragment | VaultItem_VaultVideo_Fragment;

export type UpsertUserContentViewMutationVariables = Exact<{
  input: MutationUpsertUserViewContentInput;
}>;


export type UpsertUserContentViewMutation = { upsertUserViewContent: { id: string } | null };

export type VideoItemFragment = { id: string, title: string, linkValue: string, createdAt: string, caption: string | null, downloadEnabled: boolean, isFullVersionAvailable: boolean, blurredMediaUrl: string | null, vaultId: string, duration: number, parentVaultContentId: string | null, uploadedMedia: { id: string, mediaType: MediaType, videoScreenshotUrl: string | null, url: string } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VideoItemFragment' };

export type SignedVaultDownloadUrlByVaultIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type SignedVaultDownloadUrlByVaultIdQuery = { getSignedVaultContentDownload: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'QueryGetSignedVaultContentDownloadSuccess', data: string } };

export type ContentToShareQueryVariables = Exact<{
  linkValue: Scalars['String']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type ContentToShareQuery = { vaultContentBySlug: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultContentBySlugSuccess', data: { __typename: 'VaultFolder', id: string, title: string } | { __typename: 'VaultImage', id: string, title: string, uploadedMedia: { id: string, imageOptimizedUrl: string | null } | null } | { __typename: 'VaultTrack', id: string, title: string, thumbnailMedia: { id: string, imageOptimizedUrl: string | null } | null } | { __typename: 'VaultVideo', id: string, title: string, uploadedMedia: { id: string, cdnUrl: string, videoScreenshotUrl: string | null } | null } } | null };

export type RsvpEventToShareQueryVariables = Exact<{
  dropSlug: Scalars['String']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type RsvpEventToShareQuery = { rsvpEventByLink: { __typename: 'InactiveRsvpEvent', id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | { __typename: 'PublicRsvpEvent', id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | { __typename: 'RsvpEventPrivateInfo', id: string, title: string, coverImage: { id: string, coverImageUrl: string | null } | null, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | null };

export type CampaignToShareQueryVariables = Exact<{
  dropSlug: Scalars['String']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type CampaignToShareQuery = { releaseCampaignByIdOrSlug: { __typename: 'ReleaseCampaignInactive', id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | { __typename: 'ReleaseCampaignPrivateInfo', currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, id: string, title: string, coverImage: { id: string, coverImageUrl: string | null } | null, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | { __typename: 'ReleaseCampaignPublicInfo', id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, profileImageUrl: string | null } } } | null };

export type EditTrackSnippetFragment = (
  { id: string, duration: number, title: string, isFullVersionAvailable: boolean, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, vault: { id: string, isUserArtistAdmin: boolean, artistProfile: { id: string, name: string } | null } }
  & { ' $fragmentRefs'?: { 'WaveformTrackInfoFragment': WaveformTrackInfoFragment } }
) & { ' $fragmentName'?: 'EditTrackSnippetFragment' };

export type MessageChannelViewFragment = { id: string, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, details: { ' $fragmentRefs'?: { 'MessageChannelDetailsFragment': MessageChannelDetailsFragment } } | null, vault: { id: string, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null, messages: { pageInfo: { hasNextPage: boolean, endCursor: string | null }, edges: Array<{ cursor: string, node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
      ) }> } } & { ' $fragmentName'?: 'MessageChannelViewFragment' };

export type SeeDetailsHeaderFragment = { id: string, channelType: MessageChannelType, details: (
    { singleRecipientActorId: string | null, isPushNotificationEnabled: boolean, isSmsEnabled: boolean | null, isPushNotificationEnabledForUserMessagesInGroupChat: boolean | null, titleText: string }
    & { ' $fragmentRefs'?: { 'MessageChannelDetailsFragment': MessageChannelDetailsFragment } }
  ) | null, artist: { id: string, isAuthUserAdmin: boolean, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, vault: { id: string, isUserArtistAdmin: boolean, artistProfile: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null } & { ' $fragmentName'?: 'SeeDetailsHeaderFragment' };

export type GetArtistMessagesQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetArtistMessagesQuery = { artistMessagesInChannel: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type GetPinnedMessagesQueryVariables = Exact<{
  input: QueryMessageChannelInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetPinnedMessagesQuery = { messageChannel: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMessageChannelSuccess', data: { id: string, vault: { id: string, artistProfile: { id: string } | null } | null, pinnedMessages: Array<(
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment;'PinnedMessageFragment': PinnedMessageFragment } }
      )> } } | { __typename: 'ValidationError' } | null };

export type GetAttachmentsInMessageChannelQueryVariables = Exact<{
  channelId: Scalars['UUID']['input'];
}>;


export type GetAttachmentsInMessageChannelQuery = { messageChannelAttachments: { pageInfo: { hasNextPage: boolean, endCursor: string | null }, edges: Array<{ node: { id: string, createdAt: string, uploadedMedia: { id: string, mediaType: MediaType, cdnUrl: string, fullImageUrl: string | null, mediumImageUrl: string | null } | null } }> } };

export type ArtistSubscribeViewFragment = (
  { id: string, mainVault: { id: string, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } }
  & { ' $fragmentRefs'?: { 'ArtistSubscribeHeaderInfoFragment': ArtistSubscribeHeaderInfoFragment } }
) & { ' $fragmentName'?: 'ArtistSubscribeViewFragment' };

export type ArtistInviteViewFragment = { id: string, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistFullProfileImageUrl: string | null, fallbackImageUrl: string } } & { ' $fragmentName'?: 'ArtistInviteViewFragment' };

type InvitedUser_PrivateUser_Fragment = { id: string, displayName: string | null, computedDisplayName: string, username: string | null, createdAt: string, updatedAt: string, avatar: { id: string, cdnUrl: string, userSmallProfileImageUrl: string | null } } & { ' $fragmentName'?: 'InvitedUser_PrivateUser_Fragment' };

type InvitedUser_PublicUser_Fragment = { id: string, displayName: string | null, computedDisplayName: string, username: string | null, createdAt: string, updatedAt: string, avatar: { id: string, cdnUrl: string, userSmallProfileImageUrl: string | null } } & { ' $fragmentName'?: 'InvitedUser_PublicUser_Fragment' };

export type InvitedUserFragment = InvitedUser_PrivateUser_Fragment | InvitedUser_PublicUser_Fragment;

export type VaultInviteStatsFragment = { inviteCount: number, previewInvitedUsers: Array<{ ' $fragmentRefs'?: { 'InvitedUser_PrivateUser_Fragment': InvitedUser_PrivateUser_Fragment } } | { ' $fragmentRefs'?: { 'InvitedUser_PublicUser_Fragment': InvitedUser_PublicUser_Fragment } }> } & { ' $fragmentName'?: 'VaultInviteStatsFragment' };

export type TopInvitersFragment = { inviteCount: number, rank: number, inviter: { ' $fragmentRefs'?: { 'InvitedUser_PrivateUser_Fragment': InvitedUser_PrivateUser_Fragment } } | { ' $fragmentRefs'?: { 'InvitedUser_PublicUser_Fragment': InvitedUser_PublicUser_Fragment } } } & { ' $fragmentName'?: 'TopInvitersFragment' };

export type ArtistLandingFragment = (
  { id: string, name: string, linkValue: string, profileImage: { id: string, dominantColor: string | null, artistFullProfileImageUrl: string | null } }
  & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
) & { ' $fragmentName'?: 'ArtistLandingFragment' };

export type MessageChannelDetailsFragment = { channelRecipientHasActivePermissions: boolean, receiptCount: number | null, subscriptionTierLevel: TierTypename | null, showVerifiedBadge: boolean, username: string | null, subtitleText: string | null, titleText: string, singleRecipientActorId: string | null, coverImage: { id: string, cdnUrl: string, dominantColor: string | null, imageSmallUrl: string | null } | null, singleRecipientLocation: { country: string | null, city: string | null, region: string | null } | null } & { ' $fragmentName'?: 'MessageChannelDetailsFragment' };

export type GetMessageChannelQueryVariables = Exact<{
  input: QueryMessageChannelInput;
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetMessageChannelQuery = { messageChannel: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMessageChannelSuccess', data: (
      { id: string, channelType: MessageChannelType, details: (
        { singleRecipientActorId: string | null, channelRecipientHasActivePermissions: boolean }
        & { ' $fragmentRefs'?: { 'MessageChannelDetailsFragment': MessageChannelDetailsFragment } }
      ) | null, artist: { id: string, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, vault: { id: string, type: VaultType, price: number | null, contentCount: number, artist: { id: string, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, activeSubscription: (
          { id: string }
          & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
        ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null }
      & { ' $fragmentRefs'?: { 'MessageChannelViewFragment': MessageChannelViewFragment } }
    ) } | { __typename: 'ValidationError' } | null };

export type ArtistMainVaultViewFragment = (
  { id: string, name: string, instagramHandle: string | null, description: string, customWebsiteUrl: string | null, spotifyUrl: string | null, tiktokHandle: string | null, linkValue: string, createdAt: string, profileImage: { id: string, artistFullProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null, mainVault: { id: string, contentCount: number, memberCount: number, price: number | null, messageChannelId: string, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } }
  & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
) & { ' $fragmentName'?: 'ArtistMainVaultViewFragment' };

export type GetUserLastViewedTimeOnVaultQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type GetUserLastViewedTimeOnVaultQuery = { getUserLastViewedTimeOnVault: string | null };

export type ContentThreadCommentsQueryVariables = Exact<{
  messageId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type ContentThreadCommentsQuery = { threadMessages: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type SetUserLastViewedTimeOnVaultMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type SetUserLastViewedTimeOnVaultMutation = { setUserLastViewedTimeOnVault: { __typename: 'MutationSetUserLastViewedTimeOnVaultSuccess' } | { __typename: 'NotFoundError', message: string } };

export type UpdateUserLastViewedMessageTimeOnVaultMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type UpdateUserLastViewedMessageTimeOnVaultMutation = { updateUserLastViewedMessageTimeOnVault: { __typename: 'MutationUpdateUserLastViewedMessageTimeOnVaultSuccess', data: string } | { __typename: 'NotFoundError', message: string } };

export type VaultMessageUnreadCountsQueryVariables = Exact<{
  vaultIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type VaultMessageUnreadCountsQuery = { unreadMessageCounts: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryUnreadMessageCountsSuccess', data: Array<{ vaultId: string, unreadCount: number }> } };

export type VaultUpdateCountsQueryVariables = Exact<{
  vaultIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type VaultUpdateCountsQuery = { vaultUpdateCounts: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultUpdateCountsSuccess', data: Array<{ vaultId: string, unseenCount: number }> } };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = { createSetupIntent: { __typename: 'MutationCreateSetupIntentSuccess', data: { id: string, clientSecret: string } } | { __typename: 'StripeApiError', message: string } };

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: MutationUpdatePaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = { updatePaymentMethod: boolean | null };

export type ManageSubscriptionFragment = { id: string, createdAt: string, updatedAt: string, status: VaultSubscriptionStatus, currentPeriodEnd: string | null, stripeSubscriptionId: string | null, vault: { type: VaultType, contentCount: number, id: string, price: number | null, artist: (
      { id: string, linkValue: string, name: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, paymentMethod: (
    { id: string, active: boolean, last4: string }
    & { ' $fragmentRefs'?: { 'PaymentMethodRowFragment': PaymentMethodRowFragment } }
  ) | null } & { ' $fragmentName'?: 'ManageSubscriptionFragment' };

export type WaveformTrackInfoFragment = { id: string, normalizedPeaks: Array<number>, duration: number, parentVaultContentId: string | null, vaultId: string } & { ' $fragmentName'?: 'WaveformTrackInfoFragment' };

export type GetPaginatedVaultAnnouncementsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetPaginatedVaultAnnouncementsQuery = { announcementsForUserByArtistHandle: { edges: Array<{ node: (
        { content: string | null, scheduledAt: string, smsDeliveryStatus: ScheduledEventSmsDeliveryStatus, id: string }
        & { ' $fragmentRefs'?: { 'MmInsightsFragment': MmInsightsFragment;'MassMessageRowFragment': MassMessageRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type ScheduledEventUpdatesSubscriptionVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type ScheduledEventUpdatesSubscription = { scheduledEventUpdates: { __typename: 'NotFoundError', message: string } | { __typename: 'SubscriptionScheduledEventUpdatesSuccess', data: { __typename: 'AnnouncementUpdateSubscription', scheduledEventId: string, scheduledAt: string } | { __typename: 'ReadyScheduledEventSubscription' } } };

export type AppleMusicDeveloperTokenQueryVariables = Exact<{
  origin: Scalars['URL']['input'];
  accessSecret?: InputMaybe<Scalars['String']['input']>;
}>;


export type AppleMusicDeveloperTokenQuery = { appleMusicDeveloperToken: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryAppleMusicDeveloperTokenSuccess', data: string } };

export type ConnectAppleMusicMutationVariables = Exact<{
  input: MutationConnectAppleMusicInput;
}>;


export type ConnectAppleMusicMutation = { connectAppleMusic: { __typename: 'AppleMusicAPIError', message: string } | { __typename: 'AppleMusicAlreadyLinkedError', message: string } | { __typename: 'MutationConnectAppleMusicSuccess' } };

export type AppleMusicAuthConnectionQueryVariables = Exact<{
  userToken: Scalars['String']['input'];
}>;


export type AppleMusicAuthConnectionQuery = { appleMusicAuthConnection: { userToken: string, user: { id: string } | { id: string } | null } | null };

export type LinkAppleMusicMutationVariables = Exact<{
  input: MutationLinkAppleMusicInput;
}>;


export type LinkAppleMusicMutation = { linkAppleMusic: { __typename: 'AppleMusicAlreadyLinkedError', message: string } | { __typename: 'MutationLinkAppleMusicSuccess' } | { __typename: 'NotFoundError', message: string } };

export type UnlinkAppleMusicMutationVariables = Exact<{ [key: string]: never; }>;


export type UnlinkAppleMusicMutation = { unlinkAppleMusic: { __typename: 'MutationUnlinkAppleMusicSuccess' } | { __typename: 'NotFoundError', message: string } };

export type LastMembershipReceiptQueryVariables = Exact<{
  artistHandle?: InputMaybe<Scalars['String']['input']>;
  receiptType?: InputMaybe<ArtistMembershipReceiptTypenames>;
  releaseCampaignId?: InputMaybe<Scalars['UUID']['input']>;
  rsvpEventId?: InputMaybe<Scalars['UUID']['input']>;
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
  appleMusicUserToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type LastMembershipReceiptQuery = { lastMembershipReceipt: (
    { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
  ) | (
    { __typename: 'ArtistMembershipPresaveReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
  ) | (
    { __typename: 'ArtistMembershipRsvpEventReceipt', id: string, createdAt: string, serialNumber: number, rsvpEvent: { id: string, title: string } | { id: string, title: string } | { id: string, title: string } }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment': MembershipReceipt_ArtistMembershipRsvpEventReceipt_Fragment } }
  ) | null };

type RsvpEventItem_InactiveRsvpEvent_Fragment = { __typename: 'InactiveRsvpEvent', id: string, linkValue: string } & { ' $fragmentName'?: 'RsvpEventItem_InactiveRsvpEvent_Fragment' };

type RsvpEventItem_PublicRsvpEvent_Fragment = { __typename: 'PublicRsvpEvent', title: string, description: string | null, eventDate: string | null, status: RsvpEventStatus, id: string, linkValue: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'RsvpEventItem_PublicRsvpEvent_Fragment' };

type RsvpEventItem_RsvpEventPrivateInfo_Fragment = { __typename: 'RsvpEventPrivateInfo', title: string, description: string | null, eventDate: string | null, status: RsvpEventStatus, createdAt: string, receiptCount: number, id: string, linkValue: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } }, releaseAnnouncement: { id: string, content: string | null, status: ScheduledEventStatus } | null } & { ' $fragmentName'?: 'RsvpEventItem_RsvpEventPrivateInfo_Fragment' };

export type RsvpEventItemFragment = RsvpEventItem_InactiveRsvpEvent_Fragment | RsvpEventItem_PublicRsvpEvent_Fragment | RsvpEventItem_RsvpEventPrivateInfo_Fragment;

type ArtistEvent_ArtistReleaseCampaignEvent_Fragment = { __typename: 'ArtistReleaseCampaignEvent', id: string, createdAt: string, releaseCampaign: (
    { __typename: 'ReleaseCampaignInactive', id: string }
    & { ' $fragmentRefs'?: { 'ReleaseCampaignEventItem_ReleaseCampaignInactive_Fragment': ReleaseCampaignEventItem_ReleaseCampaignInactive_Fragment } }
  ) | (
    { __typename: 'ReleaseCampaignPrivateInfo', id: string }
    & { ' $fragmentRefs'?: { 'ReleaseCampaignEventItem_ReleaseCampaignPrivateInfo_Fragment': ReleaseCampaignEventItem_ReleaseCampaignPrivateInfo_Fragment } }
  ) | (
    { __typename: 'ReleaseCampaignPublicInfo', id: string }
    & { ' $fragmentRefs'?: { 'ReleaseCampaignEventItem_ReleaseCampaignPublicInfo_Fragment': ReleaseCampaignEventItem_ReleaseCampaignPublicInfo_Fragment } }
  ), actions: Array<{ receiptType: ArtistMembershipReceiptTypenames, authUserCompleted: boolean | null }>, artist: { id: string, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'ArtistEvent_ArtistReleaseCampaignEvent_Fragment' };

type ArtistEvent_ArtistRsvpEvent_Fragment = { __typename: 'ArtistRsvpEvent', id: string, createdAt: string, rsvpEvent: (
    { __typename: 'InactiveRsvpEvent', id: string, linkValue: string }
    & { ' $fragmentRefs'?: { 'RsvpEventItem_InactiveRsvpEvent_Fragment': RsvpEventItem_InactiveRsvpEvent_Fragment } }
  ) | (
    { __typename: 'PublicRsvpEvent', id: string, linkValue: string }
    & { ' $fragmentRefs'?: { 'RsvpEventItem_PublicRsvpEvent_Fragment': RsvpEventItem_PublicRsvpEvent_Fragment } }
  ) | (
    { __typename: 'RsvpEventPrivateInfo', id: string, linkValue: string }
    & { ' $fragmentRefs'?: { 'RsvpEventItem_RsvpEventPrivateInfo_Fragment': RsvpEventItem_RsvpEventPrivateInfo_Fragment } }
  ), actions: Array<{ receiptType: ArtistMembershipReceiptTypenames, authUserCompleted: boolean | null }>, artist: { id: string, linkValue: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } } & { ' $fragmentName'?: 'ArtistEvent_ArtistRsvpEvent_Fragment' };

export type ArtistEventFragment = ArtistEvent_ArtistReleaseCampaignEvent_Fragment | ArtistEvent_ArtistRsvpEvent_Fragment;

export type ArtistEventsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  artistEventType?: InputMaybe<ArtistEventType>;
  sort: SortDirection;
}>;


export type ArtistEventsQuery = { artistEventsByArtistHandle: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'ArtistEvent_ArtistReleaseCampaignEvent_Fragment': ArtistEvent_ArtistReleaseCampaignEvent_Fragment;'FilterMembersEventItem_ArtistReleaseCampaignEvent_Fragment': FilterMembersEventItem_ArtistReleaseCampaignEvent_Fragment } }
      ) | (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'ArtistEvent_ArtistRsvpEvent_Fragment': ArtistEvent_ArtistRsvpEvent_Fragment;'FilterMembersEventItem_ArtistRsvpEvent_Fragment': FilterMembersEventItem_ArtistRsvpEvent_Fragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type UserArtistMembershipQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
}>;


export type UserArtistMembershipQuery = { userArtistMembership: { artistId: string, serialNumber: number, unseenUpdates: number, createdAt: string, receipts: number, cardFirstTimeSeen: boolean, artist: { id: string, name: string, mainVaultId: string, linkValue: string, membershipCardImage: { id: string, membershipCardImageOptimizedUrl: string | null } | null, mainVault: { id: string, isUserArtistAdmin: boolean, activeSubscription: { id: string } | null } }, user: { id: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, userProfileImageUrl: string | null } } | { id: string, displayName: string | null, computedDisplayName: string, username: string | null, avatar: { id: string, cdnUrl: string, userProfileImageUrl: string | null } } } | null };

export type ArtistAdminReceiptsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  receiptTypes?: InputMaybe<Array<ArtistMembershipReceiptTypenames> | ArtistMembershipReceiptTypenames>;
}>;


export type ArtistAdminReceiptsQuery = { receipts: { edges: Array<{ node: (
        { __typename: 'ArtistMembershipPlayStreamReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment } }
      ) | (
        { __typename: 'ArtistMembershipPresaveReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment } }
      ) | (
        { __typename: 'ArtistMembershipRsvpEventReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment': CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type UserArtistReceiptsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  receiptTypes?: InputMaybe<Array<ArtistMembershipReceiptTypenames> | ArtistMembershipReceiptTypenames>;
}>;


export type UserArtistReceiptsQuery = { receipts: { edges: Array<{ node: (
        { __typename: 'ArtistMembershipPlayStreamReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment } }
      ) | (
        { __typename: 'ArtistMembershipPresaveReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment } }
      ) | (
        { __typename: 'ArtistMembershipRsvpEventReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment': CampaignReceiptItem_ArtistMembershipRsvpEventReceipt_Fragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CreateMassMessageMutationVariables = Exact<{
  input: MutationCreateAnnouncementInput;
}>;


export type CreateMassMessageMutation = { createAnnouncement: { __typename: 'MutationCreateAnnouncementSuccess', data: { id: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'ValidationError', message: string } };

export type EditMassMessageMutationVariables = Exact<{
  input: MutationUpdateAnnouncementInput;
}>;


export type EditMassMessageMutation = { updateAnnouncement: { __typename: 'MutationUpdateAnnouncementSuccess', data: { id: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'ValidationError', message: string } };

export type FilteredMemberCountQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  artistEventIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  memberType?: InputMaybe<Array<ScheduledEventAccessFeatureInput> | ScheduledEventAccessFeatureInput>;
  locationsV2?: InputMaybe<Array<AnnouncementLocationTargetV2Input> | AnnouncementLocationTargetV2Input>;
}>;


export type FilteredMemberCountQuery = { filteredMemberCount: number | null };

export type DeleteMessageMutationVariables = Exact<{
  input: MutationDeleteMessageInput;
}>;


export type DeleteMessageMutation = { deleteMessage: { __typename: 'MutationDeleteMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type PinMessageMutationVariables = Exact<{
  input: MutationUpdatePinnedMessageInput;
}>;


export type PinMessageMutation = { updatePinnedMessage: { __typename: 'MaximumPinnedReachedError', message: string } | { __typename: 'MutationUpdatePinnedMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type RemovePinnedMessageMutationVariables = Exact<{
  input: MutationRemovePinnedMessageInput;
}>;


export type RemovePinnedMessageMutation = { removePinnedMessage: { __typename: 'MutationRemovePinnedMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type CreateMessageReactionMutationVariables = Exact<{
  input: MutationCreateMessageReactionInput;
}>;


export type CreateMessageReactionMutation = { createMessageReaction: { __typename: 'MutationCreateMessageReactionSuccess' } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeleteMessageReactionMutationVariables = Exact<{
  input: MutationDeleteMessageReactionInput;
}>;


export type DeleteMessageReactionMutation = { deleteMessageReaction: { __typename: 'MutationDeleteMessageReactionSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type GetSubscribersBanStatusQueryVariables = Exact<{
  input: QueryGetArtistSubscribersBanStatusInput;
}>;


export type GetSubscribersBanStatusQuery = { getArtistSubscribersBanStatus: Array<{ isBanned: boolean, userId: string }> };

export type GetSpotifyAccessTokenQueryVariables = Exact<{
  authCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSpotifyAccessTokenQuery = { spotifyAuthAccessToken: { __typename: 'NotFoundError', message: string } | { __typename: 'QuerySpotifyAuthAccessTokenSuccess', data: string } | { __typename: 'SpotifyAPIError', message: string } | { __typename: 'SpotifyAuthInvalidatedError', message: string } };

export type SpotifyConnectMutationVariables = Exact<{
  input: ConnectSpotifyInput;
}>;


export type SpotifyConnectMutation = { connectSpotify: { __typename: 'MutationConnectSpotifySuccess', data: { spotifyUserId: string, product: SpotifyProductStatus | null } } | { __typename: 'SpotifyAPIError', message: string } };

export type SetSpotifyAuthStateRedirectMutationVariables = Exact<{
  input: MutationSetSpotifyStateRedirectInput;
}>;


export type SetSpotifyAuthStateRedirectMutation = { setSpotifyStateRedirect: { __typename: 'MutationSetSpotifyStateRedirectSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } };

export type GetSpotifyAuthStateRedirectQueryVariables = Exact<{
  input: QuerySpotifyAuthStateRedirectUrlInput;
}>;


export type GetSpotifyAuthStateRedirectQuery = { spotifyAuthStateRedirectUrl: { __typename: 'NotFoundError', message: string } | { __typename: 'QuerySpotifyAuthStateRedirectUrlSuccess', data: string } };

export type SpotifyLinkMutationVariables = Exact<{
  authCode: Scalars['String']['input'];
  overrideAccount: Scalars['Boolean']['input'];
}>;


export type SpotifyLinkMutation = { linkSpotify: { __typename: 'MutationLinkSpotifySuccess' } | { __typename: 'NotFoundError', message: string } | { __typename: 'SpotifyAlreadyLinkedError', message: string } };

export type SpotifyUnlinkMutationVariables = Exact<{ [key: string]: never; }>;


export type SpotifyUnlinkMutation = { unlinkSpotify: { __typename: 'MutationUnlinkSpotifySuccess' } | { __typename: 'NotFoundError', message: string } };

export type SpotifyAuthConnectionQueryVariables = Exact<{
  authCode: Scalars['String']['input'];
}>;


export type SpotifyAuthConnectionQuery = { spotifyAuthConnection: { spotifyUserId: string, product: SpotifyProductStatus | null } | null };

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: MutationUpdateArtistUserSettingsByFeatureInput;
}>;


export type UpdateNotificationSettingsMutation = { updateArtistUserSettingsByFeature: boolean };

export type UserFeatureSettingsByArtistQueryVariables = Exact<{
  input: QueryUserFeatureSettingsByArtistInput;
}>;


export type UserFeatureSettingsByArtistQuery = { userFeatureSettingsByArtist: { artistName: string, dmFromArtist: { feature: ArtistUserSettingsFeature, isSmsEnabled: boolean | null, isPushEnabled: boolean | null, radioFields: Array<{ displayName: string, key: string, enabled: boolean }> | null } | null, dmFromUser: { feature: ArtistUserSettingsFeature, isSmsEnabled: boolean | null, isPushEnabled: boolean | null, radioFields: Array<{ displayName: string, key: string, enabled: boolean }> | null } | null, newVaultContent: { feature: ArtistUserSettingsFeature, isSmsEnabled: boolean | null, isPushEnabled: boolean | null, radioFields: Array<{ displayName: string, key: string, enabled: boolean }> | null } | null, groupChatMessage: { feature: ArtistUserSettingsFeature, isSmsEnabled: boolean | null, isPushEnabled: boolean | null, radioFields: Array<{ displayName: string, key: string, enabled: boolean }> | null } | null } };

export type GetActiveVaultSubscriptionsForSettingsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetActiveVaultSubscriptionsForSettingsQuery = { activeVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: (
        { id: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, url: string | null } } }
        & { ' $fragmentRefs'?: { 'ArtistNotificationRowFragment': ArtistNotificationRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type GetBatchedTracksViewedQueryVariables = Exact<{
  input: QueryVaultContentsAreSeenInput;
}>;


export type GetBatchedTracksViewedQuery = { vaultContentsAreSeen: Array<{ __typename: 'VaultContentViewed', vaultContentId: string, viewed: boolean | null }> };

export type ClickThroughSmsCampaignMutationVariables = Exact<{
  shortcode: Scalars['String']['input'];
}>;


export type ClickThroughSmsCampaignMutation = { clickThroughSmsCampaign: { __typename: 'MutationClickThroughSmsCampaignSuccess', data: boolean } | { __typename: 'NotFoundError', message: string } };

export type UpdateUserProfileMutationVariables = Exact<{
  input: MutationUpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { updateUserProfile: { __typename: 'MutationUpdateUserProfileSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserSettingsViewFragment': UserSettingsViewFragment } }
    ) } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type SubscribeFreeTierMutationVariables = Exact<{
  input: MutationSubscribeToVaultFreeInput;
}>;


export type SubscribeFreeTierMutation = { subscribeToVaultFree: { __typename: 'MutationSubscribeToVaultFreeSuccess', data: { id: string, status: VaultSubscriptionStatus } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type MessageChannelUpdatesSubscriptionVariables = Exact<{
  messageChannelId: Scalars['UUID']['input'];
}>;


export type MessageChannelUpdatesSubscription = { messageChannelUpdates: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'SubscriptionMessageChannelUpdatesSuccess', data: (
      { __typename: 'CreateMessageSubscription', id: string, source: MessageSource }
      & { ' $fragmentRefs'?: { 'MessageSubscriptionBubbleFragment': MessageSubscriptionBubbleFragment } }
    ) | { __typename: 'DeleteMessageSubscription', id: string } | { __typename: 'PinMessageSubscription', id: string, pinnedPriority: number | null } | { __typename: 'ReactionMessageSubscription', id: string, reactionType: MessageReactionType, created: boolean, userId: string, reactionTotalCount: number, isArtistReaction: boolean | null, asArtistId: string | null, emojiKeyword: string } | { __typename: 'ReadyMessageSubscription' } | { __typename: 'UnpinAllMessageSubscription', count: number } } };

export type MemberRowFragment = { id: string, createdAt: string, status: VaultSubscriptionStatus, email: string | null, phone: string | null, artistDMChannelId: string | null, isTrial: boolean | null, vaultSubscriptionSourceText: string | null, vaultSubscriptionSourceType: VaultSubscriptionSourceType | null, artistMembership: { receipts: number } | null, user: (
    { id: string }
    & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
  ), tier: { __typename: 'FreeTier' } | { __typename: 'PaidTier' }, userLocation: { city: string | null, country: string | null, region: string | null, countryIsoCode: IsoCountry | null } | null, joinedViaReferralCode: { ' $fragmentRefs'?: { 'ReferralCodeInfoFragment': ReferralCodeInfoFragment } } | null, artist: { id: string, mainVault: { id: string, type: VaultType } } } & { ' $fragmentName'?: 'MemberRowFragment' };

export type GetMySubscribersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  status?: InputMaybe<VaultSubscriptionStatus>;
  tierLevel?: InputMaybe<SubscriptionTierLevel>;
  sort: SortDirection;
  searchQueryString?: InputMaybe<Scalars['String']['input']>;
  shouldFilterBannedUsers?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMySubscribersQuery = { mySubscribers: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'MemberRowFragment': MemberRowFragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type MySubScribersCsvUrlMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type MySubScribersCsvUrlMutation = { getMySubscribersCsvUrl: { __typename: 'MutationGetMySubscribersCsvUrlSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'S3UploadError', message: string } | null };

export type MySubscribersSummaryQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type MySubscribersSummaryQuery = { mySubscribersSummary: { activeSubscriptionsCount: number, activePaidSubscriptionsCount: number, activeFreeSubscriptionsCount: number, activeFreeSubscriptionsCountLast7Days: number, activePaidSubscriptionsCountLast7Days: number, activeSubscriptionsCountLast7Days: number }, vaultFromId: { id: string, type: VaultType } | null };

export type UserNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationSettingsQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { id: string, notificationSettings: { isSmsEnabled: boolean, pushNotificationsEnabled: boolean } }
      & { ' $fragmentRefs'?: { 'UserNotificationSettingsFragment': UserNotificationSettingsFragment } }
    ) } };

export type UpdateUserSmsNotificationSettingsMutationVariables = Exact<{
  input: MutationUpdateSmsNotificationsSettingsInput;
}>;


export type UpdateUserSmsNotificationSettingsMutation = { updateSmsNotificationsSettings: (
    { id: string }
    & { ' $fragmentRefs'?: { 'UserNotificationSettingsFragment': UserNotificationSettingsFragment } }
  ) };

export type UpdateUserPushNotificationSettingsMutationVariables = Exact<{
  input: MutationUpdatePushNotificationsSettingsInput;
}>;


export type UpdateUserPushNotificationSettingsMutation = { updatePushNotificationsSettings: (
    { id: string }
    & { ' $fragmentRefs'?: { 'UserNotificationSettingsFragment': UserNotificationSettingsFragment } }
  ) };

export type ReferralCodeInfoFragment = { id: string, code: string, description: string | null, freeMonths: number, validUntil: string, isActive: boolean, postPurchaseMessage: string | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, mainVault: { id: string, price: number | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null } } } & { ' $fragmentName'?: 'ReferralCodeInfoFragment' };

export type ArtistReferralCodeQueryVariables = Exact<{
  input: QueryGetArtistReferralCodeByCodeInput;
}>;


export type ArtistReferralCodeQuery = { getArtistReferralCodeByCode: (
    { id: string }
    & { ' $fragmentRefs'?: { 'ReferralCodeInfoFragment': ReferralCodeInfoFragment } }
  ) | null };

export type SetLastReadMessageChannelMutationVariables = Exact<{
  input: MutationSetLastReadAtChannelInput;
}>;


export type SetLastReadMessageChannelMutation = { setLastReadAtChannel: boolean };

export type HighlightedFeatureQueryVariables = Exact<{
  vaultId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type HighlightedFeatureQuery = { highlightedFeature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } | null };

export type SeenFeatureMutationVariables = Exact<{
  featureKey: FeatureTypename;
}>;


export type SeenFeatureMutation = { seenFeature: { data: string } | { message: string } };

export type EditSnippetMutationVariables = Exact<{
  input: MutationEditVaultContentSnippetInput;
}>;


export type EditSnippetMutation = { editVaultContentSnippet: { __typename: 'MutationEditVaultContentSnippetSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type GetActiveVaultsSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetActiveVaultsSubscriptionsQuery = { activeVaultSubscriptions: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'MenuVaultSubscriptionFragment': MenuVaultSubscriptionFragment;'MyVaultSubscriptionFragment': MyVaultSubscriptionFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type SubscribeToVaultMutationVariables = Exact<{
  input: MutationSubscribeToVaultInput;
}>;


export type SubscribeToVaultMutation = { subscribeToVault: { __typename: 'MutationSubscribeToVaultSuccess', data: { id: string, status: VaultSubscriptionStatus, vault: { id: string } } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'StripeApiError', message: string } };

export type CancelSubscriptionMutationVariables = Exact<{
  input: MutationCancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { cancelSubscription: { __typename: 'MutationCancelSubscriptionSuccess', data: { id: string, vault: { id: string } } } | { __typename: 'NotFoundError', message: string } };

export type EnabledFeatureFragment = { feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } } & { ' $fragmentName'?: 'EnabledFeatureFragment' };

type TierEnabledFeatures_FreeTier_Fragment = { __typename: 'FreeTier', enabledFeatures: Array<{ ' $fragmentRefs'?: { 'EnabledFeatureFragment': EnabledFeatureFragment } }> } & { ' $fragmentName'?: 'TierEnabledFeatures_FreeTier_Fragment' };

type TierEnabledFeatures_PaidTier_Fragment = { __typename: 'PaidTier', enabledFeatures: Array<{ ' $fragmentRefs'?: { 'EnabledFeatureFragment': EnabledFeatureFragment } }> } & { ' $fragmentName'?: 'TierEnabledFeatures_PaidTier_Fragment' };

export type TierEnabledFeaturesFragment = TierEnabledFeatures_FreeTier_Fragment | TierEnabledFeatures_PaidTier_Fragment;

export type ActiveSubscriptionFeaturesFragment = { tier: { ' $fragmentRefs'?: { 'TierEnabledFeatures_FreeTier_Fragment': TierEnabledFeatures_FreeTier_Fragment } } | { ' $fragmentRefs'?: { 'TierEnabledFeatures_PaidTier_Fragment': TierEnabledFeatures_PaidTier_Fragment } } } & { ' $fragmentName'?: 'ActiveSubscriptionFeaturesFragment' };

export type UpdateMessageChannelParticipantUserSettingsMutationVariables = Exact<{
  input: MutationUpdateMessageChannelParticipantUserSettingsInput;
}>;


export type UpdateMessageChannelParticipantUserSettingsMutation = { updateMessageChannelParticipantUserSettings: { __typename: 'MessageChannelParticipant', actor: { __typename: 'MessageActorArtist', id: string, artist: { id: string, name: string, linkValue: string } } | { __typename: 'MessageActorUser', id: string, user: { id: string, displayName: string | null, username: string | null } | { id: string, displayName: string | null, username: string | null } } } };

export type UpdateUpsellInterstitialsMutationVariables = Exact<{
  input: MutationUpdateUpsellInterstitialsInput;
}>;


export type UpdateUpsellInterstitialsMutation = { updateUpsellInterstitials: { firstChat: boolean, firstSnippetShare: boolean, firstTrack: boolean } };

export type MessageChannelInvolvementsSubscriptionSubscriptionVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type MessageChannelInvolvementsSubscriptionSubscription = { messageChannelInvolvementUpdates: { __typename: 'NotAuthorizedError' } | { __typename: 'NotFoundError' } | { __typename: 'SubscriptionMessageChannelInvolvementUpdatesSuccess', data: { __typename: 'DeletedInvolvedMessageSubscription', channelId: string, deletedAt: string, messageId: string, newLastMessage: { messageId: string, messageContent: string, messageCreatedAt: string } | null } | { __typename: 'NewInvolvedMessageSubscription', channelId: string, subscriptionTierLevel: TierTypename | null, titleText: string, subtitleText: string | null, receiptCount: number | null, showVerifiedBadge: boolean, messageId: string, messageContent: string, channelType: MessageChannelType, userId: string | null, artistId: string, messageCreatedAt: string, coverImage: { cdnUrl: string, smallCoverImageUrl: string } | null } | { __typename: 'ReadyMessageSubscription', now: string } } };

export type UserChannelRowFragment = { hasUnreadMessages: boolean, messageChannel: { id: string, createdAt: string, channelType: MessageChannelType, latestMessage: { id: string, content: string, createdAt: string } | null, artist: { id: string } | null, details: { singleRecipientActorId: string | null, receiptCount: number | null, subscriptionTierLevel: TierTypename | null, subtitleText: string | null, titleText: string, showVerifiedBadge: boolean, isPushNotificationEnabled: boolean, isSmsEnabled: boolean | null, isPushNotificationEnabledForUserMessagesInGroupChat: boolean | null, coverImage: { id: string, cdnUrl: string, smallCoverImageUrl: string | null } | null } | null } } & { ' $fragmentName'?: 'UserChannelRowFragment' };

export type GetUserChannelsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  artistHandle: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  filterType: MessageChannelInvolvementFilter;
  first: Scalars['Int']['input'];
  sort: SortDirection;
}>;


export type GetUserChannelsQuery = { artistMessageChannelInvolvements: { edges: Array<{ cursor: string, node: (
        { messageChannel: { id: string, channelType: MessageChannelType } }
        & { ' $fragmentRefs'?: { 'UserChannelRowFragment': UserChannelRowFragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type VaultContentPaginationQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<VaultContentType>;
}>;


export type VaultContentPaginationQuery = { vaultContent: { edges: Array<{ node: (
        { __typename: 'VaultFolder', id: string, title: string, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', duration: number, id: string, title: string, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackRowInfoFragment': TrackRowInfoFragment;'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type VaultContentByFolderQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type VaultContentByFolderQuery = { vaultContentBySortTime: { edges: Array<{ cursor: string, node: (
        { __typename: 'VaultFolder', id: string, title: string, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', id: string, title: string, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

type VaultContentLandingPage_VaultFolder_Fragment = { __typename: 'VaultFolder', id: string, isFullVersionAvailable: boolean, title: string, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: (
      { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultFolder_Fragment' };

type VaultContentLandingPage_VaultImage_Fragment = { __typename: 'VaultImage', id: string, blurredMediaUrl: string | null, fileTypeExtension: string | null, isFullVersionAvailable: boolean, title: string, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: (
      { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultImage_Fragment' };

type VaultContentLandingPage_VaultTrack_Fragment = { __typename: 'VaultTrack', id: string, normalizedPeaks: Array<number>, createdAt: string, duration: number, title: string, linkValue: string, caption: string | null, commentMessageCount: number, isFullVersionAvailable: boolean, parentVaultContentId: string | null, thumbnailMedia: { id: string, cdnUrl: string } | null, snippetVideo: { id: string, url: string } | null, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: (
      { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, commentPinnedMessage: (
    { id: string, content: string }
    & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
  ) | null, commentCaptionMessage: (
    { id: string, content: string }
    & { ' $fragmentRefs'?: { 'ContentCommentRowFragment': ContentCommentRowFragment } }
  ) | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultTrack_Fragment' };

type VaultContentLandingPage_VaultVideo_Fragment = { __typename: 'VaultVideo', id: string, blurredMediaUrl: string | null, duration: number, fileTypeExtension: string | null, isFullVersionAvailable: boolean, title: string, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: (
      { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null }, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultVideo_Fragment' };

export type VaultContentLandingPageFragment = VaultContentLandingPage_VaultFolder_Fragment | VaultContentLandingPage_VaultImage_Fragment | VaultContentLandingPage_VaultTrack_Fragment | VaultContentLandingPage_VaultVideo_Fragment;

export type VaultContentByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type VaultContentByIdQuery = { vaultContentById: (
    { __typename: 'VaultFolder', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultFolder_Fragment': VaultContentLandingPage_VaultFolder_Fragment } }
  ) | (
    { __typename: 'VaultImage', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultImage_Fragment': VaultContentLandingPage_VaultImage_Fragment } }
  ) | (
    { __typename: 'VaultTrack', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultTrack_Fragment': VaultContentLandingPage_VaultTrack_Fragment } }
  ) | (
    { __typename: 'VaultVideo', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultVideo_Fragment': VaultContentLandingPage_VaultVideo_Fragment } }
  ) | null };

export type VaultContentBySlugQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  vaultContentSlug: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type VaultContentBySlugQuery = { vaultContentBySlug: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultContentBySlugSuccess', data: (
      { __typename: 'VaultFolder', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultFolder_Fragment': VaultContentLandingPage_VaultFolder_Fragment } }
    ) | (
      { __typename: 'VaultImage', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultImage_Fragment': VaultContentLandingPage_VaultImage_Fragment } }
    ) | (
      { __typename: 'VaultTrack', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultTrack_Fragment': VaultContentLandingPage_VaultTrack_Fragment } }
    ) | (
      { __typename: 'VaultVideo', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultVideo_Fragment': VaultContentLandingPage_VaultVideo_Fragment } }
    ) } | null };

export type VaultContentByFolderPositionQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type VaultContentByFolderPositionQuery = { vaultContentByFolderPosition: { edges: Array<{ cursor: string, node: (
        { __typename: 'VaultFolder', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type PinnedMessageChannelQueryVariables = Exact<{
  input: QueryMessageChannelInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type PinnedMessageChannelQuery = { messageChannel: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMessageChannelSuccess', data: (
      { id: string, vault: { id: string, artist: { id: string } | null, activeSubscription: (
          { id: string }
          & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
        ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null, pinnedMessages: Array<(
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment;'PinnedMessageFragment': PinnedMessageFragment } }
      )> }
      & { ' $fragmentRefs'?: { 'MessageChannelLayoutInfoFragment': MessageChannelLayoutInfoFragment } }
    ) } | { __typename: 'ValidationError' } | null };

export type UnpinAllMessagesMutationVariables = Exact<{
  input: MutationRemoveAllPinnedMessageInput;
}>;


export type UnpinAllMessagesMutation = { removeAllPinnedMessage: { __typename: 'MutationRemoveAllPinnedMessageSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type VaultThemeByArtistHandleQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type VaultThemeByArtistHandleQuery = { artistByLink: { id: string, mainVaultId: string, name: string, linkValue: string, mainVault: { id: string, type: VaultType, accentColor: any, backgroundColor: any, logoImage: { id: string, logoImageUrl: string | null } | null }, profileImage: { id: string, artistFullProfileImageUrl: string | null } } | null };

export type VaultThemeByVaultIdQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultThemeByVaultIdQuery = { vaultFromId: { id: string, type: VaultType, accentColor: any, backgroundColor: any, logoImage: { id: string, logoImageUrl: string | null } | null, artistProfile: { id: string, name: string, profileImage: { id: string, artistFullProfileImageUrl: string | null } } | null } | null };

export type MoveToFolderMutationVariables = Exact<{
  input: MutationMoveContentsToVaultFolderInput;
}>;


export type MoveToFolderMutation = { moveContentsToVaultFolder: { __typename: 'MutationMoveContentsToVaultFolderSuccess', data: Array<{ __typename: 'VaultFolder', id: string, vaultId: string, title: string } | { __typename: 'VaultImage', id: string, vaultId: string, title: string } | { __typename: 'VaultTrack', id: string, vaultId: string, title: string } | { __typename: 'VaultVideo', id: string, vaultId: string, title: string }> } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type RemoveVaultContentsMutationVariables = Exact<{
  input: MutationRemoveVaultContentsInput;
}>;


export type RemoveVaultContentsMutation = { removeVaultContents: { __typename: 'MutationRemoveVaultContentsSuccess', data: Array<{ __typename: 'VaultFolder', id: string } | { __typename: 'VaultImage', id: string } | { __typename: 'VaultTrack', id: string } | { __typename: 'VaultVideo', id: string }> } | { __typename: 'NotFoundError', message: string } };

export type CreateVaultFolderMutationVariables = Exact<{
  input: MutationCreateVaultFolderInput;
}>;


export type CreateVaultFolderMutation = { createVaultFolder: { __typename: 'MutationCreateVaultFolderSuccess', data: { id: string } | { id: string } | { id: string } | { id: string } } | { __typename: 'NotFoundError', message: string } };

export type EditVaultFolderMutationVariables = Exact<{
  input: MutationUpdateVaultFolderInput;
}>;


export type EditVaultFolderMutation = { updateVaultFolder: { __typename: 'MutationUpdateVaultFolderSuccess' } | { __typename: 'NotFoundError', message: string } };

export type AuthUserFragment = (
  { id: string, phone: string | null, zipCode: string | null, username: string | null, isInternal: boolean, isAdmin: boolean, isTestPhoneUser: boolean, isOfficialVaultUser: boolean, highestSubscriptionLevel: SubscriptionTierLevel | null, displayName: string | null, authEmail: string | null, computedDisplayName: string, createdAt: string, inviteCode: string, pushNotificationsConfigurable: boolean, email: string | null, spotifyAuthConnection: { spotifyUserId: string, product: SpotifyProductStatus | null } | null, upsellInterstitials: { firstChat: boolean, firstSnippetShare: boolean, firstTrack: boolean }, artist: { id: string, name: string, userId: string | null, mainLinkValue: string, links: Array<{ value: string }>, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null, artistFullProfileImageUrl: string | null }, mainVault: { id: string, type: VaultType } } | null, avatar: { id: string, cdnUrl: string, dominantColor: string | null, userSmallProfileImageUrl: string | null }, payee: { id: string, email: string, phone: string | null, hasCompletedOnboarding: boolean } | null, appleMusicAuthConnections: Array<{ userToken: string }> }
  & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
) & { ' $fragmentName'?: 'AuthUserFragment' };

export type AuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { privyUserId: string | null, id: string, username: string | null, phone: string | null, highestSubscriptionLevel: SubscriptionTierLevel | null, isInternal: boolean, email: string | null }
      & { ' $fragmentRefs'?: { 'AuthUserFragment': AuthUserFragment } }
    ) } };

export type CheckInMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckInMutation = { userCheckIn: null | null };

export type CreateSubscriptionPaymentIntentMutationVariables = Exact<{
  input: MutationCreateSubscriptionPaymentIntentInput;
}>;


export type CreateSubscriptionPaymentIntentMutation = { createSubscriptionPaymentIntent: { __typename: 'ArtistUnfinishedExternalAccount', message: string } | { __typename: 'MutationCreateSubscriptionPaymentIntentSuccess', data: { id: string, client_secret: string, subscriptionId: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'StripeApiError', message: string } };

export type GetArtistsToExploreQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetArtistsToExploreQuery = { unsubscribedArtists: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type ArtistEventByIdQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ArtistEventByIdQuery = { artistEventById: { __typename: 'ArtistReleaseCampaignEvent', id: string, releaseCampaign: { ' $fragmentRefs'?: { 'CampaignInsightHeaderFragment': CampaignInsightHeaderFragment } } | {} } | { __typename: 'ArtistRsvpEvent', id: string, rsvpEvent: { ' $fragmentRefs'?: { 'RsvpInsightHeaderFragment': RsvpInsightHeaderFragment } } | {} } | null };

export type GetArtistInvitesPageQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type GetArtistInvitesPageQuery = { artistByLink: (
    { id: string, name: string, mainVaultId: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } }
    & { ' $fragmentRefs'?: { 'ArtistInviteViewFragment': ArtistInviteViewFragment } }
  ) | null };

export type UserVaultInviteStatsQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type UserVaultInviteStatsQuery = { userVaultInviteStats: { ' $fragmentRefs'?: { 'VaultInviteStatsFragment': VaultInviteStatsFragment } } };

export type TopVaultInvitersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type TopVaultInvitersQuery = { topVaultInviters: Array<{ ' $fragmentRefs'?: { 'TopInvitersFragment': TopInvitersFragment } }> };

export type GetMessageChannelDetailsQueryVariables = Exact<{
  input: QueryMessageChannelInput;
}>;


export type GetMessageChannelDetailsQuery = { messageChannel: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryMessageChannelSuccess', data: (
      { channelType: MessageChannelType, artist: { id: string, isAuthUserAdmin: boolean, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null, vault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
          { id: string }
          & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
        ) | null, artistProfile: { id: string, name: string, isAuthUserAdmin: boolean } | null } | null }
      & { ' $fragmentRefs'?: { 'SeeDetailsHeaderFragment': SeeDetailsHeaderFragment } }
    ) } | { __typename: 'ValidationError' } | null };

export type UpdateUserOnboardingMutationVariables = Exact<{
  input: MutationUpdateUserProfileInput;
}>;


export type UpdateUserOnboardingMutation = { updateUserProfile: { __typename: 'MutationUpdateUserProfileSuccess', data: { id: string, username: string | null } } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type GetSubscribePageArtistQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type GetSubscribePageArtistQuery = { artistLink: { artist: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ArtistSubscribeViewFragment': ArtistSubscribeViewFragment } }
    ) } | null };

export type BillingRowFragment = { id: string | null, timestamp: number, amount: number, status: string, recipientName: string | null } & { ' $fragmentName'?: 'BillingRowFragment' };

export type GetBillingQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetBillingQuery = { billing: { __typename: 'QueryBillingSuccess', data: { pageInfo: { endCursor: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: (
          { id: string | null }
          & { ' $fragmentRefs'?: { 'BillingRowFragment': BillingRowFragment } }
        ) }> } } | { __typename: 'StripeApiError', message: string } };

export type GetMyEarningsBreakdownQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']['input']>;
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}>;


export type GetMyEarningsBreakdownQuery = { earningsBreakdown: Array<{ payeeEarningAmount: number, vaultArtistName: string, vaultContentTitle: string }> };

export type MyArtistVaultQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type MyArtistVaultQuery = { artistById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
  ) };

export type GetMyEarningsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  artistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetMyEarningsQuery = { myEarnings: { __typename: 'QueryMyEarningsSuccess', data: { pageInfo: { endCursor: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: (
          { id: string | null, month: number, year: number }
          & { ' $fragmentRefs'?: { 'EarningsRowFragment': EarningsRowFragment } }
        ) }> } } | { __typename: 'StripeApiError', message: string } };

export type UserSettingsViewFragment = { id: string, username: string | null, displayName: string | null, stripeEmail: string | null, authEmail: string | null, zipCode: string | null, avatar: { id: string, cdnUrl: string, mediaType: MediaType, userSmallProfileImageUrl: string | null }, userProvidedGoogleLocation: { googlePlaceId: string, googlePlaceInputLabel: string | null, city: string | null, region: string | null, regionShort: string | null, country: string | null, isoCountry: string | null } | null } & { ' $fragmentName'?: 'UserSettingsViewFragment' };

export type UserProfileSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileSettingsQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserSettingsViewFragment': UserSettingsViewFragment } }
    ) } };

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = { paymentMethods: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'PaymentMethodRowFragment': PaymentMethodRowFragment } }
  )> };

export type RemovePaymentMethodMutationVariables = Exact<{
  input: MutationRemovePaymentMethodInput;
}>;


export type RemovePaymentMethodMutation = { removePaymentMethod: { id: string } | null };

export type GetSubscriptionByIdQueryVariables = Exact<{
  input: QueryVaultSubscriptionInput;
}>;


export type GetSubscriptionByIdQuery = { vaultSubscription: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultSubscriptionSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ManageSubscriptionFragment': ManageSubscriptionFragment } }
    ) } };

export type UserNotificationSettingsFragment = { id: string, phone: string | null, authEmail: string | null, notificationSettings: { isSmsEnabled: boolean, pushNotificationsEnabled: boolean, smsNotifications: { newArtistTrack: boolean, newSubscriber: boolean } } } & { ' $fragmentName'?: 'UserNotificationSettingsFragment' };

export type CreateConnectedAccountMutationVariables = Exact<{
  artistId: Scalars['UUID']['input'];
  stripeReturnUrl?: InputMaybe<Scalars['URL']['input']>;
}>;


export type CreateConnectedAccountMutation = { createConnectedAccount: { __typename: 'MutationCreateConnectedAccountSuccess', data: { id: string, accountLinkUrl: string } } | { __typename: 'StripeApiError', message: string } };

export type GetExternalAccountQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type GetExternalAccountQuery = { externalAccount: { __typename: 'ArtistUnfinishedExternalAccount', message: string } | { __typename: 'QueryExternalAccountSuccess', data: { id: string, brand: string | null, type: ExternalAccountType, last4: string } } | { __typename: 'StripeApiError', message: string } | null };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { deleteAccount: { __typename: 'PrivateUser', id: string } | { __typename: 'PublicUser', id: string } };

export type SetVaultWelcomeMessageMutationVariables = Exact<{
  input: MutationSetVaultWelcomeMessageInput;
}>;


export type SetVaultWelcomeMessageMutation = { setVaultWelcomeMessage: { __typename: 'MutationSetVaultWelcomeMessageSuccess', data: { id: string, welcomeMessage: string | null } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type SubscriptionRowFragment = { id: string, status: VaultSubscriptionStatus, createdAt: string, updatedAt: string, currentPeriodEnd: string | null, isTrial: boolean | null, tier: { __typename: 'FreeTier' } | { __typename: 'PaidTier' }, vault: { contentCount: number, id: string, artist: (
      { linkValue: string }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null } } & { ' $fragmentName'?: 'SubscriptionRowFragment' };

export type GetSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetSubscriptionsQuery = { allVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'SubscriptionRowFragment': SubscriptionRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CreateArtistMutationVariables = Exact<{
  input: MutationCreateArtistInput;
}>;


export type CreateArtistMutation = { createArtist: { __typename: 'Artist', id: string, linkValue: string } };

export type EditVaultContentByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type EditVaultContentByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultImage', id: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultTrack', id: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultVideo', id: string, vault: { id: string, artistId: string | null } } | null };

export type TrackSnippetDetailsByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type TrackSnippetDetailsByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultImage', id: string, title: string, vault: { id: string, artistId: string | null } } | (
    { __typename: 'VaultTrack', id: string, title: string, vault: { id: string, artistId: string | null } }
    & { ' $fragmentRefs'?: { 'EditTrackSnippetFragment': EditTrackSnippetFragment } }
  ) | { __typename: 'VaultVideo', id: string, title: string, vault: { id: string, artistId: string | null } } | null };

type FolderPage_VaultFolder_Fragment = { __typename: 'VaultFolder', id: string, childrenVaultCount: number, vaultId: string, title: string, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null } } & { ' $fragmentName'?: 'FolderPage_VaultFolder_Fragment' };

type FolderPage_VaultImage_Fragment = { __typename: 'VaultImage', id: string, vaultId: string, title: string, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null } } & { ' $fragmentName'?: 'FolderPage_VaultImage_Fragment' };

type FolderPage_VaultTrack_Fragment = { __typename: 'VaultTrack', id: string, vaultId: string, title: string, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null } } & { ' $fragmentName'?: 'FolderPage_VaultTrack_Fragment' };

type FolderPage_VaultVideo_Fragment = { __typename: 'VaultVideo', id: string, vaultId: string, title: string, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, artistSmallProfileImageUrl: string | null } } | null } } & { ' $fragmentName'?: 'FolderPage_VaultVideo_Fragment' };

export type FolderPageFragment = FolderPage_VaultFolder_Fragment | FolderPage_VaultImage_Fragment | FolderPage_VaultTrack_Fragment | FolderPage_VaultVideo_Fragment;

export type GetFolderInformationQueryVariables = Exact<{
  folderId: Scalars['UUID']['input'];
}>;


export type GetFolderInformationQuery = { __typename: 'Query', vaultContentById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultFolder_Fragment': FolderPage_VaultFolder_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultImage_Fragment': FolderPage_VaultImage_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultTrack_Fragment': FolderPage_VaultTrack_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultVideo_Fragment': FolderPage_VaultVideo_Fragment } }
  ) | null };

export type GetFolderInformationBySlugQueryVariables = Exact<{
  folderSlug: Scalars['String']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type GetFolderInformationBySlugQuery = { __typename: 'Query', vaultContentBySlug: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultContentBySlugSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultFolder_Fragment': FolderPage_VaultFolder_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultImage_Fragment': FolderPage_VaultImage_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultTrack_Fragment': FolderPage_VaultTrack_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultVideo_Fragment': FolderPage_VaultVideo_Fragment } }
    ) } | null };

export type GetAllFoldersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetAllFoldersQuery = { vaultContentBySortTime: { edges: Array<{ node: (
        { __typename: 'VaultFolder', id: string, title: string, vault: { id: string, artistProfile: { id: string, linkValue: string } | null } }
        & { ' $fragmentRefs'?: { 'FolderItemFragment': FolderItemFragment } }
      ) | { __typename: 'VaultImage', id: string } | { __typename: 'VaultTrack', id: string } | { __typename: 'VaultVideo', id: string } }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type GetCurrentFolderQueryVariables = Exact<{
  folderId: Scalars['UUID']['input'];
}>;


export type GetCurrentFolderQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultImage', id: string, title: string, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultTrack', id: string, title: string, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultVideo', id: string, title: string, parentVaultContentId: string | null, vaultId: string } | null };

export type ArtistByHandleQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type ArtistByHandleQuery = { artistLink: { artist: (
      { id: string, name: string, linkValue: string, mainVaultId: string, mainVault: { id: string, messageChannelId: string, type: VaultType, isUserArtistAdmin: boolean, welcomeMessage: string | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }>, activeSubscription: (
          { id: string, status: VaultSubscriptionStatus, tier: { __typename: 'FreeTier' } | { __typename: 'PaidTier' } }
          & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
        ) | null }, profileImage: { id: string, cdnUrl: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null, artistMediumProfileImageUrl: string | null, artistFullProfileImageUrl: string | null }, phoneNumbers: Array<{ phoneNumber: string, phoneCountryCode: SupportedPhoneCountryCode }>, membershipCardImage: { id: string, membershipCardImageUrl: string | null } | null }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment;'ArtistLayoutFragment': ArtistLayoutFragment;'ArtistMainVaultViewFragment': ArtistMainVaultViewFragment;'ArtistLandingFragment': ArtistLandingFragment } }
    ) } | null };

export type VisualVaultContentQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type VisualVaultContentQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string, caption: string | null, linkValue: string } | { __typename: 'VaultImage', id: string, blurredMediaUrl: string | null, title: string, caption: string | null, linkValue: string, uploadedMedia: { id: string, mediaType: MediaType, imageUrl: string | null } | null } | { __typename: 'VaultTrack', id: string, title: string, caption: string | null, linkValue: string } | { __typename: 'VaultVideo', id: string, blurredMediaUrl: string | null, title: string, caption: string | null, linkValue: string, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null } | null };

export type ReleaseCampaignByIdOrSlugQueryVariables = Exact<{
  artistHandle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReleaseCampaignByIdOrSlugQuery = { releaseCampaignByIdOrSlug: (
    { __typename: 'ReleaseCampaignInactive', id: string }
    & { ' $fragmentRefs'?: { 'MusicCampaignView_ReleaseCampaignInactive_Fragment': MusicCampaignView_ReleaseCampaignInactive_Fragment } }
  ) | (
    { __typename: 'ReleaseCampaignPrivateInfo', id: string }
    & { ' $fragmentRefs'?: { 'MusicCampaignView_ReleaseCampaignPrivateInfo_Fragment': MusicCampaignView_ReleaseCampaignPrivateInfo_Fragment } }
  ) | (
    { __typename: 'ReleaseCampaignPublicInfo', currentState: ReleaseCampaignState, id: string }
    & { ' $fragmentRefs'?: { 'MusicCampaignView_ReleaseCampaignPublicInfo_Fragment': MusicCampaignView_ReleaseCampaignPublicInfo_Fragment;'EarnedReceiptViewFragment': EarnedReceiptViewFragment } }
  ) | null };

export type ReleaseCampaignByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type ReleaseCampaignByIdQuery = { releaseCampaignByIdOrSlug: { __typename: 'ReleaseCampaignInactive', id: string } | { __typename: 'ReleaseCampaignPrivateInfo', id: string, contentType: ReleaseCampaignContentType, showSpotifyPresaveFirst: boolean, createdAt: string, description: string | null, showAppleMusicPresave: boolean, showSpotifyPresave: boolean, initialReleaseImageUrl: string | null, linkValue: string, currentState: ReleaseCampaignState, releaseDate: string | null, status: ReleaseCampaignStatus, title: string, isrc: string | null, upc: string | null, releaseAnnouncementMessage: string | null, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, artistProfileImagedUrl: string | null } }, coverImage: { id: string, campaignCoverImageUrl: string | null } | null, externalLinks: Array<{ cta: string | null, enabled: boolean, id: string, order: number, platform: ThirdPartyPlatform, releaseCampaignId: string, url: string }>, announcement: { id: string, content: string | null, scheduledAt: string } | null, releaseAnnouncement: { id: string, content: string | null } | null } | { __typename: 'ReleaseCampaignPublicInfo', id: string } | null };

export type RsvpEventBySlugQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  eventLinkValue: Scalars['String']['input'];
}>;


export type RsvpEventBySlugQuery = { rsvpEventByLink: (
    { __typename: 'InactiveRsvpEvent', id: string, artist: { id: string, name: string, isAuthUserAdmin: boolean, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, activeSubscription: { id: string } | null } } }
    & { ' $fragmentRefs'?: { 'RsvpDropView_InactiveRsvpEvent_Fragment': RsvpDropView_InactiveRsvpEvent_Fragment } }
  ) | (
    { __typename: 'PublicRsvpEvent', id: string, title: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, name: string, isAuthUserAdmin: boolean, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, activeSubscription: { id: string } | null } } }
    & { ' $fragmentRefs'?: { 'RsvpDropView_PublicRsvpEvent_Fragment': RsvpDropView_PublicRsvpEvent_Fragment } }
  ) | (
    { __typename: 'RsvpEventPrivateInfo', id: string, title: string, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, artist: { id: string, name: string, isAuthUserAdmin: boolean, profileImage: { id: string, artistProfileImagedUrl: string | null }, mainVault: { id: string, activeSubscription: { id: string } | null } } }
    & { ' $fragmentRefs'?: { 'RsvpDropView_RsvpEventPrivateInfo_Fragment': RsvpDropView_RsvpEventPrivateInfo_Fragment } }
  ) | null };

export type EditRsvpEventFragment = { status: RsvpEventStatus, createdAt: string, description: string | null, eventDate: string | null, linkValue: string, thankYouMessage: string | null, title: string, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, artistProfileImagedUrl: string | null } }, coverImage: { id: string, rsvpCoverImageUrl: string | null } | null, releaseAnnouncement: { id: string, content: string | null, rsvpTarget: ScheduledEventTarget | null } | null } & { ' $fragmentName'?: 'EditRsvpEventFragment' };

export type RsvpEventByIdQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type RsvpEventByIdQuery = { rsvpEventById: { __typename: 'InactiveRsvpEvent', id: string } | { __typename: 'PublicRsvpEvent', id: string } | (
    { __typename: 'RsvpEventPrivateInfo', id: string }
    & { ' $fragmentRefs'?: { 'EditRsvpEventFragment': EditRsvpEventFragment } }
  ) | null };

export type VaultInsightsHeaderQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultInsightsHeaderQuery = { mySubscribersSummary: { activeSubscriptionsCount: number, activeSubscriptionsCountLast7Days: number } };

export type EventsSummaryQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
}>;


export type EventsSummaryQuery = { myEventsSummary: { artistMembershipReceiptsCount: number, newSubscriptionsFromEventsCount: number, totalActionsCount: number } };

export type MembershipArtistQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type MembershipArtistQuery = { artistLink: { artist: (
      { id: string, name: string, linkValue: string, mainVaultId: string, createdAt: string, profileImage: { id: string, cardImageOptimizedUrl: string | null }, membershipCardImage: { id: string, membershipCardImageOptimizedUrl: string | null } | null, mainVault: { id: string, price: number | null, activeSubscription: { id: string } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'DMRead' } | { __typename: 'DMWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } }
      & { ' $fragmentRefs'?: { 'JoinArtistFragment': JoinArtistFragment } }
    ) } | null };

export type GetAnnouncementQueryVariables = Exact<{
  announcementId: Scalars['UUID']['input'];
}>;


export type GetAnnouncementQuery = { announcementById: { id: string, content: string | null, scheduledAt: string, featureAccess: Array<{ feature: { __typename: 'FreeScheduledEvent' } | { __typename: 'PaidScheduledEvent' } }>, artistEventTarget: Array<{ id: string, title: string | null }> | null, locationTargetV2: Array<{ __typename: 'AnnouncementTargetingLocationV2Geo', displayName: string, latitude: number, longitude: number, radiusMiles: number, isoCountry: IsoCountry, type: AnnouncementLocationTargetV2Type } | { __typename: 'AnnouncementTargetingLocationV2Region', isoCountry: IsoCountry, isoUsStateCode: IsousState | null, type: AnnouncementLocationTargetV2Type }> | null, vaultContentAttachment: { id: string } | { id: string } | { id: string } | { id: string } | null, messageAttachments: Array<{ id: string, cdnUrl: string, mediaType: MediaType }> | null } };

export type MassMessageRowFragment = { id: string, content: string | null, scheduledAt: string, status: ScheduledEventStatus, smsDeliveryStatus: ScheduledEventSmsDeliveryStatus, sourceType: AnnouncementSourceType | null, memberCount: number, featureAccess: Array<{ feature: { __typename: 'FreeScheduledEvent' } | { __typename: 'PaidScheduledEvent' } }>, locationTargetV2: Array<{ __typename: 'AnnouncementTargetingLocationV2Geo', displayName: string, latitude: number, longitude: number, radiusMiles: number, isoCountry: IsoCountry, type: AnnouncementLocationTargetV2Type } | { __typename: 'AnnouncementTargetingLocationV2Region', isoCountry: IsoCountry, isoUsStateCode: IsousState | null, type: AnnouncementLocationTargetV2Type }> | null, artistEventTarget: Array<{ title: string | null }> | null, rsvpEvent: { __typename: 'InactiveRsvpEvent', id: string, title: string } | { __typename: 'PublicRsvpEvent', id: string, title: string } | { __typename: 'RsvpEventPrivateInfo', id: string, title: string, coverImage: { id: string, smallRsvpEventCoverImage: string | null } | null } | null, vaultContentAttachment: { id: string, linkValue: string } | { id: string, linkValue: string } | { id: string, linkValue: string } | { id: string, linkValue: string } | null, messageAttachments: Array<{ id: string, mediaType: MediaType }> | null } & { ' $fragmentName'?: 'MassMessageRowFragment' };

export type SortVaultContentMutationVariables = Exact<{
  input: MutationSetVaultContentOrderInput;
}>;


export type SortVaultContentMutation = { setVaultContentOrder: { __typename: 'MutationSetVaultContentOrderSuccess', data: boolean } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'ValidationError', message: string } };

export type AuthorizedUserProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorizedUserProfilesQuery = { authorizedUserProfiles: Array<{ user: (
      { __typename: 'PrivateUser', phone: string | null, id: string, username: string | null, displayName: string | null, computedDisplayName: string, avatar: { id: string, cdnUrl: string, userSmallProfileImageUrl: string | null }, artist: (
        { id: string, name: string, mainVaultId: string, userId: string | null, linkValue: string, profileImage: { id: string, dominantColor: string | null, artistSmallProfileImageUrl: string | null, artistFullProfileImageUrl: string | null }, mainVault: { id: string, type: VaultType }, links: Array<{ value: string }> }
        & { ' $fragmentRefs'?: { 'ArtistProfileImageFragment': ArtistProfileImageFragment } }
      ) | null }
      & { ' $fragmentRefs'?: { 'UserProfileImage_PrivateUser_Fragment': UserProfileImage_PrivateUser_Fragment } }
    ) }> };

type UserProfileImage_PrivateUser_Fragment = { avatar: { id: string, cdnUrl: string, userSmallProfileImageUrl: string | null } } & { ' $fragmentName'?: 'UserProfileImage_PrivateUser_Fragment' };

type UserProfileImage_PublicUser_Fragment = { avatar: { id: string, cdnUrl: string, userSmallProfileImageUrl: string | null } } & { ' $fragmentName'?: 'UserProfileImage_PublicUser_Fragment' };

export type UserProfileImageFragment = UserProfileImage_PrivateUser_Fragment | UserProfileImage_PublicUser_Fragment;

export type ArtistProfileImageFragment = { profileImage: { id: string, cdnUrl: string, artistSmallProfileImageUrl: string | null } } & { ' $fragmentName'?: 'ArtistProfileImageFragment' };

export type CreateMultipartUploadMutationVariables = Exact<{
  input: MutationCreateMultipartUploadInput;
}>;


export type CreateMultipartUploadMutation = { createMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationCreateMultipartUploadSuccess', data: { uploadId: string, uploadKey: string, signedUrls: Array<{ partNumber: number, url: string }> } } | { __typename: 'NotAuthorizedError', message: string } };

export type AbortMultipartUploadMutationVariables = Exact<{
  input: MutationAbortMultipartUploadInput;
}>;


export type AbortMultipartUploadMutation = { abortMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationAbortMultipartUploadSuccess', data: { __typename: 'AbortMultipartUploadOutput', uploadKey: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type CompleteMultipartUploadMutationVariables = Exact<{
  input: MutationCompleteMultipartUploadInput;
}>;


export type CompleteMultipartUploadMutation = { completeMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationCompleteMultipartUploadSuccess', data: { mediaId: string, cdnUrl: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };
