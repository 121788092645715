import { useEffect, useRef } from 'react';
import { MediaCommentList } from '../comments/MediaCommentList';
import { View } from '../common/View';
import { type MediaView, RenderedMedia } from './RenderedMedia';

export const MediaOverlay = ({
  media,
  currentVideoRef,
  onClose,
}: {
  media: MediaView;
  currentVideoRef: React.RefObject<HTMLVideoElement> | null;
  onClose: () => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        onClose(); // Call the onClose function if the click is outside the container and not on a button
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <View className="box-content flex max-h-[90vh] w-[94vw] max-w-[1172px] flex-row rounded-xl bg-vault_text/10 backdrop-blur-2xl">
      <View className="flex aspect-square w-[65%] content-center">
        <RenderedMedia media={media} currentVideoRef={currentVideoRef} />
      </View>
      <View className="no-scrollbar border-l-1 w-[35%] flex-shrink border-b-0 border-r-0 border-t-0 border-solid border-base700">
        <MediaCommentList
          contentId={media.id}
          contentTitle={media.title}
          withVaultTheme={false}
          onClose={onClose}
        />
      </View>
    </View>
  );
};
