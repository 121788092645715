import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { faTrophy } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMutation } from '../../graphql/client';
import { FeatureTypename, SeenFeatureDocument } from '../../graphql/generated';
import type { UserReferralFeatureBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function UserReferralFeatureBottomsheet({
  artistHandle,
}: UserReferralFeatureBottomsheetProps) {
  const navigate = useNavigate();

  const { closeBottomsheet } = useBottomsheetContainer();

  const { mutateAsync } = useMutation(SeenFeatureDocument, {
    retry: 5,
  });

  // On modal open, mark the feature as seen after 300 ms
  useEffect(() => {
    setTimeout(() => {
      mutateAsync({ featureKey: FeatureTypename.UserReferrals });
    }, 300);
  }, [mutateAsync]);

  return (
    <>
      <View className="w-full md2:flex md2:h-full md2:flex-col md2:justify-center">
        <View className="my-[20px] flex w-full flex-col items-center justify-center gap-4">
          <FontAwesomeIcon icon={faTrophy} fontSize={60} className="text-white" />

          <Text className="text-center font-title text-[28px] font-medium leading-[34px] text-base50">
            Help grow
            <br />
            this vault
          </Text>
          <Text className="mb-2 text-center font-base text-[16px] font-normal leading-[20px] text-white">
            Invite friends to help this artist grow and
            <br />
            see your ranking against other fans!
          </Text>

          <Button
            type="primary"
            label="Learn more"
            className="!text-[16px]"
            onClick={() => {
              closeBottomsheet();

              trackEvent({
                type: EVENTS.VAULT_INVITES,
                properties: {
                  artistHandle,
                },
              });

              navigate(artistNavigationPath(artistHandle, '/invites'));
            }}
          />
        </View>
      </View>
    </>
  );
}
