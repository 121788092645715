import React, { type FC, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { resetActiveTrack } from '../../audio/AudioController';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { useVaultContentUpload } from '../../components/upload/useContentUpload';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { UploadContentView } from '../../components/views/upload/UploadContentView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';

import { EditVaultContentByIdDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query EditVaultContentById($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename

      id
      vault {
        id
        artistId
      }
    }
  }
`);

export const EditContentPage: FC = () => {
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const { loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const ownedArtist = useOwnedArtist({ artistHandle });

  const { fields } = useVaultContentUpload({ vaultId: ownedArtist?.mainVault.id ?? '' });

  useVaultTheme();

  const { vaultContentId } = useParams();

  const { data: vaultContent, isLoading } = useQuery(EditVaultContentByIdDocument, {
    variables: !!vaultContentId && { vaultContentId },
    staleTime: 0,
  });

  const mediaType =
    fields.contentType ??
    (vaultContent?.data.vaultContentById?.__typename === 'VaultTrack'
      ? 'Track'
      : vaultContent?.data.vaultContentById?.__typename === 'VaultImage'
        ? 'Image'
        : 'Video');

  useSetMetaHeaders({
    title: `Editing ${mediaType?.toLowerCase() ?? 'content'}`,
  });

  if (loginStatus === LoginStatus.LOADING) return <FullPageLoading withVaultTheme />;

  if (artistHandle == null || ownedArtist == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const goBack = useStableCallback(() => {
    if (
      (window.history.state.idx != null && window.history.state.idx > 0) ||
      (window.history?.length && window.history.length > 1)
    ) {
      navigate(-1);
    } else {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  });

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION, event: EVENTS.EDIT_TRACK },
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        title: 'Are you sure?',
        customContent: (
          <Text className="mb-[24px] text-center font-base text-[18px]/[24px] font-normal text-white md2:mb-[16px]">
            You have unsaved changes.
            <br />
            Keep editing or discard?
          </Text>
        ),
        onConfirm: goBack,
        event: EVENTS.EDIT_TRACK,
        cancelButtonText: 'Keep editing',
        confirmButtonText: 'Discard',
        buttonContainerClassName: 'w-full',
        confirmType: 'delete',
        fullWidth: true,
      },
    });
  });

  useEffect(() => {
    return () => {
      resetActiveTrack();
    };
  }, []);

  if (!vaultContentId || !artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    (!isLoading && !vaultContent?.data.vaultContentById) ||
    (loginStatus === LoginStatus.LOGGED_IN &&
      vaultContent?.data.vaultContentById &&
      vaultContent.data.vaultContentById.vault.artistId !== ownedArtist?.id)
  ) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={ownedArtist?.mainVault.id}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] pt-5 md2:pt-10"
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">
          {mediaType == null ? 'Edit content' : `Edit ${mediaType.toLowerCase()}`}
        </Text>
      }
      contentClassName="md2:bg-vault_text/2 overflow-y-auto overflow-x-hidden"
      showBorder
      stretch
      hideAudioPlayer
      childrenWrapperClassName="!px-0"
    >
      <UploadContentView />
    </DefaultLayout>
  );
};
