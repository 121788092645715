import type { FC } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { Logo } from '../components/svg/Logo';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useVaultTheme } from '../hooks/useVaultTheme';

export const NotFoundPage: FC = () => {
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const withVaultTheme = !!artistHandle;

  return (
    <DefaultLayout
      withVaultTheme={withVaultTheme}
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      showBorder
      withBottomNavigator={false}
      contentClassName={withVaultTheme ? 'md2:bg-vault_text/3' : 'md2:bg-white/3'}
      headerCenter={
        <View className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full">
          <Logo variant={withVaultTheme ? 'themed' : 'default'} />
        </View>
      }
    >
      <Text
        className={twMerge('text-[28px]/[34px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
      >
        Page not found
      </Text>
    </DefaultLayout>
  );
};
