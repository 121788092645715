import { useMemo } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, Text, View } from '@tamagui/core';
import { Button as TButton, type ButtonProps as TButtonProps, Spinner as TSpinner } from 'tamagui';
import { useSnapshot } from 'valtio';
import { COLOR } from '../../constants/colorConstants';
import { useStableCallback } from '../../hooks/useStableCallback';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getColorWithOpacity } from '../../utils/colorUtils';

type ButtonSize = 'xs' | 'sm' | 'md';
type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'ghost' | 'ghost-accent' | 'destructive';

type BaseButtonProps<Event extends EventType> = {
  variant?: ButtonVariant;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  useVaultTheme: boolean;
  fullWidth?: boolean;
  size?: ButtonSize;
  event?: EventObject<Event>;
  fill?: boolean;
  fitContent?: boolean;
  color?: string;
} & Omit<TButtonProps, 'variant' | 'size' | 'disabled' | 'loading' | 'fullWidth' | 'icon'>;

type ButtonProps<Event extends EventType> =
  | (BaseButtonProps<Event> & {
      icon?: IconProp;
      emojiIcon?: never;
    })
  | (BaseButtonProps<Event> & {
      emojiIcon?: React.ReactNode;
      icon?: never;
    });

const BUTTON_DIMENSIONS = {
  xs: { paddingVertical: 8, paddingHorizontal: 14 },
  sm: { paddingVertical: 12, paddingHorizontal: 18 },
  md: { paddingVertical: 14, paddingHorizontal: 24 },
} as const;

export const ICON_SIZES = {
  xs: 16,
  sm: 16,
  md: 18,
  lg: 20,
} as const;

const StyledButton = styled(TButton, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 0,
  cursor: 'pointer',
  fontFamily: '$title',
  fontWeight: '$semibold',
  borderWidth: 1,
  flexShrink: 1,
  alignSelf: 'center',
  height: 'auto',
  transition: 'all 0.2s ease',
  hoverStyle: {
    opacity: 0.8,
    borderColor: 'transparent',
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
      },
    },
    fullWidth: {
      true: { width: '100%' },
      false: { width: 'auto' },
    },
    size: {},
    fill: {
      true: {
        borderRadius: 0,
      },
      false: {
        borderRadius: 100,
      },
    },
    grow: {
      true: {
        flexGrow: 1,
      },
      false: {
        flexGrow: 0,
      },
    },
    shrink: {
      true: {
        flexShrink: 1,
      },
      false: {
        flexShrink: 0,
      },
    },
  } as const,
});

const Spinner = styled(TSpinner, {
  variants: {
    variant: {
      primary: { color: '$base_accent_text' },
      secondary: { color: '$base_text' },
      outline: { color: '$base_text' },
      ghost: { color: '$base_accent' },
      'ghost-accent': { color: '$base_accent' },
      destructive: { color: '$base_opposite_text' },
    },
  },
});

const StyledText = styled(Text, {
  color: 'inherit',
  fontFamily: '$title',
  fontWeight: '500',
  letterSpacing: 0.5,

  variants: {
    textSize: {
      xs: {
        fontSize: 12,
        lineHeight: 16,
      },
      sm: {
        fontSize: 14,
        lineHeight: 16,
      },
      md: {
        fontSize: 14,
        lineHeight: 18,
      },
    },
    hideLabel: {
      true: {
        color: 'transparent',
      },
    },
  } as const,
});

export const Button = <Event extends EventType>({
  label,
  variant = 'primary',
  size = 'md',
  icon,
  loading,
  disabled,
  fullWidth = false,
  emojiIcon,
  onPress,
  event,
  fill = false,
  fitContent = false,
  useVaultTheme,
  color,
  ...props
}: ButtonProps<Event>) => {
  const effectiveVaultTheme = useVaultTheme ? useSnapshot(VaultThemeStore) : null;

  const hasIcon = Boolean(icon || emojiIcon);

  const styles = useMemo(() => {
    switch (variant) {
      case 'primary':
        const accentColor = effectiveVaultTheme?.accentColor || COLOR.base_accent;
        return {
          backgroundColor: accentColor,
          borderColor: accentColor,
          color: effectiveVaultTheme?.accentTextColor || COLOR.base_accent_text,
        };
      case 'secondary': {
        const textColor = effectiveVaultTheme?.textColor || COLOR.base_text;
        return {
          backgroundColor: getColorWithOpacity(textColor, 0.1),
          borderColor: 'transparent',
          color: textColor,
        };
      }
      case 'outline':
        return {
          backgroundColor: 'transparent',
          borderColor: effectiveVaultTheme?.textColor || COLOR.base_text,
          color: effectiveVaultTheme?.textColor || COLOR.base_text,
        };
      case 'ghost':
        return {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: effectiveVaultTheme?.textColor || COLOR.base_text,
        };
      case 'ghost-accent':
        return {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: effectiveVaultTheme?.accentColor || COLOR.base_accent,
        };
      case 'destructive':
        return {
          backgroundColor: COLOR.destructive300,
          borderColor: COLOR.destructive300,
          color: COLOR.white,
        };
      default:
        return {
          backgroundColor: COLOR.base_accent,
          borderColor: COLOR.base_accent,
          color: COLOR.base_accent_text,
        };
    }
  }, [variant, effectiveVaultTheme]);
  const textColor = color || styles.color;

  const renderIcon = () => {
    if (loading) {
      return <Spinner size="small" variant={variant} color={textColor} />;
    }

    if (icon) {
      return <FontAwesomeIcon icon={icon} fontSize={ICON_SIZES[size]} color={textColor} />;
    }

    if (emojiIcon) {
      return <View>{emojiIcon}</View>;
    }
    return null;
  };

  const handlePress = useStableCallback(e => {
    if (!onPress) return;
    if (!event) return onPress(e);
    trackEvent(event);
    onPress(e);
  });

  return (
    <StyledButton
      disabled={disabled || loading}
      fullWidth={fullWidth}
      onPress={handlePress}
      fill={fill}
      grow={fullWidth || fill}
      shrink={fullWidth || fill}
      paddingHorizontal={fitContent ? 0 : BUTTON_DIMENSIONS[size].paddingHorizontal}
      paddingVertical={fitContent ? 0 : BUTTON_DIMENSIONS[size].paddingVertical}
      style={styles}
      pressStyle={{ opacity: 0.9 }}
      {...props}
    >
      {hasIcon && (
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: ICON_SIZES[size],
            height: ICON_SIZES[size],
          }}
        >
          {renderIcon()}
        </View>
      )}
      <StyledText textSize={size} hideLabel={loading && !hasIcon}>
        {label}
      </StyledText>
      {loading && !hasIcon && (
        <Spinner
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          size="small"
          variant={variant}
        />
      )}
    </StyledButton>
  );
};
