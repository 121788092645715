import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faCalendarPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useArtistEvents } from '../../hooks/membership/useArtistEvents';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { CreateButton } from '../vault/CreateButton';
import { EventItem, EventItemSkeleton } from './EventItem';
import { EmptyEvents, Item } from './shared';

export const EventsSection = ({ artistHandle }: { artistHandle: string }) => {
  const {
    artistEvents: events = [],
    isInitialLoading,
    hasNextPage,
  } = useArtistEvents({
    artistHandle,
    limit: 3,
    source: 'membership_page',
  });

  const hasEvents = events.length > 0;
  const hasMoreEvents = hasNextPage;

  const headerText = (
    <Text className="text-nowrap font-title text-[24px]/[24px] font-medium text-vault_text lg:text-[24px]/[26px]">
      Upcoming drops
    </Text>
  );

  const ownedArtist = useOwnedArtist({
    artistHandle,
  });
  const hasMoreEventsHref = hasMoreEvents
    ? artistNavigationPath(artistHandle, '/drops/events')
    : null;

  const headerLink = (
    <View className="relative flex w-full flex-row items-center justify-between">
      {hasMoreEventsHref ? (
        <Link
          to={hasMoreEventsHref}
          className="text-base text-base-l font-black text-vault_text no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      ) : (
        <View />
      )}

      {ownedArtist && (
        <CreateButton
          artistHandle={artistHandle}
          icon={faCalendarPlus}
          className="top-[-75%] w-[120px] !gap-2 md:!gap-1"
        />
      )}
    </View>
  );

  if (isInitialLoading) {
    return (
      <Item header={headerText} className={twMerge('mb-[-1px] gap-3')}>
        <View className="h-[1px]" />
        {Array.from({ length: 3 }).map((_, index) => (
          <EventItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  if (!hasEvents) {
    return (
      <Item header={headerText} headerLink={headerLink} className="gap-2">
        <View className="h-[1px]" />
        <EmptyEvents artistHandle={artistHandle} />
      </Item>
    );
  }

  return (
    <Item
      header={
        hasMoreEventsHref ? (
          <Link to={hasMoreEventsHref} className="no-underline">
            {headerText}
          </Link>
        ) : (
          headerText
        )
      }
      headerLink={headerLink}
      className="gap-6"
    >
      {events.map(event => (
        <EventItem key={event.id} eventData={event} />
      ))}
    </Item>
  );
};
