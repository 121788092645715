import { captureMessage } from '@sentry/react';
import type Hls from 'hls.js';
import { noop } from 'lodash-es';
import { proxy, ref, useSnapshot } from 'valtio';
import * as z from 'zod';

import { typedEntries } from '@soundxyz/utils';
import {
  AUDIO_ERROR_ACTIONS,
  AUDIO_INFO_ACTIONS,
  ERROR_TYPE,
  type ErrorAction,
  MISCELLANEOUS_ERROR_ACTIONS,
  PILLARS,
} from '@soundxyz/vault-utils/constants';
import { baseHeaders, HAS_CLOUDFLARE_ACCESS } from '../constants/env';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { logErrorNoDefaultToast } from '../hooks/logger/useLogError';
import { logInfo } from '../hooks/logger/useLogInfo';
import { trackEvent } from '../utils/analyticsUtils';
import { logger } from '../utils/logger';
import { store } from './Audio';
import { AudioMeta } from './AudioMeta';
import { isHlsSupported } from './hls';

const pillar = PILLARS.AUDIO;
const TIME_REMAINING_THRESHOLD = 0.2;
const FADE_VOLUME_PRECISION = 0.01;

// Error thrown on mobile when play is called before data is loaded
// When not checking for this error, it will play audio without updating the UI to playing state
const PLAY_ERROR_NAME = 'AbortError';

// Error thrown when autoplay doesn't work because of browser policy
const AUTOPLAY_ERROR_NAME = 'NotAllowedError';

// Unsupported Media Format errors:
// Not all browsers support the same audio formats, however, more often than not these errors
// are thrown when audio files have invalid or incorrect MIME types in the metadata.
const LOAD_MEDIA_ERROR_CODES = [2, 9];

//const pillar = PILLARS.AUDIO;
//const isArtistOnlyPage = false;

export const AudioEngineHTML5Schema = z.object({
  instance: z.nullable(typeof Audio === 'undefined' ? z.never() : z.instanceof(Audio)),
  loading: z.boolean(),
  ready: z.boolean(),
  playing: z.boolean(),
  muted: z.boolean(),
  volume: z.number(),
  duration: z.number(),
});

type AudioEngineHTML5Type = (typeof AudioEngineHTML5Schema)['_output'];

const initialAudioEngineHTML5: AudioEngineHTML5Type = {
  instance: typeof Audio === 'undefined' ? null : ref(new Audio()),
  loading: false,
  ready: false,
  playing: false,
  muted: false,
  volume: 1.0,
  duration: 0,
};

let hls: Hls | null = null;

export const AudioEngineHTML5 = proxy(initialAudioEngineHTML5);

// Special case. This should be manually requested as needed to avoid rerenders
export const audioEngineInstance = {
  get position() {
    if (!AudioEngineHTML5.instance) return 0;
    return AudioEngineHTML5.instance.currentTime;
  },
};

export const LoadListenerArguments: {
  duration: number;
  src: string;
  trackId: string | null;
  artistId: string | null;
  vaultId: string | null;
  title: string | null;
} = {
  duration: -1,
  src: '',
  trackId: null,
  artistId: null,
  vaultId: null,
  title: null,
};

function nanDefaultValue({ value, defaultValue }: { value: number; defaultValue: number }) {
  return Number.isNaN(value) ? defaultValue : value;
}

const isHlsLibrarySupported = isHlsSupported();

const loadListener = () => {
  AudioEngineHTML5.ready = true;

  const instanceDuration = nanDefaultValue({
    value: AudioEngineHTML5.instance?.duration ?? 0,
    defaultValue: 0,
  });

  trackEvent({
    type: 'Loaded Track',
    properties: {
      ...LoadListenerArguments,
      isHlsLibrarySupported,
    },
  });

  AudioEngineHTML5.duration = instanceDuration;
  AudioEngineHTML5.loading = false;
};

const envHlsBypassHlsProtection: string | false =
  import.meta.env.VITE_BYPASS_HLS_PROTECTION || false;

const hlsBypassHlsProtection: false | string = (() => {
  if (envHlsBypassHlsProtection) {
    if (IS_FINAL_DEPLOYMENT) {
      return false;
    }

    return envHlsBypassHlsProtection;
  }

  return false;
})();

const hlsBypassHeaderName = 'x-bypass-hls-protection';

const HlsPromise = import('hls.js').then(v => v.default);

export const IGNORED_EXPECTED_HLS_ERRORS = [
  /**
   * Network issues that are self-recovered when reconnecting
   */
  'Playback stalling at',
  /**
   * Normal error that is self-recovered
   */
  'Found no media in fragment',
  /**
   * Normal error that is self-recovered
   */
  "Failed to execute 'appendBuffer' on 'SourceBuffer'",
] as const;

export const load = async ({ src, autoplay }: { src: string; autoplay: boolean }) => {
  if (!AudioEngineHTML5.instance) return;

  // Show spinner
  AudioEngineHTML5.loading = true;

  LoadListenerArguments.src = src;

  // remove previous listeners
  AudioEngineHTML5.instance.removeEventListener('canplay', loadListener);
  AudioEngineHTML5.instance.addEventListener('canplay', loadListener, {
    once: true,
  });

  // Add to fetched audio files
  if (!store.audioFiles.has(src)) {
    store.audioFiles.add(src);
  }

  AudioEngineHTML5.instance.autoplay = autoplay;
  AudioEngineHTML5.instance.src = src;

  if (src.endsWith('.m3u8') && isHlsLibrarySupported) {
    const Hls = await HlsPromise;
    hls = new Hls({
      fetchSetup(context) {
        return new Request(context.url, {
          headers: {
            ...baseHeaders,
            ...context.headers,
            ...(hlsBypassHlsProtection
              ? {
                  [hlsBypassHeaderName]: hlsBypassHlsProtection,
                }
              : {}),
          },
        });
      },
      xhrSetup(xhr) {
        if (hlsBypassHlsProtection)
          xhr.setRequestHeader(hlsBypassHeaderName, hlsBypassHlsProtection);

        if (HAS_CLOUDFLARE_ACCESS) {
          for (const [key, value] of typedEntries(baseHeaders)) {
            if (!value) continue;

            xhr.setRequestHeader(key, value);
          }
        }
      },
    });
    hls.loadSource(src);
    hls.attachMedia(AudioEngineHTML5.instance);

    hls.on(Hls.Events.ERROR, (_event, errorData) => {
      switch (errorData.details) {
        case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL: {
          // This error is expected and is self-recovered
          // Do nothing
          return;
        }
      }

      for (const ignoredError of IGNORED_EXPECTED_HLS_ERRORS) {
        if (errorData.error.message.startsWith(ignoredError)) return;
      }

      captureMessage(`HLS error ${errorData.details}`, {
        extra: {
          ...errorData,
        },
        level: 'warning',
        tags: {
          type: errorData.type,
          fatal: errorData.fatal,
          details: errorData.details,
        },
      });
    });
  }

  if (autoplay) AudioEngineHTML5.playing = true;

  // Avoid loading track when no autoplay and already playing
  // Else play requests will be blocked
  if (!autoplay && !AudioEngineHTML5.playing) {
    AudioEngineHTML5.instance.load();
  }
  if (!autoplay || AudioEngineHTML5.playing) {
    // If autoplay is set to false or we are already playing when we get to this point,
    // the loading flag won't be properly reset since that is handled in the play callback
    // so do so here
    AudioEngineHTML5.loading = false;
  }
};

export const unload = () => {
  if (!AudioEngineHTML5.instance) return;

  // Note settin src to '' might cause errors. The empty string is equal to an empty relative
  // URL, which resolves to the page itself (the same way <a href="">this page</a> does).
  // Removing attribute does not pause the audio, loading audio without an src does and is safe
  AudioEngineHTML5.instance.pause();
  AudioEngineHTML5.instance.removeAttribute('src');
  AudioEngineHTML5.instance.load();

  if (hls) {
    hls.stopLoad();
  }

  AudioEngineHTML5.loading = false;
  AudioEngineHTML5.ready = false;
  AudioEngineHTML5.playing = false;
  AudioEngineHTML5.duration = 0;
};

export const audioEngineSeek = (position: number) => {
  if (!AudioEngineHTML5.instance) return;
  try {
    AudioEngineHTML5.instance.currentTime = position;
  } catch (error) {
    logger.debug(
      {
        error,
        position,
      },
      'Error using `seek` in AudioEngineHTML5',
    );
    AudioEngineHTML5.instance.currentTime = 0;
  }
};

export const audioEngineSeekBy = (offset: number) => {
  if (!AudioEngineHTML5.instance) return;
  try {
    AudioEngineHTML5.instance.currentTime += offset;
  } catch (error) {
    logger.debug(
      {
        error,
        offset,
      },
      'Error using `seekBy` in AudioEngineHTML5',
    );
  }
};

export const play = ({
  forcePlay,
}: {
  forcePlay?: boolean;
} = {}) => {
  if (!AudioEngineHTML5.instance || (AudioEngineHTML5.playing && !forcePlay)) return;

  logInfo({
    action: AUDIO_INFO_ACTIONS.AUDIO_ENGINE_PLAY,
    message: 'Play from audio engine',
    pillar,
    data: {
      src: AudioEngineHTML5.instance.src,
    },
  });

  AudioEngineHTML5.instance
    .play()
    .then(() => {
      AudioEngineHTML5.playing = true;
    })
    .catch(async errPromise => {
      let action: ErrorAction = MISCELLANEOUS_ERROR_ACTIONS.MISCELLANEOUS_ERROR;
      let level: 'error' | 'warning' = 'error';
      let message = '';
      let errorType = ERROR_TYPE.UNKNOWN;
      const err =
        errPromise instanceof Error
          ? errPromise
          : Error(typeof errPromise === 'string' ? errPromise : 'Non-error thrown', {
              cause: errPromise,
            });

      if (err instanceof Error) {
        if (err.name === PLAY_ERROR_NAME) {
          // This happens if we start playing before data is loaded but audio is actually playing so set state accordingly
          // This error is expected in this case but so just return early and avoid additional error handling
          AudioEngineHTML5.playing = true;
          return;
        }

        if (err.name === AUTOPLAY_ERROR_NAME) {
          // This will happen when browser policies don't allow autoplay
          // Nothing we can do here, should be warning
          action = AUDIO_ERROR_ACTIONS.AUDIO_PLAYBACK_ERROR;
          level = 'warning';
          message = 'Autoplay not allowed due to browser policy';
          errorType = ERROR_TYPE.UNKNOWN;
        }
      }

      const errCode: unknown = Reflect.get(err, 'code');

      if (
        // If there's an decoding issue caused by invalid metadata, fetching blob and setting as src often fixes it
        // This can happen when eg user plays back a file that was just uploaded, and we're playing the uploaded file
        // without transcoding it first
        typeof errCode === 'number' &&
        LOAD_MEDIA_ERROR_CODES.includes(errCode) &&
        !AudioEngineHTML5.instance?.src.includes('blob:') &&
        AudioEngineHTML5.instance
      ) {
        logInfo({
          action: AUDIO_INFO_ACTIONS.AUDIO_ENGINE_RETRY,
          message: 'Retrying audio engine play with blob',
          pillar,
          data: {
            src: AudioEngineHTML5.instance.src,
          },
        });

        AudioEngineHTML5.loading = true;
        try {
          const response = await fetch(
            AudioEngineHTML5.instance.src,
            hlsBypassHlsProtection
              ? {
                  headers: {
                    [hlsBypassHeaderName]: hlsBypassHlsProtection,
                  },
                }
              : {},
          );
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          AudioEngineHTML5.instance.src = objectUrl;
        } catch (err) {
          logErrorNoDefaultToast({
            action: AUDIO_ERROR_ACTIONS.AUDIO_PLAYBACK_ERROR,
            error: err,
            level: 'error',
            message: 'Error when trying to fetch audio to pass blob as objecturl after play failed',
            errorType: ERROR_TYPE.AUDIO_BLOB_ERROR,
            pillar,
            indexedTags: {
              src: AudioEngineHTML5.instance?.src,
              trackId: AudioMeta.activeTrackId,
              errorCode: AudioEngineHTML5.instance?.error?.code,
            },
            unindexedExtra: {
              elementError: AudioEngineHTML5.instance?.error,
            },
          });
        }
      } else {
        logErrorNoDefaultToast({
          action,
          error: err,
          level,
          message,
          errorType,
          pillar,
          indexedTags: {
            src: AudioEngineHTML5.instance?.src,
            trackId: AudioMeta.activeTrackId,
            errorCode: AudioEngineHTML5.instance?.error?.code,
          },
          unindexedExtra: {
            elementError: AudioEngineHTML5.instance?.error,
          },
        });

        // Reset state
        AudioEngineHTML5.loading = false;
        AudioEngineHTML5.ready = true;
        pause();
      }

      for (const onError of onErrorListeners) {
        onError(err);
      }
    });
};

export const pause = () => {
  if (!AudioEngineHTML5.instance || !AudioEngineHTML5.playing) return;
  AudioEngineHTML5.instance.pause();
  AudioEngineHTML5.playing = false;
};

export const togglePlayPause = () => {
  if (!AudioEngineHTML5.instance) return;
  if (AudioEngineHTML5.playing) {
    pause();
  } else {
    play();
  }
};

export const toggleMute = () => {
  if (!AudioEngineHTML5.instance) return;
  const newMute = !AudioEngineHTML5.muted;
  AudioEngineHTML5.instance.muted = newMute;
  AudioEngineHTML5.muted = newMute;
};

export const setVolume = (value: number) => {
  if (!AudioEngineHTML5.instance) return;
  AudioEngineHTML5.instance.volume = value;
  AudioEngineHTML5.volume = value;
};

export const setVolumeBy = (value: number) => {
  if (!AudioEngineHTML5.instance) return;

  let newVolume = AudioEngineHTML5.volume + value;
  if (newVolume < 0.0) newVolume = 0.0;
  if (newVolume > 1.0) newVolume = 1.0;
  AudioEngineHTML5.instance.volume = newVolume;
  AudioEngineHTML5.volume = newVolume;
};

export const fadeOut = (secondsToFade: number) => {
  if (!AudioEngineHTML5.instance) return;
  const startingVolume = AudioEngineHTML5.volume;

  const timeRemaining =
    AudioEngineHTML5.duration - audioEngineInstance.position - TIME_REMAINING_THRESHOLD;
  if (timeRemaining < 0) pause();
  const fadeTime = Math.min(timeRemaining, secondsToFade) / 1000;

  // Loosely based on the fade implementation in Howler.js
  // https://github.com/goldfire/howler.js/blob/master/src/howler.core.js#L1356

  let vol = startingVolume;
  const steps = Math.abs(startingVolume / FADE_VOLUME_PRECISION);
  const stepLength = fadeTime / steps;
  let lastTick = Date.now();

  // Update the volume value on each interval tick.
  const interval = setInterval(function () {
    // Update the volume based on the time since the last tick.
    const tick = (Date.now() - lastTick) / fadeTime;
    lastTick = Date.now();
    vol -= startingVolume * tick;

    // Round to within 2 decimal points.
    vol = Math.round(vol * 100) / 100;

    // Make sure the volume is in the right bounds.
    vol = Math.max(0, vol);

    setVolume(vol);

    // When the fade is complete, stop it then pause and reset volume.
    if (vol <= 0) {
      clearInterval(interval);
      setVolume(0);

      pause();
      setVolume(startingVolume);
    }
  }, stepLength);
};

const onEvent = (
  event: keyof HTMLMediaElementEventMap,
  callback: () => void,
  { once }: { once: boolean },
) => {
  if (!AudioEngineHTML5.instance) return noop;
  AudioEngineHTML5.instance.addEventListener(event, callback, { once });
  return () => {
    AudioEngineHTML5.instance?.removeEventListener?.(event, callback);
  };
};

export const onPlay = (callback: () => void, { once }: { once: boolean }) => {
  return onEvent('play', callback, { once });
};

export const onPause = (callback: () => void, { once }: { once: boolean }) => {
  return onEvent('pause', callback, { once });
};

export const onEnd = (callback: () => void, { once }: { once: boolean }) => {
  return onEvent('ended', callback, { once });
};

export const onTimeUpdate = (callback: () => void, { once }: { once: boolean }) => {
  return onEvent('timeupdate', callback, { once });
};

const onErrorListeners = new Set<(err: unknown) => void>();

export const onError = (callback: (err?: unknown) => void, { once }: { once: boolean }) => {
  const cleanup = () => {
    AudioEngineHTML5.instance?.removeEventListener?.('error', onErrorCallback);
    onErrorListeners.delete(onErrorCallback);
  };

  const onErrorCallback = (err?: unknown) => {
    callback(err);
    if (once) cleanup();
  };

  AudioEngineHTML5.instance?.addEventListener('error', onErrorCallback, { once });

  onErrorListeners.add(onErrorCallback);

  return cleanup;
};

export const useAudioEngineHTML5 = () => useSnapshot(AudioEngineHTML5);
