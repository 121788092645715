import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import { VAULT_APP_QR_CODE } from '../../constants/imageConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { LoginStatus } from '../../types/authTypes';
import { PersistenceStorage } from '../../utils/storeUtils';
import { Button } from '../buttons/Button';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { Image } from './Image';
import { Text } from './Text';
import { View } from './View';

const STORAGE_KEY = `app-qr-code-hide${
  ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT
}` as const;

const HideCodeStore = PersistenceStorage({
  schema: z.object({
    hideCode: z.boolean().default(false),
  }),
  key: STORAGE_KEY,
  eager: true,
});

export function AppQRCodeDisplay({ className }: { className?: string }) {
  const { value } = HideCodeStore.useStore();
  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();
  const { loginStatus } = useAuthContext();

  const isDevPrev =
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'preview';

  if (value?.hideCode === true) return null;

  if (loginStatus !== LoginStatus.LOGGED_IN) return null;

  return (
    <View
      className={twMerge(
        'fixed bottom-4 right-4 z-stickyFooter box-border hidden w-[240px] cursor-pointer select-none flex-row items-center justify-start gap-[6px] rounded-md border border-solid border-vault_text bg-white p-[2px] md:flex',
        isDevPrev && 'bottom-10',
        isBottomAudioPlayerOpen && 'bottom-[100px]',
        className,
      )}
      onClick={() => {
        window.open('https://vault.fm/download');
      }}
    >
      <Image src={VAULT_APP_QR_CODE} alt="Vault App QR Code" className="h-12 w-12 " />
      <View className="flex flex-1 flex-col items-start justify-start">
        <View className="flex w-full flex-row items-center justify-between gap-3">
          <Text className="text-[16px]/[20px] font-bold text-black">Get the iOS app</Text>
          <Button
            label=""
            iconOnly
            leadingIcon={faClose}
            className="h-6 w-6 text-[13px] text-black"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              HideCodeStore.produceExistingState(
                draft => {
                  draft.hideCode = true;
                },
                { hideCode: true },
              );
            }}
          />
        </View>
        <Text className="text-[14px]/[18px] font-medium text-black/60">Never miss a message</Text>
      </View>
    </View>
  );
}
