import { captureException } from '@sentry/react';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import { SignedVaultDownloadUrlByVaultIdDocument } from '../../../graphql/generated';
import { logErrorNoDefaultToast } from '../../../hooks/logger/useLogError';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useJoinToGetStartedUpsell } from './useJoinToGetStartedUpsell';

gql(/* GraphQL */ `
  query SignedVaultDownloadUrlByVaultId($vaultContentId: UUID!) {
    getSignedVaultContentDownload(vaultContentId: $vaultContentId) {
      __typename
      ... on Error {
        message
      }
      ... on QueryGetSignedVaultContentDownloadSuccess {
        data
      }
    }
  }
`);

export const useVaultDownloadPress = ({
  vaultContentId,
  isLocked,
  vaultId,
  artistHandle,
  artistName,
  vaultArtistProfileImage,
  vaultArtistMembershipCardImage,
  hasActiveSubscription,
  fileName = null,
  mimeType = null,
}: {
  vaultContentId: string;
  isLocked: boolean;
  vaultId: string;
  artistHandle: string;
  artistName: string | null;
  vaultArtistProfileImage: string | null;
  vaultArtistMembershipCardImage: string | null;
  hasActiveSubscription: boolean;
  fileName?: string | null;
  mimeType?: string | null;
}) => {
  const { mutateAsync: fetchDownloadUrl } = useMutation(SignedVaultDownloadUrlByVaultIdDocument, {
    retry: 3,
  });

  const { openToast } = useToast();
  const { onIsLocked } = useJoinToGetStartedUpsell({
    vaultId,
    artistHandle,
    artistName,
    vaultArtistProfileImage,
    vaultArtistMembershipCardImage,
    hasActiveSubscription,
  });

  /**
   * Force download by fetching the file and creating a blob URL
   */
  const forceDownload = useStableCallback(async (url: string, suggestedFileName: string) => {
    try {
      openToast({
        text: 'Preparing download...',
        variant: 'primary',
      });

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download: ${response.statusText}`);
      }

      const blob = await response.blob();

      const contentType =
        mimeType || response.headers.get('content-type') || 'application/octet-stream';

      const fileBlob = new Blob([blob], { type: contentType });

      const blobUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', suggestedFileName);
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 100);

      openToast({
        text: 'File downloaded successfully!',
        variant: 'success',
      });
    } catch (error) {
      captureException(error, {
        extra: {
          vaultContentId,
          downloadUrl: url,
        },
      });

      openToast({
        text: 'Download failed. Please try again later.',
        variant: 'error',
      });
    }
  });

  const downloadContent = useStableCallback(async () => {
    try {
      const { data } = await fetchDownloadUrl({
        vaultContentId,
      });

      if (
        data.getSignedVaultContentDownload.__typename ===
        'QueryGetSignedVaultContentDownloadSuccess'
      ) {
        const downloadUrl = data.getSignedVaultContentDownload.data;

        const fileExt = guessFileExtension(downloadUrl, mimeType);

        const suggestedFileName = extractFilename({
          url: downloadUrl,
          fallBackFilename:
            `${fileName?.includes(fileExt) ? fileName : fileName + fileExt}` ||
            `${artistHandle}-content-${vaultContentId.slice(0, 8)}` + fileExt,
        });

        await forceDownload(downloadUrl, suggestedFileName);
      } else {
        openToast({
          text: data.getSignedVaultContentDownload.message,
          variant: 'error',
        });
      }
    } catch (e) {
      captureException(e, {
        extra: {
          vaultContentId,
        },
      });
      openToast({
        text: 'Error fetching download url. Please try again later.',
        variant: 'error',
      });
    }
  });

  const onDownloadClick = async () => {
    if (isLocked) {
      onIsLocked();
      return;
    }

    downloadContent();
  };

  return { onDownloadClick };
};

export const guessFileExtension = (url: string, contentType: string | null): string => {
  // Try to extract extension from URL
  const urlMatch = url.match(/\.([a-zA-Z0-9]+)(?:\?|$)/);
  if (urlMatch && urlMatch[1]) {
    return `.${urlMatch[1].toLowerCase()}`;
  }

  // If no extension in URL, try to guess from mime type
  if (contentType) {
    const mimeMap: Record<string, string> = {
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
      'image/webp': '.webp',
      'audio/mpeg': '.mp3',
      'audio/wav': '.wav',
      'audio/ogg': '.ogg',
      'video/mp4': '.mp4',
      'video/webm': '.webm',
      'application/pdf': '.pdf',
    };

    return mimeMap[contentType] || '';
  }

  return '';
};

export const extractFilename = ({
  url,
  fallBackFilename,
}: {
  url: string;
  fallBackFilename?: string;
}): string => {
  try {
    const urlObj = new URL(url);
    const disposition = urlObj.searchParams.get('response-content-disposition');
    if (disposition) {
      const filenameMatch = /filename=(["']?)([^"';\s]+)\1/.exec(disposition);
      if (filenameMatch && filenameMatch[2]) {
        try {
          return decodeURIComponent(decodeURIComponent(filenameMatch[2]));
        } catch (e) {
          return decodeURIComponent(filenameMatch[2]);
        }
      }
    }

    const pathSegments = urlObj.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    return fallBackFilename || lastSegment || 'download';
  } catch (error) {
    logErrorNoDefaultToast({
      pillar: 'MEDIA_FILE',
      error,
      errorType: 'UNKNOWN',
      message: 'Failed to extract filename from URL',
      indexedTags: {
        url,
      },
      level: 'warning',
      toast: 'Failed to extract filename from URL',
      action: 'MEDIA_FILE_DOWNLOAD_ERROR',
    });
    return 'download';
  }
};
