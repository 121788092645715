import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import type { ConnectStreamingPlatformBottomSheetProps } from '../../types/bottomsheetTypes';
import type { EventType } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button, type ButtonProps } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function ConnectStreamingPlatformBottomSheet({
  artistHandle,
  campaignLinkValue,
  hasSpotifyLink,
  hasAppleMusicLink,
  showConnectionModal,
  onServiceError,
}: ConnectStreamingPlatformBottomSheetProps) {
  const navigate = useNavigate();

  const { loginStatus } = useAuthContext();
  const { closeBottomsheet } = useBottomsheetContainer();

  const spotifyAuth = useSpotifyAuth();

  const onSpotifyClick = useStableCallback(() => {
    if (spotifyAuth.type !== 'already-connected') return; // Do nothing, let the href prop do the work

    closeBottomsheet();

    navigate(artistNavigationPath(artistHandle, `/s/${campaignLinkValue}/claim`, 'step=view'));
  });

  const appleMusicAuth = useAppleMusicAuth();

  const onAppleMusicClick = useStableCallback(async () => {
    try {
      if (appleMusicAuth.type === 'connect') {
        await appleMusicAuth.connect();
      } else if (appleMusicAuth.type === 'connected-without-api-confirmation') {
        await appleMusicAuth.connectWithApi();
      } else if (appleMusicAuth.type === 'link-apple-music') {
        await appleMusicAuth.link();
      }
      closeBottomsheet();
      navigate(artistNavigationPath(artistHandle, `/s/${campaignLinkValue}/claim`, 'step=view'));
    } catch (error) {
      onServiceError('apple');
    }
  });

  useEffect(() => {
    if (showConnectionModal) return;
    if (spotifyAuth.type === 'already-connected' && hasSpotifyLink) {
      onSpotifyClick();
    } else if (
      (appleMusicAuth.type === 'apple-music-connected-without-user' ||
        appleMusicAuth.type === 'apple-music-already-linked') &&
      hasAppleMusicLink
    ) {
      onAppleMusicClick();
    }
  }, [
    onSpotifyClick,
    spotifyAuth.type,
    onAppleMusicClick,
    appleMusicAuth.type,
    hasSpotifyLink,
    hasAppleMusicLink,
    showConnectionModal,
  ]);

  return (
    <View className="w-full md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-col items-center justify-around">
        <Text className="text-center font-title text-[22px]/[26px] text-white">
          Choose your
          <br />
          streaming platform
        </Text>

        <View className="mt-6 flex w-full flex-col items-center justify-center gap-2">
          {hasSpotifyLink && (
            <PlatformButton
              label="Spotify"
              leadingIcon={faSpotify}
              loading={spotifyAuth.type === 'loading'}
              disabled={spotifyAuth.type === 'loading'}
              href={spotifyAuth.link}
              isExternal={!!spotifyAuth.link}
              onClick={onSpotifyClick}
            />
          )}

          {hasAppleMusicLink && (
            <PlatformButton
              label="Apple Music"
              leadingIcon={faApple}
              loading={appleMusicAuth.type === 'loading'}
              disabled={appleMusicAuth.type === 'loading'}
              onClick={onAppleMusicClick}
            />
          )}
        </View>

        {loginStatus === LoginStatus.LOGGED_OUT && (
          <View className="mt-6">
            <Text className="text-center font-base text-[16px]/[20px] text-white/50">
              Already connected?{' '}
              <Link
                className="text-white"
                to={`${ROUTES.SIGN_IN}?${constructQueryParams({ redirect: artistNavigationPath(artistHandle, `/s/${campaignLinkValue}`, 'openBottomSheet=connect') })}`}
                onClick={() => closeBottomsheet()}
              >
                Login to Vault
              </Link>
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

function PlatformButton<Event extends EventType>(props: ButtonProps<Event>) {
  return (
    <Button
      className="w-full rounded-full bg-white/10 px-7 py-4 font-title text-[16px]/[20px] text-white hover:bg-white/20 md2:max-w-[310px]"
      linkClassName="w-full flex items-center justify-center md2:max-w-[310px]"
      leadingIconClassName="text-[24px]"
      disabledClassName="opacity-70"
      {...props}
    />
  );
}
