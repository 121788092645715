import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { VaultType } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';

export const LockedContent = ({
  showJoinFree,
  vaultId,
  linkValue,
  artistName,
  memberCardImage,
  monthlySubPrice,
  artistImage,
  vaultType,
  chatAvailableForFreeUsers,
  containerClassName,
}: {
  showJoinFree: boolean;
  vaultId: string | undefined;
  linkValue: string | undefined;
  artistName: string | undefined;
  memberCardImage?: string | null;
  monthlySubPrice?: number;
  artistImage?: string | null;
  vaultType: VaultType | undefined;
  chatAvailableForFreeUsers: boolean;
  containerClassName?: string;
}) => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');

  const showJoinFreeButton = vaultType === VaultType.FreeOnly || chatAvailableForFreeUsers;

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: linkValue,
          artistName,
          imageUrl: memberCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    linkValue,
    loginStatus,
    openBottomsheet,
    artistName,
    memberCardImage,
    loggedInUser?.username,
    smsCampaignResponseShortcode,
    navigate,
  ]);

  if (showJoinFree) {
    return null;
  }
  return (
    <div
      className={twMerge(
        'absolute bottom-4 left-0 right-0 mx-auto flex max-w-[360px] flex-col gap-5 p-4 text-center text-vault_text',
        containerClassName,
      )}
    >
      <p className="text-title-m font-semibold">Unlock private chat</p>
      <p className="text-base-m">
        Get behind the scenes, hear directly from the artist about their music and process.
      </p>
      {linkValue &&
        vaultId &&
        (showJoinFreeButton ? (
          <Button
            label="Join for free"
            type="primary-themed"
            buttonType="submit"
            disabledClassName="opacity-30"
            className="w-full"
            onClick={onJoinFreeClick}
          />
        ) : (
          <SubscribeButton
            label={`Unlock for $${monthlySubPrice}/month`}
            className="w-full bg-vault_accent text-base-l text-vault_accent_text"
            linkValue={linkValue}
            artistAvatarUrl={artistImage}
            price={monthlySubPrice || DEFAULT_PRICE}
            vaultId={vaultId}
            showBottomSheet={false}
            smsCampaignResponseShortcode={smsCampaignResponseShortcode}
            component="locked_chat"
          />
        ))}
    </div>
  );
};
