import { useCallback } from 'react';
import { z } from 'zod';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistMessageChannelNotificationOverridesDocument,
  GetMessageChannelDocument,
  GetUserChannelsDocument,
  UpdateMessageChannelParticipantUserSettingsDocument,
} from '../graphql/generated';
import { useOwnedArtist } from './useOwnedArtist';
import { useWindow } from './useWindow';

gql(/* GraphQL */ `
  mutation UpdateMessageChannelParticipantUserSettings(
    $input: MutationUpdateMessageChannelParticipantUserSettingsInput!
  ) {
    updateMessageChannelParticipantUserSettings(input: $input) {
      __typename
      actor {
        id
        __typename
        ... on MessageActorUser {
          user {
            id
            displayName
            username
          }
        }
        ... on MessageActorArtist {
          artist {
            id
            name
            linkValue
          }
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: UpdateMessageChannelParticipantUserSettingsDocument,
  refetch: [
    GetUserChannelsDocument,
    GetMessageChannelDocument,
    ArtistMessageChannelNotificationOverridesDocument,
  ],
});

const validationSchema = z.object({
  isPushNotificationEnabled: z.boolean(),
  isSmsEnabled: z.boolean().nullable(),
  radioFields: z
    .array(
      z.object({
        enabled: z.boolean(),
        displayName: z.string(),
        key: z.string(),
      }),
    )
    .nullish(),
});

export function useToggleChannelParticipantSettings({
  artistHandle,
  messageChannelId,
  actorId,
  messageChannelTitle,
}: {
  artistHandle: string;
  messageChannelId: string | null | undefined;
  actorId: string | null | undefined;
  messageChannelTitle: string;
}) {
  const { isEmailVerified } = useAuthContext();
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const { mutateAsync: updateMessageChannelParticipantUserSettings, isLoading } = useMutation(
    UpdateMessageChannelParticipantUserSettingsDocument,
    {},
  );

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isArtist = !!ownedArtist;

  const { isDesktop } = useWindow();

  const onToggleNotificationsPress = useCallback(
    async ({
      isPushNotificationEnabled,
      isSmsEnabled,
      isPushNotificationEnabledForUserMessagesInGroupChat,
    }: {
      isPushNotificationEnabled: boolean;
      isSmsEnabled: boolean | null;
      isPushNotificationEnabledForUserMessagesInGroupChat: boolean | null;
    }) => {
      if (actorId == null || messageChannelId == null) return;

      if (isArtist) {
        await updateMessageChannelParticipantUserSettings({
          input: {
            actorId,
            isPushNotificationEnabled: !isPushNotificationEnabled,
            isSmsEnabled: !isSmsEnabled,
            channelId: messageChannelId,
          },
        });

        openToast({
          text: `Push notifications ${
            !isPushNotificationEnabled ? 'unmuted' : 'muted'
          } for ${messageChannelTitle}`,
          variant: 'success',
        });
        return;
      }

      openBottomsheet({
        type: BOTTOMSHEET_TYPES.TOGGLE_FORM,
        shared: {
          withVaultTheme: true,
          fitContent: true,
        },
        toggleFormBottomsheetProps: {
          type: BOTTOMSHEET_TYPES.TOGGLE_FORM,
          title:
            isPushNotificationEnabledForUserMessagesInGroupChat != null && !isDesktop
              ? null
              : 'Notifications',
          defaultValues: {
            isPushNotificationEnabled,
            isSmsEnabled,
            radioFields:
              isPushNotificationEnabledForUserMessagesInGroupChat != null
                ? [
                    {
                      enabled: !isPushNotificationEnabledForUserMessagesInGroupChat,
                      displayName: 'Only for the artist',
                      key: 'artistOnly',
                    },
                    {
                      enabled: isPushNotificationEnabledForUserMessagesInGroupChat,
                      displayName: 'Everyone',
                      key: 'userMessageInGroupChat',
                    },
                  ]
                : undefined,
          },
          parseToggleName: value => {
            if (value === 'isPushNotificationEnabled') return 'Push notifications';
            if (value === 'isSmsEnabled')
              return `${isEmailVerified ? 'Email' : 'SMS'} notifications`;
            return value;
          },
          onSubmit: async values => {
            const result = validationSchema.safeParse(values);
            if (!result.success) {
              return values;
            }

            await updateMessageChannelParticipantUserSettings({
              input: {
                actorId,
                isPushNotificationEnabled: result.data.isPushNotificationEnabled,
                isSmsEnabled: result.data.isSmsEnabled ?? false,
                isPushNotificationEnabledForUserMessagesInGroupChat:
                  result.data.radioFields != null
                    ? result.data.radioFields.some(
                        ({ key, enabled }) => key === 'userMessageInGroupChat' && enabled,
                      )
                    : undefined,
                channelId: messageChannelId,
              },
            });

            return values;
          },
          checkDisabledRadioFields: values => {
            return 'isPushNotificationEnabled' in values && !values.isPushNotificationEnabled;
          },
          onClose: ({ hasChanges, values }) => {
            if (!hasChanges) return;

            const result = validationSchema.safeParse(values);
            const content = (() => {
              if (!result.success) {
                return `Notification settings updated for ${messageChannelTitle}`;
              }
              const {
                isPushNotificationEnabled: resultIsPushNotificationEnabled,
                isSmsEnabled: resultIsSmsEnabled,
                radioFields: resultRadioFields,
              } = result.data;

              const activeRadioField = resultRadioFields?.find(({ enabled }) => enabled) ?? null;

              const pushChanged = resultIsPushNotificationEnabled !== isPushNotificationEnabled;
              const smsChanged = resultIsSmsEnabled !== isSmsEnabled;
              const userMessageInGroupChatChanged =
                activeRadioField != null &&
                isPushNotificationEnabledForUserMessagesInGroupChat != null &&
                ((activeRadioField.key === 'userMessageInGroupChat' &&
                  !isPushNotificationEnabledForUserMessagesInGroupChat) ||
                  (activeRadioField.key === 'artistOnly' &&
                    isPushNotificationEnabledForUserMessagesInGroupChat));

              if (pushChanged && smsChanged) {
                const actionText =
                  resultIsPushNotificationEnabled && resultIsSmsEnabled
                    ? 'unmuted'
                    : !resultIsPushNotificationEnabled && !resultIsSmsEnabled
                      ? 'muted'
                      : 'updated';
                return `All notifications ${actionText} for ${messageChannelTitle}`;
              }
              if (pushChanged || userMessageInGroupChatChanged) {
                const actionText = resultIsPushNotificationEnabled ? 'unmuted' : 'muted';
                return `Push notifications ${actionText} ${
                  resultIsPushNotificationEnabled && activeRadioField != null
                    ? `for messages ${activeRadioField.key === 'artistOnly' ? 'only from the artist in' : 'from everyone in'}`
                    : 'for'
                } ${messageChannelTitle}`;
              }
              if (smsChanged) {
                const actionText = resultIsSmsEnabled ? 'unmuted' : 'muted';
                return `${isEmailVerified ? 'Email' : 'SMS'} notifications ${actionText} for ${messageChannelTitle}`;
              }

              return `Notification settings updated for ${messageChannelTitle}`;
            })();

            openToast({
              text: content,
              variant: 'success',
            });
          },
        },
      });
    },
    [
      actorId,
      isArtist,
      isDesktop,
      isEmailVerified,
      messageChannelId,
      messageChannelTitle,
      openBottomsheet,
      openToast,
      updateMessageChannelParticipantUserSettings,
    ],
  );

  return {
    onToggleNotificationsPress,
    isLoading,
  };
}
