import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faRightToBracket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useAuthorizedUserProfiles } from '../../store/userProfile';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { maskPhoneNumber } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { UserRow } from '../structures/UserRow';
import { AccountSwitcherIcon } from './AccountSwitcherIcon';

export const MenuUser = ({
  selectedHandle,
  closeAll,
}: {
  selectedHandle?: string | null;
  closeAll: () => void;
}) => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { openBottomsheet } = useBottomsheetContainer();

  const withVaultTheme = !!selectedHandle;

  const { authorizedUserProfiles } = useAuthorizedUserProfiles();

  const userProfileImageUrl =
    loggedInUser?.avatar.userSmallProfileImageUrl || loggedInUser?.avatar.cdnUrl;
  const userSubText = !!loggedInUser?.username
    ? `@${loggedInUser?.username}`
    : maskPhoneNumber(loggedInUser?.phone);

  const showAccountSwitcherButton = !!authorizedUserProfiles && authorizedUserProfiles.length > 1;

  const accountSwitcherComponent = useMemo(() => {
    return showAccountSwitcherButton ? (
      <View className="flex flex-shrink-0 items-center justify-center">
        <button
          className="flex h-full flex-shrink-0 cursor-pointer appearance-none items-center justify-center border-none bg-transparent outline-none hover:opacity-80"
          onClick={e => {
            e.stopPropagation();

            closeAll();

            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACCOUNT_SWITCHER,
              accountSwitcherBottomsheetProps: {
                withVaultTheme,
              },
              shared: {
                withVaultTheme,
              },
            });
          }}
        >
          <AccountSwitcherIcon className={withVaultTheme ? 'fill-vault_text' : 'text-white'} />
        </button>
      </View>
    ) : null;
  }, [closeAll, openBottomsheet, showAccountSwitcherButton, withVaultTheme]);

  if (loginStatus === LoginStatus.LOADING) {
    return (
      <View className="box-border flex items-center justify-start gap-3 px-3 py-3 md2:pl-2">
        <UserRow
          managedVault={false}
          name=""
          profileImageUrl={userProfileImageUrl}
          verifiedBadge={false}
          useVaultTheme={withVaultTheme}
          subtitle={userSubText ?? undefined}
          loading
        />
      </View>
    );
  }

  if (loginStatus === LoginStatus.LOGGED_OUT || loggedInUser == null) {
    return (
      <View>
        <Button
          href={`${ROUTES.SIGN_IN}?${constructQueryParams({
            artistHandle: selectedHandle,
            invite,
            c: smsCampaignResponseShortcode,
            openBottomSheet: selectedHandle ? 'freeTierModal' : undefined,
          })}`}
          onClick={closeAll}
          className={twMerge(
            'mb-4 w-full justify-center rounded-full px-7 py-3 font-title !text-[16px]/[20px] font-medium md2:ml-2 md2:items-center md2:p-3',
            withVaultTheme ? 'bg-vault_text text-vault_text_opposite' : 'bg-white text-black',
          )}
          label="Log in"
          linkClassName="w-full"
          leadingIcon={faRightToBracket}
          labelClassName={twMerge('w-full overflow-hidden transition-all ease-in-out')}
        />
      </View>
    );
  }

  return (
    <View className="box-border flex items-center justify-start gap-3 px-3 py-3 md2:pl-2">
      <Link
        to={ROUTES.SETTINGS_PROFILE}
        className="flex min-w-0 flex-1 items-center bg-transparent text-[unset] no-underline hover:opacity-80"
        onClick={closeAll}
      >
        <UserRow
          managedVault={false}
          name={loggedInUser.computedDisplayName}
          profileImageUrl={userProfileImageUrl}
          verifiedBadge={!!loggedInUser.artist?.id}
          useVaultTheme={withVaultTheme}
          subtitle={userSubText ?? undefined}
        />
      </Link>
      {accountSwitcherComponent}
    </View>
  );
};
