import { type HTMLInputTypeAttribute, type ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureMessage } from '@sentry/react';
import clsx from 'clsx';
import type { SubmitHandler, UseFormRegisterReturn } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { Navigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import { faSearch } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faLandscape, faTrash } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCircleXmark } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { deepTrim } from '@soundxyz/utils/src/string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';

import { ErrorView } from '../../components/error/ErrorView';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { ProfileImage } from '../../components/user/ProfileImage';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ToastContext } from '../../contexts/ToastContext';
import { GQLReactQuery, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AuthUserDocument,
  type FragmentType,
  getFragment,
  MediaType,
  UpdateUserProfileDocument,
  UserProfileSettingsDocument,
  UserSettingsViewFragmentDoc,
} from '../../graphql/generated';
import { useEditProfile } from '../../hooks/useEditProfile';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useUploadAvatar } from '../../hooks/useUpdateAvatar';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { sleep } from '../../utils/timerUtils';
import { removeInvalidUsernameChars, usernameSchema } from '../../utils/username';

gql(/* GraphQL */ `
  fragment userSettingsView on PrivateUser {
    id
    username
    displayName
    stripeEmail
    authEmail
    zipCode
    avatar {
      id
      cdnUrl
      userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      mediaType
    }
    userProvidedGoogleLocation {
      googlePlaceId
      googlePlaceInputLabel
      city
      region
      regionShort
      country
      isoCountry
    }
  }

  query UserProfileSettings {
    currentUser {
      __typename
      ... on QueryCurrentUserSuccess {
        data {
          id
          ...userSettingsView
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserProfileDocument],
  refetch: [AuthUserDocument, UserProfileSettingsDocument],
});

function EditSubscriberProfilePage() {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <EditSubscriberProfileLoader />;
}

export { EditSubscriberProfilePage };

function EditSubscriberProfileLoader() {
  const { data, isError, isLoading, refetch } = useQuery(UserProfileSettingsDocument, {
    staleTime: 0,
  });

  const [submits, setSubmits] = useState(0);

  if (isError) {
    return (
      <SettingsLayout
        title="Edit profile"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="edit_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isLoading || data == null) {
    return <SettingsLayout title="Edit profile" nonScrollingChildren={<EditProfileSkeleton />} />;
  }

  if (data.data.currentUser.__typename !== 'QueryCurrentUserSuccess') {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <EditSubscriberProfileForm
      key={submits}
      data={data.data.currentUser.data}
      onSubmit={() => setSubmits(prev => prev + 1)}
    />
  );
}

const validationSchema = z.object({
  username: usernameSchema,
  displayName: z.string().max(50, { message: 'Max length 50 characters' }).nullish(),
  avatarImageMediaId: z.string().nullish(),
  usingDefaultAvatar: z.boolean(),
  email: z.string().email({ message: 'Invalid email address' }).nullish().or(z.literal('')),
  googlePlaceId: z.string().nullish(),
  googlePlaceInputLabel: z.string().nullish(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const mailToLink = 'mailto:help@vault.fm?subject=Change%20username';

function EditSubscriberProfileForm({
  data,
  onSubmit: onSubmitParent,
}: {
  data: FragmentType<UserSettingsViewFragmentDoc>;
  onSubmit: () => void;
}) {
  const { openToast } = ToastContext.useContainer();
  const userFragment = getFragment(UserSettingsViewFragmentDoc, data);

  const ownedArtist = useOwnedArtist({ userProfileId: userFragment.id });

  const disableUsernameEditing = !!ownedArtist;

  const [isRemovingProfilePicture, setIsRemovingProfilePicture] = useState<boolean>(false);
  const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    reset,
    setFocus,
    control,
  } = useForm<ValidationSchema>({
    defaultValues: {
      username: userFragment.username ?? '',
      displayName: userFragment.displayName ?? '',
      avatarImageMediaId: userFragment.avatar.id,
      usingDefaultAvatar: userFragment.avatar.mediaType === MediaType.DefaultAvatar,
      email: userFragment.authEmail ?? userFragment.stripeEmail ?? undefined,
      googlePlaceId: userFragment.userProvidedGoogleLocation?.googlePlaceId ?? null,
      googlePlaceInputLabel: userFragment.userProvidedGoogleLocation?.googlePlaceInputLabel ?? null,
    },
    resolver: zodResolver(validationSchema),
  });

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { updateSubscriberProfile } = useEditProfile();

  const onSubmit: SubmitHandler<ValidationSchema> = async props => {
    trackEvent({
      type: EVENTS.EDIT_PROFILE,
      properties: null,
    });

    const newEmail =
      userFragment.authEmail == null ? props.email?.trim().toLowerCase() || null : null;

    const newDisplayName = deepTrim(props.displayName || '') || null;

    const result = await updateSubscriberProfile({
      input: {
        username: props.username.toLowerCase().trim() || null,
        displayName: newDisplayName,
        avatarId:
          isRemovingProfilePicture || props.usingDefaultAvatar ? null : props.avatarImageMediaId,
        stripeEmail: newEmail,
        locationPlace: props.googlePlaceId
          ? {
              googlePlaceId: props.googlePlaceId,
              googlePlaceInputLabel: props.googlePlaceInputLabel ?? '',
            }
          : null,
        unset: {
          avatarId: isRemovingProfilePicture,
          displayName: true,
          locationPlace: true,
          stripeEmail: userFragment.authEmail == null,
        },
      },
    });

    if (result.data.updateUserProfile.__typename === 'MutationUpdateUserProfileSuccess') {
      // Update the cache
      GQLReactQuery.setQueryData(
        {
          query: UserProfileSettingsDocument,
        },
        {
          data: {
            currentUser: {
              __typename: 'QueryCurrentUserSuccess',
              data: result.data.updateUserProfile.data,
            },
          },
        },
      );

      // Update the form state
      const updatedFragment = getFragment(
        UserSettingsViewFragmentDoc,
        result.data.updateUserProfile.data,
      );
      reset({
        username: updatedFragment.username ?? '',
        displayName: updatedFragment.displayName ?? '',
        avatarImageMediaId: updatedFragment.avatar.id,
        usingDefaultAvatar: updatedFragment.avatar.mediaType === MediaType.DefaultAvatar,
        email: updatedFragment.authEmail ?? updatedFragment.stripeEmail ?? '',
        googlePlaceId: updatedFragment?.userProvidedGoogleLocation?.googlePlaceId ?? null,
        googlePlaceInputLabel:
          updatedFragment?.userProvidedGoogleLocation?.googlePlaceInputLabel ?? null,
      });
      setIsRemovingProfilePicture(false);
      setTemporaryProfileImageUrl(undefined);

      openToast({
        text: 'Your profile has been updated',
        variant: 'success',
      });

      onSubmitParent();
    } else if (result.data.updateUserProfile.__typename === 'UsernameUnavailableError') {
      captureMessage('UsernameUnavailableError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'info',
      });
      openToast({
        text: 'Username is not available, please try a different username',
        variant: 'error',
      });
    } else if (result.data.updateUserProfile.__typename === 'ValidationError') {
      captureMessage('ValidationError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'warning',
      });
      openToast({
        text: result.data.updateUserProfile.message,
        variant: 'error',
      });
    } else {
      captureMessage('NotFoundError in EditArtistProfileForm', {
        extra: {
          data: result.data,
          userFragment,
        },
        level: 'error',
      });
      openToast({
        text: 'Internal error, please try again later',
        variant: 'error',
      });
    }
  };

  const [updatePending, setUpdatePending] = useState<boolean>(false);
  const {
    getInputProps,
    getRootProps,
    inputRef,
    open: openAvatarUpload,
  } = useUploadAvatar({
    onConfirm: () => {
      setUpdatePending(true);
    },
    onDone: async () => {
      await sleep(400);
      setIsRemovingProfilePicture(false);
      setUpdatePending(false);
    },
    onSuccess({ mediaId, cdnUrl }) {
      setIsRemovingProfilePicture(false);
      setTemporaryProfileImageUrl(cdnUrl);
      setValue('avatarImageMediaId', mediaId, {
        shouldDirty: true,
      });
      setValue('usingDefaultAvatar', false);
    },
  });

  const imageUri = isRemovingProfilePicture
    ? undefined
    : temporaryProfileImageUrl ??
      userFragment.avatar.userSmallProfileImageUrl ??
      userFragment.avatar.cdnUrl;

  const googlePlaceId = useWatch({
    control,
    name: 'googlePlaceId',
  });

  const googlePlaceInputLabel = useWatch({
    control,
    name: 'googlePlaceInputLabel',
  });

  return (
    <SettingsLayout
      title="Edit my profile"
      right={
        <Button
          label="Save"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || !isDirty || updatePending}
          disabledClassName="opacity-50"
        />
      }
    >
      <div className="cursor-pointer" {...getRootProps()} ref={inputRef}>
        {updatePending ? (
          <SkeletonProfiePicture />
        ) : (
          <ProfileImage
            profileImageUrl={imageUri}
            className="mb-[12px] h-[100px] w-[100px]"
            onClick={undefined}
          />
        )}
        <input {...getInputProps()} />
      </div>
      <View className="flex flex-row">
        {!!(temporaryProfileImageUrl || isRemovingProfilePicture) && (
          <Button
            label="Reset"
            className="mr-[16px] font-title text-[16px]/[20px] font-medium text-yellow100"
            onClick={() => {
              setTemporaryProfileImageUrl(undefined);
              setIsRemovingProfilePicture(false);
              setValue('avatarImageMediaId', userFragment.avatar.id, {
                shouldDirty: true,
              });
              setValue(
                'usingDefaultAvatar',
                userFragment.avatar.mediaType === MediaType.DefaultAvatar,
              );
            }}
            disabled={isSubmitting}
            disabledClassName="opacity-50 cursor-default"
          />
        )}
        <Button
          label="Edit"
          className="font-title text-[16px]/[20px] font-medium text-yellow100"
          onClick={
            !updatePending
              ? () => {
                  if (imageUri != null) {
                    openBottomsheet({
                      type: BOTTOMSHEET_TYPES.ACTION,
                      actionBottomsheetProps: {
                        withVaultTheme: false,
                        buttons: [
                          {
                            label: 'Choose from library',
                            type: 'secondary',
                            leadingIcon: faLandscape,
                            onClick() {
                              closeBottomsheet();
                              openAvatarUpload();
                            },
                          },
                          {
                            label: 'Remove current picture',
                            type: 'secondary',
                            leadingIcon: faTrash,
                            leadingIconClassName: 'text-destructive300',
                            className: 'text-destructive300 rounded-xl rounded-t-none',
                            onClick() {
                              setIsRemovingProfilePicture(true);
                              setValue('avatarImageMediaId', null, {
                                shouldDirty: true,
                              });
                              setValue('usingDefaultAvatar', true);
                              closeBottomsheet();
                            },
                            position: 'bottom',
                          },
                        ],
                        className: 'h-fit pb-2',
                      },
                    });
                  } else {
                    openAvatarUpload();
                  }
                }
              : undefined
          }
          disabled={isSubmitting}
          disabledClassName="opacity-50 cursor-default"
        />
      </View>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <View className="mt-[20px] w-full items-start pb-10 pr-[16px]">
          {/* Username */}
          <View className="mb-[28px] flex flex-col gap-3">
            <Text
              className="cursor-pointer font-title text-[16px]/[20px] font-medium text-white"
              onClick={() => setFocus('username')}
            >
              Username
            </Text>
            {disableUsernameEditing && (
              <Text className="text-base-m font-medium text-base500">
                Contact us at{' '}
                <a className="text-base500" href={mailToLink}>
                  help@vault.fm
                </a>{' '}
                to change your username
              </Text>
            )}
          </View>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('username', {
              onChange(event) {
                setValue(
                  'username',
                  removeInvalidUsernameChars(event.target.value.toLowerCase().trim()),
                );
              },
            })}
            error={errors.username?.message}
            className="pb-0.5"
            autoCapitalize="none"
            setFocus={() => setFocus('username')}
            isDisabled={disableUsernameEditing}
          />

          {/* Display name */}
          <Text
            className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white"
            onClick={() => setFocus('displayName')}
          >
            Display name
          </Text>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('displayName')}
            error={errors.displayName?.message}
            className="pb-0.5"
            setFocus={() => setFocus('displayName')}
          />

          {/* Email */}
          <Text className="mb-[28px] mt-[20px] cursor-pointer font-title text-title-s font-medium text-white">
            Email
          </Text>
          <FormRow
            placeholder=""
            isSubmitting={isSubmitting}
            registerResult={register('email')}
            error={errors.email?.message}
            className="pb-0.5"
            autoCapitalize="none"
            setFocus={() => setFocus('email')}
            isDisabled={userFragment.authEmail != null}
            trailingIcon={
              userFragment.authEmail != null && (
                <FontAwesomeIcon icon={faLock} className="text-base500" />
              )
            }
          />

          {/* Google Location */}
          <Text className="mt-[20px] cursor-pointer font-title text-title-s font-medium text-white">
            Location
          </Text>
          <Text className="mb-4 mt-3 text-base-m font-medium text-base500">
            Used for invites to local events
          </Text>
          <View
            className="flex cursor-pointer flex-row items-center justify-between gap-2"
            onClick={() => {
              openBottomsheet({
                type: 'SEARCH_GOOGLE_LOCATION',
                searchGoogleLocationBottomsheetProps: {
                  initialLocation:
                    googlePlaceId && googlePlaceInputLabel
                      ? {
                          placeId: googlePlaceId,
                          label: googlePlaceInputLabel,
                        }
                      : null,
                  onLocationChange(value) {
                    setValue('googlePlaceId', value?.value.place_id ?? null, {
                      shouldDirty: true,
                    });
                    setValue('googlePlaceInputLabel', value?.label ?? null, {
                      shouldDirty: true,
                    });

                    if (value) {
                      closeBottomsheet('other');
                    }
                  },
                },
              });
            }}
          >
            <View className="flex flex-row items-center gap-2">
              <FontAwesomeIcon icon={faSearch} className="text-base500" />
              <Text
                className={clsx('text-base-m font-medium', {
                  'text-base500': !googlePlaceId,
                  'text-white': !!googlePlaceId,
                })}
              >
                {googlePlaceInputLabel || 'Set location'}
              </Text>
            </View>
            {!!googlePlaceId && !!googlePlaceInputLabel && (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="mr-1 cursor-pointer justify-end place-self-end self-center text-white"
                onClick={event => {
                  event.stopPropagation();
                  setValue('googlePlaceId', null, {
                    shouldDirty: true,
                  });
                  setValue('googlePlaceInputLabel', null, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          </View>
          <View className={twMerge('mt-3 h-[1px] w-full bg-base700')} />
        </View>
      </form>
    </SettingsLayout>
  );
}

export function SkeletonProfiePicture({ className }: { className?: string }) {
  return <LoadingSkeleton className={className ?? 'mb-[12px] h-[100px] w-[100px] rounded-full'} />;
}

export function FormRow({
  isSubmitting,
  error,
  icon,
  placeholder,
  registerResult,
  className,
  autoCapitalize,
  type = 'text',
  setFocus,
  isDisabled,
  trailingIcon,
}: {
  isSubmitting: boolean;
  error?: string;
  icon?: ReactNode;
  placeholder: string;
  registerResult: UseFormRegisterReturn;
  className?: string;
  autoCapitalize?: 'none' | 'off' | 'on' | 'sentences' | 'words' | 'characters';
  type?: HTMLInputTypeAttribute;
  setFocus: () => void;
  isDisabled?: boolean;
  trailingIcon?: ReactNode;
}) {
  return (
    <View className={twMerge('w-full cursor-pointer', className)} onClick={setFocus}>
      <View className="mx-1 my-3 box-content flex flex-col">
        <View className={twMerge('mx-1 flex flex-row gap-4', isSubmitting && 'opacity-50')}>
          {icon}
          <input
            type={type}
            disabled={isSubmitting || isDisabled}
            className={twMerge(
              'flex-1 border-none bg-transparent font-base text-[16px]/[20px] font-normal focus:border-none focus:outline-none',
              isDisabled ? 'text-base500' : 'text-base50',
            )}
            placeholder={placeholder}
            {...registerResult}
            autoCapitalize={autoCapitalize}
          />
          {trailingIcon}
          {error != null && <FormErrorIndicator />}
        </View>
        <View
          className={twMerge(
            'mt-3 h-[1px] w-full bg-base700',
            error != null && 'bg-destructive300',
          )}
        />
      </View>
      {error && (
        <Text className="mb-5 mt-3 text-center font-base text-base-m font-normal text-destructive300">
          {error}
        </Text>
      )}
    </View>
  );
}

export function EditProfileSkeleton() {
  const { isDesktop } = useWindow();
  return (
    <View
      className={twMerge(
        'z-above1 flex w-full flex-1 flex-col items-center text-white',
        !isDesktop && 'sm:w-[66%] md:w-[50%] lg:w-[33%]',
      )}
    >
      <SkeletonProfiePicture />
      <LoadingSkeleton className="mb-[20px] h-[30px] w-[60px]" />
      <View className="box-border flex w-full flex-1 flex-col bg-black px-[16px]">
        <LoadingSkeleton className="mb-[28px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
        <LoadingSkeleton className="mb-[28px] mt-[20px] h-[30px] w-[100px]" />
        <LoadingSkeleton className="mb-[20px] h-[30px] w-full" />
      </View>
    </View>
  );
}
