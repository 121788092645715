import { useMemo } from 'react';
import { type GetProps, type GetThemeValueForKey, styled, Text as TamaguiText } from 'tamagui';
import { getColorWithOpacityTamagui } from '../../utils/colorUtils';

const StyledLabel = styled(TamaguiText, {
  fontFamily: '$title',
  fontWeight: '500',
  flexShrink: 1,
  variants: {
    size: {
      xs: {
        fontSize: 12,
        lineHeight: 16,
      },
      sm: {
        fontSize: 14,
        lineHeight: 16,
      },
      md: {
        fontSize: 16,
        lineHeight: 20,
      },
    },
    weight: {
      normal: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      bold: {
        fontWeight: '700',
      },
    },
  },
});

type LabelProps = {
  label: string;
  color: string;
  numberOfLines?: number;
  size?: 'xs' | 'sm' | 'md';
  weight?: 'normal' | 'medium' | 'bold';
} & Omit<GetProps<typeof TamaguiText>, 'style' | 'color'>;

type SubtextProps = {
  text: string;
  color: string;
  numberOfLines?: number;
  size?: 'xs' | 'sm' | 'md';
} & Omit<GetProps<typeof TamaguiText>, 'style' | 'color'>;

export const Label = ({
  label,
  color,
  numberOfLines = 1,
  size = 'md',
  weight = 'medium',
  ...rest
}: LabelProps) => {
  return (
    <StyledLabel
      color={color as GetThemeValueForKey<'color'>}
      numberOfLines={numberOfLines}
      size={size}
      weight={weight}
      {...rest}
    >
      {label}
    </StyledLabel>
  );
};

const StyledSubtext = styled(TamaguiText, {
  fontFamily: '$base',
  fontWeight: '400',
  flexShrink: 1,
  variants: {
    size: {
      xs: {
        fontSize: 10,
        lineHeight: 14,
      },
      sm: {
        fontSize: 12,
        lineHeight: 16,
      },
      md: {
        fontSize: 14,
        lineHeight: 20,
      },
    },
  },
});
export const Subtext = ({ text, color, numberOfLines = 1, size = 'md', ...rest }: SubtextProps) => {
  const subtextColor = useMemo(() => getColorWithOpacityTamagui(color, 0.5), [color]);

  return (
    <StyledSubtext size={size} color={subtextColor} numberOfLines={numberOfLines} {...rest}>
      {text}
    </StyledSubtext>
  );
};
