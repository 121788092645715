import { useNavigate } from 'react-router';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginStatus } from '../types/authTypes';
import { useArtistHandle } from './useArtistHandle';
import { useOwnedArtist } from './useOwnedArtist';

export const useOwnerOnlyAccess = () => {
  const { artistHandle } = useArtistHandle();
  const { loginStatus } = useAuthContext();
  const navigate = useNavigate();

  const isLoading = loginStatus === LoginStatus.LOADING;
  const isLoggedOut = loginStatus === LoginStatus.LOGGED_OUT;

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwner = !!ownedArtist;

  if ((!isOwner || isLoggedOut) && !isLoading) {
    return navigate('404', {
      replace: true,
    });
  }
};
