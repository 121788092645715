import { WAVE_HEIGHT } from '../constants/waveformConstants';
import { chunkFill } from './arrayUtils';

function barCoordinates({
  index,
  barWidth,
  maxBarHeight,
  barMargin,
  amplitude,
  containerHeight,
  topWaveHeight = WAVE_HEIGHT,
}: {
  index: number;
  barWidth: number;
  maxBarHeight: number;
  minBarHeight?: number;
  barMargin: number;
  amplitude: number;
  containerHeight: number;
  topWaveHeight?: number;
}): {
  x: number;
  y: number;
  w: number;
  h: number;
} {
  const currBarHeight = (amplitude / 100) * maxBarHeight;
  const verticalCenter = (containerHeight - currBarHeight) * topWaveHeight;

  return {
    x: index * (barWidth + barMargin),
    y: verticalCenter,
    w: barWidth,
    h: currBarHeight,
  };
}

function roundRect({
  ctx,
  h,
  r,
  w,
  x,
  y,
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  w: number;
  h: number;
  r: number;
}) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;

  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
  return ctx;
}
const bgColor = '#F3F4F6';

function paintCanvas({
  canvasRef,
  normalizedPeaks,
  maxBarHeight,
  barWidth,
  barMargin,
  playingBarNum,
  hoverXCoord,
  isActiveTrack,
  showHalf,
  snippet,
  customActiveColor,
  theme = 'light',
}: {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  normalizedPeaks: number[];
  maxBarHeight: number;
  barWidth: number;
  barMargin: number;
  playingBarNum: number;
  hoverXCoord: number;
  isActiveTrack: boolean;
  showHalf: boolean;
  snippet: {
    start: number;
    end: number;
  } | null;
  customActiveColor: string | null;
  theme?: 'dark' | 'light';
}) {
  const ref = canvasRef.current;
  const ctx = ref?.getContext('2d');
  if (!ref || !ctx) {
    return;
  }

  // erase the canvas before painting or waveforms will stack every update
  ctx.fillStyle = bgColor;
  ctx.clearRect(0, 0, ref.width, ref.height);

  ctx.beginPath();

  normalizedPeaks.forEach((p, i) => {
    const coord = barCoordinates({
      index: i,
      barWidth,
      barMargin,
      maxBarHeight,
      amplitude: p,
      containerHeight: ref.height,
      topWaveHeight: WAVE_HEIGHT,
    });

    // paint top half of waveform
    roundRect({
      ctx,
      x: coord.x,
      y: coord.y,
      w: coord.w,
      h: Math.min(coord.h * (showHalf ? WAVE_HEIGHT : 1), maxBarHeight), // ensures rounding on max height bars
      r: 6,
    });

    const withinHover = hoverXCoord > coord.x;
    const alreadyPlayed = i < playingBarNum;

    fillBar({
      ctx,
      opacity: 1,
      withinHover,
      alreadyPlayed: !!snippet ? i >= snippet.start && i < snippet.end : alreadyPlayed,
      isActiveTrack,
      isHovering: hoverXCoord > 0,
      customActiveColor,
      theme,
    });
  });
  ctx.closePath();
}

const WAVEFORM_COLOR_STATES = {
  DARK_THEME: {
    ACTIVE_TRACK: {
      IS_HOVERING: {
        PAST_PLAYED: {
          WITHIN_HOVER: '#E3F41D',
          IDLE: 'hsla(0, 0%, 0%, 0.8)',
        },
        FUTURE_TO_PLAY: {
          WITHIN_HOVER: 'hsla(0, 0%, 0%, 0.8)',
          IDLE: 'hsla(0, 0%, 0%, 0.6)',
        },
      },
      IS_NOT_HOVERING: {
        PAST_PLAYED: '#E3F41D',
        FUTURE_TO_PLAY: 'hsla(0, 0%, 0%, 0.6)',
      },
    },
    INACTIVE_TRACK: {
      HOVER: 'hsla(0, 0%, 0%, 0.8)',
      IDLE: 'hsla(0, 0%, 0%, 0.6)',
    },
  },
  LIGHT_THEME: {
    ACTIVE_TRACK: {
      IS_HOVERING: {
        PAST_PLAYED: {
          WITHIN_HOVER: '#E3F41D',
          IDLE: 'hsla(0, 0%, 100%, 0.4)',
        },
        FUTURE_TO_PLAY: {
          WITHIN_HOVER: 'hsla(0, 0%, 100%, 0.4)',
          IDLE: 'hsla(0, 0%, 100%, 0.2)',
        },
      },
      IS_NOT_HOVERING: {
        PAST_PLAYED: '#E3F41D',
        FUTURE_TO_PLAY: 'hsla(0, 0%, 100%, 0.2)',
      },
    },
    INACTIVE_TRACK: {
      HOVER: 'hsla(0, 0%, 100%, 0.4)',
      IDLE: 'hsla(0, 0%, 100%, 0.2)',
    },
  },
} as const;

function fillStyle({
  alreadyPlayed,
  isActiveTrack,
  withinHover,
  isHovering,
  customActiveColor,
  theme = 'light',
}: {
  alreadyPlayed: boolean;
  isActiveTrack: boolean;
  withinHover: boolean;
  isHovering: boolean;
  customActiveColor: string | null;
  theme?: 'dark' | 'light';
}) {
  const themeColors =
    theme === 'dark' ? WAVEFORM_COLOR_STATES.DARK_THEME : WAVEFORM_COLOR_STATES.LIGHT_THEME;

  if (isActiveTrack) {
    if (isHovering) {
      if (alreadyPlayed) {
        if (withinHover) {
          return customActiveColor ?? themeColors.ACTIVE_TRACK.IS_HOVERING.PAST_PLAYED.WITHIN_HOVER;
        }

        return themeColors.ACTIVE_TRACK.IS_HOVERING.PAST_PLAYED.IDLE;
      }

      if (withinHover) {
        return themeColors.ACTIVE_TRACK.IS_HOVERING.FUTURE_TO_PLAY.WITHIN_HOVER;
      }
    }

    if (alreadyPlayed) {
      return customActiveColor ?? themeColors.ACTIVE_TRACK.IS_NOT_HOVERING.PAST_PLAYED;
    }
    return themeColors.ACTIVE_TRACK.IS_NOT_HOVERING.FUTURE_TO_PLAY;
  }

  if (withinHover) {
    return themeColors.INACTIVE_TRACK.HOVER;
  }

  return themeColors.INACTIVE_TRACK.IDLE;
}

function fillBar({
  alreadyPlayed,
  ctx,
  isActiveTrack,
  opacity,
  withinHover,
  isHovering,
  customActiveColor,
  theme = 'light',
}: {
  ctx: CanvasRenderingContext2D;
  opacity: number;
  withinHover: boolean;
  alreadyPlayed: boolean;
  isActiveTrack: boolean;
  isHovering: boolean;
  customActiveColor: string | null;
  theme?: 'dark' | 'light';
}) {
  function colorBar(color: string): string {
    ctx.globalAlpha = opacity;
    return color;
  }

  ctx.fillStyle = colorBar(
    fillStyle({
      alreadyPlayed,
      isActiveTrack,
      withinHover,
      isHovering,
      customActiveColor,
      theme,
    }),
  );

  ctx.fill();
}

/**
 * convert hsl to hsla
 * @param hsl - hsl color string
 * @param alpha - alpha value
 * @returns - hsla color string
 */
export function hslToHsla(hsl: string, alpha: number) {
  const [h, s, l] = hsl
    .replace(/hsla?\(/, '')
    .replace(/\)/, '')
    .split(',');
  return `hsla(${h}, ${s}, ${l}, ${alpha})`;
}

async function processAudio(track: File) {
  // Safari wider compatibility with webkit prefix https://developer.mozilla.org/en-US/docs/Web/API/AudioContext#browser_compatibility

  // eslint-disable-next-line compat/compat
  window.AudioContext = window.AudioContext;
  // || window.webkitAudioContext;

  const audioBuffer = await track
    .arrayBuffer()
    // eslint-disable-next-line compat/compat
    .then(arrayBuffer => new AudioContext().decodeAudioData(arrayBuffer));

  const normalizedPeaks = normalizeData(filterData(audioBuffer));
  const duration = audioBuffer.duration;

  return { normalizedPeaks, duration };
}

function filterData(audioBuffer: AudioBuffer) {
  // only need one channel of data
  const rawData = audioBuffer.getChannelData(0);
  // number of samples in dataset, assuming ultrawide(3440) with 4px bars, 3440/4=860 and rounded up to 1k
  const samples = 1000;
  // the number of samples in each subdivision
  const blockSize = Math.floor(rawData.length / samples);
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    const blockStart = blockSize * i; // location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j] ?? 0); // find sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide sum by the block size to get the average
  }
  return filteredData;
}

function normalizeData(filteredData: number[]) {
  // highest peak is 100 and normalize others
  const ratio = Math.max(...filteredData) / 100;
  return filteredData.map(point => Math.round(point / ratio));
}

function waveformAvgChunker(waveData: number[], displayableChunks: number) {
  const itemsInChunk = Math.floor(waveData.length / displayableChunks);
  const avgChunks = chunkFill(displayableChunks, itemsInChunk, waveData);
  return avgChunks;
}

export { paintCanvas, roundRect, barCoordinates, processAudio, waveformAvgChunker };
