import { memo, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery, useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  ArtistVaultRowFragmentDoc,
  getFragment,
  GetPriorityActiveVaultSubscriptionsDocument,
  MenuVaultSubscriptionFragmentDoc,
} from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useChosenUserProfile } from '../../store/userProfile';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Dropdown } from '../common/Dropdown';
import { DynamicText } from '../common/DynamicText';
import { PaginatedView } from '../common/PaginatedView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UserRow } from '../structures/UserRow';
import { useBatchedVaultUnseenUpdateCount } from '../views/hooks/useVaultUpdateCount';
import { MenuLogo } from './MenuLogo';

const LIMIT = 10;

export const VaultSelector = memo(({ selectedHandleMemo }: { selectedHandleMemo: string }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [optimisticSelectedArtist, setOptimisticSelectedArtist] = useState<CustomVault | null>(
    null,
  );

  const { loginStatus, loggedInUser } = useAuthContext();
  const { userProfileId } = useChosenUserProfile();
  const ownedArtist = useOwnedArtist({ userProfileId });

  // Clear optimistic state when profile changes AND new data is loaded
  useEffect(() => {
    setOptimisticSelectedArtist(null);
  }, [userProfileId]);

  const {
    orderedList: priorityVaults,
    fetchNextPage,
    hasNextPage,
    isLoadingNewPage,
  } = useInfiniteQuery(GetPriorityActiveVaultSubscriptionsDocument, {
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    filterQueryKey: {
      artistHandle: selectedHandleMemo,
      userId: loggedInUser?.id,
      userProfileId,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.activeVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.activeVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ activeVaultSubscriptionsByPriority }) => {
      return activeVaultSubscriptionsByPriority.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  // use selectedhandle memo to get the selected artist and show it first alawys
  const { data: selectedArtistData } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: {
      link: selectedHandleMemo,
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
      selectedHandleMemo,
      userProfileId,
    },
    keepPreviousData: true,
  });

  const selectedArtist = selectedArtistData?.data.artistLink?.artist;

  const subscriptionData = getFragment(MenuVaultSubscriptionFragmentDoc, priorityVaults);

  const managedVaults = useMemo(
    () =>
      !!ownedArtist
        ? [
            {
              artistId: ownedArtist.id,
              artistName: ownedArtist.name,
              artistProfileImage: ownedArtist.profileImage
                ? {
                    ...ownedArtist.profileImage,
                    cdnUrl: ownedArtist.profileImage.artistFullProfileImageUrl ?? '',
                  }
                : null,
              isManaged: true,
              artistMainVaultId: ownedArtist.mainVault.id,
              artistMainLinkValue: ownedArtist.mainLinkValue,
              artistUserId: ownedArtist.userId,
            },
          ]
        : [],
    [ownedArtist],
  );

  const adminArtistIds = useMemo(
    () => new Set(managedVaults.map(artist => artist.artistId) ?? []),
    [managedVaults],
  );

  const unmanagedVaults =
    subscriptionData
      ?.filter(({ vault }) => !adminArtistIds.has(vault.artist?.id ?? ''))
      .map(({ vault }) => {
        const artist = getFragment(ArtistVaultRowFragmentDoc, vault.artist);

        if (!artist) return null;

        return {
          artistId: artist.id,
          artistName: artist.name,
          artistProfileImage: artist.profileImage,
          isManaged: artist.mainVault.isUserArtistAdmin,
          artistMainVaultId: artist.mainVault.id,
          artistMainLinkValue: artist.linkValue,
          artistUserId: artist.userId,
        };
      }) ?? [];

  const vaults = [...managedVaults, ...unmanagedVaults].filter(
    vault =>
      vault?.artistId !== selectedArtist?.id || vault?.artistMainLinkValue !== selectedHandleMemo,
  );

  if (!selectedArtist) return <MenuLogo withVaultTheme />;

  const selectedProfileImage =
    optimisticSelectedArtist?.artistProfileImage?.artistSmallProfileImageUrl ??
    selectedArtist.profileImage?.artistSmallProfileImageUrl ??
    selectedArtist.profileImage?.cdnUrl;

  const selectedName = optimisticSelectedArtist?.artistName ?? selectedArtist.name;

  const selectedIsManaged =
    optimisticSelectedArtist?.isManaged ?? selectedArtist.mainVault.isUserArtistAdmin;

  return (
    <>
      <View
        className={twMerge(
          'transition-opacity invisible fixed inset-0 z-above6 bg-black/90 opacity-0 duration-500 ease-in-out',
          isOpen && 'visible opacity-80',
        )}
      />
      <Dropdown
        align="center"
        className="shadow-none z-above7 rounded-t-none"
        sideOffset={0}
        onOpenChange={setIsOpen}
        onClose={() => setIsOpen(false)}
        collisionPadding={{
          bottom: 72,
          right: 0,
          left: 0,
          top: 16,
        }}
        trigger={
          // Has to be a <div> to enable the trigger to be clickable
          <div
            className={twMerge(
              'relative',
              isOpen && 'z-above7 rounded-t-xl bg-vault_background backdrop-blur-2xl',
            )}
          >
            <div className={isOpen ? 'w-full rounded-t-xl bg-vault_text/10' : undefined}>
              <Button
                onClick={() => {
                  navigate(artistNavigationPath(selectedArtist.linkValue, '/'));
                }}
                className={twMerge(
                  'flex h-auto max-h-[100px] min-h-16 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
                  '!font-base !text-[16px] !font-medium text-vault_text/50 hover:bg-vault_text/10',
                  isOpen && 'bg-vault_text/10',
                )}
                label={null}
                labelComponent={
                  <UserRow
                    managedVault={selectedIsManaged}
                    name={selectedName}
                    nameComponent={
                      isOpen ? (
                        <Text className="truncate font-title text-[18px] font-medium text-vault_text">
                          {selectedName}
                        </Text>
                      ) : (
                        <DynamicText
                          text={selectedName}
                          className="line-clamp-3 font-title font-medium text-vault_text"
                          maxFontSize={18}
                        />
                      )
                    }
                    profileImageUrl={selectedProfileImage}
                    size="large"
                    verifiedBadge={false}
                    rightComponent={
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={twMerge(
                          'flex-shrink-0 text-vault_text/50',
                          isOpen ? 'invisible h-[16px] w-[16px]' : 'visible',
                        )}
                      />
                    }
                    useVaultTheme
                  />
                }
                fullWidthLabel
              />
            </div>
          </div>
        }
      >
        <PaginatedView
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          className={twMerge(
            'flex max-h-[calc(80vh-100px)] w-[254px] flex-col rounded-b-xl border border-solid border-vault_text/10 bg-vault_background backdrop-blur-2xl',
            'overflow-auto scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-thumb-neutral700 scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1',
          )}
          isFetchingNextPage={isLoadingNewPage}
        >
          {vaults.map(vault => {
            if (!vault) return null;

            return (
              <SubscribedArtist
                key={vault.artistId}
                vault={vault}
                setOptimisticSelectedArtist={setOptimisticSelectedArtist}
                setIsOpen={setIsOpen}
              />
            );
          })}
          {!hasNextPage && (
            <button
              className={twMerge(
                'flex h-[80px] max-h-[80px] min-h-[80px] w-full appearance-none flex-row items-center justify-start gap-3 rounded-none border-none p-3 outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
                'cursor-pointer bg-vault_text/10 !font-base !text-[16px] !font-medium text-vault_text/50 hover:opacity-80',
              )}
              onClick={() => {
                navigate('/vaults');
              }}
            >
              <div className="flex items-center gap-3">
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-vault_text/10">
                  <FontAwesomeIcon icon={faWaveformLines} className="w-5 text-white" />
                </div>

                <p className="truncate font-title text-[18px] font-medium text-vault_text">
                  Explore Vaults
                </p>
              </div>
            </button>
          )}
        </PaginatedView>
      </Dropdown>
    </>
  );
});

type CustomVault = {
  artistId: string;
  artistName: string;
  artistMainLinkValue: string;
  artistProfileImage: {
    id: string;
    artistSmallProfileImageUrl: string | null;
    cdnUrl: string;
    dominantColor: string | null;
  } | null;
  isManaged: boolean;
  artistMainVaultId: string;
  artistUserId: string | null;
};

function SubscribedArtist({
  vault,
  setOptimisticSelectedArtist,
  setIsOpen,
}: {
  vault: CustomVault;
  setOptimisticSelectedArtist: (artist: CustomVault | null) => void;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { loginStatus } = useAuthContext();

  const { updateCount, isLoading } = useBatchedVaultUnseenUpdateCount({
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    vaultId: vault.artistMainVaultId,
    messageChannelId: null,
  });

  const hasUpdateCount = !!updateCount && updateCount > 0;

  const profileImageUrl =
    vault.artistProfileImage?.artistSmallProfileImageUrl ?? vault.artistProfileImage?.cdnUrl;

  return (
    <Button
      className={twMerge(
        'flex h-[80px] max-h-[80px] min-h-[80px] w-full flex-row items-center justify-start gap-3 rounded-none p-3 outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
        'bg-vault_text/10 !font-base !text-[16px] !font-medium text-vault_text/50',
      )}
      onClick={() => {
        setIsOpen(false);
        setOptimisticSelectedArtist(vault);
      }}
      href={artistNavigationPath(vault.artistMainLinkValue, '/')}
      label={null}
      labelComponent={
        <UserRow
          managedVault={vault.isManaged}
          name={vault.artistName}
          profileImageUrl={profileImageUrl}
          loading={isLoading}
          size="large"
          subtitle={
            hasUpdateCount
              ? `${updateCount} ${pluralizeText({ count: updateCount, text: 'update' })}`
              : undefined
          }
          verifiedBadge={false}
          rightComponent={
            hasUpdateCount && (
              <View
                key="updateIndicator"
                className="min-h-2 min-w-2 flex-shrink-0 overflow-hidden rounded-full bg-vault_accent transition-all ease-in-out"
              />
            )
          }
          useVaultTheme
        />
      }
      fullWidthLabel
    />
  );
}
