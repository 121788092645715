import type { FC, FormEventHandler } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { AuthAgreementText } from '../../components/auth/AuthAgreementText';
import { AuthBox } from '../../components/auth/AuthBox';
import { AuthInput } from '../../components/auth/AuthInput';
import { AuthMethodSwitch } from '../../components/auth/AuthMethodSwitch';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ActionField } from '../../components/structures/ActionField';
import { Logo } from '../../components/svg/Logo';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

export const SignInPage: FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const artistHandle = searchParams.get('artistHandle');
  const payeeSecretId = searchParams.get('psid');
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const trackId = searchParams.get('trackId');
  const source = searchParams.get('source');
  const invite = searchParams.get('invite');
  const linkSpotify = searchParams.get('linkSpotify');
  const linkAppleMusic = searchParams.get('linkAppleMusic');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const eventType = searchParams.get('eventType');
  const showReceiptModal = searchParams.get('showReceiptModal');
  const receiptId = searchParams.get('receiptId');

  const {
    isOpen,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
    validEmail,
    lastActiveEmail,
    status,
  } = useSignIn();

  const { loginStatus, loggedInUser } = useAuthContext();

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme={false} />;
  }

  const navigationPath = ({
    artistHandle,
    loggedInUser,
  }: {
    artistHandle?: string | null;
    loggedInUser?: {
      highestSubscriptionLevel?: string | null;
    } | null;
  }) => {
    if (artistHandle != null) {
      return artistNavigationPath(artistHandle, '/');
    }
    if (loggedInUser?.highestSubscriptionLevel != null) {
      return ROUTES.VAULTS;
    }
    return ROUTES.VAULTS;
  };

  if (loginStatus === LoginStatus.LOGGED_IN) {
    const navigateTo = navigationPath({ artistHandle, loggedInUser });

    return <Navigate to={navigateTo} />;
  }

  if (status === 'awaiting-code-input' && (!!lastActivePhoneNumber || !!lastActiveEmail)) {
    const queryParams = constructQueryParams({
      artistHandle,
      openBottomSheet: bottomSheetType,
      redirect,
      code,
      psid: payeeSecretId,
      trackId,
      invite,
      linkSpotify,
      linkAppleMusic,
      sourceReleaseCampaignId,
      eventType,
      source,
      showReceiptModal: showReceiptModal,
      receiptId,
      c: smsCampaignResponseShortcode,
    });

    return <Navigate to={`${ROUTES.VERIFY}?${queryParams}`} />;
  }

  const onNextClickV2: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({
      type: EVENTS.SIGN_IN_CLICK,
      properties: { artistHandle },
    });

    onSignInClick({
      source: 'SignInPage',
    });
  };

  return (
    <SignInPageV2
      onNextClick={onNextClickV2}
      status={status}
      codeSentDisabledSecondsRemaining={codeSentDisabledSecondsRemaining}
      isInCooldown={isInCooldown}
      validPhoneNumber={validPhoneNumber}
      validEmail={validEmail}
      codeRenabled={codeRenabled}
      artistHandle={artistHandle}
    />
  );
};

function SignInPageV2({
  onNextClick,
  status,
  codeSentDisabledSecondsRemaining,
  isInCooldown,
  validPhoneNumber,
  validEmail,
  codeRenabled,
  artistHandle,
}: {
  onNextClick: FormEventHandler<HTMLFormElement>;
  status: string;
  codeSentDisabledSecondsRemaining: number;
  isInCooldown: boolean;
  validPhoneNumber: string | null;
  validEmail: string | null;
  codeRenabled: number;
  artistHandle: string | null;
}) {
  const isSubmitLoading = status === 'sending-code' || status === 'submitting-code';
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 &&
      (!!validPhoneNumber || !!validEmail) &&
      codeRenabled !== 1) ||
    isInCooldown;

  return (
    <div className="relative flex items-center justify-center px-4 h-screen-safe">
      <AuthBox
        header={
          <View className="flex w-full items-center justify-center self-center">
            <View className="flex h-[32px] w-[32px] flex-col items-center justify-center">
              <Logo variant="default" />
            </View>
          </View>
        }
        subText={<Text className="font-title text-[22px] font-medium text-white">Sign In</Text>}
        fill={false}
        userAction={
          <form onSubmit={onNextClick} className="flex w-full flex-col items-center justify-center">
            <ActionField
              label="Next"
              useVaultTheme={false}
              loading={isSubmitLoading}
              disabled={isSubmitDisabled}
              buttonType="submit"
            >
              <AuthInput withVaultTheme={false} />
            </ActionField>
          </form>
        }
        footerInfo={
          <AuthAgreementText
            withVaultTheme={false}
            artistHandle={artistHandle ? artistHandle : undefined}
          />
        }
        subFooter={<AuthMethodSwitch />}
      />
    </div>
  );
}
