import { createSwitch, type GetThemeValueForKey, Stack, styled, type SwitchProps } from 'tamagui';
import { useSnapshot } from 'valtio';
import { COLOR } from '../../constants/colorConstants';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { getColorWithOpacity } from '../../utils/colorUtils';

const Frame = styled(Stack, {
  width: 40,
  borderRadius: 20,
  borderColor: 'transparent',
  position: 'relative',
  cursor: 'pointer',
});

const Thumb = styled(Stack, {
  width: 20,
  height: 20,
  borderRadius: 20,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
});

export const Switch = createSwitch({
  Frame,
  Thumb,
});

export const Toggle = ({
  useVaultTheme = false,
  checked,
  ...rest
}: {
  useVaultTheme?: boolean;
  size?: string;
  checked?: boolean;
} & SwitchProps) => {
  const VaultThemeSnapshot = useSnapshot(VaultThemeStore);

  const uncheckedThumbColor = COLOR.black;
  const checkedThumbColor = useVaultTheme ? VaultThemeSnapshot.accentTextColor : COLOR.black;
  const thumbColor = checked ? checkedThumbColor : uncheckedThumbColor;

  const uncheckedBackgroundColor = useVaultTheme
    ? getColorWithOpacity(VaultThemeSnapshot.textColor, 0.2)
    : getColorWithOpacity(COLOR.base_text, 0.2);
  const checkedBackgroundColor = useVaultTheme ? VaultThemeSnapshot.accentColor : COLOR.base_accent;
  const backgroundColor = checked ? checkedBackgroundColor : uncheckedBackgroundColor;

  return (
    <Switch
      checked={checked}
      id="unstyled-switch"
      backgroundColor={backgroundColor as GetThemeValueForKey<'backgroundColor'>}
      {...rest}
    >
      <Switch.Thumb
        animation="quick"
        animateOnly={['transform']}
        backgroundColor={thumbColor as GetThemeValueForKey<'backgroundColor'>}
      />
    </Switch>
  );
};
