import { type FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { SubscriptionTierLevel, VaultType } from '../../graphql/generated';
import {
  type FiltersSchema,
  filtersState,
  initialFilters,
  useMySubscribers,
} from '../../hooks/useMySubscribers';
import { useStableCallback } from '../../hooks/useStableCallback';
import { Radio } from '../../tamagui-components/elements/Radio';
import type { SubscribersFiltersBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

type InternalState = {
  sort: FiltersSchema['sort'];
  tierLevel: FiltersSchema['tierLevel'] | null;
};

export const SubscribersFiltersBottomsheet: FC<SubscribersFiltersBottomsheetProps> = ({
  withVaultTheme,
  vaultType,
  vaultId,
}) => {
  const { refetch } = useMySubscribers({ vaultId, searchQueryString: undefined });

  const { closeBottomsheet } = useBottomsheetContainer();

  const { sort, tierLevel } = useSnapshot(filtersState);

  const [state, setState] = useState<InternalState>({
    sort,
    tierLevel,
  });

  const applyFilters = useStableCallback(() => {
    filtersState.sort = state.sort;
    filtersState.tierLevel = state.tierLevel;

    refetch();
    closeBottomsheet();
  });

  return (
    <View className="w-full overflow-x-hidden md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-col items-center justify-center gap-6 md2:px-6">
        <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
          <Text
            className={twMerge(
              'text-center font-title text-title-l font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            {vaultType === VaultType.Freemium ? 'Sort & filter' : 'Sort'}
          </Text>

          <Text
            className={twMerge(
              'self-start text-center font-base text-base-m font-medium text-base500',
              withVaultTheme ? 'text-vault_text/50' : 'text-base500',
            )}
          >
            Sort
          </Text>
        </View>

        <View className="flex w-full flex-col self-start md:w-[91%]">
          <Button
            label="Newest"
            type="secondary"
            className={twMerge(
              'mb-[1px]',
              withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
            )}
            position="top"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Newest</Text>
                <Radio useVaultTheme={withVaultTheme} selected={state.sort === 'DESC'} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'DESC' }))}
            fullWidthLabel
          />
          <Button
            label="Oldest"
            type="secondary"
            position="bottom"
            className={withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined}
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Oldest</Text>
                <Radio useVaultTheme={withVaultTheme} selected={state.sort === 'ASC'} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'ASC' }))}
            fullWidthLabel
          />
        </View>

        {vaultType === VaultType.Freemium && (
          <>
            <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
              <Text
                className={twMerge(
                  'self-start text-center font-base text-base-m font-medium',
                  withVaultTheme ? 'text-vault_text/50' : 'text-base500',
                )}
              >
                Filter
              </Text>
            </View>

            <View className="flex w-full flex-col self-start md:w-[91%]">
              <Button
                label="Paid Members"
                type="secondary"
                className={twMerge(
                  'mb-[1px]',
                  withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
                )}
                position="top"
                labelComponent={
                  <View className="flex w-full items-center justify-between">
                    <Text>All members</Text>
                    <Radio selected={state.tierLevel == null} useVaultTheme={withVaultTheme} />
                  </View>
                }
                onClick={() => setState(prev => ({ ...prev, tierLevel: null }))}
                fullWidthLabel
              />
              <Button
                label="Paid members"
                type="secondary"
                className={twMerge(
                  'mb-[1px]',
                  withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
                )}
                position="middle"
                labelComponent={
                  <View className="flex w-full items-center justify-between">
                    <Text>Paid members</Text>
                    <Radio
                      selected={state.tierLevel === SubscriptionTierLevel.Paid}
                      useVaultTheme={withVaultTheme}
                    />
                  </View>
                }
                onClick={() =>
                  setState(prev => ({ ...prev, tierLevel: SubscriptionTierLevel.Paid }))
                }
                fullWidthLabel
              />
              <Button
                label="Free members"
                className={twMerge(
                  'mb-[1px]',
                  withVaultTheme ? 'bg-vault_text/10 text-vault_text' : undefined,
                )}
                type="secondary"
                position="bottom"
                labelComponent={
                  <View className="flex w-full items-center justify-between">
                    <Text>Free members</Text>
                    <Radio
                      selected={state.tierLevel === SubscriptionTierLevel.Free}
                      useVaultTheme={withVaultTheme}
                    />
                  </View>
                }
                onClick={() =>
                  setState(prev => ({ ...prev, tierLevel: SubscriptionTierLevel.Free }))
                }
                fullWidthLabel
              />
            </View>
          </>
        )}
        <View className="flex w-full flex-col items-center justify-center gap-6 self-start md:w-[91%]">
          <Button
            label="Apply"
            isExternal
            type="primary"
            className={twMerge(
              'w-full',
              withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : undefined,
            )}
            linkClassName="w-full"
            onClick={applyFilters}
          />

          <Button
            label="Reset"
            onClick={() => {
              Object.assign(filtersState, initialFilters);
              setState({
                sort: 'DESC',
                tierLevel: null,
              });
            }}
            type="default"
            className={twMerge(
              'w-full !font-title !text-title-s !font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          />
        </View>
      </View>
    </View>
  );
};
