import { setTimeout } from 'timers/promises';
import { type ChangeEventHandler, useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function EmailAuthInput({ useVaultTheme = false }: { useVaultTheme?: boolean }) {
  const {
    email: emailState,
    setEmail,
    errorText,
    validEmail,
    setErrorText,
    status,
    codeRenabled,
  } = useSignIn();

  const [editingEmail, setEditingEmail] = useState(false);

  const email = editingEmail ? emailState : '';

  useEffect(() => {
    setEmail('');
    setEditingEmail(true);
  }, [setEmail]);

  const hasError = errorText != null;

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validEmail || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validEmail]);

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    setEmail(currentInput);
  };

  return (
    <View className="box-border w-full">
      <View className="box-content flex w-full flex-col rounded-l-full border border-solid border-black/5 py-4">
        <motion.div
          initial={{ opacity: 0, y: 28 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="relative box-border flex w-full items-center justify-center gap-2 overflow-hidden"
        >
          <Popover.Root open={hasError}>
            <Popover.Anchor asChild>
              <div className="absolute left-0 top-0" />
            </Popover.Anchor>
            <Popover.Portal>
              <Popover.Content
                side="top"
                sideOffset={26}
                align="start"
                className="z-bottomsheet box-border flex max-w-[320px] flex-col self-center rounded-lg bg-destructive300 p-1 px-2 outline-none will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:w-full md2:max-w-none"
              >
                <Text className="p-3 text-left font-base !text-base-m font-normal text-white">
                  {errorText}
                </Text>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
          <input
            type="email"
            value={email}
            placeholder="Email"
            onChange={e => {
              onChange(e);
              setErrorText(null);
            }}
            disabled={status === 'sending-code'}
            className={twMerge(
              'w-full border-none bg-transparent pl-6 font-base !text-base-l font-normal focus:border-none focus:outline-none',
              useVaultTheme ? 'placeholder:text-vault_text/50' : 'placeholder:text-base_text/50',
              hasError
                ? 'text-destructive300'
                : useVaultTheme
                  ? 'text-vault_text'
                  : 'text-base_text',
            )}
            onFocus={async e => {
              await setTimeout(100);
              e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          />
        </motion.div>
      </View>
    </View>
  );
}
