import { useCallback, useState } from 'react';
import { captureException } from '@sentry/react';
import { useSnapshot } from 'valtio';
import { PlayerType } from '../../audio/AudioController';
import { useAppleMusicContext } from '../../contexts/AppleMusicContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  ConnectAppleMusicDocument,
  type ReleaseCampaignContentType,
} from '../../graphql/generated';
import { useLogError } from '../logger/useLogError';
import { connectAppleMusicApi, MusicKitReady } from './useAppleMusicAuth';

/**
 * Hook to play a track on Apple Music (Make sure to use the AppleMusicProvider)
 *
 * @param resourceId - The ID of the resource to play
 * @param resourceType - The type of the resource to play
 * @param campaignId - The ID of the campaign to play
 * @returns An object containing the onClick function, the isLoading state, the isError state, and the isDisabled state
 */
export function usePlayAppleMusic({
  resourceId,
  resourceType,
  campaignId,
}: {
  resourceId: string | null | undefined;
  resourceType: ReleaseCampaignContentType;
  campaignId: string | null | undefined;
}) {
  const logError = useLogError();
  const { loggedInUser } = useAuthContext();
  const {
    musicKit,
    currentTrack,
    campaignId: currentCampaignId,
    setCampaignId,
  } = useAppleMusicContext();

  const instanceLoaded = useSnapshot(MusicKitReady).instanceLoaded;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();

  const { mutateAsync: connectAppleMusic, isLoading: isConnecting } = useMutation(
    ConnectAppleMusicDocument,
    {
      retry: 3,
    },
  );

  const onClick = useCallback(async () => {
    if (
      musicKit === null ||
      isLoading ||
      !instanceLoaded ||
      resourceId == null ||
      campaignId == null
    ) {
      return;
    }

    setCampaignId(campaignId);

    setIsLoading(true);

    if (!musicKit.isAuthorized) {
      const result = await musicKit.authorize().catch(error => {
        captureException(error, {
          tags: {
            feature: 'usePlayAppleMusic - authorize',
          },
          extra: {
            resourceId,
          },
        });
      });

      if (!result) {
        setIsLoading(false);
        return;
      }

      await connectAppleMusicApi({
        userToken: result,
        openBottomsheet,
        openToast,
        connectAppleMusic,
        loggedInUserId: loggedInUser?.id ?? null,
        logError,
      });
    }

    PlayerType.current = 'appleMusic';

    if (
      currentTrack != null &&
      currentTrack.id === resourceId &&
      currentCampaignId === campaignId
    ) {
      if (musicKit.isPlaying) {
        musicKit.pause();
      } else {
        try {
          musicKit.play();
          setIsError(false);
        } catch (error) {
          setIsError(true);
          captureException(error, {
            tags: {
              feature: 'usePlayAppleMusic - pause',
            },
            extra: {
              resourceId,
            },
          });
        }
      }
    } else {
      const queueOptions: MusicKit.SetQueueOptions =
        resourceType === 'ALBUM' ? { album: resourceId } : { song: resourceId };

      await musicKit
        .setQueue(queueOptions)
        .then(queue => {
          setIsError(false);
          return queue;
        })
        .catch(error => {
          setIsError(true);
          captureException(error, {
            tags: {
              feature: 'usePlayAppleMusic - playNext',
            },
            extra: {
              resourceId,
            },
          });
        });

      musicKit.play();
    }

    setIsLoading(false);
  }, [
    campaignId,
    connectAppleMusic,
    currentCampaignId,
    currentTrack,
    instanceLoaded,
    isLoading,
    logError,
    loggedInUser?.id,
    musicKit,
    openBottomsheet,
    openToast,
    resourceId,
    resourceType,
    setCampaignId,
  ]);

  return {
    onClick,
    isDisabled: musicKit === null || !instanceLoaded || resourceId == null || campaignId == null,
    isLoading: isConnecting || isLoading,
    isError,
  };
}
