import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { gql } from '@soundxyz/gql-string';
import { NewArtistBubble, SkeletonArtistBubble } from '../components/artist/NewArtistBubble';
import { MenuButton } from '../components/buttons/MenuButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../components/loading/LoadingSkeleton';
import { SubscribedArtists } from '../components/menu/SubscribedArtists';
import { useAuthContext } from '../contexts/AuthContext';
import { useInfiniteQuery } from '../graphql/client';
import { GetArtistsToExploreDocument } from '../graphql/generated';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';

gql(/* GraphQL */ `
  query GetArtistsToExplore($after: String, $first: Int!) {
    unsubscribedArtists(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...BubbleArtist
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 20;

export const AllVaultsPage = () => {
  const { isDesktop } = useWindow();
  const { loginStatus, loggedInUser } = useAuthContext();
  const hasSubscriptions = loggedInUser?.highestSubscriptionLevel !== null;

  const [bottomRef, isAtBottom] = useInView();

  const {
    orderedList: artists,
    isInitialLoading,
    isError,
    refetch,
    isFetchingNextPage,
    isLoadingError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(GetArtistsToExploreDocument, {
    staleTime: 0,
    filterQueryKey: {},
    getNextPageParam: ({ data }) => {
      return (
        data.unsubscribedArtists.pageInfo.hasNextPage && {
          after: data.unsubscribedArtists.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ unsubscribedArtists }) => {
      return unsubscribedArtists.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const headerLeft = isDesktop ? (
    <Text className="font-title text-title-m font-normal ">Vaults</Text>
  ) : (
    <MenuButton withVaultTheme={false} />
  );
  const hasUnsubscribedArtists =
    !isInitialLoading && artists.length !== 0 && loginStatus === LoginStatus.LOGGED_IN;

  const headerCenter = isDesktop ? null : (
    <Text className="font-title text-title-m font-medium">Vaults</Text>
  );

  if (isError && artists.length === 0) {
    return (
      <DefaultLayout
        withVaultTheme={false}
        showRoundedTop={false}
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={headerLeft}
        headerCenter={headerCenter}
        headerClassName="md2:!border-b"
        contentClassName="md2:bg-white/3"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="explore_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isInitialLoading) {
    return (
      <DefaultLayout
        withVaultTheme={false}
        showRoundedTop
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={headerLeft}
        headerCenter={headerCenter}
        headerClassName="md2:!border-b"
        contentClassName="md2:bg-white/3"
        childrenWrapperClassName="items-start h-[unset] gap-8"
      >
        <View className="flex w-full flex-col gap-6 pt-5 md2:pt-4">
          {loginStatus === LoginStatus.LOGGED_IN && (
            <View className="flex w-full flex-col gap-1 sm:grid sm:grid-cols-2 sm:gap-x-4 ">
              <LoadingSkeleton className="h-[58px] w-full rounded-xl bg-neutral700" />
              <LoadingSkeleton className="h-[58px] w-full rounded-xl bg-neutral700" />
            </View>
          )}
          <View className="flex w-full flex-col gap-6 pt-5">
            {loginStatus === LoginStatus.LOGGED_IN && (
              <Text className="text-title-m font-medium text-white md2:font-medium">
                Explore Vaults
              </Text>
            )}
            <View className=" grid w-full grid-cols-2 items-start gap-x-4 sm:grid-cols-3">
              <SkeletonArtistBubble className="mb-[20px]" />
              <SkeletonArtistBubble className="mb-[20px]" />
              <SkeletonArtistBubble className="mb-[20px]" />
            </View>
          </View>
        </View>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={headerLeft}
      headerCenter={headerCenter}
      headerClassName="md2:!border-b bg-black"
      childrenWrapperClassName="items-start h-[unset] gap-8 pt-4"
      contentClassName="md2:bg-white/3"
    >
      {loginStatus === LoginStatus.LOGGED_IN && hasSubscriptions && (
        <SubscribedArtists
          selectedHandleMemo={null}
          closeAll={null}
          source="vaults-page"
          className="flex w-full flex-col sm:grid sm:grid-cols-2 sm:gap-x-4"
        />
      )}

      <View className="flex w-full flex-col gap-6">
        {loginStatus === LoginStatus.LOGGED_IN && hasSubscriptions && hasUnsubscribedArtists && (
          <Text className="text-title-m font-medium text-white md2:font-medium">
            Explore Vaults
          </Text>
        )}

        <View className="grid w-full grid-cols-2 items-start gap-x-4 sm:grid-cols-3">
          {isLoadingError ? (
            <ErrorView
              onRetryClick={fetchNextPage}
              loggingType="explore_page_next_page"
              withVaultTheme={false}
            />
          ) : (
            artists.map(artist => (
              <NewArtistBubble artist={artist} key={artist.id} className="mb-[20px]" />
            ))
          )}

          {isFetchingNextPage && (
            <>
              <SkeletonArtistBubble className="mb-[20px]" />
              <SkeletonArtistBubble className="mb-[20px]" />
            </>
          )}
        </View>
      </View>
      <div ref={bottomRef} className="h-4" />
    </DefaultLayout>
  );
};
