import type { FC } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { faEnvelope, faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPhone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { ArtistNotificationList } from '../../components/settings/notifications/ArtistNotificationsList';
import { NotificationRow } from '../../components/settings/notifications/NotificationRow';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';

import { useNotificationSettings } from '../../hooks/useNotificationSettings';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  fragment userNotificationSettings on PrivateUser {
    id
    phone
    authEmail
    notificationSettings {
      isSmsEnabled
      smsNotifications {
        newArtistTrack
        newSubscriber
      }
      pushNotificationsEnabled
    }
  }
`);

export const NotificationSettingsPage: FC = () => {
  const { loginStatus, isEmailVerified } = useAuthContext();
  const navigate = useNavigate();

  const { data, isLoading, isError, refetch } = useNotificationSettings();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError && !isLoading) {
    return (
      <SettingsLayout title="Notification settings">
        <ErrorView onRetryClick={refetch} loggingType="notification-settings" withVaultTheme />
      </SettingsLayout>
    );
  }
  if (isLoading) {
    return <SettingsLayout title="Notification settings" />;
  }
  const currentUserData = data?.currentUser;
  if (!currentUserData || currentUserData?.__typename !== 'QueryCurrentUserSuccess') {
    if (currentUserData?.__typename === 'AccountDeletedPending') {
      return <Navigate to={ROUTES.LANDING_PAGE} />;
    }

    return (
      <SettingsLayout title="Notification settings">
        <ErrorView onRetryClick={refetch} loggingType="notification-settings" withVaultTheme />
      </SettingsLayout>
    );
  }

  const currentUserNotifications = currentUserData.data.notificationSettings;

  return (
    <SettingsLayout title="Notification settings">
      <div className="flex w-full flex-col space-y-6 pb-10">
        <section className="flex flex-col space-y-3">
          <div className="flex flex-col space-y-1">
            <h2 className="font-title text-title-m text-white">Global notifications</h2>
            <p className="font-base text-base-m text-white/50">Applies to all artists.</p>
          </div>

          <ul
            role="list"
            className="w-full list-none space-y-3 p-0 "
            aria-label="Notification settings"
          >
            <li className="overflow-hidden rounded-t-md">
              <NotificationRow
                icon={isEmailVerified ? faEnvelope : faPhone}
                label={`${isEmailVerified ? 'Email' : 'SMS'} notifications`}
                onClick={() => {
                  navigate(ROUTES.SETTINGS_SMS_NOTIFICATION_SETTINGS);
                }}
                value={currentUserNotifications.isSmsEnabled ? 'On' : 'Off'}
              />
            </li>
            <li className="overflow-hidden rounded-b-md">
              <NotificationRow
                icon={faMobile}
                label="Push notifications"
                onClick={() => {
                  navigate(ROUTES.SETTINGS_PUSH_NOTIFICATION_SETTINGS);
                }}
                value={currentUserNotifications.pushNotificationsEnabled ? 'On' : 'Off'}
              />
            </li>
          </ul>
        </section>

        <ArtistNotificationList />
      </div>
    </SettingsLayout>
  );
};
