import { twMerge } from 'tailwind-merge';
import { ProfileImage } from '../user/ProfileImage';
import { ActionField } from './ActionField';

export const UserActionField = ({
  userName,
  profileImageUrl,
  onClick,
  loading,
  disabled,
  useVaultTheme = false,
  label,
  buttonType = 'button',
}: {
  userName: string;
  profileImageUrl?: string | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  useVaultTheme: boolean;
  label: string;
  buttonType?: 'submit' | 'button';
}) => {
  return (
    <ActionField
      label={label}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      useVaultTheme={useVaultTheme}
      buttonType={buttonType}
    >
      <div className="flex w-full flex-row items-center gap-3 px-4 py-3.5 md2:py-2.5">
        <ProfileImage
          className="h-6 w-6 md2:h-8 md2:w-8"
          profileImageUrl={profileImageUrl}
          href={null}
        />
        <p
          className={twMerge(
            'max-w-[50%] flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-base text-base-l',
            useVaultTheme ? 'text-vault_text' : 'text-base_text',
          )}
        >
          {userName}
        </p>
      </div>
    </ActionField>
  );
};
