import type { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faLock, faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import type { VaultContentType } from '../../graphql/generated';
import { Subtext } from '../../tamagui-components/elements/TextElements';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { Image } from '../common/Image';
import { Text } from '../common/Text';

type Size = 'sm' | 'md';

const SIZES: Record<Size, Record<'FOLDER' | 'MEDIA' | 'TRACK', string | null> | null> = {
  sm: {
    FOLDER: 'h-[45px] w-[48px]',
    TRACK: 'h-[45px] w-[45px]',
    MEDIA: 'h-[45px] w-[48px]',
  },
  md: {
    FOLDER: 'h-[66px] w-[75px]',
    TRACK: 'h-[64px] w-[64px]',
    MEDIA: 'h-[60px] w-[48px]',
  },
};

export function Preview({
  title,
  media,
  blurredMedia,
  type,
  locked,
  color,
  isSnippet,
  themeMode,
  videoRef,
  thumbnailUrl,
  size,
}: {
  title: string;
  media: string | null | undefined;
  blurredMedia?: string | null | undefined;
  type: VaultContentType;
  locked: boolean;
  color: string;
  isSnippet: boolean;
  themeMode: 'light' | 'dark';
  thumbnailUrl?: string | null;
  size: Size;
  videoRef?: RefObject<HTMLVideoElement>;
}) {
  const { FOLDER, MEDIA, TRACK } = SIZES[size] ?? {};

  switch (type) {
    case 'FOLDER':
      return (
        <div
          className={twMerge(
            'bg-contain bg-center bg-no-repeat',
            themeMode === 'light' ? 'bg-folder-background-black' : 'bg-folder-background-white',
            FOLDER,
          )}
        />
      );
    case 'IMAGE':
      return (
        <Media
          title={title}
          media={
            <Image
              src={media ?? ''}
              alt={title}
              className={twMerge('rounded-md object-cover', MEDIA)}
            />
          }
          blurredMedia={blurredMedia}
          locked={locked}
          themeMode={themeMode}
          size={size}
        />
      );
    case 'VIDEO':
      return (
        <Media
          title={title}
          media={
            <video
              ref={videoRef}
              className={twMerge(
                'block cursor-pointer rounded-md bg-vault_text/10 object-cover',
                MEDIA,
              )}
              preload="metadata"
              controls={false}
              controlsList="nodownload"
              src={media ?? ''}
              onClick={e => e.preventDefault()}
              poster={thumbnailUrl ?? undefined}
            />
          }
          blurredMedia={blurredMedia}
          locked={locked}
          themeMode={themeMode}
          size={size}
        />
      );
    case 'TRACK':
      return (
        <div className="relative flex items-center justify-center">
          {locked || isSnippet ? (
            <>
              {!!blurredMedia ? (
                <div className={twMerge('relative items-center justify-center', TRACK)}>
                  <Image
                    src={isSnippet && !locked ? media ?? '' : blurredMedia}
                    alt={title}
                    className={twMerge('rounded-md object-cover', TRACK)}
                  />
                  {locked || isSnippet ? (
                    <div
                      className={twMerge(
                        'absolute top-0 flex flex-col items-center justify-center gap-1 rounded-md bg-vault_text_opposite/50',
                        TRACK,
                      )}
                    >
                      {locked && (
                        <FontAwesomeIcon
                          icon={faLock}
                          fontSize={14}
                          className="text-vault_text/50"
                        />
                      )}
                      {isSnippet && (
                        <Text className="font-base text-[10px] font-medium text-vault_text/50">
                          Snippet
                        </Text>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <div
                    className={twMerge(
                      'bg-contain bg-center bg-no-repeat',
                      themeMode === 'light'
                        ? 'bg-new-file-background-black'
                        : 'bg-new-file-background-white',
                      MEDIA,
                    )}
                  />

                  <div className="absolute flex flex-col items-center justify-center gap-1 pt-1">
                    <FontAwesomeIcon
                      icon={locked ? faLock : faWaveformLines}
                      fontSize={locked ? 14 : 16}
                      className={locked ? 'text-vault_text/50' : 'text-vault_text'}
                    />
                    {isSnippet && !locked && <Subtext text="Snippet" color={color} size="xs" />}
                  </div>
                </>
              )}
            </>
          ) : media ? (
            <Image src={media} alt={title} className={twMerge('rounded-md object-cover', TRACK)} />
          ) : (
            <>
              <div
                className={twMerge(
                  'bg-contain bg-center bg-no-repeat',
                  themeMode === 'light'
                    ? 'bg-new-file-background-black'
                    : 'bg-new-file-background-white',
                  MEDIA,
                )}
              />

              <div className="absolute flex items-center justify-center">
                <FontAwesomeIcon icon={faWaveformLines} fontSize={16} className="text-vault_text" />
              </div>
            </>
          )}
        </div>
      );
    default:
      passiveExhaustiveGuard(type);
      return;
  }
}

const Media = ({
  locked,
  blurredMedia,
  themeMode,
  media,
  title,
  size,
}: {
  locked: boolean;
  blurredMedia?: string | null | undefined;
  themeMode?: 'light' | 'dark';
  media?: React.ReactNode;
  title: string;
  size: Size;
}) => {
  const { MEDIA } = SIZES[size] ?? {};

  return (
    <div className="relative flex items-center justify-center">
      {locked ? (
        <>
          {!!blurredMedia ? (
            <Image
              src={blurredMedia}
              alt={title}
              className={twMerge('rounded-md object-cover', MEDIA)}
            />
          ) : (
            <div
              className={twMerge(
                'bg-contain bg-center bg-no-repeat',
                themeMode === 'light'
                  ? 'bg-new-file-background-black'
                  : 'bg-new-file-background-white',
                MEDIA,
              )}
            />
          )}
          <div className="absolute flex items-center justify-center">
            <FontAwesomeIcon icon={faLock} fontSize={14} className="text-vault_text/50" />
          </div>
        </>
      ) : !!media ? (
        media
      ) : (
        <div
          className={twMerge(
            'bg-contain bg-center bg-no-repeat',
            themeMode === 'light' ? 'bg-new-file-background-black' : 'bg-new-file-background-white',
            MEDIA,
          )}
        />
      )}
    </div>
  );
};
