import { useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException, captureMessage } from '@sentry/react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faMessage } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faClose, faPlusCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUp } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { uuidv4 } from '@soundxyz/utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useInfiniteQuery, useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AddCommentDocument,
  ContentByIdDocument,
  type ContentByIdQuery,
  type ContentCommentRowFragment,
  ContentCommentRowFragmentDoc,
  ContentCommentsDocument,
  ContentThreadCommentsDocument,
  DeleteCommentDocument,
  type FragmentType,
  GetBanStatusDocument,
  getFragment,
  makeFragmentData,
  MessagesByIdDocument,
  ReactCommentDocument,
  RemoveReactCommentDocument,
  ReplyToMessageFragmentDoc,
  TierTypename,
} from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { EVENTS } from '../../types/eventTypes';
import { pluralizeText } from '../../utils/textUtils';
import { secondsToTimestamp, type TimestampString } from '../../utils/timestampParse';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';
import {
  getContentCommentsOwnComments,
  useContentCommentsOwnComments,
} from '../views/hooks/useContentCommentsReplies';
import { ContentCommentRow } from './ContentCommentRow';

RefetchOnComplete({
  trigger: [
    ReactCommentDocument,
    RemoveReactCommentDocument,
    AddCommentDocument,
    DeleteCommentDocument,
  ],
  refetch: [
    ContentByIdDocument,
    ContentCommentsDocument,
    ContentThreadCommentsDocument,
    MessagesByIdDocument,
  ],
});

export function MediaCommentList({
  contentId,
  contentTitle,
  withVaultTheme,
  onClose,
}: {
  contentId: string;
  contentTitle: string;
  withVaultTheme: boolean;
  onClose: () => void;
}) {
  const { loggedInUser } = useAuthContext();
  const [, setIsScrolling] = useState(false);

  const { data: content, isLoading } = useQuery(ContentByIdDocument, {
    variables: {
      vaultContentId: contentId,
      asArtistId: loggedInUser?.artist?.id,
    },
    staleTime: 0,
    select(data) {
      const type = data.data.vaultContentById?.__typename;
      if (type !== 'VaultImage' && type !== 'VaultVideo') return null;

      return data.data.vaultContentById;
    },
  });

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: content?.vault?.activeSubscription,
    isOwner: !!content?.vault.isUserArtistAdmin,
  });

  const [replyToComment, setReplyToComment] = useState<{
    comment: ContentCommentRowFragment;
    userDisplayName: string;
    rootIndex: number;
  } | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  if (isLoading) {
    return (
      <View className="flex h-full flex-col items-center">
        <CommentHeader
          contentTitle={contentTitle}
          withVaultTheme={withVaultTheme}
          onClose={onClose}
        />
        <View className="box-border flex h-full w-full flex-col items-center justify-between pl-3 pr-[10%]">
          <View className="flex w-full flex-col items-center gap-3 py-3">
            <View className="flex w-full flex-col">
              <SkeletonComment
                isAuthor={false}
                className="rotate-180 scale-x-[-1] pt-3"
                withVaultTheme={withVaultTheme}
              />
              <SkeletonComment
                isAuthor={false}
                className="rotate-180 scale-x-[-1] pt-3"
                withVaultTheme={withVaultTheme}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  if (content == null) {
    return (
      <View className="flex h-full w-full flex-col items-center text-center">
        <CommentHeader
          contentTitle={contentTitle}
          withVaultTheme={withVaultTheme}
          onClose={onClose}
        />
        <View className="flex h-full w-full flex-col items-center justify-between">
          <ErrorComments withVaultTheme={withVaultTheme} />
        </View>
      </View>
    );
  }

  const isFreeTierSubscription = activeSubscriptionFeatures?.tier !== TierTypename.PaidTier;

  return (
    <View className="flex h-full w-full flex-col items-center">
      <CommentHeader
        contentTitle={contentTitle}
        commentCount={content.commentMessageCount}
        withVaultTheme={withVaultTheme}
        onClose={onClose}
      />

      <View className="flex h-full w-full flex-col items-center justify-between">
        <View className="flex h-full w-full flex-col items-center gap-3 py-3">
          {content.commentMessageCount > 0 || content.commentCaptionMessage ? (
            <CommentsList
              withVaultTheme={withVaultTheme}
              content={content}
              setIsScrolling={setIsScrolling}
              isFreeTierSubscription={isFreeTierSubscription}
              virtuosoRef={virtuosoRef}
              onReply={({ userDisplayName, comment, rootIndex }) => {
                const commentFrag = getFragment(ContentCommentRowFragmentDoc, comment);
                setReplyToComment({ comment: commentFrag, userDisplayName, rootIndex });
                inputRef.current?.focus();
              }}
              onClose={onClose}
            />
          ) : (
            <EmptyComments withVaultTheme={withVaultTheme} />
          )}
        </View>

        <View className="flex w-full flex-col items-center justify-end gap-4">
          <View
            className={twMerge('h-[1px] w-full', withVaultTheme ? 'bg-vault_text/5' : 'bg-base700')}
          />
          <>
            {replyToComment && (
              <View className="mx-4 box-border flex w-full flex-row items-center justify-between">
                <Text
                  className={twMerge(
                    'pl-3 !text-base-m',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  Replying to {replyToComment.userDisplayName}
                </Text>
                <FontAwesomeIcon
                  icon={faClose}
                  size="lg"
                  className={twMerge(
                    'cursor-pointer pr-3',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                  onClick={() => setReplyToComment(null)}
                />
              </View>
            )}

            <AddCommentInput
              artistId={content.vault.artist?.id}
              messageChannelId={content.vault.messageChannelId}
              vaultContentId={content.id}
              withVaultTheme={withVaultTheme}
              enableTimestampsButton={false}
              isOwner={!!content?.vault.isUserArtistAdmin}
              onSendComment={() => {
                if (replyToComment?.rootIndex) {
                  virtuosoRef.current?.scrollToIndex({
                    index: replyToComment.rootIndex,
                    align: 'start',
                    behavior: 'smooth',
                  });
                } else {
                  virtuosoRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }
              }}
              activeSubscriptionTier={
                activeSubscriptionFeatures?.tier
                  ? TierTypename[activeSubscriptionFeatures?.tier]
                  : null
              }
              replyToComment={replyToComment?.comment}
              setReplyToComment={setReplyToComment}
              inputRef={inputRef}
            />
          </>
        </View>
      </View>
    </View>
  );
}

function CommentHeader({
  contentTitle,
  commentCount,
  withVaultTheme,
  onClose,
}: {
  contentTitle: string;
  commentCount?: number;
  withVaultTheme: boolean;
  onClose: () => void;
}) {
  const headerCenter = (
    <View className="flex w-full flex-col items-center">
      <h2
        className={twMerge(
          'box-border w-full break-words pt-3 text-center font-title font-medium',
          withVaultTheme && 'text-vault_text',
          commentCount === undefined ? 'pb-3' : 'pb-1',
          contentTitle.length > 30 ? '!text-title-xs' : '!text-title-m',
        )}
      >
        {contentTitle}
      </h2>
      {commentCount !== undefined && (
        <p
          className={twMerge(
            'box-border line-clamp-1 px-5 pb-3 font-title !text-base-m font-normal',
            withVaultTheme ? 'text-vault_text/50' : 'text-base500',
          )}
        >
          {commentCount} {pluralizeText({ text: 'comment', count: commentCount })}
        </p>
      )}
    </View>
  );

  return (
    <View className="w-full">
      <ViewHeader
        center={headerCenter}
        right={
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            className={twMerge('cursor-pointer', withVaultTheme ? 'text-vault_text' : 'text-white')}
            onClick={onClose}
          />
        }
        gridClassName="mx-4 grid-cols-7"
        rightClassName="self-start pt-4"
        centerClassName="col-span-5"
        className="pb-0"
      />
      <View
        className={twMerge(
          'flex h-[1px] flex-row',
          withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
        )}
      />
    </View>
  );
}

function EmptyComments({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View
      className={twMerge(
        'flex h-full flex-col items-center justify-center gap-2 text-center',
        withVaultTheme && 'text-vault_text',
      )}
    >
      <FontAwesomeIcon icon={faMessage} className="h-[60px] w-[60px]" />
      <Text className="text-title-s font-medium">No comments yet</Text>
      <Text
        className={twMerge('font-normal', withVaultTheme ? 'text-vault_text/50' : 'text-base400')}
      >
        Be the first to comment
      </Text>
    </View>
  );
}

function ErrorComments({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View className="flex h-full flex-col items-center justify-center gap-2">
      <Text
        className={twMerge(
          '!text-title-s font-medium',
          withVaultTheme ? 'text-vault_text' : 'text-white',
        )}
      >
        Comments not available
      </Text>
      <Text
        className={twMerge('font-normal', withVaultTheme ? 'text-vault_text/50' : 'text-base400')}
      >
        Please try again later
      </Text>
    </View>
  );
}

function AddCommentInput({
  artistId,
  messageChannelId,
  vaultContentId,
  isOwner,
  isDisabled,
  enableTimestampsButton,
  activeSubscriptionTier,
  onSendComment,
  setReplyToComment,
  replyToComment,
  inputRef,
  withVaultTheme,
}: {
  artistId: string | undefined | null;
  messageChannelId: string;
  vaultContentId: string;
  isOwner: boolean;
  isDisabled?: boolean;
  enableTimestampsButton: boolean;
  activeSubscriptionTier: TierTypename | null;
  onSendComment: () => void;
  setReplyToComment: React.Dispatch<
    React.SetStateAction<{
      comment: ContentCommentRowFragment;
      userDisplayName: string;
      rootIndex: number;
    } | null>
  >;
  replyToComment: ContentCommentRowFragment | undefined | null;
  inputRef: React.RefObject<HTMLInputElement>;
  withVaultTheme: boolean;
}) {
  const { mutate: addComment, isLoading: isSendingMessage } = useMutation(AddCommentDocument, {
    retry: 5,
  });
  const { loggedInUser } = useAuthContext();

  const ownedArtist = useOwnedArtist({ artistId: artistId ?? undefined });

  const { data: isBanned = false } = useQuery(GetBanStatusDocument, {
    variables: !!artistId && { artistId },
    staleTime: 0,
    select(data) {
      return data.data.getBanStatus;
    },
  });

  const [content, setContent] = useState('');

  const disabled = !!isDisabled || isSendingMessage || isBanned || !loggedInUser;

  const trimmedContent = content.trim();

  const { openToast } = useToast();

  const onSend = useStableCallback(() => {
    if (!loggedInUser) return;

    if (!trimmedContent) return;

    const optimisticId = uuidv4();

    const createdAt = new Date().toISOString();

    const ContentCommentsOwnComments = getContentCommentsOwnComments(vaultContentId);

    const replyTo = replyToComment
      ? makeFragmentData(
          {
            id: replyToComment.id,
            creator: replyToComment.creator,
            content,
            createdAt,
            vaultContent: [],
            messageAttachments: [],
            activeSubscriptionTier: replyToComment.activeSubscriptionTier,
          },
          ReplyToMessageFragmentDoc,
        )
      : null;

    const creator =
      ownedArtist != null
        ? ({
            __typename: 'MessageActorArtist',
            id: ownedArtist.id,
            artist: {
              id: ownedArtist.id,
              linkValue: ownedArtist.mainLinkValue,
              name: ownedArtist.name,
              profileImage: ownedArtist.profileImage,
            },
          } satisfies Extract<
            ContentCommentRowFragment['creator'],
            { __typename: 'MessageActorArtist' }
          >)
        : ({
            __typename: 'MessageActorUser',
            id: loggedInUser.id,
            user: loggedInUser,
          } satisfies Extract<
            ContentCommentRowFragment['creator'],
            { __typename: 'MessageActorUser' }
          >);

    ContentCommentsOwnComments.set(optimisticId, {
      data: {
        vaultContentComments: {
          edges: [
            {
              node: {
                // @ts-expect-error Used to render as disabled until real comment is fetched back from refetch
                optimistic: true,

                id: optimisticId,
                createdAt,
                ...makeFragmentData(
                  {
                    id: optimisticId,
                    artistReactions: [],
                    creator,
                    content: trimmedContent,
                    createdAt,
                    myReactionsInfo: [],
                    reactionsSummary: [],
                    user: {
                      __typename: 'PublicUser',
                      id: loggedInUser.id,
                      avatar: loggedInUser.avatar,
                      createdAt: loggedInUser.createdAt,
                      displayName: loggedInUser.computedDisplayName,
                      username: loggedInUser.username,
                    },
                    activeSubscriptionTier: ownedArtist ? null : activeSubscriptionTier,
                    replyTo,
                    threadMessagesPreview: [],
                    threadMessagesCount: 0,
                    threadRootId: replyToComment?.threadRootId || replyToComment?.id || null,
                    updatedAt: createdAt,
                  },
                  ContentCommentRowFragmentDoc,
                ),
              },
            },
          ],
          pageInfo: {
            hasNextPage: true,
            endCursor: null,
          },
        },
      },
    });

    onSendComment();

    function revert() {
      setReplyToComment(null);
      ContentCommentsOwnComments.delete(optimisticId);
    }

    addComment(
      {
        input: {
          optimisticId,
          content: trimmedContent,
          messageChannelId,
          asArtistId: ownedArtist?.id,
          source: 'CONTENT_SECTION',
          vaultContentId,
          replyToId: replyToComment?.id,
          threadRootId: replyToComment?.threadRootId || replyToComment?.id,
        },
        asArtistId: ownedArtist?.id,
      },
      {
        onSuccess({ data }) {
          switch (data.createMessage.__typename) {
            case 'MutationCreateMessageSuccess': {
              setContent('');
              setReplyToComment(null);
              const commentEdge =
                ContentCommentsOwnComments.get(optimisticId)?.data.vaultContentComments.edges[0];
              if (commentEdge) {
                commentEdge.node = data.createMessage.data;
              }
              return;
            }
            default: {
              captureMessage(data.createMessage.message, {
                extra: {
                  data,
                },
              });
              openToast({
                text: 'Failed to send comment. Please try again.',
                variant: 'error',
              });
              revert();
            }
          }
        },
        onError(error) {
          captureException(error, {
            extra: {
              error,
            },
          });
          openToast({
            text: 'Failed to send comment. Please try again.',
            variant: 'error',
          });
          revert();
        },
      },
    );
  });

  if (isBanned) {
    return (
      <View className="mb-[12px] flex w-full flex-row items-center justify-around gap-2">
        <View
          className="p-2 text-center !text-base-s text-vault_text/50 md2:!text-base-m"
          role="status"
          aria-live="polite"
        >
          You cannot leave new comments
        </View>
      </View>
    );
  }

  return (
    <View className="mb-[12px] box-border flex w-full flex-row items-center justify-around gap-2 px-3">
      <ProfileImage
        className="h-[36px] w-[36px]"
        onClick={undefined}
        profileImageUrl={
          ownedArtist && isOwner
            ? ownedArtist?.profileImage?.artistSmallProfileImageUrl ||
              loggedInUser?.avatar.userSmallProfileImageUrl ||
              loggedInUser?.avatar.cdnUrl
            : loggedInUser?.avatar.userSmallProfileImageUrl || loggedInUser?.avatar.cdnUrl
        }
      />
      <View
        className={clsx(
          'flex w-full flex-row items-center rounded-[100px] py-[10px] pl-[20px] pr-[10px]',
          withVaultTheme ? 'bg-vault_text/10' : 'bg-base700',
          disabled && 'opacity-50',
        )}
      >
        <input
          className={twMerge(
            'w-full border-0 bg-transparent !text-base-l focus:font-normal focus:outline-none',
            withVaultTheme ? 'text-vault_text placeholder:text-vault_text/50' : 'text-white',
          )}
          placeholder="Add a comment"
          value={content}
          onChange={e => setContent(e.target.value)}
          disabled={disabled}
          ref={inputRef}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setContent('');
              setReplyToComment(null);
              return;
            }

            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
              return;
            }
          }}
        />
        {enableTimestampsButton && (
          <CommentTimestampButton
            onClick={({ timestamp }) => {
              const endsWithWhitespace = /\s$/;

              if (endsWithWhitespace.test(content) || !content) {
                setContent(`${content}${timestamp} `);
              } else {
                setContent(`${content} ${timestamp} `);
              }
            }}
            isDisabled={disabled}
            withVaultTheme={withVaultTheme}
          />
        )}
      </View>
      {!!trimmedContent && (
        <Button
          iconOnly
          leadingIcon={faArrowUp}
          className={twMerge(
            'mr-[8px] h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full p-0 text-[16px]/[20px] font-black',
            withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : 'bg-yellow100 text-base800',
            disabled && 'bg-transparent',
          )}
          disabledClassName="opacity-30 transition-opacity"
          disabled={disabled}
          loading={isSendingMessage}
          label=""
          onClick={onSend}
          event={{
            type: EVENTS.SEND_COMMENT_MESSAGE,
            properties: {
              contentId: vaultContentId,
              messageChannelId,
              artistId,
              content: trimmedContent,
              isFreeTierSubscription: activeSubscriptionTier === TierTypename.FreeTier,
            },
          }}
        />
      )}
    </View>
  );
}

const SkeletonComment = ({
  isAuthor,
  className,
  withVaultTheme,
}: {
  isAuthor: boolean;
  className?: string;
  withVaultTheme: boolean;
}) => {
  return (
    <View
      className={twMerge(
        'flex w-full flex-row items-end pb-[10px]',
        isAuthor ? 'justify-end' : 'justify-start',
        className,
      )}
    >
      <LoadingSkeleton
        className={twMerge('mr-[4px] h-[30px] w-[30px]', withVaultTheme && 'bg-vault_text/10')}
      />
      <LoadingSkeleton
        className={twMerge(
          'flex h-[40px] w-full flex-col rounded-xl p-[12px]',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    </View>
  );
};

function CommentsList({
  content,
  setIsScrolling,
  isFreeTierSubscription,
  virtuosoRef,
  onReply,
  withVaultTheme,
  onClose,
}: {
  content: Exclude<NonNullable<ContentByIdQuery['vaultContentById']>, 'VaultFolder' | 'VaultTrack'>;
  setIsScrolling: (isScrolling: boolean) => void;
  isFreeTierSubscription: boolean;
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  onReply: (data: {
    comment: FragmentType<typeof ContentCommentRowFragmentDoc>;
    userDisplayName: string;
    rootIndex: number;
  }) => void;
  withVaultTheme: boolean;
  onClose: () => void;
}) {
  const contentArtistId = content.vault.artist?.id;
  const isUserAdminArtist = content.vault.isUserArtistAdmin;
  const vaultContentId = content.id;

  const ownComments = useContentCommentsOwnComments({ vaultContentId });

  const customPages = useMemo(() => {
    return orderBy(
      Array.from(ownComments?.values() || []).filter(v => {
        const commentNode = getFragment(
          ContentCommentRowFragmentDoc,
          v.data.vaultContentComments.edges[0]?.node,
        );
        return !commentNode?.replyTo;
      }),
      v => v.data.vaultContentComments.edges[0]?.node?.createdAt,
      'desc',
    );
  }, [ownComments]);

  const { orderedList, hasNextPage, loadMoreNextPage } = useInfiniteQuery(ContentCommentsDocument, {
    filterQueryKey: {
      vaultContentId,
    },
    customPages,
    variables({ pageParam }) {
      return {
        vaultContentId,
        asArtistId: isUserAdminArtist ? contentArtistId : undefined,
        after: pageParam?.after,
      };
    },
    onFetchCompleted(result) {
      /**
       * Update the "ownComments" store to always have the latest data available
       */
      const ContentCommentsOwnComments = getContentCommentsOwnComments(vaultContentId);
      for (const fetchedComment of result.data.vaultContentComments.edges) {
        const ownCommentEdge = ContentCommentsOwnComments.get(fetchedComment.node.id)?.data
          .vaultContentComments.edges[0];
        if (ownCommentEdge) {
          ownCommentEdge.node = fetchedComment.node;
        }
      }
    },
    list(result) {
      return result.vaultContentComments.edges.map(edge => edge.node);
    },
    staleTime: 0,
    uniq(entity) {
      return entity.id;
    },
    getNextPageParam(result) {
      return (
        result.data.vaultContentComments.pageInfo.hasNextPage && {
          after: result.data.vaultContentComments.pageInfo.endCursor,
        }
      );
    },
  });

  const LoadingFooter = useCallback(
    () =>
      hasNextPage ? (
        <SkeletonComment
          isAuthor={false}
          className="rotate-180 scale-x-[-1] pl-3 pt-3"
          withVaultTheme={withVaultTheme}
        />
      ) : (
        <View className="pl-3 pt-3" />
      ),
    [hasNextPage, withVaultTheme],
  );

  const vaultArtistId = content.vault.artist?.id;
  const vaultId = content.vault.id;
  const contentId = content.id;

  const Header = useStableCallback(() => {
    return content.commentCaptionMessage ? (
      <>
        <ContentCommentRow
          rootIndex={0}
          comment={content.commentCaptionMessage}
          vaultArtistId={vaultArtistId}
          type="caption"
          className="w-full px-3"
          contentId={contentId}
          vaultId={content.vault.id}
          contentDuration={content.__typename === 'VaultVideo' ? content.duration : null}
          disableTimestamps
          isFreeTierSubscription={isFreeTierSubscription}
          vaultArtistProfileImageUrl={
            content.vault.artist?.profileImage?.artistSmallProfileImageUrl ?? null
          }
          vaultArtistHandle={content.vault.artist?.linkValue}
          hasTrackCommentsWriteAccess
          isDisabled={false}
          onReply={onReply}
          onHideReplies={() => {
            virtuosoRef.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          folderId={content.parentVaultContentId}
          onClose={onClose}
        />
        <View className="py-1">
          <View
            className={twMerge(
              'flex h-[1px] w-full flex-row',
              withVaultTheme ? 'bg-vault_text/5' : 'bg-base700',
            )}
          />
        </View>
      </>
    ) : undefined;
  });

  const scrollerRef = useRef<HTMLElement | Window | null>(null);

  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);

  const commentContent = useCallback(
    (i: number, message: (typeof orderedList)[number]) => {
      return (
        <ContentCommentRow
          key={message.id}
          rootIndex={i}
          comment={message}
          type="comment"
          vaultArtistId={vaultArtistId}
          contentId={contentId}
          vaultId={vaultId}
          contentDuration={content.__typename === 'VaultVideo' ? content.duration : null}
          disableTimestamps
          isFreeTierSubscription={isFreeTierSubscription}
          vaultArtistProfileImageUrl={
            content.vault.artist?.profileImage?.artistSmallProfileImageUrl ?? null
          }
          vaultArtistHandle={content.vault.artist?.linkValue}
          hasTrackCommentsWriteAccess
          isDisabled={'optimistic' in message}
          onReply={onReply}
          onHideReplies={() => {
            virtuosoRef.current?.scrollToIndex({
              index: i,
              align: 'start',
              behavior: 'smooth',
            });
          }}
          folderId={content.parentVaultContentId}
          className="pl-3"
        />
      );
    },
    [content, contentId, isFreeTierSubscription, onReply, vaultArtistId, vaultId, virtuosoRef],
  );

  const isScrolling = useRef(false);

  return (
    <Virtuoso
      data={orderedList}
      itemContent={commentContent}
      components={{
        Footer: LoadingFooter,
        Header: Header,
      }}
      className={twMerge(
        'no-scrollbar box-border h-full w-full overflow-x-hidden',
        withVaultTheme ? 'scrollbar-theme' : 'scrollbar-dark',
      )}
      ref={virtuosoRef}
      endReached={loadMoreNextPage}
      overscan={{ reverse: 100, main: 1000 }}
      scrollerRef={handleScrollerRef}
      isScrolling={scrolling => {
        isScrolling.current = scrolling;

        setIsScrolling(scrolling);
      }}
      computeItemKey={(_comment, item) => item.id}
    />
  );
}

function CommentTimestampButton({
  onClick,
  isDisabled,
  withVaultTheme,
}: {
  onClick(data: { timestamp: TimestampString }): void;
  isDisabled: boolean;
  withVaultTheme: boolean;
}) {
  // TODO: Derive this position from the currently playing video position
  const position = 0;

  const timestamp = useMemo(() => {
    return secondsToTimestamp(position);
  }, [position]);

  return (
    <Button
      label={timestamp}
      className={twMerge(
        'rounded-[100px] p-[4px_8px] font-base !text-base-s font-normal',
        withVaultTheme ? 'bg-vault_text/20 text-vault_text' : 'bg-base800 text-white',
      )}
      onClick={() => {
        onClick({ timestamp });
      }}
      disabled={isDisabled}
      trailingIcon={faPlusCircle}
    />
  );
}
