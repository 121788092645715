import { useEffect } from 'react';
import msFn from 'ms';
import { Navigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { isUUID4 } from '@soundxyz/utils/validation';
import {
  EarnedReceiptView,
  EarnedReceiptViewSkeleton,
} from '../../../components/campaign/earnedReceipt/EarnedReceiptView';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useQuery } from '../../../graphql/client';
import {
  ArtistByHandleDocument,
  ReleaseCampaignByIdOrSlugDocument,
} from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';

const fiveMinutes = msFn('5 minutes');

export const ClaimReceiptPage = () => {
  const { campaignSlugOrId } = useParams<{
    campaignSlugOrId: string;
  }>();

  const [searchParams] = useSearchParams();
  const step = searchParams.get('step');
  const source = searchParams.get('source');

  useVaultTheme();

  const { artistHandle } = useArtistHandle();
  const { loginStatus } = useAuthContext();

  const { setShowMenu } = useMenuContainer();

  const { data, isLoading, isError, refetch } = useQuery(ReleaseCampaignByIdOrSlugDocument, {
    staleTime: 0,
    variables: !!artistHandle &&
      !!campaignSlugOrId && {
        artistHandle: artistHandle.toLowerCase(),
        ...(isUUID4(campaignSlugOrId) ? { id: campaignSlugOrId } : { slug: campaignSlugOrId }),
      },
    filterQueryKey: {
      campaignSlugOrId,
      artistHandle,
    },
    // Refetch to always get latest campaign.currentState
    refetchInterval: fiveMinutes,
    select(data) {
      return data.data.releaseCampaignByIdOrSlug?.__typename === 'ReleaseCampaignPublicInfo'
        ? data.data.releaseCampaignByIdOrSlug
        : null;
    },
  });

  const {
    data: artistData,
    isLoading: isLoadingArtist,
    isError: isErrorArtist,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    select(data) {
      return data.data.artistLink;
    },
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (campaignSlugOrId == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || isErrorArtist) {
    return (
      <View className="flex h-full w-full items-center justify-center bg-vault_background">
        <ErrorView
          onRetryClick={refetch}
          loggingType="claim_campaign_page"
          withVaultTheme
          showBackButton
        />
      </View>
    );
  }

  if (isLoading || isLoadingArtist || loginStatus == LoginStatus.LOADING) {
    return <EarnedReceiptViewSkeleton />;
  }

  if (data == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  // Presave Campaign require a source prop
  if (data.currentState === 'PRESAVE' && source !== 'apple' && source !== 'spotify') {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <EarnedReceiptView
      campaignFrag={data}
      step={step}
      // Safe type casting cause we validate above
      sourceParam={source as 'apple' | 'spotify' | null}
      artistData={{
        id: artistData?.artist.id,
        membershipImage: artistData?.artist.membershipCardImage?.membershipCardImageUrl ?? null,
        linkValue: artistData?.artist.linkValue,
        name: artistData?.artist.name,
      }}
    />
  );
};
