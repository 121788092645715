import type { ReactNode } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faMessages } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCrown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { setHideAudioPlayer } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import {
  type FragmentType,
  getFragment,
  MessageChannelDetailsFragmentDoc,
} from '../../graphql/generated';
import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { BackButton } from '../buttons/BackButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';
import { VaultNav } from '../vault/VaultNav';
import { DefaultLayout } from './DefaultLayout';

gql(/* GraphQL */ `
  fragment MessageChannelLayoutInfo on MessageChannel {
    id
    vault {
      id
      contentCount
      artist: artistProfile {
        id
        name
      }
    }
  }
`);

/**
 *
 * @deprecated Please use NewMessageLayout instead
 *
 */
export const MessageChannelLayout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    artistName?: string;
    artistProfileImage?: string | null;

    secondaryFooter?: ReactNode;
    nonScrollingChildren?: ReactNode;
    stretch?: boolean;
    activeSubscriptionFeatures: TierFeatures;

    // VaultNav related props
    messageChannelId: string | undefined;
    hasChatReadAccess: boolean;
    chatAvailableForFreeUsers: boolean;

    childrenContainerClassName?: string;

    vaultId: string | undefined;
    artistHandle: string | undefined;
    isLockedChat?: boolean;

    artistId: string | undefined;
  }
>(
  (
    {
      children,
      artistName,
      secondaryFooter,
      nonScrollingChildren,
      stretch,
      activeSubscriptionFeatures,
      messageChannelId,
      hasChatReadAccess,
      chatAvailableForFreeUsers,
      childrenContainerClassName,
      vaultId,
      artistHandle,
      isLockedChat = false,
      artistId,
    },
    ref,
  ) => {
    const { isDesktop } = useWindow();

    const showVaultNav = !isDesktop;

    const { track, playing } = useAudioController();
    const { percentComplete } = useAudioPosition();

    useEffect(() => {
      if (!isDesktop) {
        setHideAudioPlayer(true);
      }

      return () => {
        if (!isDesktop) {
          setHideAudioPlayer(false);
        }
      };
    }, [isDesktop]);

    const onPlayClick = () => {
      track != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: track.id,
            percentComplete,
            artistId: track.vault.artist?.id,
            vaultId: track.vault.id,
            isPreview: !track.isFullVersionAvailable,
            component: 'message_channel_layout',
          },
        });

      togglePlayPause();
    };

    const strokeDashoffset = isSafari
      ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
      : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

    return (
      <DefaultLayout
        withVaultTheme
        showRoundedTop
        showBorder
        ref={ref}
        headerCenter={
          <HeaderCenter
            artistName={artistName}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            artistHandle={artistHandle}
            isGroupChat={false}
            messageChannelDetails={null}
            withBackButton={false}
            messageChannelId={undefined}
            isLockedChat={isLockedChat}
            isOwner={false}
            artistId={artistId}
          />
        }
        headerRight={
          track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  stroke="yellow"
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView isPlaying={playing} isDisabled={false} size={24} />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        isHeaderTransparent={false}
        shouldSkipMargin
        secondaryFooter={secondaryFooter}
        nonScrollingChildren={nonScrollingChildren}
        stretch={stretch}
        headerGridClassName="flex"
        headerLeftClassName="mr-4"
        headerCenterClassName="flex-1 justify-start"
        headerClassName={twMerge(
          'border-b border-t-0 border-solid border-b-base700 bg-base900_alpha90 pt-8 backdrop:blur-[50px] md2:border-b',
          'border-x-0 border-b border-white/5 md2:mt-1 md2:rounded-t-[20px] md2:border-y md2:bg-white/3',
          'bg-vault_background md2:bg-vault_text/3',
        )}
        withBottomNavigator={showVaultNav}
        messageChannelId={messageChannelId}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={chatAvailableForFreeUsers}
        vaultId={vaultId}
        childrenContainerClassName={childrenContainerClassName}
        contentClassName="md2:bg-vault_text/3"
      >
        {children}
      </DefaultLayout>
    );
  },
);

type Props = {
  children: ReactNode;
  artistName?: string;
  contentCount?: number;

  secondaryFooter?: ReactNode;
  nonScrollingChildren?: ReactNode;
  activeSubscriptionFeatures: TierFeatures;
  vaultId: string | undefined;
  artistId: string | undefined;
  messageChannelDetails: FragmentType<MessageChannelDetailsFragmentDoc> | null;
  messageChannelId: string | undefined;
  chatAvailableForFreeUsers: boolean;
  isGroupChat: boolean;
  artistHandle: string | undefined;
  showVaultNav: boolean;
  isLockedChat?: boolean;
  isOwner: boolean;
};

export const NewMessageLayout = ({
  secondaryFooter,
  children,
  artistName,
  nonScrollingChildren,
  activeSubscriptionFeatures,
  vaultId,
  messageChannelDetails,
  messageChannelId,
  chatAvailableForFreeUsers,
  isGroupChat,
  artistHandle,
  showVaultNav,
  isLockedChat = false,
  isOwner,
  artistId,
}: Props) => {
  const viewHeaderRef = useRef<HTMLDivElement>(null);

  const { isDesktop } = useWindow();

  const withVaultNav = showVaultNav && !isDesktop;

  const { track, playing } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const strokeDashoffset = isSafari
    ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
    : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const onPlayClick = () => {
    track != null &&
      trackEvent({
        type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
        properties: {
          trackId: track.id,
          percentComplete,
          artistId: track.vault.artist?.id,
          vaultId: track.vault.id,
          isPreview: !track.isFullVersionAvailable,
          component: 'message_channel_layout',
        },
      });

    togglePlayPause();
  };

  return (
    <View
      className={twMerge(
        'flex h-full w-full select-none flex-col items-center overflow-y-clip overscroll-none bg-vault_background text-vault_text',
        'md2:bg-transparent',
      )}
    >
      <ViewHeader
        ref={viewHeaderRef}
        center={
          <HeaderCenter
            artistId={artistId}
            artistName={artistName}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            isGroupChat={isGroupChat}
            messageChannelDetails={messageChannelDetails}
            artistHandle={artistHandle}
            withBackButton={!withVaultNav}
            messageChannelId={messageChannelId}
            isLockedChat={isLockedChat}
            isOwner={isOwner}
          />
        }
        right={
          !isDesktop && track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer select-none items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  className="stroke-vault_accent"
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView
                isPlaying={playing}
                isDisabled={false}
                size={24}
                className="text-vault_text"
              />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        className={twMerge(
          'select w-full border-b border-solid border-vault_text/5 bg-vault_background pt-6',
          'border-0 border-b md2:bg-transparent',
        )}
        gridClassName="flex md2:mx-[0px]"
        leftClassName="mr-4"
        centerClassName="flex-1 justify-start"
      />

      <View
        className={twMerge(
          'flex h-full w-full flex-col overflow-y-clip overscroll-y-none bg-vault_background',
          'mt-0 md2:bg-transparent',
        )}
      >
        {children}
        {nonScrollingChildren}
        <View className="z-above4 w-full">{secondaryFooter}</View>
      </View>

      {withVaultNav && (
        <View className="w-full md2:hidden">
          <VaultNav
            vaultId={vaultId}
            messageChannelId={messageChannelId}
            hasChatReadAccess={hasChatReadAccess}
            chatAvailableForFreeUsers={chatAvailableForFreeUsers}
            variant="borderless"
            withVaultTheme
          />
        </View>
      )}
    </View>
  );
};

function HeaderCenter({
  activeSubscriptionFeatures,
  artistHandle,
  artistName,
  messageChannelId,
  isGroupChat,
  messageChannelDetails,
  withBackButton,
  isLockedChat,
  isOwner,
  artistId,
}: {
  activeSubscriptionFeatures: TierFeatures | null;
  artistHandle: string | undefined;
  artistName: string | undefined;
  messageChannelId: string | undefined;
  isGroupChat: boolean;
  messageChannelDetails: FragmentType<MessageChannelDetailsFragmentDoc> | null;
  withBackButton: boolean;
  isLockedChat?: boolean;
  artistId: string | undefined;
  isOwner: boolean;
}) {
  const details = getFragment(MessageChannelDetailsFragmentDoc, messageChannelDetails);
  const titleText = details?.titleText ?? artistName;
  const coverImage = details?.coverImage;

  const userId = details?.singleRecipientActorId;

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const banStatusQueryEnabled = !isGroupChat && !!artistId && !!userId && isOwner;

  const { data: isBanned } = useSubscriberBanStatus({
    userId,
    artistId,
    enabled: banStatusQueryEnabled,
  });

  const onClick = () => {
    if (!artistHandle) return;
    if (isLockedChat) return;

    if (isGroupChat && activeSubscriptionFeatures?.enabledFeatures.ChatRead) {
      navigate(artistNavigationPath(artistHandle, '/messages/details'));
      return;
    }

    if (!isGroupChat && activeSubscriptionFeatures?.enabledFeatures.DMRead) {
      navigate(artistNavigationPath(artistHandle, `/messages/${messageChannelId}/details`));
      return;
    }

    navigate(artistNavigationPath(artistHandle, '/chat/details'));
  };

  return (
    <View
      className={twMerge(
        'flex min-w-0 cursor-pointer select-none flex-row items-center justify-start gap-3 md2:gap-0',
        (!activeSubscriptionFeatures?.enabledFeatures.ChatRead || isLockedChat) && 'cursor-default',
      )}
      containerRef={ref}
    >
      {withBackButton && (
        <BackButton
          className="-ml-4 flex-shrink-0 p-1 md2:hidden"
          withVaultTheme
          href={artistNavigationPath(artistHandle, '/messages')}
        />
      )}
      <button
        className="flex min-w-0 flex-1 select-none appearance-none flex-row items-center gap-2.5 border-none bg-transparent outline-none hover:cursor-pointer"
        onClick={onClick}
      >
        <div className="flex-shrink-0">
          {isGroupChat ? (
            <View className="flex h-9 w-9 items-center justify-center rounded-full bg-vault_text md2:h-10 md2:w-10">
              <FontAwesomeIcon icon={faMessages} className="text-[16px] text-vault_text_opposite" />
            </View>
          ) : (
            <ProfileImage
              profileImageUrl={coverImage?.imageSmallUrl || coverImage?.cdnUrl}
              className="h-9 w-9 rounded-full md2:h-10 md2:w-10"
              onClick={undefined}
            />
          )}
        </div>
        <div className="min-w-0 flex-1">
          {artistName != null || isGroupChat ? (
            <>
              <div className="flex min-w-0 items-center">
                <Text className="overflow-hidden text-ellipsis whitespace-nowrap text-left font-title !text-title-s font-normal text-vault_text md2:!text-title-m">
                  {isGroupChat ? 'Group chat' : titleText}
                </Text>
                <div className="ml-1 flex items-center gap-0.5">
                  {details?.showVerifiedBadge ? (
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      className="text-[14px] text-vault_accent"
                    />
                  ) : (
                    details?.subscriptionTierLevel === 'PaidTier' && (
                      <FontAwesomeIcon icon={faCrown} className="text-[14px] text-vault_accent" />
                    )
                  )}
                  {isOwner && isBanned && (
                    <View className="rounded-full bg-vault_text/10 px-2 py-[2px]">
                      <Text className="font-base text-[11px]/[14px] font-semibold text-destructive300">
                        Banned
                      </Text>
                    </View>
                  )}
                </div>
              </div>
              {!isLockedChat && (
                <View onClick={onClick} className="mt-1 flex items-center gap-1">
                  <Text
                    className={twMerge(
                      'font-base text-[12px]/[14px] font-normal md:text-[14px]/[16px]',
                      'text-vault_text/60 hover:text-vault_text/70',
                      ((isGroupChat && activeSubscriptionFeatures?.enabledFeatures.ChatRead) ||
                        (!isGroupChat && activeSubscriptionFeatures?.enabledFeatures.DMRead)) &&
                        'hover:text-vault_text/60',
                    )}
                  >
                    See details
                  </Text>
                </View>
              )}
            </>
          ) : (
            <>
              <LoadingSkeleton className="h-[22px] w-[100px] bg-vault_text/10" />
              <LoadingSkeleton className="mt-1 h-[14px] w-[60px] bg-vault_text/10" />
            </>
          )}
        </div>
      </button>
    </View>
  );
}
