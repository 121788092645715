import { useMemo } from 'react';
import { type DebouncedFunc, shuffle, throttle } from 'lodash-es';
import { proxy, subscribe } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { isUUID4 } from '@soundxyz/utils/validation';
import {
  AUDIO_ERROR_ACTIONS,
  AUDIO_INFO_ACTIONS,
  ERROR_TYPE,
  PILLARS,
} from '@soundxyz/vault-utils/constants';
import { fetchGQL, invalidateOperations, useQuery } from '../graphql/client';
import {
  ContentByIdDocument,
  NotifyPlayedContentDocument,
  VaultUpdateCountsDocument,
} from '../graphql/generated';
import { logErrorNoDefaultToast } from '../hooks/logger/useLogError';
import { logInfo } from '../hooks/logger/useLogInfo';
import { useOwnedArtist } from '../hooks/useOwnedArtist';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import {
  AudioEngineHTML5,
  audioEngineInstance,
  audioEngineSeek,
  audioEngineSeekBy,
  load,
  LoadListenerArguments,
  onEnd,
  onPause,
  onPlay,
  onTimeUpdate,
  pause,
  play,
  unload,
  useAudioEngineHTML5,
} from './AudioEngineHTML5';
import { queryAudioFromTrack } from './AudioFetcher';
import {
  AudioMeta,
  RepeatMode,
  setActiveFolderId,
  setActiveTrackId,
  setActiveVaultId,
  setHideAudioPlayer,
  setIsFullVersionAvailable,
  setLoadingActiveTrack,
  setRepeatMode,
  useAudioMeta,
} from './AudioMeta';
import { setPosition } from './AudioPosition';

import { AudioQueue, goToNextTrack, goToPrevTrack, useAudioQueue } from './AudioQueue';
import {
  setMetadata,
  setPauseActionHandler,
  setPlayActionHandler,
  setPlaybackState,
  setSeekActionHandler,
  setSeekBackwardActionHandler,
  setSeekForwardActionHandler,
} from './MediaSession';
import { PersistentAudio, usePersistentAudio } from './Persistence';
import {
  endActiveSession,
  startSession,
  userPaused,
  userReportCurrentSession,
  userResumedListening,
} from './playSession';

const pillar = PILLARS.AUDIO;
export const heardVaults = proxy({
  vaultIds: new Set<string>(),
});

export const PlayerType = proxy<{ current: 'spotify' | 'vault' | 'appleMusic' | null }>({
  current: null,
});

const refetchVaultUpdateCount = () => {
  invalidateOperations({
    operations: [VaultUpdateCountsDocument],
  });
};

export const throttledRefetchVaultUpdateCount: DebouncedFunc<typeof refetchVaultUpdateCount> =
  throttle(refetchVaultUpdateCount, 5000, {
    leading: true,
    trailing: true,
  });

const onLoopSkippedSongs = new Set<string>();

/**
 * Playing last track when repeat all is set should add the existing up next tracks to the queue
 */
let prevIsLastTrackActive = false;
let prevIsRepeatAllActive = false;
subscribe(proxy({ audioQueue: AudioQueue, audioMeta: AudioMeta }), () => {
  const currentIsLastTrackActive = isLastTrackActive();
  const currentIsRepeatAllActive = AudioMeta.repeatMode === RepeatMode.REPEAT_ALL;

  if (
    (!prevIsLastTrackActive && currentIsLastTrackActive && currentIsRepeatAllActive) ||
    (!prevIsRepeatAllActive && currentIsRepeatAllActive && currentIsLastTrackActive)
  ) {
    appendUpNextTrackIds();
  }

  prevIsLastTrackActive = currentIsLastTrackActive;
  prevIsRepeatAllActive = currentIsRepeatAllActive;
});

/**
 * Fetches audio info for a given track, prepares audio engine with the track, and sets state accordingly
 */
export const loadTrack = async ({
  autoplay = true,
  trackId,
  vaultId,
  audioSrc,
  component,
  folderId,
}: {
  autoplay?: boolean;
  trackId: string | null;
  vaultId: string | null;
  folderId: string | null;
  audioSrc?: string | null;
  addToQueue?: boolean;
  pathname?: string;
  component?:
    | 'message_attachment'
    | 'artist_page'
    | 'message_channel_layout'
    | 'minimized_message_attachment'
    | 'upload_view'
    | 'waveform'
    | 'content_comments'
    | 'content_landing_page'
    | 'track_landing_page';
}) => {
  PlayerType.current = 'vault';
  // If track is already playing, do nothing
  if (!audioSrc && AudioMeta.activeTrackId && trackId === AudioMeta.activeTrackId) {
    return;
  }

  const hasInitialActiveTrack = !!AudioMeta.activeTrackId;

  setLoadingActiveTrack(true);
  setHideAudioPlayer(false);
  let audioInfo: Awaited<ReturnType<typeof queryAudioFromTrack>> = null;
  let src: string | undefined | null;

  if (audioSrc) {
    src = audioSrc;
    setActiveTrackId(null);
  } else if (
    trackId &&
    isUUID4(trackId) &&
    /**
     * If the track is on the set of temporarily skipped songs, we should stop the loop
     */
    !onLoopSkippedSongs.has(trackId)
  ) {
    // Play as soon as possible on first load so mobile recognizes the play command before load is finished
    if (autoplay && !hasInitialActiveTrack) {
      play();
    }

    try {
      logInfo({
        action: AUDIO_INFO_ACTIONS.AUDIO_FETCH_DATA,
        message: 'Fetching audio data for track',
        pillar,
        data: {
          trackId,
          autoplay,
        },
      });
      audioInfo = await queryAudioFromTrack({ trackId });
    } catch (err) {
      logErrorNoDefaultToast({
        action: AUDIO_ERROR_ACTIONS.AUDIO_FETCH_ERROR,
        error: err,
        level: 'warning',
        message: 'Error fetching track audio data',
        errorType: ERROR_TYPE.MISSING_AUDIO_DATA,
        pillar,
        indexedTags: {
          trackId,
        },
      });

      toNextTrack(autoplay);
      setLoadingActiveTrack(false);
      return;
    }

    if (!audioInfo) {
      /**
       * If audio is not playable, skip the track and add it to the set of skipped songs to prevent infinite looping
       */
      onLoopSkippedSongs.add(trackId);

      toNextTrack(autoplay);
      setLoadingActiveTrack(false);
      return;
    } else {
      /**
       * If any track was found playable, clear the set of skipped songs
       */
      onLoopSkippedSongs.clear();
    }

    const {
      cdnUrl,
      id,
      vault: { id: vaultId, artist },
      title,
    } = audioInfo;

    LoadListenerArguments.artistId = artist?.id ?? null;
    LoadListenerArguments.trackId = id;
    LoadListenerArguments.vaultId = vaultId;
    LoadListenerArguments.title = title;

    trackEvent({
      type: EVENTS.PLAY_TRACK,
      properties: {
        trackId: id,
        vaultId,
        artistId: artist?.id,
        loadedTrack: true,
        percentComplete: 0,
        component,
        title,
        isPreview: !audioInfo.isFullVersionAvailable,
      },
    });

    src = cdnUrl;
  }

  if (src) {
    load({ src, autoplay });
    if (autoplay && (hasInitialActiveTrack || audioSrc)) play();

    // Track the current play session and related stats
    endActiveSession();
    if (trackId) {
      startSession({
        startTime: Date.now(),
        trackID: trackId,
        sessionId: uuidv4(),
        isPreview: !audioInfo?.isFullVersionAvailable,
      });

      setActiveTrackId(trackId);

      if (vaultId) {
        setActiveVaultId(vaultId);
      }

      if (folderId) {
        setActiveFolderId(folderId);
      }
    }
  } else {
    pause();
  }
  setIsFullVersionAvailable(audioInfo?.isFullVersionAvailable || !!audioSrc);
  setLoadingActiveTrack(false);
};

/**
 * Removes existing track data and sets playback back at last active position
 */
export const resetActiveTrack = () => {
  const lastActiveTrackId = AudioMeta.activeTrackId;

  setActiveTrackId(null);
  unload();

  loadTrack({
    trackId: lastActiveTrackId,
    addToQueue: false,
    autoplay: false,
    vaultId: null,
    folderId: null,
  });
};

/**
 *  Loads and starts playing the active track
 */
export const loadActiveTrack = (autoplay = true) => {
  if (!AudioMeta.activeTrackId) return;

  loadTrack({
    trackId: AudioMeta.activeTrackId,
    addToQueue: false,
    autoplay,
    vaultId: null,
    folderId: null,
  });
};

/**
 * Called to go to the previous track
 */
export const toPrevTrack = () => {
  if (
    AudioMeta.activeTrackId === AudioQueue.playbackTrackIds[0] ||
    !AudioQueue.playbackTrackIds.length
  ) {
    seek(0);
    return;
  }

  goToPrevTrack();
  loadActiveTrack();
};

/**
 * Called to go to the next track
 */
export const toNextTrack = (autoplay = true) => {
  goToNextTrack();
  loadActiveTrack(autoplay);

  if (AudioMeta.repeatMode === RepeatMode.REPEAT_ONE) {
    setRepeatMode(RepeatMode.REPEAT_ALL);
  }
};

/**
 * Seek the audio to the given time in `seconds`
 */
export const seek = (value: number) => {
  audioEngineSeek(value);
  setPosition(audioEngineInstance.position);
};

/**
 * Seek the audio by the given time in `seconds`
 */
export const seekBy = (value: number) => {
  audioEngineSeekBy(value);
  setPosition(audioEngineInstance.position);
};

/**
 * Helper to determine if the last track in the queue is the currently active one
 */
const isLastTrackActive = () =>
  AudioMeta.activeTrackId === AudioQueue.playbackTrackIds[AudioQueue.playbackTrackIds.length - 1];

export const cycleRepeatMode = () => {
  let newRepeatMode;
  switch (AudioMeta.repeatMode) {
    case RepeatMode.NO_REPEAT:
      newRepeatMode = RepeatMode.REPEAT_ALL;
      break;
    case RepeatMode.REPEAT_ALL:
      newRepeatMode = RepeatMode.REPEAT_ONE;
      break;
    case RepeatMode.REPEAT_ONE:
    default:
      newRepeatMode = RepeatMode.NO_REPEAT;
      //resets the playbackTrackIds to the original order
      if (AudioQueue.playbackTrackIds.length > AudioQueue.vaultTrackIds.length) {
        AudioQueue.playbackTrackIds = AudioQueue.vaultTrackIds;
      }
      break;
  }
  setRepeatMode(newRepeatMode);
};

export const appendUpNextTrackIds = () => {
  const trackIdsToAppend = AudioQueue.shuffleEnabled
    ? shuffle(AudioQueue.vaultTrackIds)
    : AudioQueue.vaultTrackIds;

  AudioQueue.playbackTrackIds = [...AudioQueue.playbackTrackIds, ...trackIdsToAppend];
};

/**
 * Used in React components to read data about the current state of audio
 */
export const useAudioController = () => {
  const {
    activeTrackId,
    repeatMode,
    loadingActiveTrack,
    hideAudioPlayer,
    disableNextPrev,
    playbackId,
    hideExpandedPlayer,
    activeVaultId,
    isFullVersionAvailable,
    activeFolderId,
  } = useAudioMeta();

  const { loading, muted, playing, ready, volume, duration } = useAudioEngineHTML5();
  const { playbackTrackIds, shuffleEnabled, queueLength } = useAudioQueue();
  const { isReady } = usePersistentAudio();

  const activeTrackIndex = useMemo(
    () => (activeTrackId ? playbackTrackIds.indexOf(activeTrackId) ?? 0 : 0),
    [activeTrackId, playbackTrackIds],
  );
  const queueTrackIds = useMemo(
    () =>
      queueLength === 0
        ? []
        : playbackTrackIds.slice(activeTrackIndex + 1, activeTrackIndex + queueLength + 1),
    [activeTrackIndex, playbackTrackIds, queueLength],
  );
  const upNextTrackIds = useMemo(
    () => playbackTrackIds.slice(activeTrackIndex + queueLength + 1),
    [activeTrackIndex, playbackTrackIds, queueLength],
  );

  const ownedArtist = useOwnedArtist({ artistId: activeVaultId });

  const track = useQuery(ContentByIdDocument, {
    variables: !!activeTrackId &&
      isUUID4(activeTrackId) && {
        vaultContentId: activeTrackId,
        asArtistId: ownedArtist?.id,
      },
    staleTime: 5000,
    select(data) {
      if (data.data.vaultContentById?.__typename !== 'VaultTrack') return null;

      return data.data.vaultContentById;
    },
  }).data;

  return {
    activeTrackId,
    activeTrackIndex,
    disableNextPrev,
    duration,
    hideAudioPlayer,
    hideExpandedPlayer,
    isNextTrackDisabled: isLastTrackActive() && repeatMode === RepeatMode.NO_REPEAT,
    isReady,
    loading,
    loadingActiveTrack,
    muted,
    playbackId,
    playbackTrackIds,
    playing,
    queueTrackIds,
    ready,
    repeatMode,
    shuffleEnabled,
    upNextTrackIds,
    volume,
    track,
    activeVaultId,
    activeFolderId,
    isFullVersionAvailable,
  };
};

if (typeof window !== 'undefined') {
  /**
   * Go to next track when current one ends depending on repeat mode
   */
  onEnd(
    () => {
      if (
        !AudioMeta.playNextTrackOnEnd ||
        (isLastTrackActive() && AudioMeta.repeatMode === RepeatMode.NO_REPEAT)
      ) {
        seek(0);
        pause();
        setHideAudioPlayer(true);
        return;
      }

      if (AudioMeta.repeatMode === RepeatMode.REPEAT_ONE) {
        seek(0);
        play({ forcePlay: true });
        return;
      }

      toNextTrack();
    },
    { once: false },
  );

  /**
   * Load active track from persistent client side storage
   */
  subscribeKey(PersistentAudio, 'isReady', () => {
    // If a track is already playing, avoid loading the persisted track
    if (!PersistentAudio.isReady || !!AudioMeta.activeTrackId || !!AudioEngineHTML5.playing) return;

    loadTrack({
      trackId: AudioMeta.activeTrackId,
      autoplay: false,
      addToQueue: false,
      vaultId: null,
      folderId: null,
    });

    // Sync saved engine values with audio instance
    if (AudioEngineHTML5.instance) {
      AudioEngineHTML5.instance.muted = AudioEngineHTML5.muted;
      AudioEngineHTML5.instance.volume = AudioEngineHTML5.volume;
    }
  });

  /**
   * Update play stats when users pause the track and fires at 10 seconds
   */
  let offPlay: () => void | undefined;
  let offPause: () => void | undefined;
  let offTimeUpdate: () => void | undefined;
  subscribeKey(AudioMeta, 'activeTrackId', () => {
    if (offPlay) offPlay();
    if (offPause) offPause();
    if (offTimeUpdate) offTimeUpdate();
    let hasPaused = false;
    let hasFiredAt30Sec = false;

    if (AudioMeta.activeTrackId) {
      offPause = onPause(
        () => {
          userPaused();
          hasPaused = true;
        },
        { once: false },
      );
      offPlay = onPlay(
        () => {
          if (hasPaused) {
            userResumedListening();
          }
        },
        { once: false },
      );
      offTimeUpdate = onTimeUpdate(
        () => {
          if (!hasFiredAt30Sec) {
            hasFiredAt30Sec = userReportCurrentSession();
          }
        },
        { once: false },
      );
    }
  });

  /**
   * Attach media session handlers
   */
  setPlayActionHandler(play);
  setPauseActionHandler(pause);
  setSeekActionHandler(seek);
  setSeekBackwardActionHandler(seekBy);
  setSeekForwardActionHandler(seekBy);

  /**
   * Update media session playback state to sync with audio player
   */
  subscribeKey(AudioEngineHTML5, 'playing', async () => {
    setPlaybackState(AudioEngineHTML5.playing);
  });

  gql(/* GraphQL */ `
    mutation NotifyPlayedContent($input: MutationUpsertUserViewContentInput!) {
      upsertUserViewContent(input: $input) {
        id
      }
    }
  `);

  /**
   * Update MediaSession meta data when songs change
   */
  subscribeKey(AudioMeta, 'activeTrackId', async () => {
    if (!AudioMeta.activeTrackId || !isUUID4(AudioMeta.activeTrackId)) return;

    const songInfo = await queryAudioFromTrack({
      trackId: AudioMeta.activeTrackId,
    });

    const coverImageUrl = songInfo?.vault.artist?.profileImage?.artistSmallProfileImageUrl;
    const artistName = songInfo?.vault.artist?.name ?? 'Unnamed Artist';
    const songTitle = songInfo?.title ?? 'Unnamed Song';

    if (songInfo?.vault.artist) {
      fetchGQL(NotifyPlayedContentDocument, {
        variables: {
          input: {
            vaultContentId: songInfo.id,
          },
        },
        keepalive: true,
      })
        .then(() => {
          heardVaults.vaultIds.add(songInfo.id);
          throttledRefetchVaultUpdateCount();
        })
        .catch(error =>
          logErrorNoDefaultToast({
            error,
            level: 'warning',
            errorType: ERROR_TYPE.PLAYBACK_ERROR,
            message: 'Failed to notify played content',
            unindexedExtra: {
              songInfo,
            },
            action: AUDIO_ERROR_ACTIONS.AUDIO_SUBSCRIPTION_ERROR,
            pillar,
          }),
        );
    }

    // Devices check for compatibility and set the current metadata for the media session.
    // Source: https://developer.mozilla.org/en-US/docs/Web/API/MediaMetadata/artwork
    setMetadata({
      title: songTitle,
      artist: artistName,
      artwork: coverImageUrl
        ? [
            // Hint the user agent about the type of image being used. In theory, this image could be jpeg, jpg or png.
            // We use jpeg because it is the most common image type used for album art, and it avoids some devices from
            // skipping this type and not showing an image at all
            // MDN: The MIME type hint for the user agent that allows it to ignore images of types
            // that it doesn't support. However, the user agent may still use MIME type sniffing
            // after downloading the image to determine its type.
            // Source: https://developer.mozilla.org/en-US/docs/Web/API/MediaImage
            { src: coverImageUrl, type: 'image/jpeg' },
          ]
        : undefined,
    });
  });
}
