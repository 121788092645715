import { useSignIn } from '../../hooks/useSignIn';
import { EmailAuthInput } from './EmailAuthInput';
import { PhoneAuthInput } from './PhoneAuthInput';

export function AuthInput({ withVaultTheme = false }: { withVaultTheme?: boolean }) {
  const { authMethod } = useSignIn();

  return authMethod === 'sms' ? (
    <PhoneAuthInput useVaultTheme={withVaultTheme} />
  ) : (
    <EmailAuthInput useVaultTheme={withVaultTheme} />
  );
}
