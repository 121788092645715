import { type FC, type ReactNode, useRef, useState } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Content, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useEscapeKeyListener } from '../../hooks/useEscapeKeyListener';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { View } from './View';

export type DropdownAlign = 'start' | 'center' | 'end';

type Props = {
  onOpenChange?: (open: boolean) => void;
  trigger?: ReactNode; //Must be a ForwardRef component or wrapped in one
  disabled?: boolean;
  align?: DropdownAlign;
  sideOffset?: number;
  children: ReactNode;
  className?: string;
  collisionPadding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  onClose?: () => void;
};

export const Dropdown: FC<Props> = ({
  onOpenChange,
  trigger,
  disabled,
  align,
  children,
  className,
  sideOffset,
  collisionPadding,
  onClose,
}) => {
  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();
  const isScrolling = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = useStableCallback(() => {
    setIsOpen(false);
    onClose?.();
  });
  useEscapeKeyListener(isOpen, closeDropdown);

  return (
    <div
      onPointerDownCapture={event => {
        // prevent opening dropdown when scrolling on touch devices
        if (event.pointerType === 'touch' && !isOpen) event.preventDefault();
      }}
      onTouchMove={() => {
        isScrolling.current = true;
      }}
      onTouchEnd={() => {
        if (!isScrolling.current && !isOpen) setIsOpen(true);
        isScrolling.current = false;
      }}
      onClick={e => {
        //prevent events on the parent element of the dropdown to get executed instead of the dropdown elements events
        e.stopPropagation();
      }}
    >
      <Root
        open={isOpen}
        onOpenChange={open => {
          setIsOpen(open);
          onOpenChange?.(open);
        }}
      >
        <Trigger disabled={disabled} asChild>
          {trigger}
        </Trigger>

        <Content
          align={align}
          sideOffset={sideOffset}
          className={twMerge(
            'z-dropdown overflow-clip rounded-xl shadow-tertiary shadow-black',
            className,
          )}
          collisionPadding={
            collisionPadding ?? {
              bottom: 72 + (isBottomAudioPlayerOpen ? 87 : 0),
              right: 16,
              left: 16,
              top: 16,
            }
          }
          onClick={closeDropdown}
        >
          {children}
        </Content>
      </Root>
    </div>
  );
};

export const ActionDropdown: FC<ActionBottomsheetProps> = ({
  buttons,
  withVaultTheme,
  className,
}) => {
  const { openBottomsheet } = useBottomsheetContainer();

  return (
    <View
      className={twMerge(
        'flex w-full flex-col gap-y-[2px] overflow-hidden rounded-xl border border-solid border-vault_text/10',
        withVaultTheme ? 'bg-vault_background backdrop-blur-2xl' : 'bg-black',
        className,
      )}
    >
      {buttons.map((button, index) => (
        <Button
          {...button}
          className={twMerge('rounded-none', button.className)}
          key={button.key ?? index}
          onClick={
            button.onClick != null && button.requireConfirmation
              ? () =>
                  openBottomsheet({
                    type: 'CONFIRMATION',
                    confirmationBottomsheetProps: {
                      onConfirm:
                        button.onClick != null
                          ? e => {
                              button.event != null &&
                                trackEvent({
                                  type: button.event.type,
                                  properties: button.event.properties,
                                });

                              button.onClick?.(e);
                            }
                          : () => null,
                      subText: button.confirmationSubText,
                      confirmType: button.confirmType,
                    },
                  })
              : button.onClick
          }
          event={
            button.onClick != null && button.requireConfirmation
              ? {
                  type: EVENTS.OPEN_BOTTOMSHEET,
                  properties: {
                    bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION,
                    event: button.event?.type,
                  },
                }
              : button.event
          }
        />
      ))}
    </View>
  );
};

export const DropdownEllipsis: FC<{
  desktopClassname?: string;
  onClick: (() => void) | null; // NOTE: Only applies on mobile view
  buttons: ActionBottomsheetProps['buttons'];
  dropdownType: string;
  sharedClassname?: string;
  dropdownClassName?: string;
  withVaultTheme?: boolean;
  disabled?: boolean;
  loading?: boolean;
  sideOffset?: number;
}> = ({
  onClick,
  disabled,
  loading,
  buttons,
  dropdownType,
  desktopClassname,
  sharedClassname,
  withVaultTheme,
  dropdownClassName,
  sideOffset,
}) => {
  return (
    <ResponsiveDropdown
      desktopClassName={clsx(sharedClassname, desktopClassname)}
      onClick={onClick}
      buttons={buttons}
      dropdownType={dropdownType}
      label=""
      trailingIcon={faEllipsis}
      desktopIconOnly
      mobileIconOnly
      mobileClassName={sharedClassname}
      withVaultTheme={withVaultTheme}
      disabled={disabled}
      loading={loading}
      dropdownClassName={dropdownClassName}
      sideOffset={sideOffset}
    />
  );
};

export const ResponsiveDropdown: FC<{
  onClick: (() => void) | null;
  buttons: ActionBottomsheetProps['buttons'];
  dropdownType: string;
  align?: DropdownAlign;

  withVaultTheme?: boolean;

  label: string;
  trailingIcon?: IconProp;
  leadingIcon?: IconProp;
  buttonPosition?: 'individual' | 'top' | 'middle' | 'bottom';

  desktopButtonType?: 'primary' | 'secondary' | 'default';
  mobileButtonType?: 'primary' | 'secondary' | 'default';
  desktopIconOnly?: boolean;
  mobileIconOnly?: boolean;
  desktopClassName?: string;
  mobileClassName?: string;
  dropdownClassName?: string;

  loading?: boolean;
  disabled?: boolean;
  sideOffset?: number;
}> = ({
  onClick,
  buttons,
  dropdownType,
  align,

  withVaultTheme,

  label,
  trailingIcon,
  leadingIcon,
  buttonPosition,

  desktopButtonType,
  mobileButtonType,
  desktopIconOnly,
  mobileIconOnly,
  desktopClassName,
  mobileClassName,
  dropdownClassName,

  disabled,
  loading,
  sideOffset,
}) => {
  const { isDesktop } = useWindow();

  if (!isDesktop && onClick != null) {
    return (
      <Button
        iconOnly={mobileIconOnly}
        type={mobileButtonType}
        label={label}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        position={buttonPosition}
        className={twMerge(
          'text-[16px] text-white',
          withVaultTheme && 'text-vault_text',
          mobileClassName,
        )}
        onClick={onClick}
        event={{
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.ACTION,
            buttons: buttons.length,
            type: dropdownType,
          },
        }}
        disabled={disabled}
        loading={loading}
      />
    );
  }

  return (
    <Dropdown
      align={align}
      disabled={disabled}
      className={dropdownClassName}
      sideOffset={sideOffset}
      trigger={
        <div>
          <Button
            iconOnly={desktopIconOnly}
            type={desktopButtonType}
            label={label}
            leadingIcon={leadingIcon}
            trailingIcon={trailingIcon}
            position={buttonPosition}
            className={twMerge(
              'text-[16px] text-white',
              withVaultTheme && 'text-vault_text',
              desktopClassName,
            )}
            event={{
              type: EVENTS.OPEN_BOTTOMSHEET,
              properties: {
                bottomsheetType: BOTTOMSHEET_TYPES.ACTION,
                buttons: buttons.length,
                type: dropdownType,
              },
            }}
            disabled={disabled}
            loading={loading}
          />
        </div>
      }
    >
      <ActionDropdown buttons={buttons} withVaultTheme={!!withVaultTheme} />
    </Dropdown>
  );
};
