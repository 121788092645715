import { useEffect } from 'react';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import type { ShareContentBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { ShareContentView } from '../vault/share/ShareContentView';

export function ShareContentBottomsheet({ linkValue, artistHandle }: ShareContentBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const ownedArtist = useOwnedArtist({ artistHandle });

  useEffect(() => {
    if (ownedArtist == null) {
      closeBottomsheet();
    }
  }, [ownedArtist, closeBottomsheet]);

  return (
    <View className="min-h-[470px] pb-5">
      <ShareContentView
        linkValue={linkValue}
        artistHandle={artistHandle}
        artistName={ownedArtist?.name ?? ''}
      />
    </View>
  );
}
