import type { ReactNode } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { twMerge } from 'tailwind-merge';
import type { LongPressPointerHandlers } from 'use-long-press';
import { faEllipsisH, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useMediaQuery } from '../../../../contexts/mediaQuery';
import { useEscapeKeyListener } from '../../../../hooks/useEscapeKeyListener';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { SelectButton } from '../../../common/SelectButton';
import { View } from '../../../common/View';

export type ContentOption = {
  title: string;
  icon: IconDefinition;
  onClick: () => void;
};

export function ContentOptions({
  isOpen,
  setIsOpen,
  onLongPress,
  isSelecting,
  onClick,
  selectedFile,
  menuHovered,
  setMenuHovered,
  buttons,
  triggerItem,
  disabled,
  disableHover,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setMenuHovered: (value: boolean) => void;
  onLongPress: LongPressPointerHandlers<Element>;
  isSelecting: boolean;
  onClick: () => void;
  selectedFile: boolean;
  menuHovered: boolean;
  buttons: ContentOption[];
  triggerItem: ReactNode;
  disabled: boolean;
  disableHover: boolean;
}) {
  const { md2 } = useMediaQuery();
  const closePopover = useStableCallback(() => setIsOpen(false));
  useEscapeKeyListener(isOpen, closePopover);

  return (
    <Popover.Root open={isOpen}>
      {isOpen && (
        <View
          className="absolute left-0 top-0 z-overlay h-full w-full animate-fadeIn select-none bg-vault_background/50 md2:hidden"
          onTouchMove={e => {
            e?.preventDefault();
            setIsOpen(false);
          }}
        />
      )}
      <View className="relative h-full w-full flex-grow select-none">
        <Popover.Trigger
          {...onLongPress}
          onContextMenu={e => {
            e.preventDefault();
            if (isSelecting || md2) return;
            setIsOpen(true);
          }}
          className="relative w-full select-none appearance-none border-none bg-transparent outline-none"
          onClick={onClick}
          disabled={disabled}
        >
          {triggerItem}
          {isSelecting && (
            <SelectButton
              isSelected={selectedFile}
              hideCheck
              className="absolute right-3 top-2 z-above2 h-2 w-2 cursor-pointer border-vault_text/40"
            />
          )}
        </Popover.Trigger>
        {!isSelecting && (
          <div
            className="absolute right-0 top-[114px] z-above2 flex aspect-square h-5 w-5 flex-shrink-0 select-none appearance-none items-center justify-center rounded-full border-none bg-transparent outline-none hover:bg-vault_text/10"
            onMouseEnter={() => {
              if (disableHover) return;

              setIsOpen(true);
            }}
            onMouseLeave={() => {
              if (menuHovered || disableHover) return;
              setTimeout(() => {
                setIsOpen(false);
              }, 2000);
            }}
            onClick={() => {
              if (disabled) return;
              if (disableHover) {
                setIsOpen(!isOpen);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              className="cursor-pointer select-none !text-base-m text-vault_text"
            />
          </div>
        )}
      </View>

      <Popover.Portal>
        <Popover.Content
          side={md2 ? 'right' : 'bottom'}
          avoidCollisions
          sideOffset={4}
          onClick={() => {
            setIsOpen(false);
          }}
          onInteractOutside={() => {
            if (md2 && !disableHover) return;
            setIsOpen(false);
          }}
          align="center"
          className="z-overlay flex w-56 select-none flex-col rounded-lg bg-vault_text/10 p-1 backdrop-blur-2xl will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:p-0"
          onMouseLeave={() => {
            if (md2 && !disableHover) return;
            if (!disableHover) setIsOpen(false);
            setMenuHovered(false);
          }}
          onMouseEnter={() => {
            if (!md2) return;
            setMenuHovered(true);
          }}
        >
          {buttons.map((button, index) => {
            const isLast = index === buttons.length - 1;
            return (
              <React.Fragment key={`${button.title}-${index}`}>
                <button
                  onClick={button.onClick}
                  className={twMerge(
                    'flex h-11 w-full appearance-none items-center justify-between border-x-0 border-b-[0.5px] border-t-0 px-4 outline-none first:rounded-t-lg md2:border-b-[1px]',
                    'cursor-pointer border-b-vault_text/5 bg-transparent font-base text-[14px]/[18px] text-vault_text hover:bg-vault_text/20',
                    isLast ? 'rounded-b-lg border-b-0' : '',
                  )}
                >
                  {button.title}
                  <FontAwesomeIcon icon={button.icon} className="text-[16px]" />
                </button>
              </React.Fragment>
            );
          })}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
