import { type ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { View } from './View';

export function PaginatedView({
  children,
  hasNextPage,
  fetchNextPage,
  className,
  isFetchingNextPage,
}: {
  children: ReactNode;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  className: string | undefined;
  isFetchingNextPage: boolean;
}) {
  const [bottomRef, isAtBottom] = useInView();

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isAtBottom, fetchNextPage, isFetchingNextPage]);

  return (
    <View className={className}>
      {children}
      <div className="min-h-px" ref={bottomRef} />
    </View>
  );
}
