import type { LegacyRef, RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useSnapshot } from 'valtio';
import { faVideo } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faImage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { COLOR } from '../../constants/colorConstants';
import type { VaultContentType } from '../../graphql/generated';
import { useVaultTheme, VaultThemeStore } from '../../hooks/useVaultTheme';
import { Label, Subtext } from '../../tamagui-components/elements/TextElements';
import { getDurationAsTime } from '../../utils/dateUtils';
import { formatDateString } from '../../utils/textUtils';
import { View } from '../common/View';
import { SmallPlayIndicatorBars } from '../track/PlayIndicatorBars';
import { Preview } from './Preview';

const getContentIndicator = ({
  type,
  isPlaying,
  contentId,
  textColor,
}: {
  type: Exclude<VaultContentType, 'FOLDER'>;
  isPlaying?: boolean;
  contentId: string;
  textColor: string;
}) => {
  switch (type) {
    case 'TRACK':
      return (
        <SmallPlayIndicatorBars
          key={`${contentId}-${isPlaying}`}
          isPaused={!isPlaying}
          sharedBarClassName={isPlaying ? 'bg-vault_accent' : 'bg-vault_text opacity-[0.98]'}
          withVaultTheme
        />
      );
    case 'IMAGE':
      return <FontAwesomeIcon icon={faImage} fontSize={16} color={textColor} />;
    case 'VIDEO':
      return <FontAwesomeIcon icon={faVideo} fontSize={16} color={textColor} />;
  }
};

export const VaultContent = ({
  type,
  title,
  media,
  blurredMedia,
  createdAt,
  duration,
  locked = false,
  isNew = false,
  isLoading = false,
  subText,
  isPlaying = false,
  contentId,
  isSnippet,
  containerRef,
  hasEllipsis = false,
  videoRef,
  onClick,
  thumbnailUrl = null,
}: {
  type: VaultContentType;
  title: string;
  media: string | null | undefined;
  blurredMedia?: string | null | undefined;
  createdAt: string;
  duration?: number;
  locked?: boolean;
  isNew?: boolean;
  isLoading?: boolean;
  subText?: string;
  isPlaying?: boolean;
  contentId: string;
  isSnippet: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  hasEllipsis?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
  onClick?: () => void;
  thumbnailUrl?: string | null;
}) => {
  useVaultTheme();
  const vaultTheme = useSnapshot(VaultThemeStore);
  const textColor = vaultTheme.textColor ?? COLOR.base_text;

  const createdAtText = formatDateString({ date: createdAt, format: 'numerical_month_day_year' });
  const durationText = !!duration ? getDurationAsTime(duration) : '';

  const getSubtext = () => {
    if (type === 'FOLDER') {
      return !!subText ? `${createdAtText} · ${subText}` : createdAtText;
    }

    return !!durationText ? `${createdAtText} · ${durationText}` : createdAtText;
  };

  return (
    <View
      className="w-full flex-1 cursor-pointer gap-3"
      ref={containerRef}
      onClick={() => {
        if (isLoading) return;
        onClick?.();
      }}
    >
      <div className=" flex flex-shrink flex-col gap-2">
        <div className="relative flex h-[108px] w-full flex-shrink items-center justify-center rounded-md bg-vault_text/10">
          <AnimatePresence>
            {isNew && (
              <motion.div
                className="absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-vault_accent"
                exit={{ opacity: 0 }}
              >
                New
              </motion.div>
            )}
          </AnimatePresence>

          <Preview
            title={title}
            media={media}
            blurredMedia={blurredMedia}
            type={type}
            locked={locked}
            color={textColor}
            isSnippet={isSnippet}
            themeMode={vaultTheme.mode}
            videoRef={videoRef}
            thumbnailUrl={thumbnailUrl}
            size="md"
          />

          {type !== 'FOLDER' && (
            <div className="absolute bottom-2 left-2">
              {getContentIndicator({
                type,
                isPlaying,
                contentId,
                textColor,
              })}
            </div>
          )}
        </div>

        <div className="flex flex-col items-start gap-1">
          <div className="flex w-full flex-row items-start justify-between gap-2 text-start">
            <Label label={title} color={textColor} numberOfLines={2} />
            {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
          </div>

          <Subtext text={getSubtext()} color={textColor} size="sm" />
        </div>
      </div>
    </View>
  );
};
