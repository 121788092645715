import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Navigate } from 'react-router';

import * as z from 'zod';
import { faEnvelope, faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { HookAutoSave } from '../../../components/forms/hook-form/Autosave';
import { HookToggle } from '../../../components/forms/hook-form/HookToggle';
import { SettingsLayout } from '../../../components/layouts/SettingsLayout';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { setQueryData } from '../../../graphql/client';
import {
  type FragmentType,
  getFragment,
  UserNotificationSettingsDocument,
  UserNotificationSettingsFragmentDoc,
} from '../../../graphql/generated';
import { useNotificationSettings } from '../../../hooks/useNotificationSettings';
import { LoginStatus } from '../../../types/authTypes';

export const SmsNotificationSettingsPage: FC = () => {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <SettingsLoader />;
};

const SettingsLoader = () => {
  const { isEmailVerified } = useAuthContext();
  const { data, isLoading } = useNotificationSettings();

  if (isLoading || data?.currentUser.__typename !== 'QueryCurrentUserSuccess') {
    return null;
  }

  const settings = data?.currentUser.data;
  return (
    <SettingsLayout title={`${isEmailVerified ? 'Email' : 'SMS'} notifications`}>
      <SettingsForm notificationSettings={settings} />
    </SettingsLayout>
  );
};

const validationSchema = z.object({
  isSmsEnabled: z.boolean(),
  newArtistTrack: z.boolean(),
  newSubscriber: z.boolean(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const SettingsForm = ({
  notificationSettings,
}: {
  notificationSettings: FragmentType<UserNotificationSettingsFragmentDoc>;
}) => {
  const { isEmailVerified } = useAuthContext();
  const { updateSmsSettings } = useNotificationSettings();
  const user = getFragment(UserNotificationSettingsFragmentDoc, notificationSettings);

  const hookForm = useForm<ValidationSchema>({
    defaultValues: {
      isSmsEnabled: user.notificationSettings.isSmsEnabled,
      newArtistTrack: user.notificationSettings.smsNotifications.newArtistTrack,
      newSubscriber: user.notificationSettings.smsNotifications.newSubscriber,
    },
    resolver: zodResolver(validationSchema),
  });

  const { control } = hookForm;
  const formData = useWatch({ control });

  const onSubmit = async (data: ValidationSchema) => {
    const resp = await updateSmsSettings({
      input: {
        notificationsEnabled: data.isSmsEnabled,
        newArtistTrack: data.newArtistTrack,
        newSubscriber: data.newSubscriber,
      },
    });

    setQueryData(
      {
        query: UserNotificationSettingsDocument,
      },
      {
        data: {
          currentUser: {
            __typename: 'QueryCurrentUserSuccess',
            data: {
              ...getFragment(
                UserNotificationSettingsFragmentDoc,
                resp.data.updateSmsNotificationsSettings,
              ),
            },
          },
        },
      },
    );

    return data;
  };

  return (
    <View className="flex w-full flex-col">
      <FormProvider {...hookForm}>
        <HookAutoSave onSubmit={onSubmit} keepDirtyValues />

        <HookToggle
          label={`All ${isEmailVerified ? 'email' : 'SMS'} notifications`}
          control={control}
          name="isSmsEnabled"
        />

        {formData.isSmsEnabled && (
          <>
            <View className="mt-5 inline-flex items-center justify-between rounded-[16px] bg-base800 px-6 py-5">
              <View className="inline-flex items-center">
                <FontAwesomeIcon
                  icon={isEmailVerified ? faEnvelope : faMobile}
                  className="mr-3 px-[4px] py-[2px]"
                />
                <Text>{isEmailVerified ? user.authEmail : user.phone}</Text>
              </View>

              <View>
                <FontAwesomeIcon icon={faLock} />
              </View>
            </View>
          </>
        )}
      </FormProvider>
    </View>
  );
};
