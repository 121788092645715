import { useEffect } from 'react';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import type { ShareDropBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { ShareDropView } from '../vault/share/ShareDropView';

export function ShareDropBottomsheet({ dropSlug, artistHandle, type }: ShareDropBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const ownedArtist = useOwnedArtist({ artistHandle });

  useEffect(() => {
    if (ownedArtist == null) {
      closeBottomsheet();
    }
  }, [ownedArtist, closeBottomsheet]);

  return (
    <View className="min-h-[470px] pb-5">
      <ShareDropView dropSlug={dropSlug} artistHandle={artistHandle} type={type} />
    </View>
  );
}
