import { type FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { faMegaphone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { TierTypename } from '../../graphql/generated';
import { getFragment, MemberRowFragmentDoc, UserRowFragmentDoc } from '../../graphql/generated';
import { useDebounce } from '../../hooks/useDebounce';
import { useMySubscribers } from '../../hooks/useMySubscribers';
import { NewMessagePersistence } from '../../hooks/useNewMessage';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { NewMessageBottomsheetProps } from '../../types/bottomsheetTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { PaginatedView } from '../common/PaginatedView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { MemberRow } from '../membership/MemberRow';

export const NewMessageBottomsheet: FC<NewMessageBottomsheetProps> = ({
  artistHandle,
  vaultId,
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { hasNextPage, fetchNextPage, subscribers, isInitialLoading, isFetchingNextPage } =
    useMySubscribers({
      vaultId,
      searchQueryString: debouncedSearchQuery,
      shouldFilterBannedUsers: true,
    });

  const onSelectUser = useStableCallback(
    async ({
      artistDMChannelId,
      userId,
      displayName,
      profileImageUrl,
      subscriptionTierLevel,
    }: {
      artistDMChannelId: string;
      userId: string;
      displayName: string;
      profileImageUrl: string;
      subscriptionTierLevel: TierTypename;
    }) => {
      await NewMessagePersistence.set({
        artistDMChannelId,
        userId,
        displayName,
        profileImageUrl,
        subscriptionTierLevel,
      });

      const queryParams = constructQueryParams({
        newMessage: true,
      });

      navigate(artistNavigationPath(artistHandle, `/messages/${artistDMChannelId}`, queryParams));

      closeBottomsheet();
    },
  );

  const handleTextBlast = useStableCallback(() => {
    if (!artistHandle) return;
    closeBottomsheet();
    navigate(artistNavigationPath(artistHandle, '/messages/create'));
  });

  const handleSearchChange = useStableCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value),
  );
  return (
    <View className="h-full w-full md2:max-h-[67vh]">
      <View className="flex w-full items-center justify-between">
        <Button
          label=""
          className="rounded-full md2:hidden"
          leadingIcon={faAngleLeft}
          leadingIconClassName="text-[24px] text-vault_text"
          onClick={closeBottomsheet}
          iconOnly
        />

        <Text className="pb-3 pt-2 text-center font-title text-[28px]/[34px] font-medium text-vault_text md2:pt-0">
          New message
        </Text>

        <View className="w-[32px] md2:hidden" />
      </View>

      <View className="mt-2 flex flex-col">
        <div role="search" className="w-full">
          <label htmlFor="member-search" className="sr-only">
            Search members
          </label>
          <input
            id="member-search"
            className="flex w-full flex-grow appearance-none rounded-xl border-none bg-vault_text/10 p-4 text-[16px]/[20px] text-vault_text outline-none ring-0 placeholder:text-vault_text/50"
            placeholder="Search members"
            value={searchQuery}
            onChange={handleSearchChange}
            type="search"
            aria-label="Search members"
            aria-describedby="search-description"
          />
        </div>

        <Button
          label="New text blast"
          type="secondary"
          leadingIcon={faMegaphone}
          leadingIconClassName="mr-4"
          className="mt-6 w-full bg-transparent px-2 py-0 text-[16px]/[22px] text-vault_text"
          onClick={handleTextBlast}
        />

        <View className="my-4 h-[1px] w-full bg-vault_text/10" />

        <PaginatedView
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          className="flex h-[450px] w-full flex-col gap-2 pb-4"
          isFetchingNextPage={isFetchingNextPage}
        >
          {isInitialLoading ? (
            <Loaders />
          ) : (
            subscribers.map(subscriber => {
              const member = getFragment(MemberRowFragmentDoc, subscriber);
              const user = getFragment(UserRowFragmentDoc, member.user);

              return (
                <MemberRow
                  key={member.user.id}
                  id={member.id}
                  source="messages"
                  user={member.user}
                  tier={member.tier}
                  status={member.status}
                  createdAt={member.createdAt}
                  phone={member.phone}
                  email={member.email}
                  isTrial={member.isTrial}
                  userLocation={member.userLocation}
                  artistMembership={member.artistMembership}
                  withVaultTheme
                  artist={member.artist}
                  subText="joined-vault"
                  onSelect={() => {
                    if (!member.artistDMChannelId) return;
                    onSelectUser({
                      artistDMChannelId: member.artistDMChannelId,
                      userId: user.id,
                      displayName: user.computedDisplayName || 'Member',
                      profileImageUrl: user.avatar.avatarImageUrl || user.avatar.cdnUrl,
                      subscriptionTierLevel: member.tier.__typename,
                    });
                  }}
                  className="box-border p-2 hover:bg-vault_text/10"
                />
              );
            })
          )}
        </PaginatedView>
      </View>
    </View>
  );
};

const Loaders = () => (
  <>
    {Array.from({ length: 10 }).map((_, index) => (
      <LoadingSkeleton
        key={index}
        className="h-[60px] w-full self-center rounded-xl"
        withVaultTheme
      />
    ))}
  </>
);
