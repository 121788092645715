import React from 'react';
import prettyBytes from 'pretty-bytes';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useVaultContentUpload } from '../upload/useContentUpload';

export function ContentUploadingTracker() {
  const { artistHandle } = useArtistHandle();
  const ownedArtist = useOwnedArtist({ artistHandle });

  const {
    fields: { isUploading, retryUpload },
  } = useVaultContentUpload({ vaultId: ownedArtist?.mainVault.id ?? '' });

  if (!isUploading && !retryUpload) return null;

  return <ConetentUploadingView vaultId={ownedArtist?.mainVault.id ?? ''} />;
}

function ConetentUploadingView({ vaultId }: { vaultId: string }) {
  const {
    fields: { progressSize, totalSize, retryUpload },
  } = useVaultContentUpload({ vaultId });

  const percentage = Math.round((progressSize / totalSize) * 100);

  const totalSizePretty = React.useMemo(
    () => prettyBytes(totalSize, { space: false }),
    [totalSize],
  );

  const progressSizePretty = React.useMemo(
    () => prettyBytes(progressSize, { space: false }),
    [progressSize],
  );

  if (retryUpload) {
    return (
      <View className="absolute bottom-[100px] z-uploadTooltip flex w-full flex-row justify-center md2:max-lt:ml-[150px]">
        <View className="mx-4 flex w-full flex-col gap-2 rounded-lg bg-destructive700 px-4 py-3 sm:w-[66%] md:w-[50%] md2:max-w-[520px] lg:w-[30%]">
          <View className="flex flex-1 flex-row items-center justify-between py-3">
            <View className="flex flex-col gap-1 font-base">
              <View className="flex flex-row gap-1">
                <span className="text-base-m font-semibold">Your upload failed.</span>

                <span
                  onClick={retryUpload}
                  className="cursor-pointer text-base-m font-semibold underline"
                >
                  Try again.
                </span>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View className="absolute bottom-[100px] z-uploadTooltip flex w-full flex-row justify-center md2:max-lt:ml-[150px]">
      <View className="mx-4 flex w-full rounded-lg bg-vault_background  sm:w-[66%] md:w-[50%] md2:max-w-[520px] lg:w-[30%] ">
        <View className="flex w-full flex-col gap-2 rounded-lg bg-vault_text/20 px-4 py-3 ">
          <View className="flex flex-1 flex-row items-center justify-between ">
            <View className="flex flex-col gap-1 font-base">
              <Text className="text-base-m font-semibold text-vault_text">Uploading 1 file...</Text>
              <Text className="text-base-s font-normal">
                <span className="text-vault_accent">{progressSizePretty}</span>
                <span className="text-vault_text"> / {totalSizePretty}</span>
              </Text>
            </View>
          </View>
          <View className="h-1 w-full rounded-full bg-vault_text bg-opacity-30">
            <View
              className="h-1 w-1/4 rounded-full bg-vault_accent transition-all"
              style={{
                width: `${percentage}%`,
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
}
