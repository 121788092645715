import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCalendar, faGift, faReceipt } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';

export const Item = ({
  header,
  headerLink,
  children,
  className,
}: {
  header: React.ReactNode;
  headerLink?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <View className={twMerge('flex flex-col gap-6', className)}>
      <View className="flex w-full items-center gap-3">
        {typeof header === 'string' ? (
          <Text className="text-nowrap font-title text-[24px]/[24px] font-medium text-vault_text lg:text-[24px]/[26px]">
            {header}
          </Text>
        ) : (
          header
        )}

        {headerLink}
      </View>

      {children}
    </View>
  );
};

export const EmptyEvents = ({ artistHandle }: { artistHandle: string | undefined }) => {
  const ownedArtist = useOwnedArtist({
    artistHandle,
  });

  if (ownedArtist) {
    return (
      <View className="box-border flex w-full flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
        <View className="flex flex-col gap-4">
          <FontAwesomeIcon
            icon={faCalendar}
            size="2xl"
            className="flex items-center text-vault_text/90"
          />
          <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
            You currently don't have any active drops
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View className="box-border flex w-full flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
      <View className="flex flex-col gap-4">
        <FontAwesomeIcon
          icon={faCalendar}
          size="2xl"
          className="flex items-center text-vault_text/90"
        />
        <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
          There are currently no active drops from this artist
        </Text>
      </View>
    </View>
  );
};

export const EmptyReceipts = ({ artistHandle }: { artistHandle: string | undefined }) => {
  const ownedArtist = useOwnedArtist({
    artistHandle,
  });

  if (ownedArtist) {
    return (
      <View className="box-border flex w-full flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
        <View className="flex flex-col gap-4">
          <FontAwesomeIcon
            icon={faReceipt}
            size="2xl"
            className="flex items-center text-vault_text/90"
          />
          <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
            Members earn receipts when they RSVP to your drops
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View className="box-border flex w-full flex-col items-center gap-6 rounded-xl border border-vault_text/7 bg-vault_text/10 p-10">
      <View className="flex flex-col gap-4">
        <FontAwesomeIcon
          icon={faReceipt}
          size="2xl"
          className="flex items-center text-vault_text/90"
        />
        <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
          Collect receipts by RSVPing to drops
        </Text>
      </View>

      <View className="h-[1px] w-full bg-vault_text/5" />

      <View className="flex flex-col gap-4">
        <FontAwesomeIcon
          icon={faGift}
          size="2xl"
          className="flex items-center text-vault_text/90"
        />
        <Text className="max-w-[310px] text-center text-[16px]/[20px] text-vault_text/50">
          The more receipts you collect, the more status & access you get
        </Text>
      </View>
    </View>
  );
};
