import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { ArtistUserSettingsFeature } from '../../../graphql/generated';
import { useArtistUserNotificationSettings } from '../../../hooks/useArtistUserNotificationSettings';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { HookAutoSave } from '../../forms/hook-form/Autosave';
import { HookToggle } from '../../forms/hook-form/HookToggle';

const validationSchema = z.object({
  isPushEnabled: z.boolean().nullable(),
  isSmsEnabled: z.boolean().nullable(),
  radioFields: z.array(
    z.object({
      enabled: z.boolean(),
      key: z.string(),
      displayName: z.string(),
    }),
  ),
});

type ValidationSchema = z.infer<typeof validationSchema>;

export const ToggleView = ({
  artistHandle,
  isPushEnabled,
  isSmsEnabled,
  radioFields,
  feature,
  isEmailVerified,
}: {
  artistHandle: string;
  isPushEnabled?: boolean | null;
  isSmsEnabled?: boolean | null;
  feature: ArtistUserSettingsFeature;
  radioFields: { enabled: boolean; key: string; displayName: string }[];
  isEmailVerified: boolean;
}) => {
  const { updateNotificationSettings } = useArtistUserNotificationSettings({ artistHandle });
  const onSubmit = useStableCallback(async (data: ValidationSchema): Promise<ValidationSchema> => {
    await updateNotificationSettings({
      input: {
        artistHandle,
        isPushEnabled: data.isPushEnabled,
        isSmsEnabled: data.isSmsEnabled,
        radioFields: data.radioFields.map(field => ({
          enabled: field.enabled,
          key: field.key,
        })),
        feature,
      },
    });

    return data;
  });

  const form = useForm<ValidationSchema>({
    defaultValues: {
      isPushEnabled,
      isSmsEnabled,
      radioFields,
    },
    resolver: zodResolver(validationSchema),
  });

  return (
    <FormProvider {...form}>
      <HookAutoSave onSubmit={onSubmit} keepDirtyValues />
      <section className="flex w-full flex-col gap-6">
        {isSmsEnabled != null && (
          <HookToggle
            control={form.control}
            label={`${isEmailVerified ? 'Email' : 'SMS'} notifications`}
            name="isSmsEnabled"
          />
        )}
        {isPushEnabled != null && (
          <HookToggle
            control={form.control}
            label={
              feature === ArtistUserSettingsFeature.DmFromUser
                ? 'Enable push notifications'
                : 'Push notifications'
            }
            name="isPushEnabled"
          />
        )}
        <AnimatePresence>
          {!!isPushEnabled && radioFields.length > 0 && (
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-col gap-4 md2:gap-6"
            >
              <Controller
                name="radioFields"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      {value.map(({ key, displayName, enabled }) => (
                        <View
                          key={key}
                          className="flex cursor-pointer flex-row justify-between"
                          onClick={() => {
                            onChange(
                              value.map(({ key: loopedKey, displayName }) => ({
                                displayName,
                                key: loopedKey,
                                enabled: loopedKey === key ? true : false,
                              })),
                            );
                          }}
                        >
                          <Text className="!text-base-l text-vault_text">{displayName}</Text>
                          <input
                            type="radio"
                            className="default-radio cursor-pointer"
                            checked={enabled}
                            readOnly
                          />
                        </View>
                      ))}
                    </>
                  );
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </FormProvider>
  );
};
