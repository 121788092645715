import { z } from 'zod';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';

export const CONTENT_UPLOAD_ERRORS = {
  title: {
    min: 'File name is required',
    max: 'File name must be less than 128 characters',
  },
};

const UploadContentType = {
  TRACK: 'TRACK',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
} as const;

export const ContentUploadFieldSchema = z.object({
  folderId: z.string().nullable(),
  mediaId: z.string().nullable(),
  thumbnailRef: z.object({
    current: z
      .object({
        file: z.instanceof(File),
        fileUri: z.string(),
      })
      .nullable(),
  }),
  thumbnailUrl: z.string().nullable(),
  thumbnailId: z.string().nullable(),
  isThumbnailUploading: z.boolean(),
  normalizedPeaks: z.array(z.number()).nullable(),
  title: z
    .string()
    .min(1, {
      message: CONTENT_UPLOAD_ERRORS.title.min,
    })
    .max(128, {
      message: CONTENT_UPLOAD_ERRORS.title.max,
    })
    .refine(val => /\S/.test(val), {
      message: 'File name is required',
    }),
  caption: z.string().nullable(),
  featureAccess: z.nativeEnum(VaultContentAccessFeatureInput),
  shouldSendSMS: z.boolean(),
  contentType: z.nativeEnum(UploadContentType).nullable(),
  downloadEnabled: z.boolean(),
  isUploading: z.boolean(),
  isUploadingPreview: z.boolean(),
  totalSize: z.number(),
  progressSize: z.number(),
  isPromptOpen: z.boolean(),
  duration: z.number().nullable(),
  fileRef: z.object({
    current: z
      .object({
        file: z.instanceof(File),
        vaultId: z.string(),
        fileUri: z.string(),
        normalizedPeaks: z.array(z.number()).nullable(),
        duration: z.number(),
        parentVaultContentId: z.string().nullable(),
        contentType: z.nativeEnum({
          TRACK: 'TRACK',
          VIDEO: 'VIDEO',
          IMAGE: 'IMAGE',
        }),
      })
      .nullable(),
  }),
  retryUpload: z.function().nullable(),
  isEditing: z.boolean(),
  isSnippetOnly: z.boolean(),
});

const ContentUploadErrorsSchema = z.object({
  title: z.string().nullish(),
});

export const ContentUploadSchema = z.object({
  fields: ContentUploadFieldSchema,
  errors: ContentUploadErrorsSchema,
});

export type ContentUploadState = z.infer<typeof ContentUploadSchema>;
export type ContentUploadFields = z.infer<typeof ContentUploadFieldSchema>;
export type ContentUploadErrors = z.infer<typeof ContentUploadErrorsSchema>;
