import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';

type UserRowSize = 'default' | 'large';

const sizeStyles = {
  default: {
    image: 'h-8 w-8',
    title: 'text-[14px] font-base',
  },
  large: {
    image: 'h-12 w-12',
    title: 'text-[18px] font-title',
  },
} as const satisfies Record<UserRowSize, { image: string; title: string }>;

type UserRowProps = {
  className?: string;
  loading?: boolean;
  managedVault: boolean;
  name: string;
  nameComponent?: React.ReactNode;
  profileImageUrl: string | null | undefined;
  rightComponent?: React.ReactNode;
  size?: UserRowSize;
  subtitle?: string;
  subtitleComponent?: React.ReactNode;
  useVaultTheme: boolean;
  verifiedBadge: boolean;
};

export const UserRow = memo(function UserRow({
  className,
  loading,
  managedVault,
  name,
  nameComponent,
  profileImageUrl,
  rightComponent,
  size = 'default',
  subtitle,
  subtitleComponent,
  useVaultTheme,
  verifiedBadge,
}: UserRowProps) {
  const styles = sizeStyles[size];

  return (
    <View className={twMerge('flex w-full items-center justify-between gap-3', className)}>
      <View className="flex min-w-0 flex-1 items-center gap-3">
        <ProfileImage
          profileImageUrl={profileImageUrl}
          className={twMerge(styles.image, 'rounded-full object-cover')}
          loading={loading}
          onClick={undefined}
          withVaultTheme={useVaultTheme}
        />

        <View className="flex min-w-0 flex-1 flex-col gap-0.5">
          <TitleComponent
            name={name}
            verifiedBadge={verifiedBadge}
            loading={loading}
            managedVault={managedVault}
            useVaultTheme={useVaultTheme}
            size={size}
          />
          {subtitleComponent ?? (
            <SubtitleComponent
              subtitle={subtitle}
              loading={loading}
              managedVault={managedVault}
              useVaultTheme={useVaultTheme}
              name={name}
              nameComponent={nameComponent}
              size={size}
            />
          )}
        </View>
      </View>

      {rightComponent}
    </View>
  );
});

type TitleComponentProps = Pick<
  UserRowProps,
  'name' | 'verifiedBadge' | 'loading' | 'managedVault' | 'useVaultTheme' | 'size'
>;

function TitleComponent({
  loading,
  managedVault,
  name,
  useVaultTheme,
  verifiedBadge,
  size = 'default',
}: TitleComponentProps) {
  const styles = sizeStyles[size];

  if (loading) {
    return (
      <LoadingSkeleton className="h-3 w-[100px] rounded-full" withVaultTheme={useVaultTheme} />
    );
  }

  if (managedVault) {
    return (
      <Text
        className={twMerge(
          'text-left font-base text-[12px] font-medium',
          useVaultTheme ? 'text-vault_text/40' : 'text-white/40',
        )}
      >
        Your vault
      </Text>
    );
  }

  return (
    <View className="flex min-w-0 max-w-full items-center">
      <Text
        className={twMerge(
          'truncate text-left font-medium',
          styles.title,
          useVaultTheme ? 'text-vault_text' : 'text-white',
        )}
      >
        {name}
      </Text>
      {verifiedBadge && (
        <View className="ml-1.5 flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon
            icon={faBadgeCheck}
            className={twMerge('h-3 w-3', useVaultTheme ? 'text-vault_accent' : 'text-yellow100')}
          />
        </View>
      )}
    </View>
  );
}

type SubtitleComponentProps = Pick<
  UserRowProps,
  'subtitle' | 'loading' | 'managedVault' | 'useVaultTheme' | 'name' | 'size' | 'nameComponent'
>;

function SubtitleComponent({
  loading,
  managedVault,
  name,
  subtitle,
  useVaultTheme,
  size = 'default',
  nameComponent,
}: SubtitleComponentProps) {
  const styles = sizeStyles[size];

  if (loading) {
    return <LoadingSkeleton className="h-3 w-[50px] rounded-full" withVaultTheme={useVaultTheme} />;
  }

  if (managedVault) {
    return (
      nameComponent ?? (
        <Text
          className={twMerge(
            'truncate text-left font-medium',
            styles.title,
            useVaultTheme ? 'text-vault_text' : 'text-white',
          )}
        >
          {name}
        </Text>
      )
    );
  }

  if (subtitle == null) {
    return null;
  }

  return (
    <Text
      className={twMerge(
        'text-left font-base text-[12px] font-medium',
        useVaultTheme ? 'text-vault_text/40' : 'text-white/40',
      )}
    >
      {subtitle}
    </Text>
  );
}
