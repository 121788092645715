import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { deepTrim } from '@soundxyz/utils/src/string';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistProfileSettingsDocument,
  ArtistSettingsViewFragmentDoc,
  AuthorizedUserProfilesDocument,
  AuthUserDocument,
  CustomizeVaultDocument,
  CustomizeVaultViewFragmentDoc,
  getFragment,
  VaultCustomizationDocument,
  VaultThemeByArtistHandleDocument,
  VaultThemeByVaultIdDocument,
} from '../../../graphql/generated';
import { useLogError } from '../../../hooks/logger/useLogError';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { resetVaultTheme, VaultThemeStore } from '../../../hooks/useVaultTheme';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { generateAccentColors } from '../../../utils/colorUtils';
import { customizeMenuValidationSchema, type CustomizeMenuValidationSchema } from './schema';

gql(/* GraphQL */ `
  mutation CustomizeVault($input: MutationCustomizeVaultInput!) {
    customizeVault(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationCustomizeVaultSuccess {
        data {
          id
          ...customizeVaultView
        }
      }
    }
  }

  query VaultCustomization($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      ...customizeVaultView
    }
  }

  fragment artistSettingsView on Artist {
    id
    name
    description
    customWebsiteUrl
    spotifyUrl
    instagramHandle
    tiktokHandle
    profileImage {
      id
      artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      mediaType
    }
    mainVault {
      id
      welcomeMessage
    }
    phoneNumbers {
      phoneNumber
      phoneCountryCode
    }
  }

  query ArtistProfileSettings($artistId: UUID!) {
    artistById(artistId: $artistId) {
      id
      ...artistSettingsView
    }
  }
`);

RefetchOnComplete({
  trigger: [CustomizeVaultDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultThemeByArtistHandleDocument,
    VaultThemeByVaultIdDocument,
    VaultCustomizationDocument,
    CustomizeVaultViewFragmentDoc,
    ArtistProfileSettingsDocument,
    AuthUserDocument,
    AuthorizedUserProfilesDocument,
  ],
});

export function useCustomize({ vaultId }: { vaultId: string | null | undefined }) {
  const { openToast } = useToast();

  const logError = useLogError();

  const ownedArtist = useOwnedArtist({ artistMainVaultId: vaultId });

  const vaultDataInitialized = useRef(false);
  const vaultTheme = useSnapshot(VaultThemeStore);

  const [isActionPending, setActionPending] = useState(false);
  const [activeTab, setActiveTab] = useState<'profile' | 'theme'>('profile');

  const {
    data: vaultData,
    isLoading: isLoadingVault,
    isError: isVaultError,
    refetch: refetchVault,
  } = useQuery(VaultCustomizationDocument, {
    staleTime: 0,
    cacheTime: 0,
    filterQueryKey: {
      vaultId,
      artistId: ownedArtist?.id,
    },
    variables: !!vaultId && {
      vaultId,
    },
  });

  const {
    data: artistProfileData,
    isError: isArtistProfileError,
    isLoading: isLoadingArtistProfile,
    refetch: refetchArtistProfile,
  } = useQuery(ArtistProfileSettingsDocument, {
    staleTime: 0,
    cacheTime: 0,
    variables: !!ownedArtist?.id && { artistId: ownedArtist.id },
  });

  const vault = getFragment(CustomizeVaultViewFragmentDoc, vaultData?.data.vaultFromId);
  const artistProfile = getFragment(
    ArtistSettingsViewFragmentDoc,
    artistProfileData?.data.artistById,
  );

  const isError = isVaultError || isArtistProfileError;
  const isLoading =
    isLoadingVault ||
    vault == null ||
    isLoadingArtistProfile ||
    artistProfileData?.data.artistById == null;

  // Used to check changes before closing
  const [initialProfileImageUrl, setInitialProfileImageUrl] = useState<string | null>(null);
  const [initialLogoUrl, setInitialLogoUrl] = useState<string | null>(null);
  const [initialBackgroundColor, setInitialBackgroundColor] = useState<string | null>(null);
  const [initialAccentColor, setInitialAccentColor] = useState<string | null>(null);

  const [temporaryProfileImageUrl, setTemporaryProfileImageUrl] = useState<string | null>(
    vaultTheme.profileImageUrl,
  );

  const [temporaryLogoUrl, setTemporaryLogoUrl] = useState<string | null>(vaultTheme.logoMediaUrl);

  const form = useForm<CustomizeMenuValidationSchema>({
    defaultValues: {
      shouldRemoveLogo: false,
    },
    resolver: zodResolver(customizeMenuValidationSchema),
  });

  const { setValue, control, register, handleSubmit, formState, reset } = form;
  const { isSubmitting, errors, touchedFields } = formState;

  const {
    accentColor: formAccentColor,
    backgroundColor: formBackgroundColor,
    name: formName,
  } = useWatch({
    control,
  });

  const { mutateAsync: customizeVault, isLoading: isCustomizing } = useMutation(
    CustomizeVaultDocument,
    {
      onSuccess: () => {
        setInitialLogoUrl(temporaryLogoUrl);
        setInitialProfileImageUrl(temporaryProfileImageUrl);
        setInitialBackgroundColor(formBackgroundColor || null);
        setInitialAccentColor(formAccentColor || null);
      },
      onError: e => {
        openToast({
          text: e.message,
          variant: 'error',
        });
      },
    },
  );

  const hasUnsavedChanges = useCallback(() => {
    const touchedFieldsExist = Object.keys(touchedFields).length > 0;
    const logoChanged = temporaryLogoUrl !== initialLogoUrl;
    const profileImageChanged = temporaryProfileImageUrl !== initialProfileImageUrl;
    const backgroundColorChanged = (formBackgroundColor || null) !== initialBackgroundColor;
    const accentColorChanged = (formAccentColor || null) !== initialAccentColor;

    return (
      touchedFieldsExist ||
      logoChanged ||
      profileImageChanged ||
      backgroundColorChanged ||
      accentColorChanged
    );
  }, [
    formAccentColor,
    formBackgroundColor,
    initialAccentColor,
    initialBackgroundColor,
    initialLogoUrl,
    initialProfileImageUrl,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    touchedFields,
  ]);

  useEffect(() => {
    if (isLoading || !vault || !artistProfile || vaultDataInitialized.current) return;

    // For customize vault
    setValue('accentColor', vaultTheme.accentColor || vault?.accentColor || null);
    setValue('backgroundColor', vaultTheme.backgroundColor || vault?.backgroundColor || null);
    setValue('logoMediaId', vault?.logoImage?.id || null);
    // For customize artist profile
    setValue('profileImageMediaId', vault?.artistProfile?.profileImage?.id || null);
    setTemporaryLogoUrl(vault?.logoImage?.logoImageUrl || null);
    setTemporaryProfileImageUrl(vault?.artistProfile?.profileImage?.artistProfileImageUrl || null);

    // Used to check changes before closing
    setInitialLogoUrl(vault?.logoImage?.logoImageUrl || null);
    setInitialProfileImageUrl(vault?.artistProfile?.profileImage?.artistProfileImageUrl || null);
    setInitialBackgroundColor(vaultTheme.backgroundColor || vault?.backgroundColor || null);
    setInitialAccentColor(vaultTheme.accentColor || vault?.accentColor || null);

    setValue('name', artistProfile?.name || '');
    setValue('customWebsiteUrl', artistProfile?.customWebsiteUrl || '');
    setValue('instagramHandle', artistProfile?.instagramHandle || '');
    setValue('spotifyUrl', artistProfile?.spotifyUrl || '');
    setValue('tiktokHandle', artistProfile?.tiktokHandle || '');

    vaultDataInitialized.current = true;
  }, [vault, setValue, vaultTheme, artistProfile, isLoading]);

  const accents = useMemo(
    () => (formBackgroundColor ? generateAccentColors(formBackgroundColor).accents : null),
    [formBackgroundColor],
  );

  const onSubmit = async ({
    props,
    onSubmitParent,
    onSave,
  }: {
    props: CustomizeMenuValidationSchema;
    onSubmitParent: () => void;
    onSave?: () => void;
  }) => {
    if (!vault || !artistProfile) return;

    trackEvent({
      type: EVENTS.CUSTOMIZE_VAULT,
      properties: { vaultId: vault.id },
    });

    trackEvent({
      type: EVENTS.EDIT_ARTIST_PROFILE,
      properties: { artistId: artistProfile.id },
    });

    const customizeResult = await customizeVault({
      input: {
        vaultId: vault.id,
        profileImageId: props.profileImageMediaId,
        accentColor: props.accentColor ?? vault.accentColor,
        backgroundColor: props.backgroundColor ?? vault.backgroundColor,
        logoImageId: props.logoMediaId,
        displayName: deepTrim(props.name),
        socials: {
          customWebsiteUrl: props.customWebsiteUrl?.trim() || null,
          spotifyUrl: props.spotifyUrl?.trim() || null,
          instagramHandle: props.instagramHandle?.trim() || null,
          tiktokHandle: props.tiktokHandle?.trim() || null,
        },
        unset: {
          logoImageId: props.shouldRemoveLogo,
        },
      },
    });

    if (customizeResult.data.customizeVault.__typename === 'MutationCustomizeVaultSuccess') {
      onSave?.();

      resetVaultTheme({ vaultId: vault.id });

      const updatedCustomizationFragment = getFragment(
        CustomizeVaultViewFragmentDoc,
        customizeResult.data.customizeVault.data,
      );

      setTemporaryLogoUrl(updatedCustomizationFragment.logoImage?.logoImageUrl ?? null);
      setTemporaryProfileImageUrl(
        updatedCustomizationFragment.artistProfile?.profileImage?.artistProfileImageUrl ?? null,
      );

      reset({
        logoMediaId: updatedCustomizationFragment.logoImage?.id ?? null,
        profileImageMediaId: updatedCustomizationFragment.artistProfile?.profileImage?.id ?? null,
        shouldRemoveLogo: false,
        name: updatedCustomizationFragment.artistProfile?.name,
        customWebsiteUrl: updatedCustomizationFragment.artistProfile?.customWebsiteUrl,
        instagramHandle: updatedCustomizationFragment.artistProfile?.instagramHandle,
        spotifyUrl: updatedCustomizationFragment.artistProfile?.spotifyUrl,
        tiktokHandle: updatedCustomizationFragment.artistProfile?.tiktokHandle,
        accentColor: updatedCustomizationFragment.accentColor,
        backgroundColor: updatedCustomizationFragment.backgroundColor,
      });

      openToast({
        text: 'Customization saved successfully!',
        variant: 'success',
        withAudioPlayer: false,
      });

      onSubmitParent();
    } else if (customizeResult.data.customizeVault.__typename === 'UsernameUnavailableError') {
      logError({
        message: customizeResult.data.customizeVault.message,
        action: 'PROFILE_CUSTOMIZATION_ERROR',
        error: new Error(customizeResult.data.customizeVault.message),
        errorType: 'MUTATION_ERROR',
        level: 'log',
        feature: 'VAULT_CUSTOMIZATION',
        openToast,
        toast: {
          text: 'Username is not available, please try a different username',
          variant: 'error',
        },
      });
    } else if (customizeResult.data.customizeVault.__typename === 'ValidationError') {
      logError({
        message: customizeResult.data.customizeVault.message,
        action: 'PROFILE_CUSTOMIZATION_ERROR',
        error: new Error(customizeResult.data.customizeVault.message),
        errorType: 'VALIDATION_ERROR',
        level: 'error',
        feature: 'VAULT_CUSTOMIZATION',
        openToast,
        toast: {
          text: customizeResult.data.customizeVault.message,
          variant: 'error',
        },
      });
    } else {
      logError({
        message: customizeResult.data.customizeVault.message,
        action: 'PROFILE_CUSTOMIZATION_ERROR',
        error: new Error(customizeResult.data.customizeVault.message),
        errorType:
          customizeResult.data.customizeVault.__typename === 'NotAuthorizedError'
            ? 'NOT_AUTHORIZED'
            : 'NOT_FOUND_ERROR',
        level: 'error',
        feature: 'VAULT_CUSTOMIZATION',
        openToast,
        toast: {
          text: 'Failed to save Customization. Please try again.',
          variant: 'error',
        },
      });
    }
  };

  return {
    accents,
    activeTab,
    artistProfile,
    customizeVault,
    errors,
    form,
    formAccentColor,
    formBackgroundColor,
    formName,
    formState,
    handleSubmit,
    hasUnsavedChanges,
    isActionPending,
    isCustomizing,
    isError,
    isLoading,
    isSubmitting,
    refetchArtistProfile,
    refetchVault,
    register,
    reset,
    setActionPending,
    setActiveTab,
    setTemporaryLogoUrl,
    setTemporaryProfileImageUrl,
    setValue,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    vault,
    onSubmit,
  };
}
