import { twMerge } from 'tailwind-merge';
import { PRIVACY_POLICY_URL } from '../../constants/urlConstants';
import { useSignIn } from '../../hooks/useSignIn';
import { useTosLink } from '../../hooks/useTosLink';

export function AuthAgreementText({
  withVaultTheme,
  artistHandle,
}: {
  withVaultTheme?: boolean;
  artistHandle: string | undefined;
}) {
  const { authMethod, isOpen } = useSignIn();
  const { url: tosUrl } = useTosLink({ artistHandle });

  return (
    <p
      className={twMerge(
        'text-[10px]/[14px]',
        withVaultTheme
          ? 'text-vault_text [&_a]:text-vault_accent'
          : 'text-base_text [&_a]:text-base_accent',
      )}
    >
      By signing up, you agree to the&nbsp;
      <a
        href={tosUrl}
        target="_blank"
        className="hover:cursor-pointer"
        onClick={e => isOpen && e.preventDefault()}
      >
        Terms
      </a>
      &nbsp;&&nbsp;
      <a
        href={PRIVACY_POLICY_URL}
        target="_blank"
        className="hover:cursor-pointer"
        onClick={e => isOpen && e.preventDefault()}
      >
        Privacy Policy
      </a>
      <br />
      {authMethod === 'sms' && ' and receive texts. Reply STOP to opt out. Msg rates may apply.'}
      {authMethod === 'email' && ' and receive emails. Unsubscribe at any time.'}
    </p>
  );
}
