import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FieldErrors, type UseFormRegister, type UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { faInstagram, faSpotify, faTiktok } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { type CustomizeVaultViewFragment } from '../../../graphql/generated';
import { useUploadAvatar } from '../../../hooks/useUpdateAvatar';
import { useWindow } from '../../../hooks/useWindow';
import { sleep } from '../../../utils/timerUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';
import { ProfileImage } from '../../user/ProfileImage';
import { type CustomizeMenuValidationSchema } from './schema';

export function CustomizeProfile({
  setActionPending,
  isVisible,
  vault,
  temporaryProfileImageUrl,
  setTemporaryProfileImageUrl,
  isCustomizing,
  isSubmitting,
  setValue,
  errors,
  register,
}: {
  setActionPending: (isPending: boolean) => void;
  isVisible: boolean;
  vault: CustomizeVaultViewFragment;
  temporaryProfileImageUrl: string | null;
  setTemporaryProfileImageUrl: (value: string | null) => void;
  isCustomizing: boolean;
  isSubmitting: boolean;
  setValue: UseFormSetValue<CustomizeMenuValidationSchema>;
  errors: FieldErrors<CustomizeMenuValidationSchema>;
  register: UseFormRegister<CustomizeMenuValidationSchema>;
}) {
  const [updatePending, setUpdatePending] = useState<boolean>(false);

  const { getRootProps, getInputProps } = useUploadAvatar({
    onConfirm: () => setUpdatePending(true),
    onDone: async () => {
      await sleep(400);
      setUpdatePending(false);
    },
    onSuccess({ mediaId, cdnUrl }) {
      setTemporaryProfileImageUrl(cdnUrl);
      setValue('profileImageMediaId', mediaId, { shouldDirty: true });
    },
  });

  const isActionPending = isSubmitting || updatePending || isCustomizing;

  useEffect(() => {
    setActionPending(isActionPending);
  }, [isActionPending, setActionPending]);

  const rootProps = getRootProps();

  if (!vault.artistId) {
    return null;
  }

  return (
    <View className={twMerge('flex w-full flex-col gap-6', isVisible ? 'visible' : 'hidden')}>
      <View className="flex w-full flex-col gap-3">
        <Text className="font-title text-title-s">Profile picture</Text>

        <View className="flex w-full flex-row items-center gap-3">
          {updatePending ? (
            <SkeletonProfilePicture />
          ) : (
            <ProfileImage
              profileImageUrl={temporaryProfileImageUrl ?? undefined}
              className="h-[100px]"
              onClick={undefined}
            />
          )}
          <div
            className={twMerge('w-full', updatePending ? 'cursor-none' : 'cursor-pointer')}
            {...rootProps}
            onClick={(...args) => {
              if (updatePending) return;
              rootProps.onClick?.(...args);
            }}
          >
            <Button
              label="Choose image"
              type="primary"
              className="w-full bg-base700 text-white"
              disabled={updatePending}
              disabledClassName="opacity-30 transition-opacity"
            />
            <input {...getInputProps()} disabled={updatePending} />
          </div>
        </View>
      </View>

      <View className="flex w-full flex-col gap-3">
        <Text className="font-title text-title-s">Display name</Text>

        <input
          className={twMerge(
            'rounded-md border border-solid bg-transparent p-4 !text-base-l text-white placeholder:text-white/50 focus:font-normal focus:outline-none',
            !!errors.name ? 'border-destructive300' : 'border-white/10 focus:border-white',
          )}
          placeholder=""
          {...register('name')}
        />
        {!!errors.name && (
          <Text className="text-base-s text-destructive300">{errors.name.message}</Text>
        )}
      </View>

      <View className="flex w-full flex-col gap-2">
        <Text className="font-title text-title-s">Links</Text>

        <View className="flex w-full items-center gap-2">
          <FontAwesomeIcon icon={faGlobe} className="text-[24px]" />

          <input
            className={twMerge(
              'w-full border-0 border-b border-solid bg-transparent p-4 !text-base-l text-white placeholder:text-white/50 focus:font-normal focus:outline-none',
              !!errors.customWebsiteUrl
                ? 'border-destructive300'
                : 'border-white/10 focus:border-white',
            )}
            placeholder="https://your_website.com"
            autoCapitalize="none"
            {...register('customWebsiteUrl')}
          />
          {!!errors.customWebsiteUrl && (
            <Text className="text-base-s text-destructive300">
              {errors.customWebsiteUrl.message}
            </Text>
          )}
        </View>

        <View className="flex w-full items-center gap-2">
          <FontAwesomeIcon icon={faSpotify} className="text-[24px]" />

          <input
            className={twMerge(
              'w-full border-0 border-b border-solid bg-transparent p-4 !text-base-l text-white placeholder:text-white/50 focus:font-normal focus:outline-none',
              !!errors.spotifyUrl ? 'border-destructive300' : 'border-white/10 focus:border-white',
            )}
            placeholder="https://open.spotify.com/artist/"
            autoCapitalize="none"
            {...register('spotifyUrl')}
          />
          {!!errors.spotifyUrl && (
            <Text className="text-base-s text-destructive300">{errors.spotifyUrl.message}</Text>
          )}
        </View>

        <View className="flex w-full items-center gap-2">
          <FontAwesomeIcon icon={faInstagram} className="text-[24px]" />

          <input
            className={twMerge(
              'w-full border-0 border-b border-solid bg-transparent p-4 !text-base-l text-white placeholder:text-white/50 focus:font-normal focus:outline-none',
              !!errors.instagramHandle
                ? 'border-destructive300'
                : 'border-white/10 focus:border-white',
            )}
            placeholder="@"
            autoCapitalize="none"
            {...register('instagramHandle')}
          />
          {!!errors.instagramHandle && (
            <Text className="text-base-s text-destructive300">
              {errors.instagramHandle.message}
            </Text>
          )}
        </View>

        <View className="flex w-full items-center gap-2">
          <FontAwesomeIcon icon={faTiktok} className="text-[24px]" />

          <input
            className={twMerge(
              'w-full border-0 border-b border-solid bg-transparent p-4 !text-base-l text-white placeholder:text-white/50 focus:font-normal focus:outline-none',
              !!errors.tiktokHandle
                ? 'border-destructive300'
                : 'border-white/10 focus:border-white',
            )}
            placeholder="@"
            autoCapitalize="none"
            {...register('tiktokHandle')}
          />
          {!!errors.tiktokHandle && (
            <Text className="text-base-s text-destructive300">{errors.tiktokHandle.message}</Text>
          )}
        </View>
      </View>
    </View>
  );
}

export function CustomizeVaultSkeleton() {
  const { isDesktop } = useWindow();
  return (
    <View
      className={twMerge(
        'z-above1 flex w-full flex-1 flex-col items-center text-white',
        !isDesktop && 'sm:w-[66%] md:w-[50%] lg:w-[33%]',
      )}
    >
      <Text className="mb-2 self-start text-left font-title text-title-s">Profile picture</Text>
      <View className="flex w-full items-center gap-2">
        <SkeletonProfilePicture />
        <LoadingSkeleton className="h-[52px] w-[180px] rounded-full" />
      </View>

      <Text className="mb-3 mt-[16px] self-start text-left font-title text-title-s">Name</Text>
      <View className="flex w-full flex-col items-center gap-2">
        <LoadingSkeleton className="h-[54px] w-full" />
      </View>

      <Text className="mb-2 mt-[24px] self-start text-left font-title text-title-s">Links</Text>
      <View className="flex w-full flex-col items-center gap-2">
        <LoadingSkeleton className="mb-[4px] h-[50px] w-full" />
        <LoadingSkeleton className="mb-[4px] h-[50px] w-full" />
        <LoadingSkeleton className="mb-[4px] h-[50px] w-full" />
        <LoadingSkeleton className="h-[50px] w-full" />
      </View>
    </View>
  );
}

function SkeletonProfilePicture({ className }: { className?: string }) {
  return (
    <LoadingSkeleton
      className={className ?? 'mb-[12px] h-[100px] w-[100px] flex-shrink-0 rounded-full'}
    />
  );
}
