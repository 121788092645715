import { defaultConfig } from '@tamagui/config/v4';
import { createTamagui } from '@tamagui/core';
import { createFont } from '@tamagui/core';

const baseFont = createFont({
  family: 'Inter, scrollbar',
  size: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 22,
  },
  lineHeight: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 24,
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },

  face: {
    normal: { normal: 'Inter-Regular' },
    medium: { normal: 'Inter-Medium' },
    bold: { normal: 'Inter-Bold' },
  },
});

const titleFont = createFont({
  family: 'Acid Grotesk, sans-serif',
  size: {
    4: 12,
    xxs: 12,
    xs: 14,
    sm: 16,
    md: 18,
    lg: 22,
    xl: 28,
    xxl: 42,
  },
  lineHeight: {
    xxs: 16,
    xs: 18,
    sm: 20,
    md: 22,
    lg: 26,
    xl: 32,
    xxl: 48,
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  face: {
    normal: { normal: 'AcidGrotesk-Regular' },
    medium: { normal: 'AcidGrotesk-Medium' },
    bold: { normal: 'AcidGrotesk-Bold' },
  },
});

const monoFont = createFont({
  size: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 22,
  },
  lineHeight: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 24,
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  face: {
    normal: { normal: 'NBArchitektNeue-Regular' },
    bold: { normal: 'NBArchitektNeue-Bold' },
  },
});

export const config = createTamagui({
  ...defaultConfig,
  fonts: {
    title: titleFont,
    base: baseFont,
    mono: monoFont,
    numerals: monoFont,
  },
  // act like CSS variables at your root
  tokens: {
    // width="$sm"
    size: {
      sm: 8,
      md: 12,
      lg: 20,
      xl: 24,
      '2xl': 32,
    },
    // margin="$-sm"
    space: { '-sm': 8, '-md': 12, '-lg': 20, sm: 8, md: 12, lg: 20 },
    color: {
      white: '#fff',
      black: '#000',
      base_text: '#FFFFFF',
      base_background: '#0E1213',
      base_accent: '#E3F41F',
      base_accent_text: '#0E1213',
      base_destructive: '#FF453A',
      base_opposite_text: '#0E1213',
    },
  },

  media: {
    sm: { maxWidth: 860 },
    md2: { maxWidth: 870 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  },

  shorthands: {
    // <View px={20} />
    px: 'paddingHorizontal',
    py: 'paddingVertical',
    mx: 'marginHorizontal',
    my: 'marginVertical',
  },

  settings: {
    allowedStyleValues: 'somewhat-strict-web', // if targeting only web
  },
  themes: {
    dark: {
      white: '#fff',
      black: '#000',
      base_text: '#FFFFFF',
      base_background: '#0E1213',
      base_accent: '#E3F41F',
      base_accent_text: '#0E1213',
      base_destructive: '#FF453A',
      base_opposite_text: '#0E1213',
    },
  },
});

type AppConfig = typeof config;
declare module '@tamagui/core' {
  interface TamaguiCustomConfig extends AppConfig {}
}
