import { type LegacyRef, useMemo, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFileDownload } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useMutation } from '../../../../graphql/client';
import { MediaType } from '../../../../graphql/generated';
import { ImageItemFragmentDoc, UpsertUserContentViewDocument } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useBetterGate } from '../../../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../../../hooks/useOwnedArtist';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { useWindow } from '../../../../hooks/useWindow';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { MediaOverlay } from '../../../content/MediaOverlay';
import type { MediaView } from '../../../content/RenderedMedia';
import { MediaViewer } from '../../../message/MediaViewer';
import { ImageItem } from '../ImageItem';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';
import { useVaultDownloadPress } from '../useVaultDownloadPress';

export const UserImage = ({
  image,
  allAssets,
  containerRef,
  artistHandle,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  allAssets: Array<MediaView>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();

  const [menuHovered, setMenuHovered] = useState(false);

  const navigate = useNavigate();
  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const {
    id: imageId,
    vault,
    title,
    uploadedMedia,
    vaultId,
    linkValue,
    downloadEnabled,
  } = getFragment(ImageItemFragmentDoc, image);

  const { loginStatus, loggedInUser } = useAuthContext();
  const path = linkValue ? (`/i/${linkValue}` as const) : (`/${imageId}` as const);

  const ownedArtist = useOwnedArtist({ artistMainVaultId: vaultId });

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: !!ownedArtist ? null : loggedInUser?.inviteCode,
    });
  }, [artistHandle, path, ownedArtist, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Image link copied to clipboard!',
  });

  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('code') ?? undefined; // referral link code
  const hasSubscription = vault.activeSubscription != null;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const { isDesktop } = useWindow();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const vaultArtistProfileImage = vault.artist?.profileImage?.artistSmallProfileImageUrl ?? null;
  const vaultArtistMembershipCardImage =
    vault.artist?.membershipCardImage?.membershipCardImageUrl ?? null;
  const hasActiveSubscription = !!vault.activeSubscription?.id;
  const hasMediaCommentsFF = useBetterGate('MEDIA_COMMENTS') === 'enabled';

  const { onDownloadClick } = useVaultDownloadPress({
    vaultContentId: imageId,
    isLocked,
    vaultId: vault.id,
    artistHandle,
    artistName: vault.artist?.name ?? null,
    vaultArtistProfileImage,
    vaultArtistMembershipCardImage,
    hasActiveSubscription,
    fileName: title,
  });

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const onClick = () => {
    upsertUserContentView({ input: { vaultContentId: imageId } });
    if (isLocked || showUpsellInterstitial) {
      if (showUpsellInterstitial) {
        setTimeout(() => {
          openBottomsheet({
            type: 'GET_APP',
            getAppBottomsheetProps: {
              vaultId,
              interstitial: 'first_media',
              onContinue: null,
            },
          });

          trackEvent({
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: 'GET_APP',
              vaultId,
              interstitial: 'first_media',
            },
          });
        }, 2000);
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'JOIN_VAULT',
            joinType: hasSubscription ? 'paid' : 'free',
            artistHandle,
          },
        });

        navigate(artistNavigationPath(artistHandle, path, inviteCode));
      }
      return;
    }

    if (hasMediaCommentsFF) {
      const firstAsset = allAssets[0];
      if (!firstAsset) return;

      if (isDesktop) {
        openOverlay(
          <MediaOverlay media={firstAsset} currentVideoRef={videoRef} onClose={closeOverlay} />,
        );
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.MEDIA_VIEW,
            contentType: MediaType.Image,
            component: 'UserImage',
            event: 'Open Bottomsheet',
            contentId: firstAsset.id,
          },
        });

        openBottomsheet({
          type: 'MEDIA_VIEW',
          shared: {
            withVaultTheme: false,
            showFullScreen: true,
            hidePulleyBar: true,
            fullWidth: true,
          },
          mediaViewBottomsheetProps: {
            media: firstAsset,
            currentVideoRef: videoRef,
            withVaultTheme: false,
          },
        });
      }
    } else {
      openOverlay(<MediaViewer title={title} medias={allAssets} onClose={closeOverlay} />);
    }
  };

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];

  if (downloadEnabled && !isLocked) {
    buttons.push({
      title: 'Download',
      icon: faFileDownload,
      onClick: onDownloadClick,
    });
  }

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={<ImageItem image={image} containerRef={containerRef} hasEllipsis />}
      disabled={false}
      disableHover
    />
  );
};
