import { useNavigate } from 'react-router-dom';
import { loadTrack, PlayerType, useAudioController } from '../../../audio/AudioController';
import { togglePlayPause } from '../../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../../audio/AudioPosition';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

let firstClick = true;

export const useTrackFilePress = ({
  vaultId,
  trackId,
  isLocked,
  showUpsellInterstitial,
  folderId,
  artistHandle,
  trackLink,
}: {
  vaultId: string;
  trackId: string;
  isLocked: boolean;
  showUpsellInterstitial: boolean;
  folderId: string | null;
  artistHandle: string;
  trackLink?: string;
}) => {
  const navigate = useNavigate();
  const { activeTrackId, playing, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const { openBottomsheet } = useBottomsheetContainer();

  const onPlayClick = () => {
    if (isLocked) {
      navigate(artistNavigationPath(artistHandle, `/t/${trackLink ?? trackId}`));
      return;
    }

    const playSound = () => {
      PlayerType.current = 'vault';
      if (activeTrackId !== trackId) {
        loadTrack({
          trackId,
          vaultId,
          component: 'artist_page',
          folderId,
        });

        if (firstClick) {
          firstClick = false;
          setTimeout(() => {
            loadTrack({
              trackId,
              vaultId,
              component: 'artist_page',
              folderId,
            });
          }, 200);
        }
      } else {
        activeTrack != null &&
          trackEvent({
            type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
            properties: {
              trackId: activeTrack.id,
              vaultId: activeTrack.vault.id,
              artistId: activeTrack.vault.artist?.id,
              percentComplete,
              isPreview: !activeTrack.isFullVersionAvailable,
              component: 'artist_page',
            },
          });

        togglePlayPause();
      }
    };

    playSound();

    if (showUpsellInterstitial) {
      setTimeout(() => {
        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_track',
            onContinue: playSound,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_track',
          },
        });
      }, 2000);
    }
  };

  return {
    onPlayClick,
  };
};
