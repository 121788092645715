import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  AppleMusicPresaveDocument,
  ArtistAdminReceiptsDocument,
  ArtistEventByIdDocument,
  ArtistEventsDocument,
  GetCampaignAppleMusicPresaveStatusDocument,
  type MusicCampaignViewFragment,
  ThirdPartyPlatform,
  UserArtistReceiptsDocument,
} from '../../../graphql/generated';
import { useAppleMusicAuth } from '../../../hooks/appleMusic/useAppleMusicAuth';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

gql(/* GraphQL */ `
  query GetCampaignAppleMusicPresaveStatus(
    $input: QueryReleaseCampaignAppleMusicPresaveStatusInput!
  ) {
    releaseCampaignAppleMusicPresaveStatus(input: $input) {
      id
      status
    }
  }

  mutation AppleMusicPresave($input: MutationAppleMusicPresaveInput!) {
    appleMusicPresave(input: $input) {
      __typename
      ... on MutationAppleMusicPresaveSuccess {
        __typename
      }

      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [AppleMusicPresaveDocument],
  refetch: [
    GetCampaignAppleMusicPresaveStatusDocument,
    ArtistEventsDocument,
    ArtistAdminReceiptsDocument,
    UserArtistReceiptsDocument,
    ArtistEventByIdDocument,
  ],
});

export function useAppleMusicPresaveCampaign({
  campaign,
}: {
  campaign: MusicCampaignViewFragment | null | undefined;
}) {
  const [justConnected, setJustConnected] = useState(false);
  const { loggedInUser } = useAuthContext();

  const { openToast } = useToast();
  const navigate = useNavigate();

  const appleMusicAuth = useAppleMusicAuth();

  const isAppleMusicAccountLinked = !!loggedInUser?.appleMusicAuthConnections.length;
  const isAppleMusicAccountConnected =
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const alreadyConnected = isAppleMusicAccountLinked || isAppleMusicAccountConnected;

  const { data, isLoading: loadingPresaveStatus } = useQuery(
    GetCampaignAppleMusicPresaveStatusDocument,
    {
      staleTime: 0,
      variables: !!campaign?.id && {
        input: {
          releaseCampaignId: campaign.id,
          appleMusicAuthUserToken: appleMusicAuth.userToken,
        },
      },
      enabled: !!appleMusicAuth.userToken || !!loggedInUser?.id,
    },
  );

  const { mutateAsync: presaveAppleMusicMutation, isLoading: isPresaving } = useMutation(
    AppleMusicPresaveDocument,
    {
      retry: 5,
    },
  );

  const alreadyConnectedLogic = useCallback(
    async ({ campaign }: { campaign: MusicCampaignViewFragment }) => {
      try {
        const { data } = await presaveAppleMusicMutation(
          {
            input: {
              releaseCampaignId: campaign.id,
              appleMusicAuthUserToken: !!loggedInUser?.id ? undefined : appleMusicAuth.userToken,
            },
          },
          {
            onSuccess: () => {
              trackEvent({
                type: EVENTS.PRESAVE,
                properties: {
                  artistHandle: campaign.artist.linkValue,
                  campaignId: campaign.id,
                  platform: ThirdPartyPlatform.AppleMusic,
                },
              });

              navigate(
                artistNavigationPath(
                  campaign.artist.linkValue,
                  `/s/${campaign.linkValue}/claim`,
                  'step=claim&source=apple',
                ),
              );

              openToast({
                text: 'The song has been pre-saved.',
                variant: 'success',
                duration: 3000,
              });
            },
          },
        );

        if (data.appleMusicPresave.__typename === 'NotFoundError') {
          openToast({
            text: 'The drop campaign does not exist.',
            variant: 'error',
          });
        }
      } catch (error) {
        captureException(error, {
          tags: {
            feature: 'Presave Campaign',
          },
          extra: {
            artistHandle: campaign.artist.linkValue,
            campaignId: campaign.id,
            platform: ThirdPartyPlatform.AppleMusic,
          },
        });
        openToast({
          text: 'There was an error pre-saving the release',
          variant: 'error',
        });
      }
    },
    [appleMusicAuth.userToken, loggedInUser?.id, navigate, openToast, presaveAppleMusicMutation],
  );

  const presave = useCallback(async () => {
    if (!campaign) return;

    if (alreadyConnected) {
      alreadyConnectedLogic({ campaign });
      return;
    }

    if (appleMusicAuth.type === 'connect') {
      await appleMusicAuth.connect();
    } else if (appleMusicAuth.type === 'connected-without-api-confirmation') {
      await appleMusicAuth.connectWithApi();
    } else if (appleMusicAuth.type === 'link-apple-music') {
      await appleMusicAuth.link();
    }

    setJustConnected(true);
  }, [alreadyConnected, alreadyConnectedLogic, appleMusicAuth, campaign]);

  useEffect(() => {
    async function connect() {
      if (!campaign) return;
      await alreadyConnectedLogic({ campaign });
      setJustConnected(false);
    }
    if (justConnected && !!campaign && alreadyConnected) {
      connect();
    }
  }, [
    alreadyConnected,
    alreadyConnectedLogic,
    campaign,
    isAppleMusicAccountConnected,
    justConnected,
  ]);

  return {
    presave,
    isPresaving,
    presaveStatus: data?.data.releaseCampaignAppleMusicPresaveStatus?.status,
    loadingPresaveStatus,
  };
}
