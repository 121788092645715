import { createContext, useContext } from 'react';
import type { QueryObserverResult } from '@tanstack/react-query';
import { proxy } from 'valtio';
import type { ExecutionResultWithData } from '@soundxyz/graphql-react-query';
import type { AuthUserFragment, AuthUserQuery, VaultType } from '../graphql/generated';
import { LoginStatus } from '../types/authTypes';

export type AuthContextType = {
  loggedInUser?:
    | (AuthUserFragment & {
        artist?: {
          id: string;
          name: string;
          mainLinkValue: string;
          links: { value: string }[];
          mainVault: { id: string; type: VaultType };
          profileImage: {
            id: string;
            dominantColor?: string | null;
            artistSmallProfileImageUrl: string | null;
            artistFullProfileImageUrl: string | null;
          } | null;
        } | null;
      })
    | null;
  isArtist: boolean;
  isTestPhoneUser: boolean;
  isOfficialVaultUser: boolean;
  isEmailVerified: boolean;
  authError: Error | null;
  loginStatus: LoginStatus;
  initialLoginCheckLoading: boolean;
  refetchAuthUser: () =>
    | Promise<QueryObserverResult<ExecutionResultWithData<AuthUserQuery>>>
    | undefined;
};

const defaultAuthContext: AuthContextType = {
  loggedInUser: null,
  isArtist: false,
  isTestPhoneUser: false,
  isOfficialVaultUser: false,
  isEmailVerified: false,
  authError: null,
  loginStatus: LoginStatus.LOADING,
  initialLoginCheckLoading: true,
  refetchAuthUser() {
    throw Error('Auth not initialized');
  },
};

export const GlobalAuthContext = proxy(defaultAuthContext);

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, useAuthContext };
