import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { faMapPin } from '@soundxyz/font-awesome/pro-light-svg-icons';

import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from '../../components/common/Text';
import { UserRow } from '../../components/user/UserRow';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TierTypename } from '../../graphql/generated';
import {
  type MemberRowFragment,
  VaultSubscriptionStatus,
  VaultType,
} from '../../graphql/generated';
import { getFragment, UserRowFragmentDoc } from '../../graphql/generated';
import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import { useStableCallback } from '../../hooks/useStableCallback';
import { EVENTS } from '../../types/eventTypes';
import { formatDateString } from '../../utils/textUtils';

export function MemberRow({
  user,
  tier,
  status,
  createdAt,
  phone,
  email,
  isTrial,
  userLocation,
  artistMembership,
  withVaultTheme = false,
  className,
  artist,
  subText,
  source = 'subscribers',
  onSelect,
  receipts,
}: Omit<
  MemberRowFragment,
  | 'status'
  | 'tier'
  | 'artistDMChannelId'
  | 'joinedViaReferralCode'
  | 'vaultSubscriptionSourceText'
  | 'vaultSubscriptionSourceType'
> & {
  id: string;
  status?: VaultSubscriptionStatus;
  tier: { __typename: TierTypename } | null;
  withVaultTheme?: boolean;
  source: 'subscribers' | 'messages';
  className?: string;
  subText: 'joined-vault' | { custom: string };
  onSelect?: (userId: string) => void;
  receipts?: number;
}) {
  const { openBottomsheet } = useBottomsheetContainer();

  const isFreeOnly = artist.mainVault.type === VaultType.FreeOnly;

  const showRightComponent = isFreeOnly ? status === VaultSubscriptionStatus.Inactive : true;

  const { data: isBanned } = useSubscriberBanStatus({
    userId: user.id,
    artistId: artist.id,
  });

  const onClick = useStableCallback(() => {
    const { id, avatar, username, computedDisplayName } = getFragment(UserRowFragmentDoc, user);

    if (onSelect) {
      onSelect(id);
    } else {
      openBottomsheet({
        type: 'USER_PROFILE',
        userProfileBottomsheetProps: {
          vaultId: artist.mainVault.id,
          userId: id,
          vaultArtistId: artist?.id,
          avatarUrl: avatar.avatarImageUrl || avatar.cdnUrl,
          username,
          displayName: computedDisplayName || '@username',
          phone,
          email,
          showAdminOptions: true,
          activeSubscriptionTier: tier?.__typename ?? null,
          userLocation,
          withVaultTheme,
          receipts: artistMembership?.receipts,
          joinDate: createdAt,
        },
      });
    }
  });

  return (
    <UserRow
      key={user.id}
      className={twMerge(
        'mb-0 items-center gap-3 px-0 py-0 text-vault_text transition-all duration-200 ease-in hover:opacity-80',
        className,
      )}
      titleClassName={twMerge(withVaultTheme && 'text-vault_text')}
      user={user}
      phone={phone}
      isBanned={!!isBanned}
      badgeText={status === 'ACTIVE' && showRightComponent && isTrial ? 'Trial' : undefined}
      isPaidTier={
        status === 'ACTIVE' && showRightComponent && tier?.__typename !== TierTypename.FreeTier
      }
      subTextComponent={
        <Text
          className={twMerge(
            'mt-1 font-base text-[14px]/[20px] font-normal text-white opacity-60',
            withVaultTheme && 'text-vault_text',
          )}
        >
          {source === 'subscribers' && !!(userLocation?.country || userLocation?.region) && (
            <>
              <FontAwesomeIcon icon={faMapPin} className="mr-1" />
              {[userLocation.region, userLocation.countryIsoCode].filter(Boolean).join(', ')}
              <br />
            </>
          )}
          {source === 'messages' &&
            (getFragment(UserRowFragmentDoc, user).username ||
              getFragment(UserRowFragmentDoc, user).computedDisplayName) && (
              <>
                {`@${getFragment(UserRowFragmentDoc, user).username || getFragment(UserRowFragmentDoc, user).computedDisplayName}`}
                <br />
              </>
            )}
          {subText === 'joined-vault' ? (
            <>
              Joined{' '}
              {formatDateString({
                date: createdAt,
                format: 'month_day_year',
              })}{' '}
              {artistMembership?.receipts != null && artistMembership.receipts > 0 && (
                <>
                  {' • '}
                  <span className="inline-flex items-center gap-1">
                    {millify(artistMembership.receipts, { precision: 1, lowercase: true })}
                    <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
                  </span>
                </>
              )}
            </>
          ) : (
            subText.custom
          )}
        </Text>
      }
      profileImageClassName="w-[64px] h-[64px] rounded-full"
      withVaultTheme={withVaultTheme}
      onClick={onClick}
      event={{
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: { bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE, userId: user.id },
      }}
      receipts={receipts}
    />
  );
}

export const MemberRowSkeleton = () => {
  return (
    <div className="flex items-center justify-start gap-4">
      <div className="h-16 w-16 animate-pulse rounded-full bg-vault_text/10" />
      <div className="flex flex-col gap-1">
        <div className="h-4 w-24 animate-pulse rounded-md bg-vault_text/10" />
        <div className="h-3 w-20 animate-pulse rounded-md bg-vault_text/10" />
        <div className="h-3 w-36 animate-pulse rounded-md bg-vault_text/10" />
      </div>
    </div>
  );
};
