import { useParams } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import { getSubdomain, isValidSubdomain } from '../utils/subdomainUtils';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() || null : null;

const isSubdomain = !!subdomainArtistHandle;

export const CurrentArtistHandle = proxy<{
  artistHandle: string | null;
  isSubdomain: boolean;
}>({
  artistHandle: subdomainArtistHandle,
  isSubdomain,
});

export function useCurrentArtistHandle() {
  return useSnapshot(CurrentArtistHandle);
}

export function useArtistHandle() {
  const { artistHandle: paramArtistHandle } = useParams();
  const artistHandle = subdomainArtistHandle || paramArtistHandle;

  useSafeLayoutEffect(() => {
    const newArtistHandle = artistHandle || null;
    if (CurrentArtistHandle.artistHandle !== newArtistHandle) {
      CurrentArtistHandle.artistHandle = newArtistHandle;
    }
  }, [artistHandle]);

  return { artistHandle, isSubdomain };
}
