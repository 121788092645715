import { captureException } from '@sentry/react';
import {
  clearImages,
  setImages,
  updateImageLoadingStatus,
  updateImageWithUploadResult,
  updateProgress,
} from '../contexts/ImagesMessageAttachmentContext';
import { useToast } from '../contexts/ToastContext';
import { fileIdentifier, uploadMultipartFile } from '../utils/s3Utils';

export const useImageAttachments = ({ identifier }: { identifier: string }) => {
  const { openToast } = useToast();

  const saveImageAttachments = async (artistId: string | null | undefined, files: File[]) => {
    setImages({ identifier, files });

    const uploadPromises = files.map(async (file: File) => {
      try {
        const { mediaId, cdnUrl } = await uploadMultipartFile({
          file,
          mediaType: 'IMAGE',
          setProgress: bytes => {
            const progress = Math.floor((bytes / file.size) * 100);
            updateProgress({ identifier, imageIdentifier: fileIdentifier(file), progress });
          },
          artistId,
        });
        return { file, mediaId, cdnUrl };
      } catch (error) {
        captureException(error, {
          tags: {
            selectedFileName: file.name,
            selectedFileSize: file.size,
            selectedFileType: file.type,
            feature: 'useImageMessageAttachments',
          },
        });
        return { file, mediaId: null, cdnUrl: null };
      }
    });

    updateImageLoadingStatus({ identifier, isUploading: true });
    try {
      const results = await Promise.all(uploadPromises);
      results.forEach(({ file, mediaId, cdnUrl }) => {
        updateImageWithUploadResult({
          identifier,
          imageIdentifier: fileIdentifier(file),
          mediaId,
          cdnUrl,
        });
      });
    } catch (error) {
      captureException(error, {
        tags: {
          feature: 'saveImageAttachments',
        },
      });
      openToast({
        text: `There was an error uploading your image. ${error}`,
        variant: 'error',
      });
      clearImages({ identifier });
    } finally {
      updateImageLoadingStatus({ identifier, isUploading: false });
    }
  };

  return { saveImageAttachments };
};
