import { type ChangeEventHandler, useEffect, useState } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import * as Popover from '@radix-ui/react-popover';
import { motion } from 'framer-motion';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import msFn from 'ms';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { useQuery } from '../../graphql/client';
import { CountryCodeDocument } from '../../graphql/generated';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { sleep } from '../../utils/timerUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Item, Select } from '../forms/Select';

const countryCodeAbortController = new AbortController();

gql(/* GraphQL */ `
  query CountryCode {
    userCountryCode
  }
`);

export function PhoneAuthInput({ useVaultTheme = false }: { useVaultTheme?: boolean }) {
  const {
    countryCode,
    errorText,
    phone: phoneState,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
  } = useSignIn();

  const { data: userCountryCode, isLoading } = useQuery(CountryCodeDocument, {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 2,
    fetchOptions: {
      signal: countryCodeAbortController.signal,
    },
    enabled: !countryCodeAbortController.signal.aborted,
    select: data => data.data.userCountryCode,
  });

  const [editingPhone, setEditingPhone] = useState(false);

  // abort country code query after 3 seconds timeout
  useEffect(() => {
    if (userCountryCode !== undefined) return;

    const timeout = setTimeout(() => {
      countryCodeAbortController.abort();
    }, msFn('3 seconds'));

    return () => {
      clearTimeout(timeout);
    };
  }, [userCountryCode]);

  useEffect(() => {
    if (userCountryCode) {
      setCountryCode(userCountryCode);
    }
  }, [setCountryCode, userCountryCode]);

  const phone = editingPhone ? phoneState : '';

  useEffect(() => {
    setPhone('');
    setEditingPhone(true);
  }, [setPhone]);

  const hasError = errorText != null;

  const {
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  const selectedCountry = COUNTRY_CODES.find(({ code }) => code === countryCode);

  return (
    <View className="box-border w-full">
      <View className="box-content flex w-full flex-col rounded-l-full border border-solid border-black/5 py-4">
        <motion.div
          initial={{ opacity: 0, y: 28 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="relative box-border flex w-full items-center justify-center gap-2 overflow-hidden"
        >
          <Popover.Root open={hasError}>
            <Popover.Anchor asChild>
              <Select
                value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                onValueChange={setCountryCode}
                shouldHideIcon
                itemProps={{
                  className:
                    'bg-transparent text-vault_text hover:bg-transparent focus:bg-transparent',
                }}
                className={twMerge(
                  'bg-transparent p-0 pl-2 font-base !text-base-l font-normal text-vault_text hover:bg-transparent focus:bg-transparent',
                  selectedCountry && selectedCountry.dial_code.length >= 4
                    ? 'w-[5.4em]'
                    : selectedCountry && selectedCountry.dial_code.length === 3
                      ? 'w-[5.2em]'
                      : 'w-[5em]',
                  isLoading && 'cursor-not-allowed opacity-50',
                )}
                contentClassName="w-[5em] max-h-[400px] border border-solid border-vault_text/5 bg-vault_background/90 backdrop-blur-2xl"
                onOpenChange={open => {
                  setIsOpen(open);
                  setErrorText(null);
                }}
                disabled={status === 'sending-code' || isLoading}
              >
                {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                  <Item
                    key={code}
                    value={code}
                    className={twMerge(
                      'box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base !text-base-l font-normal hover:bg-transparent focus:bg-transparent',
                      useVaultTheme ? 'text-vault_text' : 'text-base_text',
                    )}
                    dropDownClassName="box-border justify-center overflow-x-clip rounded-sm bg-transparent pr-1 font-base !text-base-l font-normal hover:bg-transparent focus:bg-transparent"
                  >
                    <Text className="mx-2 text-[24px]">{flag}</Text>
                    <Text className="pr-2 font-base text-[16px]/[20px] font-semibold md2:pr-0">
                      {dial_code}
                    </Text>
                  </Item>
                ))}
              </Select>
            </Popover.Anchor>
            <Popover.Portal>
              <Popover.Content
                side="top"
                sideOffset={26}
                align="start"
                className="z-bottomsheet box-border flex max-w-[320px] flex-col self-center rounded-lg bg-destructive300 p-1 px-2 outline-none will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:w-full md2:max-w-none"
              >
                <Text className="p-3 text-left font-base !text-base-m font-normal text-white">
                  {errorText}
                </Text>

                <Popover.Arrow className="fill-destructive300" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>

          <input
            type="tel"
            value={phone}
            placeholder="Phone number"
            onChange={e => {
              onChange(e);
              setErrorText(null);
            }}
            disabled={status === 'sending-code'}
            className={twMerge(
              'w-full border-none bg-transparent font-base !text-base-l font-normal focus:border-none focus:outline-none',
              useVaultTheme ? 'placeholder:text-vault_text/50' : 'placeholder:text-base_text/50',
              hasError
                ? 'text-destructive300'
                : useVaultTheme
                  ? 'text-vault_text'
                  : 'text-base_text',
            )}
            onFocus={async e => {
              await sleep(100);
              e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          />
        </motion.div>
      </View>
    </View>
  );
}
