import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { faCalendar } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { InfoToggle } from '../../tamagui-components/structures/InfoToggle';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { EventHeader } from '../event/EventHeader';
import { RsvpEventImageUpload } from './RsvpEventImageUpload';
import { RSVP_MESSAGE_MAX_CHARS } from './schema';
import { generateThankYouMessage } from './store';
import { useRsvpEventForm } from './useRsvpEventForm';

export const SetupView = ({
  artistId,
  artistName,
  isEditMode,
}: {
  artistId: string | null | undefined;
  artistName: string | null | undefined;
  isEditMode: boolean;
}) => {
  const { setField, fields, errors, validateField } = useRsvpEventForm();
  const [thankyouMessageCharCounter, setThankyouMessageCharCounter] = useState(
    fields.message?.length || 0,
  );
  const [isThankYouMessageCustomized, setIsThankYouMessageCustomized] = useState(
    fields.isThankYouMessageCustomized,
  );

  useEffect(() => {
    if (!fields.thankyouMessage && !isEditMode) {
      setField('thankyouMessage', generateThankYouMessage(''));
    }
  }, [fields.thankyouMessage, isEditMode, setField]);

  const onTitleChange = (text: string) => {
    setField('title', text);
    validateField('title');

    if (!isThankYouMessageCustomized && fields.shouldSendThankyouMessage && !isEditMode) {
      const newThankYouMessage = generateThankYouMessage(text, artistName);
      setField('thankyouMessage', newThankYouMessage);
    }
  };

  return (
    <View className="mb-8 flex h-full w-full flex-col">
      <EventHeader
        icon={faCalendar}
        title="RSVP page"
        description="Create a landing page where fans can RSVP for your drop. These can be used for merch, events, music, giveaways, and more"
      />

      <RsvpEventImageUpload artistId={artistId} />
      <Text className="mt-8 font-title !text-title-s font-medium text-vault_text">Title</Text>
      <input
        className={clsx(
          'mt-2 rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
          errors.title ? 'border-destructive300' : 'border-vault_text/10 focus:border-vault_text',
        )}
        placeholder="The best merch drop of all time"
        value={fields.title}
        onChange={e => onTitleChange(e.target.value)}
      />
      {errors.title && <Text className="mt-2 text-base-s text-destructive300">{errors.title}</Text>}

      <Text className="mt-6 font-title !text-title-s font-medium text-vault_text">Description</Text>
      <textarea
        value={fields.description}
        name="description"
        aria-multiline="true"
        placeholder="Hoodies, tees, shorts, and more"
        className="no-scrollbar mb-4 mt-2 min-h-[120px] resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50 focus:border-vault_text"
        onChange={e => setField('description', e.target.value)}
      />

      <View className="item mt-2 flex w-full flex-row items-center justify-between gap-2">
        <InfoToggle
          label="RSVP thank you message"
          subText="Sent to members when they RSVP"
          checked={fields.shouldSendThankyouMessage}
          onCheckedChange={value => {
            setField('shouldSendThankyouMessage', value);
            validateField('thankyouMessage');
          }}
          useVaultTheme
        />
      </View>
      <textarea
        value={fields.thankyouMessage}
        name="thankyouMessage"
        aria-multiline="true"
        placeholder={`You are now RSVP'd to "The best merch drop of all time"${' by ' + artistName || ''}. We'll text you as soon as it's live.`}
        className={twMerge(
          'no-scrollbar mt-4 min-h-[120px] resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50',
          errors.thankyouMessage ? 'border-destructive300' : 'focus:border-vault_text',
          !fields.shouldSendThankyouMessage && 'opacity-50',
        )}
        onChange={e => {
          const text = e.target.value;
          setThankyouMessageCharCounter(text.length);
          setField('thankyouMessage', text);
          validateField('thankyouMessage');
          setIsThankYouMessageCustomized(true);
          setField('isThankYouMessageCustomized', true);
        }}
        onBlur={() => {
          validateField('thankyouMessage');
        }}
        disabled={!fields.shouldSendThankyouMessage}
      />
      <Text
        className={twMerge(
          'h-6 pt-2 font-base !text-base-s tabular-nums',
          !!errors.thankyouMessage ? 'text-destructive300' : 'text-vault_text/50',
        )}
      >
        {thankyouMessageCharCounter}/{RSVP_MESSAGE_MAX_CHARS}
      </Text>
    </View>
  );
};
