import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';

export const defaultVideoAttachment = {
  video: null,
  isUploading: false,
};

export const VideoAttachments = proxy<{
  [identifier: string]: {
    video: {
      identifier: string;
      progress: number;
      objectUrl: string;
      uploaded: boolean;
      mediaId: string | null;
      cdnUrl: string | null;
      type: MediaType;
    } | null;
    isUploading: boolean;
  };
}>({});

export function useVideoAttachment({ identifier }: { identifier: string }) {
  const videoAttachment = useSnapshot(VideoAttachments)[identifier] ?? defaultVideoAttachment;

  useEffect(() => {
    if (VideoAttachments[identifier] == null) {
      VideoAttachments[identifier] = defaultVideoAttachment;
    }
  }, [identifier]);

  return videoAttachment;
}

export function clearVideo({ identifier }: { identifier: string }) {
  if (VideoAttachments[identifier]?.video) {
    URL.revokeObjectURL(VideoAttachments[identifier].video.objectUrl);
  }

  VideoAttachments[identifier] = defaultVideoAttachment;
}

export function setVideo({ identifier, file }: { identifier: string; file: File }) {
  const currentAttachment = VideoAttachments[identifier] ?? defaultVideoAttachment;
  const video = {
    identifier: fileIdentifier(file),
    progress: 0,
    objectUrl: URL.createObjectURL(file),
    uploaded: false,
    mediaId: null,
    cdnUrl: null,
    type: MediaType.Video,
  };

  VideoAttachments[identifier] = {
    ...currentAttachment,
    video,
  };
}

export function updateProgress({ identifier, progress }: { identifier: string; progress: number }) {
  const currentAttachment = VideoAttachments[identifier] ?? defaultVideoAttachment;

  if (currentAttachment.video != null) {
    VideoAttachments[identifier] = {
      ...currentAttachment,
      video: { ...currentAttachment.video, progress },
    };
  }
}

export function updateVideoWithUploadResult({
  identifier,
  mediaId,
  cdnUrl,
}: {
  identifier: string;
  mediaId: string | null;
  cdnUrl: string | null;
}) {
  const currentAttachment = VideoAttachments[identifier] ?? defaultVideoAttachment;

  if (currentAttachment.video != null) {
    VideoAttachments[identifier] = {
      ...currentAttachment,
      video: { ...currentAttachment.video, mediaId, cdnUrl, uploaded: true },
    };
  }
}
