import { type FC, useMemo } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { compact } from 'lodash-es';
import { isIOS, isMobile } from 'react-device-detect';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import { faArrowUpFromBracket, faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faShuffle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faRepeat } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faShare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faClock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faBackwardStep,
  faChevronDown,
  faForwardStep,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faMusicNote } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { cycleRepeatMode, seek, useAudioController } from '../../audio/AudioController';
import { pause, togglePlayPause } from '../../audio/AudioEngineHTML5';
import { AudioMeta, RepeatMode, setActiveTrackId, setHideAudioPlayer } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { goToNextTrack, goToPrevTrack, toggleShuffleEnabled } from '../../audio/AudioQueue';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { prefetchQuery } from '../../graphql/client';
import {
  type ContentByIdQuery,
  TrackSnippetDetailsByIdDocument,
  VaultContentType,
  VaultType,
} from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import { useVaultContentActions } from '../../hooks/vault/useVaultContentActions';
import { MoveToContents } from '../../screens/vault/MoveToPage';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { ContentCommentPreviewRow } from '../comments/ContentCommentPreviewRow';
import { Marquee } from '../common/Marquee';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { SongArtwork } from '../track/SongArtwork';
import { useEditVaultContent } from '../upload/useContentUpload';
import { PlayButtonView } from './PlayButtonView';
import { Timeline } from './Timeline';

type Props = {
  closeFullScreen: () => void;
  content: TypeFromGraphQLUnion<NonNullable<ContentByIdQuery['vaultContentById']>, 'VaultTrack'>;
  isClosingFullscreen: boolean;
  withVaultTheme: boolean;
};

export const VaultFullscreenAudioPlayer: FC<Props> = ({
  content,
  closeFullScreen,
  isClosingFullscreen,
  withVaultTheme,
}) => {
  const {
    isNextTrackDisabled,
    playing,
    loading,
    loadingActiveTrack,
    disableNextPrev,
    shuffleEnabled,
    repeatMode,
    duration,
  } = useAudioController();
  const { position } = useAudioPosition();

  const {
    id: trackId,
    vault: { artist },
    title,
    linkValue,
    isFullVersionAvailable,
  } = content;

  const { loginStatus, loggedInUser } = useAuthContext();
  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const artistId = artist?.id;

  const ownedArtist = useOwnedArtist({ artistId });
  const inviteCode = !!ownedArtist ? null : loggedInUser?.inviteCode;

  const link = useMemo(() => {
    const trackLink = linkValue ? `/t/${linkValue}` : `/${trackId}`;

    return generateShareLink({
      artistLinkValue: artist?.linkValue,
      path: trackLink,
      inviteCode,
    });
  }, [linkValue, trackId, artist?.linkValue, inviteCode]);

  const artistName = artist?.name ?? 'vault';

  const showUpsellInterstitial =
    upsellInterstitials?.firstSnippetShare === false &&
    isMobile &&
    isIOS &&
    !wasShowedInSession &&
    loginStatus === LoginStatus.LOGGED_IN;

  return (
    <FullscreenAudioPlayer
      withVaultTheme={withVaultTheme}
      title={title}
      isFullVersionAvailable={isFullVersionAvailable}
      playing={playing}
      duration={duration}
      seek={seek}
      isClosingFullscreen={isClosingFullscreen}
      closeFullScreen={closeFullScreen}
      link={link}
      content={content}
      showUpsellInterstitial={showUpsellInterstitial}
      artistName={artistName}
      type="vault"
      isNextTrackDisabled={isNextTrackDisabled}
      loading={loading}
      loadingActiveTrack={loadingActiveTrack}
      disableNextPrev={disableNextPrev}
      shuffleEnabled={shuffleEnabled}
      repeatMode={repeatMode}
      togglePlayPause={togglePlayPause}
      position={position}
      toggleShuffleEnabled={toggleShuffleEnabled}
      cycleRepeatMode={cycleRepeatMode}
      goToPrevTrack={goToPrevTrack}
      goToNextTrack={goToNextTrack}
      isOwner={ownedArtist != null}
    />
  );
};

export const ControlButtons = ({
  large = false,
  className,
  withVaultTheme,
  isNextTrackDisabled,
  playing,
  loading,
  loadingActiveTrack,
  disableNextPrev,
  shuffleEnabled,
  repeatMode,
  togglePlayPause,
  toggleShuffleEnabled,
  cycleRepeatMode,
  goToPrevTrack,
  goToNextTrack,
}: {
  large?: boolean;
  className?: string;
  withVaultTheme: boolean;
  isNextTrackDisabled: boolean;
  playing: boolean;
  loading: boolean;
  loadingActiveTrack: boolean;
  disableNextPrev: boolean;
  shuffleEnabled: boolean;
  repeatMode: keyof typeof RepeatMode;
  togglePlayPause: () => void;
  toggleShuffleEnabled?: () => void;
  goToPrevTrack?: () => void;
  goToNextTrack?: () => void;
  cycleRepeatMode?: () => void;
}) => {
  const { playbackTrackIds, activeTrackId, track } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const isNextDisabled =
    isNextTrackDisabled ||
    loading ||
    loadingActiveTrack ||
    disableNextPrev ||
    playbackTrackIds.length < 2;

  const isPreviousDisabled =
    loading ||
    loadingActiveTrack ||
    disableNextPrev ||
    playbackTrackIds.length < 2 ||
    activeTrackId === playbackTrackIds[0];

  return (
    <View className={twMerge('flex w-full flex-row justify-between md2:justify-center', className)}>
      <View className="flex flex-row items-center justify-start md2:pr-8">
        <Button
          label=""
          iconOnly
          leadingIcon={faShuffle}
          className={twMerge(
            large ? 'text-[24px]' : 'text-[20px]',
            withVaultTheme
              ? shuffleEnabled
                ? 'text-vault_accent'
                : 'text-vault_text'
              : shuffleEnabled
                ? 'text-yellow100'
                : 'text-white',
            toggleShuffleEnabled == null && (withVaultTheme ? 'text-vault_text/30' : 'opacity-30'),
          )}
          onClick={toggleShuffleEnabled}
          disabled={toggleShuffleEnabled == null}
        />
      </View>

      {large ? (
        <>
          <View className="flex flex-row items-center justify-end">
            <Button
              label=""
              iconOnly
              leadingIcon={faBackwardStep}
              className={twMerge(
                'text-[32px]',
                withVaultTheme
                  ? isPreviousDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isPreviousDisabled
                    ? 'text-base300'
                    : 'text-white',
                isPreviousDisabled && 'opacity-50',
              )}
              onClick={goToPrevTrack}
              disabled={isPreviousDisabled || goToPrevTrack == null}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.PREV_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
          <View className="flex flex-row items-center justify-center">
            <PlayButtonView
              onClick={() => {
                track != null &&
                  trackEvent({
                    type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                    properties: {
                      trackId: track.id,
                      percentComplete,
                      artistId: track.vault.artist?.id,
                      vaultId: track.vault.id,
                      component: 'fullscreen_audio_player',
                      isPreview: !track.isFullVersionAvailable,
                    },
                  });
                togglePlayPause();
              }}
              isDisabled={loading || loadingActiveTrack}
              isPlaying={playing}
              className={twMerge(withVaultTheme && 'text-vault_text')}
              size={54}
            />
          </View>
          <View className="flex flex-row items-center justify-start">
            <Button
              label=""
              iconOnly
              leadingIcon={faForwardStep}
              className={twMerge(
                'text-[32px]',
                withVaultTheme
                  ? isNextDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isNextDisabled
                    ? 'text-base300'
                    : 'text-white',
                isNextDisabled && 'opacity-50',
              )}
              onClick={goToNextTrack}
              disabled={isNextDisabled || goToNextTrack == null}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.NEXT_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
        </>
      ) : (
        <View className="flex flex-row justify-center">
          <View className="flex flex-row items-center justify-end">
            <Button
              label=""
              iconOnly
              leadingIcon={faBackwardStep}
              className={twMerge(
                'text-[20px]',
                withVaultTheme
                  ? isPreviousDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isPreviousDisabled
                    ? 'text-base300'
                    : 'text-white',
                isPreviousDisabled && 'opacity-50',
              )}
              onClick={goToPrevTrack}
              disabled={isPreviousDisabled || goToPrevTrack == null}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.PREV_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
          <View className="mx-[28px] flex flex-row items-center justify-center">
            <PlayButtonView
              onClick={() => {
                track != null &&
                  trackEvent({
                    type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                    properties: {
                      trackId: track.id,
                      percentComplete,
                      artistId: track.vault.artist?.id,
                      vaultId: track.vault.id,
                      isPreview: !track.isFullVersionAvailable,
                      component: 'fullscreen_audio_player',
                    },
                  });
                togglePlayPause();
              }}
              isDisabled={loading || loadingActiveTrack}
              isPlaying={playing}
              size={34}
              className={twMerge(withVaultTheme && 'text-vault_text')}
            />
          </View>
          <View className="flex flex-row items-center justify-start">
            <Button
              label=""
              iconOnly
              leadingIcon={faForwardStep}
              className={twMerge(
                'text-[20px]',
                withVaultTheme
                  ? isNextDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isNextDisabled
                    ? 'text-base300'
                    : 'text-white',
                isNextDisabled && 'opacity-50',
              )}
              onClick={goToNextTrack}
              disabled={isNextDisabled || goToNextTrack == null}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.NEXT_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
        </View>
      )}

      <View className="relative flex flex-row items-center justify-start md2:pl-8">
        <Button
          label=""
          iconOnly
          leadingIcon={faRepeat}
          className={twMerge(
            'relative',
            large ? 'text-[24px]' : 'text-[20px]',
            withVaultTheme
              ? repeatMode === RepeatMode.NO_REPEAT
                ? 'text-vault_text'
                : 'text-vault_accent'
              : repeatMode === RepeatMode.NO_REPEAT
                ? 'text-white'
                : 'text-yellow100',
            cycleRepeatMode == null && (withVaultTheme ? 'text-vault_text/30' : 'opacity-30'),
          )}
          onClick={cycleRepeatMode}
          disabled={cycleRepeatMode == null}
        />
        {repeatMode === RepeatMode.REPEAT_ONE && (
          <View
            className={twMerge(
              'absolute flex items-center justify-center rounded-full text-center',
              withVaultTheme ? 'bg-vault_text' : 'bg-white',
              large
                ? ' right-[-2px] top-5 h-[13px] w-[13px]'
                : ' right-[-3px] top-[5px] h-[11px] w-[11px]',
            )}
          >
            <Text
              className={twMerge(
                'font-base text-[9px] font-bold',
                withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
              )}
            >
              1
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export const ShareButton: FC<{
  link: string;
  large?: boolean;
  className?: string;
  artistName: string;
  onClick?: () => void;
  withVaultTheme: boolean;
  icon?: IconProp;
}> = ({
  link,
  large = false,
  className,
  artistName,
  onClick,
  withVaultTheme,
  icon = faArrowUpFromBracket,
}) => {
  const { openBottomsheet } = useBottomsheetContainer();

  const handleClick = useMemo(() => {
    if (onClick) {
      return onClick;
    }

    return () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.SHARE,
        shared: {
          withVaultTheme,
        },
        shareBottomsheetProps: {
          link,
          artistName,
          withVaultTheme,
        },
      });
    };
  }, [artistName, link, onClick, openBottomsheet, withVaultTheme]);

  return (
    <Button
      iconOnly
      className={twMerge('text-white', large ? 'text-[24px]' : 'text-[16px]', className)}
      label=""
      leadingIcon={icon}
      onClick={handleClick}
    />
  );
};

export function FullscreenAudioPlayer({
  withVaultTheme,
  title,
  isFullVersionAvailable = true,
  playing,
  duration,
  seek,
  type,
  isClosingFullscreen,
  closeFullScreen,
  link,
  content,
  showUpsellInterstitial,
  artistName,
  isNextTrackDisabled,
  loading,
  loadingActiveTrack,
  disableNextPrev,
  shuffleEnabled,
  repeatMode,
  togglePlayPause,
  position,
  toggleShuffleEnabled,
  goToPrevTrack,
  goToNextTrack,
  cycleRepeatMode,
  isOwner,
}: {
  withVaultTheme: boolean;
  title: string;
  isFullVersionAvailable?: boolean;
  playing: boolean;
  duration: number;
  seek: (position: number) => void;
  isClosingFullscreen: boolean;
  closeFullScreen: () => void;
  artistName: string;
  isNextTrackDisabled: boolean;
  loading: boolean;
  loadingActiveTrack: boolean;
  disableNextPrev: boolean;
  shuffleEnabled: boolean;
  repeatMode: keyof typeof RepeatMode;
  togglePlayPause: () => void;
  position: number;
  toggleShuffleEnabled?: () => void;
  goToPrevTrack?: () => void;
  goToNextTrack?: () => void;
  cycleRepeatMode?: () => void;
} & (
  | {
      type: 'vault';
      link: string;
      content: TypeFromGraphQLUnion<
        NonNullable<ContentByIdQuery['vaultContentById']>,
        'VaultTrack'
      >;
      showUpsellInterstitial: boolean;
      isOwner: boolean;
    }
  | {
      type: 'spotify' | 'appleMusic';
      link?: undefined;
      content?: undefined;
      showUpsellInterstitial?: undefined;
      isOwner?: undefined;
    }
)) {
  const { editVaultContent } = useEditVaultContent();

  const handlers = useSwipeable({
    onSwipedDown: () => {
      type === 'vault' &&
        trackEvent({
          type: EVENTS.CLOSE_FULLSCREEN_AUDIO,
          properties: {
            trackId: content.id,
            trackTitle: title,
            vaultId: content.vault.id,
            artistId: content.vault.artist?.id ?? null,
            onSwipe: true,
          },
        });

      closeFullScreen();
    },
    trackMouse: true,
    swipeDuration: 200,
    delta: 200,
  });

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const thumbnailMediaUrl = content?.thumbnailMedia?.imageOptimizedUrl;

  const { removeContents } = useVaultContentActions();
  const { openToast } = useToast();

  const header = (
    <ViewHeader
      left={
        <Button
          label=""
          leadingIcon={faChevronDown}
          className={twMerge('text-[24px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
          onClick={closeFullScreen}
          event={
            type === 'vault'
              ? {
                  type: EVENTS.CLOSE_FULLSCREEN_AUDIO,
                  properties: {
                    trackId: content.id,
                    trackTitle: title,
                    vaultId: content.vault.id,
                    artistId: content.vault.artist?.id ?? null,
                  },
                }
              : undefined
          }
        />
      }
      right={
        type === 'vault' && (
          <ShareButton
            large
            link={link}
            icon={isOwner ? faEllipsis : faArrowUpFromBracket}
            withVaultTheme={withVaultTheme}
            artistName={content.vault.artist?.name ?? 'Unknown'}
            className={withVaultTheme ? 'text-vault_text' : undefined}
            onClick={() => {
              if (!content.vault.artist?.linkValue) return;

              if (!!link) {
                pause();

                if (showUpsellInterstitial) {
                  openBottomsheet({
                    type: 'SHARE_SNIPPET_INTERSTITIAL',
                    shareSnippetInterstitialBottomsheetProps: {
                      snippetVideo: link,
                      trackId: content.id,
                      onContinue: () => {
                        openBottomsheet({
                          type: BOTTOMSHEET_TYPES.SHARE_SNIPPET,
                          shared: {
                            withVaultTheme,
                          },
                          shareSnippetBottomsheetProps: {
                            artistName: content.vault.artist?.name ?? 'Unknown',
                            link,
                            withVaultTheme,
                          },
                        });
                      },
                    },
                  });

                  trackEvent({
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: 'SHARE_SNIPPET_INTERSTITIAL',
                      entity: 'song',
                      trackId: content.id,
                    },
                  });

                  return;
                }
                if (isOwner) {
                  openBottomsheet({
                    type: BOTTOMSHEET_TYPES.ACTION,
                    shared: {
                      withVaultTheme,
                    },
                    actionBottomsheetProps: {
                      withVaultTheme: true,
                      buttons: compact([
                        {
                          label: 'Share',
                          onClick: () => {
                            closeBottomsheet();
                          },
                          leadingIcon: faShare,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          href: artistNavigationPath(
                            content.vault.artist.linkValue,
                            `/t/${content.linkValue}/share`,
                          ),
                        },
                        {
                          label: 'Move to',
                          onClick: () => {
                            closeBottomsheet();
                            closeFullScreen();
                            MoveToContents.contentIds = [content.id];
                          },
                          leadingIcon: faFolder,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          href: artistNavigationPath(content.vault.artist.linkValue, '/move-to'),
                        },
                        {
                          label: 'Rearrange',
                          onClick: () => {
                            closeBottomsheet();
                            closeFullScreen();
                            MoveToContents.contentIds = [content.id];
                          },
                          leadingIcon: faArrowUpArrowDown,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          href: artistNavigationPath(
                            content.vault.artist.linkValue,
                            `/rearrange${content.parentVaultContentId != null ? `/${content.parentVaultContentId}` : ''}`,
                          ),
                        },
                        {
                          label: 'Edit Snippet',
                          onClick: () => {
                            closeFullScreen();
                            closeBottomsheet();

                            prefetchQuery(TrackSnippetDetailsByIdDocument, {
                              variables: {
                                vaultContentId: content.id,
                              },
                              staleTime: 0,
                            });

                            trackEvent({
                              type: EVENTS.EDIT_TRACK_SNIPPET,
                              properties: {
                                vaultContentId: content.id,
                                artistHandle: content.vault.artist?.linkValue ?? '',
                              },
                            });
                          },
                          leadingIcon: faClock,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          href: artistNavigationPath(
                            content.vault.artist.linkValue,
                            `/snippet/${content.id}`,
                          ),
                        },
                        {
                          label: 'Edit Track',
                          onClick: () => {
                            closeFullScreen();
                            closeBottomsheet();

                            trackEvent({
                              type: EVENTS.EDIT_TRACK,
                              properties: {
                                vaultContentId: content.id,
                                artistHandle: content.vault.artist?.linkValue ?? '',
                              },
                            });

                            if (content.vault.artist?.linkValue) {
                              const hasFreeAccess = content.featureAccess.some(
                                feature => feature.feature.__typename === 'FreeVaultContent',
                              );

                              editVaultContent({
                                artistHandle: content.vault.artist.linkValue,
                                withNavigation: false,
                                mediaId: content.id,
                                title,
                                contentType: VaultContentType.Track,
                                caption: content.caption ?? '',
                                downloadEnabled: content.downloadEnabled,
                                hasFreeAccess,
                                vaultContentId: content.id,
                                thumbnailId: content.thumbnailMedia?.id ?? null,
                                thumbnailUrl: content.thumbnailMedia?.imageOptimizedUrl || null,
                                duration,
                                normalizedPeaks: content.normalizedPeaks,
                                isSnippetOnly: !content.isFullVersionAvailable,
                              });
                            }
                          },
                          leadingIcon: faMusicNote,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          href: artistNavigationPath(
                            content.vault.artist.linkValue,
                            `/edit/${content.id}`,
                          ),
                        },
                        {
                          label: 'Delete',
                          onClick: async () => {
                            await removeContents({
                              contentIds: [content.id],
                              vaultId: content.vault.id,
                              onSuccess: () => {
                                if (AudioMeta.activeTrackId === content.id) {
                                  pause();
                                  setActiveTrackId(null);
                                  closeFullScreen();
                                  setHideAudioPlayer(true);
                                }

                                openToast({
                                  text: 'The track has been deleted',
                                  variant: 'success',
                                });
                              },
                            });

                            closeBottomsheet();
                          },
                          leadingIcon: faTrash,
                          type: 'secondary',
                          className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                          leadingIconClassName: 'text-[20px]',
                          requireConfirmation: true,
                          confirmType: 'delete',
                          confirmationSubText: 'Are you sure you want to delete this track?',
                        },
                      ]),
                    },
                  });
                } else {
                  openBottomsheet({
                    type: BOTTOMSHEET_TYPES.SHARE_SNIPPET,
                    shared: {
                      withVaultTheme,
                    },
                    shareSnippetBottomsheetProps: {
                      artistName,
                      link,
                      withVaultTheme,
                    },
                  });
                }
              } else {
                openBottomsheet({
                  type: BOTTOMSHEET_TYPES.SHARE,
                  shared: {
                    withVaultTheme,
                  },
                  shareBottomsheetProps: {
                    link,
                    artistName,
                    withVaultTheme,
                  },
                });
              }
            }}
          />
        )
      }
      className={twMerge(
        'sticky top-0 z-previewFrame w-full pt-5',
        !!thumbnailMediaUrl
          ? 'bg-transparent'
          : 'bg-gradient-to-b from-vault_background  via-vault_background/80 via-60% to-transparent',
      )}
    />
  );
  return (
    <View
      className={twMerge(
        'no-scrollbar fixed bottom-0 top-0 z-audioPlayerContainer flex h-full w-full origin-bottom flex-col overflow-x-clip overflow-y-scroll overscroll-none bg-vault_background sm:w-full',
        isClosingFullscreen ? 'animate-closeFullscreenPlayer' : 'animate-openFullscreenPlayer',
      )}
      swipeableHandlers={handlers}
    >
      {!!thumbnailMediaUrl && (
        <>
          <div
            className={twMerge('absolute w-full blur-2xl h-screen-safe ')}
            style={{
              backgroundImage: `url(${thumbnailMediaUrl})`,
            }}
          />
          <div className={twMerge('absolute w-full bg-vault_text_opposite/10 h-screen-safe')} />
        </>
      )}
      <div
        className={twMerge(
          'z-[1601] flex h-screen flex-col items-center overflow-y-scroll',
          !!thumbnailMediaUrl
            ? 'bg-gradient-to-b from-transparent to-vault_text_opposite to-40% '
            : 'bg-vault_background',
        )}
      >
        {header}

        <div
          className={twMerge(
            'mx-auto box-content flex aspect-square h-[calc(100%-98px)] max-h-[308px]  w-[calc(100%-98px)] max-w-[308px] flex-grow items-center justify-center xs:h-[calc(100%-32px)] xs:w-[calc(100%-32px)] ',
          )}
        >
          <SongArtwork
            thumbnailUrl={thumbnailMediaUrl}
            title={title}
            transparentBackground={!!thumbnailMediaUrl}
            iconSize={40}
            fullSize
            borderRadiusSize="none"
          />
        </div>
        <div
          className={twMerge(
            'mt-6 flex w-full flex-shrink-0 flex-col justify-between gap-5 bg-gradient-to-b from-transparent to-[15%]',
            !!thumbnailMediaUrl ? 'to-vault_text_opposite  ' : 'to-vault_background',
          )}
        >
          <View className={twMerge('box-border w-full to-50% px-4')}>
            <View className="mb-1 flex w-full flex-col">
              {!isFullVersionAvailable && (
                <Text
                  className={twMerge(
                    'mb-1 flex h-5 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 !text-base-xs',
                    withVaultTheme
                      ? 'border-vault_text text-vault_text'
                      : 'border-white text-white',
                  )}
                >
                  Snippet
                </Text>
              )}
              <View
                className={twMerge(
                  'flex w-full items-center font-title !text-title-xl font-medium',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                <Marquee className="z-above1 flex">{title}</Marquee>
              </View>
            </View>
            <Text
              className={twMerge(
                'z-above1  mb-2 w-full font-base !text-base-l font-medium',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
            >
              {artistName}
            </Text>
            <Timeline
              type="fullscreen"
              className="mb-[10px]"
              timestampPosition="bottom"
              timestampClassName={twMerge('mt-1.5', withVaultTheme ? 'text-vault_text' : undefined)}
              withVaultTheme={withVaultTheme}
              isPlaying={playing}
              duration={duration}
              seek={seek}
              position={position}
              event={
                type === 'vault'
                  ? {
                      trackId: content.id,
                      type: 'timeline',
                      artistId: content.vault.artist?.id ?? null,
                      vaultId: content.vault.id,
                    }
                  : undefined
              }
            />
            <ControlButtons
              withVaultTheme={withVaultTheme}
              large
              isNextTrackDisabled={isNextTrackDisabled}
              playing={playing}
              loading={loading}
              loadingActiveTrack={loadingActiveTrack}
              disableNextPrev={disableNextPrev}
              shuffleEnabled={shuffleEnabled}
              repeatMode={repeatMode}
              togglePlayPause={togglePlayPause}
              toggleShuffleEnabled={toggleShuffleEnabled}
              cycleRepeatMode={cycleRepeatMode}
              goToPrevTrack={goToPrevTrack}
              goToNextTrack={goToNextTrack}
            />
            {type === 'vault' &&
              content.vault.type !== VaultType.FreeOnly &&
              !isFullVersionAvailable &&
              content.vault.artist && (
                <SubscribeButton
                  label={`Unlock all songs for $${content.vault.price ?? DEFAULT_PRICE}/month`}
                  className={twMerge(
                    'mt-8 w-full !text-base-l',
                    withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : 'text-base900',
                  )}
                  linkValue={content.vault.artist.linkValue}
                  artistAvatarUrl={content.vault.artist.profileImage?.artistSmallProfileImageUrl}
                  price={content.vault.price ?? DEFAULT_PRICE}
                  vaultId={content.vault.id}
                  showBottomSheet={false}
                  component="audio_player"
                  onClick={closeFullScreen}
                />
              )}
          </View>
          {type === 'vault' && (
            <View className={twMerge('min-h-[160px] w-full flex-1 outline-none')}>
              <ContentCommentPreviewRow content={content} withVaultTheme={withVaultTheme} />
            </View>
          )}
        </div>
      </div>
    </View>
  );
}
