import { gql } from '@soundxyz/gql-string';
import {
  CHAT_ERROR_ACTIONS,
  CHAT_INFO_ACTIONS,
  ERROR_TYPE,
  type ErrorType,
  PILLARS,
} from '@soundxyz/vault-utils/constants';
import { useAuthContext } from '../../contexts/AuthContext';
import { type OpenToastProps, useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  CreateMessageReactionDocument,
  DeleteMessageDocument,
  DeleteMessageReactionDocument,
  type MutationCreateMessageReactionInput,
  type MutationDeleteMessageReactionInput,
  PinMessageDocument,
  RemovePinnedMessageDocument,
} from '../../graphql/generated';
import { useLogError } from '../logger/useLogError';
import { logInfo } from '../logger/useLogInfo';

gql(/* GraphQL */ `
  mutation DeleteMessage($input: MutationDeleteMessageInput!) {
    deleteMessage(input: $input) {
      __typename
      ... on MutationDeleteMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation PinMessage($input: MutationUpdatePinnedMessageInput!) {
    updatePinnedMessage(input: $input) {
      __typename
      ... on MutationUpdatePinnedMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation RemovePinnedMessage($input: MutationRemovePinnedMessageInput!) {
    removePinnedMessage(input: $input) {
      __typename
      ... on MutationRemovePinnedMessageSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation CreateMessageReaction($input: MutationCreateMessageReactionInput!) {
    createMessageReaction(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation DeleteMessageReaction($input: MutationDeleteMessageReactionInput!) {
    deleteMessageReaction(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const pillar = PILLARS.CHAT;
export const useMessageActions = ({ messageId }: { messageId: string }) => {
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();
  const logError = useLogError();
  const { mutateAsync: deleteMessageAsync, isLoading: isDeleting } = useMutation(
    DeleteMessageDocument,
    {
      retry: 3,
    },
  );

  const deleteMessage = async ({
    onSuccess,
    onError,
    toast = 'This message could not be deleted at this time. Try again.',
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    toast?: string | OpenToastProps;
  }) => {
    try {
      const { data } = await deleteMessageAsync({
        input: {
          messageId,
        },
      });

      if (data.deleteMessage.__typename === 'MutationDeleteMessageSuccess') {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_DELETE,
          message: 'Message deleted',
          pillar,
          data: {
            messageId,
            userId: loggedInUser?.id,
          },
        });
        onSuccess?.();
        return;
      }

      onError?.();
      let errType: ErrorType = ERROR_TYPE.UNKNOWN;

      switch (data.deleteMessage.__typename) {
        case 'NotAuthorizedError':
          errType = ERROR_TYPE.AUTH_ERROR;
          break;
        case 'NotFoundError':
          errType = ERROR_TYPE.NOT_FOUND_ERROR;
          break;
        default:
          break;
      }

      logError({
        error: new Error('Error deleting message'),
        action: CHAT_ERROR_ACTIONS.CHAT_DELETE_ERROR,
        message: 'Error deleting message',
        level: 'warning',
        errorType: errType,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    } catch (error) {
      onError?.();
      logError({
        error: error,
        action: CHAT_ERROR_ACTIONS.CHAT_DELETE_ERROR,
        message: 'Error deleting message',
        level: 'error',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    }
  };

  const { mutateAsync: pinMessageAsync, isLoading: isPinning } = useMutation(PinMessageDocument, {
    retry: 3,
  });

  const pinMessage = async ({
    onSuccess,
    onError,
    toast = 'This message could not be pinned at this time. Try again.',
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    toast?: string | OpenToastProps;
  }) => {
    try {
      const { data } = await pinMessageAsync({
        input: {
          messageId,
        },
      });

      if (data.updatePinnedMessage.__typename === 'MutationUpdatePinnedMessageSuccess') {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_PIN,
          message: 'Message pinned',
          pillar,
          data: {
            messageId,
            userId: loggedInUser?.id,
          },
        });
        onSuccess?.();
        return;
      }

      onError?.();
      let errType: ErrorType = ERROR_TYPE.UNKNOWN;

      switch (data.updatePinnedMessage.__typename) {
        case 'NotAuthorizedError':
          errType = ERROR_TYPE.AUTH_ERROR;
          break;
        case 'NotFoundError':
          errType = ERROR_TYPE.NOT_FOUND_ERROR;
          break;
        default:
          break;
      }

      logError({
        error: new Error('Error pinning message'),
        action: CHAT_ERROR_ACTIONS.CHAT_DELETE_ERROR,
        message: 'Error pinning message',
        level: 'warning',
        errorType: errType,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    } catch (error) {
      onError?.();
      logError({
        error: error,
        action: CHAT_ERROR_ACTIONS.CHAT_PIN_ERROR,
        message: 'Error pinning message',
        level: 'error',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    }
  };

  const { mutateAsync: removePinnedMessageAsync, isLoading: isRemovingPin } = useMutation(
    RemovePinnedMessageDocument,
    {
      retry: 3,
    },
  );

  const removePinnedMessage = async ({
    onSuccess,
    onError,
    toast = 'This message could not be unpinned at this time. Try again.',
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    toast?: string | OpenToastProps;
  }) => {
    try {
      const { data } = await removePinnedMessageAsync({
        input: {
          messageId,
        },
      });

      if (data.removePinnedMessage.__typename === 'MutationRemovePinnedMessageSuccess') {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_PIN,
          message: 'Message unpinned',
          pillar,
          data: {
            messageId,
            userId: loggedInUser?.id,
          },
        });
        onSuccess?.();
        return;
      }

      onError?.();
      let errType: ErrorType = ERROR_TYPE.UNKNOWN;

      switch (data.removePinnedMessage.__typename) {
        case 'NotAuthorizedError':
          errType = ERROR_TYPE.AUTH_ERROR;
          break;
        case 'NotFoundError':
          errType = ERROR_TYPE.NOT_FOUND_ERROR;
          break;
        default:
          break;
      }

      logError({
        error: new Error('Error unpinning message'),
        action: CHAT_ERROR_ACTIONS.CHAT_DELETE_ERROR,
        message: 'Error unpinning message',
        level: 'warning',
        errorType: errType,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    } catch (error) {
      onError?.();
      logError({
        error: error,
        action: CHAT_ERROR_ACTIONS.CHAT_PIN_ERROR,
        message: 'Error unpinning message',
        level: 'error',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    }
  };

  const { mutateAsync: createMessageReactionAsync, isLoading: isCreatingReaction } = useMutation(
    CreateMessageReactionDocument,
    {
      retry: 3,
    },
  );

  const createMessageReaction = async ({
    onSuccess,
    onError,
    input,
    toast = 'This message could not be reacted to at this time. Try again.',
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    input: MutationCreateMessageReactionInput;
    toast?: string | OpenToastProps;
  }) => {
    try {
      const { data } = await createMessageReactionAsync({
        input: {
          ...input,
        },
      });

      if (data.createMessageReaction.__typename === 'MutationCreateMessageReactionSuccess') {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_REACT,
          message: 'Message reaction added successfully',
          pillar,
          data: {
            userId: loggedInUser?.id,
            ...input,
          },
        });
        onSuccess?.();
        return;
      }

      onError?.();
      let errType: ErrorType = ERROR_TYPE.UNKNOWN;

      switch (data.createMessageReaction.__typename) {
        case 'ValidationError':
          errType = ERROR_TYPE.VALIDATION_ERROR;
          break;
        case 'NotFoundError':
          errType = ERROR_TYPE.NOT_FOUND_ERROR;
          break;
        default:
          break;
      }

      logError({
        error: new Error('Error reacting to chat message'),
        action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
        message: 'Error reacting to chat message',
        level: 'warning',
        errorType: errType,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    } catch (error) {
      onError?.();
      logError({
        error,
        action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
        message: 'Error reacting to chat message',
        level: 'error',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    }
  };

  const { mutateAsync: deleteMessageReactionAsync, isLoading: isDeletingReaction } = useMutation(
    DeleteMessageReactionDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.deleteMessageReaction.__typename === 'MutationDeleteMessageReactionSuccess') {
          logInfo({
            action: CHAT_INFO_ACTIONS.CHAT_REACT,
            message: 'Message reaction removed successfully',
            pillar,
            data: {
              messageId,
              userId: loggedInUser?.id,
            },
          });
          return;
        }
        let errType: ErrorType = ERROR_TYPE.UNKNOWN;
        switch (data.data.deleteMessageReaction.__typename) {
          case 'NotFoundError':
            errType = ERROR_TYPE.NOT_FOUND_ERROR;
            break;
          case 'NotAuthorizedError':
            errType = ERROR_TYPE.AUTH_ERROR;
            break;
          default:
            break;
        }

        logError({
          error: new Error('Error removed reaction to chat message'),
          action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
          message: 'Error removing reaction from chat message',
          level: 'warning',
          errorType: errType,
          pillar,
          indexedTags: {
            userId: loggedInUser?.id,
            messageId,
          },
          openToast,
          toast: 'This message reaction could not be removed at this time. Try again.',
        });
      },
      onError: error => {
        logError({
          error,
          action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
          message: 'Error removing reaction from chat message',
          level: 'error',
          errorType: ERROR_TYPE.UNKNOWN,
          pillar,
          indexedTags: {
            userId: loggedInUser?.id,
            messageId,
          },
          openToast,
          toast: 'This message reaction could not be removed at this time. Try again.',
        });
      },
    },
  );

  const deleteMessageReaction = async ({
    onSuccess,
    onError,
    input,
    toast = 'This message reaction could not be removed at this time. Try again.',
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    input: MutationDeleteMessageReactionInput;
    toast?: string | OpenToastProps;
  }) => {
    try {
      const { data } = await deleteMessageReactionAsync({
        input: {
          ...input,
        },
      });

      if (data.deleteMessageReaction.__typename === 'MutationDeleteMessageReactionSuccess') {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_REACT,
          message: 'Message reaction removed successfully',
          pillar,
          data: {
            userId: loggedInUser?.id,
            ...input,
          },
        });
        onSuccess?.();
        return;
      }

      onError?.();
      let errType: ErrorType = ERROR_TYPE.UNKNOWN;

      switch (data.deleteMessageReaction.__typename) {
        case 'NotAuthorizedError':
          errType = ERROR_TYPE.AUTH_ERROR;
          break;
        case 'NotFoundError':
          errType = ERROR_TYPE.NOT_FOUND_ERROR;
          break;
        default:
          break;
      }

      logError({
        error: new Error('Error removed reaction to chat message'),
        action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
        message: 'Error removing reaction from chat message',
        level: 'warning',
        errorType: errType,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    } catch (error) {
      onError?.();
      logError({
        error,
        action: CHAT_ERROR_ACTIONS.CHAT_REACT_ERROR,
        message: 'Error removing reaction from chat message',
        level: 'error',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          messageId,
        },
        openToast,
        toast,
      });
    }
  };

  return {
    deleteMessage,
    isDeleting,
    pinMessage,
    isPinning,
    removePinnedMessage,
    isRemovingPin,
    createMessageReaction,
    isCreatingReaction,
    deleteMessageReaction,
    isDeletingReaction,
  };
};
