import { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { z } from 'zod';
import { gql } from '@soundxyz/gql-string';
import { fetchGQL, GraphQLReactQuery, useQuery } from '../graphql/client';
import { AuthorizedUserProfilesDocument, CheckInDocument } from '../graphql/generated';
import { logger } from '../utils/logger';
import { PersistenceStorage } from '../utils/storeUtils';

export const UserProfileState = PersistenceStorage({
  schema: z.object({
    currentPrivyId: z.string().nullable(),
    store: z.record(
      z.string(),
      z.object({
        userId: z.string().uuid(),
      }),
    ),
  }),
  key: 'userProfile',
  eager: true,
});

export async function setUserProfileId({ userId }: { userId: string }) {
  if (!UserProfileState.state.value?.currentPrivyId) {
    logger.error(
      {
        userId,
      },
      'Unexpected trying to set user profile id: No current privy id found',
    );
    return;
  }

  if (
    UserProfileState.state.value?.store[UserProfileState.state.value?.currentPrivyId]?.userId ===
    userId
  ) {
    return;
  }

  await UserProfileState.produceExistingState(
    draft => {
      const currentPrivyId = draft.currentPrivyId;
      if (!currentPrivyId || draft.store[currentPrivyId]?.userId === userId) return;

      draft.store[currentPrivyId] = {
        userId,
      };
    },
    {
      currentPrivyId: null,
      store: {},
    },
  );

  GraphQLReactQuery.removeQueries({
    predicate(query) {
      return !query.isActive();
    },
  });

  GraphQLReactQuery.refetchQueries({
    predicate(query) {
      return query.isActive();
    },
  });

  fetchGQL(CheckInDocument, {
    keepalive: true,
  });
}

export function useChosenUserProfile() {
  const { user: privyUser } = usePrivy();

  const privyId = privyUser?.id ?? null;

  const state = UserProfileState.useStore().value;

  const currentPrivyId = state?.currentPrivyId;

  useEffect(() => {
    if (!privyId) return;

    if (currentPrivyId === privyId || UserProfileState.state.value?.currentPrivyId === privyId) {
      return;
    }

    UserProfileState.produceExistingState(
      draft => {
        if (draft.currentPrivyId === privyId) return;
        draft.currentPrivyId = privyId;
      },
      {
        currentPrivyId: privyId,
        store: {},
      },
    );
  }, [privyId, currentPrivyId]);

  const userProfileId = state?.store?.[privyId ?? '_']?.userId ?? null;

  return {
    userProfileId,
  };
}

gql(/* GraphQL */ `
  query AuthorizedUserProfiles {
    authorizedUserProfiles {
      user {
        __typename
        id
        username
        displayName
        computedDisplayName
        avatar {
          id
          cdnUrl
          userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        ... on PrivateUser {
          phone
        }
        artist {
          id
          name
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            artistFullProfileImageUrl: imageOptimizedUrl
            dominantColor
          }
          mainVaultId
          mainVault {
            id
            type
          }
          userId
          linkValue
          links {
            value
          }
          ...ArtistProfileImage
        }
        ...UserProfileImage
      }
    }
  }
`);

export function useAuthorizedUserProfiles() {
  const { user: privyUser } = usePrivy();

  const { data: authorizedUserProfiles } = useQuery(AuthorizedUserProfilesDocument, {
    staleTime: '10 seconds',
    enabled: privyUser != null,
    filterQueryKey: {
      privyId: privyUser?.id,
    },
    select: data => data.data.authorizedUserProfiles,
  });

  return { authorizedUserProfiles };
}
