import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../../../components/buttons/BackButton';
import { PaginatedView } from '../../../components/common/PaginatedView';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { EventItem, EventItemSkeleton } from '../../../components/membership/EventItem';
import { EmptyEvents } from '../../../components/membership/shared';
import { useQuery } from '../../../graphql/client';
import type { ArtistEventFragmentDoc } from '../../../graphql/generated';
import { EventsSummaryDocument, type FragmentType } from '../../../graphql/generated';
import { useArtistEvents } from '../../../hooks/membership/useArtistEvents';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useBetterGate } from '../../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { artistNavigationPath } from '../../../utils/navigationUtils';

gql(/* GraphQL */ `
  query EventsSummary($artistHandle: String!) {
    myEventsSummary(artistHandle: $artistHandle) {
      artistMembershipReceiptsCount
      newSubscriptionsFromEventsCount
      totalActionsCount
    }
  }
`);

export const EventsPage = () => {
  useVaultTheme();
  const { artistHandle } = useArtistHandle();
  const {
    artistEvents,
    hasNextPage,
    isLoadingNewPage,
    isInitialLoading,
    isError,
    loadMoreNextPage,
    refetch,
  } = useArtistEvents({
    artistHandle,
    source: 'events_page',
  });

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwner = !!ownedArtist;

  const {
    data: eventsSummary,
    isLoading: isLoadingEventsSummary,
    refetch: refetchEventsSummary,
  } = useQuery(EventsSummaryDocument, {
    variables: !!artistHandle && {
      artistHandle,
    },
    staleTime: 0,
    select: data => data.data.myEventsSummary,
    enabled: isOwner,
  });

  const exposeCampaignDataEnabled = useBetterGate('EXPOSE_CAMPAIGN_DATA') === 'enabled';

  const renderItems = useStableCallback(
    (
      eventData: FragmentType<ArtistEventFragmentDoc> & {
        id: string;
        createdAt: string | null;
      },
      index: number,
    ) => {
      return (
        <View
          key={`${index}-${eventData.id}-events_page`}
          className="flex h-20 w-full items-center "
        >
          <EventItem eventData={eventData} />
        </View>
      );
    },
  );

  const LoadingFooter = useCallback(() => {
    if (!isLoadingNewPage) return null;

    return (
      <View className="flex w-full items-center justify-center py-5">
        <FontAwesomeIcon
          icon={faSpinner}
          size="2xl"
          className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
        />
      </View>
    );
  }, [isLoadingNewPage]);

  const Header = useCallback(() => {
    if (!isOwner) return null;

    if (isLoadingEventsSummary || !eventsSummary) {
      return (
        <View className="flex w-full flex-row items-center justify-start gap-2 pb-8 pt-6">
          <LoadingSkeleton
            className="flex h-[96.5px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text/10"
            withVaultTheme
          />
          <LoadingSkeleton
            className="flex h-[96.5px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text/10"
            withVaultTheme
          />
        </View>
      );
    }

    const receiptCount = eventsSummary.artistMembershipReceiptsCount;
    const newSubscriptionCount = eventsSummary.newSubscriptionsFromEventsCount;
    const totalActionsCount = eventsSummary.totalActionsCount;

    return (
      <View className="flex w-full flex-row items-center justify-start gap-2 pb-8 pt-6">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(exposeCampaignDataEnabled ? totalActionsCount : receiptCount, {
              lowercase: true,
            })}
          </Text>
          <Text className="text-vault_text">Total receipts</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total signups</Text>
        </View>
      </View>
    );
  }, [eventsSummary, exposeCampaignDataEnabled, isLoadingEventsSummary, isOwner]);

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      withBottomNavigator={false}
      headerLeft={
        <BackButton
          className="text-vault_text"
          href={artistNavigationPath(artistHandle, '/drops')}
        />
      }
      hasChatReadAccess={false}
      isHeaderTransparent={false}
      headerClassName="bg-vault_background md2:bg-vault_text/3"
      contentClassName="md2:bg-vault_text/3"
      vaultId=""
      messageChannelId=""
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Drops</Text>
      }
      extend
    >
      <View className="box-border flex h-full w-full flex-col">
        <PaginatedView
          fetchNextPage={loadMoreNextPage}
          hasNextPage={hasNextPage}
          className="flex w-full flex-col pb-20"
          isFetchingNextPage={isLoadingNewPage}
        >
          <Header />
          {isInitialLoading ? (
            <View className="flex w-full flex-col">
              {Array.from({ length: 8 }).map((_, index) => (
                <EventItemSkeleton key={index} />
              ))}
            </View>
          ) : isError ? (
            <View className="flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center gap-6">
              <ErrorView
                className="flex-grow"
                onRetryClick={() => {
                  refetch();
                  refetchEventsSummary();
                }}
                loggingType="events_page"
                withVaultTheme={false}
              />
            </View>
          ) : artistEvents.length === 0 ? (
            <EmptyEvents artistHandle={artistHandle} />
          ) : (
            <>
              {artistEvents.map((event, index) => renderItems(event, index))}
              <LoadingFooter />
            </>
          )}
        </PaginatedView>
      </View>
    </DefaultLayout>
  );
};
