import { useEffect, useMemo, useRef } from 'react';
import { useLoginWithEmail, useLoginWithSms } from '@privy-io/react-auth';
import parsePhoneNumberFromString, {
  type CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js/min';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { COUNTRY_CODES } from '../constants/phoneConstants';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../screens/auth/store';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { wrapSendCode } from '../utils/privy';
import { validateEmail } from '../utils/stringUtils';
import { useLogError } from './logger/useLogError';
import { useCurrentArtistHandle } from './useArtistHandle';
import { useStableCallback } from './useStableCallback';

export function useSignIn() {
  const signInState = SignInStore.useStore({
    sync: true,
  }).value;
  const logError = useLogError();
  const phone = signInState?.phone ?? '';
  const email = signInState?.email ?? '';
  const emailStatus = signInState?.emailStatus ?? 'initial';
  const authMethod = signInState?.authMethod ?? 'sms';

  const setPhone = SignInStore.extra.setPhone;
  const setEmail = SignInStore.extra.setEmail;
  const setAuthMethod = SignInStore.extra.setAuthMethod;
  const setEmailStatus = SignInStore.extra.setEmailStatus;

  const {
    sendCode: sendSmsCode,
    state: { status: smsStatus },
  } = useLoginWithSms();
  const { sendCode: sendEmailCode } = useLoginWithEmail();

  const sendEmailCodeWithStatusUpdate = useStableCallback(async ({ email }: { email: string }) => {
    setEmailStatus('sending-code');
    await sendEmailCode({ email })
      .then(() => {
        setEmailStatus('awaiting-code-input');
      })
      .catch(e => {
        setEmailStatus('error');
        throw e;
      });
  });

  const status = authMethod === 'sms' ? smsStatus : emailStatus;

  const sendCode = authMethod === 'sms' ? sendSmsCode : sendEmailCodeWithStatusUpdate;

  const countryCode = signInState?.countryCode ?? 'US';

  const lastCountryCode = signInState?.lastCountryCode;
  const setCountryCode = SignInStore.extra.setCountryCode;

  const errorText = signInState?.errorText;
  const setErrorText = SignInStore.extra.setErrorText;

  const isOpen = signInState?.isOpen;
  const setIsOpen = SignInStore.extra.setIsOpen;

  useEffect(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const { artistHandle } = useCurrentArtistHandle();

  useEffect(() => {
    if (lastCountryCode != null) return;

    const intlCountry = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];

    if (!intlCountry) return;

    const countryCode = COUNTRY_CODES.find(({ code }) => code === intlCountry)?.code ?? 'US';

    setCountryCode(countryCode);
  }, [lastCountryCode, setCountryCode]);

  useEffect(() => {
    if (countryCode === SignInStore.state.value?.lastCountryCode) return;

    SignInStore.produceExistingState(
      draft => {
        draft.lastCountryCode = countryCode;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
        lastActiveEmail: null,
        lastCountryCode: countryCode,
      },
    );
  }, [countryCode]);

  const validPhoneNumber = useMemo(() => {
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode as CountryCode)?.number;

    if (
      phoneNumber == null ||
      (!isValidPhoneNumber(phone, countryCode as CountryCode) && phoneNumber !== '+15555555555')
    ) {
      return null;
    }

    return phoneNumber;
  }, [phone, countryCode]);

  const validEmail = useMemo(() => {
    if (!validateEmail(email)) return null;

    return email;
  }, [email]);

  const codeRenabled =
    signInState?.codeRenabled[authMethod === 'sms' ? validPhoneNumber ?? '_' : validEmail ?? '_'] ??
    1;

  const lastActivePhoneNumber = signInState?.lastActivePhoneNumber;
  const lastActiveEmail = signInState?.lastActiveEmail;

  const attemptNumber = useRef(0);

  const onSignInClick = useStableCallback(
    async ({ onSuccess, source }: { onSuccess?: () => void; source: string }) => {
      attemptNumber.current++;

      if (!validPhoneNumber && authMethod === 'sms') {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid phone number',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      } else if (!validEmail && authMethod === 'email') {
        trackEvent({
          type: EVENTS.SET_EMAIL_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid email',
            email,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid email'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid email',
          pillar: PILLARS.AUTH,
          indexedTags: {
            email,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This email cannot be used for verification');
        return;
      }

      try {
        setErrorText(null);
        await wrapSendCode(() =>
          sendCode({
            phoneNumber: authMethod === 'sms' ? validPhoneNumber ?? '' : '',
            email: authMethod === 'email' ? validEmail ?? '' : '',
          }),
        );

        trackEvent({
          type: EVENTS.RECEIVE_VERIFICATION_CODE,
          properties: {
            artistHandle,
            phone_number: validPhoneNumber,
            email: validEmail,
            auth_method: authMethod,
          },
        });
        attemptNumber.current = 0;

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

        SignInStore.produceExistingState(
          draft => {
            draft.lastActivePhoneNumber = validPhoneNumber;
            draft.lastActiveEmail = validEmail;
            draft.codeRenabled[authMethod === 'sms' ? validPhoneNumber ?? '_' : validEmail ?? '_'] =
              codeRenabled;
            draft.phone = '';
            draft.email = '';
          },
          {
            codeRenabled: {
              [authMethod === 'sms' ? validPhoneNumber ?? '_' : validEmail ?? '_']: codeRenabled,
            },
            lastActivePhoneNumber: validPhoneNumber,
            lastActiveEmail: validEmail,
          },
        );

        onSuccess?.();
      } catch (e) {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'get_verification_code_error',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });

        setErrorText('We encountered an error sending your verification code');
        return;
      }
    },
  );

  const onResendCodeClick = useStableCallback(
    async ({
      source,
      onSuccess,
      codeSentDisabledSecondsRemaining = 0,
    }: {
      codeSentDisabledSecondsRemaining: number;
      source: string;
      onSuccess?: () => void;
    }) => {
      trackEvent({
        type: EVENTS.CLICK_RESEND_VERIFICATION_CODE,
        properties: {
          artistHandle,
          phone_number: phone,
          email: email,
          auth_method: authMethod,
        },
      });
      if (!validPhoneNumber && authMethod === 'sms') {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid phone number',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      } else if (!validEmail && authMethod === 'email') {
        trackEvent({
          type: EVENTS.SET_EMAIL_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid email',
            email,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid email'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid email',
          pillar: PILLARS.AUTH,
          indexedTags: {
            email,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This email cannot be used for verification');
        return;
      }

      if (codeSentDisabledSecondsRemaining > 0) return;

      trackEvent({
        type: EVENTS.CLICK_RESEND_VERIFICATION_CODE,
        properties: {
          artistHandle,
          phone_number: validPhoneNumber,
          email: validEmail,
          auth_method: authMethod,
        },
      });

      try {
        await wrapSendCode(() =>
          sendCode({
            phoneNumber:
              authMethod === 'sms' ? (validPhoneNumber != null ? `+${validPhoneNumber}` : '') : '',
            email: authMethod === 'email' ? validEmail ?? '' : '',
          }),
        );

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;
        SignInStore.produceExistingState(
          draft => {
            draft.codeRenabled[authMethod === 'sms' ? validPhoneNumber ?? '_' : validEmail ?? '_'] =
              codeRenabled;
            draft.lastActivePhoneNumber = validPhoneNumber;
            draft.lastActiveEmail = validEmail;
          },
          {
            codeRenabled: {
              [authMethod === 'sms' ? validPhoneNumber ?? '_' : validEmail ?? '_']: codeRenabled,
            },
            lastActivePhoneNumber: validPhoneNumber,
            lastActiveEmail: validEmail,
          },
        );
        onSuccess?.();
      } catch (e) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error re-sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('We encountered an error re-sending your verification code');

        return;
      }
    },
  );

  return {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
    onResendCodeClick,
    authMethod,
    email,
    validEmail,
    lastActiveEmail,
    setEmail,
    setAuthMethod,
    status,
    setEmailStatus,
  };
}
