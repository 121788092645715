import { useMemo } from 'react';
import { useAuthContext } from '../contexts/AuthContext';

export function useOwnedArtist({
  artistHandle,
  artistId,
  artistMainVaultId,
  userProfileId,
}:
  | {
      artistHandle: string | null | undefined;
      artistId?: string | null | undefined;
      artistMainVaultId?: string | null | undefined;
      userProfileId?: string | null | undefined;
    }
  | {
      artistId: string | null | undefined;
      artistHandle?: string | null | undefined;
      artistMainVaultId?: string | null | undefined;
      userProfileId?: string | null | undefined;
    }
  | {
      artistMainVaultId: string | null | undefined;
      artistHandle?: string | null | undefined;
      artistId?: string | null | undefined;
      userProfileId?: string | null | undefined;
    }
  | {
      userProfileId: string | null | undefined;
      artistHandle?: string | null | undefined;
      artistId?: string | null | undefined;
      artistMainVaultId?: string | null | undefined;
    }) {
  const { loggedInUser } = useAuthContext();

  return useMemo(() => {
    if (!loggedInUser?.artist) {
      return null;
    }

    if (
      (userProfileId && loggedInUser.id === userProfileId) ||
      (artistId && loggedInUser.artist.id === artistId) ||
      (artistMainVaultId && loggedInUser.artist.mainVault.id === artistMainVaultId) ||
      (artistHandle && loggedInUser.artist.links.some(link => link.value === artistHandle))
    ) {
      return loggedInUser.artist;
    }

    return null;
  }, [
    artistHandle,
    artistId,
    artistMainVaultId,
    loggedInUser?.artist,
    loggedInUser?.id,
    userProfileId,
  ]);
}
