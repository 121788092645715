import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { setUserProfileId } from '../../../store/userProfile';
import { artistNavigationPath, isOnArtistSubdomain } from '../../../utils/navigationUtils';
import { isValidSubdomain } from '../../../utils/subdomainUtils';
import { UserRow } from '../../structures/UserRow';

type AccountSwitcherUserRowProps = {
  artistHandle: string | null | undefined;
  avatarUrl: string | null | undefined;
  isArtist: boolean;
  isSelected: boolean;
  name: string;
  userId: string;
  username: string | undefined;
  withVaultTheme: boolean;
};

export function AccountSwitcherUserRow({
  artistHandle,
  avatarUrl,
  isArtist,
  isSelected,
  name,
  userId,
  username,
  withVaultTheme,
}: AccountSwitcherUserRowProps) {
  const navigate = useNavigate();
  const { closeBottomsheet } = useBottomsheetContainer();

  const handleUserProfileChange = async () => {
    await setUserProfileId({ userId });

    // Here we want to do the following, if I'm switching to an artist account, go to the vault.
    // If I'm switching to a non-artist account, do nothing.
    if (isArtist && artistHandle != null) {
      if (isValidSubdomain() && !isOnArtistSubdomain(artistHandle)) {
        window.location.href = artistNavigationPath(artistHandle, '/vault');
      } else {
        navigate(artistNavigationPath(artistHandle, '/vault'));
      }
    }
    closeBottomsheet();
  };

  return (
    <button
      className={twMerge(
        'w-full cursor-pointer appearance-none rounded-[48px] border-none bg-transparent p-2 outline-none hover:opacity-80',
        isSelected && (withVaultTheme ? 'bg-vault_text/10' : 'bg-white/10'),
      )}
      onClick={handleUserProfileChange}
      disabled={isSelected}
    >
      <UserRow
        profileImageUrl={avatarUrl}
        name={name}
        subtitle={username}
        verifiedBadge={isArtist}
        managedVault={false}
        rightComponent={
          <SelectedIndicator isSelected={isSelected} withVaultTheme={withVaultTheme} />
        }
        useVaultTheme={withVaultTheme}
      />
    </button>
  );
}

function SelectedIndicator({
  isSelected,
  withVaultTheme,
}: {
  isSelected: boolean;
  withVaultTheme: boolean;
}) {
  if (!isSelected) {
    return null;
  }

  return (
    <FontAwesomeIcon
      icon={faCheck}
      className={twMerge(
        'mr-2 text-[16px]',
        withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
      )}
    />
  );
}
