import React, { useRef, useState } from 'react';
import { Navigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { ArtistProfileImage } from '../../../components/artist/ArtistProfileImage';
import { MenuButton } from '../../../components/buttons/MenuButton';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { ArtistMembershipView } from '../../../components/membership/ArtistMembershipView';
import { ROUTES } from '../../../constants/routeConstants';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { BlurContainer, getTopNavButtonClassName } from '../TopNavButton';

export const ArtistDashboard = () => {
  useVaultTheme();

  const { isDesktop } = useWindow();
  const { artistHandle } = useArtistHandle();
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const ownedArtist = useOwnedArtist({ artistHandle });

  const vaultId = ownedArtist?.mainVault.id;

  if (artistHandle) {
    return <Navigate to={artistNavigationPath(artistHandle, '/vault')} />;
  }

  if (artistHandle == null || !ownedArtist) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      ref={scrollRef}
      headerLeftClassName="md2:col-span-3"
      headerLeft={
        isDesktop ? (
          <View className="flex w-full flex-row items-center justify-start gap-2">
            <ArtistProfileImage
              profileImageUrl={ownedArtist?.profileImage?.artistSmallProfileImageUrl}
              className="h-12 w-12 rounded-full border-2 border-solid border-vault_background"
            />
            <Text className="line-clamp-1 font-title text-[18px]/[22px] font-medium text-vault_text">
              Hi, {ownedArtist?.name || ownedArtist?.mainLinkValue || 'Unnamed'}
            </Text>
          </View>
        ) : isScrollingDown ? (
          <MenuButton className="h-12" withVaultTheme />
        ) : (
          <BlurContainer>
            <MenuButton className={getTopNavButtonClassName(isScrollingDown)} withVaultTheme />
          </BlurContainer>
        )
      }
      headerRight={null}
      messageChannelId={undefined}
      withBottomNavigator
      vaultId={vaultId}
      hasChatReadAccess={false}
      shouldSkipMargin={false}
      showBorder
      showRoundedTop
      withVaultTheme
      isHeaderTransparent={!isScrollingDown}
      contentClassName="md2:bg-vault_text/3"
      headerCenterClassName={isScrollingDown ? 'px-6 md2:px-0' : 'md2:col-span-1'}
      headerClassName={twMerge(
        'pt-[18px] md2:pt-6',
        isScrollingDown && !isDesktop
          ? 'border-0 border-b border-solid border-vault_text/5 bg-vault_background'
          : 'bg-transparent md2:bg-vault_text/3',
      )}
    >
      <ArtistMembershipView setIsScrollingDown={setIsScrollingDown} scrollRef={scrollRef} />
    </DefaultLayout>
  );
};
