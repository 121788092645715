import { useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { isUUID4, type Maybe } from '@soundxyz/utils';
import { useAuthContext } from '../contexts/AuthContext';
import { type ExecutionResultWithData, useInfiniteQuery, useQuery } from '../graphql/client';
import type { VaultContentByFolderPositionQuery, VaultContentType } from '../graphql/generated';
import {
  getFragment,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentByIdDocument,
  type VaultContentByIdQuery,
  VaultContentBySlugDocument,
  type VaultContentBySlugQuery,
  VaultContentLandingPageFragmentDoc,
  VaultContentPaginationDocument,
} from '../graphql/generated';
import { useStableCallback } from './useStableCallback';
import { parseActiveSubscriptionFeatures } from './useTierFeatures';

gql(/* GraphQL */ `
  query VaultContentPagination(
    $vaultId: UUID!
    $after: String
    $first: Int
    $contentType: VaultContentType
  ) {
    vaultContent(vaultId: $vaultId, after: $after, first: $first, contentType: $contentType) {
      edges {
        node {
          __typename
          id
          title
          createdAt
          vault {
            id
            activeSubscription {
              id
              ...ActiveSubscriptionFeatures
            }
          }
          featureAccess {
            feature {
              __typename
            }
          }
          ... on VaultTrack {
            duration
          }
          ...TrackRowInfo
          ...TrackFileInfo
          ...VaultItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  query VaultContentByFolder(
    $vaultId: UUID!
    $parentVaultContentId: UUID
    $after: String
    $first: Int!
  ) {
    vaultContentBySortTime(
      vaultId: $vaultId
      parentVaultContentId: $parentVaultContentId
      after: $after
      first: $first
    ) {
      edges {
        cursor
        node {
          __typename
          id
          title
          createdAt
          featureAccess {
            feature {
              __typename
            }
          }

          isFullVersionAvailable
          ...TrackFileInfo

          ...VaultItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment VaultContentLandingPage on VaultContent {
    __typename
    id
    isFullVersionAvailable
    title
    linkValue
    createdAt
    vault {
      id
      type
      messageChannelId
      artist: artistProfile {
        id
        name
        linkValue
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
        ...JoinArtist
      }
      price
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      isUserArtistAdmin
    }
    parentVaultContentId

    ... on VaultImage {
      id
      blurredMediaUrl
      fileTypeExtension
    }

    ... on VaultVideo {
      id
      blurredMediaUrl
      duration
      fileTypeExtension
    }

    ... on VaultTrack {
      __typename
      id
      normalizedPeaks

      thumbnailMedia {
        id
        cdnUrl
      }

      createdAt

      duration

      title
      linkValue
      caption
      snippetVideo {
        id
        url: cdnUrl
      }
      freeTierSnippet {
        id
        startAt
        endAt
      }
      vault {
        id
        type
        messageChannelId
        artist: artistProfile {
          id
          name
          linkValue
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
          }
        }
        price

        isUserArtistAdmin
      }
      commentMessageCount
      commentPinnedMessage {
        id
        content
        ...ContentCommentRow
      }
      commentCaptionMessage {
        id
        content
        ...ContentCommentRow
      }
      featureAccess {
        feature {
          __typename
        }
      }
      isFullVersionAvailable
      parentVaultContentId
    }
  }

  query VaultContentById($vaultContentId: UUID!, $asArtistId: UUID) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      ...VaultContentLandingPage
    }
  }

  query VaultContentBySlug($artistHandle: String!, $vaultContentSlug: String!, $asArtistId: UUID) {
    vaultContentBySlug(artistHandle: $artistHandle, slug: $vaultContentSlug) {
      __typename
      ... on QueryVaultContentBySlugSuccess {
        data {
          __typename
          id
          ...VaultContentLandingPage
        }
      }
      ... on Error {
        message
      }
    }
  }

  query VaultContentByFolderPosition(
    $vaultId: UUID!
    $parentVaultContentId: UUID
    $after: String
    $first: Int!
  ) {
    vaultContentByFolderPosition(
      vaultId: $vaultId
      parentVaultContentId: $parentVaultContentId
      after: $after
      first: $first
    ) {
      edges {
        cursor
        node {
          __typename
          id
          title
          createdAt
          featureAccess {
            feature {
              __typename
            }
          }
          folderPosition
          isFullVersionAvailable
          ...TrackFileInfo
          ...VaultItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export function useVaultContentPagination({
  vaultId,
  pageSize = 15,
  isOwner,
  enabled = true,
  contentType,
}: {
  vaultId: Maybe<string>;
  pageSize?: number;
  isOwner: boolean;
  enabled?: boolean;
  contentType?: VaultContentType;
}) {
  return useInfiniteQuery(VaultContentPaginationDocument, {
    filterQueryKey: {
      vaultId,
    },
    enabled,
    list({ vaultContent }) {
      const enabledFeatures = parseActiveSubscriptionFeatures({
        subscription: vaultContent.edges[0]?.node.vault.activeSubscription,
        isOwner,
      });
      return vaultContent.edges.map(({ node }) => ({
        ...node,
        isPlayable: node.featureAccess.some(({ feature: { __typename } }) => {
          switch (__typename) {
            case 'FreeVaultContent':
              return enabledFeatures?.enabledFeatures.FreeVaultContent;
            case 'PaidVaultContent':
              return enabledFeatures?.enabledFeatures.PaidVaultContent;
            default:
              return false;
          }
        }),
      }));
    },
    staleTime: 0,
    uniq({ id }) {
      return id;
    },
    variables:
      !!vaultId &&
      (({ pageParam }) => {
        return {
          vaultId,
          after: pageParam?.after || null,
          first: pageSize,
          contentType,
        };
      }),
    getNextPageParam({ data }) {
      return (
        data.vaultContent.pageInfo.hasNextPage && {
          after: data.vaultContent.pageInfo.endCursor,
          first: pageSize,
        }
      );
    },
  });
}

export function useUpdatedVaultContentPagination({
  vaultId,
  pageSize = 6,
  folderId,
  enabled = true,
}: {
  vaultId: Maybe<string>;
  pageSize?: number;
  folderId?: Maybe<string>;
  enabled?: boolean;
}) {
  return useInfiniteQuery(VaultContentByFolderDocument, {
    staleTime: 0,

    filterQueryKey: { folderId, vaultId },

    getNextPageParam: ({ data }) => {
      return (
        data.vaultContentBySortTime.pageInfo.hasNextPage && {
          after: data.vaultContentBySortTime.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!vaultId &&
      (({ pageParam }) => {
        return {
          vaultId,
          parentVaultContentId: folderId ?? null,
          after: pageParam?.after ?? null,
          first: pageSize,
        };
      }),
    list: data => data.vaultContentBySortTime.edges.map(edge => edge.node),
    uniq: ({ id }) => id,
    enabled: enabled && !!vaultId,
  });
}

export function useVaultContentByIdOrSlug({
  vaultContentId,
  artistHandle,
  vaultContentSlug,
}: {
  vaultContentId?: Maybe<string>;
  artistHandle?: Maybe<string>;
  vaultContentSlug?: Maybe<string>;
}) {
  const { loggedInUser } = useAuthContext();
  const useSlugQuery = !!vaultContentSlug && !!artistHandle;

  return useQuery(useSlugQuery ? VaultContentBySlugDocument : VaultContentByIdDocument, {
    enabled: !!vaultContentId || (!!vaultContentSlug && !!artistHandle),
    filterQueryKey: {
      loggedInUserId: loggedInUser?.id,
    },
    variables: useSlugQuery
      ? {
          artistHandle,
          vaultContentSlug,
        }
      : !!vaultContentId &&
        isUUID4(vaultContentId) && {
          vaultContentId,
        },
    staleTime: 0,
    select: useStableCallback(
      (data: ExecutionResultWithData<VaultContentBySlugQuery | VaultContentByIdQuery>) => {
        if (!data.data) return null;

        if ('vaultContentBySlug' in data.data) {
          if (data.data.vaultContentBySlug?.__typename !== 'QueryVaultContentBySlugSuccess')
            return null;

          return getFragment(VaultContentLandingPageFragmentDoc, data.data.vaultContentBySlug.data);
        }

        if ('vaultContentById' in data.data && data.data.vaultContentById) {
          return getFragment(VaultContentLandingPageFragmentDoc, data.data.vaultContentById);
        }

        return null;
      },
    ),
  });
}

const customPagesStore = proxy<
  Record<string, Record<string, ExecutionResultWithData<VaultContentByFolderPositionQuery>>>
>({});

function useVaultContentCustomPages({
  vaultId,
  folderId,
}: {
  vaultId: Maybe<string>;
  folderId: string | null;
}): ExecutionResultWithData<VaultContentByFolderPositionQuery>[] {
  const customPagesSnapshot =
    useSnapshot(customPagesStore)[vaultId != null ? `${vaultId}-${folderId ?? 'root'}` : '_'];

  return useMemo(
    () => Object.values(customPagesSnapshot ?? {}),
    [customPagesSnapshot],
  ) as ExecutionResultWithData<VaultContentByFolderPositionQuery>[];
}

export function clearVaultContentCustomPage({
  vaultId,
  folderId,
}: {
  vaultId: string;
  folderId: string | null;
}) {
  customPagesStore[`${vaultId}-${folderId ?? 'root'}`] = {};
}
export function useVaultContentByFolderPosition({
  vaultId,
  pageSize = 6,
  folderId,
  enabled = true,
}: {
  vaultId: Maybe<string>;
  pageSize?: number;
  folderId?: Maybe<string>;
  enabled?: boolean;
}) {
  const customPages = useVaultContentCustomPages({
    vaultId,
    folderId: folderId ?? null,
  });

  return useInfiniteQuery(VaultContentByFolderPositionDocument, {
    staleTime: 0,
    filterQueryKey: { folderId, vaultId },
    getNextPageParam: ({ data }) => {
      return (
        data.vaultContentByFolderPosition.pageInfo.hasNextPage && {
          after: data.vaultContentByFolderPosition.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!vaultId &&
      (({ pageParam }) => {
        return {
          vaultId,
          parentVaultContentId: folderId ?? null,
          after: pageParam?.after ?? null,
          first: pageSize,
        };
      }),
    list: useStableCallback((data: VaultContentByFolderPositionQuery) =>
      data.vaultContentByFolderPosition.edges.map(edge => edge.node),
    ),
    uniq: ({ id }) => id,
    order: [e => e.folderPosition, 'asc'],
    enabled: !!vaultId && enabled,
    customPages,
  });
}
