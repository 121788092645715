import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useVaultTheme, VaultThemeStore } from '../../hooks/useVaultTheme';
import { Image } from '../common/Image';

export const SongArtwork = ({
  size = 240,
  iconSize = 24,
  thumbnailUrl,
  title,
  transparentBackground = false,
  fullSize = false,
  borderRadiusSize = 'md',
}: {
  size?: number;
  iconSize?: number;
  thumbnailUrl?: string | null;
  title: string;
  transparentBackground?: boolean;
  fullSize?: boolean;
  borderRadiusSize?: 'sm' | 'md' | 'none';
} & (
  | {
      size?: number;
      fullSize?: never;
    }
  | {
      fullSize: boolean;
      size?: never;
    }
)) => {
  useVaultTheme();
  const vaultTheme = useSnapshot(VaultThemeStore);
  const sizing = fullSize ? 'w-full h-full' : `w-[${size}px] h-[${size}px]`;

  const radiusStyle =
    borderRadiusSize === 'sm'
      ? 'rounded-sm'
      : borderRadiusSize === 'md'
        ? 'rounded-md'
        : 'rounded-[unset]';
  if (thumbnailUrl) {
    return (
      <Image
        src={thumbnailUrl}
        alt={title || 'Song artwork'}
        className={twMerge(
          'flex aspect-square flex-shrink items-center justify-center object-cover',
          sizing,
          transparentBackground ? 'bg-transparent' : 'bg-vault_background',
          radiusStyle,
        )}
      />
    );
  }
  return (
    <div
      className={twMerge(
        'flex aspect-square flex-shrink items-center justify-center rounded-md',
        sizing,
        transparentBackground ? 'bg-transparent' : 'bg-vault_background',
        radiusStyle,
      )}
    >
      <div
        className={twMerge(
          'relative flex aspect-square items-center justify-center bg-vault_text/10',
          'h-full w-full',
          radiusStyle,
        )}
      >
        <div
          className={twMerge(
            vaultTheme.mode === 'light'
              ? 'bg-new-file-background-black'
              : 'bg-new-file-background-white',
            'h-[50%] w-[40%] bg-cover bg-no-repeat',
          )}
        />
        <FontAwesomeIcon
          icon={faWaveformLines}
          fontSize={iconSize}
          className="absolute w-[38%] text-vault_text/50"
        />
      </div>
    </div>
  );
};
