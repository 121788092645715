import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'date-fns';
import { compact } from 'lodash-es';
import millify from 'millify';
import msFn from 'ms';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faCalendar, faCancel, faSend } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEdit, faPaperclip } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES, US_STATE_CODES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { type ExecutionResultWithData, useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  CancelScheduledAnnouncementDocument,
  GetPaginatedVaultAnnouncementsDocument,
  HideScheduledAnnouncementDocument,
  type MassMessageInsightsQuery,
  MmInsightsFragmentDoc,
  ShowScheduledAnnouncementDocument,
} from '../../graphql/generated';
import {
  type FragmentType,
  getFragment,
  MassMessageInsightsDocument,
} from '../../graphql/generated';
import { useTextBlastPreviewMessage } from '../../hooks/message/useTextBlastPreviewMessage';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { getManyFromList } from '../../utils/arrayUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { pluralizeText } from '../../utils/textUtils';
import { BackButton } from '../buttons/BackButton';
import { DropdownEllipsis } from '../common/Dropdown';
import { LinkifyText } from '../common/LinkifyText';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { FullPageLoading } from '../views/FullPageLoading';
import { FilterPill } from './FilterPill';

gql(/* GraphQL */ `
  fragment MMInsights on Announcement {
    id
    content
    locationTargetV2 {
      type
      __typename
      ... on AnnouncementTargetingLocationV2Geo {
        displayName
        latitude
        longitude
        radiusMiles
        isoCountry
      }
      ... on AnnouncementTargetingLocationV2Region {
        isoCountry
        isoUsStateCode
      }
    }
    featureAccess {
      feature {
        __typename
      }
    }
    artistEventTarget {
      id
      title
    }
    scheduledAt
    memberCount
    linkClickRate
    smsDeliveryStatus
    rsvpEvent {
      id
      title
    }
    vaultContentAttachment {
      id
      linkValue
    }
    messageAttachments {
      id
      mediaType
    }
  }

  query MassMessageInsights($announcementId: UUID!) {
    announcementById(announcementId: $announcementId) {
      __typename
      id
      smsDeliveryStatus
      scheduledAt
      ...MMInsights
    }
  }

  mutation CancelScheduledAnnouncement($input: MutationDeleteAnnouncementInput!) {
    deleteAnnouncement(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation HideScheduledAnnouncement($input: MutationHideAnnouncementInput!) {
    hideAnnouncement(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation ShowScheduledAnnouncement($input: MutationShowAnnouncementInput!) {
    showAnnouncement(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [
    CancelScheduledAnnouncementDocument,
    HideScheduledAnnouncementDocument,
    ShowScheduledAnnouncementDocument,
  ],
  refetch: [GetPaginatedVaultAnnouncementsDocument],
});

const fiveMinutes = msFn('5 minutes');
const thirtySeconds = msFn('30 seconds');

export function MassMessageInsightsView({
  messageId,
  isOwnPage,
  artistHandle,
  prefetchedMessage,
}: {
  messageId: string;
  isOwnPage: boolean;
  artistHandle: string;
  prefetchedMessage?: FragmentType<MmInsightsFragmentDoc> | null;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: announcement,
    isLoading: isLoadingAnnouncement,
    isError,
    refetch,
  } = useQuery(MassMessageInsightsDocument, {
    variables: { announcementId: messageId },
    staleTime: 0,
    select: data => data.data.announcementById,
    refetchInterval: useStableCallback(
      (
        data:
          | ExecutionResultWithData<MassMessageInsightsQuery>['data']['announcementById']
          | undefined,
      ) => {
        const message = data;
        if (message == null) return false;

        const now = new Date();

        const scheduledAt = new Date(message.scheduledAt);
        const diff = Math.abs(scheduledAt.getTime() - now.getTime());
        return diff < fiveMinutes ? thirtySeconds : false;
      },
    ),
  });

  const message = announcement ?? prefetchedMessage;

  if (isLoading || (isLoadingAnnouncement && prefetchedMessage == null)) {
    return (
      <View className="flex h-[100vh] w-full flex-col items-center justify-start">
        {isOwnPage && (
          <View className="box-border w-full px-4 pt-5">
            <BackButton withVaultTheme className="text-[24px]/[24px]" />
          </View>
        )}
        <FullPageLoading withVaultTheme className="bg-transparent" />
      </View>
    );
  }

  if (message == null || isError) {
    return (
      <View className="flex h-[100vh] w-full flex-col items-center justify-start">
        {isOwnPage && (
          <View className="box-border w-full px-4 pt-5">
            <BackButton withVaultTheme className="text-[24px]/[24px]" />
          </View>
        )}
        <ErrorView withVaultTheme onRetryClick={refetch} className="h-full w-full md2:w-full" />
      </View>
    );
  }

  return (
    <MassMessageInsights
      announcement={message}
      isOwnPage={isOwnPage}
      artistHandle={artistHandle}
      setIsLoading={setIsLoading}
    />
  );
}

export function MassMessageInsights({
  announcement,
  isOwnPage,
  artistHandle,
  setIsLoading,
}: {
  announcement: FragmentType<MmInsightsFragmentDoc>;
  isOwnPage: boolean;
  artistHandle: string;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const navigate = useNavigate();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: cancelMessage } = useMutation(CancelScheduledAnnouncementDocument, {});

  const {
    id,
    content,
    locationTargetV2,
    featureAccess,
    scheduledAt,
    artistEventTarget,
    memberCount,
    linkClickRate,
    rsvpEvent,
    vaultContentAttachment,
    messageAttachments,
  } = getFragment(MmInsightsFragmentDoc, announcement);

  const isRsvpMessage = rsvpEvent != null;

  const tierFilterPillText = useMemo(() => {
    if (featureAccess.length === 2) {
      return 'Everyone';
    }
    if (featureAccess.some(feature => feature.feature.__typename === 'FreeScheduledEvent')) {
      return 'Free members';
    }
    return 'Paid members';
  }, [featureAccess]);

  const countries = useMemo(() => {
    return getManyFromList(locationTargetV2 ?? [], location => {
      if (location.__typename === 'AnnouncementTargetingLocationV2Region') {
        const { isoCountry, isoUsStateCode } = location;
        const country = COUNTRY_CODES.find(({ code }) => code === isoCountry);
        const state =
          isoUsStateCode != null
            ? US_STATE_CODES.find(({ code }) => code === isoUsStateCode)
            : null;

        if (country == null || (state == null && isoUsStateCode != null)) {
          return null;
        }

        return {
          locationName: `${state?.name != null ? `${state.name}, ` : ''}${country?.name}`,
          code: `${isoCountry}${isoUsStateCode != null ? `, ${isoUsStateCode}` : ''}`,
        };
      }

      return {
        locationName: `${location.displayName} +${location.radiusMiles} ${pluralizeText({
          count: location.radiusMiles,
          text: 'mile',
        })}`,
        code: `${location.radiusMiles}-${location.latitude}-${location.longitude}`,
      };
    });
  }, [locationTargetV2]);

  const isSent = useMemo(() => {
    return new Date(scheduledAt) < new Date();
  }, [scheduledAt]);

  const messageContent = useTextBlastPreviewMessage({
    alreadySent: isSent,
    artistHandle,
    message: content,
    attachedTrack: {
      id: vaultContentAttachment?.id,
      linkValue: vaultContentAttachment?.linkValue,
    },
    messageAttachmentCount: messageAttachments?.length ?? null,
    shouldIgnoreMedia: false,
  });

  const numberOfAttachments = vaultContentAttachment ? 1 : messageAttachments?.length ?? 0;

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    return compact([
      {
        label: isRsvpMessage ? 'Edit drop' : 'Edit message',
        href: artistNavigationPath(
          artistHandle,
          isRsvpMessage ? `/rsvp/edit/${rsvpEvent.id}` : `/messages/edit/${id}`,
        ),
        type: 'secondary',
        onClick: () => {
          closeBottomsheet();
        },
        leadingIcon: faEdit,
      },
      !isRsvpMessage && {
        label: 'Cancel message',
        onClick: () => {
          setIsLoading(true);
          cancelMessage({ input: { scheduledEventId: id } }).then(() => {
            if (isOwnPage) {
              navigate(-1);
            }
            setIsLoading(false);
          });
        },
        requireConfirmation: true,
        confirmationSubText: 'Are you sure you want to cancel this message?',
        type: 'secondary',
        className: 'text-destructive300',
        leadingIcon: faCancel,
        confirmType: 'delete',
      },
    ]) satisfies ActionBottomsheetProps['buttons'];
  }, [
    artistHandle,
    cancelMessage,
    closeBottomsheet,
    id,
    isOwnPage,
    isRsvpMessage,
    navigate,
    rsvpEvent?.id,
    setIsLoading,
  ]);

  const title = `${memberCount} ${pluralizeText({ count: memberCount, text: 'member' })}`;

  return (
    <View className="flex min-h-full w-full flex-1 flex-col justify-start">
      <View className="box-border flex w-full flex-row items-center justify-between gap-6 border-0 border-b border-solid border-vault_text/10 px-4 pb-3 pt-5 lg:px-6 lg:pb-6 lg:pt-8">
        {isOwnPage && <BackButton withVaultTheme className="text-[24px]/[24px]" />}
        <Text className="flex-1 text-center font-title text-[18px]/[22px] font-medium text-vault_text md2:text-[22px]/[26px] lg:text-left">
          {title}
        </Text>
        {!isSent ? (
          <DropdownEllipsis
            sharedClassname="text-[24px]/[24px] text-vault_text"
            buttons={buttons}
            dropdownType={BOTTOMSHEET_TYPES.ACTION}
            onClick={() => {
              openBottomsheet({
                type: BOTTOMSHEET_TYPES.ACTION,
                actionBottomsheetProps: {
                  buttons,
                  withVaultTheme: false,
                },
              });
            }}
          />
        ) : (
          <View className="w-[21px]" />
        )}
      </View>
      <View className="no-scrollbar box-border flex w-full flex-col gap-3 overflow-y-scroll px-4 py-4 md2:px-6">
        <View className="flex max-w-full flex-row">
          <View
            className={twMerge(
              'flex max-w-full flex-shrink flex-col gap-4 overflow-hidden rounded-lg bg-vault_text/10 px-4 py-3 text-vault_text',
              isSent && 'bg-vault_accent text-vault_accent_text',
            )}
          >
            <LinkifyText className={isSent ? 'text-vault_accent_text' : 'text-vault_accent'}>
              <Text className="whitespace-pre-wrap break-words text-[16px]/[20px] font-normal">
                {messageContent}
              </Text>
            </LinkifyText>

            {numberOfAttachments > 0 && (
              <View className="flex flex-row items-center gap-2">
                <FontAwesomeIcon icon={faPaperclip} className="text-[12px] text-vault_text/50" />
                <Text className="font-base text-[12px] font-normal text-vault_text/50">
                  {millify(numberOfAttachments)}{' '}
                  {pluralizeText({ count: numberOfAttachments, text: 'attachment' })}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View className="flex flex-row items-center justify-start gap-2 text-[16px]/[20px] font-normal text-vault_text/50">
          <FontAwesomeIcon icon={isSent ? faSend : faCalendar} className="text-vault_text" />
          <Text>
            {isSent ? 'Sent' : 'Scheduled'} {formatDate(scheduledAt, 'E MMM d, y h:mmaa')}
          </Text>
        </View>
        <View className="flex w-full flex-col justify-start gap-4 py-4">
          <Text className="font-title text-[18px]/[22px] text-vault_text">Member filters</Text>
          <View className="no-scrollbar flex flex-row items-center justify-start gap-2 overflow-x-scroll md2:flex-wrap md2:overflow-x-hidden">
            {countries.length == 0 &&
              (artistEventTarget == null || artistEventTarget?.length === 0) &&
              tierFilterPillText === 'Everyone' && <FilterPill type="EVERYONE" />}
            {tierFilterPillText !== 'Everyone' && (
              <FilterPill type="TIER" tierName={tierFilterPillText} />
            )}
            {countries.map(({ locationName, code }) => (
              <FilterPill type="LOCATION" locationName={locationName} key={code} />
            ))}
            {artistEventTarget?.map(({ title, id }) => (
              <FilterPill type="DROP" dropName={title ?? 'Drop'} key={id} />
            ))}
          </View>
        </View>
        <View className="flex w-full flex-col justify-start gap-2 pb-4">
          <Text className="mb-2 font-title text-[18px]/[22px] text-vault_text">Insights</Text>
          <View className="flex w-full flex-row gap-2">
            <View className="flex flex-1 flex-col items-baseline rounded-md border border-solid border-vault_text/10 p-5">
              <Text className="text-[32px]/[38px] font-normal text-vault_text">{memberCount}</Text>
              <Text className="text-[16px]/[20px] font-normal text-vault_text/60">
                {pluralizeText({ count: memberCount, text: 'Member' })}
              </Text>
            </View>
            {linkClickRate != null ? (
              <View className="flex flex-1 flex-col items-baseline rounded-md border border-solid border-vault_text/10 p-5">
                <Text className="text-[32px]/[38px] font-normal text-vault_text">
                  {millify(linkClickRate * 100, { units: ['%'], precision: 1 })}
                </Text>
                <Text className="text-[16px]/[20px] font-normal text-vault_text/60">Clicked</Text>
              </View>
            ) : (
              <View className="flex-1" />
            )}
          </View>
          {!isSent && (
            <Text className="mt-1 font-base text-[12px]/[16px] font-normal text-vault_text/50 md2:text-[14px]/[18px]">
              Once your message is sent you’ll see insights here
            </Text>
          )}
        </View>
      </View>
    </View>
  );
}
