import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useJsApiLoader } from '@react-google-maps/api';
import { formatDate } from 'date-fns';
import { unionBy } from 'lodash-es';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faUserGroup } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCircleDot } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSquareCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { ERROR_TYPE, FEATURES, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { BOTTOMSHEET_TYPES, US_STATE_CODES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useQuery } from '../../graphql/client';
import {
  FilteredMemberCountDocument,
  FilterMembersEventItemFragmentDoc,
  type FragmentType,
  getFragment,
  IsoCountry,
  ScheduledEventAccessFeatureInput,
} from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import { useArtistEvents } from '../../hooks/membership/useArtistEvents';
import {
  type MassMessageStateFields,
  useMassMessageForm,
} from '../../hooks/message/useMassMessageForm';
import { useCopy } from '../../hooks/useCopy';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import type { AddLocationBottomsheetProps } from '../../types/bottomsheetTypes';
import { getManyFromList } from '../../utils/arrayUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { pluralizeText } from '../../utils/textUtils';
import { BackButton } from '../buttons/BackButton';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { EventItemSkeleton } from '../membership/EventItem';
import { Item } from '../membership/shared';
import { EmptyStateView } from '../views/EmptyStateView';

gql(/* GraphQL */ `
  fragment FilterMembersEventItem on ArtistEvent {
    id
    __typename

    ... on ArtistReleaseCampaignEvent {
      releaseCampaign {
        id
        __typename
        ... on ReleaseCampaignPrivateInfo {
          title
          artist {
            id
            profileImage {
              id
              artistFullProfileImageUrl: imageOptimizedUrl
            }
          }
          coverImage {
            id
            campaignCoverImageUrl: imageOptimizedUrl
          }
          initialReleaseImageUrl
          releaseDate
          createdAt
        }
      }
    }
    ... on ArtistRsvpEvent {
      rsvpEvent {
        id
        __typename
        artist {
          id
          profileImage {
            id
            artistFullProfileImageUrl: imageOptimizedUrl
          }
        }
        ... on RsvpEventPrivateInfo {
          title
          eventDate
          createdAt
          coverImage {
            id
            rsvpCoverImageUrl: imageOptimizedUrl
          }
        }
      }
    }
  }
`);

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_API_KEY) {
  throw new Error('VITE_GOOGLE_MAPS_API_KEY is not defined');
}

export function FilterMembersView({ artistHandle }: { artistHandle: string }) {
  const logError = useLogError();

  /* Preload google maps api */
  const { isLoaded: isGoogleMapsLoaded, loadError: googleMapsLoadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    if (googleMapsLoadError != null) {
      logError({
        error: googleMapsLoadError,
        errorType: ERROR_TYPE.UNKNOWN,
        message: 'Failed to load google maps api',
        level: 'fatal',
        feature: FEATURES.MISCELLANEOUS,
        action: 'LOAD_MAPS_API_ERROR',
        pillar: PILLARS.TEXT_BLAST,
      });
    }
  }, [googleMapsLoadError, logError]);

  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const ownedArtist = useOwnedArtist({ artistHandle });

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: '/',
      inviteCode: null,
    });
  }, [artistHandle]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Copied to clipboard',
  });

  const mainVaultType = ownedArtist?.mainVault.type;

  const { closeBottomsheet } = useBottomsheetContainer();
  const { fields, clearFields, setField } = useMassMessageForm({
    vaultId: ownedArtist?.mainVault.id,
  });

  const [memberType, setMemberType] = useState<ScheduledEventAccessFeatureInput[]>(
    fields.memberType,
  );

  const [locationsV2, setLocationsV2] = useState<MassMessageStateFields['locationsV2']>(
    fields.locationsV2,
  );

  const [drops, setDrops] = useState<{ id: string; name: string }[]>(fields.drops);

  const { data: memberCount, isLoading: isLoadingMemberCount } = useQuery(
    FilteredMemberCountDocument,
    {
      variables: !!ownedArtist && {
        vaultId: ownedArtist?.mainVault.id,
        memberType: memberType.length > 0 ? memberType : undefined,
        locationsV2: locationsV2.length > 0 ? locationsV2 : undefined,
        artistEventIds: drops.length > 0 ? drops.map(drop => drop.id) : undefined,
      },
      staleTime: Infinity,
      select: data => data.data.filteredMemberCount,
    },
  );

  const isEmpty =
    !isLoadingMemberCount &&
    memberCount === 0 &&
    locationsV2.length === 0 &&
    drops.length === 0 &&
    memberType.length === 2;

  const { isDesktop } = useWindow();
  const navigate = useNavigate();
  const onClickBack = () => {
    if (isDesktop) {
      closeBottomsheet();
    } else {
      navigate(-1);
    }
  };

  const onClickClear = () => {
    const saveHasAddedMembers = fields.hasAddedMembers;

    setMemberType([
      ScheduledEventAccessFeatureInput.FreeEvent,
      ScheduledEventAccessFeatureInput.PaidEvent,
    ]);

    setLocationsV2([]);
    setDrops([]);
    clearFields();
    setField('hasAddedMembers', saveHasAddedMembers);
  };

  const onClickFilter = () => {
    setField('hasAddedMembers', true);
    setField('memberType', memberType);
    setField('locationsV2', locationsV2);
    setField('drops', drops);

    if (isDesktop) {
      closeBottomsheet();
    } else {
      navigate(-1);
    }
  };

  return (
    <View className="no-scrollbar box-border flex w-full flex-1 flex-col items-center justify-start overflow-y-hidden overflow-x-clip bg-vault_background px-4 md2:h-[75vh] md2:max-h-[800px] md2:px-2">
      <ViewHeader
        className="mt-1 px-0 py-5 md2:hidden"
        gridClassName="mx-0"
        left={
          <BackButton
            onClick={onClickBack}
            className="w-20 justify-start text-[24px]/[24px] text-vault_text"
          />
        }
        center={
          <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">
            Add members
          </Text>
        }
        right={
          isEmpty ? (
            <Button
              label="Clear"
              className="w-20 justify-end font-base text-[16px]/[20px] font-semibold text-vault_accent"
              onClick={onClickClear}
            />
          ) : undefined
        }
      />
      <Text className="hidden w-full items-center justify-center border-0 border-b border-solid border-b-vault_text/10 pb-6 text-center font-title text-[28px]/[34px] font-medium text-vault_text md2:flex">
        Add members
      </Text>
      {isEmpty ? (
        <View className="flex w-full flex-1 items-center justify-center text-vault_text">
          <EmptyStateView
            className="mt-4 w-full px-0 pb-4"
            icon={faUserGroup}
            iconClassName="text-vault_text text-[60px] mb-8"
            title="No members yet!"
            titleClassName="text-[24px]/[22px] font-medium font-title"
            subtitle="Share your vault with your fans and you’ll be able to reach them here when they join!"
            subtitleClassName="text-[16px]/[20px] font-normal"
            buttonText="Copy link"
            onButtonClick={() => {
              copy();
            }}
            withVaultTheme
          />
        </View>
      ) : (
        <View className="no-scrollbar flex w-full flex-1 flex-col overflow-y-scroll">
          {mainVaultType === 'FREEMIUM' && (
            <TierFilter memberType={memberType} setMemberType={setMemberType} />
          )}
          <LocationFilter
            locationsV2={locationsV2}
            setLocationsV2={setLocationsV2}
            artistHandle={artistHandle}
            isGoogleMapsLoaded={isGoogleMapsLoaded}
            isGoogleMapsLoadError={googleMapsLoadError != null}
          />
          <DropsFilter
            artistHandle={artistHandle}
            isAtBottom={isAtBottom}
            drops={drops}
            setDrops={setDrops}
          />
          <View containerRef={bottomRef} />
        </View>
      )}
      {!isEmpty && (
        <View className="flex w-full flex-row items-center justify-center gap-6 border-0 border-t border-solid border-t-vault_text/10 py-4 md2:justify-end md2:px-2 md2:pb-[14px] md2:pt-6">
          <Button
            label="Clear"
            className="hidden text-[16px]/[20px] font-semibold text-vault_accent md2:flex"
            onClick={onClickClear}
          />
          <Button
            label={
              memberCount != null
                ? `Add ${memberCount} members`
                : isLoadingMemberCount
                  ? 'Add ... members'
                  : ''
            }
            className="h-[52px] w-full md2:w-[200px]"
            type="primary-themed"
            labelClassName="text-center"
            onClick={onClickFilter}
            disabledClassName="opacity-50"
            disabled={isLoadingMemberCount || !memberCount}
          />
        </View>
      )}
    </View>
  );
}

function TierFilter({
  memberType,
  setMemberType,
}: {
  memberType: ScheduledEventAccessFeatureInput[];
  setMemberType: (memberType: ScheduledEventAccessFeatureInput[]) => void;
}) {
  const selectedTier: 'ALL' | 'FREE' | 'PAID' = useMemo(() => {
    if (memberType.length === 2) return 'ALL';
    if (memberType.includes(ScheduledEventAccessFeatureInput.FreeEvent)) return 'FREE';
    return 'PAID';
  }, [memberType]);

  return (
    <View className="box-border flex w-full flex-col border-0 border-b border-solid border-b-vault_text/10 pb-5 pt-6">
      <Text className="pb-1 font-title text-[16px]/[20px] font-medium text-vault_text md2:text-[18px]/[22px]">
        Members
      </Text>
      <Button
        label="All"
        className={twMerge(
          'w-full justify-between py-3 font-base text-[16px]/[20px] font-normal text-vault_text [-webkit-tap-highlight-color:transparent]',
          selectedTier === 'ALL' && 'text-vault_accent',
        )}
        trailingIcon={selectedTier === 'ALL' ? faCircleDot : faCircle}
        labelClassName="text-vault_text"
        onClick={() =>
          setMemberType([
            ScheduledEventAccessFeatureInput.FreeEvent,
            ScheduledEventAccessFeatureInput.PaidEvent,
          ])
        }
      />
      <Button
        label="Free"
        className={twMerge(
          'w-full select-none justify-between bg-transparent py-3 font-base text-[16px]/[20px] font-normal text-vault_text [-webkit-tap-highlight-color:transparent]',
          selectedTier === 'FREE' && 'text-vault_accent',
        )}
        trailingIcon={selectedTier === 'FREE' ? faCircleDot : faCircle}
        labelClassName="text-vault_text"
        onClick={() => setMemberType([ScheduledEventAccessFeatureInput.FreeEvent])}
      />
      <Button
        label="Paid"
        className={twMerge(
          'w-full justify-between py-3 font-base text-[16px]/[20px] font-normal text-vault_text [-webkit-tap-highlight-color:transparent]',
          selectedTier === 'PAID' && 'text-vault_accent',
        )}
        trailingIcon={selectedTier === 'PAID' ? faCircleDot : faCircle}
        labelClassName="text-vault_text"
        onClick={() => setMemberType([ScheduledEventAccessFeatureInput.PaidEvent])}
      />
    </View>
  );
}

function LocationFilter({
  locationsV2,
  setLocationsV2,
  artistHandle,
  isGoogleMapsLoaded,
  isGoogleMapsLoadError,
}: {
  locationsV2: MassMessageStateFields['locationsV2'];
  setLocationsV2: Dispatch<SetStateAction<MassMessageStateFields['locationsV2']>>;
  artistHandle: string;
  isGoogleMapsLoaded: boolean;
  isGoogleMapsLoadError: boolean;
}) {
  const { openToast } = useToast();
  const { isDesktop } = useWindow();

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet, closeExtraBottomsheet } = useExtraBottomsheetContainer();

  const countries = useMemo(() => {
    if (locationsV2.length === 0) {
      return [
        { code: '', name: 'Global', flag: '🌏', regionCode: null, key: 'GLOBAL', location: null },
      ];
    }

    return getManyFromList(locationsV2, location => {
      if (location.type === 'REGION') {
        const { isoCountry, isoUsStateCode } = location;
        const country = COUNTRY_CODES.find(country => country.code === isoCountry);
        if (country == null) return null;

        if (isoCountry === IsoCountry.Us && isoUsStateCode !== null) {
          const state = US_STATE_CODES.find(state => state.code === isoUsStateCode);

          if (state == null) {
            return null;
          }

          return {
            code: isoCountry,
            name: `${state.name}, ${country.name}`,
            flag: country.flag,
            regionCode: isoUsStateCode,
            key: `${isoCountry}-${isoUsStateCode}`,
            location,
          };
        }

        return { ...country, regionCode: null, key: isoCountry, location };
      }

      const { isoCountry, displayName, radiusMiles, longitude, latitude } = location;
      const country = COUNTRY_CODES.find(country => country.code === isoCountry);
      if (country == null) return null;

      return {
        code: isoCountry,
        name: `${displayName} +${radiusMiles} ${pluralizeText({ count: radiusMiles, text: 'mile' })}`,
        flag: country.flag,
        regionCode: null,
        key: `${radiusMiles}-${longitude}-${latitude}`,
        location,
      };
    });
  }, [locationsV2]);

  const onClickChoose = () => {
    if (isGoogleMapsLoadError) {
      openToast({
        text: 'Error loading locations. Please refresh the page and try again.',
        variant: 'error',
      });
      return;
    }

    if (isDesktop) {
      openExtraBottomsheet({
        type: BOTTOMSHEET_TYPES.ADD_LOCATION,
        addLocationBottomsheetProps: {
          artistHandle,
          onAddLocation: location => {
            setLocationsV2(_locationsV2 => {
              return unionBy([location, ..._locationsV2], loc => {
                if (loc.type === 'REGION') {
                  return `${loc.isoCountry}-${loc.isoUsStateCode ?? ''}`;
                }

                return loc.isoCountry + loc.displayName;
              });
            });
            closeExtraBottomsheet();
          },
          initialLocation: null,
          removeLocation: null,
        },
        shared: {
          withVaultTheme: true,
          preventOutsideAutoClose: true,
          hideCloseBottomsheetButton: true,
          fitContent: true,
          preventSwipeToDismiss: true,
        },
      });
      return;
    }

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ADD_LOCATION,
      addLocationBottomsheetProps: {
        artistHandle,
        onAddLocation: location => {
          setLocationsV2(_locationsV2 => {
            return unionBy([location, ..._locationsV2], loc => {
              if (loc.type === 'REGION') {
                return `${loc.isoCountry}-${loc.isoUsStateCode ?? ''}`;
              }

              return `${loc.isoCountry}-${loc.displayName}`;
            });
          });
          closeBottomsheet();
        },
        initialLocation: null,
        removeLocation: null,
      },
      shared: {
        withVaultTheme: true,
        hideCloseBottomsheetButton: true,
        fitContent: true,
        preventSwipeToDismiss: true,
      },
    });
    return;
  };

  const onClickLocation = useStableCallback(
    ({ location }: { location: MassMessageStateFields['locationsV2'][number] | null }) => {
      if (isGoogleMapsLoadError) {
        openToast({
          text: 'Error loading locations. Please refresh the page and try again.',
          variant: 'error',
        });
        return;
      }

      const bottomsheetProps = {
        artistHandle,
        initialLocation: location,
        onAddLocation: locationToAdd => {
          setLocationsV2(locs => {
            const filteredLocs =
              location != null
                ? locs.filter(loc => {
                    if (loc.type === 'REGION' && location.type === 'REGION') {
                      return (
                        loc.isoCountry !== location.isoCountry ||
                        loc.isoUsStateCode !== location.isoUsStateCode
                      );
                    }

                    if (loc.type === 'GEO' && location.type === 'GEO') {
                      return (
                        loc.isoCountry !== location.isoCountry ||
                        loc.displayName !== location.displayName
                      );
                    }

                    return true;
                  })
                : locs;

            return [...filteredLocs, locationToAdd];
          });
        },
        removeLocation:
          location != null
            ? () => {
                setLocationsV2(locs =>
                  locs.filter(loc => {
                    if (loc.type === 'REGION' && location.type === 'REGION') {
                      return (
                        loc.isoCountry !== location.isoCountry ||
                        loc.isoUsStateCode !== location.isoUsStateCode
                      );
                    }

                    if (loc.type === 'GEO' && location.type === 'GEO') {
                      return (
                        loc.isoCountry !== location.isoCountry ||
                        loc.displayName !== location.displayName
                      );
                    }

                    return true;
                  }),
                );
              }
            : null,
      } satisfies AddLocationBottomsheetProps;

      if (isDesktop) {
        openExtraBottomsheet({
          type: BOTTOMSHEET_TYPES.ADD_LOCATION,
          addLocationBottomsheetProps: bottomsheetProps,
          shared: {
            withVaultTheme: true,
            preventOutsideAutoClose: true,
            hideCloseBottomsheetButton: true,
            preventSwipeToDismiss: true,
          },
        });
      } else {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.ADD_LOCATION,
          addLocationBottomsheetProps: bottomsheetProps,
          shared: {
            withVaultTheme: true,
            preventOutsideAutoClose: true,
            hideCloseBottomsheetButton: true,
            preventSwipeToDismiss: true,
          },
        });
      }
    },
  );

  return (
    <View className="box-border flex w-full flex-col border-0 border-b border-solid border-b-vault_text/10 pb-5 pt-6">
      <View className="flex w-full flex-row items-start justify-between pb-3">
        <Text className="pb-1 font-title text-[16px]/[20px] font-medium text-vault_text md2:text-[18px]/[22px]">
          Location
        </Text>
        <Button
          label="Add"
          className="font-base text-[16px]/[20px] font-medium text-vault_accent md2:font-normal"
          onClick={onClickChoose}
          disabled={!isGoogleMapsLoaded}
          disabledClassName="opacity-50"
        />
      </View>
      <View className="flex w-[unset] flex-wrap gap-1">
        {countries.map(country => {
          return (
            <View
              key={country.key}
              onClick={() => onClickLocation({ location: country.location })}
              className="flex flex-shrink-0  cursor-pointer flex-row items-center gap-2 rounded-full bg-vault_text/10 py-2 pl-3 pr-4 md2:py-3 md2:pl-4"
            >
              <Text>{country.flag}</Text>
              <Text className="flex-shrink-0 flex-grow font-base text-[14px]/[18px] font-medium text-vault_text md2:text-[16px]/[20px]">
                {country.name}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

function DropsFilter({
  artistHandle,
  isAtBottom,
  drops,
  setDrops,
}: {
  artistHandle: string;
  isAtBottom: boolean;
  drops: { id: string; name: string }[];
  setDrops: Dispatch<SetStateAction<{ id: string; name: string }[]>>;
}) {
  const {
    artistEvents: events = [],
    isInitialLoading,
    loadMoreNextPage,
  } = useArtistEvents({
    artistHandle,
    source: 'membership_page',
  });

  useEffect(() => {
    if (isAtBottom) {
      loadMoreNextPage();
    }
  }, [isAtBottom, loadMoreNextPage]);

  const hasEvents = events.length > 0;

  if (isInitialLoading) {
    return (
      <Item header="Drops" className="py-8">
        <div>
          {Array.from({ length: 3 }).map((_, index) => (
            <EventItemSkeleton key={index} />
          ))}
        </div>
      </Item>
    );
  }

  if (!hasEvents) {
    return null;
  }

  return (
    <Item header="Drops" className="gap-0 py-8">
      {events.map(event => (
        <EventItem
          key={event.id}
          eventData={event}
          isSelected={drops.some(drop => drop.id === event.id)}
          setDrops={setDrops}
        />
      ))}
    </Item>
  );
}

function EventItem({
  eventData,
  isSelected,
  setDrops,
}: {
  eventData: FragmentType<FilterMembersEventItemFragmentDoc>;
  isSelected: boolean;
  setDrops: Dispatch<SetStateAction<{ id: string; name: string }[]>>;
}) {
  const event = getFragment(FilterMembersEventItemFragmentDoc, eventData);

  if (event.__typename === 'ArtistReleaseCampaignEvent') {
    const { releaseCampaign } = event;

    if (releaseCampaign.__typename !== 'ReleaseCampaignPrivateInfo') {
      return null;
    }

    const { title, coverImage, initialReleaseImageUrl, releaseDate, createdAt, artist } =
      releaseCampaign;

    const releaseImageUrl =
      coverImage?.campaignCoverImageUrl ??
      initialReleaseImageUrl ??
      artist.profileImage?.artistFullProfileImageUrl;

    const date = releaseDate ?? createdAt;

    return (
      <EventRow
        releaseImageUrl={releaseImageUrl}
        title={title}
        date={date}
        isSelected={isSelected}
        onClick={() => {
          setDrops(prev => {
            if (prev.some(drop => drop.id === event.id)) {
              return prev.filter(drop => drop.id !== event.id);
            }
            return [...prev, { id: event.id, name: title }];
          });
        }}
      />
    );
  }

  const { rsvpEvent } = event;

  if (rsvpEvent.__typename !== 'RsvpEventPrivateInfo') {
    return null;
  }

  const { title, eventDate, createdAt, coverImage, artist } = rsvpEvent;

  return (
    <EventRow
      releaseImageUrl={
        coverImage?.rsvpCoverImageUrl ?? artist.profileImage?.artistFullProfileImageUrl
      }
      title={title}
      date={eventDate ?? createdAt}
      isSelected={isSelected}
      onClick={() => {
        setDrops(prev => {
          if (prev.some(drop => drop.id === event.id)) {
            return prev.filter(drop => drop.id !== event.id);
          }
          return [...prev, { id: event.id, name: title }];
        });
      }}
    />
  );
}

function EventRow({
  releaseImageUrl,
  title,
  date,
  isSelected,
  onClick,
}: {
  releaseImageUrl: string | null | undefined;
  title: string;
  date: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <View
      className="flex w-full cursor-pointer flex-row items-center gap-3 py-3 [-webkit-tap-highlight-color:transparent]"
      onClick={onClick}
    >
      {releaseImageUrl != null ? (
        <img src={releaseImageUrl} alt={title} className="h-10 w-10 rounded-[5px] object-cover" />
      ) : (
        <View className="h-10 w-10 rounded-[5px] bg-vault_text/10" />
      )}
      <View className="flex flex-1 flex-shrink flex-col gap-1 text-vault_text">
        <Text className="line-clamp-1 text-[16px]/[20px]">{title}</Text>
        <Text className="text-[14px]/[18px] text-vault_text/60">
          {formatDate(date, 'MMM d yyyy')}
        </Text>
      </View>
      <FontAwesomeIcon
        className={twMerge(
          'p-1 text-[20px]/[20px] text-vault_text',
          isSelected && 'text-vault_accent',
        )}
        icon={isSelected ? faSquareCheck : faSquare}
      />
    </View>
  );
}
