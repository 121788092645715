import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useToast } from '../../contexts/ToastContext';
import { ReferralCodeInfoFragmentDoc, TierTypename } from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useLockedMessages } from '../../hooks/useLockedMessages';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { Footer } from '../../screens/LandingPage';
import { daysFromToday } from '../../utils/textUtils';
import { AuthCTABox } from '../auth/AuthCTABox';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { MockMessageBubble } from '../message/MockMessageBubble';
import { TitleLogo } from '../svg/Logo';
import { MockHorizontalTrackFileCarousel } from '../track/MockHorizontalTrackFileCarousel';
import landingPageBg from '/images/landing_page_bg.png';

export const ReferralLinkView = ({
  code,
  referralCodeInfo,
  artistHandle,
}: {
  code: string;
  referralCodeInfo: FragmentType<ReferralCodeInfoFragmentDoc>;
  artistHandle: string;
}) => {
  const { setShowMenu } = useMenuContainer();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const {
    artist: {
      id,
      profileImage,
      name,
      linkValue,
      mainVault: { id: vaultId, activeSubscription, price },
    },
    validUntil,
    freeMonths,
    isActive,
  } = getFragment(ReferralCodeInfoFragmentDoc, referralCodeInfo);

  const ownedArtist = useOwnedArtist({ artistId: id });

  const isOwner = ownedArtist?.id === id;
  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: activeSubscription,
    isOwner,
  });

  const hasPaidAccess = activeSubscriptionFeatures?.tier === TierTypename.PaidTier;

  useEffect(() => {
    if (hasPaidAccess || !isActive) {
      openToast({
        text: hasPaidAccess
          ? 'You already have the All Access Pass'
          : 'This code is no longer valid',
        variant: 'success',
      });
      navigate(window.location.pathname, { replace: true });
    }

    if (linkValue !== artistHandle) {
      openToast({
        text: 'Referral code is invalid',
        variant: 'error',
      });
      navigate(window.location.pathname, { replace: true });
    }
  }, [artistHandle, isActive, hasPaidAccess, linkValue, navigate, openToast]);

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      shouldShowFullWidthOnDesktop
      headerLeft={<TitleLogo />}
      shouldSkipMargin
      hideOverflowX
    >
      {/* Hero */}
      <ReferralLinkHero
        code={code}
        vaultId={vaultId}
        freeMonths={freeMonths}
        validUntil={validUntil}
        profileImageUrl={profileImage?.artistSmallProfileImageUrl}
        artistHandle={artistHandle}
        linkValue={linkValue}
        price={price}
      />
      {/* Unreleased tracks */}
      <UnreleasedTracks
        code={code}
        vaultId={vaultId}
        profileImageUrl={profileImage?.artistSmallProfileImageUrl}
        linkValue={linkValue}
        price={price}
        name={name}
      />
      {/* Chat */}
      <PrivateChat
        code={code}
        vaultId={vaultId}
        linkValue={linkValue}
        price={price}
        name={name}
        vaultArtistImage={profileImage?.artistSmallProfileImageUrl}
      />

      <View className="mt-12">
        <Footer />
      </View>
    </DefaultLayout>
  );
};

function ReferralLinkHero({
  code,
  vaultId,
  validUntil,
  freeMonths,
  profileImageUrl,
  artistHandle,
  linkValue,
  price,
}: {
  code: string;
  vaultId: string;
  validUntil: string;
  freeMonths: number;
  profileImageUrl: string | null | undefined;
  artistHandle: string;
  linkValue: string;
  price: number | null;
}) {
  const freeDays = freeMonths * 30;

  return (
    <View
      className="mb-10 flex w-full justify-center bg-bottom bg-no-repeat max-lg:bg-[length:0px_0px] lg:mt-10 lg:min-h-[524px]"
      style={{
        backgroundImage: `url(${landingPageBg})`,
      }}
    >
      <View className="flex w-full flex-row lg:mx-auto lg:w-[870px] lg:justify-between">
        <View className="flex w-full flex-row items-center justify-between gap-20 px-4">
          <View className="hidden flex-col gap-6 lg:flex">
            <Text className="text-title-xxl font-medium text-yellow100">
              {`Free ${freeDays} days of All Access Pass`}
            </Text>
            <View className="flex w-1/2 flex-col gap-2">
              <SubscribeButton
                label="Unlock for free"
                linkValue={linkValue}
                artistAvatarUrl={profileImageUrl}
                price={price || DEFAULT_PRICE}
                vaultId={vaultId}
                showBottomSheet={false}
                code={code}
                component="referral_page"
              />
              <Text className="text-center text-base-m text-base300">{`Expires in ${daysFromToday(validUntil)} days`}</Text>
            </View>
          </View>
          <AuthCTABox fill artist={null} type="inner-circle-vault" artistHandle={artistHandle} />
        </View>
      </View>
    </View>
  );
}

function UnreleasedTracks({
  code,
  vaultId,
  linkValue,
  price,
  profileImageUrl,
  name,
}: {
  code: string;
  vaultId: string;
  linkValue: string;
  price: number | null;
  profileImageUrl: string | null | undefined;
  name: string;
}) {
  return (
    <View className="flex w-full flex-col justify-center rounded-t-[48px] border-b-[1px] border-solid border-base700 py-8 lg:mx-auto lg:w-[870px] lg:flex-row lg:items-center lg:justify-between lg:gap-12 lg:rounded-none lg:border-x-0 lg:border-t-0 lg:py-20">
      <View className="mb-8 flex shrink-0 flex-col items-center justify-center gap-4 px-4 lg:mb-0 lg:items-start">
        <Text className="max-w-[250px] text-center text-title-l font-medium lg:text-left">
          {`Get unreleased music from ${name}`}
        </Text>

        <Text className="max-w-[325px] text-center text-base-m text-base400 lg:text-left">
          {`Be the first to listen to exclusive music from ${name}`}
        </Text>
        <View className="flex w-full flex-col gap-2">
          <SubscribeButton
            className="mx-6 lg:mx-0 lg:w-44"
            label="Unlock for free"
            linkValue={linkValue}
            artistAvatarUrl={profileImageUrl}
            price={price || DEFAULT_PRICE}
            vaultId={vaultId}
            showBottomSheet={false}
            code={code}
            component="referral_page"
          />
        </View>
      </View>

      <View className="relative mb-12 overflow-x-hidden lg:mb-0">
        <MockHorizontalTrackFileCarousel vaultId={vaultId} />
        <View className="pointer-events-none absolute inset-y-0 -left-4 z-above1 w-24 bg-gradient-to-r from-black to-transparent" />
        <View className="pointer-events-none absolute inset-y-0 -right-4 z-above1 w-24 bg-gradient-to-l from-black to-transparent" />
      </View>
    </View>
  );
}

function PrivateChat({
  code,
  vaultId,
  linkValue,
  price,
  name,
  vaultArtistImage,
}: {
  code: string;
  vaultId: string;
  linkValue: string;
  price: number | null;
  name: string;
  vaultArtistImage: string | null | undefined;
}) {
  const messages = useLockedMessages({ vaultArtistName: name, vaultArtistImage, messageCount: 3 });

  return (
    <View className="flex w-full flex-col justify-center py-8 lg:mx-auto lg:w-[870px] lg:flex-row lg:items-center lg:justify-between lg:gap-12 lg:py-20">
      <View className="mb-8 flex shrink-0 flex-col items-center justify-center gap-4 px-4 lg:mb-0 lg:items-start">
        <Text className="max-w-[250px] text-center text-title-l font-medium lg:text-left">
          {`Join the private chat with ${name} and fans`}
        </Text>

        <Text className="max-w-[325px] text-center text-base-m text-base400 lg:text-left">
          Get behind the scenes, hear directly from the artist about their music and process
        </Text>
        <View className="hidden w-full flex-col gap-2 lg:flex">
          <SubscribeButton
            className="mx-6 lg:mx-0 lg:w-44"
            label="Unlock for free"
            linkValue={linkValue}
            artistAvatarUrl={vaultArtistImage}
            price={price || DEFAULT_PRICE}
            vaultId={vaultId}
            showBottomSheet={false}
            code={code}
            component="referral_page"
          />
        </View>
      </View>

      <View className="relative mb-12 flex w-full justify-center lg:mb-0">
        <View className="max-w-[350px]">
          {messages.map((message, i) => (
            <MockMessageBubble
              key={i}
              className={twMerge(i !== 0 && 'mt-4')}
              author={message.author}
              content={message.content}
              profileImageUrl={message.profileImageUrl}
              reactionSummary={message.reactionSummary}
              isVaultArtist={message.isVaultArtist}
              hasVaultContent={message.hasVaultContent}
            />
          ))}
        </View>
        <View className="pointer-events-none absolute inset-0 z-above1 bg-gradient-to-t from-black to-transparent" />
      </View>

      <View className="flex w-full flex-col gap-2 lg:hidden">
        <SubscribeButton
          className="mx-10"
          label="Unlock for free"
          linkValue={linkValue}
          artistAvatarUrl={vaultArtistImage}
          price={price || DEFAULT_PRICE}
          vaultId={vaultId}
          showBottomSheet={false}
          code={code}
          component="referral_page"
        />
      </View>
    </View>
  );
}
