import type { FC } from 'react';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { UserRow } from '../components/structures/UserRow';
import { DEFAULT_AVATAR } from '../constants/imageConstants';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { Button } from '../tamagui-components/elements/Button';
import { InfoRadioSelect } from '../tamagui-components/structures/InfoRadioSelect';
import { InfoToggle } from '../tamagui-components/structures/InfoToggle';

type Tab = 'ui-components' | 'user-row';

export const TestPage: FC = () => {
  useVaultTheme();

  const [activeTab, setActiveTab] = useState<Tab>('ui-components');

  function handleTabClick(tab: Tab) {
    setActiveTab(tab);
  }

  const [selectedA, setSelectedA] = useState<boolean>(false);
  const [selectedB, setSelectedB] = useState<boolean>(false);
  const [toggleA, setToggleA] = useState<boolean>(false);
  const [toggleValue, setToggleValue] = useState<boolean>(true);

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      showBorder
      withBottomNavigator={false}
      headerLeft={<BackButton href="/" />}
      contentClassName="md2:bg-white/3"
    >
      <View className="flex w-full flex-col">
        <View className="flex w-full">
          <button
            onClick={() => handleTabClick('ui-components')}
            className={twMerge(
              'text-base flex-1 cursor-pointer rounded-lg py-3 font-medium transition-all',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-white/20',
              activeTab === 'ui-components'
                ? 'bg-white text-black'
                : 'bg-white/10 text-white hover:bg-white/20',
            )}
          >
            UI Components
          </button>
          <button
            onClick={() => handleTabClick('user-row')}
            className={twMerge(
              'text-base flex-1 cursor-pointer rounded-lg py-3 font-medium transition-all',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-white/20',
              activeTab === 'user-row'
                ? 'bg-white text-black'
                : 'bg-white/10 text-white hover:bg-white/20',
            )}
          >
            User Row
          </button>
        </View>

        <View className="box-border flex w-full flex-col gap-4 p-4">
          {activeTab === 'ui-components' && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <InfoToggle
                  useVaultTheme={false}
                  label="Label"
                  checked={toggleA}
                  onCheckedChange={() => setToggleA(prev => !prev)}
                />

                <InfoToggle
                  useVaultTheme={false}
                  label="Downloadable"
                  subText="Members can download the original file"
                  checked={toggleValue}
                  onCheckedChange={() => setToggleValue(prev => !prev)}
                />

                <InfoToggle
                  useVaultTheme={false}
                  label="Downloadable"
                  subText="Members can download the original file"
                  checked={false}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-4">
                <InfoRadioSelect
                  useVaultTheme={false}
                  selected={selectedA}
                  onPress={() => setSelectedA(prev => !prev)}
                  label="Label"
                />
                <InfoRadioSelect
                  useVaultTheme={false}
                  selected={selectedB}
                  onPress={() => setSelectedB(prev => !prev)}
                  label="All members"
                  subText="All members can listen to the full track"
                />

                <InfoRadioSelect
                  useVaultTheme={false}
                  selected={false}
                  label="All members"
                  subText="All members can listen to the full track. All members can listen to the full track. All members can listen to the full track"
                  disabled
                />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" size="xs" />
                <Button useVaultTheme={false} label="Button" size="sm" />
                <Button useVaultTheme={false} label="Button" size="md" />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" loading />
                <Button useVaultTheme={false} label="Button" disabled />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" variant="primary" />
                <Button useVaultTheme={false} label="Button" variant="secondary" />
                <Button useVaultTheme={false} label="Button" variant="outline" />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" variant="ghost" />
                <Button useVaultTheme={false} label="Button" variant="ghost-accent" />
                <Button useVaultTheme={false} label="Button" variant="destructive" />
              </div>

              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" icon={faPlus} />
                <Button useVaultTheme={false} label="Button" variant="secondary" icon={faPlus} />
                <Button useVaultTheme={false} label="Button" variant="outline" icon={faPlus} />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" emojiIcon={<>😀</>} />
                <Button
                  useVaultTheme={false}
                  label="Button"
                  variant="secondary"
                  emojiIcon={<>😀</>}
                />
                <Button
                  useVaultTheme={false}
                  label="Button"
                  variant="outline"
                  emojiIcon={<>😀</>}
                />
              </div>
              <div className="flex gap-4">
                <Button useVaultTheme={false} label="Button" icon={faPlus} loading />
                <Button
                  icon={faPlus}
                  useVaultTheme={false}
                  label="Button"
                  variant="secondary"
                  loading
                />
                <Button
                  useVaultTheme={false}
                  label="Button"
                  variant="outline"
                  icon={faPlus}
                  loading
                />
              </div>
            </div>
          )}
          {activeTab === 'user-row' && <UserRowPreview />}
        </View>
      </View>
    </DefaultLayout>
  );
};

function UserRowPreview() {
  return (
    <View className="flex w-full flex-col gap-6 text-white">
      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Main Menu</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          managedVault
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          subtitle="1 update"
          verifiedBadge={false}
          managedVault={false}
          rightComponent={<View className="h-2 w-2 rounded-full bg-white" />}
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          managedVault={false}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Vault Selector</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          size="large"
          managedVault
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          subtitle="1 update"
          verifiedBadge={false}
          managedVault={false}
          rightComponent={<View className="h-2 w-2 rounded-full bg-white" />}
          size="large"
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Menu Footer</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          rightComponent={
            <button className="cursor-pointer border-none bg-transparent outline-none">
              <FontAwesomeIcon icon={faEllipsis} className="text-[16px] text-white" />
            </button>
          }
          verifiedBadge
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          rightComponent={
            <button className="cursor-pointer border-none bg-transparent outline-none">
              <FontAwesomeIcon icon={faEllipsis} className="text-[16px] text-white" />
            </button>
          }
          verifiedBadge={false}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Account Switcher</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Long Names</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="This is a super mega ultra duper tuper long name"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="Singlelinesupermegaultradupertuperlongname"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Loading</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          verifiedBadge={false}
          loading
        />
      </View>
    </View>
  );
}
