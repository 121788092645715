import { memo, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Slider from '@radix-ui/react-slider';
import { GoogleMap } from '@react-google-maps/api';
import { faMapPin } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { COLOR } from '../../constants/colorConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import type { IsoCountry } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import { findAddress } from '../../utils/mapUtils';
import { Text } from './Text';
import { View } from './View';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_API_KEY) {
  throw new Error('VITE_GOOGLE_MAPS_API_KEY is not defined');
}

const MAP_PADDING = 1.5;

const calculateZoom = (radiusInMiles: number, mapDiv: HTMLElement) => {
  // Base zoom calculation optimized for 550px width
  const baseZoom = 13.5 - Math.log2(radiusInMiles);

  // Add zoom adjustment for smaller widths
  const width = mapDiv.offsetWidth;
  // Multiply the adjustment by 0.25 to decrease the zoom-out effect on smaller screens
  const widthAdjustment = Math.log2(width / 550) * 0.25;

  return baseZoom + widthAdjustment;
};

export const LocationMap = memo(function LocationMap({
  location,
  setLocation,
  address,
  setAddress,
  countryCode,
  setCountryCode,
}: {
  location: { latitude: number; longitude: number; radiusMiles: number };
  setLocation: (location: { latitude: number; longitude: number; radiusMiles: number }) => void;
  address: string;
  setAddress: (address: string) => void;
  countryCode: IsoCountry | null;
  setCountryCode: (countryCode: IsoCountry | null) => void;
}) {
  const { isDesktop } = useWindow();
  const [controlledRadius, setControlledRadius] = useState(location.radiusMiles);

  const countryIcon = useMemo(() => {
    if (countryCode == null) return null;

    return COUNTRY_CODES.find(c => c.code === countryCode)?.flag;
  }, [countryCode]);

  const [circleRadius, setCircleRadius] = useState<number | null>(null);

  const geocoder = useMemo(() => new google.maps.Geocoder(), []);

  const [shouldFindAddress, setShouldFindAddress] = useState(false);

  useEffect(() => {
    if (geocoder == null || !shouldFindAddress) return;

    findAddress({
      location: {
        lat: location.latitude,
        lng: location.longitude,
      },
      setAddress,
      setCountryCode,
      geocoder,
    }).finally(() => {
      setShouldFindAddress(false);
    });
  }, [location, setAddress, setCountryCode, geocoder, shouldFindAddress]);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (map == null) return;

    const setNewLocation = () => {
      const bounds = map.getBounds();

      if (bounds != null) {
        const center = bounds.getCenter();
        const topRight = bounds.getNorthEast();

        const radius = Math.min(
          Math.max(1, Math.round(Math.abs(center.lat() * 69 - topRight.lat() * 69) / MAP_PADDING)),
          100,
        );

        setLocation({
          latitude: center.lat(),
          longitude: center.lng(),
          radiusMiles: radius,
        });
      }
    };

    const zoomListener = map.addListener('zoom_changed', setNewLocation);
    const panListener = map.addListener('center_changed', setNewLocation);

    const dragEndListener = map.addListener('dragend', () => {
      const bounds = map.getBounds();

      if (bounds != null) {
        const center = bounds.getCenter();
        const topRight = bounds.getNorthEast();

        const baseRadius = Math.round(
          Math.abs(center.lat() * 69 - topRight.lat() * 69) / MAP_PADDING,
        );

        const radius = Math.min(Math.max(1, baseRadius), 100);

        if (baseRadius !== radius) {
          map.setZoom(calculateZoom(radius, map.getDiv()));
        }

        setControlledRadius(radius);
      }
      setShouldFindAddress(true);
    });

    return () => {
      zoomListener.remove();
      panListener.remove();
      dragEndListener.remove();
    };
  }, [map, setLocation]);

  const onLoad = useStableCallback((map: google.maps.Map) => {
    const center = new google.maps.LatLng(location.latitude, location.longitude);
    map.setCenter(center);
    map.setZoom(calculateZoom(location.radiusMiles, map.getDiv()));
    setMap(map);
  });

  const onUnmount = useStableCallback(() => {
    setMap(null);
  });

  // Update the map options to enable fractional zoom
  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: false,
    scrollwheel: false,
    isFractionalZoomEnabled: true,
    gestureHandling: isDesktop ? 'cooperative' : 'greedy',
    clickableIcons: false,
  } as const satisfies google.maps.MapOptions;

  return (
    <View className="relative flex w-full max-w-[550px] flex-1 flex-col items-center justify-center">
      <GoogleMap
        mapContainerClassName="h-full w-full rounded-lg"
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      />
      <View
        className="pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
        ref={ref => {
          if (ref == null) return;

          const rect = ref.getBoundingClientRect();
          const radius = rect.width / (2 * MAP_PADDING);

          setCircleRadius(radius);
        }}
      >
        {circleRadius != null && (
          <SvgTransparentCircle radius={circleRadius} color={COLOR.base900_alpha50} />
        )}
        <View className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <FontAwesomeIcon
            icon={faMapPin}
            className="pb-6 text-[24px] text-black md2:text-[28px]"
          />
        </View>
      </View>
      <View className="absolute bottom-2 left-2 right-2 box-border flex flex-col items-center justify-center gap-4 overflow-clip overscroll-none rounded-lg bg-base800 px-3 py-4">
        <View className="flex w-full flex-row items-center justify-start gap-2">
          {countryIcon != null && <Text className="text-[20px]">{countryIcon}</Text>}
          <View className="flex flex-1 flex-shrink flex-col">
            <Text className="line-clamp-1 font-title text-[16px] font-medium text-white">
              {address}
            </Text>
            <Text className="font-base text-[14px] font-normal text-base400">
              {Math.round(location.radiusMiles)} miles radius
            </Text>
          </View>
        </View>
        <Slider.Root
          className="relative h-2 w-full rounded-full bg-white/50"
          max={100}
          min={1}
          value={[controlledRadius]}
          onValueChange={values => {
            if (values[0] != null && map != null) {
              const newRadius = Math.min(Math.max(values[0], 1), 100);
              setControlledRadius(newRadius);

              const center = map.getCenter();
              if (center) {
                map.setZoom(calculateZoom(newRadius, map.getDiv()));
              }
            }
          }}
        >
          <Slider.Range className="absolute h-2 rounded-full bg-yellow100" />
          <Slider.Thumb className="absolute -left-2 -top-1 h-4 w-4 rounded-full bg-yellow100" />
        </Slider.Root>
      </View>
    </View>
  );
});

function SvgTransparentCircle({ radius, color }: { radius: number; color: string }) {
  return (
    <svg height="100%" width="100%">
      <defs>
        <mask id="mask" x="0" y="0" height="100%" width="100%">
          <rect height="100%" width="100%" fill="#fff" />
          <circle r={radius} cx="50%" cy="50%" fill="black" />
        </mask>
      </defs>
      <rect height="100%" width="100%" fill={color} mask="url(#mask)" />
    </svg>
  );
}
