import * as React from 'react';

export function AccountSwitcherIcon({
  className,
  fill,
  height,
  width,
}: {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 21}
      height={height ?? 22}
      fill={fill ?? 'none'}
      className={className}
    >
      <path
        fill={fill ?? '#fff'}
        className={className}
        d="M3.752 6.621a4.225 4.225 0 0 0 3.686 2.125 4.212 4.212 0 0 0 3.652-2.125c.764-1.295.764-2.922 0-4.25C10.326 1.076 8.932.246 7.438.246c-1.528 0-2.922.83-3.686 2.125-.764 1.328-.764 2.955 0 4.25ZM8.932 10.34H5.91C2.623 10.34 0 12.996 0 16.283c0 .532.432.963.963.963h8.476l-.002-.125a6 6 0 0 1 3.335-5.377 5.922 5.922 0 0 0-3.84-1.404ZM13.21 15.164c-.331.352-.585.742-.722 1.172a.632.632 0 0 1-.8.39.625.625 0 0 1-.391-.8 4.415 4.415 0 0 1 1.035-1.64c1.7-1.7 4.453-1.7 6.172-.02l.82-.82c.117-.118.332-.157.508-.098a.487.487 0 0 1 .293.43v2.5a.463.463 0 0 1-.469.468h-2.5a.476.476 0 0 1-.449-.273.526.526 0 0 1 .117-.527l.801-.801a3.154 3.154 0 0 0-4.414.02Zm-2.46 3.301c0-.254.195-.469.469-.469h2.5c.176 0 .351.117.43.293.058.176.019.39-.098.508l-.82.82c1.23 1.192 3.203 1.192 4.414-.02.332-.35.585-.741.742-1.171a.604.604 0 0 1 .781-.39.625.625 0 0 1 .39.8 4.574 4.574 0 0 1-1.035 1.64c-1.699 1.7-4.453 1.7-6.171.02l-.801.801a.526.526 0 0 1-.528.117.476.476 0 0 1-.273-.449v-2.5Z"
      />
    </svg>
  );
}
