import { formatDate } from 'date-fns';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  CampaignReceiptItemFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { ProfileImage } from '../user/ProfileImage';
import { ReceiptUI } from './receipt/ReceiptUI';

gql(/* GraphQL */ `
  fragment CampaignReceiptItem on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    serialNumber
    membership {
      serialNumber
    }
    user {
      id
      username
      displayName
      computedDisplayName
      avatar {
        id
        cdnUrl
        smallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        dominantColor
      }
    }
    artist {
      id
      name
      linkValue
      mainVaultId
    }
    ... on ReleaseCampaignMembershipReceipt {
      __typename
      releaseCampaign {
        id
        title
      }
    }
    ... on ArtistMembershipRsvpEventReceipt {
      rsvpEvent {
        __typename
        id
        title
      }
    }
    ...MembershipReceipt
  }
`);

export const ReceiptItem = ({
  receiptData,
}: {
  receiptData: FragmentType<CampaignReceiptItemFragmentDoc>;
}) => {
  const receipt = getFragment(CampaignReceiptItemFragmentDoc, receiptData);
  const { openBottomsheet } = useBottomsheetContainer();

  const isPresaveReceipt = receipt.__typename === 'ArtistMembershipPresaveReceipt';
  const isStreamingReceipt = receipt.__typename === 'ArtistMembershipPlayStreamReceipt';

  const isCampaignReceipt = isPresaveReceipt || isStreamingReceipt;

  const isRsvpReceipt = receipt.__typename === 'ArtistMembershipRsvpEventReceipt';

  const {
    serialNumber: receiptNumber,
    createdAt,
    user: { computedDisplayName },
    artist: { linkValue: artistHandle, name, id: artistId },
  } = receipt;

  const title = isCampaignReceipt ? receipt.releaseCampaign.title : receipt.rsvpEvent.title;

  const receiptItemData = {
    title,
    receiptNumber,
    username: computedDisplayName || 'username',
    artistName: name,
    artistHandle,
    createdAt,
  };

  const ownedArtist = useOwnedArtist({
    artistId,
  });

  const action =
    receipt.__typename === 'ArtistMembershipPlayStreamReceipt'
      ? 'Streamed'
      : receipt.__typename === 'ArtistMembershipRsvpEventReceipt'
        ? 'RSVPd'
        : receipt.__typename === 'ArtistMembershipPresaveReceipt'
          ? 'Pre-saved'
          : passiveExhaustiveGuard(receipt) || '...';

  if (!isCampaignReceipt && !isRsvpReceipt) {
    return null;
  }

  return (
    <View
      className="flex h-16 w-full cursor-pointer justify-start gap-4 transition-all duration-200 ease-in hover:opacity-80"
      onClick={() => {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.RECEIPT,
          receiptBottomSheetProps: {
            id: receipt.id,
            receiptFrag: receipt,
          },
        });
      }}
    >
      <div className="relative flex h-16 w-16">
        <div className=" absolute z-above2 flex h-16 w-16 bg-gradient-to-t from-vault_background via-vault_background/20 to-vault_background/10" />
        <View className="aspect-square h-[58px] w-16 flex-shrink-0 overflow-hidden  pt-1">
          <div className="scale-receipt-sm relative">
            <ReceiptUI {...receiptItemData} type={isStreamingReceipt ? 'stream' : 'pre-save'} />
          </div>
        </View>
      </div>
      <View className="flex flex-col justify-center gap-2">
        <Text className="max-w-56 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-title text-[18px]/[20px] font-medium text-vault_text">
          {title}
        </Text>
        <View
          className="flex flex-row items-center justify-start gap-1 "
          onClick={ev => {
            if (!ownedArtist) return;

            ev.preventDefault();
            ev.stopPropagation();

            openBottomsheet({
              type: 'USER_PROFILE',
              shared: {
                withVaultTheme: true,
              },
              userProfileBottomsheetProps: {
                userLocation: null,
                userId: receipt.user.id,
                vaultId: receipt.artist.mainVaultId,
                vaultArtistId: receipt.artist.id,
                avatarUrl: receipt.user.avatar.smallProfileImageUrl || receipt.user.avatar.cdnUrl,
                username: receipt.user.username,
                displayName: receipt.user.computedDisplayName || '@username',
                isVaultArtist: true,
                activeSubscriptionTier: null,
                showAdminOptions: true,
                withVaultTheme: true,
                joinDate: null,
                email: null,
                phone: null,
              },
            });
          }}
        >
          <ProfileImage
            className="mr-0.5 h-[14px] w-[14px]"
            profileImageUrl={
              receipt.user.avatar?.smallProfileImageUrl || receipt.user.avatar.cdnUrl
            }
            onClick={undefined}
          />
          <Text className="text-[12px] text-vault_text/50">
            {receipt.user.computedDisplayName || '@username'}
          </Text>
          <Text className="text-[12px] text-vault_text/50">•</Text>
          <Text className="text-[12px] text-vault_text/50">
            {action} {formatDate(createdAt, 'MMM d, y')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export const ReceiptItemSkeleton = () => (
  <View className="flex h-[72px] w-full items-center justify-center gap-3">
    <View className="flex h-16 w-full gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
