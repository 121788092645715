import { ERROR_TYPE, MISCELLANEOUS_ERROR_ACTIONS } from '@soundxyz/vault-utils/dist/constants';
import { ArtistMembershipReceiptTypenames } from '../../graphql/generated';
import { logErrorNoDefaultToast } from '../logger/useLogError';
import { queryLastMembershipReceipt } from './useLastMembershipReceipt';

type FetchReceiptIdParams = {
  showReceiptModal: string | null;
  receiptIdParam: string | null;
  artistHandle: string | null;
  loggedIn: boolean;
  eventType: string | null;
  releaseCampaignId: string | null;
  source: string | null;
};

const getReceiptType = (eventType: string | null): ArtistMembershipReceiptTypenames | null => {
  switch (eventType) {
    case 'stream':
      return ArtistMembershipReceiptTypenames.ArtistMembershipPlayStreamReceipt;
    case 'rsvp':
      return ArtistMembershipReceiptTypenames.ArtistMembershipRsvpEventReceipt;
    default:
      return null;
  }
};

export const fetchReceiptId = async ({
  showReceiptModal,
  receiptIdParam,
  artistHandle,
  loggedIn,
  eventType,
  releaseCampaignId,
  source,
}: FetchReceiptIdParams): Promise<string | null> => {
  if (!showReceiptModal || !!receiptIdParam || !artistHandle || !loggedIn) {
    return null;
  }

  const receiptType = getReceiptType(eventType);

  try {
    const { receiptId } = await queryLastMembershipReceipt({
      artistHandle,
      receiptType: receiptType ?? undefined,
      releaseCampaignId: releaseCampaignId ?? undefined,
      source: source as 'apple' | 'spotify' | null,
      rsvpEventId: eventType === 'rsvp' && releaseCampaignId ? releaseCampaignId : undefined,
    });

    return receiptId ?? null;
  } catch (error) {
    logErrorNoDefaultToast({
      error,
      errorType: ERROR_TYPE.FETCH_GQL_ERROR,
      pillar: 'AUTH',
      action: MISCELLANEOUS_ERROR_ACTIONS.MISCELLANEOUS_ERROR,
      message: 'Error fetching receipt id',
      level: 'warning',
    });
    return null;
  }
};
