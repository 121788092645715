import { styled, type SwitchProps, XStack, YStack } from 'tamagui';
import { useSnapshot } from 'valtio';
import { COLOR } from '../../constants/colorConstants';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { Label, Subtext } from '../elements/TextElements';
import { Toggle } from '../elements/Toggle';

const Column = styled(YStack, {
  alignItems: 'flex-start',
  gap: 2,
  flexShrink: 1,
});

const Container = styled(XStack, {
  alignItems: 'center',
  gap: 12,
  flexShrink: 1,
  justifyContent: 'space-between',
  width: '100%',
});

export const InfoToggle = ({
  useVaultTheme,
  disabled,
  label,
  subText,
  subTextNumberOfLines = 2,
  checked,
  ...rest
}: {
  label: string;
  subText?: string;
  useVaultTheme: boolean;
  subTextNumberOfLines?: number;
  checked: boolean;
} & Omit<SwitchProps, 'checked' | 'size'>) => {
  const vaultTheme = useSnapshot(VaultThemeStore);
  const effectiveTheme = useVaultTheme ? vaultTheme : null;

  const textColor = effectiveTheme?.textColor ?? COLOR.base_text;
  const opacityContainer = disabled ? 0.5 : 1;

  return (
    <Container opacity={opacityContainer}>
      <Column>
        <Label label={label} color={textColor} numberOfLines={1} weight="normal" />
        {!!subText && (
          <Subtext text={subText} color={textColor} numberOfLines={subTextNumberOfLines} />
        )}
      </Column>
      <Toggle useVaultTheme={useVaultTheme} checked={checked} disabled={disabled} {...rest} />
    </Container>
  );
};
