export const VAULT_LOGO_SVG = 'https://d3jznoddta6qri.cloudfront.net/public/vault_logo.svg';

export const VAULT_LOGO_PNG = 'https://d3jznoddta6qri.cloudfront.net/public/vault_logo.png';

export const VAULT_LOGO_WHITE_PNG =
  'https://d3jznoddta6qri.cloudfront.net/public/vault_white_logo.png';

export const ABOUT_IMAGE = 'https://d3jznoddta6qri.cloudfront.net/public/about_image.png';

export const DEFAULT_AVATAR =
  'https://d3jznoddta6qri.cloudfront.net/public/profile-images/user.png';

export const LANDING_MOBILE_PHONE =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_phone_mobile.png';

export const INSTAGRAM_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/instagram.svg';

export const SPOTIFY_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/spotify.svg';

export const SPOTIFY_COLORED_ICON =
  'https://d3jznoddta6qri.cloudfront.net/public/spotify-colored.svg';

export const APPLE_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/apple.svg';

export const APPLE_COLORED_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/apple-colored.svg';

export const TIKTOK_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/tiktok.svg';

export const SOLID_YELLOW_THUMBTACK =
  'https://d3jznoddta6qri.cloudfront.net/public/solid_yellow_thumbtack.svg';

export const THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/thumbtack.svg';

export const GRAY_THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/gray_thumbtack.svg';

export const SOLID_THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/solid_thumbtack.svg';

export const FILE_IMAGE_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/file_image.svg';

export const LANDING_PAGE_LOGO =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_page_logo.svg';

export const APP_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/app_icon.png';

export const GIPHY_LOGO = 'https://d3jznoddta6qri.cloudfront.net/public/giphy.png';

export const BADGE_MATERIAL = 'https://d3jznoddta6qri.cloudfront.net/public/badge_material.png';

export const LANYARD_MATERIAL = 'https://d3jznoddta6qri.cloudfront.net/public/lanyard_texture.png';

export const LANDING_BANNER = 'https://d3jznoddta6qri.cloudfront.net/public/landing_banner.png';

export const LANDING_DIRECT_TO_FANS =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_direct_to_fans.png';

export const LANDING_INNER_CIRCLE =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_inner_circle.png';

export const LANDING_ONBOARD_AUDIENCE =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_onboard_audience.png';

export const LANDING_RECEIPTS_INNER_CIRCLE =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_receipts_inner_circle.png';

export const VAULT_APP_QR_CODE =
  'https://d3jznoddta6qri.cloudfront.net/public/vault_app_qr_code.svg';

export const ACCOUNT_SWITCHING_ICON =
  'https://d3jznoddta6qri.cloudfront.net/public/account-switcher.svg';

export const SEMI_TRANSPARENT_NEW_FILE_IMAGE_WHITE =
  'https://d3jznoddta6qri.cloudfront.net/public/plain_white_opacity_file.svg';

export const SEMI_TRANSPARENT_NEW_FILE_IMAGE_BLACK =
  'https://d3jznoddta6qri.cloudfront.net/public/plain_black_opacity_file.svg';
