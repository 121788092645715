import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { faAngleRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBank, faCreditCard } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { ActivateFreemiumPrompt } from '../../components/vault/ActivateFreemiumPrompt';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import type { ExternalAccountType } from '../../graphql/generated';
import {
  BubbleArtistFragmentDoc,
  CreateConnectedAccountDocument,
  GetExternalAccountDocument,
  getFragment,
  MyArtistVaultDocument,
  VaultType,
} from '../../graphql/generated';
import { useSelectedArtist } from '../../hooks/useSelectedArtist';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';

gql(/* GraphQL */ `
  mutation CreateConnectedAccount($artistId: UUID!, $stripeReturnUrl: URL) {
    createConnectedAccount(artistId: $artistId, stripeReturnUrl: $stripeReturnUrl) {
      __typename
      ... on MutationCreateConnectedAccountSuccess {
        data {
          id
          accountLinkUrl
        }
      }
      ... on Error {
        message
      }
    }
  }

  query GetExternalAccount($artistId: UUID!) {
    externalAccount(artistId: $artistId) {
      __typename
      ... on QueryExternalAccountSuccess {
        data {
          id
          brand
          type
          last4
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const PayoutsPage = () => {
  const { loginStatus } = useAuthContext();

  const selectedArtist = useSelectedArtist();

  const artistId = selectedArtist.status !== 'ready' ? null : selectedArtist.artistId;
  const artistName = selectedArtist.status !== 'ready' ? null : selectedArtist.artistName;
  const enabled = loginStatus === LoginStatus.LOGGED_IN;

  const {
    data: externalAccountData,
    isError: isErrorExternalAccount,
    isLoading: isLoadingExternalAccount,
    refetch: refetchExternalAccount,
  } = useQuery(GetExternalAccountDocument, {
    staleTime: Infinity,
    enabled,
    variables: !!artistId && { artistId },
  });

  const getMyVaultInformation = useQuery(MyArtistVaultDocument, {
    staleTime: 0,
    variables: !!artistId && { artistId },
  });

  const externalAccount =
    externalAccountData?.data.externalAccount?.__typename === 'QueryExternalAccountSuccess'
      ? externalAccountData?.data.externalAccount.data
      : null;

  const {
    data: connectedAccountData,
    isError: isErrorConnectedAccount,
    isLoading: isLoadingConnectedAccount,
    refetch: refetchConnectedAccount,
  } = useQuery(CreateConnectedAccountDocument, {
    staleTime: Infinity,
    enabled,
    variables: !!artistId && { artistId, stripeReturnUrl: window.location.href },
  });

  const artistVault = getFragment(
    BubbleArtistFragmentDoc,
    getMyVaultInformation.data?.data.artistById,
  );

  const redirectAccountLink = useMemo(() => {
    if (
      connectedAccountData?.data.createConnectedAccount.__typename ==
      'MutationCreateConnectedAccountSuccess'
    ) {
      return connectedAccountData.data.createConnectedAccount.data.accountLinkUrl;
    }
    return undefined;
  }, [connectedAccountData]);

  const onEditClick = useCallback(() => {
    trackEvent({ type: EVENTS.EDIT_BANK_ACCT, properties: null });

    if (redirectAccountLink != null) {
      window.location.href = redirectAccountLink;
    }
  }, [redirectAccountLink]);

  // TODO: Verify Error Logic is correct
  if (
    isErrorExternalAccount ||
    isErrorConnectedAccount ||
    externalAccountData?.data.externalAccount?.__typename === 'StripeApiError' ||
    connectedAccountData?.data.createConnectedAccount.__typename === 'StripeApiError' ||
    (loginStatus !== LoginStatus.LOADING && artistId == null)
  ) {
    return (
      <SettingsLayout
        title="Payout Methods"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={() => {
              refetchExternalAccount();
              refetchConnectedAccount();
            }}
            loggingType="payouts_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (
    isLoadingExternalAccount ||
    isLoadingConnectedAccount ||
    externalAccountData == null ||
    connectedAccountData == null
  ) {
    return (
      <SettingsLayout title="Payout Methods">
        <SkeletonPayoutsView />
      </SettingsLayout>
    );
  }

  const addBankLabel =
    externalAccountData.data.externalAccount?.__typename === 'ArtistUnfinishedExternalAccount'
      ? 'Finish connecting bank'
      : 'Connect bank';

  return (
    <SettingsLayout
      title="Payout Methods"
      subtitle={!!artistName ? artistName : undefined}
      right={
        externalAccount != null && (
          <div onClick={onEditClick}>
            <Text className="font-title text-base-m font-medium text-yellow100 hover:cursor-pointer active:opacity-70">
              Edit
            </Text>
          </div>
        )
      }
    >
      {externalAccount == null ? (
        <Button
          type="secondary"
          label={addBankLabel}
          leadingIcon={faBank}
          href={redirectAccountLink}
          trailingIcon={
            externalAccountData.data.externalAccount?.__typename ===
            'ArtistUnfinishedExternalAccount'
              ? faAngleRight
              : undefined
          }
          trailingIconClassName="ml-[auto]"
          event={{ type: EVENTS.EDIT_BANK_ACCT, properties: null }}
        />
      ) : (
        <ExternalAccountRow externalAccount={externalAccount} onEditClick={onEditClick} />
      )}
      {artistVault?.mainVault.type === VaultType.FreeOnly && (
        <ActivateFreemiumPrompt
          type="payouts"
          artistLinkValue={artistVault.linkValue}
          withVaultTheme={false}
        />
      )}
    </SettingsLayout>
  );
};

const ExternalAccountRow: FC<{
  externalAccount: { id: string; brand: string | null; type: ExternalAccountType; last4: string };
  onEditClick: () => void;
  className?: string;
}> = ({ externalAccount: { brand, last4, type }, onEditClick, className }) => {
  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row justify-between rounded-xl bg-base800 px-[28px] py-[20px] hover:cursor-pointer',
        className,
      )}
      onClick={onEditClick}
    >
      <View className="flex flex-row items-center">
        <FontAwesomeIcon icon={type === 'CARD' ? faCreditCard : faBank} className="mr-[12px]" />
        <View className="mr-[12px] flex flex-col justify-center">
          <Text className="font-title text-title-s font-medium text-white">
            {brand == null
              ? ''
              : brand
                  .split(' ')
                  .map(inner => capitalize(inner))
                  .join(' ')}
          </Text>
          <Text className="font-base text-base-m font-normal text-white">****** {last4}</Text>
        </View>
      </View>
      <Button
        iconOnly
        label="Right"
        leadingIcon={faAngleRight}
        className="text-[20px] text-white"
      />
    </View>
  );
};

const SkeletonPayoutsView = () => {
  return (
    <View className="flex w-full flex-row items-center ">
      <LoadingSkeleton className="box-border flex h-[60px] w-full flex-row rounded-xl bg-base800" />
    </View>
  );
};

export { PayoutsPage };
