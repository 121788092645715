import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { BAR_MARGIN, BAR_WIDTH } from '../../constants/waveformConstants';
import { useAudio } from '../../hooks/useAudio';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../utils/dateUtils';
import { FAKE_NORMALIZED_PEAKS } from '../../utils/fakeNormalizedPeaks';
import { paintCanvas, processAudio, waveformAvgChunker } from '../../utils/waveformUtils';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Text } from '../common/Text';
import { View } from '../common/View';

const RESOLUTION_MULTIPLIER = 4;

export const RecordingWaveform = ({
  className,
  height,
  audioUrl,
  peaks,
  duration,
  isLoading,
  variant,
}: {
  className?: string;
  height: number;
  audioUrl?: string;
  peaks?: number[];
  duration?: number;
  isLoading: boolean;
  variant: 'recording' | 'message';
}) => {
  const { play, pause, load, seek, isReady, isPlaying, audioRef } = useAudio();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const [percentComplete, setPercentComplete] = useState(0);
  const [normalizedPeaks, setNormalizedPeaks] = useState(peaks || FAKE_NORMALIZED_PEAKS);

  const audioDuration = useMemo(() => {
    return duration;
  }, [duration]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [waveformWidth, setWaveformWidth] = useState(0);
  const [hoverXCoord, setHoverXCoord] = useState<number>(0);

  const chunkedPeaks = useMemo(() => {
    const numBars = Math.min(
      Math.floor(waveformWidth / (BAR_WIDTH + BAR_MARGIN)),
      normalizedPeaks.length,
    );
    return waveformAvgChunker(normalizedPeaks, numBars);
  }, [normalizedPeaks, waveformWidth]);

  const paintWaveformCanvas = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    paintCanvas({
      canvasRef,
      normalizedPeaks: chunkedPeaks,
      maxBarHeight: height * RESOLUTION_MULTIPLIER,
      barWidth: BAR_WIDTH * RESOLUTION_MULTIPLIER,
      barMargin: BAR_MARGIN * RESOLUTION_MULTIPLIER,
      playingBarNum: Math.floor(
        ((percentComplete / 100) * waveformWidth) / (BAR_WIDTH + BAR_MARGIN),
      ),
      hoverXCoord: hoverXCoord * RESOLUTION_MULTIPLIER,
      isActiveTrack: true,
      showHalf: false,
      snippet: null,
      customActiveColor: vaultTheme.accentColor,
    });
  }, [chunkedPeaks, height, hoverXCoord, percentComplete, vaultTheme.accentColor, waveformWidth]);

  useEffect(() => {
    if (variant === 'message' && audioUrl) {
      fetch(audioUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], 'downloadedAudio', { type: blob.type }))
        .then(file => processAudio(file))
        .then(({ normalizedPeaks }) => {
          setNormalizedPeaks(normalizedPeaks);
        });
    }
  }, [audioUrl, variant]);

  useEffect(() => {
    if (chunkedPeaks.length > 0 && isReady && !isLoading) {
      paintWaveformCanvas();
    }
  }, [paintWaveformCanvas, chunkedPeaks, isReady, isLoading]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (containerRef.current) {
        setWaveformWidth(containerRef.current.offsetWidth * 0.75);
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef, variant]);

  const mouseOutRef = useRef(false);

  const handleMouseMove = useStableCallback((e: { clientX: number }) => {
    if (!canvasRef.current) return;

    // debounced hover coord updates
    requestAnimationFrame(() => {
      if (!canvasRef.current || mouseOutRef.current) return;

      setHoverXCoord(Math.round(e.clientX - canvasRef.current.getBoundingClientRect().left));
    });
  });

  const onMouseIn = useCallback(() => {
    mouseOutRef.current = false;
  }, []);

  const onMouseOut = useCallback(() => {
    mouseOutRef.current = true;
    setHoverXCoord(0);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      const bounds = canvasRef.current?.getBoundingClientRect();
      if (!bounds) return;

      const x = event.clientX - bounds.left;
      const trackPosition = (x / waveformWidth) * (audioDuration || 0); // Calculate position in track based on click
      play();
      seek(trackPosition);
    },
    [audioDuration, play, seek, waveformWidth],
  );

  useEffect(() => {
    // resets the hoverstate when user changes program or tab after hovering
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        onMouseOut();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [onMouseOut]);

  useEffect(() => {
    if (audioUrl) {
      load(audioUrl);
    }
  }, [load, audioUrl]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isPlaying && audioRef.current) {
      const updateProgress = () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        setPercentComplete((currentTime / duration) * 100);
      };

      updateProgress();
      intervalId = setInterval(updateProgress, 100);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [audioRef, isPlaying]);

  const loadingAnimation = (
    <FontAwesomeIcon
      icon={faSpinner}
      size="sm"
      className={twMerge(
        variant == 'message' ? 'text-vault_text' : 'text-vault_text/50',
        'ml-2 inline-block animate-spin rounded-full font-medium',
      )}
    />
  );

  if (!audioUrl) {
    return null;
  }

  return (
    <View containerRef={containerRef} className="mr-2 w-full">
      <View className={twMerge(className, 'w-full')}>
        {isLoading || !isReady ? (
          <View
            className={twMerge(
              variant == 'message' ? 'mb-0.5' : 'mb-1',
              'flex flex-row items-center gap-2',
            )}
          >
            {loadingAnimation}
            <Text
              className={twMerge(
                variant == 'message' ? 'text-vault_text' : 'text-vault_text/50',
                '!text-base-m',
              )}
            >
              Loading audio note...
            </Text>
          </View>
        ) : (
          <View
            className={twMerge(
              'flex items-center justify-between gap-2',
              variant == 'recording'
                ? 'flex-row'
                : 'flex-row-reverse rounded-lg bg-vault_accent_text/30 p-2',
            )}
          >
            <View
              className={twMerge(
                variant == 'recording' && 'mr-2',
                'relative flex h-[24px] w-[24px] flex-shrink-0 cursor-pointer select-none items-center justify-center overflow-hidden rounded-full',
              )}
              onClick={e => {
                e.stopPropagation();
                isPlaying ? pause() : play();
              }}
            >
              <PlayButtonView
                isPlaying={isPlaying}
                isDisabled={false}
                size={24}
                className="text-white"
              />
            </View>

            <canvas
              ref={canvasRef}
              height={height * RESOLUTION_MULTIPLIER}
              width={waveformWidth * RESOLUTION_MULTIPLIER}
              className="display-block w-full cursor-pointer"
              style={{
                height,
                WebkitTapHighlightColor: 'transparent',
              }}
              onMouseMove={handleMouseMove}
              onClick={handleClick}
              onMouseEnter={onMouseIn}
              onMouseOut={onMouseOut}
              onBlur={onMouseOut}
            />
            {audioDuration && (
              <Text className="ml-auto !text-base-m text-vault_text/50">
                {getDurationAsTime(audioDuration)}
              </Text>
            )}
          </View>
        )}
      </View>
    </View>
  );
};
