import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';

export const defaultImagesMessageAttachment = {
  images: [],
  isUploading: false,
};

export const ImagesMessageAttachments = proxy<{
  [identifier: string]: {
    images: {
      identifier: string;
      progress: number;
      objectUrl: string;
      uploaded: boolean;
      mediaId: string | null;
      cdnUrl: string | null;
      type: MediaType;
    }[];
    isUploading: boolean;
  };
}>({});

/**
 * Use this hook to get the images message attachments for a given identifier.
 * If the identifier is not provided, it will use the default images message attachment.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @returns The images message attachments.
 */
export function useImagesMessageAttachments({
  identifier,
}: {
  identifier: string | null | undefined;
}) {
  const images =
    useSnapshot(ImagesMessageAttachments)[identifier ?? '_'] ?? defaultImagesMessageAttachment;

  useEffect(() => {
    if (ImagesMessageAttachments[identifier ?? '_'] == null) {
      ImagesMessageAttachments[identifier ?? '_'] = defaultImagesMessageAttachment;
    }
  }, [identifier]);

  return images;
}

/**
 * Use this function to set the images message attachments for a given identifier.
 * If the identifier is not provided, it will use the default images message attachment.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @param files - The files to set the images message attachments to.
 */
export function setImages({ identifier, files }: { identifier: string; files: File[] }) {
  const newImages = files.map(file => ({
    identifier: fileIdentifier(file),
    progress: 0,
    objectUrl: URL.createObjectURL(file),
    uploaded: false,
    mediaId: null,
    cdnUrl: null,
    type: MediaType.Image,
  }));

  const currentImages =
    ImagesMessageAttachments[identifier]?.images ?? defaultImagesMessageAttachment.images;

  ImagesMessageAttachments[identifier] = {
    ...(ImagesMessageAttachments[identifier] ?? defaultImagesMessageAttachment),
    images: [...currentImages, ...newImages],
  };
}

/**
 * Use this function to update the progress of an image message attachment for a given identifier.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @param imageIdentifier - The identifier of the image to update the progress of.
 * @param progress - The progress to set the image to.
 */
export function updateProgress({
  identifier,
  imageIdentifier,
  progress,
}: {
  identifier: string;
  imageIdentifier: string;
  progress: number;
}) {
  const currentImages =
    ImagesMessageAttachments[identifier]?.images ?? defaultImagesMessageAttachment.images;

  ImagesMessageAttachments[identifier] = {
    ...(ImagesMessageAttachments[identifier] ?? defaultImagesMessageAttachment),
    images: currentImages.map(image =>
      image.identifier === imageIdentifier ? { ...image, progress } : image,
    ),
  };
}

/**
 * Use this function to update the loading status of an image message attachment for a given identifier.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @param imageIdentifier - The identifier of the image to update the loading status of.
 * @param isUploading - The loading status to set the image to.
 */
export function updateImageLoadingStatus({
  identifier,
  isUploading,
}: {
  identifier: string;
  isUploading: boolean;
}) {
  ImagesMessageAttachments[identifier] = {
    ...(ImagesMessageAttachments[identifier] ?? defaultImagesMessageAttachment),
    isUploading,
  };
}

/**
 * Use this function to update the image with the upload result for a given identifier.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @param imageIdentifier - The identifier of the image to update the upload result of.
 * @param mediaId - The media id of the image.
 * @param cdnUrl - The cdn url of the image.
 */
export function updateImageWithUploadResult({
  identifier,
  imageIdentifier,
  mediaId,
  cdnUrl,
}: {
  identifier: string;
  imageIdentifier: string;
  mediaId: string | null;
  cdnUrl: string | null;
}) {
  const currentImages =
    ImagesMessageAttachments[identifier]?.images ?? defaultImagesMessageAttachment.images;

  ImagesMessageAttachments[identifier] = {
    ...(ImagesMessageAttachments[identifier] ?? defaultImagesMessageAttachment),
    images: currentImages.map(image =>
      image.identifier === imageIdentifier ? { ...image, mediaId, cdnUrl, uploaded: true } : image,
    ),
  };
}

/**
 * Use this function to remove an image from the images message attachments for a given identifier.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 * @param imageIdentifier - The identifier of the image to remove.
 */
export function removeImage({
  identifier,
  imageIdentifier,
}: {
  identifier: string;
  imageIdentifier: string;
}) {
  const currentImages =
    ImagesMessageAttachments[identifier]?.images ?? defaultImagesMessageAttachment.images;

  ImagesMessageAttachments[identifier] = {
    ...(ImagesMessageAttachments[identifier] ?? defaultImagesMessageAttachment),
    images: currentImages.filter(image => image.identifier !== imageIdentifier),
  };
}

/**
 * Use this function to clear the images message attachments for a given identifier.
 * @param identifier - The identifier of the images message attachments. Either a message channels id or for text blasts it should be the artist id.
 */
export function clearImages({ identifier }: { identifier: string }) {
  ImagesMessageAttachments[identifier]?.images.forEach(image =>
    URL.revokeObjectURL(image.objectUrl),
  );

  ImagesMessageAttachments[identifier] = defaultImagesMessageAttachment;
}
