import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { resetActiveTrack } from '../../audio/AudioController';
import { pause } from '../../audio/AudioEngineHTML5';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useVaultContentUpload } from '../../components/upload/useContentUpload';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { UploadContentView } from '../../components/views/upload/UploadContentView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

export function MediaUploadPage() {
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const { artistHandle } = useArtistHandle();
  const ownedArtist = useOwnedArtist({ artistHandle });
  const { loginStatus } = useAuthContext();

  const { fields, clearFields } = useVaultContentUpload({
    vaultId: ownedArtist?.mainVault.id ?? '',
  });

  useVaultTheme();

  const media = fields.fileRef.current;
  const isPromptOpen = fields.isPromptOpen;

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }

    if (!media && !isPromptOpen) {
      if (
        (window.history.state.idx != null && window.history.state.idx > 0) ||
        (window.history?.length && window.history.length > 1)
      ) {
        navigate(-1);
      } else {
        navigate(artistNavigationPath(artistHandle, '/vault'));
      }
    }
  }, [artistHandle, media, navigate, isPromptOpen]);

  useEffect(() => {
    return () => {
      resetActiveTrack();
    };
  }, []);

  useEffect(() => {
    pause();
  }, []);

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION, event: EVENTS.UPLOAD_TRACK },
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        title: 'Are you sure?',
        customContent: (
          <Text className="mb-[24px] text-center font-base text-[18px]/[24px] font-normal text-white md2:mb-[16px]">
            You have unsaved changes.
            <br />
            Keep editing or discard?
          </Text>
        ),
        onConfirm: () => {
          clearFields();
        },
        event: EVENTS.UPLOAD_TRACK,
        cancelButtonText: 'Keep editing',
        confirmButtonText: 'Discard',
        buttonContainerClassName: 'w-full',
        confirmType: 'delete',
        fullWidth: true,
      },
    });
  });

  if (loginStatus === LoginStatus.LOADING) return <FullPageLoading withVaultTheme />;

  if (ownedArtist == null || artistHandle == null) return <Navigate to={ROUTES.NOT_FOUND} />;

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={ownedArtist?.mainVault.id}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:bg-vault_text/3 pt-5 md2:pt-10"
      headerLeft={<BackButton onClick={onBackClick} className="z-above1 text-vault_text" />}
      headerCenter={
        <Text className={twMerge('font-title !text-title-m font-medium text-vault_text')}>
          {media == null ? '' : `Upload ${media.contentType.toLowerCase()}`}
        </Text>
      }
      contentClassName="md2:bg-vault_text/3 overflow-y-auto "
      showBorder
      stretch
      hideAudioPlayer
      childrenWrapperClassName="!px-0"
    >
      <UploadContentView />
    </DefaultLayout>
  );
}
