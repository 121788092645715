import { type LegacyRef, useMemo, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFileDownload } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useMutation } from '../../../../graphql/client';
import {
  MediaType,
  UpsertUserContentViewDocument,
  VideoItemFragmentDoc,
} from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useBetterGate } from '../../../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../../../hooks/useOwnedArtist';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { useWindow } from '../../../../hooks/useWindow';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { MediaOverlay } from '../../../content/MediaOverlay';
import type { MediaView } from '../../../content/RenderedMedia';
import { MediaViewer } from '../../../message/MediaViewer';
import { VideoItem } from '../VideoItem';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';
import { useVaultDownloadPress } from '../useVaultDownloadPress';

export const UserVideo = ({
  video,
  containerRef,
  artistHandle,
}: {
  video: FragmentType<VideoItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();

  const [menuHovered, setMenuHovered] = useState(false);
  const hasMediaCommentsFF = useBetterGate('MEDIA_COMMENTS') === 'enabled';

  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('code') ?? undefined; // referral link code
  const navigate = useNavigate();
  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    id: videoId,
    vault,
    title,
    caption,
    uploadedMedia,
    vaultId,
    linkValue,
    downloadEnabled,
  } = getFragment(VideoItemFragmentDoc, video);
  const { loginStatus, loggedInUser } = useAuthContext();
  const path = linkValue ? (`/v/${linkValue}` as const) : (`/${videoId}` as const);

  const ownedArtist = useOwnedArtist({ artistMainVaultId: vaultId });

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: !!ownedArtist ? null : loggedInUser?.inviteCode,
    });
  }, [artistHandle, path, ownedArtist, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Video link copied to clipboard!',
  });

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const hasSubscription = vault.activeSubscription != null;

  const { isDesktop } = useWindow();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const onPlayClick = () => {
    upsertUserContentView({ input: { vaultContentId: videoId } });

    if (isLocked || showUpsellInterstitial) {
      if (showUpsellInterstitial) {
        setTimeout(() => {
          openBottomsheet({
            type: 'GET_APP',
            getAppBottomsheetProps: {
              vaultId,
              interstitial: 'first_media',
              onContinue: null,
            },
          });

          trackEvent({
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: 'GET_APP',
              vaultId,
              interstitial: 'first_media',
            },
          });
        }, 2000);
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'JOIN_VAULT',
            joinType: hasSubscription ? 'paid' : 'free',
            artistHandle,
          },
        });

        navigate(artistNavigationPath(artistHandle, path, inviteCode));
      }
      return;
    }

    if (hasMediaCommentsFF) {
      const mediaView = {
        id: videoId,
        type: uploadedMedia.mediaType,
        url: uploadedMedia.url,
        title: title,
        caption: caption,
        thumbnailUrl: uploadedMedia.videoScreenshotUrl ?? '',
      } satisfies MediaView;

      if (isDesktop) {
        openOverlay(
          <MediaOverlay media={mediaView} currentVideoRef={videoRef} onClose={closeOverlay} />,
        );
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.MEDIA_VIEW,
            contentType: MediaType.Video,
            component: 'ArtistVideo',
            event: 'Open Bottomsheet',
            contentId: mediaView.id,
          },
        });

        openBottomsheet({
          type: 'MEDIA_VIEW',
          shared: {
            withVaultTheme: false,
            showFullScreen: true,
            hidePulleyBar: true,
            fullWidth: true,
          },
          mediaViewBottomsheetProps: {
            media: mediaView,
            currentVideoRef: videoRef,
            withVaultTheme: false,
          },
        });
      }
    } else if (isDesktop) {
      openOverlay(
        <MediaViewer
          title={title}
          medias={[{ id: uploadedMedia.id, type: uploadedMedia.mediaType, url: uploadedMedia.url }]}
          onClose={closeOverlay}
        />,
      );
    } else {
      videoRef.current?.play();
    }
  };

  const vaultArtistProfileImage = vault.artist?.profileImage?.artistSmallProfileImageUrl ?? null;
  const vaultArtistMembershipCardImage =
    vault.artist?.membershipCardImage?.membershipCardImageUrl ?? null;
  const hasActiveSubscription = !!vault.activeSubscription?.id;

  const { onDownloadClick } = useVaultDownloadPress({
    vaultContentId: videoId,
    isLocked,
    vaultId: vault.id,
    artistHandle,
    artistName: vault.artist?.name ?? null,
    vaultArtistProfileImage,
    vaultArtistMembershipCardImage,
    hasActiveSubscription,
    fileName: title,
  });

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];
  if (downloadEnabled && !isLocked) {
    buttons.push({
      title: 'Download',
      icon: faFileDownload,
      onClick: onDownloadClick,
    });
  }
  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onPlayClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={
        <VideoItem video={video} containerRef={containerRef} videoRef={videoRef} hasEllipsis />
      }
      disabled={false}
      disableHover
    />
  );
};
