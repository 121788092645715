import { useCallback, useState } from 'react';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router';
import { subscribe, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import {
  ERROR_TYPE,
  MEDIA_FILE_ERROR_ACTIONS,
  MEDIA_FILE_INFO_ACTIONS,
  PILLARS,
} from '@soundxyz/vault-utils/dist/constants';

import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { invalidateOperations, useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  ContentByIdDocument,
  CreateVaultContentDocument,
  EditVaultContentDocument,
  MediaType,
  TrackItemFragmentDoc,
  VaultContentAccessFeatureInput,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentPaginationDocument,
  VaultContentType,
  VaultItemFragmentDoc,
} from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import { useLogInfo } from '../../hooks/logger/useLogInfo';
import { useVaultContentByFolderPosition } from '../../hooks/useVaultContent';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { uploadMultipartFile } from '../../utils/s3Utils';
import { PersistenceStorage } from '../../utils/storeUtils';
import {
  clearErrors,
  clearFields,
  populateFields,
  setError,
  setField,
  validateField,
} from './helpers';
import { type ContentUploadFields, ContentUploadFieldSchema } from './schema';
import { initialUploadContentState, uploadContentState } from './store';

gql(/* GraphQL */ `
  mutation CreateVaultContent($input: MutationCreateVaultContentInput!) {
    createVaultContent(input: $input) {
      __typename
      ... on MutationCreateVaultContentSuccess {
        data {
          __typename
          linkValue
          id
          title
          createdAt
          featureAccess {
            feature {
              __typename
            }
          }
          folderPosition
          isFullVersionAvailable
          ...VaultItem
          ...TrackFileInfo
        }
      }
      ... on AlreadyExistsError {
        message
      }
      ... on ContentNotifierPublishError {
        message
      }
      ... on NotAuthorizedError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
      ... on TranscodePublishError {
        message
      }
    }
  }

  mutation EditVaultContent($input: MutationEditVaultContentInput!) {
    editVaultContent(input: $input) {
      __typename

      ... on MutationEditVaultContentSuccess {
        data {
          __typename
          id
          title
          createdAt
          featureAccess {
            feature {
              __typename
            }
          }
          folderPosition
          isFullVersionAvailable
          ...TrackRowInfo
          ...TrackFileInfo
          ...VaultItem
          ...TrackItem
        }
      }

      ... on NotAuthorizedError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [CreateVaultContentDocument, EditVaultContentDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultContentPaginationDocument,
    VaultContentByFolderDocument,
    VaultContentByFolderPositionDocument,
  ],
});

RefetchOnComplete({
  trigger: [EditVaultContentDocument],
  refetch: [ContentByIdDocument],
});

const version = '0.1';
const storageKey = `@vault/upload${ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT}-${version}`;

const ContentUploadPersistence = PersistenceStorage({
  schema: ContentUploadFieldSchema.partial(),
  key: storageKey,
  eager: true,
});

if (typeof window !== 'undefined') {
  ContentUploadPersistence.initialValue
    .then(value => {
      if (!value) return;
      Object.assign(uploadContentState.fields, value);
    })
    .catch(
      // eslint-disable-next-line no-console
      console.error,
    )
    .finally(() => {
      subscribe(
        uploadContentState.fields,
        () => {
          if (isEqual(uploadContentState.fields, initialUploadContentState().fields)) {
            return;
          }
          const pendingStorageSet = setTimeout(() => {
            const parseToStore = ContentUploadFieldSchema.safeParse(uploadContentState.fields);

            if (parseToStore.success) {
              ContentUploadPersistence.set(parseToStore.data);
            }
            clearTimeout(pendingStorageSet);
          }, 0);
        },
        true,
      );
    });
}

const pillar = PILLARS.MEDIA_FILE;

export const useVaultContentUpload = ({ vaultId }: { vaultId: string }) => {
  const { fields, errors } = useSnapshot(uploadContentState, {
    sync: true,
  });

  const logError = useLogError();
  const logInfo = useLogInfo();
  const { isDesktop } = useWindow();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: createVaultContent, isLoading: isCreatingContent } = useMutation(
    CreateVaultContentDocument,
    {
      retry: 3,
    },
  );

  const { mutateAsync: editMutateAsync, isLoading: isEditingContent } = useMutation(
    EditVaultContentDocument,
    {
      retry: 3,
      onSuccess() {
        invalidateOperations({
          operations: [
            VaultContentPaginationDocument,
            VaultContentByFolderDocument,
            VaultContentByFolderPositionDocument,
            TrackItemFragmentDoc,
            VaultItemFragmentDoc,
          ],
        });
      },
    },
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  const hasError = Object.values(errors).some(error => error !== null);

  const enableSubmit =
    !!fields.title &&
    !!fields.fileRef &&
    !hasError &&
    !isSubmitting &&
    !hasSubmitError &&
    !fields.isUploading &&
    !fields.isThumbnailUploading;

  const { setInfiniteQueryData } = useVaultContentByFolderPosition({
    vaultId,
    folderId: fields.folderId,
  });

  const clearAll = useStableCallback(() => {
    ContentUploadPersistence.clear();
    clearFields();
  });

  const onContentSubmit = useStableCallback(
    async ({ artistId, artistHandle }: { artistHandle: string; artistId: string }) => {
      if (!enableSubmit) return;
      const contentFile = fields.fileRef.current;
      if (!contentFile) return;

      // error on invalid media type
      if (!fields.contentType) {
        logError({
          pillar,
          error: new Error(`Invalid media type: ${contentFile?.contentType}`),
          errorType: ERROR_TYPE.UPLOAD_ERROR,
          action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
          level: 'error',
          message: 'Invalid content type for upload',
          indexedTags: { artistHandle, mediaType: contentFile?.contentType },
          toast: 'Invalid media type',
          openToast,
        });
        return;
      }

      const mediaType =
        contentFile?.contentType === 'TRACK'
          ? MediaType.Audio
          : contentFile?.contentType === 'IMAGE'
            ? MediaType.Image
            : MediaType.Video;

      const { folderId } = fields;

      navigate(
        artistNavigationPath(artistHandle, folderId != null ? `/folder/${folderId}` : '/vault'),
      );

      uploadFileToS3();
      const startTime = Date.now();

      async function uploadFileToS3() {
        setField('retryUpload', null);
        if (!contentFile) return;

        setField('isUploadingPreview', true);
        setField('isUploading', true);
        setField('progressSize', 0);

        try {
          const { mediaId } = await uploadMultipartFile({
            file: contentFile.file,
            setProgress(bytes) {
              setField('progressSize', bytes);
            },
            mediaType,
            artistId,
          });

          let thumbnailMediaId = null;

          if (!!fields.thumbnailRef.current) {
            const thumbnailFile = fields.thumbnailRef.current;
            const { mediaId: thumbnailId } = await uploadMultipartFile({
              file: thumbnailFile.file,
              mediaType: MediaType.Image,
              artistId,
            });
            thumbnailMediaId = thumbnailId;
          }

          logInfo({
            pillar,
            action: MEDIA_FILE_INFO_ACTIONS.UPLOAD_FILE_START,
            message: 'Uploading media',
            data: {
              artistHandle,
              mediaId,
              vaultId,
              mediaType,
              duration: contentFile.duration,
            },
          });

          const contentType =
            contentFile.contentType === 'TRACK'
              ? VaultContentType.Track
              : contentFile.contentType === 'IMAGE'
                ? VaultContentType.Image
                : VaultContentType.Video;

          const { data } = await createVaultContent({
            input: {
              title: fields.title.trim(),
              caption: !!fields.caption ? fields.caption.trim() : null,
              notificationMessage: null,
              contentType,
              mediaId: mediaId,
              thumbnailMediaId,
              duration: contentFile.duration,
              shouldSendSms: false,
              vaultId,
              parentVaultContentId: folderId,
              featureAccess: fields.featureAccess,
              normalizedPeaks: contentFile.normalizedPeaks,
              uniqueId: mediaId,
              downloadEnabled: fields.downloadEnabled,
            },
          });

          const uploadDuration = Date.now() - startTime;

          switch (data?.createVaultContent?.__typename) {
            case 'MutationCreateVaultContentSuccess':
              setHasSubmitError(false);
              logInfo({
                pillar,
                action: MEDIA_FILE_INFO_ACTIONS.UPLOAD_FILE_SUCCESS,
                message: 'Vault content created',
                data: {
                  artistHandle,
                  mediaId,
                  vaultId,
                  mediaType,
                  uploadDuration,
                },
              });

              trackEvent({
                type: EVENTS.UPLOAD_CONTENT,
                properties: {
                  mediaId,
                  contentType,
                  vaultId,
                  parentVaultContentId: folderId,
                  artistHandle,
                },
              });

              const node = data.createVaultContent.data;

              setInfiniteQueryData(previousData => {
                if (!previousData) {
                  return {
                    pageParams: [],
                    pages: [
                      {
                        data: {
                          vaultContentByFolderPosition: {
                            edges: [
                              {
                                cursor: node.id,
                                node,
                              },
                            ],
                            pageInfo: {
                              hasNextPage: true,
                              endCursor: null,
                            },
                          },
                        },
                      },
                    ],
                  };
                }

                return produce(previousData, draft => {
                  draft.pages[0]?.data.vaultContentByFolderPosition.edges.unshift({
                    cursor: node.id,
                    node,
                  });
                });
              });

              clearFields();
              if (artistHandle != null) {
                if (isDesktop) {
                  openBottomsheet({
                    type: BOTTOMSHEET_TYPES.SHARE_CONTENT,
                    shareContentBottomsheetProps: {
                      linkValue: node.linkValue,
                      artistHandle,
                    },
                    shared: {
                      withVaultTheme: true,
                      preventOutsideAutoClose: true,
                    },
                  });
                } else {
                  const contentTypePathText =
                    contentFile.contentType === VaultContentType.Track
                      ? 't'
                      : contentFile.contentType === VaultContentType.Video
                        ? 'v'
                        : 'i';

                  navigate(
                    artistNavigationPath(
                      artistHandle,
                      `/${contentTypePathText}/${node.linkValue}/share`,
                    ),
                  );
                }
              } else {
                openToast({
                  text: `The ${mediaType.toLowerCase()} has been uploaded to your vault`,
                  variant: 'success',
                });
              }
              break;

            case 'AlreadyExistsError':
              return openToast({
                text: 'This media already exists in your vault',
                variant: 'error',
              });
            case 'ContentNotifierPublishError':
              logError({
                pillar,
                error: new Error(data.createVaultContent.message),
                errorType: ERROR_TYPE.UPLOAD_ERROR,
                action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
                level: 'error',
                message: 'Content notifier publish error',
                indexedTags: { artistHandle, mediaId, vaultId, mediaType },
                toast: 'There was an error publishing the content',
                openToast,
              });
              return;
            case 'NotFoundError':
              logError({
                pillar,
                error: new Error(data.createVaultContent.message),
                errorType: ERROR_TYPE.NOT_FOUND_ERROR,
                action: MEDIA_FILE_ERROR_ACTIONS.UPLOAD_SAVE_ERROR,
                level: 'error',
                message: 'Media not found',
                indexedTags: { artistHandle, mediaId, vaultId, mediaType },
                unindexedExtra: {
                  uploadDuration: Date.now() - startTime,
                },
                toast: 'The media you are trying to upload was not found',
                openToast,
              });
              return;
            case 'TranscodePublishError':
              logError({
                pillar,
                error: new Error(data.createVaultContent.message),
                errorType: ERROR_TYPE.UPLOAD_ERROR,
                action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
                level: 'warning',
                message: 'Transcode publish error',
                indexedTags: { artistHandle, mediaId, vaultId, mediaType },
                unindexedExtra: {
                  uploadDuration: Date.now() - startTime,
                },
                toast: 'There was an error transcoding the media',
                openToast,
              });
              return;
            case 'ValidationError':
              logError({
                pillar,
                error: new Error(data.createVaultContent.message),
                errorType: ERROR_TYPE.UPLOAD_ERROR,
                action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
                level: 'error',
                message: 'Validation error',
                indexedTags: { artistHandle, mediaId, vaultId, mediaType },
                unindexedExtra: {
                  uploadDuration: Date.now() - startTime,
                },
                toast: data.createVaultContent.message,
                openToast,
              });
              return;
            case 'NotAuthorizedError':
              logError({
                pillar,
                error: new Error(data.createVaultContent.message),
                errorType: ERROR_TYPE.NOT_AUTHORIZED,
                action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
                level: 'error',
                message: 'Not authorized to upload media',
                indexedTags: { artistHandle, mediaId, vaultId, mediaType },
                unindexedExtra: {
                  uploadDuration: Date.now() - startTime,
                },
                toast: 'You are not authorized to upload media',
                openToast,
              });
              return;
            default:
              return null;
          }
        } catch (error) {
          logError({
            pillar: PILLARS.MEDIA_FILE,
            error,
            errorType: ERROR_TYPE.MUTATION_ERROR,
            level: 'error',
            indexedTags: { artistHandle, vaultId: contentFile.vaultId },
            unindexedExtra: {
              vaultId,
              artistHandle,
              selectedFileName: contentFile.file.name,
              selectedFizeSize: contentFile.file.size,
              uriPath: contentFile.fileUri,
              uploadDuration: Date.now() - startTime,
            },
            message: `Error uploading ${mediaType.toLowerCase()} `,
            action: MEDIA_FILE_ERROR_ACTIONS.UPLOAD_FILE_ERROR,
            toast: `There was an error uploading the ${mediaType.toLowerCase()}`,
            openToast,
          });
        } finally {
          setField('isUploading', false);
          setField('progressSize', 0);
          setIsSubmitting(false);
          clearAll();
        }
      }
    },
  );

  const onEdit = useCallback(
    async ({
      mediaType,
      folderId,
      contentId,
      artistHandle,
      artistId,
      vaultId,
    }: {
      mediaType: VaultContentType;
      folderId?: string | null;
      contentId: string;
      artistHandle: string;
      artistId: string;
      vaultId: string;
    }) => {
      if (!enableSubmit) return;

      setIsSubmitting(true);
      const startTime = Date.now();

      if (!mediaType) {
        logError({
          pillar,
          error: new Error(`Invalid media type: ${mediaType}`),
          errorType: ERROR_TYPE.UPLOAD_ERROR,
          action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
          level: 'error',
          message: 'Invalid content type for upload',
          indexedTags: { mediaType },
          toast: 'Invalid media type',
          openToast,
        });
        return;
      }

      logInfo({
        pillar,
        action: MEDIA_FILE_INFO_ACTIONS.EDIT_FILE_START,
        message: 'Editing media',
        data: {
          vaultId,
          mediaType,
        },
      });

      try {
        let thumbnailMediaId = fields.thumbnailId || null;

        if (!!fields.thumbnailRef.current) {
          const thumbnailFile = fields.thumbnailRef.current;
          const { mediaId: thumbnailId } = await uploadMultipartFile({
            file: thumbnailFile.file,
            mediaType: MediaType.Image,
            artistId,
          });
          thumbnailMediaId = thumbnailId;
        }

        const { data } = await editMutateAsync({
          input: {
            title: fields.title.trim() || null,
            caption: !!fields.caption ? fields.caption.trim() : null,
            thumbnailMediaId,
            featureAccess: fields.featureAccess,
            downloadEnabled: fields.downloadEnabled,
            vaultContentId: contentId,
            unset: {
              thumbnailMediaId: true,
              caption: true,
            },
          },
        });

        switch (data?.editVaultContent?.__typename) {
          case 'MutationEditVaultContentSuccess':
            setHasSubmitError(false);
            logInfo({
              pillar,
              action: MEDIA_FILE_INFO_ACTIONS.EDIT_FILE_SUCCESS,
              message: 'Vault content edited',
              data: {
                vaultId,
                mediaType,
              },
            });

            trackEvent({
              type: EVENTS.EDIT_CONTENT,
              properties: {
                contentType: mediaType,
                vaultId,
                parentVaultContentId: folderId,
                contentId,
              },
            });
            if (
              (window.history.state.idx != null && window.history.state.idx > 0) ||
              (window.history?.length && window.history.length > 1)
            ) {
              navigate(-1);
            } else {
              navigate(artistNavigationPath(artistHandle, '/'));
            }

            clearAll();

            return;

          case 'NotFoundError':
            logError({
              pillar,
              error: new Error(data.editVaultContent.message),
              errorType: ERROR_TYPE.NOT_FOUND_ERROR,
              action: MEDIA_FILE_ERROR_ACTIONS.EDIT_FILE_SAVE_ERROR,
              level: 'error',
              message: 'Media not found',
              indexedTags: { vaultId, mediaType, contentId },
              unindexedExtra: {
                uploadDuration: Date.now() - startTime,
              },
              toast: 'The media you are trying to edit was not found',
              openToast,
            });
            return;

          case 'NotAuthorizedError':
            logError({
              pillar,
              error: new Error(data.editVaultContent.message),
              errorType: ERROR_TYPE.NOT_AUTHORIZED,
              action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
              level: 'error',
              message: 'Not authorized to edit media',
              indexedTags: { vaultId, mediaType, contentId },
              unindexedExtra: {
                uploadDuration: Date.now() - startTime,
              },
              toast: 'You are not authorized to edit this file',
              openToast,
            });
            return;
          default:
            return null;
        }
      } catch (error) {
        logError({
          pillar,
          errorType: ERROR_TYPE.UPLOAD_ERROR,
          action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
          error,
          level: 'error',
          message: 'Error editing vault content',
          indexedTags: {
            vaultId,
            mediaType,
            source: 'useVaultContentUpload',
          },
          toast: 'There was an error editing the media',
          openToast,
        });
        return;
      } finally {
        setField('isEditing', false);
        setIsSubmitting(false);
        clearAll();
      }
    },
    [
      clearAll,
      editMutateAsync,
      enableSubmit,
      fields.caption,
      fields.downloadEnabled,
      fields.featureAccess,
      fields.thumbnailId,
      fields.thumbnailRef,
      fields.title,
      logError,
      logInfo,
      navigate,
      openToast,
    ],
  );
  return {
    fields,
    errors,
    enableSubmit,
    clearFields: clearAll,
    isSubmitting,
    setField,
    validateField,
    setError,
    clearErrors,
    populateFields,
    onEdit,
    onContentSubmit,
    isEditingContent,
    isCreatingContent,
  };
};

export function useEditVaultContent() {
  const navigate = useNavigate();

  const editVaultContent = useCallback(
    async ({
      artistHandle,
      caption,
      contentType,
      downloadEnabled,
      duration,
      hasFreeAccess,
      isSnippetOnly,
      mediaId,
      normalizedPeaks,
      thumbnailId,
      thumbnailUrl,
      title,
      vaultContentId,
      withNavigation,
    }: {
      artistHandle: string;
      hasFreeAccess: boolean;
      isSnippetOnly?: boolean;
      thumbnailId?: string | null | undefined;
      vaultContentId: string;
      withNavigation: boolean;
    } & Pick<
      ContentUploadFields,
      | 'mediaId'
      | 'title'
      | 'contentType'
      | 'caption'
      | 'downloadEnabled'
      | 'thumbnailUrl'
      | 'duration'
      | 'normalizedPeaks'
    >) => {
      setField('isEditing', true);

      if (contentType === VaultContentType.Image || contentType === VaultContentType.Video) {
        setField('fileRef', {
          current: null,
        });
      }

      populateFields({
        mediaId,
        title,
        contentType,
        caption,
        downloadEnabled,
        featureAccess: hasFreeAccess
          ? VaultContentAccessFeatureInput.FreeVaultContent
          : VaultContentAccessFeatureInput.PaidVaultContent,
        thumbnailId,
        thumbnailUrl,
        duration,
        normalizedPeaks,
        isSnippetOnly,
      });

      if (withNavigation) {
        navigate(artistNavigationPath(artistHandle, `/edit/${vaultContentId}`));
      }
    },
    [navigate],
  );

  return { editVaultContent };
}
