import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faImage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faVideo } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../../../graphql/client';
import { AnnouncementSourceType, ContentToShareDocument } from '../../../graphql/generated';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { generateShareLink } from '../../../utils/linkUtils';
import { Image } from '../../common/Image';
import { View } from '../../common/View';
import { ErrorView } from '../../error/ErrorView';
import { SongArtwork } from '../../track/SongArtwork';
import { FullPageLoading } from '../../views/FullPageLoading';
import { BaseShareView } from './BaseShareView';

gql(/* GraphQL */ `
  query ContentToShare($linkValue: String!, $artistHandle: String!) {
    vaultContentBySlug(slug: $linkValue, artistHandle: $artistHandle) {
      __typename
      ... on QueryVaultContentBySlugSuccess {
        data {
          __typename
          id
          title
          ... on VaultImage {
            uploadedMedia {
              id
              imageOptimizedUrl(input: { width: 480, height: 480 })
            }
          }
          ... on VaultVideo {
            uploadedMedia {
              id
              cdnUrl
              videoScreenshotUrl
            }
          }
          ... on VaultTrack {
            thumbnailMedia {
              id
              imageOptimizedUrl(input: { width: 480, height: 480 })
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export function ShareContentView({
  linkValue,
  artistHandle,
  artistName,
}: {
  linkValue: string;
  artistHandle: string;
  artistName: string;
}) {
  const { data, isLoading, isError, refetch } = useQuery(ContentToShareDocument, {
    staleTime: 0,
    variables: {
      linkValue,
      artistHandle,
    },
    select: data =>
      data.data.vaultContentBySlug?.__typename === 'QueryVaultContentBySlugSuccess'
        ? data.data.vaultContentBySlug.data
        : null,
  });

  const shareLink = useMemo(() => {
    const firstPath = (() => {
      if (data == null) {
        return 't';
      }

      switch (data?.__typename) {
        case 'VaultTrack':
          return 't';
        case 'VaultVideo':
          return 'v';
        case 'VaultFolder':
          return 'f';
        case 'VaultImage':
          return 'i';
      }
    })();

    return generateShareLink({
      artistLinkValue: artistHandle,
      path: `/${firstPath}/${linkValue}`,
      inviteCode: null,
    });
  }, [artistHandle, data, linkValue]);

  if (isError) {
    return <ErrorView onRetryClick={refetch} withVaultTheme className="md2:w-full" />;
  }

  if (data == null || isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  const { title } = data;

  const vaultContentTypeText = (() => {
    switch (data.__typename) {
      case 'VaultFolder':
        return 'folder';
      case 'VaultImage':
        return 'image';
      case 'VaultTrack':
        return 'track';
      case 'VaultVideo':
        return 'video';
    }
  })();

  const sourceType = (() => {
    switch (data.__typename) {
      case 'VaultFolder':
        return AnnouncementSourceType.VaultContentFolder;
      case 'VaultTrack':
        return AnnouncementSourceType.VaultContentTrack;
      case 'VaultImage':
      case 'VaultVideo':
        return AnnouncementSourceType.VaultContentMedia;
    }
  })();

  const contentUrl = (() => {
    switch (data.__typename) {
      case 'VaultImage':
        return data.uploadedMedia?.imageOptimizedUrl;
      case 'VaultVideo':
        return data.uploadedMedia?.cdnUrl;
      case 'VaultTrack':
        return data.thumbnailMedia?.imageOptimizedUrl;
      default:
        return null;
    }
  })();

  const prefilledMessage = (() => {
    switch (data.__typename) {
      case 'VaultTrack':
        return `Hey, I just dropped a new track. Let me know what you think.`;
      case 'VaultVideo':
        return `Hey, I just dropped a new video. Let me know what you think.\n\n${shareLink}`;
      case 'VaultImage':
        return `Hey, I just dropped a new image. Let me know what you think.\n\n${shareLink}`;
      case 'VaultFolder':
        return `I just added new content to this folder in my Vault. Let me know what you think.\n\n${shareLink}`;
    }
  })();

  return (
    <BaseShareView
      attachedTrackId={data.__typename === 'VaultTrack' ? data.id : null}
      title={title}
      artistHandle={artistHandle}
      artistName={artistName}
      contentTypeText={vaultContentTypeText}
      sourceId={data.id}
      sourceType={sourceType}
      imageElement={
        <PreviewElement
          contentUrl={contentUrl}
          __typename={data.__typename}
          thumbnailUrl={
            data.__typename === 'VaultVideo' ? data.uploadedMedia?.videoScreenshotUrl : undefined
          }
        />
      }
      url={shareLink}
      prefilledMessage={prefilledMessage}
    />
  );
}

function PreviewElement({
  contentUrl,
  __typename,
  thumbnailUrl,
}: {
  contentUrl: string | null | undefined;
  thumbnailUrl?: string | null;
  __typename: 'VaultImage' | 'VaultVideo' | 'VaultFolder' | 'VaultTrack';
}) {
  const vaultTheme = useSnapshot(VaultThemeStore);
  const VaultThemeSnapshot = useSnapshot(VaultThemeStore);

  if (__typename === 'VaultFolder') {
    return (
      <View
        className={twMerge(
          'flex h-[240px] w-[240px] flex-col items-center justify-center rounded-xl bg-vault_text/5',
        )}
      >
        <View
          className={twMerge(
            'flex h-[111px] w-[114px] items-center justify-center bg-contain bg-no-repeat',
            VaultThemeSnapshot.mode === 'light'
              ? 'bg-folder-background-black-no-icon'
              : 'bg-folder-background-white',
          )}
        />
      </View>
    );
  }

  if (__typename === 'VaultTrack') {
    return (
      <View className="h-[240px] w-[240px] overflow-hidden rounded-xl bg-vault_text/5">
        <SongArtwork title="Song artwork" fullSize thumbnailUrl={contentUrl} />
      </View>
    );
  }

  return (
    <View className="h-[240px] w-[240px] overflow-hidden rounded-xl bg-vault_text/5">
      {contentUrl ? (
        <>
          {__typename === 'VaultVideo' ? (
            <video
              className="block h-[240px] w-[240px] cursor-pointer  rounded-md  bg-vault_text/10 object-cover"
              preload="metadata"
              controls={false}
              controlsList="nodownload"
              src={contentUrl}
              onClick={e => e.preventDefault()}
              poster={thumbnailUrl ?? undefined}
            />
          ) : (
            <Image src={contentUrl} alt="Vault image" className="h-full w-full object-cover" />
          )}
        </>
      ) : (
        <div
          className={twMerge(
            'flex aspect-square flex-shrink items-center justify-center  rounded-md bg-vault_background',
          )}
        >
          <div
            className={twMerge(
              'relative flex aspect-square items-center justify-center bg-vault_text/10',
              'h-full w-full',
            )}
          >
            <div
              className={twMerge(
                vaultTheme.mode === 'light'
                  ? 'bg-new-file-background-black'
                  : 'bg-new-file-background-white',
                'h-[50%] w-[40%] bg-cover bg-no-repeat',
              )}
            />
            <FontAwesomeIcon
              icon={__typename === 'VaultImage' ? faImage : faVideo}
              fontSize={24}
              className="absolute w-[38%] text-vault_text/40"
            />
          </div>
        </div>
      )}
    </View>
  );
}
