import { type RefObject, useCallback, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faVault } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  ArtistMainVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
  type MutationReportPlayStreamReleaseCampaignInput,
  ReleaseCampaignReportPlayStreamDocument,
} from '../../graphql/generated';
import { AppleMusicConnectState } from '../../hooks/appleMusic/useAppleMusicAuth';
import { SpotifyConnectState } from '../../hooks/spotify/useSpotifyAuth';

import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { VaultInsightsHeader } from '../../screens/vault/insightsHeader';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Image } from '../common/Image';
import { View } from '../common/View';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { VaultContents } from '../vault/VaultContents';
import { VaultHeader } from '../vault/VaultHeader';

import { useVaultEngagement } from './hooks/useVaultEngagement';

gql(/* GraphQL */ `
  fragment artistMainVaultView on Artist {
    id
    name
    instagramHandle
    description
    customWebsiteUrl
    spotifyUrl
    tiktokHandle
    linkValue
    createdAt
    profileImage {
      id
      artistFullProfileImageUrl: imageOptimizedUrl
    }
    membershipCardImage {
      id
      membershipCardImageUrl: imageOptimizedUrl
    }
    mainVault {
      id
      contentCount
      memberCount
      price
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      artist: artistProfile {
        id
        name
      }
      messageChannelId
      isUserArtistAdmin
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
    ...JoinArtist
  }

  query GetUserLastViewedTimeOnVault($vaultId: UUID!) {
    getUserLastViewedTimeOnVault(vaultId: $vaultId)
  }
`);

export function VaultView({
  artist,
  setShowProfileImage,
  scrollRef,
}: {
  setShowProfileImage: (header: boolean) => void;
  artist: FragmentType<ArtistMainVaultViewFragmentDoc>;
  scrollRef: RefObject<HTMLDivElement>;
}) {
  const spotifyAuth = SpotifyConnectState.useStore();
  const appleMusicAuth = AppleMusicConnectState.useStore();

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const source = searchParams.get('source');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const eventType = searchParams.get('eventType');

  const { isDesktop } = useWindow();

  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const {
    id,
    name,
    profileImage,
    mainVault: { id: vaultId, activeSubscription, isUserArtistAdmin },
    linkValue,
    membershipCardImage,
  } = getFragment(ArtistMainVaultViewFragmentDoc, artist);

  const profileImageUrl = vaultTheme.profileImageUrl ?? profileImage?.artistFullProfileImageUrl;

  // Update the last viewed time after the initial get query finishes
  useVaultEngagement({
    vaultId,
    triggerQuery: loggedInUser != null,
  });

  const { mutateAsync: reportPlayStream } = useMutation(ReleaseCampaignReportPlayStreamDocument, {
    retry: 3,
  });

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: activeSubscription,
    isOwner: isUserArtistAdmin,
  });

  const onJoinFreeClick = useCallback(async () => {
    if (isUserArtistAdmin) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: linkValue,
          artistName: name,
          imageUrl: membershipCardImage?.membershipCardImageUrl,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: invite,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        invite,
        c: smsCampaignResponseShortcode,
      });

      navigate(`/${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    isUserArtistAdmin,
    loginStatus,
    openBottomsheet,
    vaultId,
    linkValue,
    name,
    membershipCardImage?.membershipCardImageUrl,
    loggedInUser?.username,
    invite,
    smsCampaignResponseShortcode,
    sourceReleaseCampaignId,
    navigate,
  ]);

  useEffect(() => {
    const reportStream = async () => {
      if (
        !source ||
        !sourceReleaseCampaignId ||
        eventType !== 'streaming' ||
        (source === 'spotify' && !spotifyAuth.value?.code) ||
        (source === 'apple' && !appleMusicAuth.value?.userToken)
      ) {
        return;
      }

      const input: MutationReportPlayStreamReleaseCampaignInput = {
        releaseCampaignId: sourceReleaseCampaignId,
      };
      if (source === 'spotify') {
        input.spotifyAuthCode = spotifyAuth.value?.code;
      } else if (source === 'apple') {
        input.appleMusicAuthUserToken = appleMusicAuth.value?.userToken;
      }

      const { data } = await reportPlayStream({ input });
      if (
        data.reportPlayStreamReleaseCampaign?.__typename ===
          'MutationReportPlayStreamReleaseCampaignSuccess' ||
        data.reportPlayStreamReleaseCampaign === null
      ) {
        // TODO: Gel -> fix flow here
        openToast({
          text:
            data.reportPlayStreamReleaseCampaign === null
              ? 'The receipt has already been claimed'
              : "You've earned a receipt",
          variant: 'success',
        });
      }
    };

    reportStream();
  }, [
    appleMusicAuth.value,
    eventType,
    openToast,
    reportPlayStream,
    source,
    sourceReleaseCampaignId,
    spotifyAuth.value,
  ]);

  useEffect(() => {
    if (redirect === 'onboarding') {
      navigate(
        `${ROUTES.ONBOARDING}/username?artistHandle=${linkValue}&openBottomSheet=${bottomSheetType}`,
      );
    } else if (
      bottomSheetType === 'freeTierModal' &&
      loginStatus === LoginStatus.LOGGED_IN &&
      !activeSubscription &&
      !isUserArtistAdmin
    ) {
      onJoinFreeClick();
    }
  }, [
    activeSubscription,
    linkValue,
    loginStatus,
    navigate,
    onJoinFreeClick,
    bottomSheetType,
    redirect,
    isUserArtistAdmin,
  ]);

  return (
    <View
      className={twMerge(
        'relative flex min-h-full w-full flex-1 flex-col items-center text-white',
        isDesktop ? '-mt-20' : '-mt-32',
      )}
    >
      <View className="relative top-0 z-base aspect-square w-full overflow-clip">
        {profileImageUrl != null ? (
          <Image
            src={profileImageUrl}
            alt={name}
            className={twMerge(
              'z-below aspect-square w-full object-cover object-left-top',
              isDesktop && 'md2:rounded-t-[20px]',
            )}
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="z-below aspect-square w-full"
            svgClassName={isDesktop ? 'md2:rounded-t-[20px]' : undefined}
          />
        )}
        <View className="absolute top-20 z-base aspect-square w-full bg-gradient-to-b from-transparent to-vault_background to-80% md2:to-60%" />
      </View>

      <View className="z-above1 box-border w-full">
        <View className="-mt-[100px] box-border flex w-full flex-col bg-gradient-to-b from-transparent via-vault_text/3 to-vault_text/[0] px-4 py-6 md2:-mt-[290px] md2:px-6">
          <VaultHeader
            artist={artist}
            setShowProfileImage={setShowProfileImage}
            scrollRef={scrollRef}
            isOwner={isUserArtistAdmin}
            activeSubscriptionTier={activeSubscriptionFeatures?.tier}
          />
          {isUserArtistAdmin && <VaultInsightsHeader vaultId={vaultId} artistHandle={linkValue} />}
          <VaultContents
            vaultId={vaultId}
            artistLinkValue={linkValue}
            isOwner={isUserArtistAdmin}
            folderId={null}
            emptyState={{
              title: 'Nothing here, yet!',
              subTitle: 'Check back later for new uploads',
              icon: faVault,
            }}
          />
        </View>
      </View>
    </View>
  );
}
