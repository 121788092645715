import { useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import {
  faArrowUpFromBracket,
  faChevronDown,
  faEllipsis,
  faPen,
  faShare,
  faTrash,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import { ContentByIdDocument, VaultContentType } from '../../graphql/generated';
import { useCopy } from '../../hooks/useCopy';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useVaultContentActions } from '../../hooks/vault/useVaultContentActions';
import type { MediaViewBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from '../buttons/Button';
import { ContentCommentPreviewRow } from '../comments/ContentCommentPreviewRow';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { useEditVaultContent } from '../upload/useContentUpload';
import { RenderedMedia } from './RenderedMedia';

export const MediaBottomsheet = ({
  media,
  currentVideoRef,
  withVaultTheme,
}: MediaViewBottomsheetProps) => {
  const { loggedInUser } = useAuthContext();
  const { closeBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet, closeExtraBottomsheet } = useExtraBottomsheetContainer();
  const { removeContents } = useVaultContentActions();
  const { editVaultContent } = useEditVaultContent();

  const content = useQuery(ContentByIdDocument, {
    variables: {
      vaultContentId: media.id,
      asArtistId: loggedInUser?.artist?.id,
    },
    staleTime: 0,
    select(data) {
      const type = data.data.vaultContentById?.__typename;
      if (type !== 'VaultImage' && type !== 'VaultVideo') return null;

      return data.data.vaultContentById;
    },
  }).data;

  const ownedArtist = useOwnedArtist({ artistId: content?.vault.artist?.id });
  const isOwner = !!ownedArtist;
  const artistName = content?.vault.artist?.name;

  const shareLinkType = content?.__typename === 'VaultImage' ? 'i' : 'v';
  const mediaType = shareLinkType === 'i' ? 'Image' : 'Video';
  const shareLink = useMemo(() => {
    const path = content?.linkValue ? `/${shareLinkType}/${content.linkValue}` : `/${media.id}`;

    return generateShareLink({
      artistLinkValue: content?.vault.artist?.linkValue,
      path,
      inviteCode: isOwner ? null : loggedInUser?.inviteCode,
    });
  }, [
    content?.linkValue,
    content?.vault.artist?.linkValue,
    isOwner,
    loggedInUser?.inviteCode,
    media.id,
    shareLinkType,
  ]);
  const { copy } = useCopy({
    text: shareLink,
    successMessage: `${mediaType} link copied to clipboard!`,
  });

  const handlers = useSwipeable({
    onSwipedDown: () => {
      trackEvent({
        type: EVENTS.CLOSE_FULLSCREEN_MEDIA,
        properties: {
          contentId: media.id,
          contentTitle: media.title,
          vaultId: content?.vault.id ?? 'Unknown',
          artistId: content?.vault.artist?.id ?? null,
          onSwipe: true,
        },
      });

      closeBottomsheet();
    },
    trackMouse: true,
    swipeDuration: 200,
    delta: 200,
  });

  if (!content || !content.vault.artist) return null;

  const artistHandle = content.vault.artist.linkValue;
  const hasFreeAccess = content.featureAccess.some(
    feature => feature.feature.__typename === 'FreeVaultContent',
  );

  const header = useMemo(() => {
    return (
      <ViewHeader
        left={
          <Button
            label=""
            leadingIcon={faChevronDown}
            className={twMerge('text-[24px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
            onClick={closeBottomsheet}
            event={{
              type: EVENTS.CLOSE_FULLSCREEN_MEDIA,
              properties: {
                contentId: media.id,
                contentTitle: media.title,
                vaultId: content.vault.id,
                artistId: content.vault.artist?.id ?? null,
              },
            }}
          />
        }
        center={
          <View className="flex w-full flex-col items-center">
            <h2
              className={twMerge(
                'box-border w-full break-words pt-3 text-center font-title font-medium',
                withVaultTheme && 'text-vault_text',
                !artistName ? 'pb-3' : 'pb-1',
                media.title.length > 30 ? '!text-title-xs' : '!text-title-m',
              )}
            >
              {media.title}
            </h2>
            {artistName && (
              <p
                className={twMerge(
                  'box-border line-clamp-1 px-5 pb-3 font-title !text-base-m font-normal',
                  withVaultTheme ? 'text-vault_text/50' : 'text-base500',
                )}
              >
                {artistName}
              </p>
            )}
          </View>
        }
        right={
          <Button
            iconOnly
            leadingIcon={isOwner ? faEllipsis : faArrowUpFromBracket}
            label=""
            className={twMerge('text-[24px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
            onClick={() => {
              if (isOwner) {
                openExtraBottomsheet({
                  type: BOTTOMSHEET_TYPES.ACTION,
                  shared: {
                    withVaultTheme,
                  },
                  actionBottomsheetProps: {
                    withVaultTheme: true,
                    buttons: [
                      {
                        label: 'Share',
                        onClick: () => {
                          closeExtraBottomsheet();
                          closeBottomsheet();
                        },
                        leadingIcon: faShare,
                        type: 'secondary',
                        className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                        leadingIconClassName: 'text-[20px]',
                        href: artistNavigationPath(
                          content.vault.artist?.linkValue ?? '',
                          `/${shareLinkType}/${content.linkValue}/share`,
                        ),
                      },
                      {
                        label: 'Edit',
                        onClick: () => {
                          editVaultContent({
                            artistHandle: artistHandle,
                            withNavigation: true,
                            hasFreeAccess,
                            contentType:
                              shareLinkType === 'i'
                                ? VaultContentType.Image
                                : VaultContentType.Video,
                            title: content.title,
                            caption: content.caption,
                            downloadEnabled: content.downloadEnabled,
                            thumbnailUrl: media.url || null,
                            duration: content.__typename === 'VaultVideo' ? content.duration : null,
                            mediaId: media.id,
                            normalizedPeaks: null,
                            vaultContentId: content.id,
                          });
                          closeExtraBottomsheet();
                          closeBottomsheet();
                        },
                        leadingIcon: faPen,
                        type: 'secondary',
                        className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                        leadingIconClassName: 'text-[20px]',
                      },
                      {
                        label: 'Delete',
                        onClick: async () => {
                          await removeContents({
                            contentIds: [media.id],
                            vaultId: content.vault.id,
                            onSuccess: () => {
                              closeExtraBottomsheet();
                              closeBottomsheet();
                            },
                          });
                        },
                        leadingIcon: faTrash,
                        type: 'secondary',
                        className: 'text-vault_text items-center gap-2 text-[16px]/[20px]',
                        leadingIconClassName: 'text-[20px]',
                        requireConfirmation: true,
                        isExtraBottomsheet: true,
                        confirmType: 'delete',
                        confirmationSubText: 'Are you sure you want to delete this track?',
                      },
                    ],
                  },
                });
              } else {
                copy();
              }
            }}
          />
        }
        className="sticky top-0 z-previewFrame w-full bg-transparent pt-3"
      />
    );
  }, [
    artistHandle,
    artistName,
    closeBottomsheet,
    closeExtraBottomsheet,
    content,
    copy,
    editVaultContent,
    hasFreeAccess,
    isOwner,
    media.id,
    media.title,
    media.url,
    openExtraBottomsheet,
    removeContents,
    shareLinkType,
    withVaultTheme,
  ]);

  return (
    <View
      className="no-scrollbar fixed bottom-0 top-0 z-[1600] flex h-full w-full origin-bottom flex-col overflow-clip overscroll-none"
      swipeableHandlers={handlers}
    >
      <View
        className="absolute left-0 top-0 box-border h-screen w-screen blur-2xl"
        style={{
          backgroundImage: `url(${media.thumbnailUrl ?? media.url})`,
        }}
      />
      <View className="absolute w-full bg-vault_text_opposite/10 h-screen-safe" />
      <View className="z-[1601] flex h-screen flex-col items-center justify-between bg-gradient-to-b from-transparent to-vault_text_opposite to-40% ">
        {header}
        <View className="flex max-h-[65vh] max-w-[95%]">
          <RenderedMedia media={media} currentVideoRef={currentVideoRef} />
        </View>
        <View className="w-full self-end">
          <ContentCommentPreviewRow content={content} withVaultTheme={false} isExtraBottomSheet />
        </View>
      </View>
    </View>
  );
};
