import { type LegacyRef, useEffect, useState } from 'react';
import millify from 'millify';
import { gql } from '@soundxyz/gql-string';
import { useMenuContainer } from '../../../contexts/MenuContext';
import {
  FolderItemFragmentDoc,
  type FragmentType,
  getFragment,
  VaultContentType,
} from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { VaultContent } from '../../elements/VaultContent';

gql(/* GraphQL */ `
  fragment FolderItem on VaultFolder {
    id
    title
    createdAt
    parentVaultContentId
    childrenVaultCount
    nestedChildrenVaultContentCount
    linkValue
    vault {
      id
      activeSubscription {
        id
        createdAt
      }
    }
  }
`);

export const FolderItem = ({
  folderInfo,
  isOwner,
  containerRef,
  editingFolder,
  hasEllipsis,
  onClick,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc>;
  isOwner: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
  editingFolder: { id: string; title: string; isEditing: boolean } | null;
  hasEllipsis?: boolean;
  onClick: (() => void) | null;
}) => {
  const {
    id: folderId,
    title,
    createdAt,
    childrenVaultCount,
    vault: { activeSubscription },
  } = getFragment(FolderItemFragmentDoc, folderInfo);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: folderId });

  const isEditingFolder = editingFolder?.id === folderId;

  const couldBeNew =
    !isOwner && activeSubscription != null && activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  const subText = `${millify(childrenVaultCount, { precision: 0 })} ${childrenVaultCount === 1 ? 'file' : 'files'}`;

  return (
    <VaultContent
      containerRef={containerRef}
      contentId={folderId}
      type={VaultContentType.Folder}
      media={null}
      blurredMedia={null}
      title={isEditingFolder ? editingFolder?.title : title}
      createdAt={createdAt}
      isNew={isNew}
      isLoading={isLoading}
      subText={subText}
      hasEllipsis={hasEllipsis}
      onClick={() => {
        onClick?.();
      }}
      isSnippet={false}
    />
  );
};
