import { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { current, produce } from 'immer';
import { remove } from 'lodash-es';
import { proxy, useSnapshot } from 'valtio';
import { proxySet } from 'valtio/utils';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../contexts/AuthContext';
import {
  type ExecutionResultWithData,
  invalidateOperations,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '../graphql/client';
import {
  type FragmentType,
  GetMessageChannelDocument,
  type GetMessageChannelQuery,
  type MessageBubbleFragment,
  MessageChannelType,
  MessageChannelUpdatesDocument,
  type MessageChannelUpdatesSubscription,
  type ReplyToMessageFragment,
} from '../graphql/generated';
import {
  ArtistReactionsMessageReactionRowFragmentDoc,
  getFragment,
  makeFragmentData,
  MessageBubbleFragmentDoc,
  type MessageChannelViewFragment,
  MessageChannelViewFragmentDoc,
  MessageReactionRowFragmentDoc,
  MessageReactionTypeInput,
  MessageSubscriptionBubbleFragmentDoc,
  MyReactionsMessageReactionRowFragmentDoc,
  PinnedMessageChannelDocument,
  ReplyToMessageFragmentDoc,
  UpdateUserLastViewedMessageTimeOnVaultDocument,
} from '../graphql/generated';
import { type OnData, SubscriptionEffects } from '../graphql/wsClient';
import { LoginStatus } from '../types/authTypes';
import type { ReverseFragmentType, TypeFromGraphQLUnion } from '../types/gql';
import { getFromList, getManyFromList } from '../utils/arrayUtils';
import { createContainer } from '../utils/unstated';
import { useArtistHandle } from './useArtistHandle';
import { useOwnedArtist } from './useOwnedArtist';
import { useStableCallback } from './useStableCallback';

gql(/* GraphQL */ `
  query PinnedMessageChannel($input: QueryMessageChannelInput!, $asArtistId: UUID) {
    messageChannel(input: $input) {
      __typename

      ... on QueryMessageChannelSuccess {
        data {
          id
          vault {
            id
            artist: artistProfile {
              id
            }
            activeSubscription {
              id
              ...ActiveSubscriptionFeatures
            }
            tiers {
              __typename
              enabledFeatures {
                feature {
                  __typename
                }
              }
            }
          }
          pinnedMessages {
            id
            createdAt
            ...messageBubble
            ...pinnedMessage
          }
          ...MessageChannelLayoutInfo
        }
      }
    }
  }

  mutation UnpinAllMessages($input: MutationRemoveAllPinnedMessageInput!) {
    removeAllPinnedMessage(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const customPagesStore = proxy<
  Record<string, Record<string, Record<string, ExecutionResultWithData<GetMessageChannelQuery>>>>
>({});

const removedMessagesStore = proxy<Record<string, Set<string>>>({});

export const MessageInputStates = proxy<{
  [channelId: string]: {
    content: string;
    replyToMessage: FragmentType<ReplyToMessageFragmentDoc> | null;
    attachedTrack: string | null;
  };
}>({});

export const defaultMessageInputState = {
  content: '',
  replyToMessage: null,
  attachedTrack: null,
};

export function useMessageInputState({ channelId }: { channelId: string | undefined | null }) {
  const messageInputState = useSnapshot(MessageInputStates)[channelId ?? '_'];

  useEffect(() => {
    if (MessageInputStates[channelId ?? '_'] == null) {
      MessageInputStates[channelId ?? '_'] = defaultMessageInputState;
    }
  }, [channelId]);

  return messageInputState ?? defaultMessageInputState;
}

export const setReplyToMessage = ({
  message,
  channelId,
}: {
  message: FragmentType<ReplyToMessageFragmentDoc> | null;
  channelId: string;
}) => {
  MessageInputStates[channelId] = {
    ...(MessageInputStates[channelId] ?? defaultMessageInputState),
    replyToMessage: message,
  };
};

export const setContent = ({ content, channelId }: { content: string; channelId: string }) => {
  MessageInputStates[channelId] = {
    ...(MessageInputStates[channelId] ?? defaultMessageInputState),
    content,
  };
};

export const setAttachedTrack = ({
  trackId,
  channelId,
}: {
  trackId: string | null;
  channelId: string;
}) => {
  MessageInputStates[channelId] = {
    ...(MessageInputStates[channelId] ?? defaultMessageInputState),
    attachedTrack: trackId,
  };
};

export const useRemovedMessagesList = ({ artistHandle }: { artistHandle: string | undefined }) =>
  useSnapshot(removedMessagesStore)[artistHandle?.toLowerCase() || '_'];

const REFETCH_INTERVAL_NO_WEBSOCKET_CONNECTION = 2_000; // 2 seconds

export function useVaultMessagesCustomPages({
  artistHandle,
  messageChannelId,
}: {
  artistHandle: string | undefined;
  messageChannelId: string | undefined | null;
}) {
  const customPagesSnapshot =
    useSnapshot(customPagesStore)[artistHandle?.toLowerCase() || '_']?.[messageChannelId ?? '_'];

  useEffect(() => {
    if (customPagesSnapshot == null) {
      customPagesStore[artistHandle?.toLowerCase() || '_'] = { [messageChannelId ?? '_']: {} };
    }
  }, [artistHandle, customPagesSnapshot, messageChannelId]);

  return useMemo(
    () => Object.values(customPagesSnapshot ?? {}),
    [customPagesSnapshot],
  ) as ExecutionResultWithData<GetMessageChannelQuery>[];
}

export const ActiveArtistMessageChannel = proxy<{
  artistHandle: string | undefined;
  channelId: string | undefined;
  onNewMessage: ((args: { isOptimistic: boolean }) => void) | null;
  readyWebsocketChatChannelId: string | null;
}>({
  artistHandle: undefined,
  channelId: undefined,
  onNewMessage: null,
  readyWebsocketChatChannelId: null,
});

SubscriptionEffects.onCompleted(MessageChannelUpdatesDocument, ({ variables, result }) => {
  if (variables?.messageChannelId) {
    if (result.data.messageChannelUpdates.__typename !== 'SubscriptionMessageChannelUpdatesSuccess')
      return;

    if (result.data.messageChannelUpdates.data.__typename !== 'ReadyMessageSubscription') return;

    ActiveArtistMessageChannel.readyWebsocketChatChannelId = variables.messageChannelId;
  }
});

export function useSetActiveArtistChatHandle() {
  const artistHandle = useArtistHandle().artistHandle?.toLowerCase();

  useEffect(() => {
    if (!artistHandle) return;

    ActiveArtistMessageChannel.artistHandle = artistHandle;

    return () => {
      ActiveArtistMessageChannel.artistHandle = undefined;
    };
  }, [artistHandle]);
}

export function useSetActiveArtistChatChannelId({ channelId }: { channelId: string | undefined }) {
  useEffect(() => {
    if (!channelId) return;

    ActiveArtistMessageChannel.channelId = channelId;

    return () => {
      ActiveArtistMessageChannel.channelId = undefined;
    };
  }, [channelId]);
}

const VaultMessageChannelContainer = createContainer(() => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const { artistHandle, channelId, readyWebsocketChatChannelId } = useSnapshot(
    ActiveArtistMessageChannel,
  );

  const ownedArtist = useOwnedArtist({ artistHandle });
  const asArtistId = ownedArtist ? ownedArtist.id : undefined;

  const { mutate: setLastReadtime } = useMutation(UpdateUserLastViewedMessageTimeOnVaultDocument, {
    retry: 3,
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          type: 'updateUserLastViewedMessageTimeOnVault',
        },
      });
    },
  });

  const {
    data: messageChannelLayoutInfo,
    setQueryData: setPinnedMessagesData,
    isInitialLoading: pinnedMessagesInitialLoading,
  } = useQuery(PinnedMessageChannelDocument, {
    filterQueryKey: {
      artistHandle,
      channelId,
    },
    variables: !!(channelId || artistHandle) && {
      input: channelId
        ? { messageChannelId: channelId }
        : { artistHandle: { handle: artistHandle!, type: MessageChannelType.Vault } }, // We know artistHandle is defined here
      asArtistId,
    },
    staleTime: 0,
    select: data => {
      if (data.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') return null;
      return data.data.messageChannel.data;
    },
    enabled: loginStatus === 'LOGGED_IN',
  });

  const messageChannelId = messageChannelLayoutInfo?.id;

  const pinnedMessages = messageChannelLayoutInfo?.pinnedMessages;

  if (artistHandle) customPagesStore[artistHandle] ||= {};

  const customPages = useVaultMessagesCustomPages({ artistHandle, messageChannelId });

  const removedMessagesList = useRemovedMessagesList({ artistHandle });

  const filter = useCallback(
    (message: MessageChannelViewFragment['messages']['edges'][number]['node']) => {
      return !removedMessagesList?.has(message.id);
    },
    [removedMessagesList],
  );

  const websocketConnectionReady: boolean = messageChannelId
    ? readyWebsocketChatChannelId === messageChannelId
    : false;

  const query = useInfiniteQuery(GetMessageChannelDocument, {
    staleTime: 0,
    filterQueryKey: {
      artistHandle,
      channelId,
    },
    customPages,
    refetchInterval: websocketConnectionReady ? false : REFETCH_INTERVAL_NO_WEBSOCKET_CONNECTION,
    getNextPageParam: ({ data }) => {
      if (data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') return null;

      const messageChannel = getFragment(MessageChannelViewFragmentDoc, data.messageChannel?.data);
      return (
        messageChannel?.messages.pageInfo.hasNextPage && {
          after: messageChannel.messages.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!(channelId || artistHandle) &&
      (({ pageParam }) => {
        return {
          input: channelId
            ? { messageChannelId: channelId }
            : { artistHandle: { handle: artistHandle!, type: MessageChannelType.Vault } }, // We know artistHandle is defined here
          after: pageParam?.after ?? null,
          first: 40,
          asArtistId,
        };
      }),
    filter,
    list: ({ messageChannel }) => {
      if (messageChannel?.__typename !== 'QueryMessageChannelSuccess') return [];
      const messageChannelData = getFragment(MessageChannelViewFragmentDoc, messageChannel.data);

      return getManyFromList(messageChannelData?.messages.edges, edge => {
        const info = getFragment(MessageBubbleFragmentDoc, edge.node);

        return {
          ...edge.node,
          ...makeFragmentData(
            {
              ...info,
              reactionsSummary: info.reactionsSummary.filter(
                v => getFragment(MessageReactionRowFragmentDoc, v).type === 'EMOJI',
              ),
            },
            MessageBubbleFragmentDoc,
          ),
        };
      });
    },
    uniq: ({ id }) => id,
    enabled: artistHandle != null && loginStatus === LoginStatus.LOGGED_IN,
    order: [e => e.createdAt, 'desc'],
  });

  const { setInfiniteQueryData, entityStore } = query;

  const newMessage = useStableCallback(
    (
      message: ReverseFragmentType<
        TypeFromGraphQLUnion<
          TypeFromGraphQLUnion<
            MessageChannelUpdatesSubscription['messageChannelUpdates'],
            'SubscriptionMessageChannelUpdatesSuccess'
          >['data'],
          'CreateMessageSubscription'
        >
      > & { isOptimistic?: true },
    ) => {
      if (!artistHandle) {
        return {
          revert() {},
        };
      }
      const customPagesVaultMessages = customPagesStore[artistHandle]![channelId ?? '_']!;
      const messageChannel = query.firstPage?.data.messageChannel;

      const creator =
        message.asArtist != null
          ? ({
              __typename: 'MessageActorArtist',
              id: message.asArtist.id,
              artist: {
                ...message.asArtist,
                profileImage: {
                  ...message.asArtist.profileImage,
                  dominantColor: null,
                },
              },
            } satisfies Extract<
              MessageBubbleFragment['creator'],
              { __typename: 'MessageActorArtist' }
            >)
          : message.creatorUser != null
            ? ({
                __typename: 'MessageActorUser',
                id: message.creatorUser.id,
                user: {
                  ...message.creatorUser,
                  avatar: {
                    ...message.creatorUser.avatar,
                    cdnUrl: message.creatorUser.avatar.url,
                    dominantColor: null,
                  },
                },
              } satisfies Extract<
                MessageBubbleFragment['creator'],
                { __typename: 'MessageActorUser' }
              >)
            : null;

      const replyToCreator: ReplyToMessageFragment['creator'] | null =
        message.replyTo != null && message.replyTo.asArtist != null
          ? ({
              __typename: 'MessageActorArtist',
              id: message.replyTo.asArtist.id,
              artist: {
                ...message.replyTo.asArtist,
                profileImage: {
                  ...message.replyTo.asArtist.profileImage,
                  dominantColor: null,
                },
              },
            } satisfies Extract<
              ReplyToMessageFragment['creator'],
              { __typename: 'MessageActorArtist' }
            >)
          : message.replyTo != null && message.replyTo.creatorUser != null
            ? ({
                __typename: 'MessageActorUser',
                id: message.replyTo.creatorUser.id,
                user: {
                  ...message.replyTo.creatorUser,
                  avatar: {
                    ...message.replyTo.creatorUser.avatar,
                    cdnUrl: message.replyTo.creatorUser.avatar.url,
                    dominantColor: null,
                  },
                },
              } satisfies Extract<
                ReplyToMessageFragment['creator'],
                { __typename: 'MessageActorUser' }
              >)
            : null;

      if (
        messageChannel == null ||
        messageChannel.__typename !== 'QueryMessageChannelSuccess' ||
        creator == null
      ) {
        return {
          revert() {},
        };
      }

      const messageFrag = (entityStore.nodes[message.id] = {
        id: message.id,
        createdAt: message.createdAt,
        ...makeFragmentData(
          {
            id: message.id,
            createdAt: message.createdAt,
            content: message.content,
            pinnedPriority: null,
            creator,
            artistReactions: [],
            myReactions: [],
            reactionsSummary: [],
            vaultContent: message.vaultContent,
            ' $fragmentName': 'MessageBubbleFragment',
            messageAttachments: message.messageAttachments.map(attachment => ({
              ...attachment,
              media:
                attachment.media != null ? { ...attachment.media, mediumImageMedia: [] } : null,
            })),
            replyTo:
              message.replyTo != null && replyToCreator != null
                ? {
                    ...makeFragmentData(
                      {
                        content: message.replyTo.content,
                        id: message.replyTo.id,
                        createdAt: message.replyTo.createdAt,
                        vaultContent: message.replyTo.vaultContent,
                        messageAttachments: message.replyTo.messageAttachments.map(attachment => ({
                          ...attachment,
                          media:
                            attachment.media != null
                              ? { ...attachment.media, mediumImageMedia: [] }
                              : null,
                        })),
                        activeSubscriptionTier: message.replyTo.activeSubscriptionTier,
                        creator: replyToCreator,
                      },
                      ReplyToMessageFragmentDoc,
                    ),
                    id: message.replyTo.id,
                  }
                : null,
            replyToWasDeleted: false,
            activeSubscriptionTier: message.activeSubscriptionTier,
          },
          MessageBubbleFragmentDoc,
        ),
      });

      const messageChannelView = getFragment(MessageChannelViewFragmentDoc, messageChannel.data);

      const messageChannelId = messageChannel.data.id;

      const messages = {
        edges: [
          {
            cursor: message.id,
            node: messageFrag,
          },
        ],
        pageInfo: { endCursor: null, hasNextPage: true },
      } satisfies MessageChannelViewFragment['messages'];

      const messageChannelViewFragment = makeFragmentData(
        { ...messageChannelView, messages },
        MessageChannelViewFragmentDoc,
      );

      const page = {
        data: {
          messageChannel: {
            ...messageChannel,
            data: {
              ...messageChannel.data,
              ...messageChannelViewFragment,
            },
          },
        },
      } satisfies ExecutionResultWithData<GetMessageChannelQuery>;

      customPagesVaultMessages[message.id] = page;

      ActiveArtistMessageChannel.onNewMessage?.({ isOptimistic: message.isOptimistic ?? false });
      return {
        revert() {
          delete customPagesVaultMessages[message.id];
        },
        onMutationSuccess() {
          if (ActiveArtistMessageChannel.readyWebsocketChatChannelId === messageChannelId) return;

          Sentry.captureMessage('Message sent while websocket connection was not ready', {
            extra: {
              message,
              loggedInUser,
            },
            level: 'warning',
          });

          /**
           * If message was sent while the websocket connection was not ready,
           * We want to refetch the query to get the message confirmed back
           */
          invalidateOperations({
            operations: [GetMessageChannelDocument],
          });
        },
      };
    },
  );

  const removeMessage = useStableCallback(({ messageId }: { messageId: string }) => {
    if (!artistHandle) return { revert() {} };

    removedMessagesStore[artistHandle] ||= proxySet();

    const channelRemovedMessages = removedMessagesStore[artistHandle]!;

    channelRemovedMessages.add(messageId);

    return {
      revert() {
        channelRemovedMessages.delete(messageId);
      },
    };
  });

  const reactionUpdate = useStableCallback(
    ({
      id,
      created,
      reactionType,
      userId,
      reactionTotalCount,
      isArtistReaction,
      asArtistId,
      emojiKeyword,
    }: Pick<
      TypeFromGraphQLUnion<
        TypeFromGraphQLUnion<
          MessageChannelUpdatesSubscription['messageChannelUpdates'],
          'SubscriptionMessageChannelUpdatesSuccess'
        >['data'],
        'ReactionMessageSubscription'
      >,
      | 'id'
      | 'created'
      | 'reactionType'
      | 'userId'
      | 'isArtistReaction'
      | 'asArtistId'
      | 'emojiKeyword'
    > & { reactionTotalCount: number | null }) => {
      if (reactionType !== MessageReactionTypeInput.Emoji) {
        return {
          revert() {},
        };
      }

      const messageFrag = getFragment(MessageBubbleFragmentDoc, entityStore.nodes[id]);

      if (messageFrag) {
        const isActor = asArtistId ? asArtistId === ownedArtist?.id : userId === loggedInUser?.id;
        if (created) {
          const createdId = `${id}_${userId}_${reactionType}`;

          if (isActor) {
            const createdReaction: (typeof messageFrag)['myReactions'][number] = {
              id: createdId,
              type: reactionType,
              emojiKeyword,
              ...makeFragmentData(
                { id: createdId, type: reactionType, emojiKeyword },
                MyReactionsMessageReactionRowFragmentDoc,
              ),
            };
            messageFrag.myReactions.push(createdReaction);
          }

          if (isArtistReaction) {
            const createdReaction: (typeof messageFrag)['artistReactions'][number] = {
              id: createdId,
              type: reactionType,
              emojiKeyword,
              ...makeFragmentData(
                { id: createdId, type: reactionType, emojiKeyword },
                ArtistReactionsMessageReactionRowFragmentDoc,
              ),
            };
            messageFrag.artistReactions.push(createdReaction);
          }

          let reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
            const info = getFragment(MessageReactionRowFragmentDoc, value);

            return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
          });

          if (!reactionSummary) {
            reactionSummary = {
              count: 0,
              type: reactionType,
              emojiKeyword,
            };
            messageFrag.reactionsSummary.push(
              makeFragmentData(reactionSummary, MessageReactionRowFragmentDoc),
            );
            reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
              const info = getFragment(MessageReactionRowFragmentDoc, value);

              return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
            })!;
          }

          reactionSummary.count = reactionTotalCount ?? (reactionSummary.count ?? 0) + 1;

          return {
            revert() {
              if (reactionSummary) reactionSummary.count -= 1;

              if (isActor) {
                remove(messageFrag.myReactions, ({ id }) => id === createdId);
              }

              if (isArtistReaction) {
                remove(messageFrag.artistReactions, ({ id }) => id === createdId);
              }
            },
          };
        } else {
          const prevMyReaction = messageFrag.myReactions.find(
            reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
          );
          const prevArtistReaction = messageFrag.artistReactions.find(
            reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
          );
          if (isActor) {
            remove(
              messageFrag.myReactions,
              reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
            );
          }

          if (isArtistReaction) {
            remove(
              messageFrag.artistReactions,
              reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
            );
          }

          let reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
            const info = getFragment(MessageReactionRowFragmentDoc, value);

            return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
          });

          if (!reactionSummary) {
            reactionSummary = {
              count: 0,
              type: reactionType,
              emojiKeyword,
            };
            messageFrag.reactionsSummary.push(
              makeFragmentData(reactionSummary, MessageReactionRowFragmentDoc),
            );
            reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
              const info = getFragment(MessageReactionRowFragmentDoc, value);

              return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
            })!;
          }

          reactionSummary.count = reactionTotalCount ?? (reactionSummary.count ?? 0) - 1;
          return {
            revert() {
              if (isActor && prevMyReaction) {
                messageFrag.myReactions.push(prevMyReaction);
              }
              if (isArtistReaction && prevArtistReaction) {
                messageFrag.artistReactions.push(prevArtistReaction);
              }
              if (reactionSummary) ++reactionSummary.count;
            },
          };
        }
      }

      return {
        revert() {},
      };
    },
  );

  const vaultId = messageChannelLayoutInfo?.vault?.id;

  const onMessageChannelUpdate: OnData<MessageChannelUpdatesDocument> = useStableCallback(
    ({ data: { messageChannelUpdates } }) => {
      if (messageChannelUpdates.__typename === 'SubscriptionMessageChannelUpdatesSuccess') {
        if (messageChannelUpdates.data.__typename === 'ReadyMessageSubscription') {
          // noop already handled in module scope
        } else if (messageChannelUpdates.data.__typename === 'CreateMessageSubscription') {
          newMessage(getFragment(MessageSubscriptionBubbleFragmentDoc, messageChannelUpdates.data));

          if (vaultId) {
            setLastReadtime({ vaultId });
          }
        } else if (messageChannelUpdates.data.__typename === 'DeleteMessageSubscription') {
          const { id } = messageChannelUpdates.data;

          const page = customPagesStore[artistHandle || '_'];

          if (page?.[messageChannelUpdates.data.id]) {
            delete page[messageChannelUpdates.data.id];
          }

          setPinnedMessagesData(previous => {
            if (
              previous?.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess' ||
              previous?.data.messageChannel.data?.pinnedMessages == null
            ) {
              return undefined;
            }

            remove(previous.data.messageChannel.data.pinnedMessages, message => message.id === id);
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              for (const page of data.pages) {
                if (page.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') continue;
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannel.data,
                );
                if (messageChannel != null) {
                  const removed = remove(
                    messageChannel.messages.edges,
                    edge => edge.node.id === id,
                  );

                  if (removed.length > 0) {
                    if (messageChannel.messages.pageInfo.endCursor === id) {
                      messageChannel.messages.pageInfo.endCursor =
                        messageChannel.messages.edges[messageChannel.messages.edges.length - 1]
                          ?.cursor ?? null;
                    }

                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'PinMessageSubscription') {
          const { id, pinnedPriority } = messageChannelUpdates.data;

          setPinnedMessagesData(previous => {
            if (
              previous?.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess' ||
              previous?.data.messageChannel.data?.pinnedMessages == null
            ) {
              return undefined;
            }

            remove(
              previous.data.messageChannel.data.pinnedMessages,
              ({ id: messageId }) => messageId === id,
            );
          });

          invalidateOperations({
            operations: [PinnedMessageChannelDocument],
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              for (const page of data.pages) {
                if (page.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') continue;
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannel.data,
                );
                if (messageChannel != null) {
                  const message = messageChannel.messages.edges.find(edge => edge.node.id === id);
                  const messageFrag =
                    message &&
                    getFragment(MessageBubbleFragmentDoc, entityStore.nodes[message.node.id]);

                  if (messageFrag != null) {
                    messageFrag.pinnedPriority = pinnedPriority;

                    if (pinnedPriority != null) {
                      setPinnedMessagesData(previousPinnedMessageData => {
                        if (
                          previousPinnedMessageData?.data.messageChannel?.__typename !==
                            'QueryMessageChannelSuccess' ||
                          previousPinnedMessageData?.data.messageChannel.data == null
                        ) {
                          return undefined;
                        }

                        previousPinnedMessageData.data.messageChannel.data.pinnedMessages.splice(
                          pinnedPriority,
                          0,
                          current(messageFrag),
                        );
                      });
                    }

                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'UnpinAllMessageSubscription') {
          const { count } = messageChannelUpdates.data;

          setPinnedMessagesData(previous => {
            if (
              previous?.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess' ||
              previous?.data.messageChannel.data == null
            ) {
              return undefined;
            }

            previous.data.messageChannel.data.pinnedMessages = [];
          });

          invalidateOperations({
            operations: [PinnedMessageChannelDocument],
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              let remainingCount = count;

              for (const page of data.pages) {
                if (page.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') continue;
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannel.data,
                );
                if (messageChannel != null) {
                  for (const edge of messageChannel.messages.edges) {
                    const messageFrag = getFragment(
                      MessageBubbleFragmentDoc,
                      entityStore.nodes[edge.node.id],
                    );
                    if (messageFrag?.pinnedPriority != null) {
                      messageFrag.pinnedPriority = null;
                      remainingCount--;
                      if (remainingCount <= 0) {
                        break;
                      }
                    }
                  }

                  if (remainingCount <= 0) {
                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'ReactionMessageSubscription') {
          reactionUpdate(messageChannelUpdates.data);
        }
      }
    },
  );

  const isInitialLoading = !artistHandle || query.isInitialLoading || pinnedMessagesInitialLoading;

  const clearCustomPages = useCallback(() => {
    if (artistHandle && artistHandle in customPagesStore) {
      customPagesStore[artistHandle] = {};
    }
  }, [artistHandle]);

  return {
    onMessageChannelUpdate,
    query,
    pinnedMessages,
    newMessage,
    reactionUpdate,
    removeMessage,
    messageChannel: messageChannelLayoutInfo,
    isInitialLoading,
    clearCustomPages,
  };
});

export const useVaultMessageChannel = () => VaultMessageChannelContainer.useContainer();

export const VaultMessageChannelProvider = ({ children }: { children: ReactNode }) => (
  <VaultMessageChannelContainer.Provider>{children}</VaultMessageChannelContainer.Provider>
);
