import { useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash-es';
import millify from 'millify';
import { useInView } from 'react-intersection-observer';
import { Navigate, useNavigate } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faCalendarDays, faReceipt, faUsers } from '@soundxyz/font-awesome/pro-light-svg-icons';
import {
  faArrowUpRightFromSquare,
  faClose,
  faEllipsis,
  faEyeSlash,
  faGlobe,
  faPen,
  faTrash,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  type FragmentType,
  getFragment,
  MemberRowFragmentDoc,
  RsvpEventStatus,
  RsvpInsightHeaderFragmentDoc,
  RsvpInsightsDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useCopy } from '../../hooks/useCopy';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useVaultTheme, VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { InsightHeaderSkeleton } from '../../screens/EventInsightsPage';
import { Subtext } from '../../tamagui-components/elements/TextElements';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { BackButton } from '../buttons/BackButton';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { useRsvpEventForm } from '../rsvp/useRsvpEventForm';
import { SkeletonUserRow } from '../user/UserRow';
import { EmptyStateView } from '../views/EmptyStateView';
import { MemberRow } from './MemberRow';

gql(/* GraphQL */ `
  fragment RsvpInsightHeader on RsvpEventPrivateInfo {
    id
    createdAt
    title
    coverImage {
      id
      rsvpCoverImageUrl: imageOptimizedUrl
    }
    description
    eventDate
    linkValue
    newSubscriptionCount
    receiptCount
    status
    artist {
      id
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
  }

  query RsvpInsights($id: UUID!, $artistHandle: String!, $after: String, $first: Int) {
    allArtistMembershipReceipts(
      artistHandle: $artistHandle
      rsvpEventIds: [$id]
      after: $after
      first: $first
    ) {
      edges {
        cursor
        node {
          id
          rsvpDate: createdAt
          user {
            id
            ...userRow
          }
          membership {
            createdAt
            receipts
            vaultSubscription {
              ...MemberRow
            }
          }
          artist {
            id
            mainVault {
              id
              type
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const LIMIT = 20;

export function RsvpInsightView({
  rsvpEvent,
}: {
  rsvpEvent: FragmentType<RsvpInsightHeaderFragmentDoc>;
}) {
  const { id, linkValue, status, artist } = getFragment(RsvpInsightHeaderFragmentDoc, rsvpEvent);
  const { artistHandle } = useArtistHandle();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const exposeCampaignDataEnabled = useBetterGate('EXPOSE_CAMPAIGN_DATA') === 'enabled';

  const navigate = useNavigate();

  const { isDesktop } = useWindow();

  const { deleteRsvpEvent, rsvpEventDeleting, deactivateRsvpEvent, rsvpEventDeactivating } =
    useRsvpEventForm();

  useVaultTheme();

  const {
    orderedList: members,
    isError,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(RsvpInsightsDocument, {
    filterQueryKey: {
      artistHandle,
      id,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.allArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.allArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      !!id &&
      (({ pageParam }) => {
        return {
          id,
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ allArtistMembershipReceipts }) => {
      return allArtistMembershipReceipts.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const artistId = artist?.id;

  const ownedArtist = useOwnedArtist({ artistId });
  const inviteCode = !!ownedArtist ? null : loggedInUser?.inviteCode;

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: linkValue ? `/d/${linkValue}` : '/',
      inviteCode,
    });
  }, [artistHandle, linkValue, inviteCode]);

  const { copy } = useCopy({ successMessage: 'Copied to clipboard', text: link });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const isOwner = !!ownedArtist;

  const isActive = status === RsvpEventStatus.Active;

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    const buttonClassName =
      'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

    return compact([
      isActive && {
        label: 'Share',
        trailingIcon: faArrowUpFromBracket,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          if (!artistHandle) {
            return;
          }

          if (!isDesktop) {
            navigate(artistNavigationPath(artistHandle, `/d/${linkValue}/share`));
            closeBottomsheet();
          } else {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.SHARE_DROP,
              shareDropBottomsheetProps: {
                dropSlug: linkValue,
                artistHandle,
                type: 'rsvp',
              },
              shared: {
                withVaultTheme: true,
                preventOutsideAutoClose: true,
              },
            });
          }
        },
      },
      id &&
        isActive && {
          label: 'View public page',
          trailingIcon: faGlobe,
          type: 'secondary',
          className: buttonClassName,
          onClick: () => {
            window.open(link, '_blank');
            closeBottomsheet();
          },
        },
      id && {
        label: 'Edit',
        trailingIcon: faPen,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          trackEvent({
            type: EVENTS.START_RSVP_EDIT,
            properties: {
              dropId: id,
              artistHandle,
            },
          });
          navigate(artistNavigationPath(artistHandle, `/rsvp/edit/${id}`));
          closeBottomsheet();
        },
      },
      id &&
        status &&
        isActive && {
          label: 'End drop',
          trailingIcon: faEyeSlash,
          type: 'secondary',
          className: buttonClassName,
          loading: rsvpEventDeactivating,
          onClick: async () => {
            openBottomsheet({
              type: 'CONFIRMATION',
              confirmationBottomsheetProps: {
                subText:
                  'Are you sure you want to end this drop? Your drop will not be visible to others and this cannot be reversed.',
                confirmButtonText: 'Confirm',
                onConfirm: async () => {
                  await deactivateRsvpEvent(id);
                  closeBottomsheet();
                },
              },
            });
          },
        },
      id && {
        label: 'Delete',
        trailingIcon: faTrash,
        type: 'secondary',
        className: buttonClassName,
        loading: rsvpEventDeleting,
        onClick: async () => {
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: 'Are you sure you want to delete this drop?',
              confirmButtonText: 'Delete',
              onConfirm: async () => {
                await deleteRsvpEvent(id);
                closeBottomsheet();
                navigate(artistNavigationPath(artistHandle, '/drops'));
              },
            },
          });
        },
      },
    ]);
  }, [
    isActive,
    id,
    status,
    rsvpEventDeactivating,
    rsvpEventDeleting,
    isDesktop,
    closeBottomsheet,
    navigate,
    artistHandle,
    linkValue,
    openBottomsheet,
    link,
    deactivateRsvpEvent,
    deleteRsvpEvent,
  ]);

  const renderItem = useCallback(
    (_index: number, item: (typeof members)[number]) => {
      const {
        id,
        user,
        membership: { vaultSubscription, createdAt, receipts },
        artist,
        rsvpDate,
      } = item;

      const subscription = getFragment(MemberRowFragmentDoc, vaultSubscription);

      return (
        <MemberRow
          id={id}
          source="subscribers"
          createdAt={createdAt}
          user={user}
          phone={subscription?.phone ?? null}
          email={subscription?.email ?? null}
          userLocation={subscription?.userLocation ?? null}
          isTrial={subscription?.isTrial ?? null}
          artistMembership={item.membership}
          tier={subscription != null ? subscription.tier : null}
          className="pb-6"
          withVaultTheme
          artist={artist}
          subText={{
            custom: `RSVP'd ${formatDateString({ date: rsvpDate, format: 'month_day_year' })}`,
          }}
          receipts={exposeCampaignDataEnabled ? receipts : undefined}
        />
      );
    },
    [exposeCampaignDataEnabled],
  );

  const Header = useCallback(() => {
    if (rsvpEvent == null) {
      return <InsightHeaderSkeleton />;
    }

    return (
      <View className="mb-3 flex flex-col gap-5 py-4">
        <InsightHeader rsvpEvent={rsvpEvent} rsvpEventLink={link} isActive={isActive} />

        <View className="flex flex-col gap-4">
          <View className="flex flex-col gap-1">
            <h2
              className={twMerge(
                'font-title font-medium text-vault_text',
                exposeCampaignDataEnabled ? 'text-[18px]/[22px]' : 'text-[22px]/[26px]',
              )}
            >
              {exposeCampaignDataEnabled ? 'Vault members' : 'Members'}
            </h2>
            {exposeCampaignDataEnabled && (
              <Text className="font-base text-[14px] font-normal text-vault_text/60">
                Only includes fans with a Vault account
              </Text>
            )}
          </View>
        </View>
      </View>
    );
  }, [exposeCampaignDataEnabled, isActive, link, rsvpEvent]);

  const EmptyState = useCallback(() => {
    if (isLoading) {
      return (
        <>
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
        </>
      );
    }

    if (isError) {
      return (
        <ErrorView onRetryClick={refetch} loggingType="rsvp_insights_page_retry" withVaultTheme />
      );
    }

    return (
      <EmptyStateView
        className="mt-12"
        icon={exposeCampaignDataEnabled ? faReceipt : faUsers}
        iconClassName={twMerge(
          'mb-4',
          exposeCampaignDataEnabled ? 'text-[22px] text-vault_text' : 'text-vault_text/60',
        )}
        customTitle={
          <Text
            className={twMerge(
              'font-title font-medium',
              exposeCampaignDataEnabled
                ? 'text-[15px]/[20px] text-vault_text'
                : 'text-[22px] text-vault_text/60',
            )}
          >
            {exposeCampaignDataEnabled ? 'No Vault members have RSVPed' : 'No RSVPs yet'}
          </Text>
        }
        customSubtitle={
          exposeCampaignDataEnabled ? (
            <Subtext
              text="Share this drop with fans"
              fontWeight={500}
              color={vaultTheme.textColor}
              size="sm"
            />
          ) : (
            <Text
              className="mt-2 font-normal text-vault_text/60 underline"
              onClick={rsvpEvent != null ? copy : undefined}
            >
              Copy URL and share your drop
            </Text>
          )
        }
        subtitleClassName="text-vault_text/60"
        buttonText={exposeCampaignDataEnabled ? 'Share campaign' : undefined}
        onButtonClick={exposeCampaignDataEnabled && rsvpEvent != null ? copy : undefined}
        withVaultTheme
      />
    );
  }, [
    isLoading,
    isError,
    exposeCampaignDataEnabled,
    vaultTheme.textColor,
    rsvpEvent,
    copy,
    refetch,
  ]);

  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} withVaultTheme />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      headerRight={
        <Button
          label=""
          iconOnly
          leadingIcon={faEllipsis}
          onClick={() => {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACTION,
              actionBottomsheetProps: {
                buttons,
                className: 'w-full md2:w-80',
                withVaultTheme: true,
              },
            });
          }}
          className="text-[24px] text-vault_text outline-none"
        />
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <Virtuoso
        data={members}
        itemContent={renderItem}
        components={{
          Header,
          EmptyPlaceholder: EmptyState,
        }}
        className="h-full w-full overflow-x-hidden"
      />

      <div ref={bottomRef} />
    </DefaultLayout>
  );
}

function InsightHeader({
  rsvpEvent,
  rsvpEventLink,
  isActive,
}: {
  rsvpEvent: FragmentType<RsvpInsightHeaderFragmentDoc>;
  rsvpEventLink: string;
  isActive: boolean;
}) {
  const { title, coverImage, description, newSubscriptionCount, receiptCount, artist } =
    getFragment(RsvpInsightHeaderFragmentDoc, rsvpEvent);

  const coverImageUrl =
    coverImage?.rsvpCoverImageUrl ?? artist.profileImage?.artistFullProfileImageUrl;

  return (
    <View className="flex flex-col items-start justify-start gap-6">
      <View className="flex w-full flex-col items-start justify-start gap-4">
        <button
          className={twMerge(
            'w-full appearance-none border-none bg-transparent outline-none focus:outline-none',
            isActive ? 'cursor-pointer' : 'cursor-text select-text',
          )}
          onClick={() => isActive && window.open(rsvpEventLink, '_blank')}
        >
          <View className="flex w-full flex-row items-center justify-start gap-2">
            <View
              className={twMerge(
                'flex h-[56px] w-[56px] flex-shrink-0 items-center justify-center rounded-md',
                !!coverImageUrl ? 'bg-transparent' : 'bg-vault_text',
              )}
            >
              {coverImageUrl && (
                <Image
                  src={coverImageUrl}
                  alt="Cover Image"
                  className="h-full w-full rounded-md object-cover"
                />
              )}
            </View>
            <View className="flex flex-col items-start justify-start gap-1">
              <View className="flex items-center">
                <Text className="line-clamp-1   text-left font-title text-[29px] font-medium text-vault_text">
                  {title}
                </Text>
                {isActive && (
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="ml-2 text-[18px] text-vault_text"
                  />
                )}
              </View>
              <View className="flex flex-row items-center gap-1">
                <FontAwesomeIcon icon={faCalendarDays} className="text-[14px] text-vault_text/60" />
                <Text className="mt-[1px] font-base text-[14px]/[18px] font-normal text-vault_text opacity-60">
                  RSVP {!isActive ? '• Canceled' : ''}
                </Text>
              </View>
            </View>
          </View>
        </button>
        {!!description && (
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-50">
            {description}
          </Text>
        )}
      </View>

      <View className="flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">RSVPs</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total signups</Text>
        </View>
      </View>
    </View>
  );
}
