import { useMemo } from 'react';
import { type GetProps, type GetThemeValueForKey, styled, XStack, YStack } from 'tamagui';
import { useSnapshot } from 'valtio';
import { COLOR } from '../../constants/colorConstants';
import { type VaultThemeState, VaultThemeStore } from '../../hooks/useVaultTheme';
import { getColorWithOpacity } from '../../utils/colorUtils';
import { Radio } from '../elements/Radio';
import { Label, Subtext } from '../elements/TextElements';

type RadioSelectVariant = 'primary' | 'secondary';

type VariantStyle = {
  backgroundColor: string;
  color: string;
  activeColor: string;
};

const VARIANT_STYLES = {
  primary: (vaultTheme: VaultThemeState | null): VariantStyle => ({
    backgroundColor: getColorWithOpacity(vaultTheme?.textColor ?? COLOR.base_text, 0.1),
    activeColor: vaultTheme?.accentColor ?? COLOR.base_accent,
    color: vaultTheme?.textColor ?? COLOR.base_text,
  }),
  secondary: (vaultTheme: VaultThemeState | null): VariantStyle => ({
    backgroundColor: getColorWithOpacity(vaultTheme?.accentColor ?? COLOR.base_accent, 0.9),
    activeColor: vaultTheme?.accentTextColor ?? COLOR.base_accent_text,
    color: vaultTheme?.accentTextColor ?? COLOR.base_accent_text,
  }),
} as const;

const RadioButton = styled(XStack, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderWidth: 1,
  borderRadius: 16,
  paddingHorizontal: 16,
  paddingVertical: 12,
});

const ContentColumn = styled(YStack, {
  alignItems: 'flex-start',
  gap: 2,
  flexShrink: 1,
});

const RadioRow = styled(XStack, {
  alignItems: 'center',
  gap: 12,
  flexShrink: 1,
});

type InfoRadioSelectProps = {
  label: string;
  subText?: string;
  useVaultTheme: boolean;
  selected: boolean;
  variant?: RadioSelectVariant;
  subTextNumberOfLines?: number;
  disabled?: boolean;
  onPress?: () => void;
} & Omit<GetProps<typeof XStack>, 'style'>;

export const InfoRadioSelect = ({
  disabled,
  useVaultTheme,
  onPress,
  selected,
  label,
  subText,
  variant = 'primary',
  subTextNumberOfLines = 2,
  ...rest
}: InfoRadioSelectProps) => {
  const vaultTheme = useSnapshot(VaultThemeStore);
  const effectiveTheme = useVaultTheme ? vaultTheme : null;

  const variantStyle = useMemo(
    () => VARIANT_STYLES[variant](effectiveTheme),
    [variant, effectiveTheme],
  );

  return (
    <RadioButton
      backgroundColor={variantStyle.backgroundColor as GetThemeValueForKey<'backgroundColor'>}
      opacity={disabled ? 0.5 : 1}
      borderColor={
        (selected
          ? variantStyle.activeColor
          : COLOR.transparent) as GetThemeValueForKey<'borderColor'>
      }
      onPress={() => {
        if (!disabled) {
          onPress?.();
        }
      }}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      role="radio"
      aria-checked={selected}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      pressStyle={{
        opacity: disabled ? 0.5 : 0.8,
      }}
      {...rest}
    >
      <RadioRow>
        <Radio
          selected={selected}
          selectedColor={variantStyle.activeColor}
          unselectedColor={variantStyle.color}
          disabled={disabled}
          onChange={onPress}
        />
        <ContentColumn>
          <Label label={label} color={variantStyle.color} numberOfLines={1} />
          {!!subText && (
            <Subtext
              color={variantStyle.color}
              numberOfLines={subTextNumberOfLines}
              text={subText}
            />
          )}
        </ContentColumn>
      </RadioRow>
    </RadioButton>
  );
};
