import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import {
  type ContentByIdQuery,
  ContentCommentRowFragmentDoc,
  getFragment,
} from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { generateHashFromUserId } from '../../hooks/useUserDisplayName';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { pluralizeText } from '../../utils/textUtils';
import { AnimateIn } from '../common/AnimateIn';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ProfileImage } from '../user/ProfileImage';

export function ContentCommentPreviewRow({
  content,
  withVaultTheme,
  isExtraBottomSheet,
}: {
  content: Exclude<NonNullable<ContentByIdQuery['vaultContentById']>, 'VaultFolder'>;
  withVaultTheme: boolean;
  isExtraBottomSheet?: boolean;
}) {
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet } = useExtraBottomsheetContainer();

  const isEmptyState = content.commentPinnedMessage == null;
  const headerText = isEmptyState
    ? '0 Comments'
    : `${content.commentMessageCount} ${pluralizeText({ count: content.commentMessageCount, text: 'comment' })}`;
  const commentFrag = getFragment(ContentCommentRowFragmentDoc, content.commentPinnedMessage);

  const commentUser =
    commentFrag?.creator.__typename === 'MessageActorUser' ? commentFrag?.creator.user : null;
  const commentArtist =
    commentFrag?.creator.__typename === 'MessageActorArtist' ? commentFrag?.creator.artist : null;

  const onCommentPress = ({ autoFocusInput }: { autoFocusInput: boolean }) => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.CONTENT_COMMENTS,
        contentType:
          content.__typename === 'VaultTrack'
            ? 'track'
            : content.__typename === 'VaultImage'
              ? 'photo'
              : 'video',
        component: 'ContentCommentPreviewRow',
        artistId: content.vault.artist?.id,
        contentId: content.id,
      },
    });
    const bottomsheetFn = isExtraBottomSheet ? openExtraBottomsheet : openBottomsheet;
    bottomsheetFn({
      type: 'CONTENT_COMMENTS',
      shared: {
        withVaultTheme,
      },
      contentCommentsBottomsheetProps: {
        contentId: content.id,
        contentTitle: content.title,
        autoFocusInput,
        withVaultTheme,
      },
    });
  };

  const ownedArtist = useOwnedArtist({ artistId: content.vault.artist?.id });

  return (
    <AnimateIn
      key={content.id}
      from="translate-y-[200px] opacity-0"
      to="opacity-100 translate-y-0"
      duration={800}
    >
      <View
        onClick={() => {
          onCommentPress({ autoFocusInput: false });
        }}
        className={twMerge(
          'mx-2 mb-2 flex flex-col items-start justify-center gap-3 rounded-xl p-4',
          withVaultTheme ? 'bg-vault_text/20' : 'bg-base800',
        )}
      >
        <Text className={twMerge('!text-base-l font-medium', withVaultTheme && 'text-vault_text')}>
          {headerText}
        </Text>
        <hr className="h-px w-full border-0 bg-white opacity-10" />
        {commentFrag && (
          <View className="flex flex-row items-center gap-3">
            <Text
              className={twMerge('line-clamp-2 !text-base-m', withVaultTheme && 'text-vault_text')}
            >
              <span className="pr-1 font-semibold">
                {commentArtist?.name ||
                  commentUser?.username ||
                  generateHashFromUserId(commentFrag.creator.id)}
              </span>
              {commentFrag.content}
            </Text>
          </View>
        )}
        <View className="flex flex-row items-center gap-[10px] pt-1">
          <ProfileImage
            className="h-[24px] w-[24px]"
            profileImageUrl={
              ownedArtist?.profileImage?.artistSmallProfileImageUrl ||
              loggedInUser?.avatar?.userSmallProfileImageUrl ||
              loggedInUser?.avatar?.cdnUrl
            }
            onClick={undefined}
          />
          <input
            className={twMerge(
              'w-full border-0 bg-transparent font-base !text-base-m focus:font-normal focus:outline-none',
              withVaultTheme ? 'text-vault_text placeholder:text-vault_text/50' : 'text-white',
            )}
            placeholder="Add a comment..."
            readOnly
            onClick={ev => {
              ev.stopPropagation();
              ev.preventDefault();

              onCommentPress({ autoFocusInput: true });
            }}
          />
        </View>
      </View>
    </AnimateIn>
  );
}
