import React, { useLayoutEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import {
  type FragmentType,
  getFragment,
  makeFragmentData,
  MessageBubbleFragmentDoc,
  type ReplyToMessageFragment,
} from '../../graphql/generated';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { dateToTime } from '../../utils/dateUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MessageBubbleInner } from './MessageBubble';

export const ReplyToMessageBubble = ({
  replyToMessage,
  areSubscriptionTierBadgesVisible,
  isAuthor,
  isVaultArtist,
  artistProfileImageUrl,
  onProfileImageClick,
}: {
  replyToMessage: ReplyToMessageFragment;
  areSubscriptionTierBadgesVisible: boolean;
  isVaultArtist: boolean;
  isAuthor: boolean;
  artistProfileImageUrl: string | null | undefined;
  onProfileImageClick: (isMessageUser: boolean) => void;
}) => {
  const { closeOverlay } = useOverlayContainer();

  const messageFrag = makeFragmentData(
    {
      id: replyToMessage.id,
      content: replyToMessage.content,
      createdAt: replyToMessage.createdAt,
      creator: replyToMessage.creator,
      artistReactions: [],
      myReactions: [],
      replyTo: null,
      pinnedPriority: null,
      reactionsSummary: [],
      vaultContent: replyToMessage.vaultContent,
      messageAttachments: replyToMessage.messageAttachments,
      replyToWasDeleted: false,
      activeSubscriptionTier: replyToMessage.activeSubscriptionTier,
    },
    MessageBubbleFragmentDoc,
  );

  return (
    <View
      className={twMerge(
        'mb-4 flex w-full select-none flex-row items-end',
        isAuthor ? 'justify-end' : '-translate-x-[34px] justify-start',
      )}
      onClick={() => closeOverlay()}
    >
      <MessageBubbleInner
        message={messageFrag}
        areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
        isAuthor={isAuthor}
        isVaultArtist={isVaultArtist}
        type="elevated"
        artistProfileImageUrl={artistProfileImageUrl}
        onProfileImageClick={() => onProfileImageClick(false)}
      />
    </View>
  );
};

type ElevatedReplyToMessageProps = {
  messageFrag: FragmentType<MessageBubbleFragmentDoc>;
  replyToMessage: ReplyToMessageFragment;
  areSubscriptionTierBadgesVisible: boolean;
  isReplyToAuthor: boolean;
  isAuthor: boolean;
  isOwner: boolean;
  vaultArtistId: string | undefined;
  artistProfileImageUrl: string | null | undefined;
  vaultId: string | undefined;
};

export const ElevatedReplyToMessage: React.FC<ElevatedReplyToMessageProps> = ({
  messageFrag,
  replyToMessage,
  isReplyToAuthor,
  isAuthor,
  isOwner,
  areSubscriptionTierBadgesVisible,
  vaultArtistId,
  artistProfileImageUrl,
  vaultId,
}) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { closeOverlay } = useOverlayContainer();

  const messageBubbleRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const message = getFragment(MessageBubbleFragmentDoc, messageFrag);

  const openProfileBottomsheet = (isMessageUser: boolean) => {
    const selectedMessage = isMessageUser ? message : replyToMessage;
    const user =
      selectedMessage.creator.__typename === 'MessageActorUser'
        ? selectedMessage.creator.user
        : null;
    const asArtist =
      selectedMessage.creator.__typename === 'MessageActorArtist'
        ? selectedMessage.creator.artist
        : null;

    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE,
        userId: selectedMessage.creator.id,
      },
    });

    closeOverlay();
    openBottomsheet({
      type: 'USER_PROFILE',
      shared: {
        withVaultTheme: true,
      },
      userProfileBottomsheetProps: {
        vaultId,
        userId: selectedMessage.creator.id,
        joinDate: null,
        vaultArtistId,
        avatarUrl:
          asArtist?.profileImage?.artistSmallProfileImageUrl ||
          user?.avatar.userSmallProfileImageUrl ||
          user?.avatar.cdnUrl,
        username: asArtist?.linkValue || user?.username,
        displayName: asArtist?.name || user?.computedDisplayName || '@username',
        showAdminOptions: isOwner,
        isVaultArtist: asArtist?.id === vaultArtistId,
        activeSubscriptionTier: selectedMessage.activeSubscriptionTier,
        withVaultTheme: true,
        userLocation: null,
      },
    });
  };

  useLayoutEffect(() => {
    if (messageBubbleRef.current) {
      const messageBubbleHeight = messageBubbleRef.current.offsetHeight;
      const desiredOffset = messageBubbleHeight + 16;
      setOffset(-desiredOffset);
    }
  }, [messageFrag]);

  return (
    <View containerRef={messageBubbleRef}>
      <View
        className={twMerge(
          isAuthor ? 'right-0' : 'left-0',
          'absolute mb-2 flex flex-col gap-2',
          `bottom-full w-[calc(100vw-74px)] md:w-[540px]`,
          `translate-y-[${offset}px]`,
          'z-above1',
        )}
      >
        <Text className="text-center !text-base-xs text-vault_text/50">
          {dateToTime(replyToMessage.createdAt)}
        </Text>
        <ReplyToMessageBubble
          replyToMessage={replyToMessage}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          isAuthor={isReplyToAuthor}
          isVaultArtist={!!replyToMessage.creator && vaultArtistId == replyToMessage.creator.id}
          onProfileImageClick={openProfileBottomsheet}
          artistProfileImageUrl={artistProfileImageUrl}
        />
        <Text className="text-center !text-base-xs text-vault_text/50">
          {dateToTime(message.createdAt)}
        </Text>
      </View>
      <MessageBubbleInner
        className={twMerge(!isAuthor && '-translate-x-[34px]')}
        message={messageFrag}
        areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
        isAuthor={isAuthor}
        isVaultArtist={message.creator?.id === vaultArtistId}
        type="elevated"
        artistProfileImageUrl={artistProfileImageUrl}
        onProfileImageClick={() => openProfileBottomsheet(true)}
      />
    </View>
  );
};
