import { type LegacyRef, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { heardVaults, useAudioController } from '../../../audio/AudioController';
import { useMenuContainer } from '../../../contexts/MenuContext';
import {
  type FragmentType,
  getFragment,
  TrackItemFragmentDoc,
  VaultContentType,
} from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';

import { VaultContent } from '../../elements/VaultContent';

gql(/* GraphQL */ `
  fragment TrackItem on VaultTrack {
    id
    title
    caption
    linkValue
    createdAt

    vaultId
    duration
    commentMessageCount
    parentVaultContentId
    normalizedPeaks

    isFullVersionAvailable
    blurredThumbnailMediaUrl
    thumbnailMedia {
      id
      imageOptimizedUrl(input: { width: 480, height: 480 })
    }
    freeTierSnippet {
      id
      duration
    }

    featureAccess {
      feature {
        __typename
      }
    }
    downloadEnabled

    vault {
      id
      isUserArtistAdmin
      artist: artistProfile {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
      }
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const TrackItem = ({
  track,
  containerRef,
  onPlayClick,
  hasEllipsis,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  onPlayClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const {
    title,
    createdAt,
    duration,
    id: trackId,
    vault,
    isFullVersionAvailable,
    thumbnailMedia,
    blurredThumbnailMediaUrl,
    freeTierSnippet,
  } = getFragment(TrackItemFragmentDoc, track);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { activeTrackId, playing } = useAudioController();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: trackId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;
  const isActiveTrack = activeTrackId === trackId;
  const heardVaultIds = useSnapshot(heardVaults).vaultIds;
  const isPlaying = playing && isActiveTrack;

  const couldBeNew =
    !isOwner &&
    !heardVaultIds.has(trackId) &&
    vault.activeSubscription != null &&
    vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  const durationToUse = isFullVersionAvailable ? duration : freeTierSnippet?.duration ?? duration;

  return (
    <VaultContent
      containerRef={containerRef}
      type={VaultContentType.Track}
      title={title}
      media={thumbnailMedia?.imageOptimizedUrl}
      blurredMedia={blurredThumbnailMediaUrl}
      createdAt={createdAt}
      isNew={isNew}
      isLoading={isLoading}
      locked={isLocked}
      duration={durationToUse}
      contentId={trackId}
      isPlaying={isPlaying}
      isSnippet={!isFullVersionAvailable}
      hasEllipsis={hasEllipsis}
      onClick={onPlayClick}
    />
  );
};
