import { gql } from '@soundxyz/gql-string';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistProfileSettingsDocument,
  AuthorizedUserProfilesDocument,
  AuthUserDocument,
  UpdateUserProfileDocument,
} from '../graphql/generated';

gql(/* GraphQL */ `
  mutation UpdateUserProfile($input: MutationUpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserProfileSuccess {
        data {
          id
          ...userSettingsView
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserProfileDocument],
  refetch: [ArtistProfileSettingsDocument, AuthUserDocument, AuthorizedUserProfilesDocument],
});

export function useEditProfile() {
  const { openToast } = useToast();
  const { mutateAsync: updateSubscriberProfile } = useMutation(UpdateUserProfileDocument, {
    onError: e => {
      openToast({
        text: e.message,
        variant: 'error',
      });
    },
  });

  return { updateSubscriberProfile };
}
