import { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { proxy, useSnapshot } from 'valtio';
import { faFolderPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { TextInputModal } from '../../components/bottomsheets/TextInputModal';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useFullScreenAudioPlayerState } from '../../components/main/AudioPlayer';
import { LargePlayIndicatorBars } from '../../components/track/PlayIndicatorBars';
import { FolderItem } from '../../components/vault/items/Folder';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { useInfiniteQuery, useQuery } from '../../graphql/client';
import { GetAllFoldersDocument, GetCurrentFolderDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../hooks/vault/useVaultContentActions';
import {
  FOLDER_TITLE_MAX_LENGTH,
  FOLDER_TITLE_MIN_LENGTH,
  useVaultFolder,
} from '../../hooks/vault/useVaultFolder';
import { getManyFromList } from '../../utils/arrayUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query GetAllFolders($vaultId: UUID!, $folderId: UUID, $after: String, $first: Int!) {
    vaultContentBySortTime(
      vaultId: $vaultId
      parentVaultContentId: $folderId
      after: $after
      first: $first
      contentType: FOLDER
    ) {
      edges {
        node {
          __typename
          id
          ... on VaultFolder {
            id
            title
            vault {
              id
              artistProfile {
                id
                linkValue
              }
            }
            ...FolderItem
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  query GetCurrentFolder($folderId: UUID!) {
    vaultContentById(vaultContentId: $folderId) {
      __typename
      id
      title
      parentVaultContentId
      vaultId
    }
  }
`);

export const MoveToContents = proxy<{ contentIds: string[] }>({
  contentIds: [],
});

const limit = 10;

export const MoveToPage = () => {
  const { artistHandle } = useArtistHandle();
  const { folderId } = useParams<{
    folderId: string | undefined;
  }>();

  useVaultTheme();

  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();

  const { setIsSelecting } = useSelectVaultContent();

  const contentIds = useSnapshot(MoveToContents).contentIds;

  const { openToast } = useToast();
  const { openOverlay, closeOverlay } = useOverlayContainer();

  const navigate = useNavigate();

  const ownedArtist = useOwnedArtist({ artistHandle });
  const mainVaultId = ownedArtist?.mainVault.id;

  const { createFolder, isCreatingFolder } = useVaultFolder({
    vaultId: !!mainVaultId && mainVaultId,
  });
  const { moveToFolder, isMovingFolder } = useVaultContentActions();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const {
    orderedList: folders,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
  } = useInfiniteQuery(GetAllFoldersDocument, {
    staleTime: 0,
    filterQueryKey: {
      vaultId: mainVaultId,
      folderId,
    },
    getNextPageParam: ({ data }) => {
      return (
        data.vaultContentBySortTime.pageInfo.hasNextPage && {
          after: data.vaultContentBySortTime.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!mainVaultId &&
      (({ pageParam }) => {
        return {
          vaultId: mainVaultId,
          folderId,
          after: pageParam?.after ?? null,
          first: limit,
        };
      }),
    list: ({ vaultContentBySortTime }) => {
      const nodes = vaultContentBySortTime.edges.map(edge => edge.node);
      return getManyFromList(
        nodes,
        node => node.__typename === 'VaultFolder' && !contentIds.includes(node.id) && node,
      );
    },
    uniq: ({ id }) => id,
    enabled: !!artistHandle && contentIds.length > 0,
  });

  const {
    data,
    isLoading: isLoadingFolder,
    isError: isErrorFolder,
  } = useQuery(GetCurrentFolderDocument, {
    staleTime: 0,
    variables: !!folderId && { folderId },
    select: data =>
      data.data.vaultContentById?.__typename === 'VaultFolder' ? data.data.vaultContentById : null,
    enabled: contentIds.length > 0,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const folderName = folderId == null ? 'Home' : data?.title;

  const onMoveToFolder = useStableCallback(
    ({
      folderId,
      folderName,
      artistHandle,
    }: {
      folderId: string | null;
      folderName: string;
      artistHandle: string;
    }) => {
      if (mainVaultId == null) {
        return;
      }

      if (data?.vaultId != null && data.vaultId !== mainVaultId) {
        openToast({
          text: 'Cannot move content to a different vault',
          variant: 'error',
        });
        return;
      }

      const contentCount = contentIds.length;

      moveToFolder({
        folderId,
        contentIds,
        vaultId: mainVaultId,
        onSuccess: () => {
          setIsSelecting(false);

          navigate(-1);

          MoveToContents.contentIds = [];

          openToast({
            text: `File${contentCount > 1 ? 's' : ''} moved to`,
            ctaText: folderId != null ? folderName : 'Home',
            ctaHref:
              folderId != null
                ? artistNavigationPath(artistHandle, `/folder/${folderId}`)
                : artistNavigationPath(artistHandle, ``),

            variant: 'success',
            duration: 5000,
          });
        },
      });
    },
  );

  const disabledButtons =
    contentIds.length === 0 ||
    isLoadingFolder ||
    isErrorFolder ||
    isMovingFolder ||
    isCreatingFolder ||
    isInitialLoading ||
    artistHandle == null ||
    isError;

  const showEmptyComponent =
    (!isInitialLoading && folders.length == 0) || mainVaultId == null || artistHandle == null;

  const EmptyComponent = useCallback(
    () =>
      showEmptyComponent ? (
        <View className="mt-4 flex w-full flex-1 flex-col items-center justify-center">
          <Text className="mb-2 font-title text-[16px]/[20px] font-medium text-vault_text">
            {isError ? 'Something went wrong' : 'No folders'}
          </Text>
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text/50">
            {isError || artistHandle == null
              ? 'Please try again later'
              : 'Create a new folder to move the content'}
          </Text>
        </View>
      ) : null,
    [artistHandle, isError, showEmptyComponent],
  );

  const LoadingFooter = useCallback(
    () =>
      isFetchingNextPage && hasNextPage ? (
        <View className="min-h-20 w-full items-center">
          <LargePlayIndicatorBars isPaused={false} sharedBarClassName="bg-base600" />
        </View>
      ) : (
        <View className="h-20 w-full " />
      ),
    [hasNextPage, isFetchingNextPage],
  );

  const { setFolderId } = useMenuContainer();

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      withBottomNavigator={false}
      vaultId={mainVaultId}
      messageChannelId={undefined}
      hasChatReadAccess
      showBorder
      headerLeft={
        <BackButton
          className="text-vault_text"
          onClick={
            data?.parentVaultContentId != null
              ? () =>
                  navigate(
                    artistNavigationPath(artistHandle, `/move-to/${data?.parentVaultContentId}`),
                    { replace: true },
                  )
              : () => {
                  if (folderId != null) {
                    navigate(artistNavigationPath(artistHandle, `/move-to`), { replace: true });
                  } else {
                    navigate(artistNavigationPath(artistHandle, `/`), { replace: true });
                    MoveToContents.contentIds = [];
                  }
                }
          }
        />
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="h-[46px] bg-vault_background pt-6 md2:rounded-t-[20px] md2:border md2:border-vault_text/5 md2:bg-vault_text/3"
      headerGridClassName="flex items-center h-full"
      headerLeftClassName="w-[unset] mr-4"
      headerCenterClassName="flex-grow justify-start"
      headerCenter={
        <Text className="line-clamp-1 text-left font-title text-[18px]/[20px] font-medium text-vault_text">
          {folderName ?? ''}
        </Text>
      }
      nonScrollingChildren={<EmptyComponent />}
    >
      <Button
        leadingIcon={faFolderPlus}
        label="New Folder"
        type="secondary"
        className={twMerge(
          'mt-4 items-center justify-center rounded-full border border-solid bg-transparent py-[14px]',
          'border-vault_text/20 text-vault_text',
        )}
        onClick={() => {
          openOverlay(
            <TextInputModal
              title="Create New Folder"
              confirmText="Create"
              placeholder="Untitled"
              minLength={FOLDER_TITLE_MIN_LENGTH}
              maxLength={FOLDER_TITLE_MAX_LENGTH}
              onConfirm={async text => {
                await createFolder({
                  title: text,
                  folderId,
                });
                closeOverlay();
              }}
            />,
          );
        }}
        disabled={disabledButtons}
      />

      {!showEmptyComponent && (
        <View className="mt-4 grid w-full grid-cols-2 gap-x-4 md2:grid-cols-3">
          {folders.map((folder, i) => (
            <FolderItem
              key={folder.id}
              containerRef={i === folders.length - 1 ? bottomRef : undefined}
              folderInfo={folder}
              artistHandle={artistHandle}
              editingFolder={null}
              onClick={() => {
                navigate(artistNavigationPath(artistHandle, `/move-to/${folder.id}`), {
                  replace: true,
                });
                setFolderId(folder.id);
              }}
              isOwner
            />
          ))}
        </View>
      )}
      <LoadingFooter />
      <Button
        label="Move here"
        type="primary-themed"
        className={twMerge(
          'absolute bottom-10 self-center px-6 py-[14px]',
          isBottomAudioPlayerOpen && 'bottom-[60px] mb-4 md2:bottom-[87px]',
        )}
        onClick={
          artistHandle != null
            ? () => {
                onMoveToFolder({
                  folderId: folderId ?? null,
                  folderName: folderName ?? 'Unknown',
                  artistHandle,
                });
              }
            : undefined
        }
        disabled={disabledButtons}
      />
    </DefaultLayout>
  );
};
