import { Navigate, useParams } from 'react-router';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../components/loading/LoadingSkeleton';
import { CampaignInsightsView } from '../components/membership/CampaignInsightView';
import { RsvpInsightView } from '../components/membership/RsvpInsightView';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import { ArtistEventByIdDocument } from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useOwnedArtist } from '../hooks/useOwnedArtist';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import { artistNavigationPath } from '../utils/navigationUtils';

gql(/* GraphQL */ `
  query ArtistEventById($id: UUID!) {
    artistEventById(id: $id) {
      id

      ... on ArtistReleaseCampaignEvent {
        id
        __typename
        releaseCampaign {
          ...CampaignInsightHeader
        }
      }

      ... on ArtistRsvpEvent {
        id
        __typename
        rsvpEvent {
          ...RsvpInsightHeader
        }
      }
    }
  }
`);

export function EventInsightsPage() {
  const { eventId } = useParams();
  const { artistHandle } = useArtistHandle();

  const { loginStatus } = useAuthContext();

  useVaultTheme();

  const {
    data,
    isLoading: isLoadingEvent,
    isError: isEventError,
    refetch: refetchEvent,
  } = useQuery(ArtistEventByIdDocument, {
    variables: !!eventId && {
      id: eventId,
    },
    staleTime: 0,
    select: data => data.data.artistEventById,
  });

  const ownedArtist = useOwnedArtist({ artistHandle });
  const isOwner = !!ownedArtist;

  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (isLoadingEvent) {
    return <InsightSkeleton />;
  }

  if (data == null || isEventError) {
    return <InsightErrorView onRetryClick={refetchEvent} />;
  }

  if (data.__typename === 'ArtistRsvpEvent') {
    const { rsvpEvent } = data;
    return <RsvpInsightView rsvpEvent={rsvpEvent} />;
  }

  const { releaseCampaign } = data;
  return <CampaignInsightsView campaign={releaseCampaign} />;
}

export function InsightHeaderSkeleton() {
  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex flex-col items-start justify-start gap-4">
        <View className="flex flex-row items-center justify-start gap-2">
          <LoadingSkeleton className="h-[56px] w-[56px] rounded-lg" withVaultTheme />
          <View className="flex flex-col items-start justify-start gap-1">
            <LoadingSkeleton className="h-[35px] w-[200px] rounded-md" withVaultTheme />
            <LoadingSkeleton className="h-[17px] w-[150px] rounded-md" withVaultTheme />
          </View>
        </View>
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <LoadingSkeleton
          className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10"
          withVaultTheme
        />
        <LoadingSkeleton
          className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10"
          withVaultTheme
        />
      </View>
    </View>
  );
}

function InsightSkeleton() {
  const { isDesktop } = useWindow();

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} withVaultTheme />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <View className="w-full">
        <InsightHeaderSkeleton />
      </View>
    </DefaultLayout>
  );
}

function InsightErrorView({ onRetryClick }: { onRetryClick: () => void }) {
  const { isDesktop } = useWindow();
  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} withVaultTheme />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <ErrorView onRetryClick={onRetryClick} withVaultTheme className="h-full w-full" />
    </DefaultLayout>
  );
}
