import { useImagesMessageAttachments } from '../../contexts/ImagesMessageAttachmentContext';
import { useVideoAttachment } from '../../contexts/VideoMessageAttachmentContext';
import { MediaType } from '../../graphql/generated';
import { useTextBlastPreviewMessage } from '../../hooks/message/useTextBlastPreviewMessage';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Image } from '../common/Image';
import { LinkifyText } from '../common/LinkifyText';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function PreviewMassMessageView({
  message,
  attachedTrackId,
  artistHandle,
  artistName,
  artistProfileImageUrl,
  messageAttachmentCount,
  artistId,
}: {
  message: string;
  messageAttachmentCount: number | null;
  attachedTrackId: string | null;
  artistHandle: string;
  artistName: string;
  artistProfileImageUrl: string | null | undefined;
  artistId: string;
}) {
  const { video } = useVideoAttachment({ identifier: artistId });
  const { images } = useImagesMessageAttachments({ identifier: artistId });

  const messageAttachments = [...images, ...(video ? [video] : [])];

  const previewMessage = useTextBlastPreviewMessage({
    alreadySent: false,
    artistHandle,
    message,
    attachedTrack: {
      id: attachedTrackId,
      linkValue: null,
    },
    messageAttachmentCount,
    shouldIgnoreMedia: true,
  });

  return (
    <View className="box-border aspect-[339/717] h-full max-h-[717px] max-w-[339px] flex-1 overflow-hidden rounded-[39px] border-[8px] border-solid border-base800 bg-white lg:w-[339px]">
      <View className="flex w-full flex-col items-center justify-start gap-1 bg-[#F1F1F2] pb-3 pt-6">
        <ArtistProfileImage
          profileImageUrl={artistProfileImageUrl}
          className="h-[60px] w-[60px] rounded-full"
        />
        <Text className="font-base text-[12px]/[15px] text-[#000]/50">{artistName}</Text>
      </View>
      {(!!previewMessage || messageAttachments.length > 0) && (
        <View className="no-scrollbar flex max-h-[calc(100%-120px)] w-full flex-col items-start gap-2 overflow-y-scroll">
          {!!previewMessage && (
            <View className="relative mx-5 mt-4 box-border max-w-[calc(100%-40px)] rounded-[20px] bg-[#E9E9EB] px-3 py-2">
              <span className="absolute bottom-0 left-[0px] h-[17px] w-[17px]">
                <BubbleBottom />
              </span>
              <LinkifyText className="text-[#0085FF]">
                <span className="relative z-above2 whitespace-pre-wrap break-words text-[#000]">
                  {previewMessage}
                </span>
              </LinkifyText>
            </View>
          )}
          {messageAttachments.length > 0 && (
            <View className="ml-4 flex flex-col gap-2">
              {messageAttachments.map(attachment => (
                <AttachmentPreview
                  key={attachment.identifier}
                  attachment={attachment}
                  artistHandle={artistHandle}
                />
              ))}
            </View>
          )}
        </View>
      )}
    </View>
  );
}

function AttachmentPreview({
  attachment,
  artistHandle,
}: {
  attachment: { identifier: string; objectUrl: string; type: MediaType };
  artistHandle: string;
}) {
  return (
    <View className="relative flex w-[135px] flex-col gap-0">
      {attachment.type === MediaType.Image ? (
        <Image
          src={attachment.objectUrl}
          className="h-[135px] w-full rounded-t-[18px] object-cover"
          alt={attachment.type}
        />
      ) : (
        <video
          src={attachment.objectUrl}
          className="h-[135px] w-full rounded-t-[18px] object-cover"
        />
      )}
      <span className="absolute bottom-0 left-[0px] h-[17px] w-[17px]">
        <BubbleBottom />
      </span>
      <View className="relative z-above1 box-border flex w-full flex-col gap-1 rounded-b-[18px] bg-[#E9E9EB] p-2">
        <Text className="text-[11.5px] font-medium text-black">
          {attachment.type.toLowerCase()}
        </Text>
        <Text className="text-[9.5px] text-black/50 opacity-90">{artistHandle}.vlt.fm/</Text>
      </View>
    </View>
  );
}

export function BubbleBottom() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M5 10.5C4.49857 13.5086 1.66667 16.3333 0 17C6.4 17 10.5 14.8333 11.5 13.5L16.5 15L16 0H5.5V2V4V4.5C5.5 5.5 5.5 7.5 5 10.5Z"
        fill="#E9E9EB"
      />
    </svg>
  );
}
