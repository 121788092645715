import { useEffect } from 'react';
import { EvaluationReason } from 'statsig-js/dist/utils/EvaluationReason';
import { useFeatureGate } from 'statsig-react';
import * as z from 'zod';
import { isDevPrev } from '../constants/env';
import { FEATURE_GATES } from '../constants/flagConstants';
import { PersistenceStorage } from '../utils/storeUtils';

export const FeatureGatesDevToggle = PersistenceStorage({
  key: 'featureGatesDevToggle',
  schema: z.boolean(),
  eager: isDevPrev,
});

export const FeatureGatesStore = PersistenceStorage({
  schema: z.record(z.string(), z.boolean()),
  key: 'featureGates',
  eager: true,
});

export function useBetterGate(ff: keyof typeof FEATURE_GATES): 'loading' | 'enabled' | 'disabled' {
  const {
    gate,
    gate: { value: statsigValue },
    isLoading: statsigIsLoading,
  } = useFeatureGate(FEATURE_GATES[ff]);

  const evaluation = gate.getEvaluationDetails().reason;

  useEffect(() => {
    if (statsigIsLoading) return;

    if (
      evaluation === EvaluationReason.NetworkNotModified ||
      evaluation === EvaluationReason.Network ||
      evaluation === EvaluationReason.LocalOverride
    ) {
      FeatureGatesStore.produceExistingState(
        draft => {
          if (draft[ff] !== statsigValue) {
            draft[ff] = statsigValue;
          }
        },
        {
          [ff]: statsigValue,
        },
      );
    }
  }, [statsigIsLoading, statsigValue, ff, evaluation]);

  const storeValue = FeatureGatesStore.useStore().value?.[ff];

  if (storeValue == null) return 'loading';

  return storeValue ? 'enabled' : 'disabled';
}
