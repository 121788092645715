import { type FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  faComment,
  faCommentSlash,
  faEnvelope,
  faMobile,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCancel } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMessage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  BanSubscriberDocument,
  GetExposedUserInfoDocument,
  GetSubscribersBanStatusDocument,
  GetUserInformationDocument,
  UnbanSubscriberDocument,
} from '../../graphql/generated';

import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import { useCopy } from '../../hooks/useCopy';
import { NewMessagePersistence } from '../../hooks/useNewMessage';
import type {
  ActionBottomsheetProps,
  UserProfileBottomsheetProps,
} from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { getFullDate } from '../../utils/dateUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { CommonUserInfo } from '../user/CommonUserInfo';

gql(/* GraphQL */ `
  mutation BanSubscriber($input: MutationBanSubscriberFromVaultInput!) {
    banSubscriberFromVault(input: $input)
  }

  mutation UnbanSubscriber($input: MutationUnbanSubscriberFromVaultInput!) {
    unbanSubscriberFromVault(input: $input)
  }

  query GetUserInformation($input: QueryVaultSubscriberByUserIdInput!) {
    vaultSubscriberByUserId(input: $input) {
      __typename
      ... on QueryVaultSubscriberByUserIdSuccess {
        data {
          id
          phone
          email
          artistDMChannelId
          userLocation {
            country
            city
            region
          }
          vaultSubscriptionSourceText
          vaultSubscriptionSourceType
          artistMembership {
            receipts
          }
          createdAt
          artist {
            id
            linkValue
          }
        }
      }
      ... on Error {
        message
      }
    }
  }

  query GetExposedUserInfo($input: QueryVaultSubscriberByUserIdForPublicInput!) {
    vaultSubscriberByUserIdForPublic(input: $input) {
      __typename
      ... on QueryVaultSubscriberByUserIdForPublicSuccess {
        data {
          createdAt
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [BanSubscriberDocument, UnbanSubscriberDocument],
  refetch: [GetSubscribersBanStatusDocument],
});

export const UserProfileBottomsheet: FC<UserProfileBottomsheetProps> = ({
  userId,
  avatarUrl,
  username,
  displayName,
  phone: passedPhone,
  email: passedEmail,
  receipts: passedReceipts,
  showAdminOptions,
  vaultArtistId,
  isVaultArtist,
  activeSubscriptionTier,
  userLocation: passedGeoLocation,
  vaultId,
  withVaultTheme,
  joinDate: passedJoinDate,
}) => {
  const { openToast } = useToast();
  const { mutateAsync: banSubCall } = useMutation(BanSubscriberDocument, {});
  const { mutateAsync: unbanSubCall } = useMutation(UnbanSubscriberDocument, {});

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const {
    data: isBannedFromChat,
    isLoading: isLoadingBanStatus,
    isError: isErrorBanStatus,
    refetch,
  } = useSubscriberBanStatus({ userId, artistId: vaultArtistId, enabled: showAdminOptions });

  const {
    data: userInfoData,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    refetch: refetchUserInfo,
  } = useQuery(GetUserInformationDocument, {
    enabled: showAdminOptions,
    variables: !!vaultId && { input: { userId, vaultId } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserId?.__typename === 'QueryVaultSubscriberByUserIdSuccess'
        ? data.data.vaultSubscriberByUserId.data
        : null,
  });

  const {
    data: exposedUserInfoData,
    isLoading: isLoadingExposedUserInfo,
    isError: isErrorExposedUserInfo,
  } = useQuery(GetExposedUserInfoDocument, {
    enabled: !showAdminOptions,
    variables: !!vaultId && { input: { userId, vaultId } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserIdForPublic?.__typename ===
      'QueryVaultSubscriberByUserIdForPublicSuccess'
        ? data.data.vaultSubscriberByUserIdForPublic.data
        : null,
  });

  const isLoading = isLoadingBanStatus || isLoadingUserInfo || isLoadingExposedUserInfo;
  const isError = isErrorBanStatus || isErrorUserInfo || isErrorExposedUserInfo;

  const phone = passedPhone ?? userInfoData?.phone;
  const email = passedEmail ?? userInfoData?.email;
  const geoLocation = passedGeoLocation ?? userInfoData?.userLocation;
  const receipts = passedReceipts ?? userInfoData?.artistMembership?.receipts;
  const joinDate = passedJoinDate ?? exposedUserInfoData?.createdAt ?? userInfoData?.createdAt;
  const joinDateText = useMemo(
    () => (joinDate ? getFullDate(new Date(joinDate)) : null),
    [joinDate],
  );

  const artistDMChannelId = userInfoData?.artistDMChannelId;
  const artistHandle = userInfoData?.artist?.linkValue;

  const banUser = async () => {
    if (!vaultArtistId || !userId) return;
    try {
      await banSubCall({
        input: {
          artistId: vaultArtistId,
          userId,
        },
      });
      openToast({
        text: 'You have banned this person from participating in the community chat.',
      });
    } catch (error) {
      openToast({
        text: 'There was an error banning this user. Please try again.',
        variant: 'error',
      });
    }
  };

  const unbanUser = async () => {
    if (!vaultArtistId || !userId) return;

    try {
      await unbanSubCall({
        input: {
          artistId: vaultArtistId,
          userId,
        },
      });
      openToast({
        text: 'You have unbanned this person from participating in the community chat.',
      });
    } catch (error) {
      openToast({
        text: 'There was an error unbanning this user. Please try again.',
        variant: 'error',
      });
    }
  };

  const emailCopyClick = useCopy({
    text: email,
    successMessage: 'Email copied to clipboard!',
  });
  const phoneCopyClick = useCopy({
    text: phone,
    successMessage: 'Phone number copied to clipboard!',
  });

  if (isLoading) {
    return (
      <LoadingSkeleton
        className={twMerge(
          'mb-[12px] h-[100px] w-[100px] rounded-full',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    );
  } else if (showAdminOptions && (isError || isBannedFromChat == null)) {
    return (
      <View className="flex h-full flex-col items-center">
        <ErrorView
          className="flex-grow"
          onRetryClick={() => {
            isErrorBanStatus && refetch();
            isErrorUserInfo && refetchUserInfo();
          }}
          loggingType="profile_bottomsheet"
          withVaultTheme={withVaultTheme}
        />
      </View>
    );
  }

  const buttons: ActionBottomsheetProps['buttons'] = [];
  if (isBannedFromChat) {
    buttons.push({
      leadingIcon: faComment,
      label: 'Unban from chat',
      type: 'secondary',
      onClick: unbanUser,
      event: { type: EVENTS.UNBAN_USER, properties: { userId: userId ?? '' } },
    });
  } else if (isBannedFromChat === false) {
    buttons.push({
      leadingIcon: faCommentSlash,
      label: 'Ban from chat',
      type: 'secondary',
      onClick: banUser,
      event: { type: EVENTS.BAN_USER, properties: { userId: userId ?? '' } },
    });
  }

  const shouldShowAdminOptions = showAdminOptions && !isLoading;

  const showLocation =
    showAdminOptions && (!!geoLocation?.city || !!geoLocation?.country || !!geoLocation?.region);

  const showSendMessage =
    shouldShowAdminOptions &&
    !!artistHandle &&
    !!artistDMChannelId &&
    !isBannedFromChat &&
    !!activeSubscriptionTier;

  return (
    <View className="box-border flex w-full flex-col px-4">
      <CommonUserInfo
        showLocation={showLocation}
        withVaultTheme={withVaultTheme}
        avatarUrl={avatarUrl}
        displayName={displayName}
        isVaultArtist={!!isVaultArtist}
        activeSubscriptionTier={activeSubscriptionTier}
        username={username}
        isBannedFromChat={isBannedFromChat ?? false}
        joinDateText={joinDateText}
        receipts={receipts}
        geoLocation={geoLocation}
        onBanClick={null}
      />

      <View className="mb-4" />

      {shouldShowAdminOptions && phone && (
        <Button
          leadingIcon={faMobile}
          type="secondary"
          position={email || shouldShowAdminOptions ? 'top' : 'individual'}
          label={phone}
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          onClick={phoneCopyClick.copy}
          event={{ type: EVENTS.COPY, properties: { type: 'phone' } }}
        />
      )}
      {shouldShowAdminOptions && email && (
        <Button
          leadingIcon={faEnvelope}
          type="secondary"
          position={shouldShowAdminOptions ? (phone ? 'middle' : 'top') : 'bottom'}
          label={email}
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          onClick={emailCopyClick.copy}
          event={{ type: EVENTS.COPY, properties: { type: 'email' } }}
          labelClassName="line-clamp-1 text-ellipsis"
        />
      )}

      {showSendMessage && !!userId && (
        <Button
          leadingIcon={faMessage}
          type="secondary"
          position={phone || email ? 'middle' : 'top'}
          label="Send message"
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10 text-vault_text' : 'text-white',
          )}
          href={artistNavigationPath(
            artistHandle,
            `/messages/${artistDMChannelId}`,
            constructQueryParams({ newMessage: true }),
          )}
          onClick={async () => {
            await NewMessagePersistence.set({
              artistDMChannelId,
              displayName: displayName ?? username ?? 'Member',
              profileImageUrl: avatarUrl ?? null,
              subscriptionTierLevel: activeSubscriptionTier,
              userId,
            });
            closeBottomsheet();
          }}
          labelClassName="line-clamp-1 text-ellipsis"
        />
      )}

      {shouldShowAdminOptions && (
        <Button
          leadingIcon={faCancel}
          type="secondary"
          position={phone || email || showSendMessage ? 'bottom' : 'individual'}
          label={isBannedFromChat ? 'Unban member' : 'Ban member'}
          className={twMerge(
            'mb-[2px] font-title text-[16px]/[20px] font-normal',
            withVaultTheme ? 'bg-vault_text/10' : 'text-white',
            isBannedFromChat
              ? withVaultTheme
                ? 'text-vault_text'
                : 'text-white'
              : 'text-destructive300',
          )}
          onClick={() => {
            if (!vaultArtistId || !userId) return;
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.BAN_USER,
              shared: {
                withVaultTheme,
              },
              banUserBottomsheetProps: {
                artistId: vaultArtistId,
                userId,
                withVaultTheme,
              },
            });
          }}
          labelClassName="line-clamp-1 text-ellipsis"
        />
      )}
    </View>
  );
};
