import { useConfig } from 'statsig-react';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';

const apiEnv = ENVIRONMENT === 'development' ? 'preview' : ENVIRONMENT;

/**
 * Types from statisg config
 */
interface CustomTosLinkValue {
  enabled: boolean;
  tosUrls: Record<string, string>; // artistHandle, url
}

export enum STATSIG_CONFIG_KEYS {
  CUSTOM_TOS_LINK = 'CUSTOM_TOS_LINK',
}

interface StatsigConfigValueTypes {
  [STATSIG_CONFIG_KEYS.CUSTOM_TOS_LINK]: CustomTosLinkValue;
}

/**
 * Statsig config keys
 */
type StatsigConfigInfo<K extends keyof StatsigConfigValueTypes> = {
  key: string;
  default: StatsigConfigValueTypes[K];
};

const STATSIG_CONFIGS: { [K in keyof StatsigConfigValueTypes]: StatsigConfigInfo<K> } = {
  CUSTOM_TOS_LINK: {
    key: 'vault_custom_tos_link',
    default: { enabled: false, tosUrls: {} },
  },
} as const;

type StatsigConfigKey = keyof typeof STATSIG_CONFIGS;

/**
 * Statsig dynamic config hook that takes ENVIRONMENT into account
 */
export function useStatsigConfig<K extends StatsigConfigKey>(
  key: K,
): { config: StatsigConfigValueTypes[K]; isLoading: boolean } {
  const { config, isLoading } = useConfig(STATSIG_CONFIGS[key].key);
  const configValue = config.get(apiEnv, STATSIG_CONFIGS[key].default);

  return { config: configValue as StatsigConfigValueTypes[K], isLoading };
}
