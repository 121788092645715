import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate, isSameDay } from 'date-fns';
import { compact } from 'lodash-es';
import millify from 'millify';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import {
  faCancel,
  faMessages as faMessagesLight,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import {
  faChartSimple,
  faMessages,
  faPenToSquare,
  faThumbtack,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBell } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBellSlash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faReceipt } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCrown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { DEFAULT_AVATAR } from '../../constants/imageConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  BanSubscriberDocument,
  type FragmentType,
  getFragment,
  GetMessageChannelDetailsDocument,
  GetUserChannelsDocument,
  makeFragmentData,
  MessageChannelType,
  TierTypename,
  UpdateMessageChannelParticipantUserSettingsDocument,
  UserChannelRowFragmentDoc,
  VaultType,
} from '../../graphql/generated';
import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import { useNewMessage } from '../../hooks/useNewMessage';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useToggleChannelParticipantSettings } from '../../hooks/useToggleParticipantChannelSettings';
import { type FiltersSchema, filtersState, useUserChannels } from '../../hooks/useUserChannels';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { pastDateInterval } from '../../utils/dateUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { AuthCTABox } from '../auth/AuthCTABox';
import { Button } from '../buttons/Button';
import { ActionDropdown, Dropdown } from '../common/Dropdown';
import { Text } from '../common/Text';
import { View, type ViewProps } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { ListDetailLayout } from '../layouts/ListDetailLayout';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { AudioPlayer } from '../main/AudioPlayer';
import { ProfileImage } from '../user/ProfileImage';
import { VaultNav } from '../vault/VaultNav';

RefetchOnComplete({
  trigger: [BanSubscriberDocument, UpdateMessageChannelParticipantUserSettingsDocument],
  refetch: [GetUserChannelsDocument, GetMessageChannelDetailsDocument],
});

export function UserChannels({
  artistHandle,
  asArtistId,
  showOnMobile,
  vaultId,
  artistName,
  artistCoverImage,
  activeSubscription,
  openDM,
}: {
  artistHandle: string | undefined;
  asArtistId: string | undefined;
  showOnMobile: boolean;
  vaultId: string | undefined;
  artistName: string | undefined;
  artistCoverImage: string | null | undefined;
  activeSubscription: boolean;
  openDM: boolean;
}) {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { sort, filterType } = useSnapshot(filtersState);
  const { openBottomsheet } = useBottomsheetContainer();
  const ownedArtist = useOwnedArtist({ artistHandle });
  const { isDesktop } = useWindow();

  const isOwner = !!ownedArtist;

  const newMessageInfo = useNewMessage();

  const [searchParams] = useSearchParams();
  const showNewMessageRow = !!searchParams.get('newMessage');

  const [openChannelId, setOpenChannelId] = useState<string | null>(null);
  const [state, setState] = useState<{
    sort: FiltersSchema['sort'];
    filterType: FiltersSchema['filterType'];
  }>({
    sort,
    filterType,
  });

  const placeholderChannels = useMemo<typeof channels>(
    () => [
      {
        messageChannel: {
          id: 'placeholder-group',
          isPlaceholder: true,
          channelType: MessageChannelType.Vault,
          createdAt: new Date(new Date().setHours(new Date().getHours() - 2)).toISOString(),
          artist: null,
          details: {
            titleText: 'Group Chat',
            subtitleText: '',
            showVerifiedBadge: false,
            subscriptionTierLevel: null,
            receiptCount: null,
            coverImage: null,
          },
          latestMessage: {
            content: '',
            createdAt: new Date(new Date().setHours(new Date().getHours() - 2)).toISOString(),
          },
          participants: [],
          hasUnreadMessages: false,
        },
      },
      {
        messageChannel: {
          id: 'placeholder-dm',
          isPlaceholder: true,
          channelType: MessageChannelType.ArtistDm,
          createdAt: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
          artist: {
            id: 'placeholder-artist',
            name: artistName || 'Artist',
          },
          details: {
            titleText: artistName || 'Direct Message',
            subtitleText: '',
            showVerifiedBadge: false,
            subscriptionTierLevel: null,
            receiptCount: null,
            coverImage: artistCoverImage
              ? {
                  smallCoverImageUrl: artistCoverImage,
                }
              : null,
          },
          latestMessage: {
            content: '',
            createdAt: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
          },
          participants: [],
          hasUnreadMessages: false,
        },
      },
    ],
    [artistName, artistCoverImage],
  );

  const {
    channels,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading,
    isError,
    refetch,
  } = useUserChannels({
    artistHandle,
    asArtistId,
    sort: state.sort,
    filterType: state.filterType,
  });

  useEffect(() => {
    if (channels.length === 0 || !openDM) return;

    if (isOwner) {
      navigate(artistNavigationPath(artistHandle, '/messages'));
      return;
    }

    const dmChannel = channels.find(
      channel => channel.messageChannel.channelType === MessageChannelType.ArtistDm,
    );

    if (!dmChannel) return;

    navigate(artistNavigationPath(artistHandle, `/messages/${dmChannel.messageChannel.id}`));
  }, [artistHandle, channels, channels.length, isOwner, navigate, openDM]);

  const showJoinFree = useMemo(() => {
    return channels.length === 0 && !isInitialLoading && showOnMobile && !activeSubscription;
  }, [activeSubscription, channels.length, isInitialLoading, showOnMobile]);

  const renderItem = useCallback(
    (_index: number, item: (typeof channels)[number]) => {
      if (!artistHandle) return null;

      return (
        <SwipeableUserChannelRow
          key={item.messageChannel.id}
          artistHandle={artistHandle}
          channelFrag={item}
          isOwner={!!asArtistId}
          isOpen={openChannelId === item.messageChannel.id}
          onOpenChange={isOpen => {
            setOpenChannelId(isOpen ? item.messageChannel.id : null);
          }}
          isSwipeEnabled
          disableSelection={showNewMessageRow && !!newMessageInfo && isDesktop}
        />
      );
    },
    [artistHandle, asArtistId, isDesktop, newMessageInfo, openChannelId, showNewMessageRow],
  );

  const EmptyPlaceholder = useCallback(() => {
    if (showJoinFree) {
      return <>{placeholderChannels.map((channel, i) => renderItem(i, channel))}</>;
    }
    if (isInitialLoading || loginStatus === LoginStatus.LOADING) {
      return (
        <View className="flex h-full w-full flex-col">
          {Array.from({ length: 10 }).map((_, index) => (
            <SkeletonUserChannelRow key={index} />
          ))}
        </View>
      );
    }

    if (isError) {
      return (
        <View className="h-full w-full">
          <ErrorView withVaultTheme onRetryClick={refetch} className="h-full w-full md2:w-full" />
        </View>
      );
    }

    const paidOnly = state.filterType === 'PAID_ONLY';
    if (paidOnly || state.filterType === 'UNREAD_ONLY') {
      return (
        <>
          <hr className="h-[1px] w-full border-0 bg-vault_text/10 md2:-mt-1" />
          <div className="flex h-[70vh] flex-col items-center justify-center space-y-6 border-1.5 border-t border-vault_text">
            <FontAwesomeIcon icon={faMessagesLight} fontSize={60} className="text-vault_text" />
            <div className="flex flex-col items-center space-y-2 text-center">
              <h2 className="font-title text-title-l font-medium text-vault_text">
                {paidOnly ? 'No paid DMs' : 'No unread messages'}
              </h2>
              <span className="font-base text-base-m font-normal text-vault_text/50">
                {paidOnly ? 'Messages from paid users' : 'Unread messages'}
                <br />
                will appear here
              </span>
            </div>
          </div>
        </>
      );
    }
    return <>{placeholderChannels.map((channel, i) => renderItem(i, channel))}</>;
  }, [
    isError,
    isInitialLoading,
    loginStatus,
    placeholderChannels,
    refetch,
    renderItem,
    showJoinFree,
    state.filterType,
  ]);

  const Header = useCallback(
    () =>
      showNewMessageRow && newMessageInfo && isDesktop && artistHandle ? (
        <View className="my-1 h-full w-full overflow-y-hidden rounded-xl">
          <UserChannelRow
            channelFrag={makeFragmentData(
              {
                hasUnreadMessages: false,
                messageChannel: {
                  id: newMessageInfo.artistDMChannelId,
                  artist: null,
                  channelType: 'ARTIST_DM',
                  createdAt: new Date().toISOString(),
                  details: {
                    coverImage: {
                      id: crypto.randomUUID(),
                      smallCoverImageUrl: newMessageInfo.profileImageUrl,
                      cdnUrl: newMessageInfo.profileImageUrl ?? DEFAULT_AVATAR,
                    },
                    receiptCount: null,
                    showVerifiedBadge: false,
                    subscriptionTierLevel: newMessageInfo.subscriptionTierLevel,
                    subtitleText: null,
                    titleText: `New message to ${newMessageInfo.displayName}`,
                    singleRecipientActorId: null,
                    isPushNotificationEnabled: true,
                    isSmsEnabled: true,
                    isPushNotificationEnabledForUserMessagesInGroupChat: null,
                  },
                  latestMessage: null,
                },
              },
              UserChannelRowFragmentDoc,
            )}
            showEllipsisMenu={false}
            isDropdownOpen={false}
            showDate={false}
            isBanned={false}
            isOwner
            disableHover
            isSelected
            isPlaceholder={false}
          />
        </View>
      ) : null,
    [isDesktop, newMessageInfo, showNewMessageRow, artistHandle],
  );

  const Footer = useCallback(() => <View className="h-4" />, []);

  const Item = useCallback(
    (props: ViewProps) => <View {...props} className="my-1 h-full w-full" />,
    [],
  );

  return (
    <ListDetailLayout.List showOnMobile={showOnMobile}>
      <ListDetailLayout.ListTitle
        title="Messages"
        actionButton={null}
        titleOverride={
          <View className="flex w-full flex-col gap-4">
            <View className="flex w-full flex-row items-center justify-between gap-6">
              <Text className="flex-1 text-left font-title text-[32px] font-medium text-vault_text">
                Messages
              </Text>
              {!!asArtistId && (
                <View className="flex items-center justify-center gap-2">
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faChartSimple}
                    className="items-center justify-center rounded-full bg-transparent p-[10px] text-[24px] text-vault_text hover:bg-vault_text/10"
                    href={artistNavigationPath(artistHandle, '/messages/insights')}
                  />
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faPenToSquare}
                    className="items-center justify-center rounded-full bg-vault_accent p-[14px] text-[20px] text-vault_accent_text"
                    onClick={() => {
                      if (!vaultId) return;
                      openBottomsheet({
                        type: BOTTOMSHEET_TYPES.NEW_MESSAGE,
                        shared: {
                          withVaultTheme: true,
                          showFullScreen: true,
                          hidePulleyBar: true,
                        },
                        newMessageBottomsheetProps: {
                          artistHandle,
                          vaultId,
                          asArtistId,
                        },
                      });
                    }}
                  />
                </View>
              )}
            </View>

            {!!asArtistId && (
              <View className="flex w-full flex-row gap-2">
                <Button
                  label="All"
                  className={twMerge(
                    'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                    state.filterType === 'ALL'
                      ? 'bg-vault_text text-vault_text_opposite'
                      : 'bg-vault_text/10 text-vault_text',
                  )}
                  onClick={() => setState(prev => ({ ...prev, filterType: 'ALL' }))}
                />
                <Button
                  label="Unread"
                  className={twMerge(
                    'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                    state.filterType === 'UNREAD_ONLY'
                      ? 'bg-vault_text text-vault_text_opposite'
                      : 'bg-vault_text/10 text-vault_text',
                  )}
                  onClick={() => setState(prev => ({ ...prev, filterType: 'UNREAD_ONLY' }))}
                />
                {ownedArtist?.mainVault.type === VaultType.Freemium && (
                  <Button
                    label="Paid"
                    className={twMerge(
                      'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                      state.filterType === 'PAID_ONLY'
                        ? 'bg-vault_text text-vault_text_opposite'
                        : 'bg-vault_text/10 text-vault_text',
                    )}
                    onClick={() => setState(prev => ({ ...prev, filterType: 'PAID_ONLY' }))}
                  />
                )}
              </View>
            )}
          </View>
        }
        withBackButton={false}
        withDivider={false}
      />

      <ListDetailLayout.ListContent>
        <Virtuoso
          itemContent={renderItem}
          data={channels}
          className="no-scrollbar h-full w-full"
          endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
          components={{
            Header,
            Footer,
            EmptyPlaceholder,
            Item,
          }}
        />
      </ListDetailLayout.ListContent>

      {showJoinFree && !!artistHandle && (
        <div className="fixed z-chatOverlay flex w-full items-center justify-center bg-gradient-to-b from-vault_background/50 via-vault_background via-95% to-transparent h-screen-safe md2:hidden">
          <div className="absolute bottom-24 box-content h-fit w-[calc(100%-32px)] px-4">
            <AuthCTABox type="messaging" artist={null} artistHandle={artistHandle} fill />
          </div>
        </div>
      )}

      {showOnMobile && (
        <>
          {!isDesktop && <AudioPlayer withBottomNavigator withVaultTheme />}
          <View className="w-full md2:hidden">
            <VaultNav
              vaultId={vaultId}
              messageChannelId={undefined}
              hasChatReadAccess={false}
              chatAvailableForFreeUsers={false}
              variant="default"
              withVaultTheme
            />
          </View>
        </>
      )}
    </ListDetailLayout.List>
  );
}

const UserChannelRow = memo(function UserChannelRow({
  channelFrag,
  disableHover,
  isBanned,
  isDropdownOpen,
  isOwner,
  isSelected,
  showDate,
  showEllipsisMenu,
  isPlaceholder,
}: {
  channelFrag: FragmentType<UserChannelRowFragmentDoc>;
  disableHover: boolean;
  isBanned: boolean;
  isDropdownOpen: boolean;
  isOwner: boolean;
  isSelected: boolean;
  showDate: boolean;
  showEllipsisMenu: boolean;
  isPlaceholder: boolean;
}) {
  const { messageChannel: channel, hasUnreadMessages } = getFragment(
    UserChannelRowFragmentDoc,
    channelFrag,
  );

  const isGroupChat = channel.channelType === MessageChannelType.Vault;

  const messagePreview = channel.latestMessage?.content || '';

  const title = channel.details?.titleText;
  const subtitle = channel.details?.subtitleText;
  const channelType = channel.channelType;
  const coverImage = channel.details?.coverImage;
  const showVerifiedBadge = channel.details?.showVerifiedBadge || false;
  const latestMessage = channel.latestMessage;
  const createdAt = channel.createdAt;

  const subtext =
    channelType === MessageChannelType.Vault ? (!isPlaceholder ? 'Pinned' : null) : subtitle;

  const isPaidTier = channel.details?.subscriptionTierLevel === TierTypename.PaidTier;

  const dateString = useMemo(() => {
    const now = new Date();
    const date = new Date(latestMessage?.createdAt ?? createdAt);

    if (Math.abs(now.getTime() - date.getTime()) < 1000) {
      return 'Now';
    }

    if (isSameDay(now, date)) {
      return pastDateInterval(date);
    }

    return formatDate(date, 'MMM d');
  }, [createdAt, latestMessage?.createdAt]);

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row items-center gap-3 py-2 no-underline md2:px-3',
        disableHover ? 'bg-transparent' : 'md2:hover:bg-vault_text/3',
        isSelected && !disableHover
          ? 'bg-vault_text/10 md2:hover:bg-vault_text/3 lg:hover:bg-vault_text/20'
          : isSelected && 'lg:bg-vault_text/10',
        !isPlaceholder && 'cursor-pointer',
      )}
    >
      <View
        className={twMerge(
          'flex h-[64px] w-[64px] flex-col items-center justify-center rounded-full',
          isGroupChat && isSelected ? 'bg-vault_text' : 'bg-vault_text/5',
        )}
      >
        {isGroupChat ? (
          <FontAwesomeIcon
            icon={faMessages}
            className={twMerge(
              'text-[24px]/[24px]',
              isSelected ? 'text-vault_text_opposite' : 'text-vault_text',
            )}
          />
        ) : !showVerifiedBadge ? (
          <View className="relative h-full w-full rounded-full">
            <ProfileImage
              className="h-[64px] w-[64px]"
              profileImageUrl={coverImage?.smallCoverImageUrl || coverImage?.cdnUrl}
              onClick={undefined}
            />

            {isPaidTier && (
              <View className="absolute -bottom-[1px] -right-[1px] flex h-[23px] w-[23px] items-center justify-center rounded-full bg-vault_background">
                <View className="flex h-full w-full items-center justify-center rounded-full bg-vault_text/3">
                  <View
                    className={twMerge(
                      'flex h-full w-full items-center justify-center rounded-full',
                      isSelected && 'bg-vault_text/10',
                    )}
                  >
                    <View className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-vault_accent">
                      <FontAwesomeIcon
                        icon={faCrown}
                        className="text-[11px] text-vault_accent_text"
                      />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        ) : (
          <ArtistProfileImage
            className="h-[64px] w-[64px]"
            profileImageUrl={coverImage?.smallCoverImageUrl}
            withVaultTheme
          />
        )}
      </View>
      <View className="flex flex-1 flex-shrink select-none flex-col gap-1">
        <View className="flex w-full flex-row items-center justify-between">
          <View className="flex min-w-0 flex-1 items-center gap-1">
            <Text
              className={twMerge(
                'line-clamp-1 overflow-hidden text-ellipsis break-all font-base text-[16px]/[18px] text-vault_text',
                hasUnreadMessages ? 'font-semibold' : 'font-normal',
              )}
            >
              {title}
            </Text>
            {showVerifiedBadge && (
              <FontAwesomeIcon
                icon={faBadgeCheck}
                className="shrink-0 select-none text-[12px] text-vault_accent"
              />
            )}
            <Text
              className={twMerge(
                'line-clamp-1 flex shrink-0 items-center gap-1 overflow-hidden text-ellipsis break-all font-base text-[16px]/[18px] text-vault_text',
                hasUnreadMessages ? 'font-semibold' : 'font-normal',
              )}
            >
              {isOwner && channel.details?.receiptCount != null && (
                <>
                  · {millify(channel.details.receiptCount)}{' '}
                  <FontAwesomeIcon icon={faReceipt} className="mb-[1px] text-[13px]" />
                </>
              )}
              {isBanned && !isGroupChat && isOwner && (
                <View className="flex h-5 w-5 items-center justify-center rounded-full bg-destructive300">
                  <FontAwesomeIcon
                    icon={faCancel}
                    className="text-[11px] text-vault_text_opposite"
                  />
                </View>
              )}
            </Text>
          </View>

          <View
            className={twMerge(
              'flex shrink-0 items-center gap-2',
              showEllipsisMenu && 'group-hover/channel:opacity-0',
              isDropdownOpen && 'opacity-0',
            )}
          >
            {showDate && (
              <Text className="whitespace-nowrap font-base text-[14px]/[18px] text-vault_text/50">
                {dateString}
              </Text>
            )}
            {hasUnreadMessages && (
              <View className="h-2 w-2 items-center justify-center rounded-full bg-vault_text" />
            )}
          </View>
        </View>
        {!!messagePreview && (
          <Text
            className={twMerge(
              'line-clamp-1 break-all font-base text-[16px]/[18px]',
              hasUnreadMessages
                ? 'font-semibold text-vault_text'
                : 'font-normal text-vault_text/50',
              showEllipsisMenu && 'pr-12',
            )}
          >
            {messagePreview}
          </Text>
        )}
        {!!subtext && channelType === MessageChannelType.Vault && (
          <Text className="line-clamp-1 font-base text-[14px]/[18px] font-normal text-vault_text/50">
            <FontAwesomeIcon icon={faThumbtack} className="text-[14px] text-vault_text/50" />{' '}
            {subtext}
          </Text>
        )}
      </View>
    </View>
  );
});

const SwipeableUserChannelRow = memo(function SwipeableUserChannelRow({
  artistHandle,
  channelFrag,
  isOpen: controlledIsOpen,
  isOwner,
  isSwipeEnabled,
  onOpenChange,
  disableSelection,
}: {
  artistHandle: string;
  channelFrag: FragmentType<UserChannelRowFragmentDoc>;
  isOpen: boolean;
  isOwner: boolean;
  isSwipeEnabled: boolean;
  onOpenChange: (isOpen: boolean) => void;
  disableSelection: boolean;
}) {
  const navigate = useNavigate();
  const { loggedInUser } = useAuthContext();
  const ownedArtist = useOwnedArtist({ artistHandle });
  const location = useLocation();
  const { isDesktop } = useWindow();
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { openBottomsheet } = useBottomsheetContainer();

  const [isChatPage] = usePageChecks({
    pages: ['chat'],
  });

  const { channelId } = useParams();

  useEffect(() => {
    setOffset(controlledIsOpen ? -150 : 0);
  }, [controlledIsOpen]);

  // Reset state when the location changes (user navigates away)
  useEffect(() => {
    setOffset(0);
    setIsDragging(false);
    setIsSwiping(false);
    onOpenChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { messageChannel: channel } = getFragment(UserChannelRowFragmentDoc, channelFrag);
  const isGroupChat = channel.channelType === MessageChannelType.Vault;
  const title = channel.details?.titleText;
  const isPushNotificationEnabled = channel.details?.isPushNotificationEnabled;
  const isSmsEnabled = channel.details?.isSmsEnabled;
  const isPushNotificationEnabledForUserMessagesInGroupChat =
    channel.details?.isPushNotificationEnabledForUserMessagesInGroupChat;
  const artistId = channel.artist?.id;
  const userId = channel.details?.singleRecipientActorId;

  const banStatusQueryEnabled = !isGroupChat && !!artistId && !!userId && isOwner;

  const { data: isBanned } = useSubscriberBanStatus({
    userId,
    artistId,
    enabled: banStatusQueryEnabled,
  });

  const isMessagesCurrentPage =
    location.pathname.endsWith('/messages') ||
    location.pathname.endsWith('/messages/details') ||
    isChatPage;

  // Add check for placeholder channels
  const isPlaceholder = 'isPlaceholder' in channel && channel.isPlaceholder === true;

  const isSelected =
    !isPlaceholder &&
    ((isGroupChat && isMessagesCurrentPage) || channelId === channel.id) &&
    !disableSelection;

  const handleClick = () => {
    // Prevent navigation if we're swiping or the row is open
    if (isSwiping || controlledIsOpen || isPlaceholder) return;

    navigate(
      artistNavigationPath(
        artistHandle,
        isGroupChat ? (isDesktop ? '/messages' : '/chat') : `/messages/${channel.id}`,
      ),
    );
  };

  const handlers = useSwipeable({
    trackMouse: !isPlaceholder,
    trackTouch: !isPlaceholder,
    preventScrollOnSwipe: false,
    delta: 10,
    onSwipeStart: () => {
      setIsDragging(true);
      setIsSwiping(true);
    },
    onSwiping: eventData => {
      if (eventData.deltaX < 0) {
        // Only allow left swipes
        const newOffset = controlledIsOpen
          ? Math.max(eventData.deltaX - 150, -150)
          : Math.max(eventData.deltaX, -150);
        setOffset(newOffset);
      } else if (controlledIsOpen) {
        // Allow right swipes only when open
        setOffset(Math.min(eventData.deltaX - 150, 0));
      }
    },
    onSwiped: () => {
      setIsDragging(false);
      // Snap to position
      if (offset < -100) {
        setOffset(-150);
        onOpenChange(true);
      } else {
        setOffset(0);
        onOpenChange(false);
      }
      // Add a small delay before allowing clicks again
      setTimeout(() => {
        setIsSwiping(false);
      }, 50);
    },
  });

  const sharedTransition = isDragging ? 'none' : 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)';

  const enableMoreOptions = !isPlaceholder && isSwipeEnabled && (!isOwner || !isGroupChat);
  const canSwipe = enableMoreOptions && !isDesktop;
  const showEllipsisMenu = enableMoreOptions && isDesktop;

  const buttonClassName =
    'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

  const { onToggleNotificationsPress } = useToggleChannelParticipantSettings({
    artistHandle,
    messageChannelId: channel.id,
    actorId: ownedArtist?.id ?? loggedInUser?.id ?? null,
    messageChannelTitle: title ?? '',
  });

  const onBanClick = () => {
    setOffset(0);
    onOpenChange(false);
    setIsDropdownOpen(false);

    if (!artistId || !userId) {
      return;
    }

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.BAN_USER,
      shared: {
        withVaultTheme: true,
      },
      banUserBottomsheetProps: {
        artistId,
        userId,
        withVaultTheme: true,
      },
    });
  };

  const buttons = compact([
    {
      label: isPushNotificationEnabled ? 'Mute' : 'Unmute',
      trailingIcon: isPushNotificationEnabled ? faBellSlash : faBell,
      type: 'secondary',
      className: buttonClassName,
      onClick: () => {
        onToggleNotificationsPress({
          isPushNotificationEnabled: isPushNotificationEnabled ?? false,
          isSmsEnabled: isSmsEnabled ?? null,
          isPushNotificationEnabledForUserMessagesInGroupChat:
            isPushNotificationEnabledForUserMessagesInGroupChat ?? null,
        });
      },
    },
    isOwner && {
      label: isBanned ? 'Unban' : 'Ban',
      trailingIcon: faCancel,
      type: 'secondary',
      className: twMerge(buttonClassName, 'text-destructive300'),
      onClick: onBanClick,
    },
  ]) satisfies ActionBottomsheetProps['buttons'];

  return (
    <View
      className="group/channel relative select-none overflow-x-hidden rounded-xl"
      onClick={handleClick}
    >
      {(showEllipsisMenu || isDropdownOpen) && (
        <View
          className={twMerge(
            'invisible absolute right-0 z-above1 box-border h-full cursor-pointer gap-0',
            'opacity-0 group-hover/channel:visible group-hover/channel:opacity-100',
            isDropdownOpen && 'visible opacity-100',
          )}
        >
          <View className="mr-[20px] flex h-full w-full flex-col justify-center">
            <Dropdown
              sideOffset={12}
              align="center"
              disabled={false}
              className="w-[200px]"
              onOpenChange={open => {
                if (isPlaceholder) return;

                setIsDropdownOpen(open);
              }}
              trigger={
                <div className="relative">
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faEllipsis}
                    leadingIconClassName="text-[17px]"
                    className="h-8 w-8 rounded-full border border-solid border-vault_text/50 p-2 text-vault_text/50 hover:bg-vault_text/10"
                  />
                </div>
              }
            >
              <ActionDropdown buttons={buttons} withVaultTheme />
            </Dropdown>
          </View>
        </View>
      )}
      {canSwipe && (
        <View
          className="absolute right-0 top-0 flex h-full flex-row items-center gap-0"
          style={{
            transform: `translateX(${offset + 150}px)`,
            transition: sharedTransition,
          }}
        >
          <Button
            label=""
            iconOnly
            leadingIcon={isPushNotificationEnabled ? faBell : faBellSlash}
            leadingIconClassName="text-[24px] text-white"
            onClick={() => {
              onToggleNotificationsPress({
                isPushNotificationEnabled: isPushNotificationEnabled ?? false,
                isSmsEnabled: isSmsEnabled ?? null,
                isPushNotificationEnabledForUserMessagesInGroupChat:
                  isPushNotificationEnabledForUserMessagesInGroupChat ?? null,
              });
            }}
            className="h-full w-[75px] items-center justify-center bg-vault_text/50 px-6 text-white"
          />
          {isOwner && (
            <Button
              label=""
              iconOnly
              leadingIcon={faCancel}
              leadingIconClassName="text-[24px] text-white"
              onClick={onBanClick}
              className="h-full w-[75px] items-center justify-center bg-destructive300 px-6 text-white"
            />
          )}
        </View>
      )}

      <View
        {...(canSwipe ? handlers : {})}
        style={{
          transform: `translateX(${offset}px)`,
          transition: sharedTransition,
        }}
        className={twMerge(
          'overflow-hidden',
          isSwiping || controlledIsOpen
            ? 'rounded-l-xl rounded-r-none bg-destructive300'
            : 'rounded-xl',
        )}
      >
        <View
          className={twMerge(
            'w-full',
            (isSwiping || controlledIsOpen) &&
              (isSelected ? 'bg-vault_background' : 'bg-vault_background'),
          )}
        >
          <UserChannelRow
            channelFrag={channelFrag}
            isOwner={isOwner}
            disableHover
            isSelected={isSelected}
            showEllipsisMenu={showEllipsisMenu}
            isDropdownOpen={isDropdownOpen}
            isBanned={!!isBanned}
            showDate
            isPlaceholder={isPlaceholder}
          />
        </View>
      </View>
    </View>
  );
});

function SkeletonUserChannelRow() {
  return (
    <View className="box-border flex w-full flex-row gap-3 rounded-xl py-2 md2:my-1 md2:px-3">
      <LoadingSkeleton
        className="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-full bg-vault_text/5"
        withVaultTheme
      />
      <View className="box-border flex flex-1 flex-shrink flex-col gap-1">
        <LoadingSkeleton className="h-[18px] w-[100px]" withVaultTheme />
        <LoadingSkeleton className="box-border h-[20px] w-full" withVaultTheme />
        <LoadingSkeleton className="h-[18px] w-[200px]" withVaultTheme />
      </View>
    </View>
  );
}
