import { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faXmark } from '@soundxyz/font-awesome/pro-solid-svg-icons'; // Assuming you have a play circle icon
import { removeImage } from '../../contexts/ImagesMessageAttachmentContext';
import { clearVideo } from '../../contexts/VideoMessageAttachmentContext';
import { MediaType } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { View } from '../common/View';

const calculateCircumference = (radius: number) => 2 * Math.PI * radius;

export const UploadedMedia = ({
  identifier,
  type,
  cdnUrl,
  objectUrl,
  progress,
  uploaded,
  onView,
  channelIdentifier,
}: {
  identifier: string;
  type: MediaType;
  cdnUrl: string | null;
  objectUrl: string;
  progress: number;
  uploaded: boolean;
  onView: () => void;
  channelIdentifier: string;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const radius = 12;
  const circumference = calculateCircumference(radius);

  useEffect(() => {
    if (type === MediaType.Video && videoRef.current) {
      videoRef.current.load();
    }
  }, [type]);

  const renderMedia = () => {
    const src = cdnUrl || objectUrl;
    const commonClasses = 'aspect-square h-[72px] w-[72px] rounded-md object-cover';
    const commonStyle = { opacity: uploaded ? 1 : 0.2 };
    if (type === MediaType.Video) {
      return (
        <video
          ref={videoRef}
          src={src}
          className={commonClasses}
          muted
          style={commonStyle}
          preload="metadata"
        />
      );
    }
    return <Image src={src} alt="Uploaded" className={commonClasses} style={commonStyle} />;
  };

  return (
    <View className="relative shrink-0 cursor-pointer" onClick={onView}>
      {renderMedia()}

      {!uploaded && (
        <>
          <View className="absolute inset-0 bg-black bg-opacity-60" />
          <svg
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <circle
              cx="20"
              cy="20"
              r={radius}
              fill="transparent"
              stroke="#737373"
              strokeWidth="4"
            />
            <circle
              cx="20"
              cy="20"
              r={radius}
              fill="transparent"
              stroke="#fff"
              strokeWidth="4"
              strokeDasharray={circumference}
              strokeDashoffset={circumference - (Math.min(90, progress) / 100) * circumference}
              strokeLinecap="round"
            />
          </svg>
        </>
      )}
      {uploaded && type === MediaType.Video && (
        <FontAwesomeIcon
          icon={faPlayCircle}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-opacity-80"
          size="xl"
        />
      )}
      {uploaded && (
        <Button
          leadingIcon={faXmark}
          iconOnly
          iconSize="lg"
          onClick={e => {
            e.stopPropagation();
            if (type === MediaType.Image) {
              removeImage({ identifier: channelIdentifier, imageIdentifier: identifier });
            } else if (type === MediaType.Video) {
              clearVideo({ identifier: channelIdentifier });
            }
          }}
          label="Close"
          className="absolute right-[2px] top-[2px] aspect-square h-5 w-5 rounded-full bg-white text-black"
        />
      )}
    </View>
  );
};
