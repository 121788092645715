import { type RefObject, useEffect, useMemo, useRef, useState } from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChartData, Plugin } from 'chart.js';
import millify from 'millify';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCirclePlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMusic } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSave } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faRocket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import { GetWeeklySubscriberDataDocument, ThirdPartyPlatform } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useIsVisible } from '../../hooks/useIsVisible';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { SelectedArtistPersistence } from '../../hooks/useSelectedArtist';
import { useStableCallback } from '../../hooks/useStableCallback';
import { getTextColor, VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getColorWithOpacity } from '../../utils/colorUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { getDSPName, setField } from '../campaign/helpers';
import { CampaignType } from '../campaign/schema';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { CreateItem } from '../vault/CreateButton';
import { UploadButton } from '../vault/Upload';
import { EventsSection } from './EventsSection';
import { NewMembersSection } from './NewMembersSection';
import { Item } from './shared';

gql(/* GraphQL */ `
  query GetWeeklySubscriberData($vaultId: UUID!) {
    weeklyNewSubscribers(vaultId: $vaultId) {
      newSubscribersPerWeek {
        newSubscriberCount
        weekStart
      }
    }
    mySubscribersSummary(vaultId: $vaultId) {
      activeSubscriptionsCount
    }
  }
`);

export function ArtistMembershipView({
  setIsScrollingDown,
  scrollRef,
}: {
  setIsScrollingDown: (value: boolean) => void;
  scrollRef: RefObject<HTMLDivElement>;
}) {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const navigate = useNavigate();
  const { isDesktop } = useWindow();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const visibleRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible({ scrollRef, visibleRef });

  const ownedArtist = useOwnedArtist({ artistHandle });

  useEffect(() => {
    if (!isDesktop) {
      setIsScrollingDown(!isVisible);
    }
  }, [isDesktop, isVisible, setIsScrollingDown]);

  const onReleaseMusicClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CUSTOM,
      shared: {
        withVaultTheme: true,
      },
      customBottomsheetProps: {
        body: (
          <View className="flex flex-col gap-0.5">
            <CreateItem
              title="Pre-save"
              icon={faSave}
              description="Promote your upcoming release with a pre-save page"
              onClick={() => {
                if (!artistHandle) return;
                setField('campaignType', CampaignType.Presave);
                setField('dsps', [
                  {
                    key: ThirdPartyPlatform.Spotify,
                    name: getDSPName(ThirdPartyPlatform.Spotify),
                    uri: '',
                    buttonText: 'Play',
                  },
                  {
                    key: ThirdPartyPlatform.AppleMusic,
                    name: getDSPName(ThirdPartyPlatform.AppleMusic),
                    uri: '',
                    buttonText: 'Play',
                  },
                ]);
                navigate(artistNavigationPath(artistHandle, '/campaign/create'));
                closeBottomsheet();
              }}
            />
            <CreateItem
              title="Create release drop"
              icon={faRocket}
              description="Drive streams with a release page with every streaming platform"
              onClick={() => {
                if (!artistHandle) return;
                setField('campaignType', CampaignType.Stream);
                setField('dsps', [
                  {
                    key: ThirdPartyPlatform.Spotify,
                    name: 'Spotify',
                    uri: '',
                    buttonText: 'Play',
                  },
                  {
                    key: ThirdPartyPlatform.AppleMusic,
                    name: 'Apple Music',
                    uri: '',
                    buttonText: 'Play',
                  },
                ]);
                navigate(artistNavigationPath(artistHandle, '/campaign/create'));
                closeBottomsheet();
              }}
            />
          </View>
        ),
      },
    });
  });

  if (loginStatus === LoginStatus.LOADING) {
    return (
      <View className="w-full">
        {isDesktop && (
          <View className="mb-5 flex flex-row items-center justify-start gap-2">
            <LoadingSkeleton
              className="h-12 w-12 rounded-full border-2 border-solid border-vault_background"
              withVaultTheme
            />
            <LoadingSkeleton
              className="h-[22px] w-[100px] font-title text-[18px]/[22px] font-medium"
              withVaultTheme
            />
          </View>
        )}
        <LoadingSkeleton className="mb-8 h-[200px] w-full " withVaultTheme />
        <Item header="Engage with your members">
          <View className="grid w-full grid-cols-2 gap-3">
            <SkeletonArtistActionItem />
            <SkeletonArtistActionItem />
          </View>
        </Item>

        <Item header="Create a drop" className="mt-8">
          <View className="grid w-full grid-cols-2 gap-3">
            <SkeletonArtistActionItem />
            <SkeletonArtistActionItem />
          </View>
        </Item>
      </View>
    );
  }

  if (loggedInUser == null || ownedArtist == null || artistHandle == null) {
    return (
      <ErrorView
        onRetryClick={() => {
          navigate(artistNavigationPath(artistHandle, '/'));
        }}
        withVaultTheme
        className="mt-20"
      />
    );
  }
  const mainVaultId = ownedArtist.mainVault.id;
  const artistId = ownedArtist.id;
  const artistName = ownedArtist.name;

  return (
    <View className="mb-10 flex w-full flex-col gap-8">
      <Chart
        artistHandle={artistHandle}
        vaultId={mainVaultId}
        visibleRef={visibleRef}
        artistId={artistId}
        artistName={artistName}
        source="dashboard"
      />

      <Item header="Engage with your members">
        <View className="grid w-full grid-cols-2 gap-3">
          <UploadButton
            folderId={null}
            customLabel="Upload media"
            artistLinkValue={artistHandle}
            vaultId={mainVaultId}
          >
            <ArtistActionItem
              icon={faMusic}
              title="Upload"
              description="Upload a song, video, or image to your vault"
              ctaText="Upload"
            />
          </UploadButton>
          <ArtistActionItem
            icon={faMobile}
            title="Text members"
            description="Message your members via SMS"
            ctaText="Send SMS"
            onClick={() => {
              navigate(artistNavigationPath(artistHandle, '/messages/create'));
            }}
          />
        </View>
      </Item>

      <Item header="Create a drop">
        <View className="grid w-full grid-cols-2 gap-3">
          <ArtistActionItem
            icon={faMusic}
            title="Release a song"
            description="Pre-save and streaming drops"
            ctaText="Create drop"
            onClick={onReleaseMusicClick}
          />
          <ArtistActionItem
            icon={faReceipt}
            title="RSVP"
            description="Get RSVPs to your future announcement"
            ctaText="Create drop"
            onClick={() => {
              trackEvent({
                type: EVENTS.START_RSVP_CREATE,
                properties: {
                  artistHandle,
                },
              });
              navigate(artistNavigationPath(artistHandle, '/rsvp/create'));
            }}
          />
        </View>
      </Item>
      <EventsSection artistHandle={artistHandle} />
      <NewMembersSection
        artistHandle={artistHandle}
        artistId={ownedArtist.id}
        vaultId={mainVaultId}
        artistName={artistName}
      />
    </View>
  );
}

function ArtistActionItem({
  icon,
  title,
  description,
  ctaText,
  onClick,
}: {
  icon: IconDefinition;
  title: string;
  description: string;
  ctaText: string;
  onClick?: () => void;
}) {
  return (
    <View
      className="box-border flex h-[140px] w-full flex-col gap-2 rounded-md bg-vault_text bg-opacity-10 p-3 transition-all duration-200 ease-in hover:cursor-pointer hover:opacity-80"
      onClick={onClick}
    >
      <View className="flex w-full flex-row items-center gap-2">
        <FontAwesomeIcon icon={icon} className="text-vault_text" />
        <Text className="font-title text-[15px] font-medium text-vault_text">{title}</Text>
      </View>
      <Text className="flex-1 font-base text-[14px] font-light text-vault_text opacity-50">
        {description}
      </Text>
      <View className="flex w-full flex-row items-center gap-2 font-title text-[14px] font-normal text-vault_accent">
        <FontAwesomeIcon icon={faCirclePlus} />
        <Text>{ctaText}</Text>
      </View>
    </View>
  );
}

function SkeletonArtistActionItem() {
  return (
    <LoadingSkeleton
      className="box-border flex h-[140px] w-full flex-col gap-2 rounded-md p-3"
      withVaultTheme
    />
  );
}

export function Chart({
  artistHandle,
  vaultId,
  visibleRef,
  artistId,
  artistName,
  source,
}: {
  artistHandle: string;
  vaultId: string;
  visibleRef: RefObject<HTMLDivElement> | null;
  artistId: string;
  artistName: string;
  source: 'dashboard' | 'members';
}) {
  const [bgColors, setBgColors] = useState<(string | CanvasGradient)[]>([]);
  const [activeBar, setActiveBar] = useState<number | null>(null);
  const { accentColor, backgroundColor: backgroundColorTheme } = useSnapshot(VaultThemeStore);

  const accentTextColor = source === 'members' ? accentColor : getTextColor(accentColor);
  const textColor = source === 'members' ? getTextColor(backgroundColorTheme) : accentTextColor;

  const { isDesktop, width } = useWindow();

  const {
    data: { data = [], activeSubscriptionsCount = 0 } = {},
    isLoading,
    isError,
  } = useQuery(GetWeeklySubscriberDataDocument, {
    variables: !!vaultId && { vaultId },
    staleTime: 0,
    select: data => {
      return {
        data: data.data.weeklyNewSubscribers.newSubscribersPerWeek.toReversed(),
        activeSubscriptionsCount: data.data.mySubscribersSummary.activeSubscriptionsCount,
      };
    },
  });

  const activeIndex = activeBar ?? data.length - 1;

  const aspectRatio = useMemo(() => {
    const xPadding = 64;
    const leftMenuWidth = 272;
    const minimumDesktopWidth = 870;

    if (isDesktop) {
      return (minimumDesktopWidth - leftMenuWidth - xPadding) / 111;
    }
    return ((width ?? window.innerWidth) - xPadding) / 111;
  }, [isDesktop, width]);

  const labels = useMemo(() => {
    return data.map(item =>
      formatDateString({ date: item.weekStart, format: 'numerical_month_day' }),
    );
  }, [data]);

  const memberData = useMemo(() => {
    return data.map(item => item.newSubscriberCount);
  }, [data]);

  const maxWeek = useMemo(() => {
    return memberData.reduce((acc, curr) => (curr > acc ? curr : acc), 0);
  }, [memberData]);

  const backgroundColor = useMemo(() => {
    return bgColors.map((gradient, i) => {
      if (i === activeIndex) {
        return accentTextColor;
      }

      return gradient;
    });
  }, [bgColors, activeIndex, accentTextColor]);

  const hoverBackgroundColor = useMemo(() => {
    return Array.from({ length: memberData.length }, (_, i) =>
      i === activeIndex ? accentTextColor : getColorWithOpacity(accentTextColor, 0.3),
    );
  }, [accentTextColor, activeIndex, memberData.length]);

  const barData: ChartData<'bar', number[], string> = {
    labels,
    datasets: [
      {
        data: memberData,
        backgroundColor,
        hoverBackgroundColor,
        borderRadius: source === 'members' ? 3 : { topLeft: 10, topRight: 10 },
        borderSkipped: false,
      },
    ],
  };

  const plugins = useMemo((): Plugin<'bar', object>[] => {
    return [
      {
        id: 'click',
        afterEvent(chart, args) {
          if (args.event.type === 'click' && chart.scales.x && args.event.x != null) {
            const chartValue = chart.scales.x.getValueForPixel(args.event.x);

            if (chartValue == null) return;

            const label = chart.scales.x.getLabelForValue(chartValue);

            if (label == null) return;

            const labelIndex = labels.findIndex(value => value === label);

            if (labelIndex === -1) return;

            setActiveBar(labelIndex);
          }
        },
      },
    ];
  }, [labels]);

  if (isLoading || isError || data == null) {
    return <LoadingSkeleton className="h-[219px] w-full" withVaultTheme />;
  }

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-col justify-between gap-3 rounded-md px-4 py-2',
        source === 'dashboard'
          ? 'bg-vault_accent'
          : 'border border-solid border-vault_text/10 bg-vault_background !text-vault_text',
      )}
      onClick={() => {
        setActiveBar(null);
      }}
    >
      <View containerRef={visibleRef} className="flex flex-row items-end justify-between">
        <Link className="no-underline" to={artistNavigationPath(artistHandle, '/members')}>
          <Text
            className={twMerge(
              'cursor-pointer justify-start font-title text-[8px]/[10px] font-medium',
              source === 'dashboard' ? 'text-vault_accent_text' : 'text-vault_text',
            )}
          >
            <span className="text-[64px]/[64px]">
              {activeSubscriptionsCount >= 10_000
                ? millify(activeSubscriptionsCount, { lowercase: true })
                : activeSubscriptionsCount}
            </span>
            <br />
            <span className="text-[12px]/[16px]  font-normal opacity-60">Total members</span>
          </Text>
        </Link>

        <Text
          className={twMerge(
            'justify-start text-right font-title text-[8px]/[10px] font-medium text-vault_accent_text',
            source === 'dashboard' ? 'text-vault_accent_text' : 'text-vault_text',
          )}
        >
          <span className="text-[32px]/[32px]">
            {millify(memberData[activeIndex] ?? 0, { lowercase: true })}
          </span>
          <br />
          {source === 'members' ? (
            <Text className="text-[12px]/[16px] font-normal text-vault_text no-underline opacity-60 hover:opacity-50">
              {activeIndex < 9
                ? `${formatDateString({
                    date: data[activeIndex]?.weekStart ?? '',
                    format: 'month_day',
                  })} - ${formatDateString({
                    date: data[activeIndex + 1]?.weekStart ?? '',
                    format: 'month_day',
                  })}`
                : 'Current week'}
            </Text>
          ) : (
            <Link
              className="text-[12px]/[16px] font-normal text-vault_accent_text no-underline opacity-60 hover:opacity-50"
              onClick={() => {
                SelectedArtistPersistence.set({
                  artistHandle,
                  artistId,
                  artistName,
                });
              }}
              to={artistNavigationPath(artistHandle, '/members')}
            >
              {activeIndex < 9
                ? `${formatDateString({
                    date: data[activeIndex]?.weekStart ?? '',
                    format: 'month_day',
                  })} - ${formatDateString({
                    date: data[activeIndex + 1]?.weekStart ?? '',
                    format: 'month_day',
                  })}`
                : 'Current week'}{' '}
              <FontAwesomeIcon icon={faArrowUpRight} />
            </Link>
          )}
        </Text>
      </View>
      <View
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Bar
          key={textColor}
          ref={chartRef => {
            if (chartRef == null) {
              return;
            }

            const ctx = chartRef.ctx;

            setBgColors(
              memberData.map(data => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 111);

                gradient.addColorStop(
                  maxWeek === 0 ? 0.5 : Math.min(1 - data / maxWeek || 0, 0.5),
                  getColorWithOpacity(textColor, 0.3),
                );

                gradient.addColorStop(1, getColorWithOpacity(textColor, 0.06));

                return gradient;
              }),
            );
          }}
          data={barData}
          plugins={plugins}
          options={{
            onClick(_event, elements) {
              const activeElement = elements[0];

              setActiveBar(activeElement?.index ?? null);
            },
            onHover(_event, elements, chart) {
              const index = elements[0]?.index ?? 0;

              if (!index) {
                chart.canvas.style.cursor = 'default';
              } else {
                chart.canvas.style.cursor = 'pointer';
              }
            },
            aspectRatio,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                display: false,
              },
              x: {
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
                ticks: {
                  color({ index }) {
                    if (source === 'dashboard') {
                      return getColorWithOpacity(textColor, 0.6);
                    }

                    if (index === activeIndex) {
                      return getColorWithOpacity(accentColor, 1);
                    }

                    return getColorWithOpacity(textColor, 0.6);
                  },
                },
              },
            },
            elements: {
              bar: {
                hoverBackgroundColor: getColorWithOpacity(textColor, 0.3),
              },
            },
          }}
        />
      </View>
    </View>
  );
}
