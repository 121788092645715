import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { Button } from '../../tamagui-components/elements/Button';
import type { ExitFlowBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ExitFlowBottomsheet: FC<
  ExitFlowBottomsheetProps & {
    withVaultTheme: boolean;
  }
> = ({ onConfirm, event, withVaultTheme }) => {
  const { closeBottomsheet } = useBottomsheetContainer();

  return (
    <View
      className={twMerge(
        'flex w-full flex-col items-center justify-between md2:h-full md2:py-4',
        withVaultTheme ? 'text-vault_text' : 'text-white',
      )}
    >
      <Text className="mb-[16px] text-center font-title font-medium max-md2:text-title-m md2:text-title-xl">
        Are you sure?
      </Text>
      <Text className="mb-[16px] text-center font-base text-[18px]/[24px] font-normal">
        If you go back, your updates will be discarded.
      </Text>
      <View className="flex flex-row gap-2 max-md2:mb-[16px] md2:w-[calc(100%-20px)]">
        {/* TODO: fix this -> button wrapped in a button is not good semantics but need to figure out tamagui onPress */}
        <button
          onClick={e => {
            onConfirm(e);
            closeBottomsheet();
          }}
          className="w-full border-none bg-transparent outline-none"
        >
          <Button
            variant="secondary"
            label="Discard"
            event={{ type: EVENTS.EXIT_DISCARD, properties: { event } }}
            useVaultTheme={withVaultTheme}
            fullWidth
          />
        </button>

        <Button
          variant="primary"
          label="Cancel"
          useVaultTheme={withVaultTheme}
          onPress={() => closeBottomsheet()}
          className="ml-[8px] flex-1 "
          event={{ type: EVENTS.EXIT_CANCEL, properties: { event } }}
          fullWidth
        />
      </View>
    </View>
  );
};

export { ExitFlowBottomsheet };
