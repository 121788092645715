import { type FC, useEffect, useState } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSwipeable } from 'react-swipeable';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { VaultContentType } from '../../graphql/generated';
import { useOwnedArtist } from '../../hooks/useOwnedArtist';
import { useVaultContentPagination } from '../../hooks/useVaultContent';
import { AttachTrackContentTitles } from '../../store/trackTitles';
import type { AddTrackAttachmentBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { TrackFile } from '../track/TrackFile';
import { EmptyStateView } from '../views/EmptyStateView';

const AddTrackAttachmentBottomsheet: FC<
  AddTrackAttachmentBottomsheetProps & { setIsScrolling: (isScrolling: boolean) => void }
> = ({ onAttachTrack, vaultId, alreadyAttachedTrack = null, artist, setIsScrolling }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const [selectedTrack, setSelectedTrack] = useState<string | null>(alreadyAttachedTrack);
  const { closeBottomsheet } = useBottomsheetContainer();
  const handlers = useSwipeable({
    onSwipeStart: () => {
      setIsScrolling(true);
    },
    trackMouse: true,
  });

  const ownedArtist = useOwnedArtist({ artistId: artist.id });

  const {
    orderedList: vaultContents,
    loadMoreNextPage,
    hasNextPage,
    isInitialLoading,
  } = useVaultContentPagination({
    vaultId,
    pageSize: 10,
    isOwner: !!ownedArtist,
    contentType: VaultContentType.Track,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      loadMoreNextPage();
    }
  }, [inView, hasNextPage, loadMoreNextPage]);

  useEffect(() => {
    /**
     * From this component we get all the tracks that could be attached, so we store them in the track id to title
     * dictionary to be able to display the title of the track when attaching it on optimistic flows.
     */
    for (const content of vaultContents) {
      AttachTrackContentTitles.trackTitlesById[content.id] = content.title;
    }
  }, [vaultContents]);

  return (
    <View className="flex w-full flex-col items-center pb-5 pt-5">
      <Text className="mb-[28px] font-title text-title-m font-medium text-white">
        Select a track
      </Text>
      {vaultContents.length === 0 && !isInitialLoading && (
        <EmptyStateView
          title="Currently no tracks"
          subtitle={
            ownedArtist?.id === artist.id
              ? 'Add tracks to attach to your message'
              : `${artist.name} hasn't added any tracks yet.`
          }
          buttonText={ownedArtist?.id === artist.id ? 'Upload a new track' : undefined}
          buttonHref={`/${artist.linkValue}`}
          onButtonClick={closeBottomsheet}
          className="h-[500px]"
        />
      )}

      <View
        className="no-scrollbar mb-[32px] grid max-h-[67vh] flex-1 grid-cols-3 gap-x-[16px] gap-y-[32px] overflow-x-hidden overflow-y-scroll"
        swipeableHandlers={handlers}
      >
        {vaultContents.map((track, i) => {
          if (track.__typename !== 'VaultTrack') return null;

          return (
            <TrackFile
              key={track.id}
              track={track}
              type="message_attachment"
              isSelected={selectedTrack === track.id}
              onClick={() =>
                setSelectedTrack(_selectedTrack => (_selectedTrack === track.id ? null : track.id))
              }
              containerRef={i === vaultContents.length - 1 ? ref : undefined}
              vaultContentId={track.id}
            />
          );
        })}
      </View>

      <Button
        label="Attach"
        type="primary"
        onClick={() => {
          selectedTrack != null && onAttachTrack(selectedTrack);
          closeBottomsheet();
        }}
        disabled={selectedTrack == null}
        disabledClassName="invisible"
        event={
          selectedTrack != null
            ? {
                type: EVENTS.ATTACH_TRACK,
                properties: { trackId: selectedTrack, vaultId },
              }
            : undefined
        }
      />
    </View>
  );
};

export { AddTrackAttachmentBottomsheet };
