import { captureException } from '@sentry/react';
import { useToast } from '../contexts/ToastContext';
import {
  clearVideo,
  setVideo,
  updateProgress,
  updateVideoWithUploadResult,
  useVideoAttachment,
  VideoAttachments,
} from '../contexts/VideoMessageAttachmentContext';
import { uploadMultipartFile } from '../utils/s3Utils';

export const useSaveVideoAttachment = ({ identifier }: { identifier: string }) => {
  const { openToast } = useToast();

  useVideoAttachment({ identifier });

  const VideoAttachment = VideoAttachments[identifier];

  const saveVideoAttachment = async (artistId: string | null | undefined, file: File) => {
    clearVideo({ identifier });
    setVideo({ identifier, file });

    if (VideoAttachment != null) {
      VideoAttachment.isUploading = true;
    }

    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'VIDEO',
        setProgress: bytes => {
          const progress = Math.floor((bytes / file.size) * 100);
          updateProgress({ identifier, progress });
        },
        artistId,
      });
      updateVideoWithUploadResult({ identifier, mediaId, cdnUrl });
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'useVideoMessageAttachments',
        },
      });
      openToast({
        text: `There was an error uploading your video. ${error}`,
        variant: 'error',
      });
      clearVideo({ identifier });
    } finally {
      if (VideoAttachment != null) {
        VideoAttachment.isUploading = false;
      }
    }
  };

  return { saveVideoAttachment };
};
