export const ROUTES = {
  ABOUT: '/about',
  EXPLORE: '/explore',
  LANDING_PAGE: '/',
  NOT_FOUND: '/404',
  ONBOARDING: '/onboarding',
  ONBOARDING_USERNAME: '/onboarding/username',
  SETTINGS: '/settings',
  SETTINGS_ARTIST_PAYOUTS: '/settings/payouts',
  SETTINGS_ARTIST_EARNINGS: '/settings/artist-earnings',
  SETTINGS_ARTIST_EARNINGS_BREAKDOWN: '/settings/artist-earnings-breakdown',
  SETTINGS_ARTIST_SUBSCRIBERS: '/settings/subscribers',
  SETTINGS_ARTIST_MEMBERS: '/settings/members',
  SETTINGS_ARTIST_SMS_SETTINGS: '/settings/sms-settings',
  SETTINGS_COLLABORATOR_PAYOUTS: '/settings/collaborator-payouts',
  SETTINGS_NOTIFICATION_SETTINGS: '/settings/notification',
  SETTINGS_SMS_NOTIFICATION_SETTINGS: '/settings/sms-notification',
  SETTINGS_PUSH_NOTIFICATION_SETTINGS: '/settings/push-notification',
  SETTINGS_ACCOUNT_MANAGEMENT: '/settings/account-management',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_SUBSCRIPTIONS: '/settings/subscriptions',
  SETTINGS_MEMBERSHIPS: '/settings/memberships',
  SETTINGS_BILLING: '/settings/billing',
  SETTINGS_EARNINGS: '/settings/earnings',
  SETTINGS_ABOUT: '/settings/about-vault/about',
  SIGN_IN: '/sign_in',
  JOIN: '/join',
  VAULTS: '/vaults',
  VERIFY: '/verify',
  SPOTIFY_CONNECT: '/spotify-connect',
} as const;

export const HIDDEN_EXPLORE_VAULTS_NAVIGATION = true;
export const HIDDEN_ABOUT_NAVIGATION = true;
