import { TOS_URL } from '../constants/urlConstants';
import { STATSIG_CONFIG_KEYS, useStatsigConfig } from './useStatsigConfig';

export function useTosLink({ artistHandle }: { artistHandle: string | undefined }): {
  isLoading: boolean;
  url: string | undefined;
} {
  const { config, isLoading } = useStatsigConfig(STATSIG_CONFIG_KEYS.CUSTOM_TOS_LINK);
  if (isLoading) {
    return { isLoading, url: undefined };
  }

  if (artistHandle === undefined) {
    return { isLoading, url: TOS_URL };
  }

  const linkValue = config.tosUrls[artistHandle];
  if (linkValue === undefined) {
    return { isLoading, url: TOS_URL };
  }

  return { isLoading, url: linkValue };
}
