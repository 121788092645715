import { twMerge } from 'tailwind-merge';
import { useAuthorizedUserProfiles, useChosenUserProfile } from '../../../store/userProfile';
import type { AccountSwitcherBottomsheetProps } from '../../../types/bottomsheetTypes';
import { getUserProfileImageUrl } from '../../../utils/profileUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { AccountSwitcherUserRow } from './AccountSwitcherUserRow';

export function AccountSwitcherBottomsheet({ withVaultTheme }: AccountSwitcherBottomsheetProps) {
  const { userProfileId } = useChosenUserProfile();
  const { authorizedUserProfiles } = useAuthorizedUserProfiles();

  return (
    <View className="flex h-full max-h-[50vh] w-full flex-col overflow-y-auto px-4 py-6 scrollbar-none">
      <Text
        className={twMerge(
          'mb-6 text-center font-title text-[28px]/[34px] font-medium',
          withVaultTheme ? 'text-vault_text' : 'text-white',
        )}
      >
        Your accounts
      </Text>

      <View className="flex w-full flex-col gap-4">
        {authorizedUserProfiles?.map(userProfile => {
          const isArtist = userProfile.user.artist != null;
          const avatarUrl = getUserProfileImageUrl({
            artist: userProfile.user.artist,
            user: userProfile.user,
          });
          const isSelected = userProfile.user.id === userProfileId;
          const usernameValue =
            userProfile.user.artist?.linkValue ?? userProfile.user.username ?? undefined;
          const username = usernameValue ? `@${usernameValue}` : undefined;

          return (
            <AccountSwitcherUserRow
              key={userProfile.user.id}
              avatarUrl={avatarUrl}
              isSelected={isSelected}
              name={userProfile.user.computedDisplayName}
              username={username}
              isArtist={isArtist}
              userId={userProfile.user.id}
              withVaultTheme={withVaultTheme}
              artistHandle={userProfile.user.artist?.linkValue}
            />
          );
        })}
      </View>
    </View>
  );
}
