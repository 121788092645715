import type { MutableRefObject } from 'react';

import { UAParser } from 'ua-parser-js';
import { proxy } from 'valtio';
import { PathNames } from '@soundxyz/vault-utils/paths';
import { mixpanelClient } from '../clients/mixpanelClient';
import { LoggedInUserEventProperties } from '../providers/AuthProvider';
import { Sentry } from '../sentry';
import type { BottomsheetType } from '../types/bottomsheetTypes';
import type { EventProperties, EventType } from '../types/eventTypes';

export const uaParser = new UAParser();

export const CurrentPageState = proxy<{
  page: keyof typeof PathNames | 'UNMAPPED';
}>({
  page: 'UNMAPPED',
});

export const sendPageAnalytics = async ({
  pathname,
  previousAnalyticsPage,
  searchParams,
  vaultSubscriptionStatus,
  vaultArtistHandle,
  userId,
  openBottomsheet,
}: {
  pathname: string;
  previousAnalyticsPage: MutableRefObject<{
    current_page_title: string;
    timestamp: string;
    pathname: string;
  } | null>;
  searchParams: { [key: string]: string };
  vaultArtistHandle: string | null;
  vaultSubscriptionStatus: string | null;
  userId: string | null;
  openBottomsheet: BottomsheetType | null;
}) => {
  if (previousAnalyticsPage.current?.pathname === pathname) {
    return;
  }

  const result = uaParser.getResult();
  const { isbot } = await import('isbot');

  if (isbot(result.ua)) {
    // Do not report page view analytics from bots
    return;
  }

  const page = CurrentPageState.page;

  const page_name = page !== 'UNMAPPED' ? PathNames[page] : 'unmapped';

  const referrer = document.referrer;

  const now = new Date();

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(`[Page Analytics] ${new Date().toUTCString()} page=${JSON.stringify(page_name)}`);
  }

  mixpanelClient.track_pageview({
    page,
    page_name,
    vault: vaultArtistHandle,
    vaultSubscriptionStatus,
    userId,
    openBottomsheet,
    height: window?.innerHeight || 0,
    width: window?.innerWidth || 0,
    version: import.meta.env.NEXT_PUBLIC_WEBAPP_VERSION,
    platform: 'WEB',
    browser: {
      name: result.browser.name,
      version: result.browser.version,
    },
    device: {
      vendor: result.device.vendor,
      name: result.os.name,
      version: result.os.version,
    },
    searchParams,
    referrer,
    performedByActiveUser: LoggedInUserEventProperties.highestSubscriptionLevel === 'PAID',
    subscriptionStatus: LoggedInUserEventProperties.highestSubscriptionLevel,
    previousPage:
      previousAnalyticsPage.current != null
        ? {
            ...previousAnalyticsPage.current,
            duration: now.getTime() - new Date(previousAnalyticsPage.current.timestamp).getTime(),
          }
        : undefined,
  });
  previousAnalyticsPage.current = {
    current_page_title: page_name,
    timestamp: now.toISOString(),
    pathname,
  };
};

export async function trackEvent<Event extends EventType>({
  type,
  properties,
}: {
  type: Event;
  properties: EventProperties[Event];
}) {
  try {
    const page = CurrentPageState.page;
    const page_name = page !== 'UNMAPPED' ? PathNames[page] : 'unmapped';

    const userAgent = uaParser.getResult();

    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log(
        `[${type}] ${new Date().toUTCString()} page=${JSON.stringify(page_name)} properties=${JSON.stringify(properties)}`,
      );
    }

    mixpanelClient.track(type, {
      height: window?.innerHeight || 0,
      width: window?.innerWidth || 0,
      platform: 'WEB',
      browser: {
        name: userAgent.browser.name,
        version: userAgent.browser.version,
      },
      device: {
        vendor: userAgent.device.vendor,
        name: userAgent.os.name,
        version: userAgent.os.version,
      },
      page,
      page_name,
      ...properties,
      version: process.env.NEXT_PUBLIC_WEBAPP_VERSION,
      performedByActiveUser: LoggedInUserEventProperties.highestSubscriptionLevel === 'PAID',
      subscriptionStatus: LoggedInUserEventProperties.highestSubscriptionLevel,
    });
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        type: 'trackMixpanelEvent',
        mixpanelEvent: type,
      },
      level: 'warning',
    });
  }
}
