import React, { type LegacyRef, useMemo } from 'react';

import { gql } from '@soundxyz/gql-string';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  type FragmentType,
  GetBatchedTracksViewedDocument,
  getFragment,
  MediaType,
  UpsertUserContentViewDocument,
  VaultItemFragmentDoc,
  VaultUpdateCountsDocument,
} from '../../../graphql/generated';
import { VaultItemArtist } from './artist/VaultItemArtist';
import { VaultItemUser } from './user/VaultItemUser';

gql(/* GraphQL */ `
  fragment VaultItem on VaultContent {
    id
    caption
    __typename
    parentVaultContentId
    title
    ... on VaultTrack {
      id
      blurredThumbnailMediaUrl
      thumbnailMedia {
        id
        imageSmallUrl: imageOptimizedUrl(input: { width: 80, height: 80 })
      }
      vault {
        id
        artistProfile {
          id
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
          }
        }
      }
      ...TrackItem
    }
    ... on VaultFolder {
      id
      ...FolderItem
    }

    ... on VaultVideo {
      id
      uploadedMedia {
        id
        mediaType
        videoUrl: cdnUrl
        videoScreenshotUrl
      }
      ...VideoItem
    }

    ... on VaultImage {
      id
      uploadedMedia {
        id
        mediaType
        imageUrl: imageOptimizedUrl
        cdnUrl
      }
      ...ImageItem
    }
  }

  mutation UpsertUserContentView($input: MutationUpsertUserViewContentInput!) {
    upsertUserViewContent(input: $input) {
      id
    }
  }
`);

RefetchOnComplete({
  trigger: [UpsertUserContentViewDocument],
  refetch: [GetBatchedTracksViewedDocument, VaultUpdateCountsDocument],
  throttled: '500ms',
});

const VaultItemComponent = React.memo(
  ({
    item,
    allImageAndVideos,
    containerRef,
    vaultId,
    artistHandle,
    isOwner,
  }: {
    item: FragmentType<VaultItemFragmentDoc>;
    allImageAndVideos?: FragmentType<VaultItemFragmentDoc>[];
    containerRef?: LegacyRef<HTMLDivElement>;
    vaultId: string;
    artistHandle: string;
    isOwner: boolean;
  }) => {
    const reorderedAssets = useMemo(() => {
      return [
        item,
        ...(allImageAndVideos ?? [])
          .filter((media): media is NonNullable<typeof media> => {
            const fragment = getFragment(VaultItemFragmentDoc, media);
            return (
              (fragment.__typename === 'VaultImage' && fragment.uploadedMedia != null) ||
              (fragment.__typename === 'VaultVideo' && fragment.uploadedMedia != null)
            );
          })
          .filter(media => {
            const fragment = getFragment(VaultItemFragmentDoc, media);
            return fragment.id !== getFragment(VaultItemFragmentDoc, item).id;
          }),
      ]
        .map(asset => {
          const fragment = getFragment(VaultItemFragmentDoc, asset);
          if (fragment.__typename !== 'VaultImage' && fragment.__typename !== 'VaultVideo') {
            return null;
          }
          if (fragment.title == null || fragment.uploadedMedia == null) {
            return null;
          }
          return {
            id: fragment.id,
            type: fragment.__typename === 'VaultImage' ? MediaType.Image : MediaType.Video,
            url:
              fragment.__typename === 'VaultImage'
                ? fragment.uploadedMedia.imageUrl ?? fragment.uploadedMedia.cdnUrl
                : fragment.uploadedMedia.videoUrl,
            title: fragment.title,
            caption: fragment.caption,
            thumbnailUrl:
              fragment.__typename === 'VaultVideo'
                ? fragment.uploadedMedia.videoScreenshotUrl
                : undefined,
          };
        })
        .filter((item): item is NonNullable<typeof item> => item !== null);
    }, [item, allImageAndVideos]);

    if (isOwner) {
      return (
        <VaultItemArtist
          item={item}
          allAssets={reorderedAssets}
          containerRef={containerRef}
          vaultId={vaultId}
          artistHandle={artistHandle}
        />
      );
    }

    return (
      <VaultItemUser
        item={item}
        allAssets={reorderedAssets}
        containerRef={containerRef}
        artistHandle={artistHandle}
      />
    );
  },
);

VaultItemComponent.displayName = 'VaultItem';

export const VaultItem = React.memo(VaultItemComponent);
