import { useEffect } from 'react';
import { gql } from '@soundxyz/gql-string';
import {
  ERROR_TYPE,
  FEATURES,
  NOTIFICATIONS_ERROR_ACTIONS,
  NOTIFICATIONS_INFO_ACTIONS,
} from '@soundxyz/vault-utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { useMutation, useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  UpdateUserPushNotificationSettingsDocument,
  UpdateUserSmsNotificationSettingsDocument,
  UserNotificationSettingsDocument,
} from '../graphql/generated';
import { useLogError } from './logger/useLogError';
import { logInfo } from './logger/useLogInfo';

gql(/* GraphQL */ `
  query UserNotificationSettings {
    currentUser {
      __typename
      ... on QueryCurrentUserSuccess {
        data {
          id
          notificationSettings {
            isSmsEnabled
            pushNotificationsEnabled
          }

          ...userNotificationSettings
        }
      }
      ... on AccountDeletedPending {
        message
      }

      ... on Error {
        message
      }
    }
  }

  mutation UpdateUserSMSNotificationSettings($input: MutationUpdateSmsNotificationsSettingsInput!) {
    updateSmsNotificationsSettings(input: $input) {
      id
      ...userNotificationSettings
    }
  }

  mutation UpdateUserPushNotificationSettings(
    $input: MutationUpdatePushNotificationsSettingsInput!
  ) {
    updatePushNotificationsSettings(input: $input) {
      id
      ...userNotificationSettings
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserSmsNotificationSettingsDocument, UpdateUserPushNotificationSettingsDocument],
  refetch: [UserNotificationSettingsDocument],
});

const feature = FEATURES.NOTIFICATIONS;

export function useNotificationSettings() {
  const { loggedInUser } = useAuthContext();
  const { data, isError, isLoading, isInitialLoading, refetch, error } = useQuery(
    UserNotificationSettingsDocument,
    {
      staleTime: 0,
    },
  );

  const logError = useLogError();

  const { mutateAsync: updateSmsSettings } = useMutation(
    UpdateUserSmsNotificationSettingsDocument,
    {
      onError: error => {
        logError({
          action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
          error,
          level: 'warning',
          message: 'Failed to update SMS notification settings',
          errorType: ERROR_TYPE.MUTATION_ERROR,
          feature,
          indexedTags: {
            userId: loggedInUser?.id,
            source: 'useNotificationSettings',
          },
        });
      },
      onSuccess: () => {
        logInfo({
          action: NOTIFICATIONS_INFO_ACTIONS.UPDATE_NOTIFICATION_SETTINGS,
          message: 'SMS notification settings updated',
          feature,
          data: {
            userId: loggedInUser?.id,
          },
        });
      },
    },
  );

  const { mutateAsync: updatePushSettings } = useMutation(
    UpdateUserPushNotificationSettingsDocument,
    {
      onError: error => {
        logError({
          action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
          error,
          level: 'warning',
          message: 'Failed to update Push notification settings',
          errorType: ERROR_TYPE.MUTATION_ERROR,
          feature,
          indexedTags: {
            userId: loggedInUser?.id,
            source: 'useNotificationSettings',
          },
        });
      },
      onSuccess: () => {
        logInfo({
          action: NOTIFICATIONS_INFO_ACTIONS.UPDATE_NOTIFICATION_SETTINGS,
          message: 'Push notification settings updated',
          feature,
          data: {
            userId: loggedInUser?.id,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (!error) return;
    logError({
      action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
      error,
      level: 'warning',
      message: 'Failed to fetch notification settings',
      errorType: ERROR_TYPE.FETCH_GQL_ERROR,
      feature,
      indexedTags: {
        userId: loggedInUser?.id,
        source: 'useNotificationSettings',
      },
    });
  }, [error, logError, loggedInUser?.id]);

  return {
    updateSmsSettings,
    updatePushSettings,
    data: data?.data,
    isError:
      isError || (!!data?.data && data.data.currentUser.__typename !== 'QueryCurrentUserSuccess'),
    error,
    isLoading,
    refetch,
    isInitialLoading,
  };
}
