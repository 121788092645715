import { type ReactNode, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import {
  faArrowUpArrowDown,
  faFolder,
  faFolderPlus,
  faUpload,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faAdd } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import {
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_AUDIO_UPLOAD_SIZE_BYTES,
  MAX_AUDIO_UPLOAD_SIZE_TEXT,
} from '../../constants/fileConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useUploadVaultContentFile } from '../../hooks/useUploadVaultContentFile';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { FOLDER_TITLE_MAX_LENGTH, useVaultFolder } from '../../hooks/vault/useVaultFolder';
import { FOLDER_TITLE_MIN_LENGTH } from '../../hooks/vault/useVaultFolder';
import { Button } from '../../tamagui-components/elements/Button';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import type { EventObject } from '../../types/eventTypes';

import type { EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { TextInputModal } from '../bottomsheets/TextInputModal';
import { DropdownEllipsis } from '../common/Dropdown';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useVaultContentUpload } from '../upload/useContentUpload';

export const UploadButton = <Event extends EventType>({
  artistLinkValue,
  className,
  event,
  vaultId,
  customLabel,
  onNavigate,
  folderId,
  children,
  rootClassName,
  disabled,
}: {
  artistLinkValue: string;
  className?: string;
  event?: EventObject<Event>;
  vaultId: string;
  customLabel?: string;
  onNavigate?: () => void;
  folderId: string | null;
  children?: ReactNode;
  rootClassName?: string;
  disabled?: boolean;
}) => {
  const { saveVaultContentFile } = useUploadVaultContentFile({
    artistLinkValue,
    vaultId,
    folderId,
  });
  const { setField, clearFields } = useVaultContentUpload({ vaultId });

  const { openToast } = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    async onDropAccepted([file]) {
      setField('isPromptOpen', true);
      setField('folderId', folderId);
      onNavigate?.();
      await saveVaultContentFile(file);
    },
    onDropRejected() {
      openToast({
        text: `File could not be uploaded. Make sure it is a WAV or MPEG file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
        variant: 'error',
      });
      setField('isPromptOpen', false);
    },
    onFileDialogCancel() {
      setField('isPromptOpen', false);
    },
    accept: {
      ...ACCEPTED_AUDIO_MIME_TYPES,
      ...ACCEPTED_IMAGE_TYPES,
      ...ACCEPTED_VIDEO_TYPES,
    },
    maxFiles: 1,
    maxSize: MAX_AUDIO_UPLOAD_SIZE_BYTES,
    disabled: false,
  });

  const rootProps = getRootProps();

  return (
    <div
      className={twMerge('hover:cursor-pointer', rootClassName)}
      {...rootProps}
      onClick={(...args) => {
        if (disabled) return;

        event != null && trackEvent(event);
        clearFields();
        setField('isPromptOpen', true);

        rootProps.onClick?.(...args);
      }}
    >
      {children ?? (
        <View className={twMerge('flex flex-row items-center', className)}>
          <FontAwesomeIcon className="aspect-square" icon={faUpload} fontSize={24} />
          <View className="flex flex-col">
            <Text className="text-title-s font-medium text-vault_text">
              {customLabel ?? 'Upload'}
            </Text>
            <Text className="text-base-m font-normal text-vault_text/40">
              Add a song, video, or image to your vault
            </Text>
          </View>
        </View>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export function UploadDropdownSection({
  artistLinkValue,
  vaultId,
  folderId,
}: {
  artistLinkValue: string;
  vaultId: string;
  folderId: string | null;
}) {
  const { setIsSelecting, isSelecting } = useSelectVaultContent();
  const { closeBottomsheet } = useBottomsheetContainer();

  const { createFolder } = useVaultFolder({
    vaultId,
  });

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { openOverlay, closeOverlay } = useOverlayContainer();

  const onCreateFolderClick = useStableCallback(() => {
    closeBottomsheet();
    openOverlay(
      <TextInputModal
        title="Create New Folder"
        confirmText="Create"
        placeholder="Untitled"
        minLength={FOLDER_TITLE_MIN_LENGTH}
        maxLength={FOLDER_TITLE_MAX_LENGTH}
        onConfirm={async text => {
          await createFolder({
            title: text,
            folderId,
            onSuccess: () => {
              closeOverlay();
            },
          });
        }}
      />,
    );
  });

  useEffect(() => {
    if (!isSelecting) return;

    return () => {
      setIsSelecting(false);
    };
  }, [isSelecting, setIsSelecting]);

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    return [
      {
        label: 'Create folder',
        onClick: onCreateFolderClick,
        trailingIcon: faFolderPlus,
        className: 'w-full flex justify-between',
        type: 'secondary',
      },
      {
        label: 'Select',
        onClick: () => {
          setIsSelecting(!isSelecting);
          closeBottomsheet();
        },
        trailingIcon: faFolder,
        type: 'secondary',
        className: 'w-full flex justify-between',
      },
      {
        label: 'Sort',
        trailingIcon: faArrowUpArrowDown,
        href: artistNavigationPath(
          artistLinkValue,
          folderId ? `/rearrange/${folderId}` : '/rearrange',
        ),
        type: 'secondary',
        className: 'w-full flex justify-between',
      },
    ] satisfies ActionBottomsheetProps['buttons'];
  }, [
    artistLinkValue,
    setIsSelecting,
    closeBottomsheet,
    isSelecting,
    onCreateFolderClick,
    folderId,
  ]);

  const {
    fields: { isUploading },
  } = useVaultContentUpload({ vaultId });

  const uploadDisabled = isVaultCustomizeOpen || isUploading;

  return (
    <View className="flex w-fit items-center gap-2">
      <DropdownEllipsis
        buttons={buttons}
        dropdownType="vault"
        onClick={null}
        sharedClassname="p-2 border border-solid border-vault_text/20 rounded-full font-base text-vault_text w-10 h-10"
        sideOffset={10}
        disabled={isVaultCustomizeOpen}
      />
      {isSelecting && folderId != null && (
        <Button
          label="Cancel"
          variant="secondary"
          useVaultTheme
          onPress={() => {
            setIsSelecting(false);
          }}
        />
      )}
      <UploadButton
        artistLinkValue={artistLinkValue}
        vaultId={vaultId}
        folderId={folderId}
        disabled={uploadDisabled}
      >
        <Button
          icon={faAdd}
          variant="primary"
          useVaultTheme
          label="Upload"
          disabled={uploadDisabled}
          loading={isUploading}
        />
      </UploadButton>
    </View>
  );
}
