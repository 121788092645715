import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BackButton } from '../../../components/buttons/BackButton';
import { View } from '../../../components/common/View';
import { ShareDropView } from '../../../components/vault/share/ShareDropView';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';

export function ShareDropPage({ type }: { type: 'rsvp' | 'campaign' }) {
  const { isDesktop } = useWindow();
  const { dropSlug } = useParams();
  const { artistHandle } = useArtistHandle();
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const ownedArtist = useOwnedArtist({ artistHandle });

  useEffect(() => {
    if (
      (loginStatus === LoginStatus.LOGGED_IN && ownedArtist == null) ||
      loginStatus === LoginStatus.LOGGED_OUT
    ) {
      navigate(-1);
    }
  }, [ownedArtist, loginStatus, navigate]);

  useEffect(() => {
    if (dropSlug == null || artistHandle == null) {
      navigate(-1);
    }
  }, [artistHandle, dropSlug, navigate]);

  useEffect(() => {
    if (isDesktop) {
      navigate(-1);
      if (ownedArtist != null && dropSlug != null && artistHandle != null) {
        openBottomsheet({
          type: 'SHARE_DROP',
          shareDropBottomsheetProps: {
            dropSlug,
            artistHandle,
            type,
          },
          shared: {
            withVaultTheme: true,
            preventOutsideAutoClose: true,
          },
        });
      }
    }
  }, [ownedArtist, artistHandle, dropSlug, isDesktop, navigate, openBottomsheet, type]);

  if (dropSlug == null || artistHandle == null) {
    return null;
  }

  return (
    <View className="box-border flex h-full w-full flex-1 flex-col items-start justify-start bg-vault_background px-4 pt-4">
      <BackButton withVaultTheme icon={faClose} />
      <View className="mb-4 flex w-full flex-1 flex-col items-center justify-center">
        <ShareDropView dropSlug={dropSlug} artistHandle={artistHandle} type={type} />
      </View>
    </View>
  );
}
