import * as z from 'zod';
import { PersistenceStorage } from '../../utils/storeUtils';

export const SignInStore = PersistenceStorage({
  schema: z.object({
    codeRenabled: z.record(z.number()),
    lastActivePhoneNumber: z.string().nullable(),
    lastCountryCode: z.string().optional(),

    lastActiveEmail: z.string().nullable(),

    phone: z.string().default(''),
    countryCode: z.string().default('US'),

    email: z.string().default(''),

    isOpen: z.boolean().default(false),

    errorText: z.string().nullable().default(null),

    authMethod: z.enum(['sms', 'email']).default('sms'),

    emailStatus: z
      .enum(['initial', 'sending-code', 'submitting-code', 'error', 'awaiting-code-input', 'done'])
      .default('initial'),
  }),
  key: 'SignInTimestamps',
  eager: true,
  extra: {
    setPhone: (phone: string) => {
      SignInStore.produceExistingState(
        draft => {
          draft.phone = phone;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setEmail: (email: string) => {
      SignInStore.produceExistingState(
        draft => {
          draft.email = email;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setCountryCode: (countryCode: string) => {
      SignInStore.produceExistingState(
        draft => {
          if (draft.lastCountryCode === countryCode) return;
          draft.countryCode = countryCode;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setErrorText: (errorText: string | null) => {
      SignInStore.produceExistingState(
        draft => {
          if (draft.errorText === errorText) return;

          draft.errorText = errorText;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setIsOpen: (isOpen: boolean) => {
      SignInStore.produceExistingState(
        draft => {
          draft.isOpen = isOpen;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setAuthMethod: (authMethod: 'sms' | 'email') => {
      SignInStore.produceExistingState(
        draft => {
          draft.authMethod = authMethod;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
    setEmailStatus: (
      emailStatus:
        | 'initial'
        | 'sending-code'
        | 'submitting-code'
        | 'error'
        | 'awaiting-code-input'
        | 'done',
    ) => {
      SignInStore.produceExistingState(
        draft => {
          draft.emailStatus = emailStatus;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
          lastActiveEmail: null,
        },
      );
    },
  },
});

export const THIRTY_SECONDS_IN_MS = 30_000;
