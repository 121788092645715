import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
import { InfoToggle } from '../../../tamagui-components/structures/InfoToggle';

export function HookToggle<T extends FieldValues>({
  control,
  name,
  label,
  subLabel,
  useVaultTheme = false,
}: {
  name: Path<T>;
  control: Control<T>;
  label: string;
  subLabel?: string;
  useVaultTheme?: boolean;
}) {
  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <InfoToggle
          name={name}
          label={label}
          subText={subLabel}
          checked={!!value}
          onCheckedChange={onChange}
          useVaultTheme={useVaultTheme}
        />
      )}
    />
  );
}
