export const FEATURE_GATES = {
  KILLSWITCH_CHAT: 'killswitch_vault_chat',
  KILLSWITCH_SUBSCRIPTION: 'killswitch_vault_subscription',
  MEDIA_COMMENTS: 'vault_media_comments_web',
  EXPOSE_CAMPAIGN_DATA: 'vault_expose_campaign_data',
  LINKIFY_ALL: 'vault_linkify_all',
} as const satisfies Record<string, string>;

export const FEATURE_GATE_OVERRIDES: Record<keyof typeof FEATURE_GATES, boolean | undefined> = {
  KILLSWITCH_CHAT: undefined,
  KILLSWITCH_SUBSCRIPTION: undefined,
  MEDIA_COMMENTS: undefined,
  EXPOSE_CAMPAIGN_DATA: undefined,
  LINKIFY_ALL: undefined,
};
