import { type LegacyRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useMenuContainer } from '../../../contexts/MenuContext';
import {
  type FragmentType,
  getFragment,
  ImageItemFragmentDoc,
  VaultContentType,
} from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { View } from '../../common/View';
import { VaultContent } from '../../elements/VaultContent';

gql(/* GraphQL */ `
  fragment ImageItem on VaultImage {
    id
    title
    caption
    linkValue
    createdAt
    blurredMediaUrl
    downloadEnabled

    uploadedMedia {
      id
      mediaType
      fullImageUrl: imageOptimizedUrl
      mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
      smallImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      cdnUrl
    }

    vaultId

    parentVaultContentId

    featureAccess {
      feature {
        __typename
      }
    }

    vault {
      id
      isUserArtistAdmin
      artist: artistProfile {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
      }
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);

export const ImageItem = ({
  image,
  containerRef,
  onClick,
  hasEllipsis,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  onClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const {
    title,
    createdAt,
    id: imageId,
    vault,
    uploadedMedia,
    blurredMediaUrl,
  } = getFragment(ImageItemFragmentDoc, image);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: imageId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const couldBeNew =
    !isOwner && vault.activeSubscription != null && vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  const mediaUrl = uploadedMedia?.smallImageUrl ?? uploadedMedia?.fullImageUrl;

  return (
    <VaultContent
      containerRef={containerRef}
      contentId={imageId}
      type={VaultContentType.Image}
      title={title}
      media={mediaUrl}
      blurredMedia={blurredMediaUrl}
      createdAt={createdAt}
      isNew={isNew}
      isLoading={isLoading}
      locked={isLocked}
      hasEllipsis={hasEllipsis}
      onClick={onClick}
      isSnippet={false}
    />
  );
};

export const LockedImageItem = ({
  blurredMediaUrl,
  onClick,
}: {
  blurredMediaUrl: string | null;
  onClick?: () => void;
}) => {
  return (
    <>
      {blurredMediaUrl ? (
        <View className="relative flex h-full w-14 items-center justify-center">
          <img
            className="block h-full w-14 cursor-pointer rounded-lg bg-vault_text/10 object-cover"
            src={blurredMediaUrl}
            onClick={onClick}
          />
          <div className="absolute flex h-6 w-6 items-center justify-center rounded-full bg-vault_text_opposite/50">
            <FontAwesomeIcon icon={faLock} className="text-[10px] text-vault_text/50" />
          </div>
        </View>
      ) : (
        <>
          <View className="bg-new-file-background h-[74px] w-[54px] bg-contain bg-no-repeat" />
          <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
            {/* TODO: Better icon */}
            <FontAwesomeIcon icon={faImage} className="text-[24px] text-vault_text" />
            <FontAwesomeIcon icon={faLock} className="text-[11px] text-vault_text" />
          </View>
        </>
      )}
    </>
  );
};
