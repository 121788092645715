import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { Virtuoso } from 'react-virtuoso';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { VaultRow } from '../../components/vault/VaultRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  getFragment,
  GetSubscriptionsDocument,
  type SubscriptionRowFragment,
  SubscriptionRowFragmentDoc,
  TierTypename,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import { getManyFromList } from '../../utils/arrayUtils';
import { formatDateString } from '../../utils/textUtils';

gql(/* GraphQL */ `
  fragment SubscriptionRow on VaultSubscription {
    id
    status
    createdAt
    updatedAt
    currentPeriodEnd
    isTrial
    tier {
      __typename
    }
    vault {
      artist: artistProfile {
        linkValue
        ...artistVaultRow
      }
      contentCount
      id
    }
  }

  query GetSubscriptions($after: String, $first: Int!) {
    allVaultSubscriptionsByPriority(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...SubscriptionRow
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 10;

const SubscriptionsPage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const {
    orderedList: subscriptions,
    hasNextPage,
    loadMoreNextPage,
    isInitialLoading,
    isError,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(GetSubscriptionsDocument, {
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    staleTime: 0,
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    getNextPageParam: ({ data }) => {
      return (
        data.allVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.allVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ allVaultSubscriptionsByPriority }) => {
      return getManyFromList(allVaultSubscriptionsByPriority.edges, ({ node }) => {
        const val = getFragment(SubscriptionRowFragmentDoc, node);

        if (val.vault == null || val.vault.artist == null) {
          return null;
        }

        return val;
      });
    },
    uniq: ({ id }) => id,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      loadMoreNextPage();
    }
  }, [hasNextPage, isAtBottom, loadMoreNextPage, isFetchingNextPage]);

  const renderItems = useStableCallback((_i: number, subscription: SubscriptionRowFragment) => {
    return <ManageSubscriptionRow key={subscription.id} subscription={subscription} />;
  });

  const EmptyPlaceholder = useStableCallback(() => (
    <EmptyStateView
      title="You currently have no memberships"
      subtitle="Explore artists on Vault.fm and subscribe to your favorite artists"
      buttonText="Explore vaults"
      buttonHref={ROUTES.VAULTS}
    />
  ));

  const Footer = useStableCallback(() => <div className="h-4" ref={bottomRef} />);

  if (isError && subscriptions.length === 0) {
    return (
      <SettingsLayout
        title="Memberships"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="subscriptions_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (loginStatus === LoginStatus.LOADING || isInitialLoading) {
    return (
      <SettingsLayout title="Memberships">
        <SkeletonSubscriptionsView />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout title="Memberships" stretch>
      <Virtuoso
        className="no-scrollbar h-full w-full"
        data={subscriptions}
        components={{
          EmptyPlaceholder,
          Footer,
        }}
        itemContent={renderItems}
      />
    </SettingsLayout>
  );
};

const ManageSubscriptionRow: FC<{
  subscription: SubscriptionRowFragment;
}> = ({ subscription }) => {
  const { id, status, isTrial, updatedAt, currentPeriodEnd, vault, tier } = subscription;

  const subText = () => {
    const formatDate = (date: string) => formatDateString({ date, format: 'month_day' });
    const now = new Date().toISOString();

    if (status === 'ACTIVE') {
      if (tier.__typename === TierTypename.FreeTier) {
        return `Since ${formatDate(updatedAt)} • Free Tier`;
      }
      if (currentPeriodEnd && now < currentPeriodEnd) {
        return `${isTrial ? `Free Trial until  ${formatDate(currentPeriodEnd)}` : `Since ${formatDate(updatedAt)} • All Access`}`;
      }
    } else {
      if (currentPeriodEnd && now < currentPeriodEnd) {
        return `Membership ends on ${formatDate(currentPeriodEnd)}`;
      }
    }

    if (isTrial) {
      return `Free trial ${currentPeriodEnd && now < currentPeriodEnd ? `until ${formatDate(currentPeriodEnd)}` : ''}`;
    }

    return 'Inactive';
  };

  return (
    <VaultRow
      artist={vault.artist}
      key={vault.id}
      className="mb-3 box-border w-full items-center bg-base800 pl-4 pr-6"
      subText={subText()}
      href={
        status === 'ACTIVE' ||
        (currentPeriodEnd != null && new Date().toISOString() < currentPeriodEnd)
          ? `${id}`
          : undefined
      }
      actionComponent={
        (status === 'ACTIVE' ||
          (currentPeriodEnd != null && new Date().toISOString() < currentPeriodEnd)) && (
          <FontAwesomeIcon icon={faChevronRight} className="text-[20px] text-white" />
        )
      }
    />
  );
};

const SkeletonSubscriptionsView = () => {
  return (
    <>
      <LoadingSkeleton className="mb-4 box-border flex h-[60px] w-full flex-row rounded-xl bg-base800" />
      <LoadingSkeleton className="mb-4 box-border flex h-[60px] w-full flex-row rounded-xl bg-base800" />
      <LoadingSkeleton className="mb-4 box-border flex h-[60px] w-full flex-row rounded-xl bg-base800" />
    </>
  );
};

export { SubscriptionsPage };
