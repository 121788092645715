import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faMobile } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { InfoToggle } from '../../tamagui-components/structures/InfoToggle';
import { formatSelectedDate } from '../../utils/dateUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { EventHeader } from '../event/EventHeader';
import { CAMPAIGN_MESSAGE_MAX_CHARS, CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const ReleaseView = () => {
  const { setField, fields, errors, validateField } = useCampaignForm();

  const [releaseCharCounter, setReleaseCharCounter] = useState(fields.releaseMessage?.length || 0);

  const isPresave = fields.campaignType === CampaignType.Presave;

  const formatReleaseDate = fields.releaseDate
    ? formatSelectedDate({
        date: fields.releaseDate,
        alwaysShowDate: !!fields.isEditMode && !!fields.isReleaseDatePassed,
      })
    : 'Now';

  return (
    <View className="flex h-full w-full flex-col">
      <EventHeader icon={faMobile} title="Text members" description="Announce your song via SMS." />
      {isPresave && (
        <View className="mb-8 mt-2">
          <View className="mb-8 h-[1px] w-full bg-vault_text/10" />
          {fields.isReleaseDatePassed && (
            <Text className="mb-4 text-base-s text-destructive300">
              Release announcement editing has been disabled since the messages have already been
              sent
            </Text>
          )}

          <View className="mt-2 flex w-full flex-row items-center justify-between gap-2">
            <InfoToggle
              label="Release day message"
              subText={`This message is sent on ${formatReleaseDate}`}
              checked={fields.shouldSendReleaseSms}
              onCheckedChange={value => {
                setField('shouldSendReleaseSms', value);
                validateField('releaseMessage');
              }}
              disabled={fields.isReleaseDatePassed}
              useVaultTheme
            />
          </View>
          <textarea
            value={fields.releaseMessage}
            name="release message"
            aria-multiline="true"
            placeholder="Write your message here..."
            className={twMerge(
              'no-scrollbar mt-4 box-border min-h-[200px] w-full resize-none rounded-md border border-solid border-vault_text/10 bg-transparent p-3 font-base !text-base-l text-vault_text outline-none placeholder:text-vault_text/50',
              errors.releaseMessage ? 'border-destructive300' : 'focus:border-vault_text',
              (!fields.shouldSendReleaseSms || fields.isReleaseDatePassed) && 'opacity-50',
            )}
            onChange={e => {
              setField('releaseMessage', e.target.value);
              setReleaseCharCounter(e.target.value.length);
              validateField('releaseMessage');
            }}
            onBlur={() => {
              validateField('message');
            }}
            disabled={!fields.shouldSendReleaseSms || fields.isReleaseDatePassed}
          />
          {fields.shouldSendReleaseSms && (
            <View className="flex flex-row items-center justify-between">
              <View className="flex flex-row items-center justify-center gap-1">
                <FontAwesomeIcon className="text-vault_text/50" icon={faLink} size="xs" />
                <Text className="text-base-s text-vault_text/50">
                  Link to the release page will be included in the message
                </Text>
              </View>
              <Text
                className={twMerge(
                  'h-6 pt-2 font-base !text-base-s tabular-nums',
                  !!errors.releaseMessage ? 'text-destructive300' : 'text-vault_text/50',
                )}
              >
                {releaseCharCounter}/{CAMPAIGN_MESSAGE_MAX_CHARS}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};
