import type { InputHTMLAttributes } from 'react';
import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export const Slider = ({
  min = 1,
  max = 3,
  step = 0.1,
  value,
  className,
  ...props
}: {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  className?: string;
}) => {
  const backgroundSize = useMemo(() => {
    return ((value - min) * 100) / (max - min) + '%_100%';
  }, [max, min, value]);

  return (
    <input
      type="range"
      role="slider"
      min={min}
      max={max}
      step={step}
      value={value}
      {...props}
      className={twMerge(
        'h-[5px] w-full rounded-[5px] bg-gray200 bg-gradient-to-r from-blue400 to-blue400 bg-no-repeat focus:outline-none',
        `bg-[length:${backgroundSize}]`,
        className,
      )}
    />
  );
};
