import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fileTypeFromBuffer } from 'file-type';
import mime from 'mime';
import { isMobileSafari } from 'react-device-detect';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';
import { Navigate, useParams } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faPencil } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faX } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { MAX_AUDIO_UPLOAD_SIZE_BYTES, MAX_AUDIO_UPLOAD_SIZE_TEXT } from '@soundxyz/utils/src/const';
import {
  ERROR_TYPE,
  MEDIA_FILE_ERROR_ACTIONS,
  MEDIA_FILE_INFO_ACTIONS,
  PILLARS,
} from '@soundxyz/vault-utils/dist/constants';
import { loadTrack, useAudioController } from '../../../audio/AudioController';
import { togglePlayPause } from '../../../audio/AudioEngineHTML5';
import { setPosition } from '../../../audio/AudioPosition';
import { COLOR } from '../../../constants/colorConstants';
import { ACCEPTED_IMAGE_TYPES } from '../../../constants/fileConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import {
  makeFragmentData,
  VaultContentAccessFeatureInput,
  VaultContentType,
  WaveformTrackInfoFragmentDoc,
} from '../../../graphql/generated';
import { useLogError } from '../../../hooks/logger/useLogError';
import { useLogInfo } from '../../../hooks/logger/useLogInfo';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useBetterGate } from '../../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme, VaultThemeStore } from '../../../hooks/useVaultTheme';
import { Button } from '../../../tamagui-components/elements/Button';
import { Label } from '../../../tamagui-components/elements/TextElements';
import { InfoRadioSelect } from '../../../tamagui-components/structures/InfoRadioSelect';
import { InfoToggle } from '../../../tamagui-components/structures/InfoToggle';
import { LoginStatus } from '../../../types/authTypes';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { formatTime } from '../../../utils/textUtils';
import { PlayButtonView } from '../../audioPlayer/PlayButtonView';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';
import { SongArtwork } from '../../track/SongArtwork';
import { uploadContentState } from '../../upload/store';
import { useVaultContentUpload } from '../../upload/useContentUpload';
import { Waveform } from '../../waveform/Waveform';
import { FullPageLoading } from '../FullPageLoading';

const LENGTH_THRESHOLD = 60;

export function UploadContentView() {
  useVaultTheme();
  const { vaultContentId } = useParams();

  const { artistHandle } = useArtistHandle();
  const { loginStatus } = useAuthContext();
  const ownedArtist = useOwnedArtist({ artistHandle });

  const {
    enableSubmit,
    setField,
    fields,
    onContentSubmit,
    onEdit,
    isCreatingContent,
    isEditingContent,
    isSubmitting,
    validateField,
    errors,
  } = useVaultContentUpload({ vaultId: ownedArtist?.mainVault.id ?? '' });

  const hasMediaCommentsFF = useBetterGate('MEDIA_COMMENTS') === 'enabled';

  const { textColor } = useSnapshot(VaultThemeStore);

  const freeOnly = ownedArtist?.mainVault.type === 'FREE_ONLY';

  const fileType =
    fields.fileRef.current?.contentType === 'TRACK'
      ? 'TRACK'
      : fields.fileRef.current?.contentType === 'IMAGE'
        ? 'IMAGE'
        : 'VIDEO';

  const mediaType = fields.contentType || fileType;
  const loading = (fields.isEditing ? isEditingContent : isCreatingContent) || isSubmitting;
  const onUpload = useStableCallback(() => {
    if (!artistHandle || !ownedArtist) return;
    if (fields.isEditing) {
      if (!fields.mediaId || !vaultContentId) return;
      onEdit({
        mediaType:
          mediaType === 'TRACK'
            ? VaultContentType.Track
            : mediaType === 'IMAGE'
              ? VaultContentType.Image
              : VaultContentType.Video,
        contentId: vaultContentId,
        artistHandle,
        artistId: ownedArtist.id,
        vaultId: ownedArtist.mainVault.id,
      });
    } else {
      onContentSubmit({
        artistHandle,
        artistId: ownedArtist.id,
      });
    }
  });

  if (loginStatus === LoginStatus.LOADING) return <FullPageLoading withVaultTheme />;

  if (ownedArtist == null || artistHandle == null) return <Navigate to={ROUTES.NOT_FOUND} />;

  return (
    <div className="relative box-content flex h-full w-full flex-col items-center justify-between gap-8 overflow-x-hidden pb-safe-offset-10 md2:py-0 md2:mt-safe-offset-4  md2:pb-safe-offset-16">
      <div className="scrollbar-theme box-content flex h-full w-full items-start justify-center overflow-y-auto pt-4 pb-safe-offset-20 md2:pb-10">
        <div className="box-content flex w-full flex-col gap-4 px-4  md2:max-w-[390px]">
          <div className="flex w-full flex-col gap-4 md2:gap-8">
            <div className="flex w-full flex-col items-center">
              <UploadContentMedia
                contentType={mediaType}
                vaultContentId={vaultContentId}
                vaultId={ownedArtist.mainVault.id}
              />
            </div>

            <div className="flex w-full flex-col items-center justify-center gap-2 text-center">
              {!!errors.title && <Text className="text-destructive200">{errors.title}</Text>}
              <TextareaAutosize
                className={twMerge(
                  'w-full border-0 text-center focus:font-normal focus:outline-none ',
                  'font-title md2:min-h-[40px]',
                  'bg-transparent placeholder:text-vault_text/50',
                  'resize-none',
                  fields.isUploading ? 'text-vault_text/50' : 'text-vault_text',
                  fields.title.length > LENGTH_THRESHOLD
                    ? 'text-[15px] leading-[18px] md2:text-[16px] md2:leading-[20px]'
                    : 'text-[30px] leading-9 md2:text-[32px] md2:leading-10',
                )}
                placeholder="Enter file name"
                value={fields.title}
                onChange={e => {
                  setField('title', e.target.value);
                  validateField('title');
                }}
                disabled={fields.isUploading}
                aria-label="Title"
              />
              {(mediaType === 'TRACK' || hasMediaCommentsFF) && (
                <TextareaAutosize
                  className={twMerge(
                    'max-h-72 min-h-[70px] w-[inherit] resize-none rounded-md border-none p-3 text-center font-base !text-base-m focus:font-normal focus:outline-none lg:!text-base-l',
                    'bg-transparent text-vault_text placeholder:text-vault_text/50 disabled:text-vault_text/50',
                    'min-h-[32px] border-0 border-b pl-0',
                  )}
                  placeholder="Add a note"
                  value={fields.caption || ''}
                  onChange={e => setField('caption', e.target.value)}
                  disabled={fields.isUploading}
                />
              )}
            </div>
          </div>

          <div className="flex w-full flex-col items-start gap-8 md2:gap-10">
            <div className="h-[1px] w-full bg-vault_text/10" />

            {!freeOnly && (
              <div className="flex w-full flex-col gap-4">
                <Label label="Access" color={textColor} />
                <div key={fields.featureAccess} className="flex flex-col gap-3">
                  <InfoRadioSelect
                    key={`${fields.featureAccess}-${VaultContentAccessFeatureInput.FreeVaultContent}`}
                    useVaultTheme
                    selected={
                      fields.featureAccess === VaultContentAccessFeatureInput.FreeVaultContent
                    }
                    onPress={() =>
                      setField('featureAccess', VaultContentAccessFeatureInput.FreeVaultContent)
                    }
                    label="All members"
                    subText={`All members can ${mediaType === 'TRACK' ? 'listen to' : 'view'} the ${
                      mediaType === 'TRACK'
                        ? 'full track'
                        : mediaType
                          ? `the ${mediaType.toLowerCase()}`
                          : 'this file'
                    }`}
                    subTextNumberOfLines={2}
                  />
                  <InfoRadioSelect
                    key={`${fields.featureAccess}-${VaultContentAccessFeatureInput.PaidVaultContent}`}
                    useVaultTheme
                    selected={
                      fields.featureAccess === VaultContentAccessFeatureInput.PaidVaultContent
                    }
                    onPress={() =>
                      setField('featureAccess', VaultContentAccessFeatureInput.PaidVaultContent)
                    }
                    label="Paid members only"
                    subText={
                      mediaType === 'TRACK'
                        ? 'Free members can only access a snippet'
                        : `Only paid members can view ${mediaType ? `the ${mediaType.toLowerCase()}` : 'this file'}`
                    }
                    subTextNumberOfLines={2}
                  />
                </div>
              </div>
            )}
            <InfoToggle
              label="Downloadable"
              subText={`Members with access can download the ${mediaType.toLowerCase()}`}
              useVaultTheme
              checked={fields.downloadEnabled}
              onCheckedChange={value => setField('downloadEnabled', value)}
            />
          </div>
        </div>
      </div>

      <div className="absolute w-full border-x-0 border-b-0 border-t border-solid border-t-vault_text/10 bg-vault_background  bottom-safe-offset-0  md2:border-t-0 md2:bg-transparent  md2:bg-gradient-to-b  md2:from-transparent md2:to-vault_background md2:to-60%">
        <div className="flex w-full bg-transparent pb-5 pt-4 md2:bg-gradient-to-b  md2:from-transparent md2:to-vault_text/3 md2:to-60% md2:pt-10">
          <div className="mx-auto flex w-full items-center  justify-center rounded-full bg-transparent px-3 md2:max-w-[398px]">
            <Button
              label={fields.isEditing ? 'Update' : 'Upload'}
              useVaultTheme
              variant="primary"
              fullWidth
              loading={loading}
              disabled={!enableSubmit || !artistHandle || !ownedArtist}
              onPress={onUpload}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const UploadContentMedia = ({
  contentType,
  vaultContentId,
  vaultId,
}: {
  contentType: Exclude<VaultContentType, 'FOLDER'> | null;
  vaultContentId: string | null | undefined;
  vaultId: string;
}) => {
  const { fields } = useVaultContentUpload({ vaultId });

  switch (contentType) {
    case 'IMAGE':
      const imageUrl =
        fields.fileRef.current?.fileUri ??
        fields.thumbnailRef.current?.fileUri ??
        fields.thumbnailUrl;

      if (!imageUrl) return null;
      return (
        <Image
          src={imageUrl}
          alt={fields.title}
          className="h-[280px] w-[68%] rounded-md bg-vault_text/20 object-cover md2:h-[360px]"
        />
      );
    case 'VIDEO':
      return <VideoBox vaultId={vaultId} />;
    case 'TRACK':
      return (
        <div className="flex flex-col items-center">
          <SongArtworkUpload vaultId={vaultId} />
          <WaveFormBox
            vaultId={vaultId}
            normalizedPeaks={
              fields.normalizedPeaks ?? (fields.fileRef?.current?.normalizedPeaks || [])
            }
            objectUrl={fields.fileRef?.current?.fileUri || ''}
            duration={(fields.duration ?? fields.fileRef?.current?.duration) || 0}
            isLoading={fields.isUploadingPreview}
            trackId={vaultContentId}
          />
        </div>
      );
    default:
      return (
        <div className="relative flex aspect-1 w-[240px] items-center justify-center rounded-md bg-vault_text/10" />
      );
  }
};

function WaveFormBox({
  vaultId,
  normalizedPeaks,
  objectUrl,
  duration,
  isLoading,
  trackId,
}: {
  vaultId: string;
  normalizedPeaks: number[];
  objectUrl?: string;
  duration: number;
  isLoading: boolean;
  trackId?: string | null;
}) {
  const [played, setPlayed] = useState<boolean>(false);
  const { playing, activeTrackId } = useAudioController();
  const hasTrack = !!trackId || !!objectUrl;
  const vaultTheme = useSnapshot(VaultThemeStore);

  if (isLoading || !hasTrack) {
    return (
      <LoadingSkeleton className="relative flex aspect-[4] w-full justify-end justify-items-center rounded-[20px] bg-vault_text/10" />
    );
  }

  const onPlay = () => {
    trackEvent({
      type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
      properties: { component: 'upload_view', vaultId },
    });

    if (!played) {
      if (!!trackId) {
        loadTrack({
          trackId,
          vaultId,
          component: 'upload_view',
          folderId: null,
          autoplay: true,
        });
      } else {
        loadTrack({
          audioSrc: objectUrl,
          trackId: 'upload',
          vaultId,
          component: 'upload_view',
          folderId: null,
          autoplay: true,
        });
      }
    }

    togglePlayPause();
    setPlayed(true);
  };

  useEffect(() => {
    return () => {
      setPosition(0);
      setPlayed(false);
    };
  }, []);

  const waveFormTheme = vaultTheme.textColor === COLOR.white ? 'light' : 'dark';

  return (
    <div className="flex w-full max-w-[274px] flex-row items-center justify-center gap-3 rounded-xl p-4">
      <PlayButtonView
        isPlaying={playing && (!!trackId ? activeTrackId === trackId : activeTrackId === 'upload')}
        isDisabled={false}
        onClick={onPlay}
        className="text-vault_text"
        size={32}
      />

      <div className="flex flex-1 flex-col ">
        <Waveform
          height={40}
          isDisabled={false}
          theme={waveFormTheme}
          track={makeFragmentData(
            {
              normalizedPeaks,
              id: trackId || 'upload',
              duration,
              vaultId,
              parentVaultContentId: null,
            },
            WaveformTrackInfoFragmentDoc,
          )}
          isAuthor={false}
          barWidthOffset={0.8}
        />
      </div>
      <span className="font-base !text-base-s text-vault_text/50">{formatTime(duration)}</span>
    </div>
  );
}

function VideoBox({ vaultId }: { vaultId: string }) {
  const { fields } = useVaultContentUpload({ vaultId });
  const videoRef = useRef<HTMLVideoElement>(null);

  // this is a workaround for safari not showing video preview on first load from camera roll
  useEffect(() => {
    if (!videoRef.current || !isMobileSafari) return;
    const video = videoRef.current;
    video?.play();
    setTimeout(() => {
      video?.play();
      video?.pause();
    }, 50);
    // When the component mounts or mediaUrl changes
  }, []);

  if (fields.isUploadingPreview) {
    return <div className="h-[360px] w-fit animate-pulse bg-vault_text/10" />;
  }

  const media = fields.fileRef.current;
  const mediaUrl = media?.fileUri ?? fields.thumbnailUrl;

  if (mediaUrl == null) return null;

  return (
    <div className="relative flex h-[360px] w-[320px] overflow-hidden rounded-md bg-vault_text/10">
      <ReactPlayer
        url={mediaUrl}
        preload="metadata"
        className="h-full w-full max-w-full rounded-md bg-vault_text/10"
        style={{ objectFit: 'cover' }}
        controls
        controlsList="nodownload"
        width="320px"
        height="360px"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              style: {
                objectFit: 'cover',
                width: '320px',
                height: '360px',
                maxWidth: '320px',
                maxHeight: '360px',
              },
            },
          },
        }}
      />
    </div>
  );
}

function SongArtworkUpload({ vaultId }: { vaultId: string }) {
  const { fields, setField } = useVaultContentUpload({ vaultId });
  const { openToast } = useToast();
  const logInfo = useLogInfo();
  const logError = useLogError();
  const { artistHandle } = useArtistHandle();
  const ownedArtist = useOwnedArtist({ artistHandle });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    async onDropAccepted([file]) {
      if (!file) return;
      try {
        setField('isThumbnailUploading', true);
        const buffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(buffer);
        const fileType = await fileTypeFromBuffer(uint8Array);

        const mimeFromFileName = mime.getType(file.name);

        if (!fileType || !mimeFromFileName) {
          openToast({
            text: `File could not be uploaded. Make sure it is a valid image file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
            variant: 'error',
          });
          return;
        }

        logInfo({
          action: MEDIA_FILE_INFO_ACTIONS.UPLOAD_FILE_START,
          message: `Started processing ${fileType.mime} file`,
          pillar: PILLARS.MEDIA_FILE,
          data: {
            fileSize: file.size,
            fileName: file.name,
            mimeType: fileType.mime,
            source: 'SongArtworkUpload',
          },
        });

        if (Object.keys(ACCEPTED_IMAGE_TYPES).includes(fileType.mime)) {
          if (fileType.mime !== mimeFromFileName) {
            openToast({
              text: 'File type does not match file extension. Please try again with a valid file.',
              variant: 'error',
            });
            return;
          }

          uploadContentState.fields.thumbnailRef.current = {
            file,
            fileUri: URL.createObjectURL(file),
          };
          setField('isThumbnailUploading', false);
        } else {
          openToast({
            text: `File could not be uploaded. Make sure it is a valid image file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
            variant: 'error',
          });
        }
      } catch (error) {
        setField('isThumbnailUploading', false);

        logError({
          action: MEDIA_FILE_ERROR_ACTIONS.MEDIA_FILE_ERROR,
          error,
          errorType: ERROR_TYPE.UNKNOWN,
          level: 'error',
          message: 'Error uploading file',
          pillar: PILLARS.MEDIA_FILE,
          indexedTags: {
            vaultId: ownedArtist?.mainVault.id,
            artistLinkValue: artistHandle,
            source: 'SongArtworkUpload',
          },
          unindexedExtra: {
            fileSize: file.size,
            fileName: file.name,
          },
          openToast,
          toast: 'There was an error uploading your image. Please try again.',
        });
      }
    },
    onDropRejected() {
      setField('isThumbnailUploading', false);

      openToast({
        text: `File could not be uploaded. Make sure it is a image file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
        variant: 'error',
      });
    },
    onFileDialogCancel() {
      setField('isThumbnailUploading', false);
    },
    accept: {
      ...ACCEPTED_IMAGE_TYPES,
    },
    maxFiles: 1,
    maxSize: MAX_AUDIO_UPLOAD_SIZE_BYTES,
    disabled: false,
  });

  const rootProps = getRootProps();
  const imageUrl = uploadContentState.fields.thumbnailRef.current?.fileUri ?? fields.thumbnailUrl;
  const hasThumbnail = !!fields.thumbnailUrl || !!imageUrl;

  return (
    <div
      className="relative h-[240px] w-[240px] cursor-pointer rounded-md border-none bg-transparent"
      onClick={(...args) => {
        rootProps.onClick?.(...args);
      }}
      {...rootProps}
    >
      <button
        className="relative h-[240px] w-[240px] cursor-pointer rounded-md border-none bg-transparent outline-none transition-all duration-200 ease-in-out hover:opacity-80"
        aria-label="Edit track artwork"
      >
        <SongArtwork title={fields.title} size={240} thumbnailUrl={imageUrl} />
      </button>
      <button
        className="absolute -right-2 -top-2 h-8 w-8 cursor-pointer rounded-full border-none bg-vault_background outline-none hover:bg-vault_background/80"
        aria-label={hasThumbnail ? 'Remove track artwork' : 'Add track artwork'}
        onClick={e => {
          if (!!hasThumbnail) {
            e.stopPropagation();
            uploadContentState.fields.thumbnailRef.current = null;
            setField('thumbnailUrl', '');
            setField('thumbnailId', null);
          }
        }}
      >
        <div className="flex h-full w-full items-center justify-center rounded-full bg-vault_text/20">
          <FontAwesomeIcon
            icon={hasThumbnail ? faX : faPencil}
            className="text-[12px] text-vault_text"
          />
        </div>
      </button>
      <input {...getInputProps()} />
    </div>
  );
}
