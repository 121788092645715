import { proxy } from 'valtio';
import { ReleaseCampaignContentType, ThirdPartyPlatform } from '../../graphql/generated';
import type { CampaignState } from './schema';

export const initialCampaignState = () =>
  ({
    fields: {
      campaignType: null,
      mediaId: null,
      thirdPartyImageUrl: null,
      image: '',
      title: '',
      artist: '',
      description: '',
      releaseDate: null,
      announcementDate: null,
      dsps: [
        {
          key: ThirdPartyPlatform.Spotify,
          name: 'Spotify',
          uri: '',
          buttonText: 'Play',
        },
        {
          key: ThirdPartyPlatform.AppleMusic,
          name: 'Apple Music',
          uri: '',
          buttonText: 'Play',
        },
      ],
      isrc: null,
      upc: null,
      contentType: ReleaseCampaignContentType.Album,
      showSpotifyPresaveFirst: true,
      shouldSendSms: true,
      shouldSendReleaseSms: true,
      message: '',
      releaseMessage: '',
      isEditMode: false,
      isReleaseDatePassed: false,
      showAppleMusicPresave: null,
      showSpotifyPresave: null,
    },
    errors: {
      campaignType: null,
      image: null,
      title: null,
      artist: null,
      description: null,
      dsps: null,
      contentType: null,
      shouldSendSms: null,
      message: null,
      shouldSendReleaseSms: null,
      releaseMessage: null,
      isrc: null,
      upc: null,
    },
  }) satisfies CampaignState;

export const campaignState: CampaignState = proxy(initialCampaignState());

export type CampaignStateKeys = keyof CampaignState['fields'];
