import { MediaType } from '../../graphql/generated';
import { Image } from '../common/Image';

export type MediaView = {
  id: string;
  url: string;
  thumbnailUrl?: string;
  type: MediaType;
  title: string;
  caption: string | null | undefined;
};

export const RenderedMedia = ({
  media,
  currentVideoRef,
}: {
  media: MediaView;
  currentVideoRef: React.RefObject<HTMLVideoElement> | null;
}) => {
  return media.type === MediaType.Video ? (
    <video
      ref={currentVideoRef}
      src={media.url}
      preload="metadata"
      className="h-full w-full object-contain md2:aspect-square md2:rounded-l-xl"
      controls
      controlsList="nodownload"
      poster={media.thumbnailUrl ?? undefined}
    />
  ) : (
    <Image
      src={media.url}
      alt={media.title ?? 'Artist Image'}
      className="pointer-events-none aspect-square h-full w-full object-contain"
    />
  );
};
