import React, { type LegacyRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLongPress } from 'use-long-press';

import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPencil } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faFileDownload } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../../../contexts/MenuContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useToast } from '../../../../contexts/ToastContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import { useMutation } from '../../../../graphql/client';
import { MediaType } from '../../../../graphql/generated';
import {
  type FragmentType,
  getFragment,
  UpsertUserContentViewDocument,
  VideoItemFragmentDoc,
} from '../../../../graphql/generated';
import { useBetterGate } from '../../../../hooks/useFeatureGate';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { useWindow } from '../../../../hooks/useWindow';
import { useSelectVaultContent } from '../../../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../../../hooks/vault/useVaultContentActions';
import { MoveToContents } from '../../../../screens/vault/MoveToPage';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { MediaOverlay } from '../../../content/MediaOverlay';
import type { MediaView } from '../../../content/RenderedMedia';
import { MediaViewer } from '../../../message/MediaViewer';
import { useEditVaultContent } from '../../../upload/useContentUpload';
import { VideoItem } from '../VideoItem';
import { useVaultDownloadPress } from '../useVaultDownloadPress';
import { type ContentOption, ContentOptions } from './ContentOptions';

export const ArtistVideo = ({
  video,
  allAssets,
  containerRef,
  artistHandle,
}: {
  video: FragmentType<VideoItemFragmentDoc>;
  allAssets: Array<MediaView>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    id: videoId,
    linkValue,
    vault,
    title,
    uploadedMedia,
    featureAccess,
    caption,
    downloadEnabled,
    duration,
    parentVaultContentId,
  } = getFragment(VideoItemFragmentDoc, video);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const { isDesktop } = useWindow();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();

  const hasMediaCommentsFF = useBetterGate('MEDIA_COMMENTS') === 'enabled';
  const { editVaultContent } = useEditVaultContent();

  const { md2 } = MediaQuery.useContainer();
  const [menuHovered, setMenuHovered] = useState(false);

  const [selectedFile, setSelectedFile] = React.useState(false);
  const {
    isSelecting,
    removeContent: removeSelectedContent,
    addContent: addSelectedContent,
  } = useSelectVaultContent();

  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    if (isSelecting || md2) return;
    setIsOpen(true);
  })();

  const { removeContents: deleteFile } = useVaultContentActions();

  const rearrangeButton = {
    title: 'Rearrange',
    icon: faArrowUpArrowDown,
    onClick: () => {
      navigate(
        artistNavigationPath(
          artistHandle,
          `/rearrange${parentVaultContentId != null ? `/${parentVaultContentId}` : ''}`,
        ),
      );
    },
  };

  const vaultArtistProfileImage = vault.artist?.profileImage?.artistSmallProfileImageUrl ?? null;
  const vaultArtistMembershipCardImage =
    vault.artist?.membershipCardImage?.membershipCardImageUrl ?? null;

  const { onDownloadClick } = useVaultDownloadPress({
    vaultContentId: videoId,
    isLocked: false,
    vaultId: vault.id,
    artistHandle,
    artistName: vault.artist?.name ?? null,
    vaultArtistProfileImage,
    vaultArtistMembershipCardImage,
    hasActiveSubscription: true,
    fileName: title,
  });

  const deleteButton = {
    title: 'Delete',
    icon: faTrash,
    onClick: () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CONFIRMATION,
        confirmationBottomsheetProps: {
          subText: 'Are you sure you want to delete this video?',
          confirmButtonText: 'Delete',
          confirmType: 'delete',
          onConfirm: () => {
            deleteFile({
              contentIds: [videoId],
              vaultId: vault.id,
              onSuccess: () => {
                openToast({
                  text: 'The video has been deleted',
                  variant: 'success',
                });
              },
            });
          },
        },
        shared: {
          withVaultTheme: true,
        },
      });
    },
  } satisfies ContentOption;

  const downloadButton = {
    title: 'Download',
    icon: faFileDownload,
    onClick: onDownloadClick,
  } satisfies ContentOption;

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: () => {
        if (isDesktop) {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SHARE_CONTENT,
            shareContentBottomsheetProps: {
              artistHandle,
              linkValue,
            },
            shared: {
              withVaultTheme: true,
              preventOutsideAutoClose: true,
            },
          });
          return;
        }

        navigate(artistNavigationPath(artistHandle, `/v/${linkValue}/share`));
      },
    },
    {
      title: 'Move to',
      icon: faFolder,
      onClick: () => {
        MoveToContents.contentIds = [videoId];
        navigate(artistNavigationPath(artistHandle, '/move-to'));
      },
    },
    {
      title: 'Edit',
      icon: faPencil,
      onClick: () => {
        const hasFreeAccess = featureAccess.some(
          feature => feature.feature.__typename === 'FreeVaultContent',
        );

        editVaultContent({
          artistHandle,
          withNavigation: true,
          contentType: 'VIDEO',
          title,
          caption,
          downloadEnabled,
          hasFreeAccess,
          thumbnailUrl: uploadedMedia?.url || null,
          duration,
          mediaId: uploadedMedia?.id || null,
          normalizedPeaks: null,
          vaultContentId: videoId,
        });
      },
    },
  ];

  if (downloadEnabled) {
    buttons.push(downloadButton);
  }

  buttons.push(rearrangeButton, deleteButton);

  const onSelectFile = useStableCallback(() => {
    return setSelectedFile(prev => {
      if (prev) {
        removeSelectedContent(videoId);
        return false;
      }
      addSelectedContent(videoId);
      return true;
    });
  });

  useEffect(() => {
    if (!isSelecting) {
      setSelectedFile(false);
    }
  }, [isSelecting]);

  const onPlayClick = () => {
    upsertUserContentView({ input: { vaultContentId: videoId } });
    if (uploadedMedia == null) {
      return;
    }
    if (hasMediaCommentsFF) {
      const firstAsset = allAssets[0];
      if (!firstAsset) return;

      if (isDesktop) {
        openOverlay(
          <MediaOverlay media={firstAsset} currentVideoRef={videoRef} onClose={closeOverlay} />,
        );
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.MEDIA_VIEW,
            contentType: MediaType.Video,
            component: 'ArtistVideo',
            event: 'Open Bottomsheet',
            contentId: firstAsset.id,
          },
        });

        openBottomsheet({
          type: 'MEDIA_VIEW',
          shared: {
            withVaultTheme: false,
            showFullScreen: true,
            hidePulleyBar: true,
            fullWidth: true,
          },
          mediaViewBottomsheetProps: {
            media: firstAsset,
            currentVideoRef: videoRef,
            withVaultTheme: false,
          },
        });
      }
    } else {
      openOverlay(<MediaViewer title={title} medias={allAssets} onClose={closeOverlay} />);
    }
  };

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={isSelecting}
      onLongPress={onLongPress}
      onClick={() => {
        if (isSelecting) {
          return onSelectFile();
        }
        if (!isOpen) {
          return onPlayClick();
        }
      }}
      selectedFile={selectedFile}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={
        <VideoItem video={video} containerRef={containerRef} videoRef={videoRef} hasEllipsis />
      }
      disabled={isVaultCustomizeOpen}
      disableHover
    />
  );
};
