import { usePrivy } from '@privy-io/react-auth';
import { useNavigate } from 'react-router';
import { faCircleX } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { ROUTES } from '../../constants/routeConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { DeleteAccountDocument } from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import { useStableCallback } from '../../hooks/useStableCallback';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getErrorInstance } from '../../utils/errors';

export function AccountManagementPage() {
  const { mutateAsync: deleteAccountMutation, isLoading } = useMutation(DeleteAccountDocument, {
    retry: 2,
  });
  const { logout } = usePrivy();
  const navigate = useNavigate();

  const { openToast } = useToast();

  const { openBottomsheet } = useBottomsheetContainer();

  const logError = useLogError();

  const deleteAccount = useStableCallback(async () => {
    try {
      const { data } = await deleteAccountMutation({});
      if (data.deleteAccount) {
        const userId = data.deleteAccount.id;
        trackEvent({
          type: EVENTS.DELETE_ACCOUNT,
          properties: { userId },
        });
        await logout();
        navigate(ROUTES.SIGN_IN);
        openToast({
          text: 'Your account has been deleted.',
          variant: 'success',
        });
      } else {
        const message = 'There was an error deleting your account. Please try again later.';
        logError({
          error: new Error('Account not deleted'),
          errorType: 'MUTATION_ERROR',
          message,
          level: 'error',
          action: 'DELETE_PROFILE_ERROR',
          pillar: PILLARS.PROFILE_CUSTOMIZATION,
          toast: message,
        });
      }
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      const message = errorInstance.message.includes('contact us')
        ? errorInstance.message
        : 'There was an error deleting your account. Please try again later.';
      logError({
        error: errorInstance,
        errorType: 'MUTATION_ERROR',
        /**
         * IF the message contains contact us, we want to show the message as is
         * Otherwise, we want to show a generic error message
         */
        message,
        level: 'error',
        action: 'DELETE_PROFILE_ERROR',
        pillar: PILLARS.PROFILE_CUSTOMIZATION,
        toast: message,
      });
    }
  });

  return (
    <SettingsLayout title="Account Management">
      <View className="flex w-full flex-col items-start justify-start">
        <Button
          onClick={() => {
            openBottomsheet({
              type: 'CONFIRMATION',
              confirmationBottomsheetProps: {
                event: 'Delete Account',
                title: ' Are you sure you want to delete your account?',
                customContent: (
                  <Text className="text-center text-base-l">
                    Deleted accounts cannot be recovered and will lose chat and subscription history
                  </Text>
                ),
                confirmButtonText: 'Delete Account',
                onConfirm() {
                  deleteAccount();
                },
              },
            });
          }}
          label="Delete my account"
          leadingIcon={faCircleX}
          iconSize="xl"
          type="secondary"
          className="text-danger100"
          loading={isLoading}
          disabled={isLoading}
        />
      </View>
    </SettingsLayout>
  );
}
