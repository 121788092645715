import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import { gql } from '@soundxyz/gql-string';
import { ERROR_TYPE, FEATURES, NOTIFICATIONS_ERROR_ACTIONS } from '@soundxyz/vault-utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { useInfiniteQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistNotificationRowFragmentDoc,
  GetActiveVaultSubscriptionsForSettingsDocument,
  makeFragmentData,
  UpdateUserPushNotificationSettingsDocument,
  UpdateUserSmsNotificationSettingsDocument,
} from '../graphql/generated';
import { useChosenUserProfile } from '../store/userProfile';
import { useLogError } from './logger/useLogError';
import { useOwnedArtist } from './useOwnedArtist';

gql(/* GraphQL */ `
  query GetActiveVaultSubscriptionsForSettings($after: String, $first: Int) {
    activeVaultSubscriptionsByPriority(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...ArtistNotificationRow
          artist {
            id
            linkValue
            name
            profileImage {
              id
              url: imageOptimizedUrl(input: { width: 100, height: 100 })
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserSmsNotificationSettingsDocument, UpdateUserPushNotificationSettingsDocument],
  refetch: [GetActiveVaultSubscriptionsForSettingsDocument],
});

const LIMIT = 15;

const feature = FEATURES.NOTIFICATIONS;

export function useArtistsNotificationSettings() {
  const { loggedInUser } = useAuthContext();

  const { userProfileId } = useChosenUserProfile();
  const ownedArtist = useOwnedArtist({ userProfileId });

  const {
    orderedList: subscriptionRows,
    isLoading,
    isError,
    error,
    refetch,
    isFetchingNextPage,
    isLoadingNewPage,
    hasNextPage,
    isInitialLoading,
    loadMoreNextPage,
  } = useInfiniteQuery(GetActiveVaultSubscriptionsForSettingsDocument, {
    staleTime: 0,
    filterQueryKey: { loggedInUserId: loggedInUser?.id },
    getNextPageParam: ({ data }) => {
      return (
        data.activeVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.activeVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    list(result) {
      return result.activeVaultSubscriptionsByPriority.edges.map(({ node }) => node);
    },
    uniq({ id }) {
      return id;
    },
    variables({ pageParam }) {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
  });

  const logError = useLogError();

  const rowData = useMemo(() => {
    if (isLoading || isError) {
      return [];
    }

    const adminList = !!ownedArtist ? [ownedArtist] : [];
    const adminRows =
      adminList?.map(({ id, mainLinkValue, name, profileImage }) => ({
        ...makeFragmentData(
          {
            id,
            artist: {
              id,
              name,
              profileImage: {
                id: profileImage.id,
                url: profileImage.artistSmallProfileImageUrl,
              },
              linkValue: mainLinkValue,
            },
          },
          ArtistNotificationRowFragmentDoc,
        ),
        id,
        isManagedVault: true,
      })) ?? [];

    const hasData = adminRows.length > 0 || subscriptionRows.length > 0;

    if (!hasData) {
      return [];
    }

    return uniqBy([...adminRows, ...subscriptionRows], ({ id }) => id);
  }, [ownedArtist, isError, isLoading, subscriptionRows]);

  useEffect(() => {
    if (!error) return;
    logError({
      action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
      error,
      level: 'warning',
      message: 'Failed to fetch subscription notification settings',
      errorType: ERROR_TYPE.FETCH_GQL_ERROR,
      feature,
      indexedTags: {
        userId: loggedInUser?.id,
        source: 'useArtistsNotificationSettings',
      },
    });
  }, [error, logError, loggedInUser?.id]);

  return {
    artists: rowData,
    isLoading,
    isError,
    error,
    refetch,
    isFetchingNextPage,
    isLoadingNewPage,
    hasNextPage,
    loadMoreNextPage,
    isInitialLoading,
  };
}
